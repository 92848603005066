import {Component, Input, OnInit} from '@angular/core';
import { TicketService } from "../../../../administrative/ticket/ticket.service";
import { AuthService } from "../../../../../../core/services/auth.service";
import { GedService } from "../../../../admin/playground/docuware-playground/ged.service";
import { MatDialog } from "@angular/material/dialog";
import { FileSaverService } from "ngx-filesaver";
import { GED_CONFIG } from "../../../../../../core/enums/ged-type.enum";
import { FeiColumn } from "../../../../../../core/base/interfaces/fei-column.interface";
import { GedBaseComponent, IGedBaseComponent } from "../../../../../../core/base/components/ged-base/ged-base.component";
import { ContractService } from "../../contract.service";
import { ContractModel } from "../../../../../../core/models/contract.model";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {IDocumentsQueryResult} from "../../../../../../core/ui/ged-ui/interface/docuware.interface";

@Component({
  selector: 'vex-contract-details-amendment-ged',
  templateUrl: './contract-details-amendment-ged.component.html',
  styleUrls: ['./contract-details-amendment-ged.component.scss']
})
export class ContractDetailsAmendmentGedComponent extends GedBaseComponent<ContractModel> implements IGedBaseComponent {

  @Input() canAddDoc = true;

  documentTypes = ['Avenant contrat'];
  feisColumns: Array<FeiColumn> = [
    {label: 'Nom du fichier', column: 'NOM_DU_DOCUMENT'},
    {label: 'Type de document', column: 'DOCUMENT_TYPE'},
    {label: 'Nombre de pages', column: 'DWPAGECOUNT'},
    {label: 'Date du dépôt', column: 'DATE_DU_DEPOT', type: 'date'},
    {label: '', column: 'actions', canUpdate: false}
  ];
  displayedColumns = [];
  addDocumentTitle = "Avenant"
  listTitle= "Liste des avenants"

  constructor(public service: ContractService,
              private authService: AuthService,
              private gedService: GedService,
              public dialog: MatDialog,
              public fileSaver: FileSaverService) {
    super(GED_CONFIG.contract, gedService, service, dialog, fileSaver);
    this.displayedColumns = this.feisColumns.map(x => x.column);
  }

  initData() {
    this.subscription.add(
        this.gedService.allDocumentsByPredikId(this.id)
            .subscribe((res: IDocumentsQueryResult) => {

              const gedMappedDataSource = this.gedService.mapToDataSource(res);

              this.dataSource = gedMappedDataSource?.filter(x => x.DOCUMENT_TYPE === 'Avenant contrat');

              this.loadingSave = false;
              this.files = [];
              this.initForm();
            })
    );
  }

  initForm() {
    const sub = this.service.entity$.subscribe(entity => {
      this.form = new FormGroup({
        predikId: new FormControl(this.id, [Validators.required]),
      });
          this.loading = false;
    });
  }



  beforeUpload() {
    this.defaults = {
      predikId: this.form.getRawValue().predikId,
      documentType: this.documentTypes[0]
    }}
}
