import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import icClose from '@iconify/icons-ic/twotone-close';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'form-modal-delete-cofirm-base',
    styles: [`
        .example-viewport {
            height: 200px;
            width: 100%;
        }

        .example-item {
            height: 50px;
        }`
    ],
    template: `
        <ng-container>
            <div mat-dialog-title fxLayout='row' fxLayoutAlign='space-between center'>
                <div>{{ title }}</div>
                <button type='button' mat-icon-button (click)='close(false)' tabindex='-1'>
                    <mat-icon [icIcon]='icClose'></mat-icon>
                </button>
            </div>

            <mat-dialog-content>
                <p>{{ message }}</p>
            </mat-dialog-content>

            <!--            <mat-list>-->
            <!--                <mat-list-item *ngFor='let user of list' >-->
            <!--                    <p>{{user}}</p>-->
            <!--                </mat-list-item>-->
            <!--            </mat-list>-->

            <cdk-virtual-scroll-viewport itemSize="50" class="example-viewport mt-4">
                <mat-list>
                    <mat-list-item *cdkVirtualFor="let item of list" class="example-item">{{item}}</mat-list-item>
                </mat-list>
            </cdk-virtual-scroll-viewport>

            <mat-dialog-actions align='end'>
                <button mat-button color='primary' [loading]='loading' (click)='close(true)'>OK</button>
            </mat-dialog-actions>
        </ng-container>
    `
})

export class FormModalConfirmWithListComponent implements OnInit {

    title: string;
    message: string;
    list: string[];
    loading = false;

    icClose = icClose;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {
            title: string, message: string, list: string[]
        },
        private dialog: MatDialogRef<FormModalConfirmWithListComponent>
    ) {
        if (data) {
            this.title = data.title;
            this.message = data.message;
            this.list = data.list;
        }
    }

    ngOnInit() {
    }

    close(bool: boolean) {
        this.dialog.close(bool);
    }
}
