import { Component, OnInit } from '@angular/core';
import { EquipmentModel } from 'src/app/core/models/equipment.model';
import { EquipmentService } from './equipment.service';
import { MatDialog } from '@angular/material/dialog';
import {
    EquipmentCreateUpdateDeleteComponent
} from './equipment-create-update-delete/equipment-create-update-delete.component';
import { FeiColumn } from 'src/app/core/base/interfaces/fei-column.interface';
import { DataTableBase } from 'src/app/core/base/components/data-table-base/data-table-base';
import { CrudModeEnum } from 'src/app/core/base/enum/crud-mode.enum';
import { ProductModel } from '../../../../core/models/product.model';
import { Observable } from 'rxjs';
import { EstablishmentModel } from '../../../../core/models/establishment.model';
import { BuildingModel } from '../../../../core/models/building.model';
import { EstablishmentService } from '../establishment/establishment.service';
import { BuildingService } from '../building/building.service';
import { map } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { scaleIn400ms } from '../../../../../@vex/animations/scale-in.animation';
import { fadeInRight400ms } from '../../../../../@vex/animations/fade-in-right.animation';
import { stagger40ms } from '../../../../../@vex/animations/stagger.animation';
import { fadeInUp400ms } from '../../../../../@vex/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from '../../../../../@vex/animations/scale-fade-in.animation';
import { dropdownAnimation } from '../../../../../@vex/animations/dropdown.animation';
import { popoverAnimation } from '../../../../../@vex/animations/popover.animation';
import { ENUM_PERMISSIONS } from '../../../../core/enums/permission.enum';
import { AuthService } from '../../../../core/services/auth.service';

@Component({
    selector: 'vex-equipment',
    templateUrl: './equipment.component.html',
    styleUrls: ['./equipment.component.scss'],
    animations: [
        scaleIn400ms,
        fadeInRight400ms,
        stagger40ms,
        fadeInUp400ms,
        scaleFadeIn400ms,
        scaleFadeIn400ms,
        dropdownAnimation,
        popoverAnimation
    ]
})
export class EquipmentComponent extends DataTableBase<EquipmentModel> implements OnInit {

    feisColumns: Array<FeiColumn> = [
        {label: 'Identifiant technique', column: 'technicalIdentifier'},
        {label: 'Référence équipement', column: 'reference'},
        {label: 'Etablissement', column: 'establishment', propertyDisplay: 'establishment.label'},
        {label: 'Equipement générique', column: 'genericEquipment', propertyDisplay: 'genericEquipment.label'},
        {label: 'Numéro de série', column: 'serialNumber'},
        {label: 'Marque', column: 'mark'},
        {label: 'Mise en service', column: 'commissioningDate', type: 'date'},
        {label: '', column: 'actions', canUpdate: false, canArchive: true}
    ];

    entityName = 'Équipement';

    establishment$: Observable<EstablishmentModel[]>;
    building$: Observable<BuildingModel[]>;

    ENUM_PERMISSION = ENUM_PERMISSIONS;

    canShowArchivePermission = false;

    constructor(
        public service: EquipmentService,
        private dialog: MatDialog,
        private establishmentService: EstablishmentService,
        private buildingService: BuildingService,
        private router: Router,
        private authService: AuthService,
    ) {
        super();
        this.displayedColumns = this.feisColumns.map(x => x.column);
    }

    ngOnInit() {
        super.ngOnInit();
        this.establishmentAutocompleteChange();
        this.buildingAutocompleteChange();
        this.popupForm = new FormGroup({
            establishment: new FormControl(null),
            building: new FormControl(null),
            commissioningDate: new FormControl(null),
        });

        this.authService.getCurrentUserPermissions$().subscribe(permissions => {
            this.canShowArchivePermission = permissions.includes(ENUM_PERMISSIONS.READ_EQUIPMENT_ARCHIVE);
        });


    }

    createItem(): void {
        this.dialog.open(EquipmentCreateUpdateDeleteComponent).afterClosed().subscribe((equipment: EquipmentModel) => {
            if (equipment) {
                if (equipment._id) {
                    this.router.navigateByUrl('/management/equipment/' + equipment._id).then();
                } else {
                    this.initData();
                }
            }
        });
    }

    updateItem(element: EquipmentModel): void {
        this.dialog.open(EquipmentCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                mode: CrudModeEnum.Update
            }
        }).afterClosed().subscribe((equipment: EquipmentModel) => {
            if (equipment) {
                this.initData();
            }
        });
    }

    deleteItem(element: EquipmentModel) {
        this.dialog.open(EquipmentCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                name: `${element.reference}`,
                mode: CrudModeEnum.Delete
            }
        }).afterClosed().subscribe((equipment: EquipmentModel) => {
            if (equipment) {
                this.initData();
            }
        });
    }

    archiveItem(element: EquipmentModel) {
        this.dialog.open(EquipmentCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                name: `${element.reference}`,
                mode: CrudModeEnum.Archive
            }
        }).afterClosed().subscribe((equipment: EquipmentModel) => {
            if (equipment) {
                this.initData();
            }
        });
    }

    importItems() {
        this.dialog.open(EquipmentCreateUpdateDeleteComponent, {
            data: {
                mode: CrudModeEnum.Import
            }
        }).afterClosed().subscribe((equipment: ProductModel[]) => {
            if (equipment) {
                this.initData();
            }
        });
    }

    beforeValidatePopupFilter() {
        const establishment = this.popupForm?.controls?.establishment?.value;
        const building = this.popupForm?.controls?.building?.value;
        this.filters.establishment = establishment?._id;
        this.filters.building = building?._id;
    }

    establishmentAutocompleteChange(state: any = '') {
        this.establishment$ = this.establishmentService.findAll(null, null, 'label', 'asc', state).pipe(map(x => x.data));
    }

    buildingAutocompleteChange(state: any = '') {
        this.building$ = this.buildingService.findAll(null, null, 'label', 'asc', state).pipe(map(x => x.data));
    }
}
