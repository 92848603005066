<ng-container>
    <div class="overflow-x-hidden">
        <header class="fixed top z-10 bg-white" [style.width]="'470px'">
            <base-form-header (onClose)="onClose.emit()" [mode]='mode' filterLabel='Filtres avancés'>
            </base-form-header>
            <div class="mt-4" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center stretch">
                <button (click)="onClear.emit()" fxFlex color="primary" mat-raised-button type="button">
                    {{ 'Effacer tout' | uppercase }}
                </button>
                <button (click)="onValidate.emit()" fxFlex color="primary" mat-raised-button type="button">
                    {{ 'Appliquer' | uppercase }}
                </button>
            </div>
        </header>

        <section [style.margin-top]="'110px'">
            <ng-template [ngTemplateOutlet]="standardForms"></ng-template>
        </section>
    </div>
</ng-container>