import { Injectable } from '@angular/core';
import { SecurityCommissionModel } from 'src/app/core/models/security-commission.model';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/core/base/base.service';
import { ObjectApiInterface } from '../../../../core/base/interfaces/object-api.interface';
import { map } from 'rxjs/operators';
import { UserProfileModel } from '../../../../core/models/user-profile.model';
import { EquipmentModel } from '../../../../core/models/equipment.model';
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class SecurityCommissionService extends BaseService<SecurityCommissionModel>{
    defaultSort = 'technicalIdentifier';

    constructor(public http: HttpClient) {
        super(http, 'security-commission');
    }

    addContact(securityCommissionId: string, contact: any) {
        return this.http.post<ObjectApiInterface<UserProfileModel>>(`${this.baseUrlApi}/${securityCommissionId}/addContact`, contact)
            .pipe(map(x => {
                this._entity.value.contact.push(x.data);
                return x;
            }));
    }

    getEstablishmentRelation(_id): Observable<ObjectApiInterface<SecurityCommissionModel[]>> {
        return this.http.get<ObjectApiInterface<SecurityCommissionModel[]>>(`${this.baseUrlApi}/getEstablishmentRelation/${_id}`);
    }

    deleteContact(securityCommissionId: string, contactId: any) {
        return this.http.delete<ObjectApiInterface<UserProfileModel>>(`${this.baseUrlApi}/${securityCommissionId}/deleteContact/${contactId}`)
            .pipe(map(x => {
                this._entity.value.contact = this._entity.value.contact.filter(y => y._id !== x['data']._id);
                return x;
            }));
    }

    addPerimetre(securityCommissionId: string, contact: any) {
        return this.http.post<ObjectApiInterface<EquipmentModel>>(`${this.baseUrlApi}/${securityCommissionId}/addPerimetre`, contact)
            .pipe(map(x => {
                this._entity.value.equipment.push(x.data);
                return x;
            }));
    }

    deletePerimetre(securityCommissionId: string, contactId: any) {
        return this.http.delete<ObjectApiInterface<UserProfileModel>>(`${this.baseUrlApi}/${securityCommissionId}/deletePerimetre/${contactId}`)
            .pipe(map(x => {
                this._entity.value.equipment = this._entity.value.equipment.filter(y => y._id !== contactId);
                return x;
            }));
    }

    addAction(securityCommissionId: string, contact: any) {
        return this.http.post<ObjectApiInterface<SecurityCommissionModel>>(`${this.baseUrlApi}/${securityCommissionId}/addAction`, contact)
            .pipe(map(x => {
                // this._entity.value.action.push(x.data);
                this._entity.next(x.data);
                return x;
            }));
    }

    updateAction(securityCommissionId: string, contact: any) {
        return this.http.put<ObjectApiInterface<SecurityCommissionModel>>(`${this.baseUrlApi}/${securityCommissionId}/updateAction/${contact._id}`, contact)
            .pipe(map(x => {
                // this._entity.value.action.push(x.data);
                this._entity.next(x.data);
                return x;
            }));
    }

    deleteAction(securityCommissionId: string, contactId: any) {
        return this.http.delete<ObjectApiInterface<SecurityCommissionModel>>(`${this.baseUrlApi}/${securityCommissionId}/deleteAction/${contactId}`)
            .pipe(map(x => {
                this._entity.value.action = this._entity.value.action.filter(y => y._id !== contactId);
                // this._entity.next(x.data);
                return x;
            }));
    }

    setIntervention(securityCommission: SecurityCommissionModel, actionId: any, intervnetion: any) {
        return this.http.put<ObjectApiInterface<SecurityCommissionModel>>(`${this.baseUrlApi}/${securityCommission._id}/setIntervention/${actionId}`, intervnetion)
            .pipe(map(x => {
                // this._entity.value.action.push(x.data);
                this._entity.next(x.data);
                return x;
            }));
    }
}


