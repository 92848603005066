import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-content-split-layout',
    template: `
      <div class="mt-6">
        <div class="flex flex-col lg:flex-row gap-6">
          <!-- Contenu principal (3/4) -->
          <div [class]="mainColumnClass">
            <ng-content select="[mainContent]"></ng-content>
          </div>
          
          <!-- Sidebar (1/4) -->
          <div [class]="sidebarColumnClass">
            <ng-content select="[sidebarContent]"></ng-content>
          </div>
        </div>
      </div>
  `
})
export class ContentSplitLayoutComponent {
  @Input() mainColumnWidth: string = '3/4';

  private validateFraction(fraction: string): boolean {
    const [numerator, denominator] = fraction.split('/').map(Number);
    return (
      !isNaN(numerator) &&
      !isNaN(denominator) &&
      numerator < denominator &&
      numerator > 0 &&
      denominator > 0
    );
  }

  get mainColumnClass(): string {
    if (!this.validateFraction(this.mainColumnWidth)) {
      console.warn(`Invalid fraction: ${this.mainColumnWidth}. Using default 3/4`);
      return 'w-full lg:w-3/4 space-y-4';
    }
    return `w-full lg:w-${this.mainColumnWidth}  space-y-4`;
  }

  get sidebarColumnClass(): string {
    if (!this.validateFraction(this.mainColumnWidth)) {
      return 'w-full lg:w-1/4 space-y-4';
    }

    const [numerator, denominator] = this.mainColumnWidth.split('/').map(Number);
    const sidebarNumerator = denominator - numerator;

    return `w-full lg:w-${sidebarNumerator}/${denominator} space-y-4`;
  }

}

