import { Component } from '@angular/core';
import { DataTableBase } from '../../../../core/base/components/data-table-base/data-table-base';
import { FeiColumn } from '../../../../core/base/interfaces/fei-column.interface';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
    EnergyIdCreateUpdateDeleteComponent
} from './energy-id-create-update-delete/energy-id-create-update-delete.component';
import { CrudModeEnum } from '../../../../core/base/enum/crud-mode.enum';
import { EnergyIdModel } from '../../../../core/models/energy-id.model';
import { EnergyIdService } from './energy-id.service';
import { MatTableDataSource } from '@angular/material/table';
import { ENUM_PERMISSIONS } from '../../../../core/enums/permission.enum';
import { AuthService } from '../../../../core/services/auth.service';


@Component({
    selector: 'vex-energy-id',
    templateUrl: './energy-id.component.html',
    styleUrls: ['./energy-id.component.scss']
})
export class EnergyIdComponent extends DataTableBase<EnergyIdModel> {

canShowArchives = false;

    feisColumns: Array<FeiColumn> = [
        {label: 'Type d\'énergie', column: 'genericEnergyId', propertyDisplay: 'genericEnergyId.energyType'},
        {label: 'ID Énergie', column: 'energyIdId'},
        {label: 'Établissement(s)', column: 'establishments', type: 'custom', width: '30%'},
        {label: 'Bâtiment(s)', column: 'buildings', type: 'custom', width: '30%'},
   //     {label: 'Bâtiment Contact Achat', column: 'favoriteBuilding', propertyDisplay: 'favoriteBuilding.label'},
        {label: 'Créé le', column: 'createdAt', type: 'date'},
        {label: 'Édité le', column: 'updatedAt', type: 'date'},
        {label: '', column: 'actions', canUpdate: true, canArchive: this.authService.hasPermission(ENUM_PERMISSIONS.INPUT_ENERGY_ID)}
    ];

    entityName = 'ID Énergie';
    ENUM_PERMISSION = ENUM_PERMISSIONS;


    constructor(
        private dialog: MatDialog,
        private router: Router,
        public service: EnergyIdService,
        private authService: AuthService
    ) {
        super();
        this.displayedColumns = this.feisColumns.map(x => x.column);
    }

    initData() {
        this.checkPermission();
        this.loading = true;
        const items = this.service
            .findAllWithFullRelation(this.pageIndex, this.pageSize, this.sortField, this.sortOrder, this.search, {
                ...this.filters,
                requiredFields: ['*'],
                isUserEnergyId: true
            })
            .subscribe(res => {
                if (!this.dataSource) {
                    this.dataSource = new MatTableDataSource<EnergyIdModel>(res.data);
                } else {
                    this.dataSource.data = res.data;
                }
                this.count = res.totalData;
                this.loading = false;
            });

        this.subscription.add(items);
    }

    createItem(): void {
        this.dialog.open(EnergyIdCreateUpdateDeleteComponent, {
            width: '500px',
            data: {
                mode: CrudModeEnum.Create,
            }
        }).afterClosed().subscribe((energyId: EnergyIdModel) => {
            if (energyId) {
                this.initData();
            }
        });
    }

    updateItem(element: any): void {
        this.dialog.open(EnergyIdCreateUpdateDeleteComponent, {
            data: {
                width: '500px',
                defaults: element,
                mode: CrudModeEnum.Update
            }
        }).afterClosed().subscribe((energyId: EnergyIdModel) => {
            if (energyId) {
                this.initData();
            }
        });
    }

    deleteItem(element: any) {
        // this.dialog.open(EquipmentCreateUpdateDeleteComponent, {
        //   data: {
        //     defaults: element,
        //     name: `${element.reference}`,
        //     mode: CrudModeEnum.Delete
        //   }
        // }).afterClosed().subscribe((equipment: EquipmentModel) => {
        //   if (equipment) {
        //     this.initData();
        //   }
        // });
    }

    importItems() {
        this.dialog.open(EnergyIdCreateUpdateDeleteComponent, {
            width: '500px',
            data: {
                mode: CrudModeEnum.Import
            }
        }).afterClosed().subscribe((energyId: EnergyIdModel[]) => {
            if (energyId) {
                this.initData();
            } else {
            }
        });
    }

    archiveItem(element: any) {
        this.dialog.open(EnergyIdCreateUpdateDeleteComponent, {
            data: {
                mode: CrudModeEnum.Archive,
                defaults: element,
            }
        }).afterClosed().subscribe((energyId: EnergyIdModel) => {
            if (energyId) {
                this.initData();
            }
        });
    }

    checkPermission() {
        this.subscription.add(
            this.authService.getCurrentUserPermissions$().subscribe(permissions => {

                if (permissions.includes(ENUM_PERMISSIONS.READ_ENERGY_ID_ARCHIVE)) {
                    this.canShowArchives = true;
                }

            })
        );
    }
}
