import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'details-skeleton-line-base',
    templateUrl: 'details-skeleton-line-base.component.html'
})

export class DetailsSkeletonLineBaseComponent implements OnInit {
    constructor() {
    }

    ngOnInit() {
    }
}
