import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {CrudModeEnum} from '../../../../../../core/base/enum/crud-mode.enum';
import icWork from '@iconify/icons-ic/twotone-work';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icPersonAdd from '@iconify/icons-ic/twotone-person-add';
import icCheck from '@iconify/icons-ic/twotone-check';
import icMail from '@iconify/icons-ic/twotone-mail';
import icAccessTime from '@iconify/icons-ic/twotone-access-time';
import icAdd from '@iconify/icons-ic/twotone-add';
import icWhatshot from '@iconify/icons-ic/twotone-whatshot';
import {BuildingModel} from '../../../../../../core/models/building.model';
import {BuildingService} from '../../building.service';
import {scaleIn400ms} from '../../../../../../../@vex/animations/scale-in.animation';
import {fadeInRight400ms} from '../../../../../../../@vex/animations/fade-in-right.animation';
import {stagger40ms} from '../../../../../../../@vex/animations/stagger.animation';
import {fadeInUp400ms} from '../../../../../../../@vex/animations/fade-in-up.animation';
import {scaleFadeIn400ms} from '../../../../../../../@vex/animations/scale-fade-in.animation';
import {
    BuildingCreateUpdateDeleteComponent
} from '../../modals/building-create-update-delete/building-create-update-delete.component';
import {FeiColumn} from '../../../../../../core/base/interfaces/fei-column.interface';
import {MatTableDataSource} from '@angular/material/table';
import {
    StageCreateUpdateDeleteComponent
} from '../../modals/stage-create-update-delete/stage-create-update-delete.component';
import {StageModel} from '../../../../../../core/models/stage.model';
import {
    BuildingEstablishmentCreateUpdateDeleteComponent
} from '../../modals/building-establishment-create-update-delete/building-establishment-create-update-delete.component';
import {EstablishmentModel} from '../../../../../../core/models/establishment.model';
import {
    BuildingActivityCreateUpdateDeleteComponent
} from '../../modals/building-activity-create-update-delete/building-activity-create-update-delete.component';
import {ENUM_PERMISSIONS} from "../../../../../../core/enums/permission.enum";
import {AddressModel} from "../../../../../../core/models/address.model";
import {PageEvent} from "@angular/material/paginator";
import {
    BuildingAddressCreateUpdateDeleteComponent
} from "../../modals/building-address-create-update-delete/building-address-create-update-delete.component";
import {AuthService} from "../../../../../../core/services/auth.service";

@Component({
    selector: 'vex-building-details-resume',
    templateUrl: './building-details-resume.component.html',
    styleUrls: ['./building-details-resume.component.scss'],
    animations: [
        scaleIn400ms,
        fadeInRight400ms,
        stagger40ms,
        fadeInUp400ms,
        scaleFadeIn400ms
    ]
})
export class BuildingDetailsResumeComponent implements OnInit {
    protected subscription = new Subscription();

    icWork = icWork;
    icPhone = icPhone;
    icPersonAdd = icPersonAdd;
    icCheck = icCheck;
    icMail = icMail;
    icAccessTime = icAccessTime;
    icAdd = icAdd;
    icWhatshot = icWhatshot;

    object: BuildingModel;
    // loading= false;
    ENUM_PERMISSION = ENUM_PERMISSIONS;
    entity: BuildingModel;

    displayEstablishmentSurfaceWarning: boolean = false;
    displayFavoriteWarning: boolean = false;
    isMainEstablishmentPresent: boolean = false;

    feisColumnsStage: Array<FeiColumn> = [
        {label: 'Libellé', column: 'label'},
        {label: 'Niveau', column: 'level'},
        {
            label: '',
            column: 'actions',
            canUpdate: this.authService.hasPermission(ENUM_PERMISSIONS.UPDATE_BUILDING_INFO_FLOOR),
            canDelete: this.authService.hasPermission(ENUM_PERMISSIONS.DELETE_BUILDING_INFO_FLOOR),
        }
    ];
    displayedColumnsStage = [];
    dataSourceStage: MatTableDataSource<any>;

    feisColumnsEstablishmentNoFavorite: Array<FeiColumn> = [
        {label: 'Libellé', column: 'label'},
        {label: 'Surface occupé par le bâtiment (m²)', column: 'surface'},
        {label: 'Nombre de lit permanent', column: 'nbOfPermanentBed'},
        {label: '', column: 'actions', canUpdate: true, canFavorite: false}
    ];

    feisColumnsEstablishmentFavorite: Array<FeiColumn> = [
        {label: 'Contact achats', column: 'favorite'},
        {label: 'Libellé', column: 'label'},
        {label: 'Surface occupé par le bâtiment (m²)', column: 'surface'},
        {label: 'Nombre de lit permanent', column: 'nbOfPermanentBed'},
        {label: '', column: 'actions', canUpdate: true, canFavorite: true}
    ];

    displayedColumnsEstablishment = [];
    dataSourceEstablishment: MatTableDataSource<any>;
    feisColumnsActivity: Array<FeiColumn> = [
        {label: 'Activité', column: 'activity', propertyDisplay: 'activity.label'},
        {label: 'Surface occupée (m²)', column: 'mc'},
        {label: '', column: 'actions'}
    ];
    displayedColumnsActivity = [];
    dataSourceActivity: MatTableDataSource<any>;
    displayActivityWarning: boolean = false;
    firstInit: boolean = true;
    displayedColumnsAddress = ['actions'];
    countAddress = 0;
    dataSourceAddress = new MatTableDataSource<AddressModel>();
    sortFieldAddress: string;
    sortOrderAddress: string;
    pageIndexAddress: number;
    pageSizeAddress: number;
    feisColumnsEstablishment: Array<FeiColumn> = [];
    feisColumnsAddress: Array<FeiColumn> = [
        {label: 'Libellé', column: 'label', propertyDisplay: null},
        {label: 'Adresse', column: 'street', propertyDisplay: null},
        {label: 'Complément d\'adress', column: 'additionalAddress', propertyDisplay: null},
        {label: 'Code postal', column: 'postalCode', propertyDisplay: null},
        {label: 'Ville', column: 'city', propertyDisplay: null},
        {label: 'Pays', column: 'country', propertyDisplay: null},
        {
            label: '',
            column: 'actions',
            canUpdate: this.authService.hasPermission(ENUM_PERMISSIONS.UPDATE_BUILDING_INFO_ADDRESS),
            canDelete: this.authService.hasPermission(ENUM_PERMISSIONS.DELETE_BUILDING_INFO_ADDRESS)
        }
    ];
    displayEstablishmentWarning: boolean = true;
    clearSelectedEl: boolean = false;
    selectedEl = [];

    routerLinkEstablishment = '/management/establishment/:id';

    constructor(public buildingService: BuildingService, private activatedRoute: ActivatedRoute, private dialog: MatDialog, private authService: AuthService) {
        this.displayedColumnsStage = this.feisColumnsStage.map(x => x.column);
        this.displayedColumnsActivity = this.feisColumnsActivity.map(x => x.column);
    }

    async ngOnInit() {
        await this.initData();
    }

    async initData() {
        this.buildingService.entity$.subscribe(entity => {

            this.entity = entity;

            this.dataSourceStage = new MatTableDataSource<any>(entity?.stages);
            this.dataSourceEstablishment = new MatTableDataSource<any>(entity?.establishments);
            this.dataSourceActivity = new MatTableDataSource<any>(entity?.activity);
            this.dataSourceAddress = new MatTableDataSource<any>(entity?.addresses);

            this.displayActivityWarning = entity?.activity.reduce((p, c) => p + c.mc, 0) !== entity?.surface;
            this.displayEstablishmentSurfaceWarning = entity?.establishments.reduce((p, c) => p + c.surface, 0) !== entity?.surface;
            this.displayEstablishmentWarning = !(entity?.establishments.length > 0);
            this.displayFavoriteWarning = entity?.establishments.length > 1;

            this.isMainEstablishmentPresent = entity.establishments.some(e => e.isMain === true);

            if (this.displayFavoriteWarning) {
                this.feisColumnsEstablishment = this.feisColumnsEstablishmentFavorite;
                this.displayedColumnsEstablishment = this.feisColumnsEstablishmentFavorite.map(x => x.column);
            } else {
                this.feisColumnsEstablishment = this.feisColumnsEstablishmentNoFavorite;
                this.displayedColumnsEstablishment = this.feisColumnsEstablishmentNoFavorite.map(x => x.column);
            }
        });
    }

    async updateItem() {
        this.dialog.open(BuildingCreateUpdateDeleteComponent, {
            data: {
                defaults: await this.buildingService.entity,
                mode: CrudModeEnum.Update
            }
        }).afterClosed().subscribe((supplier: BuildingModel) => {
            if (supplier) {
                // this.initData();
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    async createStage() {
        this.dialog.open(StageCreateUpdateDeleteComponent, {
            data: {
                building: await this.buildingService.entity,
                mode: CrudModeEnum.Create
            }
        }).afterClosed().subscribe((stage: StageModel) => {
            if (stage) {
                this.initData();
            }
        });
    }

    async updateItemStage(element: any) {
        this.dialog.open(StageCreateUpdateDeleteComponent, {
            data: {
                building: await this.buildingService.entity,
                defaults: element,
                mode: CrudModeEnum.Update
            }
        }).afterClosed().subscribe((stage: StageModel) => {
            if (stage) {
                this.initData();
            }
        });
    }

    async deleteItemStage(element: any) {
        this.dialog.open(StageCreateUpdateDeleteComponent, {
            data: {
                building: await this.buildingService.entity,
                defaults: element,
                name: `${element.label}`,
                mode: CrudModeEnum.Delete
            }
        }).afterClosed().subscribe((stageModel: StageModel) => {
            if (stageModel) {
                this.initData();
            }
        });
    }

    async addItemEstablishment() {
        this.dialog.open(BuildingEstablishmentCreateUpdateDeleteComponent, {
            data: {
                building: await this.buildingService.entity,
                mode: CrudModeEnum.Create
            }
        }).afterClosed().subscribe((stage: EstablishmentModel) => {
            if (stage) {
                this.initData();
            }
        });
    }

    async updateItemEstablishment($event: any) {
        this.dialog.open(BuildingEstablishmentCreateUpdateDeleteComponent, {
            data: {
                building: await this.buildingService.entity,
                defaults: $event,
                mode: CrudModeEnum.Update,
            }
        }).afterClosed().subscribe((establishment: EstablishmentModel) => {
            if (establishment) {
                this.initData();
            }
        });
    }

    async favoriteItemEstablishment(element: any) {
        this.buildingService.updateMainEstablishment(this.entity._id, element._id, element.isMain).subscribe(res => {
            this.initData();
        });
    }


    selectedRow(selectedData: any) {
        if (this.clearSelectedEl) this.clearSelectedEl = false;
        this.selectedEl = selectedData;
    }

    async deleteItemEstablishment(element: any) {
        this.dialog.open(BuildingEstablishmentCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                building: await this.buildingService.entity,
                mode: CrudModeEnum.Delete,
                name: `${element.label}`
            }
        }).afterClosed().subscribe((stage: EstablishmentModel) => {
            if (stage) {
                this.initData();
            }
        });
    }

    async createActivity() {
        this.dialog.open(BuildingActivityCreateUpdateDeleteComponent, {
            data: {
                building: await this.buildingService.entity,
                mode: CrudModeEnum.Create
            }
        }).afterClosed().subscribe((activity) => {
            if (activity) {
                this.initData();
            }
        });
    }

    async updateItemActivity(element: any) {
        this.dialog.open(BuildingActivityCreateUpdateDeleteComponent, {
            data: {
                building: await this.buildingService.entity,
                defaults: element,
                mode: CrudModeEnum.Update
            }
        }).afterClosed().subscribe((activity) => {
            if (activity) {
                this.initData();
            }
        });
    }

    async deleteItemActivity(element: any) {
        this.dialog.open(BuildingActivityCreateUpdateDeleteComponent, {
            data: {
                building: await this.buildingService.entity,
                defaults: element,
                name: `${element?.activity?.label}`,
                mode: CrudModeEnum.Delete
            }
        }).afterClosed().subscribe((activity) => {
            if (activity) {
                this.initData();
            }
        });
    }

    async createAddress() {
        const entity = await this.buildingService.entity;
        this.dialog.open(BuildingAddressCreateUpdateDeleteComponent, {
            data: {
                id: entity._id,
                defaults: entity,
                mode: CrudModeEnum.Create
            },
            width: '400px'
        }).afterClosed().subscribe((building: BuildingModel) => {
            if (building) {
                this.initData();
            }
        });
    }

    async updateAddress(address: any) {
        const entity = await this.buildingService.entity;
        this.dialog.open(BuildingAddressCreateUpdateDeleteComponent, {
            data: {
                id: entity._id,
                defaults: address,
                mode: CrudModeEnum.Update
            }
        }).afterClosed().subscribe((building: BuildingModel) => {
            if (building) {
                this.initData();
            }
        });
    }

    async deleteAddress(address: any) {
        const {_id} = await this.buildingService.entity;
        this.dialog.open(BuildingAddressCreateUpdateDeleteComponent, {
            data: {
                id: _id,
                defaults: address,
                name: `"type d'adresse"`,
                mode: CrudModeEnum.Delete
            }
        }).afterClosed().subscribe((res) => {
            if (res) {
                // this.initData();
            }
        });
    }

    async pageEventAddress(event: PageEvent) {
        console.log(event);
        this.pageIndexAddress = event.pageIndex + 1;
        this.pageSizeAddress = event.pageSize;
        const establishment = await this.buildingService.entity;
        // this.buildingService.findAllAddress(this.pageIndexAddress, this.pageSizeAddress, this.sortFieldAddress, this.sortOrderAddress, null, { establishmentId: establishment._id }).subscribe();
        // this.initDataAddress();
    }

    async sortEventAddress(event: any) {
        this.sortFieldAddress = event.active;
        this.sortOrderAddress = event.direction;
        const establishment = await this.buildingService.entity;
        // this.buildingService.findAllAddress(this.pageIndexAddress, this.pageSizeAddress, this.sortFieldAddress, this.sortOrderAddress, null, { establishmentId: establishment._id }).subscribe();
    }
}