<div class="mt-6 flex flex-col md:flex-row md:items-start gap-6">

   <!-- GAUCHE (Détails, qui prend le plus de place) -->
   <div class="flex-auto">
      <div id="building-resume-details-card" class="card">
         <div class='px-gutter py-4 border-b flex justify-between items-center'>
            <h2 class='title m-0'>Détails</h2>

            <button color='primary' mat-raised-button type='button'
                    (click)='updateItem()' *hasPermission="[ENUM_PERMISSION.UPDATE_BUILDING]">
               MODIFIER
            </button>
         </div>

         <div *ngIf='(buildingService.entity$ | async)?._id; else loading'
              class="px-gutter py-4"
              gdColumns="1fr 1fr" gdColumns.xs="1fr" gdGap="16px">

            <details-line-base hint="Bâtiment générique"
                               [label]="(buildingService.entity$ | async).genericBuilding?.label">
            </details-line-base>

            <details-line-base hint="Identifiant Technique"
                               [label]="(buildingService.entity$ | async).technicalIdentifier">
            </details-line-base>

            <details-line-base hint="Libellé"
                               [label]="(buildingService.entity$ | async).label | capitalize">
            </details-line-base>

            <details-line-base hint="Surface Totale (m²)"
                               [label]="(buildingService.entity$ | async).surface">
            </details-line-base>

            <details-line-base hint="Catégorie"
                               [label]="(buildingService.entity$ | async)?.type === 'main' ? 'Principal' : 'Autre'">
            </details-line-base>
         </div>

         <div class='px-gutter py-4 border-b flex flex-row justify-between items-center'>
            <h2 class='title m-0'>Statut de fonctionnement</h2>
         </div>
         <vex-history [events]="historiqueData"></vex-history>

         <div class='px-gutter py-4 border-b flex flex-row justify-between items-center'>
            <h2 class='title m-0'>Dates de dernière maintenance</h2>
         </div>
         <div class="px-gutter py-4" gdColumns="1fr 1fr" gdColumns.xs="1fr" gdGap="16px">
            <details-line-base hint='Maintenance réglementaire'
                               [label]='(buildingService.entity$ | async)?.lastRegulatoryMaintenanceDate'
                               type='date'>
            </details-line-base>
            <details-line-base hint='Maintenance non réglementaire'
                               [label]='(buildingService.entity$ | async)?.lastMaintenanceDateOther'
                               type='date'>
            </details-line-base>
         </div>
      </div>
   </div>

   <!-- DROITE (Actions + Étages, qui prend moins de place) -->
   <div class="w-full md:max-w-md flex-none">
      <div *ngIf="entity && !entity?.isDefinitivelyOver" class='card mb-3'>
         <h2 class='title m-0 mr-2 p-4 border-b'>Actions</h2>
         <div class='px-4 py-4 flex flex-col gap-6'>
            <button (click)='setEndServiceDate()'
                    class='w-full'
                    color='primary'
                    mat-raised-button>
               AJOUTER UNE DATE DE FIN DE SERVICE
            </button>
         </div>
      </div>

      <div id="building-resume-floors-card" class="card">
         <div class="px-6 py-4 border-b flex flex-row justify-between">
            <h2 class="title m-0">Étages</h2>

            <button *hasPermission="[ENUM_PERMISSION.UPDATE_BUILDING]"
                    (click)='createStage()'
                    class='ml-4'
                    color='primary'
                    mat-mini-fab
                    matTooltip='Ajouter un étage'
                    type='button'>
               <mat-icon [icIcon]='icAdd'></mat-icon>
            </button>
         </div>

         <base-data-table
            (onDeleteItem)='deleteItemStage($event)'
            (onUpdateItem)='updateItemStage($event)'
            [dataSource]='dataSourceStage'
            [displayedColumns]='displayedColumnsStage'
            [feisColumns]='feisColumnsStage'
            [showPaginator]='false'>
         </base-data-table>
      </div>

       <div id="building-resume-energies-card" class="card mt-6">
            <div class='px-gutter py-4 border-b' fxLayout='row' fxLayoutAlign='space-between center'>
                <h2 class='title m-0'>Energies</h2>

                <button color='primary' mat-raised-button type='button'
                        (click)='updateEnergyId()' *hasPermission="[ENUM_PERMISSION.UPDATE_BUILDING]">MODIFIER
                </button>
            </div>
            <div class="px-gutter py-4" gdColumns="1fr 1fr" gdColumns.xs="1fr" gdGap="16px">
                <details-line-base
                        hint="Point de livraison Electricité (PDL)"
                        [label]="(buildingService.entity$ | async)?.deliveryEnergyId?.energyIdId"
                >
                </details-line-base>
            </div>
        </div>
   </div>

</div>

<ng-container>
   <div id="building-resume-adresses-card" class="card mt-6">
      <div class='px-gutter py-4 border-b' fxLayout='row' fxLayoutAlign='space-between center'>
         <h2 class='title m-0'>Adresses
            <icon-warning
               [show]='displayEstablishmentWarning'
               [toolTip]="'Veuillez ajouter un établissement pour pouvoir ajouter une adresse.'"
               class='ml-2'
            >
            </icon-warning>
         </h2>
         <div>
            <button *hasPermission="[ENUM_PERMISSION.UPDATE_BUILDING]"
                    (click)='createAddress()'
                    class='ml-4'
                    color='primary'
                    fxFlex='none'
                    mat-mini-fab
                    matTooltip='Ajouter une adresse'
                    type='button'>
               <mat-icon [icIcon]='icAdd'></mat-icon>
            </button>
         </div>
      </div>

      <div @stagger
           class='px-gutter py-4'
           gdGap='24px'>
         <base-data-table
            (onDeleteItem)='deleteAddress($event)'
            (onPageEvent)='pageEventAddress($event)'
            (onSortEvent)='sortEventAddress($event)'
            (onUpdateItem)='updateAddress($event)'
            [count]='countAddress'
            [dataSource]='dataSourceAddress'
            [displayedColumns]='displayedColumnsAddress'
            [feisColumns]='feisColumnsAddress'
            [pageSize]='pageSizeAddress'
            [sortField]='sortFieldAddress'
            [sortOrder]='sortOrderAddress'
         ></base-data-table>
      </div>
   </div>
</ng-container>


<ng-container>
   <div id="building-resume-establishments-card" class="card mt-6">
      <div class="px-6 py-4 border-b flex flex-row justify-between">
         <h2 class="title m-0">Etablissement
            <icon-warning
               [show]='displayEstablishmentSurfaceWarning'
               [toolTip]="'La surface des établissements saisie ne correspond pas à la surface totale saisie du bâtiment. (Surface totale : ' + (buildingService.entity$ | async)?.surface + ' m²)'"
               class='ml-2'
            >
            </icon-warning>
            <icon-warning
               [show]='displayFavoriteWarning && !isMainEstablishmentPresent'
               [toolTip]="'Il y a plus de deux établissements. Assignez un établissement comme contact achats'"
               class='ml-2'
            >
            </icon-warning>
         </h2>

         <button *hasPermission="[ENUM_PERMISSION.UPDATE_BUILDING]"
                 (click)='addItemEstablishment()'
                 class='ml-4'
                 color='primary'
                 fxFlex='none'
                 mat-mini-fab
                 matTooltip='Ajouter un établissement'
                 type='button'>
            <mat-icon [icIcon]='icAdd'></mat-icon>
         </button>
      </div>

      <!-- TODO PERMISSION [deletePermission]="[ENUM_PERMISSION.DELETE_HM_BUILDING_INFO_ESTABLISHMENT]"-->
      <!--                    (onFavoriteItem)='favoriteItemEstablishment($event)'-->

      <base-data-table
         (onDeleteItem)='deleteItemEstablishment($event)'
         (onUpdateItem)='updateItemEstablishment($event)'
         (onSelectedRow)='favoriteItemEstablishment($event)'
         [dataSource]='dataSourceEstablishment'
         [displayedColumns]='displayedColumnsEstablishment'
         [feisColumns]='feisColumnsEstablishment'
         [routerLinkDetailRow]='routerLinkEstablishment'
         [showPaginator]='false'
      ></base-data-table>
   </div>
</ng-container>

 <ng-container>
    <div class="mt-6 card">
          <div class="px-gutter py-4 border-b" fxLayout='row' fxLayoutAlign='space-between center'>
             <h2 class="title m-0">Activités
                <icon-warning
                   [show]='displayActivityWarning'
                   [toolTip]="'La surface des activités saisies ne correspond pas à la surface totale saisie du bâtiment. (Surface total : ' + (buildingService.entity$ | async)?.surface + ' m²)'"
                   class='ml-2'
                >
                </icon-warning>
             </h2>

             <button *hasPermission="[ENUM_PERMISSION.UPDATE_BUILDING]"
                     (click)='createActivity()'
                     class='ml-4'
                     color='primary'
                     fxFlex='none'
                     mat-mini-fab
                     matTooltip='Ajouter une activité'
                     type='button'>
                <mat-icon [icIcon]='icAdd'></mat-icon>
             </button>
          </div>

          <!-- TODO PERMISSION [updatePermission]="[ENUM_PERMISSION.UPDATE_HM_BUILDING_INFO_ACTIVITY]"
                  [deletePermission]="[ENUM_PERMISSION.DELETE_HM_BUILDING_INFO_ACTIVITY]"-->
          <base-data-table
             (onDeleteItem)='deleteItemActivity($event)'
             (onUpdateItem)='updateItemActivity($event)'
             [dataSource]='dataSourceActivity'
             [displayedColumns]='displayedColumnsActivity'
             [feisColumns]='feisColumnsActivity'
             [showPaginator]='false'
          ></base-data-table>
    </div>
 </ng-container>


<ng-template #loading>
    <div class="px-gutter py-4" gdColumns="1fr 1fr" gdColumns.xs="1fr" gdGap="16px">
        <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
            <vex-skeleton [circle]='true' class='w-14 mr-2' heigthTailwind='h-12'></vex-skeleton>
            <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
        </div>

        <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
            <vex-skeleton [circle]='true' class='w-14 mr-2' heigthTailwind='h-12'></vex-skeleton>
            <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
        </div>

        <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
            <vex-skeleton [circle]='true' class='w-14 mr-2' heigthTailwind='h-12'></vex-skeleton>
            <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
        </div>
    </div>
</ng-template>
