import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import icDone from '@iconify/icons-ic/twotone-done';
import icSearch from '@iconify/icons-ic/twotone-search';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';
import icAdd from '@iconify/icons-ic/twotone-add';
import icClose from '@iconify/icons-ic/twotone-close';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icEdit from '@iconify/icons-ic/twotone-edit';
import { Subscription } from 'rxjs';
import { DataTableDropdownIconComponent } from '../data-table-dropdown/data-table-dropdown-icon.component';
import { ENUM_PERMISSIONS } from '../../../enums/permission.enum';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { IDynamicTemplates } from '../data-table-header-layout-base/data-table-header-layout-base.component';

@Component({
    selector: 'base-data-table-header',
    templateUrl: 'data-table-header-base.component.html'
})

export class DataTableHeaderBaseComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() headerTemplate: TemplateRef<any>;
    @Input() backgroundColorWhite = false;
    @Input() label: string;
    @Input() loading: boolean;
    @Input() canCreate = true;
    @Input() canEdit = false;
    @Input() showArchives = false;
    @Input() showExpires = false;
    @Input() showSynchronization = false;
    @Input() showCustomHeaderCheckbox = false;
    @Input() customHeaderCheckboxLabel = '';
    @Input() searchWidthFull = false;

    @Input() canImport = false;
    @Output() onSearchChange = new EventEmitter();
    @Output() onClearTextFilter = new EventEmitter();
    @Output() onCreate = new EventEmitter();
    @Output() onImport = new EventEmitter();
    @Output() onEdit = new EventEmitter();
    @Output() onShowArchived = new EventEmitter();
    @Output() onShowExpired = new EventEmitter();
    @Output() onSynchronization = new EventEmitter();
    @Output() onShowCustomHeaderCheckbox = new EventEmitter();

    @Input() createTooltip = 'Ajouter';
    @Input() editTooltip = 'Editer';
    @Input() popupFilter = false;
    @Input() headerPopupTitle: string;
    @Input() contentPopupTemplate: TemplateRef<any>;
    @Input() countPopupFilter = 0;
    @Input() popupWidth = 400;
    @Output() clearPopupFilter = new EventEmitter();
    @Output() validatePopupFilter = new EventEmitter();

    @Input() actionsTemplate: TemplateRef<any>;

    @Input() showSearch = true;
    @Input() showCloseButton = false;

    @Input() createPermission: Array<ENUM_PERMISSIONS>;
    @Input() importPermission: Array<ENUM_PERMISSIONS>;
    @Input() editPermission: Array<ENUM_PERMISSIONS>;
    @Input() templates: Array<IDynamicTemplates> = [];

    private subscription = new Subscription();

    icFilterList = icFilterList;
    icAdd = icAdd;
    icClose = icClose;
    icDone = icDone;
    icMoreVert = icMoreVert;
    icDelete = icDelete;
    icEdit = icEdit;
    icSearch = icSearch;

    showArchived = false;
    showExpired = false;
    customHeaderCheckbox = false;
    filters: any;

    @ViewChild('search') search: ElementRef;
    @ViewChild('popup') popup: DataTableDropdownIconComponent;


    searchControl: FormControl = new FormControl();

    constructor() {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.searchChange();
    }

    searchChange() {
        if (this.search?.nativeElement) {
            this.subscription.add(
                this.searchControl.valueChanges
                    .pipe(
                        map(value => {
                            return value;
                        }),
                        debounceTime(150),
                        distinctUntilChanged(),
                        tap((value) => {
                            this.onSearchChange.emit(this.search.nativeElement.value);
                        })
                    )
                    .subscribe()
            );
        }
    }

    clearTextFilter() {
        this.search.nativeElement.value = '';
        this.onClearTextFilter.emit();
    }

    createItem() {
        this.onCreate.emit();
    }

    editItem() {
        this.onEdit.emit();
    }

    importItems() {
        this.onImport.emit();
    }

    synchronization() {
        this.onSynchronization.emit();
    }

    ngOnDestroy() {
        this.popupFilter = false;
        this.subscription.unsubscribe();
    }

    clearFilter() {
        this.countPopupFilter = 0;
        this.clearPopupFilter.emit();
    }

    validateFilter() {
        this.validatePopupFilter.emit();
        this.popup.closePopover();
    }
}
