import {VexRoutes} from '../../../../@vex/interfaces/vex-route.interface';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {QuicklinkModule} from 'ngx-quicklink';
import {ProductComponent} from './product/product.component';
import {EstablishmentComponent} from './establishment/establishment.component';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {
    UserProfileDetailsResumeComponent
} from './user-profile/user-profile-details/user-profile-details-resume/user-profile-details-resume.component';
import {UserProfileDetailsComponent} from './user-profile/user-profile-details/user-profile-details.component';
import {SupplierComponent} from './supplier/supplier.component';
import {SupplierDetailsComponent} from './supplier/supplier-details/supplier-details.component';
import {
    SupplierDetailsResumeComponent
} from './supplier/supplier-details/supplier-details-resume/supplier-details-resume.component';
import {
    SupplierDetailsOtherComponent
} from './supplier/supplier-details/supplier-details-other/supplier-details-other.component';
import {
    SupplierDetailsContactComponent
} from './supplier/supplier-details/supplier-details-contact/supplier-details-contact.component';
import {PrestationComponent} from './prestation/prestation.component';
import {BuildingComponent} from './building/building.component';
import {
    BuildingDetailsResumeComponent
} from './building/building-details/building-details-resume/building-details-resume.component';
import {
    BuildingDetailsDocumentComponent
} from './building/building-details/building-details-document/building-details-document.component';
import {BuildingDetailsComponent} from './building/building-details/building-details.component';
import {EquipmentComponent} from './equipment/equipment.component';
import {ProductDetailsComponent} from './product/product-details/product-details.component';
import {
    ProductDetailsResumeComponent
} from './product/product-details/product-details-resume/product-details-resume.component';
import {EstablishmentDetailsComponent} from './establishment/establishment-details/establishment-details.component';
import {
    EstablishmentDetailsResumeComponent
} from './establishment/establishment-details/establishment-details-resume/establishment-details-resume.component';
import {
    EstablishmentDetailsAnalyticsComponent
} from './establishment/establishment-details/establishment-details-analytics/establishment-details-analytics.component';
import {
    UserProfileDetailsCommandValidatorComponent
} from './user-profile/user-profile-details/user-profile-details-command-validator/user-profile-details-command-validator.component';
import {
    EstablishmentDetailsContactComponent
} from './establishment/establishment-details/establishment-details-contact/establishment-details-contact.component';
import {
    EstablishmentDetailsActiviteComponent
} from './establishment/establishment-details/establishment-details-activite/establishment-details-activite.component';
import {
    BuildingDetailsRoomComponent
} from './building/building-details/building-details-room/building-details-room.component';
import {EquipmentDetailsComponent} from './equipment/equipment-details/equipment-details.component';
import {
    EquipmentDetailsResumeComponent
} from './equipment/equipment-details/equipment-details-resume/equipment-details-resume.component';
import {ContractComponent} from './contract/contract.component';
import {ContractDetailsComponent} from './contract/contract-details/contract-details.component';
import {
    ContractDetailsResumeComponent
} from './contract/contract-details/contract-details-resume/contract-details-resume.component';
import {
    SupplierDetailsDocumentsComponent
} from "./supplier/supplier-details/supplier-details-documents/supplier-details-documents.component";
import {
    EquipmentDetailsDocumentsComponent
} from "./equipment/equipment-details/equipment-details-documents/equipment-details-documents.component";
import {
    EquipmentDetailsServiceBookComponent
} from './equipment/equipment-details/equipment-details-service-book/equipment-details-service-book.component';
import {
    EquipmentDetailsContractComponent
} from './equipment/equipment-details/equipment-details-contract/equipment-details-contract.component';
import {
    UserProfileDetailsRolesComponent
} from "./user-profile/user-profile-details/user-profile-details-roles/user-profile-details-roles.component";
import {PermissionGuard} from "../../../core/guards/permission.guard";
import {ENUM_PERMISSIONS} from "../../../core/enums/permission.enum";
import {
    UserProfileDetailsTechnicalAssignationComponent
} from "./user-profile/user-profile-details/user-profile-details-technical-assignation/user-profile-details-technical-assignation.component";
import {
    EstablishmentDetailsBuildingsComponent
} from "./establishment/establishment-details/establishment-details-buildings/establishment-details-buildings.component";
import {
    SupplierDetailsClientNumberComponent
} from "./supplier/supplier-details/supplier-details-code/supplier-details-client-number.component";
import {
    EstablishmentDetailsValidatorsComponent
} from "./establishment/establishment-details/establishment-details-validators/establishment-details-validators.component";
import {
    ContractDetailsPerimeterOldComponent
} from "./contract/contract-details/contract-details-perimeter-old/contract-details-perimeter-old.component";
import {
    ContractDetailsCgaComponent
} from "./contract/contract-details/contract-details-cga/contract-details-cga.component";
import {
    ContractDetailsCatalogComponent
} from "./contract/contract-details/contract-details-catalog/contract-details-catalog.component";
import {
    ContractDetailsBasketContractComponent
} from "./contract/contract-details/contract-details-basket-contract/contract-details-basket-contract.component";
import {EnergyIdComponent} from "./energy-id/energy-id.component";
import {
    ContractDetailsPerimeterComponent
} from "./contract/contract-details/contract-details-perimeter/contract-details-perimeter.component";

const routes: VexRoutes = [
    {
        path: '',
        redirectTo: 'establishment',
        pathMatch: 'full',
    },
    {
        path: 'user-profile',
        component: UserProfileComponent,
        data: {
            toolbarShadowEnabled: true,
            containerEnabled: true
        },
    },
    {
        path: 'user-profile/:id',
        component: UserProfileDetailsComponent,
        canActivate: [PermissionGuard],
        data: {

            permission: ENUM_PERMISSIONS.READ_CP_INTERNAL_CONTACT_INFO,
            toolbarShadowEnabled: true,
            containerEnabled: true
        },
        children: [
            {
                path: '',
                component: UserProfileDetailsResumeComponent,
            },
            {
                path: 'command-validator',
                component: UserProfileDetailsCommandValidatorComponent,
                canActivate: [PermissionGuard],
                data: {
                    permission: ENUM_PERMISSIONS.READ_CP_INTERNAL_CONTACT_ORDER_VALIDATION
                }
            },
            {
                path: 'technical-assignation',
                component: UserProfileDetailsTechnicalAssignationComponent,
                canActivate: [PermissionGuard],
                data: {
                    permission : ENUM_PERMISSIONS.READ_CP_INTERNAL_CONTACT_PERIMETER
                }


            },
            {
                path: 'roles',
                component: UserProfileDetailsRolesComponent,
            }
        ]
    },
    {
        path: 'establishment',
        component: EstablishmentComponent,
        canActivate: [PermissionGuard],
        data: {

            permission: ENUM_PERMISSIONS.READ_CP_ESTABLISHMENT,
            toolbarShadowEnabled: true,
            containerEnabled: true
        },
    },
    {
        path: 'establishment/:id',
        component: EstablishmentDetailsComponent,
        canActivate: [PermissionGuard],
        data: {
            permission: ENUM_PERMISSIONS.READ_CP_ESTABLISHMENT_INFO,
            toolbarShadowEnabled: true,
            containerEnabled: true
        },
        children: [
            {
                path: '',
                component: EstablishmentDetailsResumeComponent,
            },
            {
                path: 'contact',
                component: EstablishmentDetailsContactComponent,
                canActivate: [PermissionGuard],
                data: {

                    permission: ENUM_PERMISSIONS.READ_CP_ESTABLISHMENT_INTERNAL_CONTACT
                }
            },
            {
                path: 'activite',
                component: EstablishmentDetailsActiviteComponent,
                canActivate: [PermissionGuard],
                data: {

                    permission: ENUM_PERMISSIONS.READ_CP_ESTABLISHMENT_ACTIVITY
                }
            },
            {
                path: 'analytics',
                component: EstablishmentDetailsAnalyticsComponent,
                canActivate: [PermissionGuard],
                data: {

                    permission: ENUM_PERMISSIONS.READ_CP_ESTABLISHMENT_ANALYTIC_PLAN
                }
            },
            {
                path: 'buildings',
                component: EstablishmentDetailsBuildingsComponent,
            },
            {
                path: 'validators',
                component: EstablishmentDetailsValidatorsComponent,
            },
            {
                path: 'validators/:id',
                component: EstablishmentDetailsValidatorsComponent,
            }
        ]
    },
    {
        path: 'energy-id',
        component: EnergyIdComponent,
        data: {
            toolbarShadowEnabled: true,
            containerEnabled: true
        },
    },
    {
        path: 'building',
        component: BuildingComponent,
        canActivate: [PermissionGuard],
        data: {

            permission: ENUM_PERMISSIONS.READ_BUILDING,
            toolbarShadowEnabled: true,
            containerEnabled: true
        },
    },
    {
        path: 'building/:id',
        component: BuildingDetailsComponent,
        canActivate: [PermissionGuard],
        data: {

            permission: ENUM_PERMISSIONS.READ_BUILDING_INFO,
            toolbarShadowEnabled: true,
            containerEnabled: true
        },
        children: [
            {
                path: '',
                component: BuildingDetailsResumeComponent,
            },
            {
                path: 'room',
                component: BuildingDetailsRoomComponent,
                canActivate: [PermissionGuard],
                data: {

                    permission: ENUM_PERMISSIONS.READ_BUILDING_ROOM
                }
            },
            {
                path: 'document',
                component: BuildingDetailsDocumentComponent,
                canActivate: [PermissionGuard],
                data: {
                    // pas d'enumeration dans le fichier excel, il faut en mettre une
                    permission: ENUM_PERMISSIONS.READ_BUILDING_INFO,
                }
            }
        ]
    },
    {
        path: 'product',
        component: ProductComponent,
        data: {
            toolbarShadowEnabled: true,
            containerEnabled: true,
            permission: ENUM_PERMISSIONS.READ_FM_REF_PURCHASE
        },
    },
    {
        path: 'product/:id',
        component: ProductDetailsComponent,
        data: {
            toolbarShadowEnabled: true,
            containerEnabled: true
        },
        children: [
            {
                path: '',
                component: ProductDetailsResumeComponent,
            }
        ]
    },
    {
        path: 'prestation',
        component: PrestationComponent,
        data: {
            toolbarShadowEnabled: true,
            containerEnabled: true,
            permission: ENUM_PERMISSIONS.READ_FM_REF_PURCHASE_SERVICE
        },
    },
    {
        path: 'equipment',
        component: EquipmentComponent,
        canActivate: [PermissionGuard],
        data: {

            permission: ENUM_PERMISSIONS.READ_EQUIPMENT,
            toolbarShadowEnabled: true,
            containerEnabled: true
        }
    },
    {
        path: 'equipment/:id',
        component: EquipmentDetailsComponent,
        canActivate: [PermissionGuard],
        data: {

            permission: ENUM_PERMISSIONS.READ_EQUIPMENT_INFO,
            toolbarShadowEnabled: true,
            containerEnabled: true
        },
        children: [
            {
                path: '',
                component: EquipmentDetailsResumeComponent,
            },
            {
                path: 'contract',
                component: EquipmentDetailsContractComponent,
                canActivate: [PermissionGuard],
                data: {
                    permission: ENUM_PERMISSIONS.READ_EQUIPMENT_INFO_EQUIPMENT
                }
            },
            {
                path: 'service-book',
                component: EquipmentDetailsServiceBookComponent,
                canActivate: [PermissionGuard],
                data: {
                    permission: ENUM_PERMISSIONS.READ_EQUIPMENT_MAINTENANCE_BOOK_INTERVENTION
                }
            },
            {
                path: 'documents',
                component: EquipmentDetailsDocumentsComponent,
                canActivate: [PermissionGuard],
                data: {

                    permission: ENUM_PERMISSIONS.READ_EQUIPMENT,
                    toolbarShadowEnabled: true,
                    containerEnabled: true
                }
            }
        ]
    },
    {
        path: 'supplier',
        component: SupplierComponent,
        data: {
            toolbarShadowEnabled: true,
            containerEnabled: true
        },
    },
    {
        path: 'supplier/:id',
        component: SupplierDetailsComponent,
        canActivate: [PermissionGuard],
        data: {
            toolbarShadowEnabled: true,
            containerEnabled: true,
            permission: ENUM_PERMISSIONS.ACCESS_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_INFO
        },
        children: [
            {
                path: '',
                component: SupplierDetailsResumeComponent,
            },
            {
                path: 'other',
                component: SupplierDetailsOtherComponent,
            },
            {
                path: 'contact',
                component: SupplierDetailsContactComponent,
            },
            {
                path: 'document',
                component: SupplierDetailsDocumentsComponent,
            },
            {
                path: 'client-number',
                component: SupplierDetailsClientNumberComponent,
            }
        ]
    },
    {
        path: 'engagement',
        component: ContractComponent,
        data: {
            toolbarShadowEnabled: true,
            containerEnabled: true
        },
    },
    {
        path: 'engagement/:id',
        component: ContractDetailsComponent,
        data: {
            toolbarShadowEnabled: true,
            containerEnabled: true
        },
        children: [
            {
                path: '',
                component: ContractDetailsResumeComponent,
            },
            {
                path: 'perimeter',
                component: ContractDetailsPerimeterComponent
            },
            {
                path: 'cga',
                component: ContractDetailsCgaComponent
            },
            {
                path: 'catalog',
                component: ContractDetailsCatalogComponent
            },
            {
                path: 'basket-contract',
                component: ContractDetailsBasketContractComponent
            }
        ]
    },
    // {
    //     path: 'user/:id',
    //     component: UserDetailsComponent,
    //     data: {
    //         toolbarShadowEnabled: true
    //     },
    //     children: [
    //         {
    //             path: '',
    //             component: UserDetailsResumeComponent
    //         },
    //         {
    //             path: 'other',
    //             component: UserDetailsOtherComponent
    //         }
    //     ]
    // },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule, QuicklinkModule]
})
export class ManagementRoutingModule {
}
