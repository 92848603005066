<ng-container *ngIf="isCreateMode() || isUpdateMode()">
    <form (ngSubmit)="save()" [formGroup]="form">
        <base-form-header [mode]='mode' createLabel='Ajouter une salle'
                          [updateLabel]='form.get("label").value'></base-form-header>

        <mat-dialog-content fxLayout="column">

            <base-form-input
                    label='Libellé'
                    controlName='label'>
            </base-form-input>

            <base-form-input
                    label='Etablissement'
                    type='autocomplete'
                    [items$]='buildingEstablishment$'
                    displayProperty='label'
                    controlName='establishment'>
            </base-form-input>

            <base-form-input
                    label='Etage'
                    type='autocomplete'
                    [items$]='stage$'
                    displayProperty='label'
                    controlName='stage'>
            </base-form-input>

            <!--            <base-form-input-->
            <!--                label='Catégorie de salle'-->
            <!--                type='autocomplete'-->
            <!--                [items$]='roomCategory$'-->
            <!--                (autocompleteChange)='roomCategoryAutocompleteChange($event)'-->
            <!--                displayProperty='label'-->
            <!--                controlName='roomCategory'>-->
            <!--            </base-form-input>-->

            <base-form-input
                    label='Salle générique'
                    type='autocomplete'
                    [items$]='genericRoom$'
                    (autocompleteChange)='genericRoomAutocomplete($event)'
                    displayProperty='label'
                    controlName='genericRoom'>
            </base-form-input>

            <base-form-input
                    label='Surface (m²)'
                    type='number'
                    controlName='surface'>
            </base-form-input>

            <base-form-input
                    *ngIf="displayNbOfBed"
                    label='Nombre de lits'
                    type='number'
                    controlName='nbOfBed'>
            </base-form-input>

        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <base-form-input
                    type='submit'
                    [mode]='mode'
                    [loading]='loading'
                    entityLabel='BUILDING'
                    (onCreate)='createItem()'
                    (onUpdate)='updateItem()'
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>

<ng-container *ngIf="isDeleteMode()">
    <form-modal-delete-base
            [label]='data.name'
            [loading]='loading'
            (onClose)='close()'
            (onDelete)='deleteItem()'
    >
    </form-modal-delete-base>
</ng-container>


<ng-container *ngIf="isImportMode()">
    <vex-form-import-base
            [modalTitle]='"Importer des salles"'
            [entityLabel]='"Salle"'
            [form]='importForm'
            [loading]='loading'
            [service]='service'
            [mode]='mode'
            (saveForm)='save()'
            (importItems)='importItems()'
            [customExport]='true'
            (customExportItems)='exportRoomModel()'
            [canDownloadTemplateModel]="canDownloadTemplateModel"
    ></vex-form-import-base>
</ng-container>
