import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BuildingModel } from 'src/app/core/models/building.model';
import { BuildingService } from '../../building.service';
import { FormModalBaseComponent } from 'src/app/core/base/components/form-modal-base/form-modal-base.component';
import icClose from '@iconify/icons-ic/twotone-close';
import { StageModel } from '../../../../../../core/models/stage.model';
import { CrudModeEnum } from '../../../../../../core/base/enum/crud-mode.enum';


@Component({
    selector: 'vex-stage-create-update',
    templateUrl: './stage-create-update-delete.component.html'
})
export class StageCreateUpdateDeleteComponent implements OnInit {
    form: FormGroup;
    mode: CrudModeEnum;

    icClose = icClose;

    loading = false;
    defaults: StageModel;
    successCreateMessage: string;
    successUpdateMessage: string;
    successDeleteMessage: string;
    errorCreateMessage: string;
    errorUpdateMessage: string;
    errorDeleteMessage: string;

    CrudModeEnum = CrudModeEnum;


    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public dialogRef: MatDialogRef<FormModalBaseComponent<BuildingModel>>,
                public service: BuildingService) {
    }

    ngOnInit() {
        this.mode = this.data.mode;
        this.defaults = this.data.defaults;
        this.form = new FormGroup({
            _id: new FormControl(this.defaults?._id),
            label: new FormControl(this.defaults?.label, [Validators.required]),
            level: new FormControl(this.defaults?.level, [Validators.required])
        });
    }

    isCreateMode() {
        return this.mode === CrudModeEnum.Create;
    }

    isUpdateMode() {
        return this.mode === CrudModeEnum.Update;
    }

    isDeleteMode() {
        return this.mode === CrudModeEnum.Delete;
    }

    save() {
        if (this.isCreateMode()) {
            this.createItem();
        } else if (this.isUpdateMode()) {
            this.updateItem();
        }
    }

    createItem() {
        if (this.form.valid) {
            this.service.createStage(this.data.building._id, this.form.getRawValue()).subscribe(
                res => {
                    this.close(res.data);
                }, err => {
                }
            );
        }
    }

    updateItem() {
        if (this.form.valid) {
            this.service.updateStage(this.data.building._id, this.form.getRawValue()).subscribe(
                res => {
                    this.close(res.data);
                }, err => {
                }
            );
        }
    }

    deleteItem() {
        if (this.defaults) {
            this.service.deleteStage(this.data.building._id, this.defaults._id).subscribe(
                res => {
                    this.close(res);
                }, err => {
                }
            );
        }
    }

    close(res) {
        this.dialogRef.close(res);
    }
}
