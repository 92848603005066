import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {scaleIn400ms} from '../../../../../../../@vex/animations/scale-in.animation';
import {fadeInRight400ms} from '../../../../../../../@vex/animations/fade-in-right.animation';
import {stagger40ms} from '../../../../../../../@vex/animations/stagger.animation';
import {fadeInUp400ms} from '../../../../../../../@vex/animations/fade-in-up.animation';
import {scaleFadeIn400ms} from '../../../../../../../@vex/animations/scale-fade-in.animation';
import {fromEvent, Observable, Subscription} from 'rxjs';
import icPersonAdd from '@iconify/icons-ic/twotone-person-add';
import icContacts from '@iconify/icons-ic/twotone-contacts';
import {CrudModeEnum} from '../../../../../../core/base/enum/crud-mode.enum';
import {MatDialog} from '@angular/material/dialog';
import {
  SupplierContactCreateUpdateDeleteComponent
} from '../../modals/supplier-contact-create-update-delete/supplier-contact-create-update-delete.component';
import {SupplierService} from '../../supplier.service';
import {Router} from '@angular/router';
import {debounceTime, distinctUntilChanged, map, tap} from 'rxjs/operators';
import icSearch from '@iconify/icons-ic/twotone-search';
import icClose from '@iconify/icons-ic/twotone-close';
import {ENUM_PERMISSIONS} from "../../../../../../core/enums/permission.enum";
import {SupplierContactModel} from "../../../../../../core/models/supplier-contact.model";
import {isHiddenFromScreenReader} from "codelyzer/util/isHiddenFromScreenReader";
import {SupplierContactService} from "../../supplier-contact.service";


@Component({
  selector: 'vex-supplier-details-contact',
  templateUrl: './supplier-details-contact.component.html',
  styleUrls: ['./supplier-details-contact.component.scss'],
  animations: [
    scaleIn400ms,
    fadeInRight400ms,
    stagger40ms,
    fadeInUp400ms,
    scaleFadeIn400ms
  ]
})
export class SupplierDetailsContactComponent implements OnInit, AfterViewInit, OnDestroy {
  ENUM_PERMISSION = ENUM_PERMISSIONS;

  private subscription = new Subscription();

  icClose = icClose;
  icSearch = icSearch;
  icPersonAdd = icPersonAdd;
  icContacts = icContacts;
  contacts$: Observable<Array<any>>;
  contacts = [];
  loading = false;

  supplierId: string;

  // importForm: FormGroup;
  // importDefaults: any;
  // snackbarService: SnackbarService;
  //
  // form: FormGroup;
  //
  // successCreateMessage: string;
  // successUpdateMessage: string;
  // successDeleteMessage: string;
  // errorCreateMessage: string;
  // errorUpdateMessage: string;
  // errorDeleteMessage: string;

  @ViewChild('search') search: ElementRef;

  constructor(private dialog: MatDialog, public supplierService: SupplierService, private router: Router, private supplierContactservice :SupplierContactService) { }

  ngOnInit(): void {
    this.initData();
  }

  ngAfterViewInit() {
    this.subscription.add(
        fromEvent(this.search.nativeElement, 'keyup')
            .pipe(
                map(value => {
                  return value;
                }),
                debounceTime(150),
                distinctUntilChanged(),
                tap((value) => {
                  this.supplierService.searchContacts(this.search.nativeElement.value);
                  // this.onSearchChange.emit(this.search.nativeElement.value);
                })
            )
            .subscribe()
    );
  }

  initData() {
    this.supplierService.searchContacts('');
  }

  openContact(contact: any) {
    const sub = this.dialog.open(SupplierContactCreateUpdateDeleteComponent, {
      data: {
        defaults: contact,
        mode: CrudModeEnum.Read
      }
    }).afterClosed().subscribe((res) => {
      if (res) {
        // this.initData();
      }
    });
    this.subscription.add(sub)
  }

  async createContact() {
    const { _id } = await this.supplierService.entity;
    const sub = this.dialog.open(SupplierContactCreateUpdateDeleteComponent, {
      data: {
        id: _id,
        mode: CrudModeEnum.Create
      }
    }).afterClosed().subscribe((res) => {
      if (res) {
        // this.initData();
      }
    });
    this.subscription.add(sub)
  }

  importItem() {
    const sub = this.dialog.open(SupplierContactCreateUpdateDeleteComponent, {
      data: {
        mode: CrudModeEnum.Import
      }
    }).afterClosed().subscribe((supplier: SupplierContactModel) => {
      if (supplier) {
        this.initData();
      }
    });
  this.subscription.add(sub)
  }

  async editContact(contact) {
    const { _id } = await this.supplierService.entity;
    const sub = this.dialog.open(SupplierContactCreateUpdateDeleteComponent, {
      data: {
        id: _id,
        defaults: contact,
        mode: CrudModeEnum.Update
      }
    }).afterClosed().subscribe((res) => {
      if (res) {
        this.initData();
      }
    });
    this.subscription.add(sub)
  }

  async deleteContact(contact: any) {
    const { _id } = await this.supplierService.entity;
    const sub = this.dialog.open(SupplierContactCreateUpdateDeleteComponent, {
      data: {
        id: _id,
        defaults: contact,
        name: `${contact.firstName} ${contact.lastName}`,
        mode: CrudModeEnum.Delete
      }
    }).afterClosed().subscribe((res) => {
      if (res) {
        // this.initData();
      }
    });
    this.subscription.add(sub)
  }

  clearTextFilter() {
    this.search.nativeElement.value = '';
    this.supplierService.searchContacts('');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


  protected readonly isHiddenFromScreenReader = isHiddenFromScreenReader;
    protected readonly ENUM_PERMISSIONS = ENUM_PERMISSIONS;
}
