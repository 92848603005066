import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import icClose from '@iconify/icons-ic/twotone-close';
import icSearch from '@iconify/icons-ic/twotone-search';
import {FormControl} from '@angular/forms';


export interface node {
    _id: string;
    name: string;
    children?: node[];
    selected?: boolean;
    indeterminate?: boolean;
    parent?: node;
    massSelectId?: string;
    match?: boolean;
}

@Component({
    selector: 'vex-tree-checkbox',
    templateUrl: './tree-checkbox.component.html',
    styleUrls: ['./tree-checkbox.component.scss'],
    encapsulation: ViewEncapsulation.None,

})
export class TreeCheckboxComponent implements OnInit {


    // public _rawData: any[];

    @Output() onSelectedItem = new EventEmitter<any>();
    @Output() onUnSelectedItem = new EventEmitter<any>();

    @Input() node: node[] = [];
    @Input() hasSearchBar = true;
    @Input() readMode = true; // Ajout de l'Input readMode
    @Input() set massSelectId(value: string) {
        this.selectNodesBasedOnMassSelectId(value);
    }

    public treeControl = new NestedTreeControl<any>(node => node.children);
    public dataSource: MatTreeNestedDataSource<node> = new MatTreeNestedDataSource<node>();
    public searchControl: FormControl = new FormControl();
    public icClose = icClose;
    public icSearch = icSearch;
    public selectAllButtonLabel = 'Tout sélectionner';

    constructor() {

    }

    hasChild = (_: number, node: node) => !!node.children && node.children.length > 0;

    selectNodesBasedOnMassSelectId(id: string) {
        this.recursivelySelectNodes(this.dataSource.data, id);
        this.treeControl.dataNodes = this.dataSource.data; // Make sure to update the treeControl dataNodes if they are changed
        // Update the tree view
        this.treeControl.expandAll(); // If you want to expand all nodes to reveal selected ones
    }

// Recursive function to select nodes based on the massSelectId
    private recursivelySelectNodes(nodes: node[], id: string) {
        nodes.forEach(node => {
            if (node.children && node.children.length > 0) {
                // Recursive call for children
                this.recursivelySelectNodes(node.children, id);
            } else {
                // If the node is an equipment node, check the massSelectId
                if (node.massSelectId === id) {
                    this.itemToggle(true, node);

                }
            }
        });
    }


    ngOnInit(): void {
        this.dataSource.data = this.node;
        this.treeControl.dataNodes = this.node;
        this.treeControl.collapseAll();

        // Souscrire aux changements de la barre de recherche
        this.searchControl.valueChanges.subscribe((value) => {
            this.filterNodes(value);
        });

        // On défini le label du bouton "Tout sélectionner" en fonction de l'état de sélection des nœuds
        this._updateSelectAllButtonLabel();
    }

    markNodes(node: node[], value: string, isAncestorMatch: boolean): boolean {
        let isCurrentMatch = false;

        for (const n of node) {
            const isMatch = n.name.toLowerCase().includes(value);
            const isDescendantMatch = n.children ? this.markNodes(n.children, value, isMatch || isAncestorMatch) : false;

            // Marquer le nœud si lui-même, un ancêtre ou un descendant correspond
            n.match = isMatch || isDescendantMatch || isAncestorMatch;
            isCurrentMatch = isCurrentMatch || isMatch || isDescendantMatch;
        }
        return isCurrentMatch;
    }


    filterTree(node: node[]): node[] {
        return node
            .filter(n => n.match) // Filtrer pour inclure que les nœuds marqués
            .map(n => ({
                ...n,
                children: n.children ? this.filterTree(n.children) : []
            }));
    }

    filterNodes(value: string): void {
        if (!value) {
            this.restoreTree();
        } else {
            value = value.toLowerCase();
            this.markNodes(this.node, value, false);
            const nodeFiltered = this.filterTree(this.node);

            this.dataSource.data = this.dataSource.data.filter((node) => {
                return nodeFiltered.find(filtered => filtered.name === node.name);
            });
            this.treeControl.dataNodes = this.dataSource.data;
            this.treeControl.expandAll();
        }
    }

    // filterNodes(value: string): void {
    //     if (!value) {
    //         this.restoreTree();
    //     } else {
    //         value = value.toLowerCase();
    //         // Filtrer les nœuds en conservant les enfants des nœuds correspondants
    //         const nodeFiltered = this.filterRecursive(this.node, value, false);
    //         this.dataSource.data = this.dataSource.data.ticket-filter((node) => {
    //             return nodeFiltered.find(filtered=> filtered.name === node.name);
    //         })
    //
    //         this.treeControl.dataNodes = this.dataSource.data;
    //         // Expansion des nœuds après le filtrage pour montrer tous les nœuds correspondants
    //         this.treeControl.expandAll();
    //     }
    // }
    //
    // filterRecursive(node: node[], value: string, parentMatch: boolean): node[] {
    //     let filteredNodes: node[] = [];
    //
    //     for (const n of node) {
    //         // Si le nœud ou le parent correspond au terme de recherche, ou si l'un des enfants correspond
    //         let match = n.name.toLowerCase().includes(value);
    //         if (match || parentMatch) {
    //             // Inclure le nœud parent avec tous ses enfants
    //             filteredNodes.push({...n, children: n.children ? this.filterRecursive(n.children, value, match || parentMatch) : []});
    //         } else if (n.children) {
    //             // Si le nœud ne correspond pas, mais a des enfants, vérifiez si les enfants doivent être inclus
    //             let children = this.filterRecursive(n.children, value, match);
    //             if (children.length > 0) {
    //                 // Si des enfants doivent être inclus, incluez le nœud avec ces enfants
    //                 filteredNodes.push({...n, children});
    //             }
    //         }
    //     }
    //     return filteredNodes;
    // }


    restoreTree(): void {
        this.dataSource.data = this.node;
        this.treeControl.dataNodes = this.node;
        this.treeControl.collapseAll();
    }


    itemToggle(checked: boolean, node: node): void {

        node.indeterminate = false;
        node.selected = checked;

        if (node.children) {
            // Si le nœud a des enfants, récursivement définir leur état de sélection pour correspondre à celui du nœud parent
            this.setChildrenState(node, checked);
        }

        // Vérifiez et mettez à jour l'état indéterminé des parents si nécessaire
        if (node.parent) {
            this.checkParentState(node.parent);
        }


        if (checked) {
            this.onSelectedItem.emit(this.findRootParent(node));

        } else {
            this.onUnSelectedItem.emit(this.findRootParent(node));
        }
        // Émettre un événement avec le nœud modifié si nécessaire

        // Mettre à jour le label du bouton "Tout sélectionner"
        this._updateSelectAllButtonLabel();
    }

    // Fonction pour trouver le dernier parent (la racine de l'arbre pour ce nœud)
    private findRootParent(node: node): node {
        while (node.parent) {
            node = node.parent;
        }
        return node;
    }

// Fonction récursive pour définir l'état de sélection des enfants
    private setChildrenState(node: node, checked: boolean): void {
        if (node.children) {
            node.children.forEach(child => {
                child.selected = checked;
                this.setChildrenState(child, checked); // récursion sur les enfants
            });
        }
    }

// Fonction pour vérifier et mettre à jour l'état indéterminé des parents
    private checkParentState(node: node): void {
        let allSelected = true;
        let allDeselected = true;
        for (const child of node.children) {

            if (!child.selected && !child.indeterminate) {
                allSelected = false;
            } else {
                allDeselected = false;
            }
        }


        if (allSelected) {
            node.selected = true;
            node.indeterminate = false;
        } else if (allDeselected) {
            node.selected = false;
            node.indeterminate = false;
        } else {
            node.selected = false;
            node.indeterminate = true;
        }

        if (node.parent) {
            this.checkParentState(node.parent); // récursion sur le parent
        }
    }

    toggleAll() {
        // check if all nodes are selected
        let allSelected = this.treeControl.dataNodes.every(node => node.selected);

        this.treeControl.dataNodes.forEach(node => {
            this.itemToggle(!allSelected, node);
        });

        this._updateSelectAllButtonLabel();
    }

    private _updateSelectAllButtonLabel(): void {
        const allSelected = this.treeControl.dataNodes.every(node => node.selected);
        this.selectAllButtonLabel = allSelected ? 'Tout désélectionner' : 'Tout sélectionner';
    }
}
