
import { RandomUtils } from '../utils/random-utils';

export enum OrderStatus {
  DRAFT = 'Précommande',
  PENDING = 'En validation',
  ACCEPTED = 'Validée',
  DECLINED = 'Rejetée',
  COMPLIANT = 'Réception conforme',
  PARTIAL = 'Réception partielle',
  NOCOMPLIANT = 'Réception non conforme',
  REGULARIZATION = 'Régularisation',
  EXPIRED = 'Expirée',
  CANCELED = 'Annulée',
  LITIGATION = 'Litige',
}

export enum OrderType {
  ORDER = 'Commande',
  BCR = 'BCR'
}

export const orderTypeTranslation = (type: OrderType): string => {
    switch (type) {
        case OrderType.ORDER:
          return 'Commande';
        case OrderType.BCR:
          return 'BCR';
        default:
          return null;
    }
};

export const orderTypeTranslationObject = (type: OrderType) => {
    return {
        _id: RandomUtils.generateId(),
        value: type,
        label: orderTypeTranslation(type)
    };
};

export const orderStatusTranslation = (status: OrderStatus): string => {
  switch (status) {
    case OrderStatus.DRAFT:
      return 'Précommande';
    case OrderStatus.PENDING:
      return 'En validation';
    case OrderStatus.ACCEPTED:
      return 'Validée';
    case OrderStatus.DECLINED:
      return 'Rejetée';
    case OrderStatus.COMPLIANT:
      return 'Réception conforme';
    case OrderStatus.PARTIAL:
      return 'Réception partielle';
    case OrderStatus.NOCOMPLIANT:
      return 'Réception non conforme';
    case OrderStatus.REGULARIZATION:
      return 'Régularisation';
    case OrderStatus.EXPIRED:
      return 'Expirée';
    case OrderStatus.LITIGATION:
      return 'Litige';
    default:
      return 'Annulée';
  }
};

export const orderStatusTranslationObject = (status: OrderStatus) => {
  return {
    _id: RandomUtils.generateId(),
    value: status,
    label: orderStatusTranslation(status)
  };
};
