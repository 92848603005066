import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BaseService} from 'src/app/core/base/base.service';
import {map, tap} from 'rxjs/operators';
import {ObjectApiInterface} from '../../../../core/base/interfaces/object-api.interface';
import {
    BillAnalyticAxisModel,
    BillBasketModel,
    BillComptableAccountModel,
    BillModel
} from 'src/app/core/models/bill.model';
import {BehaviorSubject, Observable} from "rxjs";
import {BillStatus} from "../../../../core/enums/bill-status.enum";
import {OrderRegularizationTypeEnum} from "../../../../core/enums/order-regularization-type.enum";
import {ExportedRows} from "../../../../core/models/accounting-export.model";

@Injectable({
    providedIn: 'root'
})
export class BillService extends BaseService<BillModel> {
    defaultSort = 'technicalIdentifier';

    protected _basket = new BehaviorSubject<BillBasketModel>({products: [], prestations: []});
    readonly basket$ = this._basket.asObservable();

    protected _comptableAccounts = new BehaviorSubject<BillComptableAccountModel[]>(null);
    readonly comptableAccounts$ = this._comptableAccounts.asObservable();

    constructor(public http: HttpClient) {
        super(http, 'bill');
    }

    findById(id: string): Observable<ObjectApiInterface<BillModel>> {
        return super.findById(id)
            .pipe(tap(response => {
                this._entity.next(response.data);
                this._basket.next(response.data.basket);
            }));
    }

    validateBillReceptions(billId: string, value: boolean) {
        return this.http.get<ObjectApiInterface<BillModel>>(`${this.baseUrlApi}/validateBillReceptions/${billId}/${value}`, {}).pipe(map(x => {
            this._entity.next(x.data);
            return x;
        }));
    }

    removeLitigation(billId: string) {
        return this.http.get<ObjectApiInterface<BillModel>>(`${this.baseUrlApi}/removeLitigation/${billId}`, {}).pipe(map(x => {
            this._entity.next(x.data);
            return x;
        }));
    }

    addApprover(billId: string, approver: any) {
        return this.http.put<ObjectApiInterface<BillModel>>(`${this.baseUrlApi}/${billId}/addApprover`, approver).pipe(map(x => {
            this._entity.next(x.data);
            return x;
        }));
    }

    updateApprover(billId: string, approver: any) {
        return this.http.put<ObjectApiInterface<BillModel>>(`${this.baseUrlApi}/${billId}/updateApprover`, approver).pipe(map(x => {
            this._entity.next(x.data);
            return x;
        }));
    }

    addLine(data: any, _id: string) {
        return this.http.post<ObjectApiInterface<BillModel>>(`${this.baseUrlApi}/addLine/${_id}`, data)
            .pipe(map(x => {
                this._entity.next(x.data);
                return x;
            }));
    }

    getBillsByReception(receptionId: string): Observable<BillModel[]> {
        return this.http.get<ObjectApiInterface<BillModel[]>>(`${this.baseUrlApi}/getBillsByReception/${receptionId}`).pipe(map(x => x.data));
    }

    // add a new bill to the reception
    addReception(_id: string, reception: any) {
        return this.http.post<ObjectApiInterface<BillModel>>(`${this.baseUrlApi}/addReception/${_id}`, reception).pipe(map(x => {
            this._entity.next(x.data);
            return x;
        }));
    }

    addEstablishement(id: string, data: any) {
        return this.http.post<ObjectApiInterface<BillModel>>(`${this.baseUrlApi}/addEstablishement/${id}`, data).pipe(map(x => {
            this._entity.next(x.data);
            return x;
        }));
    }

    updateEstablishment(id: string, data: any) {
        return this.http.put<ObjectApiInterface<BillModel>>(`${this.baseUrlApi}/${id}/updateEstablishment`, data).pipe(map(x => {
            this._entity.next(x.data);
            return x;
        }));
    }

    deleteEstablishment(id: string, _idEstablishment: string): Observable<ObjectApiInterface<BillModel>> {
        return this.http.delete<ObjectApiInterface<BillModel>>(`${this.baseUrlApi}/${id}/deleteEstablishment/${_idEstablishment}`).pipe(map(x => {
            this._entity.next(x.data);
            return x;
        }));
    }


    updateManyStatusToPay(data: BillModel[]) {
        return this.http.post<ObjectApiInterface<BillModel>>(`${this.baseUrlApi}/updateManyStatusToPay`, data).pipe(map(x => {
            this._entity.next(x.data);
            return x;
        }));
    }

    updateManyToArchive(data: BillModel[]) {
        return this.http.post<ObjectApiInterface<BillModel>>(`${this.baseUrlApi}/updateManyToArchive`, data).pipe(map(x => {
            this._entity.next(x.data);
            return x;
        }));
    }

    updateLine(data: any, _id: string) {
        return this.http.put<ObjectApiInterface<BillModel>>(`${this.baseUrlApi}/updateLine/${_id}`, data)
            .pipe(map(x => {
                this._entity.next(x.data);
                return x;
            }));
    }

    setExported(_id: string) {
        return this.http.get<ObjectApiInterface<BillModel>>(`${this.baseUrlApi}/setExported/${_id}`);
    }

    getExportedRows(_id: string) {
        return this.http.get<ObjectApiInterface<any[]>>(`${this.baseUrlApi}/getExportedRows/${_id}`).pipe(map(x => {
            return x;
        }));
    }

    updateExportedRows(_id: string, data: ExportedRows[]) {
        return this.http.put<ObjectApiInterface<any[]>>(`${this.baseUrlApi}/updateExportedRows/${_id}`, {exportedRows: data}).pipe(map(x => {
            return x;
        }));
    }

    findByIsAnalyticAxisValidated(page, perPage, sortField, sortOrder = 'asc', search, filters) {
        return this.http.get<ObjectApiInterface<BillModel[]>>(`${this.baseUrlApi}/list/isAnalyticAxisValidated`, {
            params: new HttpParams()
                .set('page', page?.toString() ?? '1')
                .set('perPage', perPage?.toString() ?? '30')
                .set('sort', `${sortField}@${sortOrder}`)
                .set('search', search ?? '')
                .set('filters', JSON.stringify(filters) ?? '')
        });
    }

    deleteLine(_id: string, _idLine: string) {
        return this.http.delete<ObjectApiInterface<BillModel>>(`${this.baseUrlApi}/deleteLine/${_id}/${_idLine}`)
            .pipe(map(x => {
                this._entity.next(x.data);
                return x;
            }));
    }

    confirmPayment(_id: string) {
        return this.http.get<ObjectApiInterface<BillModel>>(`${this.baseUrlApi}/confirmPayment/${_id}`)
            .pipe(map(x => {
                this._entity.next(x.data);
                return x;
            }));
    }

    setAllAsUnexported(_id: string) {
        return this.http.get<ObjectApiInterface<BillModel>>(`${this.baseUrlApi}/setAllAsUnexported/${_id}`)
            .pipe(map(x => {
                this._entity.next(x.data);
                return x;
            }));
    }

    gedGetBillImage(_docuwareId: string) {
        return this.http.get<ObjectApiInterface<BillModel>>(`${this.baseUrlApi}/ged/getDocumentImage/${_docuwareId}`).pipe(map(x => x.data));
    }

    gedGetBillImageUrl(_docuwareId: string) {
        return this.http.get<ObjectApiInterface<BillModel>>(`${this.baseUrlApi}/ged/getDocumentImageUrl/${_docuwareId}`).pipe(map(x => x.data));
    }

    gedGetDocumentPdfViewerUrl(_docuwareId: string) {
        return this.http.get<ObjectApiInterface<BillModel>>(`${this.baseUrlApi}/ged/getDocumentPdfViewerUrl/${_docuwareId}`).pipe(map(x => x.data));
    }

    // Analytic Axis
    findAllComptableAccounts(id: string) {
        return this.http.get<ObjectApiInterface<BillComptableAccountModel[]>>(`${this.baseUrlApi}/${id}/comptableAccount/list`, {}).pipe(map(x => {
            this._comptableAccounts.next(x.data);
            return x;
        }));
    }

    addComptableAccount(id: string, data: BillComptableAccountModel): Observable<ObjectApiInterface<BillComptableAccountModel[]>> {
        return this.http.post<ObjectApiInterface<BillComptableAccountModel[]>>(`${this.baseUrlApi}/${id}/addComptableAccount`, data).pipe(map(x => {
            this._comptableAccounts.next(x.data);
            return x;
        }));
    }

    updateComptableAccount(id: string, data: any) {
        return this.http.put<ObjectApiInterface<BillComptableAccountModel[]>>(`${this.baseUrlApi}/${id}/updateComptableAccount`, data).pipe(map(x => {
            this._comptableAccounts.next(x.data);
            return x;
        }));
    }

    deleteComptableAccount(id: string, _idComptableAccount: string): Observable<ObjectApiInterface<BillComptableAccountModel[]>> {
        return this.http.delete<ObjectApiInterface<BillComptableAccountModel[]>>(`${this.baseUrlApi}/${id}/deleteComptableAccount/${_idComptableAccount}`).pipe(map(x => {
            this._comptableAccounts.next(x.data);
            return x;
        }));
    }

    addAnalyticAxis(id: string, data: BillAnalyticAxisModel): Observable<ObjectApiInterface<BillComptableAccountModel[]>> {
        return this.http.post<ObjectApiInterface<BillComptableAccountModel[]>>(`${this.baseUrlApi}/${id}/addAnalyticAxis`, data).pipe(map(x => {
            this._comptableAccounts.next(x.data);
            return x;
        }));
    }

    updateAnalyticAxis(id: string, data: any) {
        return this.http.put<ObjectApiInterface<BillComptableAccountModel[]>>(`${this.baseUrlApi}/${id}/updateAnalyticAxis`, data).pipe(map(x => {
            this._comptableAccounts.next(x.data);
            return x;
        }));
    }

    deleteAnalyticAxis(id: string, _idAnalyticAxis: string, _idAccountingAccount: string): Observable<ObjectApiInterface<BillComptableAccountModel[]>> {
        return this.http.delete<ObjectApiInterface<BillComptableAccountModel[]>>(`${this.baseUrlApi}/${id}/deleteAnalyticAxis/${_idAnalyticAxis}/${_idAccountingAccount}`).pipe(map(x => {
            this._comptableAccounts.next(x.data);
            return x;
        }));

    }

    validateAnalyticAxes(id: string, value: boolean): Observable<ObjectApiInterface<BillModel>> {
        return this.http.get<ObjectApiInterface<any>>(`${this.baseUrlApi}/validateAnalyticAxes/${id}/${value}`)
            .pipe(map(x => {
                this._entity.next(x.data);
                return x;
            }));
    }

    updateRemarque(_id: string, data: any) {
        return this.http.put<ObjectApiInterface<BillModel>>(`${this.baseUrlApi}/${_id}/updateRemarque`, data).pipe(map(x => {
            this._entity.next(x.data);
            return x;
        }));
    }

    checkCategoryAndAutorizeWithoutOrder(_id: string, establishmentFamilies: any = null) {
        return this.http.post<ObjectApiInterface<BillModel>>(`${this.baseUrlApi}/${_id}/check-category-and-autorize-without-order`, {_id: establishmentFamilies}).pipe(map(res => {
        }));

        // return this.http.get(`${this.baseUrlApi}/${_id}/check-category-and-autorize-without-order`);
    }

    addToBasket(id: string, type: 'product' | 'prestation', article: any) {
        const value = this._basket.value;
        let finded: any;

        if (type === 'product') {
            finded = value?.products?.find(x => x._id === article._id);
        } else {
            finded = value?.prestations?.find(x => x._id === article._id);
        }

        if (finded) {
            finded.quantity += article.quantity;
            const url = type === 'product' ? 'updateBasketProduct' : 'updateBasketPrestation';
            this.http.put(`${this.baseUrlApi}/${id}/${url}`, finded).subscribe();
        } else {
            if (type === 'product') {
                this.addProductToBasket(id, article).subscribe();
                // value?.products?.unshift({...article});
            } else {
                this.addPrestationToBasket(id, article).subscribe();

                // value?.prestations?.unshift({...article});
            }
            // const url = type === 'product' ? 'addBasketProduct' : 'addBasketPrestation';
            // this.http.post(`${this.baseUrlApi}/${id}/${url}`, article).subscribe();
        }

        // this._basket.next(value);
    }


    addProductToBasket(id: string, article: any) {
        return this.http.post<ObjectApiInterface<BillModel>>(`${this.baseUrlApi}/${id}/addBasketProduct`, article).pipe(map(res => {
            this._entity.next(res.data);
            const value = this._basket.value;
            value?.products.unshift(res.data.basket?.products.find(x => x._id === article._id));
            this._basket.next(value);
        }));
    }

    addPrestationToBasket(id: string, prestation: any) {
        return this.http.post<ObjectApiInterface<BillModel>>(`${this.baseUrlApi}/${id}/addBasketPrestation`, prestation).pipe(map(res => {
            this._entity.next(res.data);
            const value = this._basket.value;
            value.prestations.unshift(res.data.basket.prestations.find(x => x._id === prestation._id));
            this._basket.next(value);
        }));
    }


    removeFromBasket(id: string, article: any) {
        const value = this._basket.value;

        const isProduct = value.products.find(x => x._id === article._id);

        if (isProduct) {
            value.products = value.products.filter(x => x._id !== article._id);
        } else {
            value.prestations = value.prestations.filter(x => x._id !== article._id);
        }

        const url = isProduct ? 'deleteBasketProduct' : 'deleteBasketPrestation';
        return this.http.delete<ObjectApiInterface<BillModel>>(`${this.baseUrlApi}/${id}/${url}/${article._id}`).pipe(map(res => {
            console.log('res', res)
            this._entity.next(res.data);
            this._basket.next(res.data.basket);
        }));

    }

    updateBasketQuantity(id: string, type: 'product' | 'prestation', article: any, direction: 'minus' | 'plus' | 'change') {
        const value = this._basket.value;
        let finded: any;
        if (type === 'product') {
            finded = value.products.find(x => x._id === article._id);
        } else {
            finded = value.prestations.find(x => x._id === article._id);
        }

        if (finded) {
            if (direction === "minus" && finded.quantity === 1) {
                finded.quantity = 1;
            }
            if (direction === 'change') {
                finded.quantity = article.quantity;
            } else {
                direction === 'minus' ? finded.quantity-- : finded.quantity++;
            }
        }

        finded.stock = article.stock;

        const url = type === 'product' ? 'updateBasketProduct' : 'updateBasketPrestation';

        return this.http.put<ObjectApiInterface<BillModel>>(`${this.baseUrlApi}/${id}/${url}`, finded).pipe(map(res => {
            this._entity.next(res.data);
            // if (type === 'prestation') {
            //     value.prestations = value.prestations.map(prestation => {
            //         const foundPrestation = res.data.basket.prestations.find(x => x._id === prestation._id);
            //         return foundPrestation ? foundPrestation : prestation;
            //     });
            // } else {
            //     value.products = value.products.map(prestation => {
            //         const foundPrestation = res.data.basket.products.find(x => x._id === prestation._id);
            //         return foundPrestation ? foundPrestation : prestation;
            //     });
            // }
            this._basket.next(value);
        }));

    }

    updateBasketPrice(id: string, type: 'product' | 'prestation', article: any) {
        const value = this._basket.value;
        const url = type === 'product' ? 'updateBasketProductPrice' : 'updateBasketPrestationPrice';
        let finded: any;
        if (type === 'product') {
            finded = value.products.find(x => x._id === article._id);
        } else {
            finded = value.prestations.find(x => x._id === article._id);
        }

        return this.http.put<ObjectApiInterface<BillModel>>(`${this.baseUrlApi}/${id}/${url}`, finded).pipe(map(res => {
            this._entity.next(res.data);
            // if (type === 'product') {
            //     value.prestations = value.prestations.map(prestation => {
            //         const foundPrestation = res.data.basket.prestations.find(x => x._id === prestation._id);
            //         return foundPrestation ? foundPrestation : prestation;
            //     });
            // } else {
            //     value.products = value.products.map(prestation => {
            //         const foundPrestation = res.data.basket.products.find(x => x._id === prestation._id);
            //         return foundPrestation ? foundPrestation : prestation;
            //     });
            // }
            this._basket.next(value);
        }));
    }

    createOrderRegularization(id: string, type: OrderRegularizationTypeEnum) {
        return this.http.post<ObjectApiInterface<BillModel>>(`${this.baseUrlApi}/${id}/createOrderRegularization`, {type})
            .pipe(map(res => {
                this._entity.next(res.data);
                return res;
            }));
    }

    changeStatus(_id, status: BillStatus) {
        return this.http.put<ObjectApiInterface<BillModel>>(`${this.baseUrlApi}/${_id}/changeStatus`, {status})
            .pipe(map(res => {
                this._entity.next(res.data);
                return res;
            }));
    }

    exportNewBill(id: string, format: string) {
        return this.http.get<ObjectApiInterface<any>>(`${this.baseUrlApi}/export-new-bill/${format}/${id}`);
    }

    sendNotificationBillApprovalToApprover(id: string, purchasers?: any) {
        return this.http.post<ObjectApiInterface<any>>(`${this.baseUrlApi}/sendNotificationBillApprovalToApprover/${id}`, {
            purchasers: purchasers
        });
    }
}


