<div *ngIf="control && (control.touched || control.dirty) && control.errors">
    <mat-error *ngIf="control.errors?.required" [attr.data-mat-error-name]="controlName + '-error'">
        {{ errorMessages.required }}
    </mat-error>
    <mat-error *ngIf="control.errors?.email">
        {{ errorMessages.email }}
    </mat-error>
    <mat-error *ngIf="control.errors?.min">
        {{ errorMessages.min }} {{ control.errors?.min?.min }}
    </mat-error>
    <mat-error *ngIf="control.errors?.max">
        {{ errorMessages.max }} {{ control.errors?.max?.max }}
    </mat-error>
    <mat-error *ngIf="control.errors?.minlength">
        {{ errorMessages.minLength }} {{ control.errors?.minlength?.requiredLength }}
    </mat-error>
    <mat-error *ngIf="control.errors?.maxlength">
        {{ errorMessages.maxLength }} {{ control.errors?.maxlength?.requiredLength }}
    </mat-error>
    <mat-error *ngIf="control.errors?.pattern">
        {{ invalidPatternMessage }}
    </mat-error>
    <mat-error *ngIf="control.errors?.customError">
        {{ customErrorMessage }}
    </mat-error>
    <mat-error *ngFor="let message of control.errors?.messages">
        {{ message }}
    </mat-error>
</div>