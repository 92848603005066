import {Component, Input, OnInit} from '@angular/core';
import {EstablishmentModel} from '../../../../../core/models/establishment.model';
import {BuildingModel} from '../../../../../core/models/building.model';
import {EnergyIdModel} from '../../../../../core/models/energy-id.model';
import {EquipmentModel} from '../../../../../core/models/equipment.model';
import {QuoteModel, QuotePerimeter} from "../../../../../core/models/quote-model";

@Component({
  selector: 'vex-quote-perimeter-patrimony',
  templateUrl: './quote-perimeter-patrimony.component.html',
  styleUrls: ['./quote-perimeter-patrimony.component.scss']
})

export class QuotePerimeterPatrimonyComponent implements OnInit {

  @Input() quote: QuoteModel;
  @Input() compactMode = false;

  patrimonies: EstablishmentModel[] | BuildingModel[] | EnergyIdModel[] | EquipmentModel[];
  type: 'establishment' | 'equipment' | 'energyid' | 'building';
  hasPerimeter: boolean;
  isExpanded = false;
  typeSelectedTitle: string;

  noPerimeterMessage = "Aucun périmètre étendu défini"
  constructor() { }

  ngOnInit(): void {
    this.hasPerimeter = this.quote.perimeter?.patrimony.length > 0;
    this.patrimonies = this.quote.perimeter?.patrimony;
    this.type = this.quote.perimeter?.type;

    this.generateTitle();
  }

  generateTitle(){
    switch (this.type) {
      case 'building':
        this.typeSelectedTitle = 'Liste des bâtiments';
        break;
      case 'energyid':
        this.typeSelectedTitle = 'Liste des energy ids';
        break;
      case 'equipment':
        this.typeSelectedTitle = 'Liste des équipements';
        break;
      case 'establishment':
        this.typeSelectedTitle = 'Liste des établissements supplémentaires';
        break;
    }
  }

  transformTextLabel(patrimony){
    switch (this.type) {
      case 'building':
        return patrimony.establishments
            .map(establishment => `${establishment.label} - ${patrimony.label}`).join('<br>');;
      case 'energyid':
        return patrimony.energyIdId;
      case 'equipment':
        return `${patrimony.establishment.label} - ${patrimony.reference}`;
      case 'establishment':
        return patrimony.label;
      default:
        return '';
    }
  }

}
