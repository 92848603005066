
export enum BuildingStatusEnum {
    UNCOMPLETED = 'UNCOMPLETED',
    COMPLETED = 'COMPLETED',
}

export const buildingStatusTranslation = (status: BuildingStatusEnum) => {
    switch (status) {
        case BuildingStatusEnum.UNCOMPLETED:
            return 'Incomplet';
        case BuildingStatusEnum.COMPLETED:
            return 'Complet';
        default:
            return 'Statut inconnu';
    }
};

export class BuildingStatusTranslation {
    static translate(status: BuildingStatusEnum): string {
        switch (status) {
            case BuildingStatusEnum.UNCOMPLETED:
                return 'Incomplet';
            case BuildingStatusEnum.COMPLETED:
                return 'Complet';
            default:
                return 'Statut inconnu';
        }
    }
}
