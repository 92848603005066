import {Component, Input, OnInit} from '@angular/core';
import { GedService } from '../../../../pages/application/admin/playground/docuware-playground/ged.service';

@Component({
  selector: 'vex-ged-layout',
  templateUrl: './ged-layout.component.html',
  styleUrls: ['./ged-layout.component.scss']
})
export class GedLayoutComponent implements OnInit {

  errorMessage: string;
  @Input() loading = false;
  @Input() canAddDoc: boolean = true;
  @Input() addDocumentTitle = 'Ajout d\'un document';
  @Input() listTitle = 'Liste des documents';

  constructor(private gedService: GedService) { }

  ngOnInit(): void {
    this.initData();
  }

  initData() {
    this.gedService.errorMessage$.subscribe(errorMessage => {
      this.errorMessage = errorMessage;
    });
  }

}
