import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    convertMinutesToUnit,
    convertToMinutes,
    TimeDelay,
    TimeUnit, TimeUnitDisplay,
    translateTimeUnitObject,
} from '../../../../utils/time-utils';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
    selector: 'form-time-delay-input',
    templateUrl: './form-time-delay-input.component.html',
    styleUrls: ['./form-time-delay-input.component.scss'],
})
export class FormTimeDelayInputComponent implements OnInit {

    // Inputs provenant du parent
    @Input() formGroup: FormGroup;
    @Input() controlName: string;
    @Input() label: string;
    @Input() appearance: MatFormFieldAppearance = 'outline';
    @Input() placeholder = '';
    @Input() hint = '';
    @Input() icon: any;
    @Input() defaultErrorMessage: any;
    @Input() invalidPatternMessage: string;
    @Input() customErrorMessage: string;
    @Input() withoutPadding = false;
    @Input() minNumber: number;
    @Input() maxNumber: number;
    @Input() defaultTimeUnit: TimeUnitDisplay = TimeUnitDisplay.HOUR;
    @Input() defaultValue: TimeDelay; // Valeur par défaut fournie depuis le parent
    @Output() onLostFocus = new EventEmitter<void>();

    visible = false;
    timeDelayForm: FormGroup;
    timeUnits = Object.values(TimeUnitDisplay).map(timeUnit => translateTimeUnitObject(timeUnit));

    ngOnInit() {
        this.timeDelayForm = new FormGroup({
            quantity: new FormControl(null, Validators.required),
            displayedUnit: new FormControl(this.defaultTimeUnit, Validators.required)
        });

        if (this.defaultValue) {
            const displayedQuantity = convertMinutesToUnit(this.defaultValue.quantity, this.defaultValue.displayedUnit);
            this.timeDelayForm.setValue({
                quantity: displayedQuantity,
                displayedUnit: this.defaultValue.displayedUnit
            }, { emitEvent: false });
            this.formGroup.get(this.controlName)?.setValue(this.defaultValue);
        } else {
            this.timeDelayForm.setValue({
                quantity: 0,
                displayedUnit: this.defaultTimeUnit
            }, { emitEvent: false });
        }

        this.timeDelayForm.valueChanges.subscribe(val => {
            if (val.quantity != null && val.displayedUnit != null) {
                const minutes = convertToMinutes(val.quantity, val.displayedUnit);
                const timeDelay: TimeDelay = {
                    quantity: minutes,
                    displayedUnit: val.displayedUnit
                };
                this.formGroup.get(this.controlName)?.setValue(timeDelay);
            }
        });
    }
}
