import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'icon-warning',
    template: `
        <mat-icon
            *ngIf='show'
            class='rounded-full bg-auto p-2 justify-self-center text-2xl mat-tool align-middle'
            [ngClass]="{'bg-warn-light text-warn': type === 'warning', 'bg-amber-light text-amber': type === 'info'}"
            [matTooltip]="toolTip"
            matTooltipPosition='right'
            [matTooltipClass]="{'bg-warn text-base whitespace-pre-line': type === 'warning', 'bg-amber text-base whitespace-pre-line': type === 'info'}">{{ type }}</mat-icon>
    `,
    styleUrls: ['./icon-warning.component.scss']
})

export class IconWarningComponent implements OnInit {
    @Input() type: string = 'warning';
    @Input() show: boolean;
    @Input() toolTip: string;

    constructor() {
    }

    ngOnInit() {
    }
}
