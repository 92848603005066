import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {ENUM_PERMISSIONS} from "../enums/permission.enum";

@Injectable({
    providedIn: 'root'
})
export class PermissionGuardService {

    constructor(
        private authService: AuthService,
        private router: Router
    ) {
    }

    hasPermission(component): Observable<boolean> {
        return this.authService.getCurrentUserPermissions$().pipe(map(x => {
            console.log('component.data.permission', component.data.permission);
            if(!component.data.permission) {
                  return true;
            }
            return this.checkPermission(x, component.data.permission);

        }));
    }

    checkPermission(userPermission: any[], permission: ENUM_PERMISSIONS | ENUM_PERMISSIONS[]): boolean {
        if (permission instanceof Array) {
            return userPermission.some(x => permission.includes(x));
        } else {
            return userPermission.includes(permission);
        }
    }
}
