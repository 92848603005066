import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icBusiness from '@iconify/icons-ic/twotone-business';
import icMail from '@iconify/icons-ic/twotone-mail';
import icChat from '@iconify/icons-ic/twotone-chat';
import icStar from '@iconify/icons-ic/twotone-star';
import icStarBorder from '@iconify/icons-ic/twotone-star-border';
import icEdit from '@iconify/icons-ic/twotone-edit';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import {ENUM_PERMISSIONS} from '../../enums/permission.enum';
import {AuthService} from '../../services/auth.service';
import icContacts from '@iconify/icons-ic/twotone-contacts';

@Component({
    selector: 'vex-contacts-card',
    templateUrl: './contacts-card.component.html',
    styleUrls: ['./contacts-card.component.scss']
})
export class ContactsCardComponent implements OnInit {

    @Input() contact: any;
    @Input() canUpdate = true;
    @Input() canDelete = true;
    @Input() linkPermission: Array<ENUM_PERMISSIONS>;
    @Input() callPermission: Array<ENUM_PERMISSIONS>;
    @Input() sendPermission: Array<ENUM_PERMISSIONS>;
    @Output() openContact = new EventEmitter();
    @Output() toggleStar = new EventEmitter();
    @Output() onEdit = new EventEmitter();
    @Output() onDelete = new EventEmitter();
    @Input() deletePermission: Array<ENUM_PERMISSIONS>;
    @Input() updatePermission: Array<ENUM_PERMISSIONS>;

    ENUM_PERMISSIONS = ENUM_PERMISSIONS;

    icBusiness = icBusiness;
    icPhone = icPhone;
    icMail = icMail;
    icChat = icChat;
    icStar = icStar;
    icStarBorder = icStarBorder;
    icEdit = icEdit;
    icDelete = icDelete;
    icMoreVert = icMoreVert;
    icContacts = icContacts;

    contactInitial: string;

    showAction = true;
    allPermissions: Array<ENUM_PERMISSIONS> = [];

    constructor(
        private authService: AuthService
    ) {
    }

    ngOnInit() {
        this.contactInitial = this.contact?.firstName.split('')[0].toUpperCase() + this.contact?.lastName.split('')[0].toUpperCase();
        this.showAction = this.canUpdate || this.canDelete;
        this.authService.getCurrentUserPermissions$().subscribe((permissions) => {

            // if permissions include of one  delete or update permission
            if (this.updatePermission && !permissions.includes(this.updatePermission[0])){
                this.showAction = false;
            }
            if (this.deletePermission && !permissions.includes(this.deletePermission[0])){
                this.showAction = false;
            }
        });
        if (this.sendPermission?.length > 0) { this.allPermissions.push(...this.sendPermission); }
        if (this.callPermission?.length > 0) { this.allPermissions.push(...this.callPermission); }
    }

    // emitToggleStar(event: MouseEvent, contactId: Contact['id']) {
    //   event.stopPropagation();
    //   this.toggleStar.emit(contactId);
    // }
    linkToContact() {
        this.authService.getCurrentUserPermissions$().subscribe((permissions) => {
            if (this.linkPermission && permissions.includes(this.linkPermission[0])) {
                this.openContact.emit(this.contact);
            }
        });

    }
}
