import { Injectable } from '@angular/core';
import { ConfigService } from '../../../@vex/services/config.service';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { environment } from '../../../environments/environment';
import {
    BrowserCacheLocation,
    InteractionType,
    IPublicClientApplication,
    PublicClientApplication
} from '@azure/msal-browser';

@Injectable({
    providedIn: 'root'
})
export class MsalConfigService{

    constructor(private configService: ConfigService) {
    }

    MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
        const protectedResourceMap = new Map<string, Array<string>>([
            [`${this.configService.env.apiUrl}`, [`api://${this.configService.env.azureAudienceId}/access_as_user`]],
            [`https://graph.microsoft.com/v1.0/me`, [`User.Read`]]
        ]);

        // protectedResourceMap.set(`${this.configService.env.apiUrl}`, [`api://${this.configService.env.azureAudienceId}/access_as_user`]);
        // protectedResourceMap.set(`https://graph.microsoft.com`, [`User.Read`]);
        return {
            interactionType: InteractionType.Redirect,
            protectedResourceMap
        };
    }

    MSALInstanceFactory(): IPublicClientApplication {
        return new PublicClientApplication({
            auth: {
                clientId: this.configService.env.azureAudienceId,
                redirectUri: `${this.configService.env.apiUrl}`.replace('api', 'redirect'),
            },
            cache: {
                cacheLocation: BrowserCacheLocation.LocalStorage,
                storeAuthStateInCookie: true,
                secureCookies: true,
            },
        });
    }

    MSALGuardConfigFactory(): MsalGuardConfiguration {
        return {
            interactionType: InteractionType.Redirect,
            authRequest: {
                scopes: ['user.read']
            }
        };
    }
}