import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import icClose from '@iconify/icons-ic/twotone-close';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
    selector: 'form-modal-archive-base',
    template: `
        <ng-container>
            <div mat-dialog-title fxLayout='row' fxLayoutAlign='space-between center'>
                <div>{{ title }}</div>
                <button type='button' mat-icon-button (click)='onClose.emit()' tabindex='-1'>
                    <mat-icon [icIcon]='icClose'></mat-icon>
                </button>
            </div>

            <mat-dialog-content>
                <p>{{ defaultMessage }}</p>
            </mat-dialog-content>


            <mat-dialog-actions align='end'>
                <button mat-button (click)='onClose.emit()'>NON</button>
                <button mat-button color='primary' [loading]='loading' (click)='onArchive.emit()'>OUI</button>
            </mat-dialog-actions>
        </ng-container>
    `
})

export class FormModalArchiveBaseComponent implements OnInit {

    @Input() title: string;
    @Input() label: string;
    @Input() defaultMessage: string;
    @Input() loading = false;

    @Output() onClose = new EventEmitter();
    @Output() onArchive = new EventEmitter();


    icClose = icClose;


    constructor(@Inject(MAT_DIALOG_DATA) public data: { label: string, defaultMessage: string }) {
        if (data) {
            this.label = data.label;
            this.defaultMessage = data.defaultMessage;
        }
    }

    ngOnInit() {
        if (!this.title) {
            this.title = 'Archiver ' + this.label;
        }

        if (!this.defaultMessage) {
            this.defaultMessage = `Êtes-vous sûr de vouloir archiver ${this.label} ?`;
        }
    }
}
