import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { VersionInfo } from '../../models/version-info.model';

@Injectable({
    providedIn: 'root'
})
export class VersionService {
    private versionInfoSubject = new BehaviorSubject<VersionInfo | null>(null);
    public versionInfo$ = this.versionInfoSubject.asObservable();

    private readonly apiUrl = `/api/version`;

    constructor(private http: HttpClient) {}

    /**
     * Récupère les informations de version depuis l'API
     * @returns Un Observable contenant les informations de version
     */
    fetchVersionInfo(): Observable<VersionInfo> {
        return this.http.get<VersionInfo>(this.apiUrl).pipe(
           tap(versionInfo => {

               console.log('Version Info:', versionInfo);
               this.versionInfoSubject.next(versionInfo);
           })
        );
    }

    /**
     * Formatte la date de déploiement pour un affichage convivial
     * @param dateString La date au format ISO
     * @returns La date formattée
     */
    formatDeploymentDate(dateString: string): string {
        if (dateString === 'Unknown') return 'Date inconnue';

        try {
            const date = new Date(dateString);
            return date.toLocaleString();
        } catch (error) {
            console.error('Erreur lors du formatage de la date:', error);
            return dateString;
        }
    }
}
