import { Component, Input, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'vex-banner-message',
  templateUrl: './banner-message.component.html',
  styleUrls: ['./banner-message.component.scss']
})
export class BannerMessageComponent implements OnInit {

  @Input() text: string;
  @Input() alertType: 'warn' | 'alert' | 'default' = 'default';
  @Input() textButton: string;
  @Input() showButton = false;
  @Input() callButton: () => any;

  colorButton: ThemePalette = 'primary';

  private alertColors: { [key: string]: ThemePalette } = {
    alert: 'warn',
    warn: 'warn',
    default: 'primary'
  };

  constructor() { }

  ngOnInit(): void {
    this.setColorButton();
  }

  private setColorButton(): void {
    this.colorButton = this.alertColors[this.alertType] || this.alertColors.default;
  }

  getBorderClasses(): string {
    switch (this.alertType) {
      case 'alert':
        return 'border-red';
      case 'warn':
        return 'border-orange';
      default:
        return 'border-primary';
    }
  }
}
