<vex-ged-layout [loading]="loading">
    <base-data-table
            (onDeleteItem)="openDelete($event)"
            [dataSource]='dataSource'
            [displayedColumns]='displayedColumns'
            [feisColumns]='feisColumns'
            [showPaginator]="false"
            [showSort]="false"
            (onDownloadItem)="downloadDocument($event.DWDOCID)"
            [customColumnTemplate]='customColumnTemplateRef'
    ></base-data-table>

    <ng-template #customColumnTemplateRef let-column let-row="element" let-status="status">
        <div *ngIf="column === 'btnToPrev'">
            <button mat-icon-button (click)='preview(row)' [loading]="row.isLoading" color="primary">
                <mat-icon>remove_red_eye</mat-icon>
            </button>
        </div>
    </ng-template>

    <div dragFormZone>
        <div *ngIf="!loading" fxLayout="column">
            <div [formGroup]="form" class="w-full">
                <mat-form-field class="w-full">
                    <mat-label>Type de document</mat-label>
                    <mat-select formControlName="documentType">
                        <mat-option *ngFor='let type of documentTypes' [value]="type">{{ type }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <vex-drop-zone [files]="files"></vex-drop-zone>
            <button (click)="validForm()" [disabled]="form.invalid || files.length === 0" [loading]="loadingSave" class="mt-4" color="primary"
                    fxFlexAlign="end"
                    mat-raised-button>ENREGISTRER
            </button>
        </div>

    </div>
</vex-ged-layout>

