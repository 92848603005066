import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import icFileCopy from '@iconify/icons-ic/twotone-file-copy';

@Component({
    selector: 'vex-more-info-modal',
    templateUrl: './more-info-modal.component.html',
    styleUrls: ['./more-info-modal.component.scss']
})
export class MoreInfoModalComponent implements OnInit {

    dataRow: any;
    supplierRow: string;
    isProductRow: boolean;
    icFileCopy = icFileCopy;
    hasDocumentation: boolean = false;
    iconCustom: string = 'inventory_2';

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<MoreInfoModalComponent>) {
    }

    ngOnInit(): void {
        this.initData();
    }

    initData() {
        this.dataRow = this.data?.data;
        this.supplierRow = 'Fournisseur ' + this.dataRow?.supplier.label;
        this.isProductRow = this.dataRow?.isProduct;
        this.hasDocumentation = this.dataRow?.generalSupplierCatalog || this.dataRow?.technicalSheet || this.dataRow?.safetyUsageProtocol;
    }

}
