import {Component, OnInit} from '@angular/core';
import {NotificationModel} from 'src/app/core/models/notification.model';
import {NotificationService} from './notification.service';
import {MatDialog} from '@angular/material/dialog';
import {
    NotificationCreateUpdateDeleteComponent
} from './notification-create-update-delete/notification-create-update-delete.component';
import {FeiColumn} from 'src/app/core/base/interfaces/fei-column.interface';
import {DataTableBase} from 'src/app/core/base/components/data-table-base/data-table-base';
import {CrudModeEnum} from 'src/app/core/base/enum/crud-mode.enum';
import {NotificationStatusEnum} from '../../../enums/notification-status.enum';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup} from '@angular/forms';
import {NotificationSeverityEnum} from '../../../enums/notification-severity.enum';
import {HttpClient} from '@angular/common/http';
import {
    FormModalConfirmBaseComponent
} from '../../../base/components/form-modal-confirme-base/form-modal-confirm-base.component';
import {PageEvent} from '@angular/material/paginator';
import {fadeInUp400ms} from '../../../../../@vex/animations/fade-in-up.animation';
import {stagger40ms} from '../../../../../@vex/animations/stagger.animation';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {scaleIn400ms} from '../../../../../@vex/animations/scale-in.animation';
import {fadeInRight400ms} from '../../../../../@vex/animations/fade-in-right.animation';
import {scaleFadeIn400ms} from '../../../../../@vex/animations/scale-fade-in.animation';
import {dropdownAnimation} from '../../../../../@vex/animations/dropdown.animation';
import {popoverAnimation} from '../../../../../@vex/animations/popover.animation';
import {BillService} from '../../../../pages/application/administrative/bill/bill.service';
import {
    BillPreviewModalComponent
} from '../../../../pages/application/administrative/bill/modals/bill-preview-modal/bill-preview-modal.component';
import {MatTableDataSource} from '@angular/material/table';
import {AuthService} from '../../../services/auth.service';
import {ENUM_PERMISSIONS} from '../../../enums/permission.enum';
import {NotificationTypeEnum} from '../../../enums/notification-type.enum';

@Component({
    selector: 'vex-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    animations: [
        fadeInUp400ms,
        stagger40ms,
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
        scaleIn400ms,
        fadeInRight400ms,
        scaleFadeIn400ms,
        scaleFadeIn400ms,
        dropdownAnimation,
        popoverAnimation
    ],
})
export class NotificationComponent extends DataTableBase<NotificationModel> implements OnInit {

    feisColumns: Array<FeiColumn> = [
        {label: '', column: 'selection'},
        {label: '', column: 'notificationStatus', type: 'notificationStatus'},
        {label: 'Type', column: 'type'},
        // { label: 'Message', column: 'message', },
        {label: 'Sévérité', column: 'severity'},
        {label: 'Date d\'envoi', column: 'sendDate', type: 'date'},
        {label: 'Émetteur', column: 'sender', propertyDisplay: 'fullName'},
        // { label: 'Recever', column: 'recever', },
        {label: 'Statut', column: 'status'},
        {
            label: '',
            column: 'actions',
            canUpdate: false,
            canDelete: this.authService.hasPermission(this.ENUM_PERMISSION.DELETE_DASHBOARD),
            canChangeStatus: true,
        }

    ];

    NotificationStatusEnum = NotificationStatusEnum;

    listSeverity: Array<string>;
    clearSelectedEl = false;
    selectedEl = [];
    deleteItemPermission = false;
    protected readonly ENUM_PERMISSIONS = ENUM_PERMISSIONS;

    constructor(
        public service: NotificationService,
        private dialog: MatDialog,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private http: HttpClient,
        private billService: BillService,
        private authService: AuthService
    ) {
        super();
        this.displayedColumns = this.feisColumns.map(x => x.column);
    }

    ngOnInit() {
        this.popupForm = new FormGroup({
            severity: new FormControl(null)
        });

        if (this.activatedRoute.snapshot.queryParams.severity) {
            this.popupForm.get('severity').setValue([this.activatedRoute.snapshot.queryParams.severity]);
            this.filters = this.popupForm.value;
        }
        super.ngOnInit();
    }

    initData() {
        this.loading = true;
        const items = this.service.findAll(this.pageIndex, this.pageSize, this.sortField, this.sortOrder, this.search, this.filters).subscribe(res => {
            res.data.map(x => {
                if (x.sender) {
                    x.fullName = `${x?.sender?.firstName} ${x?.sender?.lastName}`;
                }
            });
            this.dataSource = new MatTableDataSource<NotificationModel>(res.data);
            this.count = res.totalData;
            this.loading = false;
        });

        this.subscription.add(items);

        this.listSeverity = Object.values(NotificationSeverityEnum);

        this.authService.getCurrentUserPermissions$().subscribe(permissions => {
            this.deleteItemPermission = permissions.includes(ENUM_PERMISSIONS.TEST);
        });
    }

    selectedRow(selectedData: any) {
        if (this.clearSelectedEl) {
            this.clearSelectedEl = false;
        }
        this.selectedEl = selectedData;
    }

    clearPopupFilter() {
        this.popupForm.reset();
        if (Object.keys(this.filters).length > 0) {
            this.filters = {};
            this.countPopupFilter = 0;
            this.clearSelectedEl = true;
            this.initData();
        }
    }

    clearTextFilter() {
        super.clearTextFilter();
        this.clearSelectedEl = true;
    }

    onSearchChange(event) {
        super.onSearchChange(event);
        this.clearSelectedEl = true;
    }

    validatePopupFilter(): void {
        this.filters = this.popupForm.value;
        this.countPopupFilter = Object.entries(this.popupForm.value).filter(entrie => entrie[1]).length;
        if (this.filters.severity) {
            this.clearSelectedEl = true;
            this.initData();
        }
    }

    pageEvent(event: PageEvent) {
        super.pageEvent(event);
        this.clearSelectedEl = true;
    }

    removeNotifications(notifications: NotificationModel[]) {
        this.deleteConfirmModal();
    }

    deleteConfirmModal() {
        this.dialog.open(FormModalConfirmBaseComponent, {
            data: {
                title: `Confirmation de suppression`,
                message: `Veuillez confirmer la suppression des ${this.selectedEl.length} élements ?`
            }
        }).afterClosed().subscribe(x => {
            this.service.deleteNotifications(this.selectedEl.map(s => {
                return s._id;
            }).join(',')).subscribe(() => {
                this.initData();
                this.clearSelectedEl = true;
            });
        });
    }

    createItem(): void {
        this.dialog.open(NotificationCreateUpdateDeleteComponent).afterClosed().subscribe((notification: NotificationModel) => {
            if (notification) {
                this.initData();
            }
        });
    }

    updateItem(element: NotificationModel): void {
        this.dialog.open(NotificationCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                mode: CrudModeEnum.Update
            }
        }).afterClosed().subscribe((notification: NotificationModel) => {
            if (notification) {
                this.initData();
            }
        });
    }

    changeReadStatus(element: NotificationModel) {

        if (element.status === NotificationStatusEnum.READ) {
            this.service.unrRead(element).subscribe(() => {
                this.dataSource.data = this.dataSource.data.map(x => {
                    if (x._id === element._id) {
                        x.status = NotificationStatusEnum.UN_READ;
                    }
                    return x;
                });
            });
        }

        if (element.status === NotificationStatusEnum.UN_READ) {
            this.service.read(element).subscribe(() => {
                this.dataSource.data = this.dataSource.data.map(x => {
                    if (x._id === element._id) {
                        x.status = NotificationStatusEnum.READ;
                    }
                    return x;
                });
            });
        }

    }

    changeMassiveReadStatus(status: NotificationStatusEnum, notifications: NotificationModel[]) {
        if (!notifications || notifications.length === 0) {
            return;
        }

        console.log('status', status, 'notifications', notifications);
        const statusToChange = status === NotificationStatusEnum.READ ? '"lu"' : '"non lu"';
        const message = notifications.length > 1
            ? `Voulez-vous confirmer la modification du statut de ces notifications sélectionnées comme ${statusToChange} ?`
            : `Voulez-vous confirmer la modification du statut de cette notification sélectionnée comme ${statusToChange} ?`;

        this.dialog.open(FormModalConfirmBaseComponent, {
            data: {
                title: `Changement de status`,
                message
            }
        }).afterClosed().subscribe(validate => {

            if (validate) {
                this.service.changeMassiveReadStatus(status, notifications.map(notification => notification._id).join(','))
                .subscribe(() => {
                    this.dataSource.data = this.dataSource.data.map(notification => {
                        notifications.map(x => {
                            if (notification._id === x._id) {
                                notification.status = status;
                            }
                        });
                        return notification;
                    });
                });
            }
        });
    }

    deleteItem(element: NotificationModel) {
        this.dialog.open(NotificationCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                name: `${element.type}`,
                mode: CrudModeEnum.Delete
            }
        }).afterClosed().subscribe((notification: NotificationModel) => {
            if (notification) {
                this.initData();
            }
        });
    }

    readNotification(element: NotificationModel) {
        if (element.status === NotificationStatusEnum.UN_READ) {
            this.service.read(element).subscribe(() => {
                this.dataSource.data = this.dataSource.data.map(x => {
                    if (x._id === element._id) {
                        x.status = NotificationStatusEnum.READ;
                    }
                    return x;
                });
            });
        }
    }

    refresh() {
        this.initData();
    }

    async goToLocation(element: any) {
        if (element.type === NotificationTypeEnum.SEND_BILL_WAITING_APPROVER) {
            element.source = element.source + '/approval';
            await this.router.navigate([element.source]);
        }
        if (element.source) {
            await this.router.navigate([element.source]);
        }
    }

    async displayBill(element: any) {
        this.billService.findById(element.sourceObject._id).subscribe(bill => {
            this.dialog.open(BillPreviewModalComponent, {
                data: {
                    docuwareId: bill?.data?.docuwareId
                },
                width: '80vw',
                height: '80vh'
            });
        });
    }

    allSelectedAreRead(selectedElem: NotificationModel[]): boolean {
        return selectedElem.every(notification => notification.status === NotificationStatusEnum.READ);
    }

    allSelectedAreUnread(selectedElem: NotificationModel[]): boolean {
        return selectedElem.every(notification => notification.status === NotificationStatusEnum.UN_READ);
    }
}
