import {Component, Inject, OnInit} from '@angular/core';
import {FormModalBaseComponent} from '../../../../../core/base/components/form-modal-base/form-modal-base.component';
import {UserProfileModel} from '../../../../../core/models/user-profile.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserProfileService} from '../../../management/user-profile/user-profile.service';
import {AuthService} from '../../../../../core/services/auth.service';
import {SnackbarService} from '../../../../../core/services/snackbar.service';
import {ResponseTypeEnum} from '../../../../../core/base/enum/response-type.enum';
import {CrudModeEnum} from '../../../../../core/base/enum/crud-mode.enum';
import {b64ToBlob} from "../../../../../core/helpers/b64ToBlob.helper";
import {ContentTypeEnum} from "../../../../../core/base/enum/content-type.enum";
import {FileSaverService} from "ngx-filesaver";

@Component({
    selector: 'vex-modal-import-user-test',
    templateUrl: './modal-import-user-test.component.html',
    styleUrls: ['./modal-import-user-test.component.scss']
})
export class ModalImportUserTestComponent extends FormModalBaseComponent<UserProfileModel> implements OnInit {

    loadingMessage: string | null = null;
    timeoutHandle: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        public dialogRef: MatDialogRef<FormModalBaseComponent<UserProfileModel>>,
        public service: UserProfileService,
        public snackbarService: SnackbarService,
        private fileSaver: FileSaverService,
    ) {
        super(data, dialogRef, service);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    afterImportItem(result?: any, error?: any) {
        if (result) {
            this.loadingMessage = null; // Supprime le message une fois terminé
            clearTimeout(this.timeoutHandle);
            this.close(true);
        }
    }

    importItems() {
        this.importDefaults = this.importForm.value;
        this.beforeImportItem();

        if (this.importForm.valid) {
            this.loading = true;

            // **🔥 Définir un timeout pour afficher le message après 5 secondes**
            this.timeoutHandle = setTimeout(() => {
                this.loadingMessage = '⚠️ L\'opération peut prendre du temps...';
            }, 5000);

            const sub = this.service.importUsersTest(this.importDefaults).subscribe(
                result => {
                    this.afterImportItem(result, null);
                    this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Import, result.message);
                },
                error => {
                    this.afterImportItem(null, error);
                    this.setImportErrorsMessages(error.error.errors);
                    this.loadingMessage = null; // Supprime le message en cas d'erreur
                    clearTimeout(this.timeoutHandle);
                    this.loading = false;
                }
            );

            this.subscription.add(sub);
        } else {
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    downloadUsersTestTemplate() {
        this.service.downloadUsersTestTemplate().subscribe((x: any) => {
            const b = b64ToBlob(x.data.b64, ContentTypeEnum.excel);
            this.fileSaver.save(b, x.data.fileName);
        });
    }


}
