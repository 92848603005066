<ng-container *ngIf="isCreateMode() || isUpdateMode()">
    <form (ngSubmit)="save()" [formGroup]="form">
        <base-form-header [mode]='mode' createLabel='Ajouter un contact interne'
                          [updateLabel]='form.get("firstName").value + " " + form.get("lastName").value'></base-form-header>

        <mat-dialog-content fxLayout="column">
            <base-form-input
                    label="Mme/Mr"
                    type="autocomplete"
                    controlName="gender"
                    [items]="genderItem"
            ></base-form-input>

            <div class="flex flex-col sm:flex-row">
                <base-form-input class='sm:mr-3'
                                 label='Nom'
                                 controlName='lastName'
                                 [icon]='icPerson'>
                </base-form-input>
                <base-form-input class='sm:ml-3'
                                 label='Prénom'
                                 controlName='firstName'
                                 [icon]='icPerson'>
                </base-form-input>
            </div>

            <base-form-input
                    label='Numéro de téléphone'
                    controlName='mobileNumber'
                    hint='Entre 10 et 13 chiffres'
                    [icon]='icPhone'>
            </base-form-input>

            <base-form-input
                    label='Email Microsoft'
                    controlName='mcEmail'
                    [icon]='icEmail'>
            </base-form-input>

            <base-form-input
                    label='Email'
                    controlName='email'
                    [icon]='icEmail'>
            </base-form-input>

            <!--            <base-form-input-->
            <!--                    label='ID Docuware'-->
            <!--                    controlName='idDocuware'>-->
            <!--            </base-form-input>-->

            <!--           TODO: Delete this part if role work for predik

             <base-form-input-->
            <!--                label='Rôle'-->
            <!--                type='multiple'-->
            <!--                controlName='roles'-->
            <!--                [loading]='loading'-->
            <!--                [items]='temporayRole'>-->
            <!--            </base-form-input>-->
             <div class="flex flex-col sm:flex-row">
                <base-form-input class='sm:mr-3'
                        *ngIf='authService.currentUserValue().isPredik'
                        label='Predik'
                        controlName='isPredik'
                        type='checkbox'
                >
                </base-form-input>

                <!--<base-form-input class='sm:ml-3'  *hasPermission="[ENUM_PERMISSION.RECEIVE_NOTIFICATION]"-->
                <base-form-input class='sm:ml-3'
                        label='Notification par e-mail'
                        controlName='notifyByEmail'
                        type='checkbox'
                >
                </base-form-input>
            </div>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <base-form-input
                    type='submit'
                    [mode]='mode'
                    entityLabel='USER'
                    [loading]='loading'
                    (onCreate)='createItem()'
                    (onUpdate)='updateItem()'
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>

<ng-container *ngIf="isDeleteMode()">
    <form-modal-delete-base
            [label]='data.name'
            [loading]='loading'
            (onDelete)='deleteItem()'
            (onClose)='close()'
    >

    </form-modal-delete-base>
</ng-container>
<ng-container *ngIf='isArchiveMode()'>
    <form-modal-delete-base
            (onClose)='close()'
            (onDelete)='archiveItem()'
            title="Archiver le contact interne"
            defaultMessage='Êtes-vous sûr de vouloir archiver ce contact interne ?'
    >
    </form-modal-delete-base>
</ng-container>
