<span
    [style.background]="background"
    [style.color]="color"
    class="item-badge"
    style="margin-left: 8px;"
    *ngIf="value != null"
>

    {{ value }}
</span>

<mat-icon *ngIf="icon" class='custor-pointer text-warn align-middle' >{{ icon}}</mat-icon>
