import { RandomUtils } from '../utils/random-utils';
import { GenericEngagementFormatEnum } from './generic-engagement-format.enum';

export enum GenericAdditionalCostTypeEnum {
    FLATE_RATE = 'Forfaitaire',
    TO_DEFINED = 'A définir',
}

export enum GenericAdditionalCostSigns {
    POSITIVE = 'positive',
    NEGATIVE = 'negative',
}


export const genericAdditionalCostTranslation = (status: GenericAdditionalCostSigns) => {
    switch (status) {
        case GenericAdditionalCostSigns.POSITIVE.toString():
            return 'Positif';
        case GenericAdditionalCostSigns.NEGATIVE.toString():
            return 'Négatif';
        default:
            return null;
    }
};

export const genericAdditionalCostTranslationObject = (status: GenericAdditionalCostSigns) => {
    return {
        _id: RandomUtils.generateId(),
        value: status,
        label: genericAdditionalCostTranslation(status)
    };
};
