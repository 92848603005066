import { Component, Inject, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { AddressModel } from "../../../../../../core/models/address.model";
import { CrudModeEnum } from "../../../../../../core/base/enum/crud-mode.enum";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AddressType } from "../../../../../../core/enums/address-type.enum";
import { Country } from "../../../../../../core/enums/countries.enum";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SupplierService } from "../../../supplier/supplier.service";
import { ActivatedRoute } from "@angular/router";
import { ErrorApi } from "../../../../../../core/models/api/error-api";
import { b64ToBlob } from "../../../../../../core/helpers/b64ToBlob.helper";
import { ContentTypeEnum } from "../../../../../../core/base/enum/content-type.enum";
import { FileSaverService } from "ngx-filesaver";
import { BuildingService } from "../../building.service";

@Component({
  selector: "vex-building-address-create-update-delete",
  templateUrl: "./building-address-create-update-delete.component.html",
  styleUrls: ["./building-address-create-update-delete.component.scss"]
})
export class BuildingAddressCreateUpdateDeleteComponent implements OnInit {

  protected subscription = new Subscription();
  defaults: AddressModel;
  mode: CrudModeEnum = CrudModeEnum.Create;
  form: FormGroup;
  importForm: FormGroup;
  loading: boolean = false;
  buildingId: string;
  typeItem: AddressType[];
  countryItem: Country[];
  importDefaults: any;
  addresses: AddressModel[] = [];
  displayAddress: string[] = ["street", "city"];
  hideCopyFromEstablishment: boolean = false;
  hideLabel: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<BuildingAddressCreateUpdateDeleteComponent>,
    private supplierService: SupplierService,
    public buildingService: BuildingService,
    public activatedRoute: ActivatedRoute,
    private fileSaver: FileSaverService
  ) {
    if (this.data) {
      this.defaults = this.data?.defaults ?? {} as AddressModel;
      this.mode = this.data.mode;
    }
  }

  ngOnInit(): void {
    console.log(this.data);
    this.buildingId = this.data.id;
    this.typeItem = Object.values(AddressType);
    this.countryItem = Object.values(Country);

    if (this.isImportMode()) {
      this.importForm = new FormGroup({
        file: new FormControl("", [Validators.required])
      });
    }

    this.data.defaults.establishments.map((establishment) => {
      establishment.addresses.map((address) => {
        address.completeAddress = `${address.street} ${address.postalCode} ${address.city} ${address.country}`;
        const duplicate = this.addresses.find(
          (obj) => obj.completeAddress === address.completeAddress
        );
        if (!duplicate) {
          this.addresses.push(address);
        }
      });
      this.addresses.map((address) => {
        console.log(address);
      });
    });

    this.form = new FormGroup({
      _id: new FormControl(this.defaults?._id),
      label: new FormControl(null),
      type: new FormControl(this.defaults?.type),
      street: new FormControl(this.defaults?.street, [Validators.required]),
      additionalAddress: new FormControl(this.defaults?.additionalAddress),
      postalCode: new FormControl(this.defaults?.postalCode, [Validators.required, Validators.pattern(/^[0-9]{5}$/)]),
      city: new FormControl(this.defaults?.city, [Validators.required]),
      country: new FormControl(this.defaults?.country, [Validators.required]),
      address: new FormControl(null)
    });

    this.form.controls.address.valueChanges.subscribe(address => {
      if (address) {
        this.form.controls.type.setValue(address.type);
        this.form.controls.label.setValue(address.label);
        this.form.controls.street.setValue(address.street);
        this.form.controls.additionalAddress.setValue(address.additionalAddress);
        this.form.controls.postalCode.setValue(address.postalCode);
        this.form.controls.city.setValue(address.city);
        this.form.controls.country.setValue(address.country);
      } else {
        this.form.controls.type.reset();
        this.form.controls.label.reset();
        this.form.controls.street.reset();
        this.form.controls.additionalAddress.reset();
        this.form.controls.postalCode.reset();
        this.form.controls.city.reset();
        this.form.controls.country.reset();
      }
    });
  }

  save(): void {
    if (this.isCreateMode()) {
      this.createItem();
    } else if (this.isUpdateMode()) {
      this.updateItem();
    } else if (this.isImportMode()) {
      this.importItems();
    }
  }

  close(data?: any): void {
    this.dialogRef.close(data);
  }

  isDeleteMode() {
    return this.mode === CrudModeEnum.Delete;
  }

  isCreateMode() {
    return this.mode === CrudModeEnum.Create;
  }

  isUpdateMode() {
    return this.mode === CrudModeEnum.Update;
  }

  isImportMode(): boolean {
    return this.mode === CrudModeEnum.Import;
  }

  createItem() {
    this.loading = true;
    this.defaults = this.form.value;
    if (this.form.valid) {
      const sub = this.buildingService.createAddress(this.buildingId, this.defaults).subscribe(
        res => {
          this.close(res);
          this.loading = false;
        },
        err => {
          console.log(err);
          // this.setErrorsMessages(err.error.errors);
          this.loading = false;
        }
      );
      this.subscription.add(sub);
    } else {
      this.form.markAllAsTouched();
      this.loading = false;
    }
  }

  updateItem() {
    this.loading = true;
    this.defaults = this.form.value;

    if (this.form.valid) {
      const sub = this.buildingService.updateAddress(this.buildingId, this.defaults).subscribe(
        result => {
          this.close(result);
          this.loading = false;
        }, error => {
          this.setErrorsMessages(error.error.errors);
          this.loading = false;
        });
      this.subscription.add(sub);
    } else {
      this.form.markAllAsTouched();
      this.loading = false;
    }
  }

  setErrorsMessages(errors: Array<ErrorApi>) {
    errors?.forEach(error => {
      let err = this.form.controls[error.property].errors;
      if (err === null) {
        err = { messages: [] };
      }
      err.messages.push(error.message);
      this.form.controls[error.property].setErrors(err);
    });
    this.form.markAllAsTouched();
  }

  deleteItem() {
    this.loading = true;
    const sub = this.buildingService.deleteAddress(this.buildingId, this.defaults?._id).subscribe(
      result => {
        this.close(result);
        this.loading = false;
      }, error => {
        this.setErrorsMessages(error.error.errors);
        this.loading = false;
      }
    );
    this.subscription.add(sub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  importItems() {
    this.importDefaults = this.importForm.value;
    this.beforeImportItem();
    if (this.importForm.valid) {
      this.loading = true;
      const sub = this.buildingService.importAddress(this.importDefaults, this.buildingId).subscribe(
        result => {
          this.close(result);
        }, error => {
          this.setImportErrorsMessages(error.error.errors);
          this.loading = false;
        });
      this.subscription.add(sub);
    } else {
      this.form.markAllAsTouched();
      this.loading = false;
    }
  }

  beforeImportItem() {
    let formData = new FormData();

    if (!this.importDefaults?.file?._files?.length) {
      this.importForm.controls["file"].setErrors({ required: true });
      this.importForm.markAllAsTouched();
      return;
    }

    formData.append("file-excel", this.importDefaults.file._files[0]);
    this.importDefaults = formData;
  }

  setImportErrorsMessages(errors: Array<ErrorApi>) {
    errors?.forEach(error => {
      let err = this.importForm.controls[error.property].errors;
      if (err === null) {
        err = { messages: [] };
      }
      err.messages.push(error.message);
      this.importForm.controls[error.property].setErrors(err);
    });
    this.importForm.markAllAsTouched();
  }

  exportAddressModel() {
    this.buildingService.downloadExcelAddressTemplate().subscribe((x: any) => {
      const b = b64ToBlob(x.data.b64, ContentTypeEnum.excel);
      this.fileSaver.save(b, x.data.fileName);
    });
  }
}
