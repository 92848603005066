import {RandomUtils} from '../utils/random-utils';

export enum GenericEngagementFormatEnum {
    QUOTE = 'Devis',
    CONTRACT = 'Contrat',
}

export const genericEngagementTranslation = (status: GenericEngagementFormatEnum) => {
    switch (status) {
        case GenericEngagementFormatEnum.CONTRACT.toString():
            return 'Contract';
        case GenericEngagementFormatEnum.QUOTE.toString():
            return 'Devis';
        default:
            return null;
    }
};

export const genericEngagementTranslationObject = (status: GenericEngagementFormatEnum) => {
    return {
        _id: RandomUtils.generateId(),
        value: status,
        label: genericEngagementTranslation(status)
    };
};
