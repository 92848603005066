<vex-page-layout>
    <div class='w-full flex flex-row justify-center py-2' style='background-color: #014463; height: 70px'>
        <img src='../../../../../assets/img/Logo%20naming%20blanc%20ss%20baseline.png' alt=''>
    </div>
    <div class='w-full border-y-4 overflow-hidden ' style='border-color: #53aede;'>
        <img class='w-full object-cover md:hidden'
             src='../../../../../assets/img/predik_tool_banner_desktop.svg'
             alt='banniere'>
        <img class='w-full object-cover hidden md:block'
             src='../../../../../assets/img/predik_tool_banner_phone.svg'
             alt='banniere'>
    </div>

    <vex-app-carrousel [items]="carouselItems"></vex-app-carrousel>

    <div class='flex flex-col justify-center items-center gap-6' [style]=' isPredik ? "" : "height: 400px"'>
        <button
                [loading]='loadingEshopBtn'
                [disabled]="disabledEshopBtn"
                (click)='createRequirement()'
                class='px-gutter mr-4  py-1 w-2/3 md:w-1/3 hover:text-black text-white'
                style='background-color: #53aede;'
                mat-raised-button
                type='button'
        >J'exprime un besoin (e-Shop360)
        </button>
        <button *ngIf="isPredik"
                [loading]='loadingEticketBtn'
                [disabled]="disabledEticketBtn"
                (click)='createIncident()'
                class='px-gutter py-1 mr-4 w-2/3 md:w-1/3 hover:text-black text-white'
                style='background-color: #53aede;'
                mat-raised-button
                type='button'
        >Je signale une anomalie (e-Ticket360)
        </button>
        <button
                [disabled]="disabledDemandsBtn"
                (click)='goToTicketList()'
                class='px-gutter mr-4  py-1 w-2/3 md:w-1/3 hover:text-black text-white'
                style='background-color: #53aede;'
                mat-raised-button
                type='button'
        >Je suis mes demandes (Mes demandes)
        </button>
    </div>
</vex-page-layout>

<ng-template #topicRef *ngFor="let topic of hotTopics">
        <ng-container *ngIf="topic.genericHotTopic.carrouselStyle.toLowerCase() === 'contract'">
            <div
                style="margin: 30px"
                fxFlex="auto"
                fxLayout="row"
                fxLayoutAlign="space-evenly center"
                fxLayout.lt-md="column"
                fxLayoutAlign.lt-md="center start"
                fxLayoutGap.lt-md="20px"
                fxLayoutGap.md="0px"
            >
                <div>
                    <p class="m-0 caption text-hint">Actualités</p>
                    <h2><strong>{{ topic.genericHotTopic.type }}</strong></h2>
                    <p><strong>{{ topic.contract?.object }}</strong></p>
                    <p style="opacity: .7">
                        {{ topic.contract?.technicalIdentifier }}
                    </p>
                </div>

                <div fxLayout.lt-md="row-reverse" fxLayoutAlign.lt-md="start center" fxLayoutGap.lt-md="5px">
                    <p class="m-0 body-1">
                        <strong>{{ topic.contract?.applicationDate | date:'dd/MM/yyyy' }}</strong>
                    </p>
                    <p class="m-0 caption text-hint">Date de début d'application</p>
                </div>

                <div fxLayout.lt-md="row-reverse" fxLayoutAlign.lt-md="start center" fxLayoutGap.lt-md="5px">
                    <p  class="m-0 body-1"><strong>{{ topic.contract?.references }}</strong></p>
                    <p class="m-0 caption text-hint">Références au catalogue</p>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="topic.genericHotTopic.carrouselStyle.toLowerCase() === 'description'">
            <div style="margin: 20px" fxFlex="auto">
                <p class='m-0 caption text-hint'>Actualités</p>
                <h2><strong>{{ topic.genericHotTopic.type }}</strong></h2>
                <p style="opacity: .7">
                    <quill-editor
                        [modules]="quillConfig"
                        [readOnly]="true"
                        [ngModel]="topic.description"
                    ></quill-editor>
                </p>
            </div>
        </ng-container>
</ng-template>