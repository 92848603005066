import { Directive, HostBinding, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[drag-n-drop]'
})
export class DragNDropDirective {

  constructor() {
   }

  @HostBinding('class.fileover') fileOver: boolean;
  @Output() fileDropped = new EventEmitter<any>();
  @Output() fileDragover = new EventEmitter<Boolean>();

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    console.info('dragover');
    evt.preventDefault();
    evt.stopPropagation();
    if(!this.fileOver){
      this.fileOver = true;
      this.fileDragover.emit(true);
    }
    
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    console.info('dragleave');
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    this.fileDragover.emit(false);
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    console.info('drop');
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    this.fileDragover.emit(false);
    let files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }
  
}
