<vex-page-layout>
    <vex-page-layout-header class='pb-4' fxLayout='column' fxLayoutAlign='center start'>
<!--        <div class='w-full flex flex-col sm:flex-row justify-between container'>-->
<!--            <div>-->
<!--                <h1 class='title mt-0 mb-1'>Contact interne</h1>-->
<!--                <vex-breadcrumbs [crumbs]="['Contact interne']"></vex-breadcrumbs>-->
<!--            </div>-->
<!--        </div>-->
    </vex-page-layout-header>

    <vex-page-layout-content class='-mt-6'>
       <!-- PREVIOUS PERMISSION
       [createPermission]="[permission.CREATE_CP_INTERNAL_CONTACT]"
       -->
        <div class='card overflow-auto -mt-16'>
            <base-data-table-header
                label='Contact interne'
                [loading]='loading'
                [showArchives]="canShowArchivesPermission"
                (onSearchChange)='onSearchChange($event)'
                (onShowArchived)='onShowArchived($event)'
                (onClearTextFilter)='clearTextFilter()'
                (onCreate)='createItem()'
                [canCreate]='true'
                [popupFilter]='true'
                headerPopupTitle='Filtres avancés'
                [contentPopupTemplate]='contentPopupTemplate'
                [countPopupFilter]='countPopupFilter'
                (clearPopupFilter)='clearPopupFilter()'
                (validatePopupFilter)='validatePopupFilter()'
                [canImport]='true'
                [canExport]='true'
                (onImport)='importItem()'
                (onExport)='exportItem()'
                [createPermission]="[permission.CREATE_INTERNAL_CONTACT]"
            ></base-data-table-header>
            <!-- PREVIOUS PERMISSION
            [deletePermission]="[permission.DELETE_CP_INTERNAL_CONTACT]"
            -->
            <base-data-table
                [count]='count'
                [pageSize]='pageSize'
                [sortField]='sortField'
                [sortOrder]='sortOrder'
                [dataSource]='dataSource'
                [displayedColumns]='displayedColumns'
                [feisColumns]='feisColumns'
                [routerLinkDetailRow]='routerLinkDetailRow'
                [stickyTop]='true'
                (onPageEvent)='pageEvent($event)'
                (onSortEvent)='sortEvent($event)'
                (onUpdateItem)='updateItem($event)'
                (onDeleteItem)='deleteItem($event)'
                (onArchiveItem)="archiveItem($event)"
            ></base-data-table>
        </div>
    </vex-page-layout-content>
</vex-page-layout>

<ng-template #contentPopupTemplate>
    <form [formGroup]='popupForm'>
        <base-form-input
                label='Civilité'
                type='multiple'
                [items]='popupGender'
                controlName='gender'
        >
        </base-form-input>
        <base-form-input
            label='Etablissement'
            type='autocomplete'
            [items$]='establishment$'
            (autocompleteChange)='establishmentAutoCompleteChange($event)'
            displayProperty='label'
            controlName='establishment'
        >
        </base-form-input>
    </form>
</ng-template>
