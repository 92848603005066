import { Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { LayoutService } from '../../../../@vex/services/layout.service';
import { ConfigService } from '../../../../@vex/services/config.service';
import { NavigationService } from '../../../../@vex/services/navigation.service';
import { PopoverService } from '../../../../@vex/components/popover/popover.service';
import icSearch from '@iconify/icons-ic/twotone-search';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import icPersonAdd from '@iconify/icons-ic/twotone-person-add';
import icAssignmentTurnedIn from '@iconify/icons-ic/twotone-assignment-turned-in';
import icBallot from '@iconify/icons-ic/twotone-ballot';
import icDescription from '@iconify/icons-ic/twotone-description';
import icAssignment from '@iconify/icons-ic/twotone-assignment';
import icReceipt from '@iconify/icons-ic/twotone-receipt';
import icDoneAll from '@iconify/icons-ic/twotone-done-all';
import icBookmarks from '@iconify/icons-ic/twotone-bookmarks';
import emojioneUS from '@iconify/icons-emojione/flag-for-flag-united-states';
import emojioneDE from '@iconify/icons-emojione/flag-for-flag-germany';
import icMenu from '@iconify/icons-ic/twotone-menu';
import { VersionInfo } from '../../../../models/version-info.model';
import { VersionService } from '../../../../@vex/services/version.service';
import moment from 'moment/moment';


@Component({
  selector: 'ui-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  @Input() mobileQuery: boolean;

  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean;

  navigationItems = this.navigationService.items;

  // @ts-ignore
  isHorizontalLayout$ = this.configService.config$.pipe(map(config => config.layout === 'horizontal'));
  // @ts-ignore
  isVerticalLayout$ = this.configService.config$.pipe(map(config => config.layout === 'vertical'));
  // @ts-ignore
  isNavbarInToolbar$ = this.configService.config$.pipe(map(config => config.navbar.position === 'in-toolbar'));
  // @ts-ignore
  isNavbarBelowToolbar$ = this.configService.config$.pipe(map(config => config.navbar.position === 'below-toolbar'));

  icSearch = icSearch;
  icBookmarks = icBookmarks;
  emojioneUS = emojioneUS;
  emojioneDE = emojioneDE;
  icMenu = icMenu;
  icPersonAdd = icPersonAdd;
  icAssignmentTurnedIn = icAssignmentTurnedIn;
  icBallot = icBallot;
  icDescription = icDescription;
  icAssignment = icAssignment;
  icReceipt = icReceipt;
  icDoneAll = icDoneAll;
  icArrowDropDown = icArrowDropDown;




  constructor(private layoutService: LayoutService,
              private configService: ConfigService,
              private navigationService: NavigationService,
              private popoverService: PopoverService,
              ) {

  }

  ngOnInit() {

  }


  openQuickpanel() {
    this.layoutService.openContractLinepanel();
  }

  openSidenav() {
    this.layoutService.openSidenav();
  }

  openMegaMenu(origin: ElementRef | HTMLElement) {
    // this.popoverService.open({
    //   content: MegaMenuComponent,
    //   origin,
    //   position: [
    //     {
    //       originX: 'start',
    //       originY: 'bottom',
    //       overlayX: 'start',
    //       overlayY: 'top'
    //     },
    //     {
    //       originX: 'end',
    //       originY: 'bottom',
    //       overlayX: 'end',
    //       overlayY: 'top',
    //     },
    //   ]
    // });
  }

  openSearch() {
    this.layoutService.openSearch();
  }

}
