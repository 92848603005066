import {ChangeDetectorRef, Component, Inject, OnInit, Self} from '@angular/core';
import {ENUM_PERMISSIONS} from "../../../enums/permission.enum";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import icClose from '@iconify/icons-ic/twotone-close';
import jsPDF from 'jspdf';

@Component({
  selector: 'vex-document-preview-modal',
  templateUrl: './document-preview-modal.component.html',
  styleUrls: ['./document-preview-modal.component.scss']
})
export class DocumentPreviewModalComponent implements OnInit {

  ENUM_PERMISSION = ENUM_PERMISSIONS;
  loading = false;
  icClose = icClose;
  fileBlob: Blob;
  fileName: string;

  url: SafeResourceUrl;
  isPdf: boolean;

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: { fileBlob: Blob, fileName: string},
      public dialogRef: MatDialogRef<DocumentPreviewModalComponent>,
      private sanitizer: DomSanitizer,
      private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.loading = true;

    this.fileBlob = this.data.fileBlob;
    this.fileName = this.data.fileName;

    const blobUrl = URL.createObjectURL(this.fileBlob);
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);

    this.isPdf = this.fileName.toLowerCase().endsWith('.pdf');
    
    this.loading = false;
  }

  // checkPDFExtension(url: string): boolean {
  //   return url.toLowerCase().endsWith('.pdf');
  // }

  // convertToPDF(fileBlob: Blob): void {
  //   const img = new Image();
  //   img.src = URL.createObjectURL(fileBlob);
  //
  //   img.onload = () => {
  //     const imgWidth = img.width;
  //     const imgHeight = img.height;
  //
  //     const pdfWidth = imgWidth * 0.75;
  //     const pdfHeight = imgHeight * 0.75;
  //
  //     // Create a jsPDF instance with a custom page size matching the image
  //     const pdf = new jsPDF({
  //       orientation: 'landscape',
  //       unit: 'pt',
  //       format: [pdfWidth, pdfHeight],
  //     });
  //
  //     const format = fileBlob.type.split('/')[1];
  //
  //     pdf.addImage(img, format, 0, 0, pdfWidth, pdfHeight);
  //     this.fileBlob = pdf.output('blob');
  //     this.fileName = this.fileName.replace(/\.[^.]+$/, '.pdf');
  //
  //     this.loading = false;
  //   };
  //
  //   img.onerror = () => {
  //     console.error('Failed to load image.');
  //     this.loading = false;
  //   };
  // }

  close(result: any = null) {
    this.dialogRef.close(result);
  }

}
