import {Component, Inject, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {AddressModel} from "../../../../../../core/models/address.model";
import {CrudModeEnum} from "../../../../../../core/base/enum/crud-mode.enum";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AddressType} from "../../../../../../core/enums/address-type.enum";
import {Country} from "../../../../../../core/enums/countries.enum";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SupplierService} from "../../../supplier/supplier.service";
import {ActivatedRoute} from "@angular/router";
import {ErrorApi} from "../../../../../../core/models/api/error-api";
import {EstablishmentService} from "../../establishment.service";
import {b64ToBlob} from "../../../../../../core/helpers/b64ToBlob.helper";
import {ContentTypeEnum} from "../../../../../../core/base/enum/content-type.enum";
import {FileSaverService} from "ngx-filesaver";
import {AuthService} from "../../../../../../core/services/auth.service";
import {ENUM_PERMISSIONS} from "../../../../../../core/enums/permission.enum";

@Component({
    selector: "vex-establishment-address-create-update-delete",
    templateUrl: "./establishment-address-create-update-delete.component.html",
    styleUrls: ["./establishment-address-create-update-delete.component.scss"]
})
export class EstablishmentAddressCreateUpdateDeleteComponent implements OnInit {

    protected subscription = new Subscription();
    defaults: AddressModel;
    mode: CrudModeEnum = CrudModeEnum.Create;
    form: FormGroup;
    importForm: FormGroup;
    loading: boolean = false;
    establishmentId: string;
    typeItem: AddressType[];
    countryItem: Country[];
    importDefaults: any;
    canDownloadModel: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        public dialogRef: MatDialogRef<EstablishmentAddressCreateUpdateDeleteComponent>,
        private supplierService: SupplierService,
        public establishmentService: EstablishmentService,
        public activatedRoute: ActivatedRoute,
        private fileSaver: FileSaverService,
        private authService: AuthService
    ) {
        if (this.data) {
            this.defaults = this.data?.defaults ?? {} as AddressModel;
            this.mode = this.data.mode;
        }
    }

    ngOnInit(): void {
        this.establishmentId = this.data.id;
        this.typeItem = Object.values(AddressType);
        this.countryItem = Object.values(Country);

        if (this.isImportMode()) {
            this.importForm = new FormGroup({
                file: new FormControl("", [Validators.required])
            });
        }

        this.form = new FormGroup({
            _id: new FormControl(this.defaults?._id),
            type: new FormControl(this.defaults?.type, [Validators.required]),
            street: new FormControl(this.defaults?.street, [Validators.required]),
            additionalAddress: new FormControl(this.defaults?.additionalAddress),
            postalCode: new FormControl(this.defaults?.postalCode, [Validators.required, Validators.pattern(/^[0-9]{5}$/)]),
            city: new FormControl(this.defaults?.city, [Validators.required]),
            country: new FormControl(this.defaults?.country, [Validators.required])
        });

        this.authService.getCurrentUserPermissions$().subscribe(permissions => {
            this.canDownloadModel = permissions.includes(ENUM_PERMISSIONS.DOWNLOAD_CP_ESTABLISHMENT_INFO)
        })
    }

    save(): void {
        if (this.isCreateMode()) {
            this.createItem();
        } else if (this.isUpdateMode()) {
            this.updateItem();
        } else if (this.isImportMode()) {
            this.importItems();
        }
    }

    close(data?: any): void {
        this.dialogRef.close(data);
    }

    isDeleteMode() {
        return this.mode === CrudModeEnum.Delete;
    }

    isCreateMode() {
        return this.mode === CrudModeEnum.Create;
    }

    isUpdateMode() {
        return this.mode === CrudModeEnum.Update;
    }

    isImportMode(): boolean {
        return this.mode === CrudModeEnum.Import;
    }

    createItem() {
        this.loading = true;
        this.defaults = this.form.value;
        if (this.form.valid) {
            const sub = this.establishmentService.createAddress(this.establishmentId, this.defaults).subscribe(
                res => {
                    this.close(res);
                    this.loading = false;
                },
                err => {
                    this.setErrorsMessages(err.error.errors);
                    this.loading = false;
                }
            );
            this.subscription.add(sub);
        } else {
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    updateItem() {
        this.loading = true;
        this.defaults = this.form.value;

        if (this.form.valid) {
            const sub = this.establishmentService.updateAddress(this.establishmentId, this.defaults).subscribe(
                result => {
                    this.close(result);
                    this.loading = false;
                }, error => {
                    this.setErrorsMessages(error.error.errors);
                    this.loading = false;
                });
            this.subscription.add(sub);
        } else {
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    setErrorsMessages(errors: Array<ErrorApi>) {
        errors?.forEach(error => {
            let err = this.form.controls[error.property].errors;
            if (err === null) {
                err = {messages: []};
            }
            err.messages.push(error.message);
            this.form.controls[error.property].setErrors(err);
        });
        this.form.markAllAsTouched();
    }

    deleteItem() {
        this.loading = true;
        const sub = this.establishmentService.deleteAddress(this.establishmentId, this.defaults?._id).subscribe(
            result => {
                this.close(result);
                this.loading = false;
            }, error => {
                this.setErrorsMessages(error.error.errors);
                this.loading = false;
            }
        );
        this.subscription.add(sub);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    importItems() {
        this.importDefaults = this.importForm.value;
        this.beforeImportItem();
        if (this.importForm.valid) {
            this.loading = true;
            const sub = this.establishmentService.importAddress(this.importDefaults, this.establishmentId).subscribe(
                result => {
                    this.close(result);
                }, error => {
                    this.setImportErrorsMessages(error.error.errors);
                    this.loading = false;
                });
            this.subscription.add(sub);
        } else {
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    beforeImportItem() {
        let formData = new FormData();

        if (!this.importDefaults?.file?._files?.length) {
            this.importForm.controls["file"].setErrors({required: true});
            this.importForm.markAllAsTouched();
            return;
        }

        formData.append("file-excel", this.importDefaults.file._files[0]);
        this.importDefaults = formData;
    }

    setImportErrorsMessages(errors: Array<ErrorApi>) {
        errors?.forEach(error => {
            let err = this.importForm.controls[error.property].errors;
            if (err === null) {
                err = {messages: []};
            }
            err.messages.push(error.message);
            this.importForm.controls[error.property].setErrors(err);
        });
        this.importForm.markAllAsTouched();
    }

    exportAddressModel() {
        this.establishmentService.downloadExcelAddressTemplate().subscribe((x: any) => {
            const b = b64ToBlob(x.data.b64, ContentTypeEnum.excel);
            this.fileSaver.save(b, x.data.fileName);
        });
    }
}
