import {Injectable} from '@angular/core';
import {GenericPrestationModel} from 'src/app/core/models/generic-prestation.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BaseService} from 'src/app/core/base/base.service';
import {GenericProductModel} from "../../../../core/models/generic-product.model";
import {Observable} from "rxjs";
import {ObjectApiInterface} from "../../../../core/base/interfaces/object-api.interface";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class GenericPrestationService extends BaseService<GenericPrestationModel> {
    defaultSort = 'createdAt';

    constructor(public http: HttpClient) {
        super(http, 'generic-prestation');
    }

    findAll(page?: number, perPage?: number, sortField: string = this.defaultSort, sortOrder: string = 'desc', search?: string, filters?: GenericPrestationModel | any, option?: any): Observable<ObjectApiInterface<GenericPrestationModel[]>> {

        if (option) {
            return this.http.get<ObjectApiInterface<GenericPrestationModel[]>>(`${this.baseUrlApi}/list/${option}`, {
                params: new HttpParams()
                    .set('page', page?.toString() ?? '1')
                    .set('perPage', perPage?.toString() ?? '30')
                    .set('sort', `${sortField}@${sortOrder}`)
                    .set('search', search ?? '')
                    .set('filters', JSON.stringify(filters) ?? '')
            });
        }

        return this.http.get<ObjectApiInterface<GenericPrestationModel[]>>(`${this.baseUrlApi}/list`, {
                params: new HttpParams()
                    .set('page', page?.toString() ?? '1')
                    .set('perPage', perPage?.toString() ?? '30')
                    .set('sort', `${sortField}@${sortOrder}`)
                    .set('search', search ?? '')
                    .set('filters', JSON.stringify(filters) ?? '')
            }
        );
    }

    findAllGenItemByGenEngagement(data: string | string [], search?: string, filters?: any) {
        return this.http.get<ObjectApiInterface<GenericPrestationModel[]>>(`${this.baseUrlApi}/listByGenContract`,
            {
                params: new HttpParams()
                    .set('search', search ?? '')
                    .set('filters', JSON.stringify(filters) ?? '')
                    .set('data', JSON.stringify(data) ?? '')
            }
        )
            .pipe(map(x => {
                return x.data;
            }));
    }
}


