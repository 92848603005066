<div class="mt-20">
    <vex-page-layout>
        <vex-page-layout-content class='-mt-6'>
            <div class='card overflow-auto -mt-16'>
                <base-data-table-header
                    label='Historique des métriques'
                    [loading]='loading'
                    [canCreate]='true'
                    (onCreate)='createItem()'
                    (onSearchChange)='onSearchChange($event)'
                ></base-data-table-header>

                <base-data-table
                    [count]='count'
                    [dataSource]='dataSource'
                    [displayedColumns]='displayedColumns'
                    [feisColumns]='feisColumns'
                    [pageSize]='pageSize'
                    [routerLinkDetailRow]='routerLinkDetailRow'
                    [sortField]='sortField'
                    [sortOrder]='sortOrder'
                    (onArchiveItem)='archiveItem($event)'
                    (onPageEvent)='pageEvent($event)'
                    (onSortEvent)='sortEvent($event)'
                    (onUpdateItem)='updateItem($event)'
                    (onDeleteItem)='deleteItem($event)'
                ></base-data-table>
            </div>
        </vex-page-layout-content>
    </vex-page-layout>
</div>