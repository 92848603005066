import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserProfileModel} from 'src/app/core/models/user-profile.model';
import {FormModalBaseComponent} from 'src/app/core/base/components/form-modal-base/form-modal-base.component';
import {Observable} from 'rxjs';
import {RoleModel} from "../../../../models/role.model";
import {
  UserProfileValidatorLevel,
  userProfileValidatorLevelTransalation
} from "../../../../enums/user-profile-validator-seuil.enum";
import {RoleService} from "../../../../../pages/application/admin/role/role.service";
import {ContactTypeModel} from "../../../../models/contact-type.model";
import {AuthService} from "../../../../services/auth.service";
import {ROLE_USER_PROFILE} from "../../../../enums/role_user_profile.enum";
import {Gender} from "../../../../enums/gender.enum";
import {Typology} from "../../../../enums/typology.enum";
import {BaseContactType} from "../../../../enums/contact-type.enum";
import {UserProfileService} from "../../../../../pages/application/management/user-profile/user-profile.service";
import {ContactTypeService} from "../../../../../pages/application/settings/contact-type/contact-type.service";
import {ENUM_PERMISSIONS} from "../../../../enums/permission.enum";


@Component({
  selector: 'vex-profil-create-update-delete',
  templateUrl: './profil-create-update-delete.component.html',
  styleUrls: ['./profil-create-update-delete.component.scss']
})
export class ProfilCreateUpdateDeleteComponent extends FormModalBaseComponent<UserProfileModel> implements OnInit {

  allRoles$: Observable<RoleModel[]>;
  contactType: ContactTypeModel[] = [];
  genderItem: string[];
  levels = [];
  ENUM_PERMISSION = ENUM_PERMISSIONS;
  temporayRole: Array<string>;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<FormModalBaseComponent<UserProfileModel>>,
              private roleService: RoleService,
              public service: UserProfileService,
              private contactTypeService: ContactTypeService,
              public authService: AuthService,
  ) {
    super(data, dialogRef, service);
  }

  ngOnInit() {
    super.ngOnInit();
    this.genderItem = Object.values(Gender);
    // this.allRoles$ = this.roleService.selectItems();
    this.levels = Object.keys(UserProfileValidatorLevel);

    this.temporayRole = Object.values(ROLE_USER_PROFILE);

    this.contactTypeService.findAll().subscribe(x => {
      this.contactType = [...BaseContactType, ...x.data].filter(x => x.typology === Typology.intern);

      if (this.isUpdateMode()) {
        const a = [];
        this.defaults.contactType.forEach(x => {
          const ct = this.contactType.findIndex(y => y._id === x._id);
          if (ct !== -1) {
            a.push(this.contactType[ct]);
          }
        });
        this.form.controls.contactType.setValue(a);
      }
    });

    this.form = new FormGroup({
      _id: new FormControl(this.defaults?._id),
      firstName: new FormControl(this.defaults?.firstName, [Validators.required]),
      lastName: new FormControl(this.defaults?.lastName, [Validators.required]),
      mobileNumber: new FormControl(this.defaults?.mobileNumber, [Validators.required]),
      mcEmail: new FormControl(this.defaults?.mcEmail, [Validators.required, Validators.email]),
      email: new FormControl(this.defaults?.email, [Validators.required, Validators.email]),
      // role: new FormControl(this.defaults?.role, [Validators.required]),
      gender: new FormControl(this.defaults?.gender),
      // idDocuware: new FormControl(this.defaults?.idDocuware),
      contactType: new FormControl(null),
      roles: new FormControl(this.defaults.roles ?? []),
      validatorMatriceLevel: new FormControl(this.defaults?.validatorMatriceLevel, [Validators.required]),
      // isPredik: new FormControl(this.defaults?.isPredik ?? false, []),
      notifyByEmail: new FormControl(this.defaults?.notifyByEmail ?? false, []),
    });

    if (this.isCreateMode()) {
      // this.allRoles$.subscribe(role => {
      //     this.form.controls.role.setValue(role.find(x => x.name === 'admin'));
      // });
    }

    if (this.authService.currentUserValue().isPredik) {
      this.form.addControl('isPredik', new FormControl(this.defaults?.isPredik  ?? false));
    }

    if (this.isUpdateMode()) {
      this.form.controls.mcEmail.disable();
    }

  }

  beforeCreateItem() {
    // this.defaults.contactType = this.defaults?.contactType?.map(x => x.label);
    // Used to remove the "gender" property if null before sending to back-end. Otherwise, it won't accept it
    if (this.defaults?.gender == null) {
      delete this.defaults.gender;
    }
  }

  afterCreateItem(result?: any, error?: any) {
    if (result) {
      this.close(result.data);
    }
  }

  beforeUpdateItem() {
    // this.defaults.contactType = this.defaults?.contactType?.map(x => x.label);
    delete this.defaults.roles;
  }

  afterUpdateItem(result?: any, error?: any) {
    if (result) {
      this.close(true);
    }
  }

  levelTranslation(level: string) {
    return userProfileValidatorLevelTransalation(UserProfileValidatorLevel[level]);
  }
}

