<mat-form-field [appearance]="appearance" color='primary' [formGroup]="formGroup" class="flex-auto w-full" [ngClass]="{'without-padding': withoutPadding}">
    <mat-label>{{ label }}</mat-label>
    <input [min]="minNumber" [max]="maxNumber" type="number" [formControlName]="controlName" (blur)="onLostFocus.emit()"
           matInput>
    <button *ngIf="obscure" (click)="toggleInput()" mat-icon-button matSuffix type="button">
        <mat-icon *ngIf="visible" [icIcon]="icVisibility"></mat-icon>
        <mat-icon *ngIf="!visible" [icIcon]="icVisibilityOff"></mat-icon>
    </button>
    <mat-icon *ngIf="icon" [icIcon]="icon" class="mr-3" matPrefix></mat-icon>
    <mat-hint>{{ hint }}</mat-hint>

    <base-form-input-errors
        [control]="formGroup.controls[controlName]"
        [controlName]="controlName"
        [errorMessages]="defaultErrorMessage"
        [invalidPatternMessage]="invalidPatternMessage"
        [customErrorMessage]="customErrorMessage">
    </base-form-input-errors>
</mat-form-field>