<ng-container *ngIf="isCreateMode() || isUpdateMode()">
    <form (ngSubmit)="save()" [formGroup]="form">
        <base-form-header [mode]='mode' createLabel="Ajouter une adresse" [updateLabel]='form.get("type").value' ></base-form-header>

        <mat-dialog-content fxLayout="column">

            <base-form-input
                label="Type d'adresse"
                type="autocomplete"
                controlName="type"
                [items]="typeItem"
            ></base-form-input>

            <base-form-input
                label='Adresse'
                controlName='street'>
            </base-form-input>

            <base-form-input
                label="Complément d'adresse"
                controlName='additionalAddress'>
            </base-form-input>

            <base-form-input
                label='Code Postal'
                controlName='postalCode'>
            </base-form-input>

            <base-form-input
                label='Ville'
                controlName='city'>
            </base-form-input>

            <base-form-input
                label='Pays'
                type="autocomplete"
                controlName='country'
                [items]="countryItem"
            >
            </base-form-input>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <base-form-input
                [loading]="loading"
                type='submit'
                [mode]='mode'
                entityLabel='SUPPLIER'
                (onCreate)='createItem()'
                (onUpdate)='updateItem()'
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>

<ng-container *ngIf="isDeleteMode()">
    <form-modal-delete-base
        [label]="data.name"
        defaultMessage="Etes-vous sûr de vouloir supprimer cette adresse ?"
        (onClose)="close()"
        (onDelete)="deleteItem()"
    ></form-modal-delete-base>
</ng-container>

<ng-container *ngIf="isImportMode()">
    <vex-form-import-base
        [modalTitle]='"Importer des adresses"'
        [entityLabel]='"Adresse"'
        [form]='importForm'
        [service]='establishmentService'
        [mode]='mode'
        (saveForm)='save()'
        (importItems)='importItems()'
        [customExport]='true'
        (customExportItems)='exportAddressModel()'
        [downloadModel]="canDownloadModel"
        [canDownloadTemplateModel]='true'
    ></vex-form-import-base>
</ng-container>
