import {Component, Inject, OnInit} from '@angular/core';
import {CrudModeEnum} from '../../../../../../core/base/enum/crud-mode.enum';
import {ErrorApi} from '../../../../../../core/models/api/error-api';
import {Observable, Subscription} from 'rxjs';
import {FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {AddressType} from '../../../../../../core/enums/address-type.enum';
import {Country} from '../../../../../../core/enums/countries.enum';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SupplierService} from '../../supplier.service';
import {ActivatedRoute} from '@angular/router';
import {CategoryModel} from '../../../../../../core/models/category.model';
import {CategoryService} from '../../../../settings/category/category.service';

@Component({
    selector: 'vex-supplier-famille-create-update-delete',
    templateUrl: './supplier-famille-create-update-delete.component.html',
    styleUrls: ['./supplier-famille-create-update-delete.component.scss']
})
export class SupplierFamilleCreateUpdateDeleteComponent implements OnInit {

    protected subscription = new Subscription();
    defaults: CategoryModel;
    mode: CrudModeEnum = CrudModeEnum.Create;
    form: FormGroup;
    loading: boolean = false;
    supplierId: string;
    typeItem: AddressType[];
    countryItem: Country[];
    categories$: Observable<CategoryModel[]>;
    categories: Array<any> = []

    totalPercent: number;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        public dialogRef: MatDialogRef<SupplierFamilleCreateUpdateDeleteComponent>,
        private supplierService: SupplierService,
        private categoryService: CategoryService,
        public activatedRoute: ActivatedRoute
    ) {
        if (this.data) {
            this.defaults = this.data?.defaults ?? {} as CategoryModel;
            this.mode = this.data.mode;
        }
    }

    canCreateMode: boolean = false;
    canUpdateMode: boolean = false;
    canDeleteMode: boolean = false;
    canReadMode: boolean = false;


    ngOnInit(): void {

        this.canCreateMode = this.isCreateMode();
        this.canUpdateMode = this.isUpdateMode();
        this.canDeleteMode = this.isDeleteMode();


        this.supplierId = this.data.id;
        this.totalPercent = this.data.totalPercent;
        this.categoryService.searchByLevel(1).subscribe(res => {
            res.sort((a, b) => a.link.localeCompare(b.link));
            this.categories = res
        });

        // if (this.mode === CrudModeEnum.Update) {
        //     this.totalPercent -= this.defaults.percent;
        // }

        this.form = new FormGroup({
            _id: new FormControl(this.defaults?._id),
            category: new FormControl(this.defaults?.category, [Validators.required]),
            // percent: new FormControl(this.defaults?.percent, [Validators.required])
        });
    }

    save(): void {
        if (this.isCreateMode()) {
            this.createItem();
        } else if (this.isUpdateMode()) {
            this.updateItem();
        }
    }

    close(data?: any): void {
        this.dialogRef.close(data);
    }

    isDeleteMode() {
        return this.mode === CrudModeEnum.Delete;
    }

    isCreateMode() {
        return this.mode === CrudModeEnum.Create;
    }

    isUpdateMode() {
        return this.mode === CrudModeEnum.Update;
    }

    createItem() {
        this.loading = true;
        this.defaults = this.form.value;
        // if (this.form.valid && (this.totalPercent + this.form.controls.percent.value <= 100)) {
        if (this.form.valid) {
            const sub = this.supplierService.createFamille(this.supplierId, this.defaults).subscribe(
                res => {
                    this.close(res);
                    this.loading = false;
                },
                err => {
                    this.setErrorsMessages(err?.error?.errors);
                    this.loading = false;
                }
            );
            this.subscription.add(sub);
        }
            // else if (this.totalPercent + this.form.controls.percent.value > 100) {
            //     this.loading = false;
            //     this.form.controls.percent.setErrors({
            //         messages: ['Le pourcentage total ne doit pas dépasser les 100%']
            //     });
        // }
        else {
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    updateItem() {
        this.loading = true;
        this.defaults = this.form.value;
        // if (this.form.valid && (this.totalPercent + this.form.controls.percent.value <= 100)) {
        if (this.form.valid) {
            const sub = this.supplierService.updateFamille(this.supplierId, this.defaults).subscribe(
                result => {
                    this.close(result);
                    this.loading = false;
                }, error => {
                    this.setErrorsMessages(error.error.errors);
                    this.loading = false;
                });
            this.subscription.add(sub);
        }
            // else if (this.totalPercent + this.form.controls.percent.value > 100) {
            //     this.loading = false;
            //     this.form.controls.percent.setErrors({
            //         messages: ['Le pourcentage total ne doit pas dépasser les 100%']
            //     });
            //     this.form.get('percent').markAsTouched();
        // }
        else {
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    setErrorsMessages(errors: Array<ErrorApi>) {
        errors?.forEach(error => {
            let err = this.form.controls[error.property].errors;
            if (err === null) {
                err = {messages: []};
            }
            err.messages.push(error.message);
            this.form.controls[error.property].setErrors(err);
        });
        this.form.markAllAsTouched();
    }

    deleteItem() {
        this.loading = true;
        const sub = this.supplierService.deleteFamille(this.supplierId, this.defaults?._id).subscribe(
            result => {
                this.close(result);
                this.loading = false;
            }, error => {
                this.setErrorsMessages(error.error.errors);
                this.loading = false;
            }
        );
        this.subscription.add(sub);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    // categoriesChange(state: any) {
    //     this.categories$ = this.categoryService.searchByLevel(0, state);
    // }

}
