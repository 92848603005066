import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ObjectApi } from '../../../../core/models/api/object-api';
import { RoleModel } from '../../../../core/models/role.model';
import { BaseService } from '../../../../core/base/base.service';

@Injectable({
    providedIn: 'root'
})
export class RoleService extends BaseService<RoleModel>{
    defaultSort = 'name';

    constructor(public http: HttpClient) {
        super(http, 'roles');
    }

    selectItems(): Observable<RoleModel[]> {
        return this.http.get<ObjectApi<RoleModel[]>>(`/api/role/list`)
            .pipe(map(response => response.data));
    }

}


