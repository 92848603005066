import { Injectable } from '@angular/core';
import { SupplierModel } from 'src/app/core/models/supplier.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseService } from 'src/app/core/base/base.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ObjectApiInterface } from '../../../../core/base/interfaces/object-api.interface';
import { SupplierContactModel } from '../../../../core/models/supplier-contact.model';
import { map } from 'rxjs/operators';
import { AddressModel } from '../../../../core/models/address.model';
import { CategoryModel } from '../../../../core/models/category.model';
import { ClientNumberModel } from '../../../../core/models/client-number-model';

@Injectable({
    providedIn: 'root'
})
export class SupplierService extends BaseService<SupplierModel> {
    defaultSort = 'createdAt';

    protected _contacts = new BehaviorSubject<SupplierContactModel[]>([] as SupplierContactModel[]);
    readonly contacts$ = this._contacts.asObservable();

    constructor(public http: HttpClient) {
        super(http, 'supplier');
    }

    findById(id: string): Observable<ObjectApiInterface<SupplierModel>> {
        return super.findById(id).pipe(map(x => {
            this._contacts.next(this._entity.value.contacts);
            return x;
        }))
    }

    findAllSupplierContactById(page?: number, perPage?: number, sortField: string = this.defaultSort, sortOrder: string = 'desc', search?: string, filters?: SupplierModel | any): Observable<ObjectApiInterface<SupplierContactModel[]>> {
        return this.http.get<ObjectApiInterface<SupplierContactModel[]>>(`${this.baseUrlApi}/supplier-contact/list`, {
                params: new HttpParams()
                    .set('page', page?.toString() ?? '1')
                    .set('perPage', perPage?.toString() ?? '30')
                    .set('sort', `${sortField}@${sortOrder}`)
                    .set('search', search ?? '')
                    .set('filters', JSON.stringify(filters) ?? '')
            }
        );
    }

    getContactTypeRelation(_id): Observable<ObjectApiInterface<SupplierModel[]>> {
        return this.http.get<ObjectApiInterface<SupplierModel[]>>(`${this.baseUrlApi}/getContactTypeRelation/${_id}`);
    }

    searchContacts(search: string) {
        if (search) {
            const match = this._entity.value.contacts.filter(x => {
                const email = x.email.includes(search.toLowerCase());
                const fullName = `${x.firstName} ${x.lastName}`.toLowerCase().includes(search.toLowerCase());
                const fullNameReverse = `${x.lastName} ${x.firstName}`.toLowerCase().includes(search.toLowerCase());
                const phone = x.mobileNumber.includes(search);
                return email || fullName || fullNameReverse || phone;
            });
            this._contacts.next(match);
        } else {
            this._contacts.next(this._entity.value.contacts);
        }
    }

    getContacts(supplierId: string): Observable<ObjectApiInterface<SupplierContactModel[]>> {
        return this.http.get<ObjectApiInterface<SupplierContactModel[]>>(`${this.baseUrlApi}/${supplierId}/getContacts`);
    }

    createContact(supplierId: string, contact: SupplierContactModel): Observable<ObjectApiInterface<SupplierContactModel>> {
        return this.http.post<ObjectApiInterface<SupplierContactModel>>(`${this.baseUrlApi}/${supplierId}/createContact`, contact)
            .pipe(map(x => {
                const entity = this._entity.getValue();
                entity?.contacts?.push(x.data);
                this._entity.next(entity);
                return x;
            }));
    }

    deletingFamillies(){
        return  this.http.delete<ObjectApiInterface<SupplierModel>>(`${this.baseUrlApi}/deleteEmptyFamillies`);
    }

    deleteContact(id: string, contactId: string): Observable<ObjectApiInterface<SupplierContactModel>> {
        return this.http.delete<ObjectApiInterface<SupplierContactModel>>(`${this.baseUrlApi}/${id}/deleteContact/${contactId}`).pipe(map(x => {
                const entity = this._entity.getValue();
                const findedIdx = entity.contacts.findIndex(y => y._id === contactId);
                if (findedIdx !== -1) {
                    entity.contacts.splice(findedIdx, 1);
                    this._entity.next(entity);
                }
                return x;
            }
        ));
    }

    updateContact(id: string, data: any): Observable<ObjectApiInterface<SupplierModel>> {
        return this.http.put<ObjectApiInterface<SupplierModel>>(`${this.baseUrlApi}/${id}/updateContact/${data._id}`, data)
            .pipe(map(x => {
                this._entity.next(x.data);
                return x;
            }));
    }

    createAddress(supplierId: string, address: AddressModel): Observable<ObjectApiInterface<AddressModel>> {
        return this.http.post<ObjectApiInterface<AddressModel>>(`${this.baseUrlApi}/${supplierId}/createAddress`, address)
            .pipe(map(x => {
                const entity = this._entity.getValue();
                entity.addresses.push(x.data);
                this._entity.next(entity);
                return x;
            }));
    }

    deleteAddress(id: string, addressId: string): Observable<ObjectApiInterface<AddressModel>> {
        return this.http.delete<ObjectApiInterface<AddressModel>>(`${this.baseUrlApi}/${id}/deleteAddress/${addressId}`).pipe(map(x => {
                const entity = this._entity.getValue();
                const findedIdx = entity.addresses.findIndex(y => y._id === addressId);
                if (findedIdx !== -1) {
                    entity.addresses.splice(findedIdx, 1);
                    this._entity.next(entity);
                }
                return x;
            }
        ));
    }

    updateAddress(id: string, data: any): Observable<ObjectApiInterface<SupplierModel>> {
        return this.http.put<ObjectApiInterface<SupplierModel>>(`${this.baseUrlApi}/${id}/updateAddress/${data._id}`, data)
            .pipe(map(x => {
                this._entity.next(x.data);
                return x;
            }));
    }

    createFamille(supplierId: string, famille: CategoryModel): Observable<ObjectApiInterface<CategoryModel>> {
        return this.http.post<ObjectApiInterface<CategoryModel>>(`${this.baseUrlApi}/${supplierId}/createFamille`, famille)
            .pipe(map(x => {
                const entity = this._entity.getValue();
                entity.famillies.push(x.data);
                this._entity.next(entity);
                return x;
            }));
    }

    deleteFamille(id: string, familleId: string): Observable<ObjectApiInterface<CategoryModel>> {
        return this.http.delete<ObjectApiInterface<CategoryModel>>(`${this.baseUrlApi}/${id}/deleteFamille/${familleId}`).pipe(map(x => {
                const entity = this._entity.getValue();
                const findedIdx = entity.famillies.findIndex(y => y._id === familleId);
                if (findedIdx !== -1) {
                    entity.famillies.splice(findedIdx, 1);
                    this._entity.next(entity);
                }
                return x;
            }
        ));
    }

    updateFamille(id: string, data: any): Observable<ObjectApiInterface<SupplierModel>> {
        return this.http.put<ObjectApiInterface<SupplierModel>>(`${this.baseUrlApi}/${id}/updateFamille/${data._id}`, data)
            .pipe(map(x => {
                this._entity.next(x.data);
                return x;
            }));
    }

    findAllClientNumbers(supplierId: string, page?: number, perPage?: number, sortField: string = 'clientNumber', sortOrder: string = 'asc', search?: string, filters: any = {}) {
        if (!filters?.isArchive)
        {
            filters.isArchive = false;
        }
        return this.http.get<ObjectApiInterface<any[]>>(`${this.baseUrlApi}/${supplierId}/clientNumbers/list`, {
            params: new HttpParams()
                .set('page', page?.toString() ?? '1')
                .set('perPage', perPage?.toString() ?? '30')
                .set('sort', `${sortField}@${sortOrder}`)
                .set('search', search ?? '')
                .set('filters', JSON.stringify(filters) ?? '')
        });
    }

    createClientNumber(supplierId: string, data: ClientNumberModel): Observable<ObjectApiInterface<ClientNumberModel>> {
        return this.http.post<ObjectApiInterface<ClientNumberModel>>(`${this.baseUrlApi}/${supplierId}/createClientNumber`, data)
            .pipe(map(x => {
                const entity = this._entity.getValue();
                entity?.clientNumbers?.push(x.data);
                this._entity.next(entity);
                return x;
            }));
    }

    deleteClientNumber(id: string, codeId: string): Observable<ObjectApiInterface<SupplierModel>> {
        return this.http.delete<ObjectApiInterface<SupplierModel>>(`${this.baseUrlApi}/${id}/clientNumbers/${codeId}/archive`).pipe(map(x => {
                const entity = this._entity.getValue();
                const findedIdx = entity.clientNumbers.findIndex(y => y._id === codeId);
                if (findedIdx !== -1) {
                    entity.clientNumbers.splice(findedIdx, 1);
                    this._entity.next(entity);
                }
                return x;
            }
        ));
    }

    updateClientNumber(id: string, data: any): Observable<ObjectApiInterface<SupplierModel>> {
        return this.http.put<ObjectApiInterface<SupplierModel>>(`${this.baseUrlApi}/${id}/updateClientNumber/${data._id}`, data)
            .pipe(map(x => {
                this._entity.next(x.data);
                return x;
            }));
    }

    import(entity: SupplierModel): Observable<ObjectApiInterface<SupplierModel>> {
        return super.import(entity);
    }

    importContact(contacts: any, supplierId: string): Observable<any> {
        return this.http.post<ObjectApiInterface<SupplierModel>>(`${this.baseUrlApi}/import-contact/${supplierId}`, contacts)
            .pipe(map(x => {
                const contacts = this._contacts.value;
                const newContacts = x.data.contacts?.filter(contact => !contacts.map((c => c._id)).includes(contact._id))
                this._contacts.value.push(...newContacts);
                return x;
            }));
    }
}
