import { AbstractControl } from '@angular/forms';
import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'base-form-input-errors',
    templateUrl: './form-input-errors.component.html',
    styleUrls: ['./form-input-errors.component.scss'],

})
export class FormErrorsComponent {
    @Input() control: AbstractControl;
    @Input() controlName: string;
    @Input() errorMessages: any = {};
    @Input() invalidPatternMessage: string;
    @Input() customErrorMessage: string;
}
