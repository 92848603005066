<div @fadeInRight class="card border-l-4 border-red text-orange-700 p-4" role="alert">
    <div class="flex flex-row w-full justify-between">
        <p class="font-bold">{{label}}</p>
        <button *ngIf="!disableClose" class="text-secondary ml-4" mat-dialog-close mat-icon-button type="button">
            <mat-icon [icIcon]="icClose" [color]="color ?? 'primary'" (click)="closeExportError()"></mat-icon>
        </button>
    </div>
    <ul class="list-disc pl-10" *ngIf="exportErrors">
        <li *ngFor="let error of exportErrors">
            {{error}}
        </li>
    </ul>
</div>