<ng-container *ngIf="isCreateMode() || isUpdateMode()">
    <form (ngSubmit)="save()" [formGroup]="form">
        <base-form-header [mode]='mode' createLabel='Ajouter un type de sol' [updateLabel]='form.get("floorType")?.value?.label'></base-form-header>

        <mat-dialog-content fxLayout="column">

            <base-form-input
                label='Type de sol'
                type='autocomplete'
                [items$]='floorType$'
                (autocompleteChange)='flootTypeAutocompleteChange($event)'
                displayProperty='label'
                controlName='floorType'>
            </base-form-input>

            <base-form-input
                label='Pourcentage'
                type='number'
                controlName='percent'>
            </base-form-input>

        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <base-form-input
                type='submit'
                [mode]='mode'
                entityLabel='FLOOR'
                (onCreate)='createItem()'
                (onUpdate)='updateItem()'
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>

<ng-container *ngIf="isDeleteMode()">
    <form-modal-delete-base
        [label]='data.name'
        [loading]='loading'
        (onClose)='close()'
        (onDelete)='deleteItem()'
    >
    </form-modal-delete-base>
</ng-container>
