import {Component, Input, OnInit} from '@angular/core';
import {scaleIn400ms} from "../../../../../@vex/animations/scale-in.animation";
import {fadeInRight400ms} from "../../../../../@vex/animations/fade-in-right.animation";
import {scaleFadeIn400ms} from "../../../../../@vex/animations/scale-fade-in.animation";
import {dropdownAnimation} from "../../../../../@vex/animations/dropdown.animation";
import {popoverAnimation} from "../../../../../@vex/animations/popover.animation";
import {SafeHtml} from "@angular/platform-browser";
import {ThemePalette} from "@angular/material/core";

@Component({
  selector: 'vex-banner-list-message',
  templateUrl: './banner-list-message.component.html',
  styleUrls: ['./banner-list-message.component.scss'],
  animations: [
    scaleIn400ms,
    fadeInRight400ms,
    scaleFadeIn400ms,
    scaleFadeIn400ms,
    dropdownAnimation,
    popoverAnimation
  ],
})
export class BannerListMessageComponent implements OnInit {

  @Input() title: string | SafeHtml;
  @Input() errors: string[] | SafeHtml[];
  @Input() alertType: 'warn' | 'alert' | 'default' = 'default';
  @Input() description: string = ''
  @Input() textButton: string;
  @Input() showButton = false;
  @Input() noMargin = false;
  @Input() callButton: () => any;

  colorButton: ThemePalette = 'primary';

  private alertColors: { [key: string]: ThemePalette } = {
    alert: 'warn',
    warn: 'warn',
    default: 'primary'
  };

  constructor() { }

  ngOnInit(): void {
    this.setColorButton();
    // if text is SafeHtml convert to string
  }

  private setColorButton(): void {
    this.colorButton = this.alertColors[this.alertType] || this.alertColors.default;
  }

  getBorderClasses(): string {
    let classVar = 'card border-l-4 p-4 mt-4';
    if (this.noMargin) {
      classVar = 'border-l-4 p-4';
    }
    switch (this.alertType) {
      case 'alert':
        return `${classVar} border-red`;
      case 'warn':
        return `${classVar} border-yellow`;
      default:
        return `${classVar} border-blue`; // J'ai ajouté l'accent fermant ici
    }
  }

}
