<ng-container>

    <div>
        <base-form-header
                [iconCustom]=iconCustom
                [createLabel]=dataRow?.label
        ></base-form-header>
    </div>

    <div class='px-gutter py-4 border-b reduced-top-margin'>
        <details-line-base
                [label]=dataRow?.comment
                hint='Information complémentaire'
        >
        </details-line-base>

            <strong>{{ supplierRow }}</strong>
    </div>

    <mat-divider class="mb-6"></mat-divider>

    <div class='px-gutter py-4 border-b reduced-top-margin' fxLayout='row' fxLayoutAlign='space-between' >
        <details-line-base
                [label]=dataRow?.reference
                hint='Référence fournisseur'
        >
        </details-line-base>

        <details-line-base
                [label]=dataRow?.previousReference
                hint='Référence précédente'
        >
        </details-line-base>
    </div>

    <div [ngClass]="{'light-gray-background': true}" *ngIf="hasDocumentation ; else noDocumentation">

        <div class='flex flex-row px-gutter border-b py-4 mb-6'>
            <mat-icon color="primary" class="mr-2">file_open</mat-icon>
            <h2 class='title m-0 ml-2'>Documentations</h2>
        </div>

        <div class="px-gutter py-4 reduced-top-margin">
            <ng-container *ngIf="isProductRow">
                <details-line-base
                        *ngIf="dataRow?.generalSupplierCatalog"
                        label='Catalogue général du fournisseur'
                        [link]=dataRow?.generalSupplierCatalog
                >
                </details-line-base>

                <details-line-base
                        *ngIf="dataRow?.technicalSheet"
                        label='Fiche technique'
                        [link]=dataRow?.technicalSheet
                >
                </details-line-base>

                <details-line-base
                        *ngIf="dataRow?.safetyUsageProtocol"
                        label='Protocole d’utilisation'
                        [link]=dataRow?.safetyUsageProtocol
                >
                </details-line-base>
            </ng-container>

            <ng-container *ngIf="!isProductRow">
                <details-line-base
                        *ngIf="dataRow?.generalSupplierCatalog"
                        label='Catalogue public du fournisseur'
                        [link]=dataRow?.generalSupplierCatalog
                >
                </details-line-base>

                <details-line-base
                        *ngIf="dataRow?.technicalSheet"
                        label='Fiche méthode'
                        [link]=dataRow?.technicalSheet
                >
                </details-line-base>

                <details-line-base
                        *ngIf="dataRow?.safetyUsageProtocol"
                        label='Protocole de sécurité'
                        [link]=dataRow?.safetyUsageProtocol
                >
                </details-line-base>
            </ng-container>
        </div>

    </div>

    <ng-template #noDocumentation>
        <div [ngClass]="{'light-gray-background': true}">
            <div class='flex flex-row px-gutter border-b py-4 mb-6 '>
                <mat-icon color="primary" class="mr-2">file_open</mat-icon>
                <h2 class='title m-0 ml-2'>Documentations</h2>
            </div>
            <div class="px-gutter py-4 reduced-top-margin">
                <p>Aucune documentation renseignée.</p>
            </div>
        </div>
    </ng-template>

</ng-container>