import { Component, Inject, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FeiColumn } from '../../../../../../../core/base/interfaces/fei-column.interface';
import icAdd from '@iconify/icons-ic/twotone-add';
import { BuildingDetailsRoomCreateUpdateDeleteComponent } from '../building-details-room-create-update-delete/building-details-room-create-update-delete.component';
import { CrudModeEnum } from '../../../../../../../core/base/enum/crud-mode.enum';
import { BuildingService } from '../../../building.service';
import { ActivatedRoute } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BuildingRoomFloorCreateUpdateDeleteComponent } from './building-room-floor-create-update-delete/building-room-floor-create-update-delete.component';

@Component({
  selector: 'vex-building-details-room-floor',
  templateUrl: './building-details-room-floor.component.html',
  styleUrls: ['./building-details-room-floor.component.scss']
})
export class BuildingDetailsRoomFloorComponent implements OnInit {

  dataSourceFloor: MatTableDataSource<any>;
  displayedColumnsFloor = [];
  feisColumnsFloor: Array<FeiColumn> = [
    { label: 'Type de sol', column: 'floorType', propertyDisplay: 'floorType.label'},
    { label: 'Pourcentage', column: 'percent', propertyDisplay: null, additionalDisplay: ' %'},
    { label: '', column: 'actions' }
  ];
  icAdd = icAdd;
  displayPercentWarning = false;
  count: any;
  pageSize: any;

  constructor(
      @Inject(MAT_DIALOG_DATA) public data,
      public buildingService: BuildingService,
      private activatedRoute: ActivatedRoute,
      private dialog: MatDialog
  )
  {
      this.displayedColumnsFloor = this.feisColumnsFloor.map(x => x.column);
  }

  ngOnInit(): void {
    this.initData();
  }

  initData() {
    this.buildingService.entity$.subscribe(building => {
      building.rooms.forEach(room => {
        if (room._id === this.data?.defaults?._id) {
          this.dataSourceFloor = new MatTableDataSource<any>(room.floor);
          this.displayPercentWarning = room.floor ? room.floor.reduce((p,c) => p + c.percent, 0) !== 100 : true;
        }
      })
    })
  }

  async createFloor() {
    this.dialog.open(BuildingRoomFloorCreateUpdateDeleteComponent, {
      data: {
        roomId: this.data?.defaults?._id,
        building: await this.buildingService.entity,
        floorIds: this.data?.defaults?.floor ? this.data?.defaults?.floor.map(x => x.floorType?._id) : [],
        mode: CrudModeEnum.Create
      }
    }).afterClosed().subscribe((room) => {
      if (room) {
        this.data?.defaults?.floor?.push(room);
        this.initData();
      }
    });
  }

  async updateItemFloor(element: any) {
    this.dialog.open(BuildingRoomFloorCreateUpdateDeleteComponent, {
      data: {
        roomId: this.data?.defaults?._id,
        building: await this.buildingService.entity,
        defaults: element,
        mode: CrudModeEnum.Update
      }
    }).afterClosed().subscribe((room) => {
      if (room) {
        this.initData();
      }
    });
  }

  async deleteItemFloor(element: any) {
    this.dialog.open(BuildingRoomFloorCreateUpdateDeleteComponent, {
      data: {
        roomId: this.data?.defaults?._id,
        building: await this.buildingService.entity,
        defaults: element,
        name: `${element.floorType?.label}`,
        mode: CrudModeEnum.Delete
      }
    }).afterClosed().subscribe((room) => {
      if (room) {
        const findedIdx = this.data?.defaults?.floor.findIndex(x => x._id === element._id);
        if (findedIdx !== -1) {
          this.data?.defaults?.floor?.splice(this.data?.defaults?.floor[findedIdx], 1);
        }
        this.initData();
      }
    });
  }

    pageEvent($event: any) {
        
    }
}
