import {Component} from '@angular/core';
import {DetailsBaseComponent} from '../../../../../core/base/components/details-base/details-base.component';
import {ActivatedRoute} from '@angular/router';
import {ProductService} from '../product.service';
import {ProductModel} from '../../../../../core/models/product.model';
import {AuthService} from "../../../../../core/services/auth.service";
import {ENUM_PERMISSIONS} from "../../../../../core/enums/permission.enum";

@Component({
    selector: 'vex-product-details',
    templateUrl: './product-details.component.html',
    styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent extends DetailsBaseComponent<ProductModel> {

    defaultTabs = [
        {
            label: 'INFORMATIONS',
            route: './',
            routerLinkActiveOptions: {exact: true},
            permission : ENUM_PERMISSIONS.READ_FM_REF_PURCHASE_INFO
        }
        // {
        //   label: 'FRIENDS',
        //   route: '',
        //   disabled: true
        // },
        // {
        //   label: 'PHOTOS',
        //   route: '',
        //   disabled: true
        // }
    ];

    constructor(public service: ProductService, public authService: AuthService, public activatedRoute: ActivatedRoute) {
        super(service, activatedRoute, authService);
    }


}

