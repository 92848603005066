<div *ngIf='!loading' class='py-3' fxLayout='row' fxLayoutAlign='start center' [ngClass]='{"cursor-pointer": link}' (click)='navigateTo()'>
    <div @fadeInRight *ngIf='type === "text"'>
        <div class='flex flex-row '>
<!--            <p class='m-0 body-1' [ngClass]='{"details-line-link": link}'>{{ label ?? '-' }}{{ additionalDisplay }}</p>-->
            <p class='m-0 body-1'>{{ label ?? '-' }}{{ additionalDisplay }}</p>
            <span [innerHTML]="extraHtml"></span>

<!--            <mat-icon *ngIf='link' [ngClass]='{"details-line-link": link}' class='text-base text-center flex flex-col justify-center' >open_in_new</mat-icon>-->
            <mat-icon *ngIf='link && isShowLink' class='ml-2 text-center details-line-link'>open_in_new</mat-icon>
        </div>
        <p class='m-0 caption text-hint'>{{ hint }}</p>
    </div>

    <div @fadeInRight *ngIf='type === "number"'>
        <p class='m-0 body-1' *ngIf='!label && label !== 0'>{{ '-'  }}</p>
        <p class='m-0 body-1' *ngIf='label || label === 0'>{{ round(label) | number: '1.2' }}{{ additionalDisplay }}</p>
        <p class='m-0 caption text-hint'>{{ hint }}</p>
    </div>

   <div @fadeInRight *ngIf='type === "timeDelay"'>
      <p class='m-0 body-1' *ngIf='!label && label !== 0'>{{ '-'  }}</p>
      <p class='m-0 body-1' *ngIf='label || label === 0'>{{ convertToUnit(label) }}{{ additionalDisplay }}</p>
      <p class='m-0 caption text-hint'>{{ hint }}</p>
   </div>

    <div @fadeInRight *ngIf='type === "boolean"'>
        <p class='m-0 body-1'>
            <a *ngIf='label' (click)='$event.stopPropagation()'
               class='w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-primary bg-primary-light'
               mat-icon-button>
                <mat-icon [icIcon]='icDone' size='18px'></mat-icon>
            </a>
            <a *ngIf='!label' (click)="$event.stopPropagation()"
               class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-accent bg-accent-light"
               mat-icon-button>
                <mat-icon [icIcon]="icClose" size="18px"></mat-icon>
            </a>
        </p>
        <p class='m-0 caption text-hint'>{{ hint }}</p>
    </div>

    <div @fadeInRight *ngIf='type === "date"'>
        <p class='m-0 body-1' *ngIf='label'>{{ label | date: DateFormat }}{{ additionalDisplay }}</p>
        <p class='m-0 body-1' *ngIf='!label'>-</p>
        <p class='m-0 caption text-hint'>{{ hint }}</p>
    </div>

    <div @fadeInRight *ngIf='type === "html"'>
        <div class='flex flex-row'>
            <div [innerHTML]="label"></div>
            <span [innerHTML]="extraHtml"></span>
            <mat-icon *ngIf='link' class='ml-2 text-center details-line-link'>open_in_new</mat-icon>
        </div>
        <p class='m-0 caption text-hint'>{{ hint }}</p>
    </div>


</div>

<div *ngIf='loading' class='py-3' fxLayout='row' fxLayoutAlign='start center'>
    <div class="bg-gray-light animate-pulse rounded-full w-12 h-12 mr-2"></div>
    <div class="bg-gray-light animate-pulse rounded w-full h-12"></div>
</div>
