import {RandomUtils} from '../utils/random-utils';

export enum TicketTypeEnum {
    INCIDENT = 'INCIDENT',
    REQUIREMENT = 'REQUIREMENT'
}

export const ticketTypeTranslation = (status: TicketTypeEnum) => {
    switch (status) {
        case TicketTypeEnum.INCIDENT.toString():
            return 'Incident';
        case TicketTypeEnum.REQUIREMENT.toString():
            return 'Besoin';
        default:
            return null;
    }
};

export const ticketTypeEnumObject = (type: TicketTypeEnum) => {
    return {
        _id: RandomUtils.generateId(),
        value: type,
        label: ticketTypeTranslation(type)
    };
};
