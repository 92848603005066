<div class="dropdown">
    <div class="dropdown-header" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="start center">
            <div class="dropdown-heading-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon [icIcon]="icPerson"></mat-icon>
            </div>
            <div class="dropdown-heading"
                 *ngIf='user$ | async as user'>{{ user.firstName | titlecase }} {{ user.lastName | titlecase }}</div>
        </div>
        <a (click)="authService.logout()" class="text-white" mat-button>
<!--            LOGOUT-->
            <mat-icon>power_settings_new</mat-icon>

        </a>
        <!--    <button [matMenuTriggerFor]="settingsMenu"-->
        <!--            mat-icon-button-->
        <!--            matTooltip="Settings"-->
        <!--            matTooltipPosition="before"-->
        <!--            type="button">-->
        <!--      <mat-icon [icIcon]="icSettings" class="notifications-header-icon"></mat-icon>-->
<!--        </button>-->
    </div>

    <!--  <div class="dropdown-content">-->
    <!--    <a (click)="close(item)"-->
    <!--       *ngFor="let item of items; trackBy: trackById"-->
    <!--       [routerLink]="item.route"-->
    <!--       class="notification"-->
    <!--       fxLayout="row"-->
    <!--       fxLayoutAlign="start center"-->
    <!--       matRipple>-->
    <!--      <mat-icon [icIcon]="item.icon"-->
    <!--                [ngClass]="item.colorClass"-->
    <!--                class="notification-icon"-->
    <!--                fxFlex="none"></mat-icon>-->
    <!--      <div fxFlex="auto">-->
    <!--        <div class="notification-label">{{ item.label }}</div>-->
    <!--        <div class="notification-description">{{ item.description }}</div>-->
    <!--      </div>-->
    <!--      <mat-icon *ngIf='!switchModeLoading' [icIcon]="icChevronRight" class="notification-chevron" fxFlex="none"></mat-icon>-->
    <!--      <mat-spinner *ngIf='switchModeLoading' [diameter]='30'></mat-spinner>-->
    <!--    </a>-->
    <!--  </div>-->

    <ng-container *ngIf='userProfile$ | async as userProfile'>
        <div class="dropdown-content">
            <a [routerLink]="['/profil']"
               class="notification"
               fxLayout="row"
               (click)="popoverRef.close()"
               fxLayoutAlign="start center"
               matRipple>
                <mat-icon [icIcon]="icPerson"
                          [ngClass]="'text-green'"
                          class="notification-icon"
                          fxFlex="none"></mat-icon>
                <div fxFlex="auto">
                    <div class="notification-label">Profil</div>
                    <div class="notification-description">Mon profil</div>
                </div>
                <mat-icon [icIcon]="icChevronRight" class="notification-chevron" fxFlex="none"></mat-icon>
            </a>
        </div>
<!--        <div class="dropdown-content">-->
<!--            <a href='https://sso-preprod-energie.predik360.com:8443/OpenAM-13.0.0/saml2/jsp/spSSOInit.jsp?metaAlias=%2FPREDIK%2Fsp&idpEntityID=https%3A%2F%2Fsts.windows.net%2F01f0f611-609a-423f-8db1-abb5215eee9e%2F'-->
<!--               class="notification"-->
<!--               fxLayout="row"-->
<!--               fxLayoutAlign="start center"-->
<!--               target="_blank"-->
<!--               matRipple>-->
<!--                <mat-icon [icIcon]="icMoveToInbox"-->
<!--                          [ngClass]="'text-accent'"-->
<!--                          class="notification-icon"-->
<!--                          fxFlex="none"></mat-icon>-->
<!--                <div fxFlex="auto">-->
<!--                    <div class="notification-label">Energie</div>-->
<!--                    <div class="notification-description">Espace Energie</div>-->
<!--                </div>-->
<!--                <mat-icon [icIcon]="icChevronRight" class="notification-chevron" fxFlex="none"></mat-icon>-->
<!--            </a>-->
<!--        </div>-->
        <div class="dropdown-content" *ngIf='userProfile.isPredik'>
            <a (click)="switchMode()"
               class="notification"
               fxLayout="row"
               fxLayoutAlign="start center"
               matRipple>
                <mat-icon [icIcon]="icListAlt"
                          [ngClass]="'text-amber'"
                          class="notification-icon"
                          fxFlex="none"></mat-icon>
                <div fxFlex="auto">
                    <div class="notification-label">{{ userProfile.mode === UserProfileMode.TENANT ? 'Espace Admin' : 'Espace Client' }}</div>
                    <div class="notification-description">{{ userProfile.mode === UserProfileMode.TENANT ? 'Réservé à la partie Core' : 'Retour à la partie Client' }}</div>
                </div>
                <mat-icon *ngIf='!switchModeLoading' [icIcon]="icChevronRight" class="notification-chevron"
                          fxFlex="none"></mat-icon>
                <mat-spinner *ngIf='switchModeLoading' [diameter]='30'></mat-spinner>
            </a>
        </div>
    </ng-container>

    <div class="dropdown-footer" fxLayout="row" fxLayoutAlign="space-between center">
        <!--    <button [matMenuTriggerFor]="statusMenu" class="dropdown-footer-select" mat-button type="button">-->
        <!--      <ng-container *ngFor="let status of statuses; trackBy: trackById">-->
        <!--        <span *ngIf="status === activeStatus">-->
        <!--          <mat-icon [icIcon]="status.icon" [ngClass]="status.colorClass"></mat-icon>-->
        <!--          <span>{{ status.label }}</span>-->
        <!--          <mat-icon [icIcon]="icArrowDropDown" class="dropdown-footer-select-caret"></mat-icon>-->
        <!--        </span>-->
        <!--      </ng-container>-->
        <!--    </button>-->


        <div *ngIf='user$ | async as user' class="w-full">

            <div *ngIf='userProfile$ | async as userProfile' class="flex flex-row justify-between w-full">
                <button color='primary' *ngIf='user.tenants.length === 1 && userProfile.mode === UserProfileMode.TENANT'
                        mat-button
                        type='button'> {{user.currentTenant.technicalIdentifier}} - {{user.currentTenant.label}}
<!--                    <ic-icon [icon]='icArrowDropDown' class='ltr:-mr-1 rtl:-ml-1' inline='true'></ic-icon>-->
                </button>
                <button color='primary' *ngIf='user.tenants.length > 1 && userProfile.mode === UserProfileMode.TENANT'
                        [matMenuTriggerFor]='menu'
                        mat-button
                        type='button'> {{user.currentTenant.technicalIdentifier}} - {{user.currentTenant.label}}
                    <ic-icon [icon]='icArrowDropDown' class='ltr:-mr-1 rtl:-ml-1' inline='true'></ic-icon>
                </button>
                <div *ngIf='displaySettingsIcon'>
                    <button *ngIf='userProfile.mode !== "CORE" && accesCustomerGroupPermission' (click)="goCustumerGroupSettings()"
                            mat-icon-button
                            type="button">
                        <mat-icon [icIcon]="icOutlineSettings" class="notifications-header-icon"></mat-icon>
                    </button>
                </div>

            </div>
            <mat-menu #menu='matMenu'>
                <button mat-menu-item *ngFor='let t of user.tenants' (click)='authService.switchTenant(t)'>
                    <span>{{t.technicalIdentifier}} - {{t.label}}</span>
                </button>
            </mat-menu>
        </div>
        <!--    <a (click)="authService.logout()" color="primary" mat-button>LOGOUT</a>-->
    </div>
</div>
<mat-menu #statusMenu="matMenu" xPosition="before" yPosition="below">
    <button (click)="setStatus(status)"
            *ngFor="let status of statuses; trackBy: trackById"
            mat-menu-item>
        <ic-icon [icon]="status.icon" [ngClass]="status.colorClass" inline="true" size="24px"></ic-icon>
        <span>{{ status.label }}</span>
    </button>
</mat-menu>
<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
    <button mat-menu-item>
        <mat-icon [icIcon]="icBusiness"></mat-icon>
        <span>Change Address</span>
    </button>

    <button mat-menu-item>
        <mat-icon [icIcon]="icVerifiedUser"></mat-icon>
        <span>Change Username</span>
    </button>

    <button mat-menu-item>
        <mat-icon [icIcon]="icLock"></mat-icon>
        <span>Change Password</span>
    </button>

    <button mat-menu-item>
        <mat-icon [icIcon]="icNotificationsOff"></mat-icon>
        <span>Disable Notifications</span>
    </button>
</mat-menu>
