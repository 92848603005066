import {Injectable} from '@angular/core';
import {BaseService} from '../../../../core/base/base.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CorrectiveInterventionModel} from '../../../../core/models/corrective-intervention.model';
import {Observable} from 'rxjs';
import {ObjectApiInterface} from '../../../../core/base/interfaces/object-api.interface';
import {map} from 'rxjs/operators';
import {BuildingRoomModel} from "../../../../core/models/building-room.model";

@Injectable({
    providedIn: 'root'
})
export class CorrectiveInterventionService extends BaseService<CorrectiveInterventionModel> {
    defaultSort = 'technicalIdentifier';

    constructor(public http: HttpClient) {
        super(http, 'intervention');
    }

    findAllCorrectiveIntervention(page?: number, perPage?: number, sortField: string = this.defaultSort, sortOrder: string = 'desc', search?: string, filters?: any): Observable<ObjectApiInterface<CorrectiveInterventionModel[]>> {
        return this.http.get<ObjectApiInterface<CorrectiveInterventionModel[]>>(`${this.baseUrlApi}/corrective/list`, {
            params: new HttpParams()
                .set('page', page?.toString() ?? '1')
                .set('perPage', perPage?.toString() ?? '30')
                .set('sort', `${sortField}@${sortOrder}`)
                .set('search', search ?? '')
                .set('filters', JSON.stringify(filters) ?? '')
        }).pipe(map(x => {
            // @ts-ignore
            x.data = x.data.map(y => {
                this.addFullName(y);
                return y;
            })
            return x;
        }));
    }

    createCorrectiveIntervention(entity: CorrectiveInterventionModel): Observable<ObjectApiInterface<CorrectiveInterventionModel>> {
        return this.http.post<ObjectApiInterface<CorrectiveInterventionModel>>(`${this.baseUrlApi}/corrective/create`, entity);
    }

    findById(id: string): Observable<ObjectApiInterface<CorrectiveInterventionModel>> {
        return super.findById(id).pipe(map(x => {
            this.addFullName(x.data);
            return x;
        }));
    }

    updateCorrectiveIntervention(entity: CorrectiveInterventionModel): Observable<ObjectApiInterface<CorrectiveInterventionModel>> {
        return this.http.put<ObjectApiInterface<CorrectiveInterventionModel>>(`${this.baseUrlApi}/corrective/update/${entity._id}`, entity).pipe(map(x => {
            this.addFullName(x.data);
            // @ts-ignore
            this._entity.next(x.data);
            return x;
        }));
    }

    addContact(id: string, data: any) {
        return this.http.post<ObjectApiInterface<CorrectiveInterventionModel>>(`${this.baseUrlApi}/addContact/${id}`, data).pipe(map(x => {
            this.addFullName(x.data);
            // @ts-ignore
            this._entity.next(x.data);
            return x;
        }));
    }

    deleteContact(id: string, idContact: any) {
        return this.http.delete<ObjectApiInterface<CorrectiveInterventionModel>>(`${this.baseUrlApi}/${id}/deleteContact/${idContact}`).pipe(map(x => {
            this.addFullName(x.data);
            // @ts-ignore
            this._entity.next(x.data);
            return x;
        }));
    }


    addLocationWithEquipment(id: string, data: any) {
        return this.http.post<ObjectApiInterface<CorrectiveInterventionModel>>(`${this.baseUrlApi}/add-location-equipment/${id}`, data).pipe(map(x => {
            this.addFullName(x.data);
            // @ts-ignore
            this._entity.next(x.data);
            return x;
        }));
    }

    updateLocationWithEquipment(id: string, data: any) {
        return this.http.put<ObjectApiInterface<BuildingRoomModel>>(`${this.baseUrlApi}/${id}/update-location-equipment`, data).pipe(map(x => {
            this.addFullName(x.data);
            // @ts-ignore
            this._entity.next(x.data);

            return x;
        }));
    }

    deleteLocation(id: string, idLocation: any) {
        return this.http.delete<ObjectApiInterface<CorrectiveInterventionModel>>(`${this.baseUrlApi}/${id}/deleteLocation/${idLocation}`).pipe(map(x => {
            this.addFullName(x.data);
            // @ts-ignore
            this._entity.next(x.data);
            return x;
        }));
    }

    addFullName(x: any) {

        x.customerReferent.fullName = `${x.customerReferent?.firstName} ${x.customerReferent?.lastName}`;
        // @ts-ignore
        x.createdBy.fullName = `${x.createdBy?.firstName} ${x.createdBy?.lastName}`;
        // @ts-ignore
        if (x.contributor) {
            x.contributor.fullName = `${x.contributor?.firstName} ${x.contributor?.lastName}`;
        }

        return x;
    }

    checkEquipmentGeneric() {
        return this.http.get<ObjectApiInterface<any>>(`${this.baseUrlApi}/checkEquipmentGeneric`);
    }

    createInterventionFromOrderManagement(entity: CorrectiveInterventionModel): Observable<ObjectApiInterface<CorrectiveInterventionModel[]>> {
        return this.http.post<ObjectApiInterface<CorrectiveInterventionModel[]>>(`${this.baseUrlApi}/order-management/create`, entity);
    }

    updateInterventionFromOrderManagement(entity: CorrectiveInterventionModel): Observable<ObjectApiInterface<CorrectiveInterventionModel>> {
        return this.http.post<ObjectApiInterface<CorrectiveInterventionModel>>(`${this.baseUrlApi}/order-management/update`, entity);
    }

    sendMail(entity: CorrectiveInterventionModel) {
        return this.http.get<ObjectApiInterface<CorrectiveInterventionModel>>(`${this.baseUrlApi}/sendMail/${entity._id.toString()}`);
    }

    cancelScheduling(entity: CorrectiveInterventionModel) {
        return this.http.patch<ObjectApiInterface<CorrectiveInterventionModel>>(`${this.baseUrlApi}/cancel-scheduling/${entity._id.toString()}`, {}).pipe(
            map(x => {

                this._entity.next(x.data);
                return x;
            })
        );
    }

    isPrestationOrdered(entity: CorrectiveInterventionModel) {
        return this.http.get<ObjectApiInterface<any>>(`${this.baseUrlApi}/corrective/isPrestationOrdered/${entity._id.toString()}`).pipe(
            map(x => {
                return x.data?.prestationAlreadyOrdered;
            })
        );
    }
}
