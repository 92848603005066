<ng-container class="h10">
    <!-- Champ Statut de fonctionnement -->
    <base-form-input
        label="Statut de fonctionnement"
        controlName="genericMetrikStatus"
        type="autocomplete"
        [items$]="genericMetrikStatus$"
        (autocompleteChange)="metrikStatusAutocompleteChange($event)"
        displayProperty="displayLabel">
    </base-form-input>

    <!-- Partie Date -->
    <div class="flex flex-col sm:flex-row" *ngIf="form.controls.startDate.enabled">
        <base-form-input
            class="sm:mr-3 flex-auto"
            label="Date de début"
            type="date"
            controlName="startDate">
        </base-form-input>
        <base-form-input
            class="sm:ml-3 flex-auto"
            label="Date de fin"
            type="date"
            controlName="endDate"
            [minDate]="minEndDate">
        </base-form-input>
        <!-- Message d'erreur pour la date de fin -->
        <mat-error *ngIf="form.controls.startDate.hasError('dateBeforeStart') && form.controls.startDate.touched"
                   style="font-size: 75%">
            La date de fin ne peut être antérieure à la date de début.
        </mat-error>
    </div>

    <!-- Partie Commentaire -->
    <div style="margin-bottom: 25px" *ngIf="form.controls.comment.enabled">
        <div class="card">
            <quill-editor
                class="h-200"
                [modules]="customModule"
                placeholder="Insérez le commentaire ici"
                [formControl]="form.controls.comment">
            </quill-editor>
            <mat-error *ngIf="form.controls.comment.hasError('required') && form.controls.comment.touched"
                       style="font-size: 75%">
                Le champ commentaire est obligatoire.
            </mat-error>
        </div>
    </div>
</ng-container>
