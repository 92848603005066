import { Injectable } from '@angular/core';
import { FloorTypeModel } from 'src/app/core/models/floor-type.model';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/core/base/base.service';

@Injectable({
    providedIn: 'root'
})
export class FloorTypeService extends BaseService<FloorTypeModel>{
    defaultSort = 'createdAt';

    constructor(public http: HttpClient) {
        super(http, 'floor-type');
    }
}


