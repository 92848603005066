import { FormGroup } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { isArray } from 'chart.js/helpers';


@Component({
    selector: 'form-multi-input',
    templateUrl: './form-multi-input.component.html',
    styleUrls: ['./form-multi-input.component.scss']
})
export class MultiSelectInputComponent implements OnInit {
    @Input() formGroup: FormGroup;
    @Input() controlName: string;
    @Input() label: string;
    @Input() appearance: MatFormFieldAppearance = 'outline';
    @Input() items: any[] = [];
    @Input() displayProperty: string;
    @Input() loading = false;
    @Input() hint = '';
    @Input() icon: any;
    @Input() defaultErrorMessage: any;
    @Input() invalidPatternMessage: string;
    @Input() customErrorMessage: string;
    @Input() customMultipleSelectedTemplate: any;
    @Input() getProperties: (value: any) => string;
    @Input() withoutPadding = false;

    ngOnInit() {
        this.items = this.getSortedItems(this.items);
    }

    getSortedItems(items: any[]) {
        return items.sort((a, b) => a[this.displayProperty].localeCompare(b[this.displayProperty]));
    }
}
