import {Component, OnDestroy, OnInit} from '@angular/core';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icPersonAdd from '@iconify/icons-ic/twotone-person-add';
import icCheck from '@iconify/icons-ic/twotone-check';
import icAccessTime from '@iconify/icons-ic/twotone-access-time';
import icAdd from '@iconify/icons-ic/twotone-add';
import icWork from '@iconify/icons-ic/twotone-work';
import icMail from '@iconify/icons-ic/twotone-mail';
import icWhatshot from '@iconify/icons-ic/twotone-whatshot';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {SupplierService} from '../../supplier.service';
import {SupplierModel} from '../../../../../../core/models/supplier.model';
import {
    SupplierCreateUpdateDeleteComponent
} from '../../modals/supplier-create-update-delete/supplier-create-update-delete.component';
import {CrudModeEnum} from '../../../../../../core/base/enum/crud-mode.enum';
import {MatDialog} from '@angular/material/dialog';
import {scaleIn400ms} from '../../../../../../../@vex/animations/scale-in.animation';
import {fadeInRight400ms} from '../../../../../../../@vex/animations/fade-in-right.animation';
import {stagger40ms} from '../../../../../../../@vex/animations/stagger.animation';
import {fadeInUp400ms} from '../../../../../../../@vex/animations/fade-in-up.animation';
import {scaleFadeIn400ms} from '../../../../../../../@vex/animations/scale-fade-in.animation';
import {FeiColumn} from '../../../../../../core/base/interfaces/fei-column.interface';
import {MatTableDataSource} from '@angular/material/table';
import {AddressModel} from '../../../../../../core/models/address.model';
import {PageEvent} from '@angular/material/paginator';
import {
    SupplierAddressCreateUpdateDeleteComponent
} from '../../modals/supplier-address-create-update-delete/supplier-address-create-update-delete.component';
import {SupplierType} from '../../../../../../core/enums/supplier-type.enum';
import {
    SupplierFamilleCreateUpdateDeleteComponent
} from '../../modals/supplier-famille-create-update-delete/supplier-famille-create-update-delete.component';
import {ENUM_PERMISSIONS} from "../../../../../../core/enums/permission.enum";
import {AuthService} from "../../../../../../core/services/auth.service";

@Component({
    selector: 'vex-supplier-details-resume',
    templateUrl: './supplier-details-resume.component.html',
    styleUrls: ['./supplier-details-resume.component.scss'],
    animations: [
        scaleIn400ms,
        fadeInRight400ms,
        stagger40ms,
        fadeInUp400ms,
        scaleFadeIn400ms
    ]
})
export class SupplierDetailsResumeComponent implements OnInit, OnDestroy {
    ENUM_PERMISSION = ENUM_PERMISSIONS;

    feisColumnsAddress: Array<FeiColumn> = [
        {label: 'Type d\'adresse', column: 'type', propertyDisplay: null},
        { label: 'Libellé', column: 'label', propertyDisplay: null },
        { label: 'Adresse postale', column: 'street', propertyDisplay: null },
        { label: 'Complément d\'adresse', column: 'additionalAddress', propertyDisplay: null },
        { label: 'Code postal', column: 'postalCode', propertyDisplay: null },
        { label: 'Ville', column: 'city', propertyDisplay: null },
        { label: 'Pays', column: 'country', propertyDisplay: null },
        { label: '', column: 'actions', canDelete : this.authService.hasPermission(ENUM_PERMISSIONS.DELETE_FM_REF_PURCHASE_SUPPLIER_INFO), canUpdate : this.authService.hasPermission(ENUM_PERMISSIONS.UPDATE_FM_REF_PURCHASE_SUPPLIER_INFO_ADDRESS) }
    ];

    protected subscription = new Subscription();

    SupplierType = SupplierType;
    icWork = icWork;
    icPhone = icPhone;
    icPersonAdd = icPersonAdd;
    icCheck = icCheck;
    icMail = icMail;
    icAccessTime = icAccessTime;
    icAdd = icAdd;
    icWhatshot = icWhatshot;

    object: SupplierModel;
    // loading= false;

    countAddress = 0;
    dataSourceAddress = new MatTableDataSource<AddressModel>();
    sortFieldAddress: string;
    sortOrderAddress: string;
    pageIndexAddress: number;
    pageSizeAddress = 0;
    displayedColumnsAddress = ['actions'];

    dataSourceFamille: any;
    displayedColumnsFamille: any;
    feisColumnsFamille: Array<FeiColumn> = [
        // {label: '', column: 'actions'},
        {label: 'Libellé', column: 'label', propertyDisplay: 'label'},
        // {label: 'Pourcentage', column: 'percent', additionalDisplay: ' %'},
    ];

    displayPostePercentWarning = false;

    constructor(public supplierService: SupplierService, private activatedRoute: ActivatedRoute, private dialog: MatDialog, private authService : AuthService) {
        this.displayedColumnsAddress = this.feisColumnsAddress.map(x => x.column);
        this.displayedColumnsFamille = this.feisColumnsFamille.map(x => x.column);
    }

    ngOnInit() {
        this.initData();
    }

    initData() {
        this.supplierService.entity$.subscribe(entity => {
            this.dataSourceAddress = new MatTableDataSource<any>(entity?.addresses);
            this.dataSourceFamille = new MatTableDataSource<any>(entity?.famillies);
            this.displayPostePercentWarning = entity?.famillies.reduce((p, c) => p + c.percent, 0) !== 100;
        });
    }

    async updateItem() {
        const sub = this.dialog.open(SupplierCreateUpdateDeleteComponent, {
            data: {
                defaults: await this.supplierService.entity,
                mode: CrudModeEnum.Update
            }
        }).afterClosed().subscribe((supplier: SupplierModel) => {
            if (supplier) {
                this.initData();
            }
        });
        this.subscription.add(sub)
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    pageEventAddress(event: PageEvent) {
        this.pageIndexAddress = event.pageIndex + 1;
        this.pageSizeAddress = event.pageSize;
        this.initData();
    }

    sortEventAddress(event: any) {
        this.sortFieldAddress = event.active;
        this.sortOrderAddress = event.direction;
        this.initData();
    }

    async updateAddress(address: any) {
        const entity = await this.supplierService.entity;
        this.dialog.open(SupplierAddressCreateUpdateDeleteComponent, {
            data: {
                id: entity._id,
                defaults: address,
                mode: CrudModeEnum.Update
            }
        }).afterClosed().subscribe((supplier: SupplierModel) => {
            if (supplier) {
                this.initData();
            }
        });
    }

    async createAddress() {
        const entity = await this.supplierService.entity;
        this.dialog.open(SupplierAddressCreateUpdateDeleteComponent, {
            data: {
                id: entity._id,
                defaults: entity.addresses,
                mode: CrudModeEnum.Create
            }
        }).afterClosed().subscribe((supplier: SupplierModel) => {
            if (supplier) {
                this.initData();
            }
        });
    }

    async deleteAddress(address: any) {
        const {_id} = await this.supplierService.entity;
        this.dialog.open(SupplierAddressCreateUpdateDeleteComponent, {
            data: {
                id: _id,
                defaults: address,
                name: `"adresse"`,
                mode: CrudModeEnum.Delete
            }
        }).afterClosed().subscribe((res) => {
            if (res) {
                // this.initData();
            }
        });
    }

    async addItemFamille() {
        const entity = await this.supplierService.entity;
        this.dialog.open(SupplierFamilleCreateUpdateDeleteComponent, {
            data: {
                id: entity._id,
                mode: CrudModeEnum.Create,
                totalPercent: entity.famillies.reduce((p, c) => p + c.percent, 0)
            }
        }).afterClosed().subscribe((supplier: SupplierModel) => {
            if (supplier) {
                this.initData();
            }
        });
    }

    async updateItemFamille(element: any) {
        const entity = await this.supplierService.entity;
        this.dialog.open(SupplierFamilleCreateUpdateDeleteComponent, {
            data: {
                id: entity._id,
                defaults: element,
                mode: CrudModeEnum.Update,
                totalPercent: entity.famillies.reduce((p, c) => p + c.percent, 0)
            }
        }).afterClosed().subscribe((supplier: SupplierModel) => {
            if (supplier) {
                this.initData();
            }
        });
    }

    async deleteItemFamille(element: any) {
        const entity = await this.supplierService.entity;
        this.dialog.open(SupplierFamilleCreateUpdateDeleteComponent, {
            data: {
                id: entity._id,
                defaults: element,
                mode: CrudModeEnum.Delete
            }
        }).afterClosed().subscribe((supplier: SupplierModel) => {
            if (supplier) {
                this.initData();
            }
        });
    }
}
