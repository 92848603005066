import {Typology} from './typology.enum';
import {ContactTypeModel} from '../models/contact-type.model';

export enum ContactTypeEnum {
    // REFERENT_METIER = 'Référent métier',
    DEVIS = 'Devis',
    ORDER = 'Commande',
    APPROVISIONNEUR = 'Approvisionneur',
    BILL = 'Validateur',
    APPROVER = 'Approbateur facture',
    ACCOUNTANT = 'Comptable',
    CONTRACT = 'Contrat',
    REFERENT_TECHNIC = 'Référent équipement',
    REFERENT_QUALITY = 'Référent qualité',
    // ORDER = 'Commande',
    REFERENT_ENGAGEMENT = 'Référent engagement',
}

export const BaseContactType: ContactTypeModel[] = [
    {
        _id: '66431d1859c475c8bf0d61fc',
        technicalIdentifier: 'SYSTEM-0000011',
        label: ContactTypeEnum.REFERENT_ENGAGEMENT,
        typology: Typology.intern,
        noAction: true
    },
    // {
    //     _id: '628ce71c067aed6070dd84f1',
    //     technicalIdentifier: 'SYSTEM-0000010',
    //     label: ContactTypeEnum.REFERENT_METIER,
    //     typology: Typology.intern,
    //     noAction: true
    // },
    {
        _id: '637f4961eaeffff5f110f4d4',
        technicalIdentifier: 'SYSTEM-000009',
        label: ContactTypeEnum.BILL,
        typology: Typology.intern,
        noAction: true

    },
    {
        _id: '628ce71c067aed6070dd84f2',
        technicalIdentifier: 'SYSTEM-000008',
        label: ContactTypeEnum.DEVIS,
        typology: Typology.supplier,
        noAction: true
    },
    {
        _id: '628ce71c067aed6070dd84f3',
        technicalIdentifier: 'SYSTEM-000007',
        label: ContactTypeEnum.ORDER,
        typology: Typology.supplier,
        noAction: true
    },
    {
        _id: '628ce71c067aed6070dd84f4',
        technicalIdentifier: 'SYSTEM-000006',
        label: ContactTypeEnum.APPROVISIONNEUR,
        typology: Typology.intern,
        noAction: true
    },
    {
        _id: '63c1560c7c890fe2559c4557',
        technicalIdentifier: 'SYSTEM-000005',
        label: ContactTypeEnum.APPROVER,
        typology: Typology.intern,
        noAction: true
    },
    {
        _id: '63c165b77c890fe2559c4558',
        technicalIdentifier: 'SYSTEM-000004',
        label: ContactTypeEnum.ACCOUNTANT,
        typology: Typology.intern,
        noAction: true
    },
    {
        _id: '63c165b77c890fe2559c4559',
        technicalIdentifier: 'SYSTEM-000003',
        label: ContactTypeEnum.CONTRACT,
        typology: Typology.supplier,
        noAction: true
    },
    {
        _id: '63d7c4180bd5e96d20ab1c53',
        technicalIdentifier: 'SYSTEM-000002',
        label: ContactTypeEnum.REFERENT_TECHNIC,
        typology: Typology.intern,
        noAction: true
    },
    {
        _id: '63f5e93d5ce034703e254d79',
        technicalIdentifier: 'SYSTEM-000001',
        label: ContactTypeEnum.REFERENT_QUALITY,
        typology: Typology.intern,
        noAction: true
    },
];
