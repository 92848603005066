<div class="text-gray py-2">{{ label }}</div>

<div *ngIf="selection === 'between'">

    <!-- Conteneur pour aligner les 3 éléments sur une seule ligne -->
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" [ngClass]="{'without-padding': withoutPadding}">
        <!-- Bouton de sélection (prend environ 33% de la largeur) -->
        <mat-form-field [formGroup]="formGroup" [appearance]="appearance" fxFlex="30%">
            <mat-label>Comparaison</mat-label>
            <mat-select [formControlName]="controlName" (selectionChange)="onSelectionChange($event)">
                <mat-option value="between">Compris entre</mat-option>
                <mat-option value="fixed">Valeur fixe</mat-option>
                <mat-option value="greaterThan">Supérieur à</mat-option>
                <mat-option value="lessThan">Inférieur à</mat-option>
            </mat-select>
        </mat-form-field>

        <!-- Input pour min (prend environ 33% de la largeur) -->
        <mat-form-field *ngIf="typeSelection === 'number'" [formGroup]="formGroup" [appearance]="appearance" fxFlex="33%">
            <mat-label>Montant minimum</mat-label>
            <input matInput [formControlName]="minMaxField[0]" type="number" placeholder="Montant minimum">
        </mat-form-field>

        <!-- Input pour max (prend environ 33% de la largeur) -->
        <mat-form-field *ngIf="typeSelection === 'number'" [formGroup]="formGroup" [appearance]="appearance" fxFlex="33%">
            <mat-label>Montant maximum</mat-label>
            <input matInput [formControlName]="minMaxField[1]" type="number" placeholder="Montant maximum">
        </mat-form-field>

        <!-- Si type date, affichage des champs de date (prend aussi 33%) -->
        <mat-form-field *ngIf="typeSelection === 'date'" [formGroup]="formGroup" [appearance]="appearance" fxFlex="33%">
            <mat-label>Date de début</mat-label>
            <input matInput [matDatepicker]="picker1" [formControlName]="minMaxField[0]">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>

        <mat-form-field *ngIf="typeSelection === 'date'" [formGroup]="formGroup" [appearance]="appearance" fxFlex="33%">
            <mat-label>Date de fin</mat-label>
            <input matInput [matDatepicker]="picker2" [formControlName]="minMaxField[1]">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
    </div>
</div>


<!-- Sélection simple -->
<ng-container *ngIf="selection !== 'between'">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" [ngClass]="{'without-padding': withoutPadding}">
        <!-- Sélection simple à côté de l'input -->
        <mat-form-field [formGroup]="formGroup" [appearance]="appearance" class="flex-auto w-full">
            <mat-label>Comparaison</mat-label>
            <mat-select [formControlName]="controlName" (selectionChange)="onSelectionChange($event)">
                <mat-option value="between">Compris entre</mat-option>
                <mat-option value="fixed">Valeur fixe</mat-option>
                <mat-option value="greaterThan">Supérieur à</mat-option>
                <mat-option value="lessThan">Inférieur à</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="typeSelection === 'number'" [formGroup]="formGroup" [appearance]="appearance" class="flex-auto w-full">
            <mat-label>Montant </mat-label>
            <input matInput formControlName="max" type="number" placeholder="Montant maximum">
        </mat-form-field>

        <mat-form-field *ngIf="typeSelection === 'date'" [formGroup]="formGroup" [appearance]="appearance" class="flex-auto w-full">
            <mat-label>Date</mat-label>
            <input matInput [matDatepicker]="picker3" [formControlName]="minMaxField[1]">
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
        </mat-form-field>
    </div>
</ng-container>
