import {BaseService} from '../../../../core/base/base.service';
import {GenericEquipmentModel} from '../../../../core/models/generic-equipment.model';
import {HttpClient} from '@angular/common/http';
import {GenericAdditionalCostModel} from '../../../../core/models/generic-additional-cost.model';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GenericAdditionalCostService  extends BaseService<GenericAdditionalCostModel> {
    defaultSort = 'createdAt';

    constructor(public http: HttpClient) {
        super(http, 'generic-additional-cost');
    }
}
