    <div class="card w-full p-4 h-full">
        <div class="items-center mb-4">
            <span class="font-semibold text-lg text-gray-700">Panier</span>
            <div class="flex flex-row items-center mt-8">
                <div class="relative flex">
                    <mat-icon class="large-icon h-full w-full" color="primary">shopping_cart</mat-icon>
                    <span class="absolute font-bold text-white text-xs md:text-sm lg:text-base"
                          style="top: 36%; left: 50%; transform: translate(-50%, -50%);">{{ totalQuantity }}</span>
                </div>
                <h2 class='title m-0' *ngIf="displayPrice">Total : {{ totalBasketAmount | number: '1.2-2' }}€</h2>
            </div>
        </div>
    </div>
