<mat-form-field [appearance]="appearance" color="primary" class="flex-auto w-full" [ngClass]="{'without-padding': withoutPadding}">
   <mat-label>{{ label }}</mat-label>
   <div style="display: flex; gap: 8px; align-items: center;" [formGroup]="timeDelayForm">
      <!-- Champ de saisie pour la quantité -->
      <input matInput type="number"
             [min]="minNumber"
             [max]="maxNumber"
             [placeholder]="placeholder"
             formControlName="quantity"
             (blur)="onLostFocus.emit()">
      <!-- Sélecteur pour choisir l'unité -->
      <mat-select formControlName="displayedUnit">
         <mat-option *ngFor="let unit of timeUnits" [value]="unit.value">
            {{ unit.label }}
         </mat-option>
      </mat-select>
   </div>
   <mat-hint>{{ hint }}</mat-hint>

   <!-- Affichage des erreurs à partir du contrôle du parent -->
   <base-form-input-errors
      [control]="formGroup.controls[controlName]"
      [controlName]="controlName"
      [errorMessages]="defaultErrorMessage"
      [invalidPatternMessage]="invalidPatternMessage"
      [customErrorMessage]="customErrorMessage">
   </base-form-input-errors>
</mat-form-field>
