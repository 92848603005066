import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserProfileService} from '../../../user-profile.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CrudModeEnum} from '../../../../../../../core/base/enum/crud-mode.enum';
import icClose from '@iconify/icons-ic/twotone-close';
import {forkJoin, Observable} from 'rxjs';
import {EstablishmentModel} from '../../../../../../../core/models/establishment.model';
import {EstablishmentService} from '../../../../establishment/establishment.service';
import {map} from 'rxjs/operators';
import {UserProfileModel} from '../../../../../../../core/models/user-profile.model';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from '../../../../../../../core/services/auth.service';
import {SnackbarService} from '../../../../../../../core/services/snackbar.service';

@Component({
    selector: 'vex-user-profile-details-resume-affectation-form',
    templateUrl: './user-profile-details-resume-affectation-form.component.html',
    styleUrls: ['./user-profile-details-resume-affectation-form.component.scss']
})
export class UserProfileDetailsResumeAffectationFormComponent implements OnInit {

    form: FormGroup;
    mode: CrudModeEnum = CrudModeEnum.Create;

    establishments$: Observable<Array<EstablishmentModel>>;
    responsables$: Observable<any>;

    responsableFilters: any = {};
    title: string;

    icClose = icClose;

    userProfileId: string;
    loading = false;

    defaults: any;
    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public dialogRef: MatDialogRef<UserProfileDetailsResumeAffectationFormComponent>,
                public service: UserProfileService,
                private establishmentService: EstablishmentService,
                private activatedRoute: ActivatedRoute,
                private snackbarService: SnackbarService,
    ) {
    }

    ngOnInit() {
        this.mode = this.data.mode;
        this.title = this.data.title;
        this.userProfileId = this.data.userProfile._id;

        this.responsableFilters = {
            establishment: null,
            userProfile: this.userProfileId,
            excludeIds: [this.userProfileId]
        };

        this.defaults = this.data.defaults;

        this.establishmentChange();

        this.form = new FormGroup({
            _id: new FormControl(this.defaults?._id),
            establishment: new FormControl(this.defaults?.establishment, Validators.required),
            responsable: new FormControl(this.defaults?.responsable)
        });

        this.form.controls.establishment.valueChanges.subscribe(state => {
            if (state) {
                this.responsableFilters.establishment = state._id;
            } else {
                this.responsableFilters.establishment = null;
            }
            this.form.controls.responsable.reset();
            this.responsableChange();
        });
    }

    createItem() {
        if (this.form.valid) {
            this.loading = true;
            this.service.addAffectation(this.userProfileId, this.form.value).subscribe(res => {
                this.dialogRef.close(res);
            }, error => {
                console.log(error);
                this.loading = false;
            });
        }
    }

    updateItem() {
        if (this.form.valid) {
            this.loading = true;
            this.service.updateAffectation(this.userProfileId, this.form.value).subscribe(res => {
                this.dialogRef.close(res);
            }, error => {
                console.log(error);
                this.loading = false;
            });
        }
    }

    deleteItem() {
        this.loading = true;
        this.establishmentService.findById(this.data.defaults.establishment._id).subscribe(x => {
            const allUserIds = [];

            x.data.validators?.forEach(validator => {
                if (validator.buisnessReferents) {
                    allUserIds.push(...validator.buisnessReferents.map(user => user._id));
                }
                if (validator.purchasers) {
                    allUserIds.push(...validator.purchasers.map(user => user._id));
                }
                if (validator.referentN1) {
                    allUserIds.push(validator.referentN1._id);
                }
                if (validator.referentN2) {
                    allUserIds.push(validator.referentN2._id);
                }
                if (validator.approver) {
                    allUserIds.push(validator.approver._id);
                }
            });

            if (allUserIds.includes(this.userProfileId)) {
                this.dialogRef.close();
                this.snackbarService.danger('Cet utilisateur est présent dans la liste des ACTEURS ACHATS de cet établissement. Veuillez le remplacer avant de supprimer cette affectation.');
            } else {
                this.service.deleteAffectation(this.userProfileId, this.data.affectationId).subscribe(res => {
                    this.dialogRef.close(res);
                }, error => this.loading = false);
            }
        });
    }

    close() {
        this.dialogRef.close();
    }

    save() {

    }

    isCreateMode() {
        return this.mode === CrudModeEnum.Create;
    }

    isUpdateMode() {
        return this.mode === CrudModeEnum.Update;
    }

    isDeleteMode(): boolean {
        return this.mode === CrudModeEnum.Delete;
    }

    establishmentChange(state: any = '') {
        const excludedEstablishmentToFind = this.isUpdateMode() ? this.data.userProfile?.affectations.map(x => x.establishment._id).filter(y => y !== this.defaults.establishment._id) : this.data.userProfile?.affectations?.map(x => x.establishment._id);
        this.establishments$ = this.establishmentService.findAllAsSelect(true, 'label', 'asc', state, {excludeIds: excludedEstablishmentToFind}).pipe(map(x => {
            if (this.isUpdateMode()) {
                this.responsableFilters.establishment = this.defaults.establishment._id;
                this.responsableChange();
            }
            return x.data;
        }));
    }

    responsableChange(search: any = '') {
        if (this.responsableFilters.establishment) {
            this.responsables$ = this.service.findAllForAffectation(null, null, null, null, search, this.responsableFilters).pipe(map(x => x.data));
        } else {
            this.responsables$ = new Observable();
        }
    }
}
