<div class='px-6 h-16 border-b sticky left-0'
     [ngClass]='{"bg-white": backgroundColorWhite, "bg-app-bar" : !backgroundColorWhite}' fxLayout='row'
     fxLayoutAlign='start center'>

    <div *ngFor="let dt of dynamicTemplatesBefore">
        <ng-container *hasPermission="dt.hasPermission" [ngTemplateOutlet]="dt.template"></ng-container>
    </div>

    <span fxFlex></span>

    <div *ngFor="let dt of dynamicTemplatesAfter">
        <ng-container *hasPermission="dt.hasPermission" [ngTemplateOutlet]="dt.template"></ng-container>
    </div>

</div>

