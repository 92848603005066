import { Component, OnDestroy, OnInit } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { CategoryModel } from '../../../../../../core/models/category.model';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { CategoryService } from '../../../../settings/category/category.service';
import { SnackbarService } from '../../../../../../core/services/snackbar.service';
import { Tab } from '../../../../../../core/base/interfaces/tab.interface';
import { Observable, Subject, Subscription } from 'rxjs';
import icSearch from '@iconify/icons-ic/twotone-search';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserProfileService } from '../../../user-profile/user-profile.service';
import { map } from 'rxjs/operators';
import { EstablishmentService } from '../../establishment.service';
import { ActivatedRoute } from '@angular/router';
import { ENUM_PERMISSIONS } from '../../../../../../core/enums/permission.enum';
import { AuthService } from '../../../../../../core/services/auth.service';

interface FieldConfig {
    formArrayName: string;    // nom du FormArray (ex: 'businessReferents')
    controlName: string;      // nom du contrôle individuel (ex: 'businessReferent')
}

const FIELD_CONFIGS: { [key: string]: FieldConfig } = {
    'business-referent': {
        formArrayName: 'buisnessReferents',
        controlName: 'buisnessReferent'
    },
    'purchaser': {
        formArrayName: 'purchasers',
        controlName: 'purchaser'
    },
    'referent-n1': {
        formArrayName: 'referentsN1',
        controlName: 'referentN1'
    },
    'referent-n2': {
        formArrayName: 'referentsN2',
        controlName: 'referentN2'
    }
};

@Component({
    selector: 'establishment-details-validators-component',
    templateUrl: 'establishment-details-validators.component.html',
    styleUrls: ['establishment-details-validators.component.scss']
})

export class EstablishmentDetailsValidatorsComponent implements OnInit, OnDestroy {

    get purchasers() {
        return this.form.get('purchasers') as FormArray;
    }

    get buisnessReferents() {
        return this.form.get('buisnessReferents') as FormArray;
    }

    get referentsN1() {
        return this.form.get('referentsN1') as FormArray;
    }

    get referentsN2() {
        return this.form.get('referentsN2') as FormArray;
    }

    constructor(
        private establishmentService: EstablishmentService,
        public service: CategoryService,
        private snackbarService: SnackbarService,
        private userProfileService: UserProfileService,
        private route: ActivatedRoute,
        private authService: AuthService
        ) {
    }
    private subscription = new Subscription();
    treeControl: NestedTreeControl<CategoryModel>;
    dataSource: MatTreeNestedDataSource<CategoryModel>;

    loading = false;
    loadingExport = false;
    canUpdateActorsPermission = false;


    ENUM_PERMISSIONS = ENUM_PERMISSIONS;

    selectedCategory: CategoryModel = null;
    tabs: Array<Tab> = [
        {
            value: 'validations',
            filters: [''],
            label: 'Validateurs',
            number: 0,
            icon: icSearch
        }
    ];
    activeTab: Tab = this.tabs[0];
    activeTab$ = new Subject<Tab>();

    validator: any;
    form: FormGroup;

    referents$: Observable<any>;
    refN1$: Observable<any>;
    refN2$: Observable<any>;
    purchasers$: Observable<any>;
    approver$: Observable<any>;
    applyToChildren = true;

    affectedUsers$: Observable<any>;

    establishmentId: any;
    establishmentLabel: any;

    getLevel = (node: CategoryModel) => node.level;
    isExpandable = (node: CategoryModel) => node.children?.length > 0;
    hasChild = (_: number, _nodeData: CategoryModel) => _nodeData.children?.length > 0 && _nodeData.level < 2;

    ngOnInit(): void {
        this.initData();
        this.initForm();
        this.authService.getCurrentUserPermissions$().subscribe(permissions => {
            if (!permissions.includes(ENUM_PERMISSIONS.UPDATE_ESTABLISHMENT)) {
                this.canUpdateActorsPermission = true;
            }
        });
    }

    initData(): void {
        this.loading = true;
        this.treeControl = new NestedTreeControl<CategoryModel>(node => node.children);
        this.dataSource = new MatTreeNestedDataSource<CategoryModel>();
        this.service.findByLevel(0).subscribe(res => {
            this.dataSource.data = res.data;
            this.loading = false;

            // if we have the id of the category in the url, we select it by default
            if (this.route.snapshot.params?.id) {
                this.service.findById(this.route.snapshot.params?.id).subscribe(category => {
                    this.selectCategory(category.data);
                });
            }
        });
        this.establishmentService.entity$.subscribe(entity => {
            this.establishmentId = entity._id;
            this.establishmentLabel = entity.label;
            this.loadAutoComplete();
        });
    }

    async initForm() {
        this.form = new FormGroup({
            buisnessReferents: new FormArray([]),
            referentsN1: new FormArray([]),
            referentsN2: new FormArray([]),
            approver: new FormControl(this.validator?.approver, [Validators.required]),
            purchasers: new FormArray([]),
        //    applyToChildren: new FormControl(this.validator?.applyToChildren ?? this.applyToChildren, [Validators.required]),
            applyToChildren: new FormControl(true, [Validators.required]),
            autorizationOrder: new FormControl(this.validator?.autorizationOrder, [Validators.required]),
        });


        if (this.canUpdateActorsPermission) {
            this.form.controls.approver.disable();
        }



        this.validator?.buisnessReferents?.forEach(buisnessReferent => {
            this.addFields('business-referent', buisnessReferent);
        });

        this.validator?.purchasers?.forEach(purchaser => {
            this.addFields('purchaser', purchaser);
        });

        this.validator?.referentsN1?.forEach(referentN1 => {
            this.addFields('referent-n1', referentN1);

        });

        this.validator?.referentsN2?.forEach(referentN2 => {

            this.addFields('referent-n2', referentN2);

        });

        // const subApprobator = this.form.controls.approver.valueChanges.subscribe(value => {
        //     if (value?._id) {
        //         this.updateValidators(true);
        //     }
        // });
        //
        // const subAutorizationOrder = this.form.controls.autorizationOrder.valueChanges.subscribe(value => {
        //     if (value?._id) {
        //         this.updateValidators(true);
        //     }
        // });

        // this.subscription.add(subAutorizationOrder);
        // this.subscription.add(subApprobator);
    }

    openLevel(node) {
        if (this.treeControl.isExpanded(node) && !node.expandedOnce) {
            node.isLoading = true;
            node.expandedOnce = true;
            this.service.findByLevel(node.level + 1, node.children.map(x => x._id)).subscribe(res => {
                this.dataSource.data = [];
                this.dataSource.data = res.data;
                node.isLoading = false;
            });
        }
    }

    addFields(type: string, defaultValue = null) {
        const config = FIELD_CONFIGS[type];
        if (!config) {
            console.error(`Type de champ non reconnu: ${type}`);
            return;
        }


        const formGroup = new FormGroup({
            [config.controlName]: new FormControl(defaultValue, [Validators.required])
        });


        // const sub = formGroup.get(config.controlName).valueChanges.subscribe(value => {
        //     if (value?._id) {
        //         this.updateValidators();
        //     }
        // });
        // this.subscription.add(sub);

        // Ajout au FormArray
        const formArray = this.form.get(config.formArrayName) as FormArray;
        formArray.push(formGroup);

    }

    removeField(type: string, index: number): void {
        const config = FIELD_CONFIGS[type];
        if (!config) {
            console.error(`Type de champ non reconnu: ${type}`);
            return;
        }

        const formArray = this.form.controls[config.formArrayName] as FormArray;
        formArray.removeAt(index);
        this.updateValidators();
    }

    async selectCategory(node) {
        const entity = await this.establishmentService.entity;
        this.selectedCategory = node;
        this.establishmentService.getValidator(entity._id, node._id)
          .subscribe((validator) => {

              // @ts-ignore
              this.validator = validator;

              // if (this.validator?.approver) {
              //     this.validator.approver.fullName = this.validator.approver.firstName + ' ' + this.validator.approver.lastName;
              // }
              this.initForm();
          });

    }

    switchTab(tab: any) {
        if (this.activeTab !== tab) {
            this.activeTab$.next(tab);
        }
    }

    loadAutoComplete() {
        this.autocompleteApprover();
        this.autocompleteReferent();
        this.autocompleteRefN1();
        this.autocompleteRefN2();
        this.autocompletePurchaser();
        this.affectedUsers$ = this.userProfileService.findAll(null, null, 'fullName', 'asc', null, { establishment: this.establishmentId }).pipe(
            map(users => users.data)
        );
    }

    autocompleteApprover(search = '') {
        this.approver$ = this.userProfileService.findAll(null, null, 'fullName', 'asc', search, { isApprover: true, isPredik: false, establishment: this.establishmentId })
            .pipe(
                map(users => users.data)
            );

    }

    autocompleteReferent(search = '') {
        this.referents$ = this.userProfileService.findAll(null, null, 'fullName', 'asc', search, { isReferentEngagement: true, isPredik: false, establishment: this.establishmentId })
            .pipe(
                map(users => users.data)
            );
    }

    autocompleteRefN1(search = '') {

        this.refN1$ =  this.userProfileService.findAll(null, null, 'fullName', 'asc', search, { establishment: this.establishmentId, isPredik: false, isValidator: true })
            .pipe(
                map(users => users.data)
            );
    }

    autocompleteRefN2(search = '') {

        this.refN2$ =  this.userProfileService.findAll(null, null, 'fullName', 'asc', search, { establishment: this.establishmentId, isPredik: false, isValidator: true })
            .pipe(
                map(users => users.data)
            );
    }

    autocompletePurchaser(search = '') {
        this.purchasers$ = this.userProfileService.findAll(null, null, 'fullName', 'asc', search, { isPurchaser: true, isPredik: false, establishment: this.establishmentId })
            .pipe(
                map(users => users.data)
            );
    }

    async updateValidators(isInit = false) {

        const extractUserData = (user) => user ? {
            _id: user._id,
            fullName: user.fullName,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            mobileNumber: user.mobileNumber,
        } : null;

        const processArrayData = (array: any[], key: string) => {
            return array
              .filter(x => x?.[key]?._id)
              .map(x => extractUserData(x[key]));
        };

        const entity = await this.establishmentService.entity;
        const formData = this.form.getRawValue();

        const data = {
            ...formData,
            category: this.selectedCategory._id,
            purchasers: processArrayData(formData.purchasers, 'purchaser'),
            buisnessReferents: processArrayData(formData.buisnessReferents, 'buisnessReferent'),
            referentsN1: processArrayData(formData.referentsN1, 'referentN1'),
            referentsN2: processArrayData(formData.referentsN2, 'referentN2'),
            approver: extractUserData(formData.approver)
        };

            if (!isInit) {

              const sub = this.establishmentService.setValidator(entity._id, data).subscribe()
              this.subscription.add(sub);
        }


    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
