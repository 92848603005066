// list-card.component.ts
import { Component, Input, ChangeDetectionStrategy, ContentChild, TemplateRef, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'base-list-card',
  templateUrl: './list-card.component.html',
  styleUrls: ['./list-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListCardComponent {
  @Input() title: string;
  @Input() items: any;
  @Input() showRefresh = false; // Propriété conditionnelle
  @Output() refresh = new EventEmitter<void>();
  @ContentChild('itemTemplate') itemTemplate: TemplateRef<any>;

  onRefresh(): void {
    this.refresh.emit();
  }
}
