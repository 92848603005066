<div class="h-full" fxLayout="column">
    <div class="p-6" fxFlex="none">
        <div vexContainer>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div class='bg-card rounded-full border px-4'
                     fxFlex='400px'
                     fxFlex.lt-md='auto'
                     fxHide.xs
                     fxLayout='row'
                     fxLayoutAlign='start center'>
                    <ic-icon [icIcon]='icSearch' size='20px'></ic-icon>
                    <input
                            class='px-4 py-3 border-0 outline-none w-full bg-transparent'
                            placeholder='Recherche...'
                            #search
                    >
                    <button *ngIf='search.value' matSuffix mat-icon-button aria-label='EFFACER'
                            (click)='clearTextFilter()'>
                        <ic-icon [icIcon]='icClose' size='20px'></ic-icon>
                    </button>
                </div>

                <div>

                    <button *hasPermission="[ENUM_PERMISSION.UPDATE_SUPPLIER]" (click)="createContact()"
                            class="ltr:ml-4 rtl:mr-4"
                            color="primary"
                            fxFlex="none"
                            fxHide.xs
                            mat-raised-button
                            type="button">
                        <ic-icon [icon]="icPersonAdd"
                                 class="ltr:mr-2 rtl:ml-2 ltr:-ml-1 rtl:-mr-1"
                                 inline="true"
                                 size="20px"></ic-icon>
                        <span>Ajouter un contact</span>
                    </button>

                    <button mat-icon-button color="primary" (click)="importItem()">
                        <mat-icon>upload_file</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="(supplierService.contacts$ | async)?.length > 0"
         @stagger
         class="overflow-y-auto"
         fxFlex="auto">
        <div class="p-gutter"
             vexContainer
             gdColumns="1fr 1fr 1fr 1fr"
             gdColumns.lt-md="1fr 1fr"
             gdColumns.xs="1fr"
             gdGap="24px">
            <vex-contacts-card (openContact)="openContact($event)"
                               *ngFor="let contact of (supplierService.contacts$ | async)"
                               @fadeInUp
                               [contact]="contact"
                               (onEdit)="editContact(contact)"
                               (onDelete)="deleteContact(contact)"
                               [sendPermission]="[ENUM_PERMISSION.READ_SUPPLIER]"
                               [callPermission]="[ENUM_PERMISSION.READ_SUPPLIER]"
                               [updatePermission]="[ENUM_PERMISSION.UPDATE_SUPPLIER]"
                               [deletePermission]="[ENUM_PERMISSION.UPDATE_SUPPLIER]"
            ></vex-contacts-card>
        </div>
    </div>

    <div *ngIf="(supplierService.contacts$ | async)?.length === 0"
         @scaleFadeIn
         fxFlex="auto"
         fxLayout="column"
         fxLayoutAlign="center center">
        <img class="m-12 h-64" src="assets/img/illustrations/idea.svg">
        <h2 class="headline m-0 text-center">Il n'y a pas de contact pour ce fournisseur</h2>
    </div>
</div>
