
<div class="dropdown drop-shadow-xl bg-card p-6" [style.width.px]="width">
    <div class="dropdown-header" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="header" >
        <div>
            <div class="dropdown-heading">
                <ng-content select="[header]"></ng-content>
            </div>
        </div>
    </div>

    <div class="dropdown-content">
        <ng-content select="[content]"></ng-content>
    </div>

    <div class="dropdown-footer" fxLayout="row" fxLayoutAlign="center center" *ngIf="footer">
        <ng-content select="[footer]"></ng-content>
    </div>
</div>