<ng-container *ngIf="isCreateMode() || isUpdateMode()">
    <form (ngSubmit)="save()" [formGroup]="form">
        <base-form-header [mode]='mode' createLabel="Ajouter une adresse" [updateLabel]='form.get("street").value' ></base-form-header>

        <mat-dialog-content fxLayout="column">
            <div
                *ngIf="form.get('address').value || !form.get('street').value && !form.get('label').value && !form.get('additionalAddress').value && !form.get('postalCode').value && !form.get('city').value && !form.get('country').value"
            >
                <base-form-input
                        label="Copier une addresse de l'établissement"
                        type="autocomplete"
                        controlName='address'
                        [items]="addresses"
                        displayProperty='completeAddress'
                >
                </base-form-input>

                <mat-divider class="-mx-6 text-border mb-6"></mat-divider>
            </div>

            <base-form-input
                    label="Libellé"
                    controlName="label"
            ></base-form-input>

            <base-form-input
                    *ngIf="!form.get('address').value"
                    label='Adresse'
                    controlName='street'>
            </base-form-input>

            <base-form-input
                    *ngIf="!form.get('address').value"
                    label="Complément d'adresse"
                    controlName='additionalAddress'>
            </base-form-input>

            <base-form-input
                    *ngIf="!form.get('address').value"
                    label='Code Postal'
                    controlName='postalCode'>
            </base-form-input>

            <base-form-input
                    *ngIf="!form.get('address').value"
                    label='Ville'
                    controlName='city'>
            </base-form-input>

            <base-form-input
                    *ngIf="!form.get('address').value"
                    label='Pays'
                    type="autocomplete"
                    controlName='country'
                    [items]="countryItem"
            >
            </base-form-input>        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <base-form-input
                [loading]="loading"
                type='submit'
                [mode]='mode'
                entityLabel='SUPPLIER'
                (onCreate)='createItem()'
                (onUpdate)='updateItem()'
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>

<ng-container *ngIf="isDeleteMode()">
    <form-modal-delete-base
        [label]="data.name"
        defaultMessage="Etes-vous sûr de vouloir supprimer cette adresse ?"
        (onClose)="close()"
        (onDelete)="deleteItem()"
    ></form-modal-delete-base>
</ng-container>

<ng-container *ngIf="isImportMode()">
    <vex-form-import-base
        [modalTitle]='"Importer des adresses"'
        [entityLabel]='"Adresse"'
        [form]='importForm'
        [service]='buildingService'
        [mode]='mode'
        (saveForm)='save()'
        (importItems)='importItems()'
        [customExport]='true'
        (customExportItems)='exportAddressModel()'
        [canDownloadTemplateModel]='true'
    ></vex-form-import-base>
</ng-container>
