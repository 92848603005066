<vex-page-layout>
    <vex-page-layout-header class='pb-4' fxLayout='column' fxLayoutAlign='center start'>
<!--        <div class='w-full flex flex-col sm:flex-row justify-between container'>-->
<!--            <div>-->
<!--                <h1 class='title mt-0 mb-1'>Prestations</h1>-->
<!--                <vex-breadcrumbs [crumbs]="['Prestations']"></vex-breadcrumbs>-->
<!--            </div>-->
<!--        </div>-->
    </vex-page-layout-header>

    <vex-page-layout-content class='-mt-6'>
        <div class='card overflow-auto -mt-16'>


            <!--            PRED-1278 : juste en readOnly : Ne peut plus modifier ajouter ou importer -->

            <base-data-table-header
                label='Prestations'
                headerPopupTitle='Filtres avancés'
                [loading]='loading'
                [canImport]="false"
                [canCreate]='false'
                [popupFilter]='true'
                [countPopupFilter]='countPopupFilter'
                [contentPopupTemplate]='contentPopupTemplate'
                (onSearchChange)='onSearchChange($event)'
                (onClearTextFilter)='clearTextFilter()'
                (onCreate)='createItem()'
                (onImport)="importItem()"
                [showArchives]="canShowArchive"
                [showExpires]="false"
                (onShowExpired)="onShowExpired($event)"
                (onShowArchived)="onShowArchived($event)"
                (clearPopupFilter)='clearPopupFilter()'
                (validatePopupFilter)='validatePopupFilter()'
                [createPermission]="[ENUM_PERMISSION.ADD_FM_REF_PURCHASE_SERVICE]"
                [importPermission]="[ENUM_PERMISSION.IMPORT_FM_REF_PURCHASE_SERVICE]"
            ></base-data-table-header>

            <!-- PREVIOUS PERMISSION
            [deletePermission]="[ENUM_PERMISSION.DELETE_FM_BF_SERVICE]"
            [updatePermission]="[ENUM_PERMISSION.UPDATE_FM_BF_SERVICE_INFO]"
            -->
            <base-data-table
                [count]='count'
                [pageSize]='pageSize'
                [sortField]='sortField'
                [sortOrder]='sortOrder'
                [dataSource]='dataSource'
                [displayedColumns]='displayedColumns'
                [feisColumns]='feisColumns'
m               (onPageEvent)='pageEvent($event)'
                (onSortEvent)='sortEvent($event)'
                (onUpdateItem)='updateItem($event)'
                (onDeleteItem)='deleteItem($event)'
                (onArchiveItem)="archiveItem($event)"
                (onRowClick)='rowClickItem($event)'
                [customColumnTemplate]="templateRef"
                [deletePermission]="[ENUM_PERMISSION.DELETE_FM_REF_PURCHASE_SERVICE]"
                [updatePermission]="[ENUM_PERMISSION.UPDATE_FM_REF_PURCHASE_SERVICE_INFO]"
                [actionText]='actionText'
                [isProductPrestation]="true"
            ></base-data-table>
        </div>
    </vex-page-layout-content>
</vex-page-layout>

<ng-template #contentPopupTemplate>
    <form [formGroup]='popupForm'>
        <base-form-input
            label='Fournisseur'
            type='autocomplete'
            [items$]='suppliers$'
            displayProperty='label'
            controlName='supplier'
            (autocompleteChange)='supplierAutocompleteChange($event)'
        >
        </base-form-input>

        <base-form-input
            label='Prestation générique'
            type='autocomplete'
            [items$]='genericPrestations$'
            displayProperty='label'
            controlName='genericPrestation'
            (autocompleteChange)='genericPrestationAutocompleteChange($event)'
        >
        </base-form-input>

    </form>
</ng-template>

<ng-template #templateRef let-column let-row="element" let-status="status">
  <div *ngIf="column === 'quoteId'" class="relative flex items-center" >

    <button [matTooltip]="row.quoteId ? row?.quote?.technicalIdentifier : row?.contract?.technicalIdentifier"
            (click)="redirectToElement(row)"
            matTooltipClass="example-tooltip-uppercase"
    >
        <mat-icon color="{{(row.quoteId || row.contract?.format === 'Devis')? 'secondary' : 'primary' }}">
            {{(row.quoteId || row.contract?.format === 'Devis')? 'description' : 'assignment' }}
        </mat-icon>
    </button>
  </div>

    <div *ngIf="column === 'tva'" class="relative flex items-center">
        <span>{{ getTvaRate(row.tva) }}</span>
    </div>

    <div *ngIf="column === 'label'" class="relative flex items-center" (mouseenter)="showComment = row._id" (mouseleave)="showComment = null">
        <span>{{ row.label }}</span>
    </div>

    <div *ngIf="column === 'reference'" class="relative flex items-center" (mouseenter)="showPreviousReference = row._id" (mouseleave)="showPreviousReference = null">
        <span>{{ row.reference }}</span>
        <div [hidden]="showPreviousReference !== row._id" class="absolute bg-white z-10 top-0 translate-y-5">
            <div *ngIf="row.previousReference" class="p-2 bg-gray-100 border rounded shadow-lg max-w-3xl overflow-auto" [innerHTML]="row.previousReference"></div>
        </div>
    </div>

</ng-template>

