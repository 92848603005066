import { Injectable } from '@angular/core';
import { GenericEquipmentModel } from 'src/app/core/models/generic-equipment.model';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/core/base/base.service';

@Injectable({
    providedIn: 'root'
})
export class GenericEquipmentService extends BaseService<GenericEquipmentModel>{
    defaultSort = 'createdAt';

    constructor(public http: HttpClient) {
        super(http, 'generic-equipment');
    }

}


