<ng-container *ngIf="isCreateMode() || isUpdateMode() || isCustomMode()">
    <form (ngSubmit)="save()" [formGroup]="form">
        <base-form-header [mode]='mode' createLabel='Ajouter un ID énergie'
                          customLabel='Ajouter un ID énergie'
                          updateLabel='Modifier un ID énergie'></base-form-header>

        <mat-dialog-content fxLayout="column">

            <base-form-input
                    label="Type d'énergie"
                    type='autocomplete'
                    [items$]="genericEnergyIds$"
                    (autocompleteChange)='genericEnergyIdsAutoCompleteChange($event)'
                    displayProperty='energyType'
                    controlName='genericEnergyId'>
            </base-form-input>

            <mat-form-field class="full-width">
                <mat-label>{{ customFieldLabel }}</mat-label>
                <mat-error *ngIf="energyIdIdCustomError && !isIDEnergyAlreayExist">
                    {{ energyIdIdCustomError }}
                </mat-error>
                <mat-error *ngIf="isIDEnergyAlreayExist">
                    {{ energyIdIdCustomError }}
                </mat-error>
                <input matInput formControlName="energyIdId">
            </mat-form-field>

            <base-form-input
                *ngIf="!isCustomMode()"
                (autocompleteChange)='onAutocompleteInput($event)'
                [items$]="buildings$"
                [selectedItems]="form.get('buildings').value"
                [isStaticData]="true"
                controlName='buildings'
                displayProperty='label'
                label='Bâtiment(s)'
                type='search-multiple'
                (onSelectItems)="onSelectBuildings($event)"
            >
            </base-form-input>

            <base-form-input
                    *ngIf="isCustomMode()"
                    controlName='buildings'
                    displayProperty='label'
                    label='Bâtiment(s)'
                    type='autocomplete'
                    [disabled]="true"
            >
            </base-form-input>

            <mat-form-field class="example-chip-list" appearance="fill">
                <mat-label>Établissement(s)</mat-label>
                <mat-chip-list aria-label="Établissement(s)">
                    <mat-chip formControlName="establishments"
                              *ngFor="let establishment of form.get('establishments').value"
                            [disabled]="true">
                        {{ establishment.label }}
                    </mat-chip>
                </mat-chip-list>
            </mat-form-field>

            <base-form-input
                    label="Bâtiment Contact Achat"
                    type='autocomplete'
                    [items]="favoriteBuildings"
                    [disabled]="isCustomMode()"
                    (autocompleteChange)='genericBuildingsAutoCompleteChange($event)'
                    displayProperty='label'
                    controlName='favoriteBuilding'>
            </base-form-input>

            <!--            <base-form-input-->
            <!--                    label="Établissement"-->
            <!--                    type="autocomplete"-->
            <!--                    [items$]="establishments$"-->
            <!--                    (autocompleteChange)="genericEstablishmentsAutoCompleteChange($event)"-->
            <!--                    displayProperty="label"-->
            <!--                    controlName='establishment'>-->
            <!--            </base-form-input>-->

            <!--            <base-form-input-->
            <!--                    label="Bâtiment"-->
            <!--                    type="autocomplete"-->
            <!--                    [items$]="buildings$"-->
            <!--                    (autocompleteChange)="genericBuildingsAutoCompleteChange($event)"-->
            <!--                    displayProperty="label"-->
            <!--                    controlName='building'>-->
            <!--            </base-form-input>-->

        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <base-form-input
                    type='submit'
                    [mode]='mode'
                    entityLabel='ENERGY_ID'
                    (onCreate)='createItem()'
                    (onCustom)='createItem()'
                    (onUpdate)='updateItem()'
                    [loading]="loading"
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>

<ng-container *ngIf='isArchiveMode()'>
    <form-modal-delete-base
            (onClose)='close()'
            (onDelete)='archiveItem()'
            title="Archiver l'ID énergie"
            defaultMessage='Êtes-vous sûr de vouloir archiver cet ID énergie ?'
    >
    </form-modal-delete-base>
</ng-container>

<ng-container *ngIf="isImportMode()">
    <vex-form-import-base
            [modalTitle]='"Importer des ID Energie"'
            [entityLabel]='"ID Energie"'
            [form]='importForm'
            [loading]='loading'
            [service]='service'
            [mode]='mode'
            (saveForm)='save()'
            (importItems)='importItems()'
            [canDownloadTemplateModel]="canDownloadTemplateModel"
    ></vex-form-import-base>
</ng-container>
