<div *ngIf='equipment; else loading' class="mt-6 flex flex-col md:flex-row md:items-start">
    <div class='flex-auto'>
        <div class='mt-6 flex flex-col md:flex-row md:items-start'>
           <!--           LEFT CARD / DETAILS -->
           <div class='flex-auto'>
                <div id="equipment-resume-details-card" class='card'>

                    <div class='px-gutter py-4 border-b' fxLayout='row' fxLayoutAlign='space-between center'>
                        <h2 class='title m-0'>Détails</h2>
                        <div *ngIf="isEditable">
                            <button color='primary' mat-raised-button type='button' (click)='updateItem()' *hasPermission="[ENUM_PERMISSIONS.UPDATE_EQUIPMENT]">MODIFIER</button>
                        </div>
                    </div>

                    <div class="px-gutter py-4" gdColumns="1fr 1fr" gdColumns.xs="1fr" gdGap="16px">
                        <details-line-base
                                hint='Identifiant technique'
                                [label]='equipment.technicalIdentifier'
                        >
                        </details-line-base>

                        <details-line-base
                                *ngIf='equipment?.reference'
                                hint='Référence équipement'
                                [label]='equipment.reference'
                        >
                        </details-line-base>

                        <details-line-base
                                hint='Etablissement'
                                [label]='equipment.establishment?.label'
                                [link]="'/management/establishment/' + equipment.establishment?._id"
                                [linkPermission]="ENUM_PERMISSIONS.READ_ESTABLISHMENT"
                        >
                        </details-line-base>

                        <details-line-base
                                hint="Référent de l'équipement"
                                [label]='equipment.contactIntern?.fullName'
                                [link]="'/management/user-profile/' + equipment.contactIntern?._id"
                                [linkPermission]="ENUM_PERMISSIONS.READ_INTERNAL_CONTACT"
                        >
                        </details-line-base>

                        <details-line-base
                                hint='Numéro de série'
                                [label]='equipment.serialNumber'
                        >
                        </details-line-base>

                        <details-line-base
                                hint='Marque'
                                [label]='equipment.mark'
                        >
                        </details-line-base>

                        <details-line-base
                                *ngIf='equipment.model'
                                hint='Modèle'
                                [label]='equipment?.model'
                        >
                        </details-line-base>

                        <details-line-base
                                *ngIf='equipment.genericEquipment'
                                hint='Equipement générique'
                                [label]='equipment.genericEquipment?.label'
                        >
                        </details-line-base>

                        <details-line-base
                                hint='Mise en service'
                                [label]='equipment.commissioningDate'
                                type='date'
                        >
                        </details-line-base>

                        <details-line-base
                                hint='Fin de garantie constructeur'
                                [label]='equipment.endManufacturerDate'
                                type='date'
                        >
                        </details-line-base>

                        <details-line-base
                                hint="Achat"
                                [label]='equipment.purchaseDate'
                                type='date'
                        >
                        </details-line-base>

                        <details-line-base
                                *ngIf="equipment.detentionType"
                                hint="Type de détention"
                                [label]='equipment.detentionType?.label'
                        >
                        </details-line-base>

                        <details-line-base
                                hint="Prix acquisition HT"
                                [label]="equipment.valueAmortization | number: '1.5-5'"
                        >
                        </details-line-base>

                    </div>

                    <ng-container *ngIf='equipment?.valueAmortization >= 500'>
                        <div class='px-gutter py-4 border-b' fxLayout='row' fxLayoutAlign='space-between center'>
                            <h2 class='title m-0'>Amortissement</h2>
                        </div>
                        <div class="px-gutter py-4" gdColumns="1fr 1fr" gdColumns.xs="1fr" gdGap="16px">

                            <details-line-base
                                    hint="Début d'amortissement"
                                    [label]='equipment.startAmortizationDate'
                                    type='date'
                            >
                            </details-line-base>

                            <details-line-base
                                    hint="Fin d'amortissement"
                                    [label]='equipment.endAmortizationDate'
                                    type='date'
                            >
                            </details-line-base>

                            <details-line-base
                                    hint='Durée amortissement'
                                    [label]='equipment.depreciationPeriod?.value'
                            >
                            </details-line-base>
                        </div>
                    </ng-container>

                    <div  class='px-gutter py-4 border-b' fxLayout='row' fxLayoutAlign='space-between center'>
                        <h2 class='title m-0'>Statut de fonctionnement</h2>
                        <span *ngIf="!equipment?.isDefinitivelyOver">
                            <button  color='primary' mat-raised-button type='button' (click)='createMetrikStatus()' *hasPermission="[ENUM_PERMISSIONS.UPDATE_EQUIPMENT]">AJOUTER</button>
                        </span>
                    </div>
                    <vex-history [events]="historiqueData"></vex-history>

                    <div class='px-gutter py-4 border-b' fxLayout='row' fxLayoutAlign='space-between center'>
                        <h2 class='title m-0'>Dates de dernière maintenance</h2>
                    </div>
                    <div class="px-gutter py-4" gdColumns="1fr 1fr" gdColumns.xs="1fr" gdGap="16px">

                        <details-line-base
                                hint='Maintenance réglementaire'
                                [label]='equipment.lastRegulatoryMaintenanceDate'
                                type='date'
                        >
                        </details-line-base>

                        <details-line-base
                                hint='Maintenance non réglementaire'
                                [label]='equipment.lastMaintenanceDateOther'
                                type='date'
                        >
                        </details-line-base>
                    </div>
                </div>
           </div>

            <!--           RIGHT CARDS-->
            <div class="flex flex-col gap-4 flex-none w-full max-w-full sm:max-w-full md:max-w-lg md:ltr:ml-6 md:rtl:mr-6 mt-6 md:mt-0">

                <!--           ACTIONS-->
                <div class='card' *ngIf="isEditable && !equipment?.isDefinitivelyOver">
                    <h2 class='title m-0 mr-2 p-4 border-b'>Actions</h2>
                    <div class='px-4 py-4 flex flex-col gap-6'>
                        <button (click)='setEndServiceDate()'
                                class='w-full'
                                color='primary'
                                mat-raised-button
                                matSuffix>
                            AJOUTER UNE DATE DE FIN DE SERVICE
                        </button>
                    </div>
                </div>

                <!--     LOCATION -->
                <div class='card'>
                    <div class='px-gutter py-4 border-b' fxLayout='row' fxLayoutAlign='space-between center'>
                        <h2 class='title m-0'>Emplacement actuel</h2>
                        <div *ngIf="isEditable">
                            <button
                                    color='primary'
                                    mat-raised-button
                                    type='button'
                                    (click)='addLocation()'
                                    *hasPermission="[ENUM_PERMISSIONS.UPDATE_EQUIPMENT]"
                            >
                                {{ !equipment?.location?.technicalIdentifier ? 'AJOUTER' : 'MODIFIER' }}
                            </button>
                        </div>
                    </div>

                    <div class="px-gutter py-4" gdColumns="1fr 1fr" gdColumns.xs="1fr" gdGap="16px">
                        <details-line-base
                                hint='Identifiant technique'
                                [label]='equipment?.location?.technicalIdentifier'
                        >
                        </details-line-base>

                        <details-line-base
                                hint='Bâtiment'
                                [label]='equipment?.location?.building?.label'
                        >
                        </details-line-base>

                        <details-line-base
                                hint='Salle'
                                [label]='equipment?.location?.room?.label'
                        >
                        </details-line-base>

                        <details-line-base
                                hint='Etage'
                                [label]='equipment?.location?.room?.stage?.label'
                        >
                        </details-line-base>

                        <details-line-base
                                hint='Surface totale (m²)'
                                [label]='equipment?.location?.room?.stage?.label'
                        >
                        </details-line-base>

                        <details-line-base
                                hint='Nombre de lits'
                                [label]='equipment?.location?.building?.surface'
                        >
                        </details-line-base>
                    </div>
                </div>

                <!--           ACTIVITY -->
                <div id="equipment-resume-activities-card" class="card" *hasPermission="[ENUM_PERMISSIONS.READ_EQUIPMENT]">
                    <div class="px-gutter py-4 border-b"  fxLayout='row' fxLayoutAlign='space-between center'>
                        <h2 class="title m-0">Activités
                            <icon-warning
                                    class='ml-2'
                                    [show]='isEditable && (displayPercentWarning || displayLocation)'
                                    [toolTip]='displayMessage()'
                            >
                            </icon-warning>
                        </h2>

                        <ng-container *ngIf="isEditable">
                            <button *hasPermission="[ENUM_PERMISSIONS.UPDATE_EQUIPMENT]"
                                    (click)='createActivity()'
                                    class='ml-4'
                                    color='primary'
                                    fxFlex='none'
                                    mat-mini-fab
                                    matTooltip='Ajouter une activité'
                                    type='button'>
                                <mat-icon [icIcon]='icAdd'></mat-icon>
                            </button>
                        </ng-container>

                    </div>

                    <base-data-table
                            [dataSource]='dataSourceActivity'
                            [displayedColumns]='displayedColumnsActivity'
                            [feisColumns]='feisColumnsActivity'
                            [showPaginator]='false'
                            (onUpdateItem)='updateItemActivity($event)'
                            (onDeleteItem)='deleteItemActivity($event)'
                    ></base-data-table>
                </div>

            </div>
        </div>

        <!--           LOCATION HISTORY -->
        <ng-container *ngIf="displayHistoryLocation">
            <div class="card mt-6" *hasPermission="[ENUM_PERMISSIONS.READ_EQUIPMENT]">
                <div class="px-gutter py-4 border-b" fxLayout='row' fxLayoutAlign='space-between center'>
                    <h2 class="title m-0">Historique des emplacements</h2>
                </div>
                <base-data-table
                        [showPaginator]='false'
                        [showSort]='false'
                        [dataSource]='dataSourceLocationHistory'
                        [displayedColumns]='displayedColumnsLocationHistory'
                        [feisColumns]='feisColumnsLocationHistory'
                        [customColumnTemplate]="templateRef"
                ></base-data-table>
                <ng-template #templateRef let-column let-row="element">
                    <div *ngIf="column === 'surface'">
                        <div *ngIf='row?.room; else noRoom'>
                            {{row?.room?.surface}} m²
                        </div>
                        <ng-template #noRoom>
                            {{row?.building?.surface}} m²
                        </ng-template>
                    </div>
                </ng-template>
            </div>
        </ng-container>

        <!--  AMORTISSEMENT -->
        <div *hasPermission="[ENUM_PERMISSIONS.READ_EQUIPMENT]" >
            <div class="card mt-6" *ngIf='equipment.valueAmortization >= 500'>
                <div class="px-gutter py-4 border-b" fxLayout='row' fxLayoutAlign='space-between center'>
                    <h2 class="title m-0">Amortissement</h2>
                </div>

                <base-data-table
                        [showPaginator]='false'
                        [showSort]='false'
                        [dataSource]='dataSourceAmortization'
                        [displayedColumns]='displayedColumnsAmortization'
                        [feisColumns]='feisColumnsAmortization'
                ></base-data-table>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="px-gutter py-4" gdColumns="1fr 1fr" gdColumns.xs="1fr" gdGap="16px">
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
    </div>
</ng-template>
