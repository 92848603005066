<div class="w-full flex justify-center card">
    <table mat-table [dataSource]="dataSource" matSort class="w-full divide-y divide-gray-300">
        <!-- Position Column -->
        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td mat-cell *matCellDef="let element" [ngClass]="{'subheader-cell': element.category === 'subheader'}">
                <ng-container *ngIf="element.category !== 'subheader'; else subheaderTemplate">
                    <span *ngIf="element?.added === ContactTypeEnum.APPROVISIONNEUR">
                        <mat-icon [icIcon]="faQuestion"
                                  class="w-5 h-5 leading-none flex items-center justify-center text-orange"
                                  [matTooltip]="'Ajouté par l\'approvisionneur'"></mat-icon>
                    </span>
                    <span *ngIf="element?.added === ContactTypeEnum.REFERENT_METIER">
                        <mat-icon [icIcon]="faQuestion"
                                  class="w-5 h-5 leading-none flex items-center justify-center text-red" size="18px"
                                  [matTooltip]="'Ajouté par l\'approvisionneur'"></mat-icon>
                    </span>
                </ng-container>
                <ng-template #subheaderTemplate>
                    <div class="custom-subheader">{{ element.name }}</div>
                </ng-template>
            </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Libellé</th>
            <td mat-cell *matCellDef="let element"
                [ngClass]="{'subheader-cell': element.category === 'subheader', 'text-gray': !element.isAllowed}"
                [style.color]="showExpired && element.item?.isExpired ? 'red' : null"
            >
                <ng-container *ngIf="element.category !== 'subheader'; else subheaderTemplate">
                    {{ element.item?.label || element.label }}
                </ng-container>
                <ng-template #subheaderTemplate>
                    <div class="custom-subheader"></div>
                </ng-template>
            </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="manufacturer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fabriquant</th>
            <td mat-cell *matCellDef="let element" [ngClass]="{'subheader-cell': element.category === 'subheader'}">
                <ng-container *ngIf="element.category !== 'subheader'; else subheaderTemplate">
                    {{ element.item?.manufacturer || element.manufacturer }}
                </ng-container>
                <ng-template #subheaderTemplate>
                    <div class="custom-subheader"></div>

                </ng-template>
            </td>
        </ng-container>

        <!-- Quantity Column -->
        <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Quantité</th>
            <td mat-cell *matCellDef="let element" [ngClass]="{'subheader-cell': element.category === 'subheader'}">
                <ng-container *ngIf="element.category !== 'subheader'; else subheaderTemplate">

                    <div class='flex flex-row justify-between w-20 p-0'
                         *ngIf="(showExpired && !element.item?.isExpired) && (!isBasketType ? element.quantity >= 0 : element.quantity)">
                        <a
                                [disabled]='!element.isAllowed'
                                (click)="updateQuantity(element, 'minus', (!element.isAllowed))"
                                class="w-5 h-5 leading-none flex items-center justify-center"
                                mat-icon-button
                                [ngClass]='{"bg-gray": !element.isAllowed, "hover:bg-hover hover:text-primary text-white bg-primary": element.isAllowed}'
                        >
                            <mat-icon [icIcon]="icMinus" size="18px"></mat-icon>
                        </a>
                        <input [disabled]='!element.isAllowed'
                               (change)='_checkNumberInput($event, element)'
                               class='number-no-arrows w-1/3 min-w-10 text-center' type='number'
                               [min]="(!isBasketType ? 0 : 1)"
                               oninput="this.value = (this.value > 9999 ? 9999 : Math.max(1, this.value))"
                               [(ngModel)]='element.quantity'>
                        <a
                                [disabled]='!element.isAllowed'
                                (click)="updateQuantity(element, 'plus', !element.isAllowed)"
                                class="w-5 h-5 leading-none flex items-center justify-center"
                                mat-icon-button
                                [ngClass]='{"bg-gray": !element.isAllowed, "hover:bg-hover hover:text-primary text-white bg-primary": element.isAllowed}'
                        >
                            <mat-icon [icIcon]="icPlus" size="18px"></mat-icon>
                        </a>
                    </div>

                </ng-container>
                <ng-template #subheaderTemplate>
                    <div class="custom-subheader"></div>
                </ng-template>
            </td>
        </ng-container>

        <ng-container matColumnDef="stock">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>En stock</th>
            <td mat-cell *matCellDef="let element" [ngClass]="{'subheader-cell': element.category === 'subheader'}">
                <ng-container *ngIf="element.category !== 'subheader'; else subheaderTemplate">

                    <ng-container *ngIf="canChangeStockQuantity">
                        <input (change)='_stockChange($event, element)'
                               class='number-no-arrows w-1/3 min-w-10 text-center' type='number' min='0'
                               oninput="this.value = Math.max(0, this.value)" [(ngModel)]='element.stock'>
                    </ng-container>
                    <ng-container *ngIf="!canChangeStockQuantity">
                        {{element.stock}}
                    </ng-container>


                </ng-container>
                <ng-template #subheaderTemplate>
                    <div class="custom-subheader"></div>
                </ng-template>
            </td>
        </ng-container>

        <ng-container matColumnDef="movementOfStock">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Mouvement de stock</th>
            <td mat-cell *matCellDef="let element" [ngClass]="{'subheader-cell': element.category === 'subheader'}">
                <ng-container *ngIf="element.category !== 'subheader'; else subheaderTemplate">
                    {{element.movementOfStock}}
                </ng-container>
                <ng-template #subheaderTemplate>
                    <div class="custom-subheader"></div>
                </ng-template>
            </td>
        </ng-container>
        <ng-container matColumnDef="urgent">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Urgent</th>
            <td mat-cell *matCellDef="let element" [ngClass]="{'subheader-cell': element.category === 'subheader'}">
                <ng-container *ngIf="element.category !== 'subheader'; else subheaderTemplate">
                    <mat-slide-toggle color="primary" [disabled]="!element.isAllowed"
                                      [checked]="element?.isUrgent"
                                      (change)="updateEmergency($event, element)"></mat-slide-toggle>
                </ng-container>
                <ng-template #subheaderTemplate>
                    <div class="custom-subheader"></div>

                </ng-template>
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Statut</th>
            <td mat-cell *matCellDef="let element" [ngClass]="{'subheader-cell': element.category === 'subheader'}">
                <ng-container *ngIf="element.category !== 'subheader'; else subheaderTemplate">
                    {{element.status | statusToText}}
                </ng-container>
                <ng-template #subheaderTemplate>
                    <div class="custom-subheader"></div>
                </ng-template>
            </td>
        </ng-container>

        <ng-container matColumnDef="removeAction">
            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td mat-cell *matCellDef="let element" [ngClass]="{'subheader-cell': element.category === 'subheader'}">
                <ng-container *ngIf="element.category !== 'subheader' && canRemove(element)">
                    <button mat-icon-button class="text-red" [disabled]='!canDelete && !element.isAllowed'
                            (click)='remove(element)'>
                        <mat-icon>delete</mat-icon>
                    </button>
                </ng-container>
                <ng-container *ngIf="element.category !== 'subheader' && element.status === 'WAITING'" >
                    <button mat-icon-button class="text-primary" [disabled]='!canRestore && !element.isAllowed'
                            (click)='this.restoreItem.emit(element)'>
                        <mat-icon>restore</mat-icon>
                    </button>
                </ng-container>
                <ng-template #subheaderTemplate>
        <!--                    <div class="custom-subheader"></div>-->
                </ng-template>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-gray-50"></tr>
        <tr mat-row [ngClass]="{'bg-orange-light': (row.addedAs && row.addedAs !== 'CREATOR')}"
            *matRowDef="let row; columns: displayedColumns; when: isNotSubheader"
        ></tr>
    </table>
</div>
