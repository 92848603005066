<ng-container *ngIf="canCreateMode || canUpdateMode || canReadMode">
    <form (ngSubmit)="save()" [formGroup]="form" autocomplete='off'>
        <base-form-header [mode]='mode' createLabel='Ajouter un contact au fournisseur'
                          [updateLabel]='form.get("firstName").value + " " + form.get("lastName").value?.toUpperCase()'></base-form-header>

        <mat-dialog-content fxLayout="column">

            <base-form-input
                    *ngIf='!canReadMode'
                    label="Mme/Mr"
                    type="autocomplete"
                    controlName="gender"
                    [items]="genderItem"
            ></base-form-input>

            <base-form-input
                    *ngIf='canReadMode'
                    label="Mme/Mr"
                    controlName="gender"
                    [disabled]='isReadMode()'
            ></base-form-input>

            <base-form-input
                    *ngIf='!canReadMode'
                    label="Type d'acteur"
                    type="multiple"
                    controlName="contactTypes"
                    [items]="contactTypes"
                    displayProperty='label'
            ></base-form-input>

            <base-form-input
                    *ngIf='canReadMode'
                    label='Type'
                    [disabled]='isReadMode()'
                    displayProperty='label'
                    controlName='type'>
            </base-form-input>

            <div class="flex flex-col sm:flex-row w-full gap-4">
                <base-form-input class="sm:w-1/2 w-full"
                                 [disabled]='isReadMode()'
                                 label='Nom'
                                 controlName='lastName'
                                 [forceUpperCase]='true'>
                </base-form-input>

                <base-form-input class="sm:w-1/2 w-full"
                                 [disabled]='isReadMode()'
                                 label='Prénom'
                                 controlName='firstName'>
                </base-form-input>
            </div>

            <base-form-input
                    [disabled]='isReadMode()'
                    label='N° Téléphone'
                    controlName='mobileNumber'>
            </base-form-input>

            <base-form-input
                    [disabled]='isReadMode()'
                    label='Email'
                    controlName='email'>
            </base-form-input>

            <form>
                <p class='mb-3'>Affectations</p>
                <mat-divider class="mb-6"></mat-divider>

                <div *ngIf="selectedAffectations.length > 0">
                    <div *ngFor="let affectation of selectedAffectations; let i = index" class="establishment-row">
                        <div class="establishment-fields-row">

                            <!-- Etablissement -->
                            <mat-form-field appearance="fill" class="flex-auto">
                                <mat-label>Établissement</mat-label>
                                <input matInput [matAutocomplete]="autoEstablishment"
                                       [(ngModel)]="selectedAffectations[i].establishment.label"
                                       name="establishment_{{i}}"
                                       (input)="establishmentAutocompleteChange($event.target.value, i)" [disabled]="isCheckedBoxSelectAllEstablishments">
                                <mat-autocomplete #autoEstablishment="matAutocomplete"
                                                  (optionSelected)="onEstablishmentSelect($event, i)">
                                    <mat-option *ngFor="let est of filteredEstablishments[i] || []"
                                                [value]="est.label"
                                                [attr.data-id]="est._id">
                                        {{ est.label }}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="!selectedAffectations[i].establishment.label">
                                    salut
                                </mat-error>
                            </mat-form-field>

<!--                            &lt;!&ndash; Responsable &ndash;&gt;-->
<!--                            <mat-form-field appearance="fill" class="flex-auto">-->
<!--                                <mat-label>Responsable</mat-label>-->
<!--                                <input matInput [matAutocomplete]="autoResponsable"-->
<!--                                       [(ngModel)]="selectedAffectations[i].responsable.fullName"-->
<!--                                       name="responsable_{{i}}"-->
<!--                                       (focus)="ensureResponsablesLoaded(i)"-->
<!--                                       (input)="filterResponsables($event.target.value, i)"-->
<!--                                       [disabled]="!selectedAffectations[i].establishment._id">-->
<!--                                <mat-autocomplete #autoResponsable="matAutocomplete"-->
<!--                                                  (optionSelected)="onResponsableSelect($event, i)">-->
<!--                                    <mat-option *ngFor="let resp of filteredResponsables[i] || []"-->
<!--                                                [value]="resp.fullName">-->
<!--                                        {{ resp.fullName }}-->
<!--                                    </mat-option>-->
<!--                                </mat-autocomplete>-->
<!--                            </mat-form-field>-->


                            <button class="mb-6" mat-icon-button color="warn" (click)="removeAffectation(i)" [disabled]="isCheckedBoxSelectAllEstablishments">
                                <mat-icon>cancel</mat-icon>
                            </button>

                        </div>
                    </div>
                </div>

                <div class="flex flex-col sm:flex-row w-full items-center gap-4">
                    <!-- Bouton d'ajout -->
                    <button mat-raised-button color="primary"
                            (click)="addAffectation()"
                            [disabled]="this.form.get('isCheckedBoxSelectAllEstablishments').value === true">
                        <mat-icon>add</mat-icon>
                        Ajouter une affectation
                    </button>

                    <!-- Sélection de tous les établissements -->
                    <base-form-input controlName="isCheckedBoxSelectAllEstablishments"
                                     type="checkbox"
                                     label="Affecter à tous les établissements"
                                     (onChangeCheckBox)="selectAllEstablishments($event)">
                    </base-form-input>
                </div>

            </form>

        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <base-form-input
                    [loading]="loading"
                    type='submit'
                    [mode]='mode'
                    entityLabel='SUPPLIER'
                    (onCreate)='createItem()'
                    (onUpdate)='updateItem()'
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>

<ng-container *ngIf="canImportMode">
    <vex-form-import-base
            [modalTitle]='"Importer des contacts"'
            [entityLabel]='"Contact"'
            [form]='importForm'
            [loading]='loading'
            [service]='service'
            [mode]='mode'
            (saveForm)='save()'
            (importItems)='importItems()'
            [canDownloadTemplateModel]='true'
    ></vex-form-import-base>
</ng-container>

<ng-container *ngIf="canDeleteMode">
    <form-modal-delete-base
            [label]="data.name"
            (onClose)="close()"
            (onDelete)="deleteItem()"
    ></form-modal-delete-base>
</ng-container>
