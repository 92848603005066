<ng-container>
    <div *ngIf="column === 'addToCart' && !readonly && canAddToBasketPermission">
        <button mat-icon-button color="primary" (click)='add(row)' *ngIf="!readonly">
            <mat-icon>shopping_cart</mat-icon>
        </button>
    </div>
    <div *ngIf="column === 'quantity' && !readonly && canAddToBasketPermission">
        <div class='flex flex-row justify-between w-20 p-0' *ngIf="!readonly">
            <a (click)='updateQuantity(row, "minus")'
               class="w-5 h-5 leading-none flex items-center justify-center hover:bg-hover hover:text-primary text-white bg-primary"
               mat-icon-button>
                <mat-icon [icIcon]="icMinus" size="18px"></mat-icon>
            </a>
            <input (change)='_checkNumberInput($event, row)'
                   class='number-no-arrows w-1/3 min-w-10 text-center'
                   type='number'
                   min='1'
                   oninput="this.value = (this.value > 9999 ? 9999 : Math.max(1, this.value))"
                   [(ngModel)]='isContractColumnQuantity ? row.minQuantity : row.quantity'>
            <a (click)='updateQuantity(row, "plus")'
               class="w-5 h-5 leading-none flex items-center justify-center hover:bg-hover hover:text-primary text-white bg-primary"
               mat-icon-button>
                <mat-icon [icIcon]="icPlus" size="18px"></mat-icon>
            </a>
        </div>
        <div class='flex flex-row justify-between w-20 p-0' *ngIf="readonly">
            {{ isContractColumnQuantity ? row.minQuantity : row.quantity}}
        </div>
    </div>
</ng-container>
