import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {SnackbarService} from '../../../../../../core/services/snackbar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
    DialogConfirmComponent,
} from '../../../../../../../@vex/components/dialog-confirm/dialog-confirm.component';
import {SelectionModel} from '@angular/cdk/collections';
import {MatTableDataSource} from '@angular/material/table';
import moment from "moment/moment";
import {ContractService} from "../../contract.service";
import {ContractModel} from "../../../../../../core/models/contract.model";


export interface IDialogConfirm {
    description: string;
    supplementaryDescription: {
        label: string;
        color: string;

    };
    title: string;
    validText: string;
    cancelText: string;
    cancelDisabled: boolean;
    call$: Observable<any>;
    body?: any;
    _id?: string;
    selectedItems?: any[];
    contract?: ContractModel;
}

@Component({
    selector: 'vex-contract-confirm-validate',
    templateUrl: './contract-confirm-validate.component.html',
    styleUrls: ['./contract-confirm-validate.component.scss']
})
export class ContractConfirmValidateComponent implements OnInit, OnDestroy {

    subscription = new Subscription();
    loading = false;

    displayedColumns: string[] = ['select', 'label', 'previousDate', 'nextRecurrence'];
    dataSource = new MatTableDataSource<IDialogConfirm>([]);
    selection = new SelectionModel<IDialogConfirm>(true, []);


    constructor(
        private snackbarService: SnackbarService,
        private dialog: MatDialogRef<DialogConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IDialogConfirm,
        private readonly contractService: ContractService,
    ) {
    }

    ngOnInit(): void {
        this.initData();
        console.log('Data :', this.data);
    }

    initData() {
        this.dataSource.data = this.data.body;
        console.log('Data source :', this.dataSource.data);
        this.dataSource.data.forEach((item: any) => {
            item.confirmationDelayDate = moment(item.nextRecurrence).subtract(item.confirmationDelay, 'days').toDate();
        });
    }

    // async validate() {
    //     this.loading = true;
    //
    //     const selectedItems = this.getSelectedItems();
    //     console.log('Éléments sélectionnés :', selectedItems);
    //     console.log('Data :', this.data);
    //
    //
    //
    //     const sub = await this.data.call$.subscribe(x => {
    //             this.dialog.close(x);
    //             this.loading = false;
    //         },
    //         error => {
    //
    //             if (error?.error?.statusCode === 5509) {
    //                 this.snackbarService.danger(error.error.message);
    //             }
    //
    //             this.loading = false;
    //             this.dialog.close();
    //         });
    //     this.subscription.add(sub);
    // }

    async validate() {
        // this.loading = true;
        //
        // const selectedItems = this.getSelectedItems();  // Récupère les éléments sélectionnés
        // console.log('Éléments sélectionnés :', selectedItems);
        // console.log('Data :', this.data);
        //
        // // Crée un Observable en envoyant les éléments sélectionnés
        // const sub = await this.data.call$.subscribe(
        //     x => {
        //         this.dialog.close({ result: x, selectedItems: selectedItems });  // Ferme la boîte de dialogue en incluant les éléments
        //         this.loading = false;
        //     },
        //     error => {
        //         if (error?.error?.statusCode === 5509) {
        //             this.snackbarService.danger(error.error.message);
        //         }
        //
        //         this.loading = false;
        //         this.dialog.close();
        //     }
        // );
        // this.subscription.add(sub);

        this.loading = true;

        const selectedItems = this.getSelectedItemIds();
        console.log('Éléments sélectionnés :', selectedItems);

        const sub = await this.contractService.validatesContract(this.data.contract._id, this.data.contract, selectedItems).subscribe(
            x => {
                this.loading = false;
                this.dialog.close();
            },
            error => {
                if (error?.error?.statusCode === 5509) {
                    this.snackbarService.danger(error.error.message);
                }
                this.loading = false;
                this.dialog.close();
            }
        );
        this.subscription.add(sub);
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    checkboxLabel(row?: IDialogConfirm): string {
        if (!row) {
            return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row._id + 1}`;
    }

    close() {
        this.dialog.close();
    }

    getSelectedItemIds(): string[] {
        return this.selection.selected.map(item => item._id);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
