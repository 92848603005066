import {Component, OnDestroy, OnInit} from '@angular/core';
import {DetailsBaseComponent} from '../../../../../core/base/components/details-base/details-base.component';
import {ContractService} from '../contract.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../../../core/services/auth.service';
import {Link} from '../../../../../../@vex/interfaces/link.interface';
import {ContractModel} from '../../../../../core/models/contract.model';
import {TicketStatusEnum} from '../../../../../core/enums/ticketStatusEnum';
import {ContractStatusEnum, contractStatusTranslation} from '../../../../../core/enums/contract-status.enum';
import {OrderStatus} from '../../../../../core/enums/order-status.enum';
import {Subscription} from 'rxjs';
import {DialogConfirmComponent} from '../../../../../../@vex/components/dialog-confirm/dialog-confirm.component';
import {MatDialog} from '@angular/material/dialog';
import {
    ContractConfirmValidateComponent
} from '../modals/contract-confirm-validate/contract-confirm-validate.component';
import moment from 'moment';
import {QuotesService} from '../../../achat/quotes/quotes.service';
import {ContactTypeEnum} from '../../../../../core/enums/contact-type.enum';
import {SnackbarService} from '../../../../../core/services/snackbar.service';

@Component({
    selector: 'vex-contract-details',
    templateUrl: './contract-details.component.html',
    styleUrls: ['./contract-details.component.scss'],
})
export class ContractDetailsComponent extends DetailsBaseComponent<ContractModel> implements OnInit, OnDestroy {

    subscription = new Subscription();
    isValidable = false;
    contractStatus: ContractStatusEnum;
    creationStep: number;
    contractStatusEnum = ContractStatusEnum;
    contract: ContractModel;
    contractStatusTranslation = contractStatusTranslation;
    defaultTabs: Link[] = [
        {
            label: 'INFORMATIONS',
            route: './',
            routerLinkActiveOptions: {exact: true},
        },
        {
            label: 'PÉRIMÈTRE',
            route: './perimeter',
            routerLinkActiveOptions: {exact: true},
        },
        {
            label: 'CGA',
            route: './cga',
            additionalCondition: this.authService.currentUserValue().contactType.some(x => x.label === ContactTypeEnum.REFERENT_ENGAGEMENT),
            routerLinkActiveOptions: {exact: true},
        },
        {
            label: 'CATALOGUE FOURNISSEUR',
            route: './catalog',
            additionalCondition: this.authService.currentUserValue().contactType.some(x => x.label === ContactTypeEnum.REFERENT_ENGAGEMENT),
            routerLinkActiveOptions: {exact: true},
        },
        {
            label: 'PANIER CONTRAT',
            route: './basket-contract',
            additionalCondition: this.authService.currentUserValue().contactType.some(x => x.label === ContactTypeEnum.REFERENT_ENGAGEMENT),
            routerLinkActiveOptions: {exact: true},
        },
    ];
    protected readonly TicketStatusEnum = TicketStatusEnum;
    protected readonly OrderStatus = OrderStatus;

    constructor(
        public service: ContractService,
        public activatedRoute: ActivatedRoute,
        public authService: AuthService,
        private router: Router,
        private snackbarService: SnackbarService,
        private dialog: MatDialog,
        private quoteService: QuotesService
    ) {
        super(service, activatedRoute, authService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        const sub = this.service.entity$.pipe().subscribe((contract: ContractModel) => {
            this.contract = contract;
            const isPerimeterDisabled = contract?.creationStep < 2;
            const isCgaDisabled = contract?.creationStep < 3;
            const isCatalogDisabled = contract?.creationStep < 4;
            this.contractStatus = contract?.status;
            this.creationStep = contract?.creationStep;

            this.defaultTabs = this.defaultTabs.map(tab => {
                if (tab.label === 'PÉRIMÈTRE') {
                    tab.disabled = isPerimeterDisabled;
                }
                if (tab.label === 'CGA') {
                    tab.disabled = isCgaDisabled;
                }
                if (tab.label === 'CATALOGUE FOURNISSEUR') {
                    tab.disabled = isCatalogDisabled;
                }
                if (tab.label === 'PANIER CONTRAT') {
                    tab.disabled = isCatalogDisabled;
                }
                return tab;
            });


            if (contract?.creationStep >= 4) {
                this.isValidable = true;
            } else {
                this.isValidable = false;
            }

        });
        this.subscription.add(sub);

        const subEligibleForValidation = this.service.isValidable$.subscribe((isEligibleForValidation: boolean) => {
            this.isValidable = isEligibleForValidation;
        });
        this.subscription.add(subEligibleForValidation);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.subscription.unsubscribe();
    }

    async validatesContract() {


        const entity = await this.service.entity;

         this.service.confirmValidatesContract(entity._id).subscribe(
            (res) => {

                if (res.data.recurringBasketsFound) {
                    this.dialog.open(ContractConfirmValidateComponent, {
                        data: {
                            title: 'Confirmer la création des paniers',
                            description: 'Certains paniers ont des dates de confirmation antérieures. Précisez pour chacun d’eux si leur création est confirmée ou s’il convient de l’annuler.',
                            body: res.data.recurringBasketsFound,
                            cancelText: 'Annuler',
                            validText: 'Confirmer',
                            contract: entity,
                            // call$: this.service.validatesContract(entity._id, entity, []),
                        },
                    }).afterClosed().subscribe((result) => {
                        if (result) {
                            this.loading = true;
                            this.snackbarService.success('Le contrat a été validé avec succès');
                        }
                    },error => {
                        if (error?.error?.statusCode === 5509) {
                            this.snackbarService.danger(error.error.message);
                        }
                    });
                } else {

                    this.dialog.open(DialogConfirmComponent, {
                        data: {
                            title: 'Validation du contrat',
                            description: `Les paniers contrats ne nécessitent pas de confirmation. Êtes-vous sûr de vouloir valider ce contrat ?`,
                            cancelText: 'Annuler',
                            validText: 'Valider',
                            call$: this.service.validatesContract(entity._id, entity),
                        },
                    }).afterClosed().subscribe((result) => {
                        if(result) {
                            this.loading = true;
                            this.snackbarService.success('Le contrat a été validé avec succès');
                        }
                    });
                }
            });
        /////
        // await this.service.validatesContract(entity._id, entity).subscribe(
        //     (res) => {
        //         recurringBaskets = res;
        //         console.log(res, 'res');
        //
        //         if (res.data.recurringBasketsFound) {
        //             this.dialog.open(ContractConfirmValidateComponent, {
        //                 data: {
        //                     title: 'Confirmer la création des paniers',
        //                     description: 'Certains paniers ont des dates de confirmation antérieures. Précisez pour chacun d’eux si leur création est confirmée ou s’il convient de l’annuler.',
        //                     body: res.data.recurringBasketsFound,
        //                     cancelText: 'Annuler',
        //                     validText: 'Confirmer',
        //                     call$: this.service.confirmValidatesContract(entity._id, entity), // rajouter les check cheked et els no nchecked;
        //                 },
        //             }).afterClosed().subscribe((result) => {
        //             });
        //         } else {
        //
        //         }
        //     },
        //     (error) => {
        //         console.log(error, 'error');
        //     }
        // );
        //
        // console.log(recurringBaskets, 'recurringBaskets');

        // this.dialog.open(DialogConfirmComponent, {
        //   data: {
        //     title: 'Validation du contrat',
        //     description: 'Êtes-vous sûr de vouloir valider ce contrat ?',
        //     cancelText: 'Annuler',
        //     validText: 'Valider',
        //     call$: this.service.validatesContract(entity._id, entity),
        //   },
        // }).afterClosed().subscribe((result) => {
        //   console.log(result, 'result');
        //     this.loading = true;
        //
        //   if (result.data.length > 0) {
        //     this.dialog.open(ContractConfirmValidateComponent, {
        //       data: {
        //         title: 'Confirmer la création des paniers',
        //         description: 'Certains paniers ont des dates de confirmation antérieures. Précisez pour chacun d’eux si leur création est confirmée ou s’il convient de l’annuler.',
        //         body : entity,
        //         cancelText: 'Annuler',
        //         validText: 'Confirmer',
        //         call$: this.service.confirmValidatesContract(entity._id, entity), // rajouter les check cheked et els no nchecked;
        //       },
        //     }).afterClosed().subscribe((result) => {
        //     });
        //   }
        //
        // });


    }

    checkStartDateValidationIsPassed(startValidityDate: Date): boolean {
        const today = moment();
        const startDate = moment(startValidityDate);
        return today.isSameOrAfter(startDate, 'day');
    }

    async invalidateContract() {
        const entity = await this.service.entity;

        const hasRecurringBasket = entity.recurringBasket?.length > 0;
        const defaultMessage = `Êtes-vous certain(e) de vouloir dévalider l’engagement ?`
        const recurringBasketMessage = `Êtes-vous certain(e) de vouloir dévalider l’engagement ? Les paniers contrats seront désactivés.`

        this.dialog.open(DialogConfirmComponent, {
            data: {
                title: 'Dévalidation du contrat',
                description: hasRecurringBasket ? recurringBasketMessage : defaultMessage,
                cancelText: 'Annuler',
                validText: 'Dévalider',
                call$: this.service.invalidateContract(entity._id),
                additionalIcon: 'warning'
            }
        }).afterClosed().subscribe((result) => {
        });
    }

    async deleteDraft() {
        const entity = await this.service.entity;
        const dialogRef = this.dialog.open(DialogConfirmComponent, {
            data: {
                title: 'Suppression du brouillon',
                description: entity.quoteId ? 'Une demande de devis est liée à cet engagement; Êtes-vous certain(e) de vouloir supprimer le brouillon ?' : 'Êtes-vous sûr de vouloir supprimer ce brouillon ?',
                cancelText: 'Annuler',
                validText: 'Valider',
                call$: this.service.delete(entity),
            },
        }).afterClosed().subscribe((result) => {
            this.router.navigateByUrl('/management/engagement').then();
        });
    }
}
