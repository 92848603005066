<ng-container *ngIf="isCreateMode() || isUpdateMode()">
    <form (ngSubmit)="save()" [formGroup]="form" class="flex flex-col justify-between">
        <base-form-header (onClose)='close(false)' [mode]='mode' createLabel='Ouvrir une demande'
                          updateLabel='Modifier le ticket  '></base-form-header>
        <mat-dialog-content class="flex flex-col h-full">
            <p class="mb-6">Veuillez selectionner un établissement.</p>
        </mat-dialog-content>

        <base-form-input
                label='Établissement'
                controlName='establishment'
                type="autocomplete"
                [items$]="establishments$"
                displayProperty="label"
        >
        </base-form-input>

        <mat-dialog-actions align="end">
<!--            <button *ngIf='!isUpdateMode() ' (click)='toDraft()' mat-button type="button">BROUILLON</button>-->
            <base-form-input
                    type='submit'
                    [loading]="loading"
                    [mode]='mode'
                    saveButtonLabel="OUVRIR LA DEMANDE"
                    entityLabel='TICKET-EQUIPMENT'
                    [showCancelButton]='false'
                    (onCreate)='createItem(false)'
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>

<ng-container *ngIf="isDeleteMode()">
    <form-modal-delete-base
            [label]='data.defaults.technicalIdentifier'
            defaultMessage='Êtes vous sur de vouloir supprimer ce ticket ?'
            (onDelete)='deleteItem()'
            [loading]='loading'
            (onClose)='close(null)'
    >
    </form-modal-delete-base>
</ng-container>


<ng-container *ngIf='isArchiveMode()'>
    <form-modal-delete-base
            (onClose)='close()'
            (onDelete)='archiveItem()'
            title="Archiver la récéption"
            defaultMessage='Êtes-vous sûr de vouloir archiver cette récéption ?'
    >
    </form-modal-delete-base>
</ng-container>
