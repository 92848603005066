
<div class='w-full flex flex-col'>
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
        <mat-icon *ngIf="iconCustom" color="primary" class="mr-2">{{ iconCustom }}</mat-icon>
        <h2 *ngIf="mode === CrudModeEnum.Update"
            class="headline m-0"
            fxFlex="auto">{{ fixUpdateLabel }}</h2>
        <h2 *ngIf="mode === CrudModeEnum.Create"
            class="headline m-0"
            fxFlex="auto">{{ createLabel }}</h2>
        <h2 *ngIf="mode === CrudModeEnum.Read"
            class="headline m-0"
            fxFlex="auto">{{ readLabel }}</h2>
        <h2 *ngIf="mode === CrudModeEnum.Import"
            class="headline m-0"
            fxFlex="auto">{{ importLabel }}</h2>
        <h2 *ngIf="mode === CrudModeEnum.Reject"
            class="headline m-0"
            fxFlex="auto">{{ readLabel }}</h2>
        <h2 *ngIf="mode === CrudModeEnum.Confirm"
            class="headline m-0"
            fxFlex="auto">{{ readLabel }}</h2>
        <h2 *ngIf="mode === CrudModeEnum.Filter"
            class="headline m-0"
            fxFlex="auto">{{ filterLabel }}</h2>
        <h2 *ngIf="mode === CrudModeEnum.Custom"
            class="headline m-0"
            fxFlex="auto">{{ customLabel }}</h2>

        <button class="text-secondary ml-4" mat-dialog-close mat-icon-button type="button">
            <mat-icon [icIcon]="icClose" (click)='onClose.emit()'></mat-icon>
        </button>

    </div>
    <mat-progress-bar *ngIf='loading' class='w-full' mode='indeterminate' color='primary'></mat-progress-bar>

</div>
<mat-divider *ngIf="!noDivider && mode !== CrudModeEnum.Filter" class="-mx-6 text-border mb-6"></mat-divider>
<mat-divider *ngIf="!noDivider && mode === CrudModeEnum.Filter" class="-mx-2 text-border mb-2"></mat-divider>
