import { Component } from '@angular/core';
import { ProductModel } from 'src/app/core/models/product.model';
import { ProductService } from './product.service';
import { MatDialog } from '@angular/material/dialog';
import {
    ProductCreateUpdateDeleteComponent
} from './product-create-update-delete/product-create-update-delete.component';
import { FeiColumn } from 'src/app/core/base/interfaces/fei-column.interface';
import { DataTableBase } from 'src/app/core/base/components/data-table-base/data-table-base';
import { CrudModeEnum } from 'src/app/core/base/enum/crud-mode.enum';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { SupplierModel } from '../../../../core/models/supplier.model';
import { GenericProductModel } from '../../../../core/models/generic-product.model';
import { Router } from '@angular/router';
import { AuthService } from '../../../../core/services/auth.service';
import { TvaModel } from '../../../../core/models/tva.model';
import { TvaService } from '../../settings/tva/tva.service';
import { ENUM_PERMISSIONS } from '../../../../core/enums/permission.enum';

@Component({
    selector: 'vex-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss'],
})
export class ProductComponent extends DataTableBase<ProductModel> {

    suppliers$: Observable<SupplierModel[]>;
    genericProducts$: Observable<GenericProductModel[]>;

    feisColumns: Array<FeiColumn> = [
        {label: 'Type', column: 'quoteId', type: 'custom'},
        {label: 'Réf. Fournisseur', column: 'reference', type: 'custom', canSort: true},
        {label: 'Fournisseurs', column: 'supplier', propertyDisplay: 'supplier.label'},
        {label: 'Article générique', column: 'genericProduct', propertyDisplay: 'genericProduct.label'},
        {label: 'Libellé', column: 'label', type: 'custom'},
        {label: 'Marque/Fabricant', column: 'manufacturer'},
        {label: 'Prix H.T', column: 'priceHT', additionalDisplay: ' €', type: 'number'},
        {label: 'Taux TVA', column: 'tva', type: 'custom'},
        {label: 'Prix TTC', column: 'priceTTC', additionalDisplay: ' €', type: 'number'},
        {label: 'Disponible au catalogue', column: 'isAvailable', type: 'boolean'},
        {label: '', column: 'actions', canDelete: false, canArchive: this.authService.hasPermission(ENUM_PERMISSIONS.DELETE_PRODUCT), canUpdate: false, canCreate: false} // Pred 1283
    ];

    canShowArchive = true;
    actionText = 'Expiré';
    showComment;
    showPreviousReference;

    constructor(public service: ProductService, private dialog: MatDialog,
                private router: Router,
                private tvaService: TvaService,
                private authService: AuthService
    ) {
        super();
        this.displayedColumns = this.feisColumns.map(x => x.column);
    }

    initData() {
        this.loading = true;
        const items = this.service
            .findAll(this.pageIndex, this.pageSize, this.sortField, this.sortOrder, this.search, { ...this.filters, requiredFields: ['*'] })
            .subscribe(res => {
                this.dataSource = new MatTableDataSource<ProductModel>(res.data);
                this.count = res.totalData;
                this.loading = false;

            });

        this.subscription.add(items);

    }

    getTvaRate(tva: TvaModel) {
        return `${this.tvaService.getRateByDate(tva).rate.toFixed(2)}%`;
    }
    hasPermission() {
        return this.authService.isReferentEngagement();
    }

    rowClickItem(element: ProductModel): void {
        if (this.hasPermission()) {
            this.updateItem(element);
        } else {
            this.readItem(element);
        }
    }

    readItem(element: ProductModel): void {
        this.dialog.open(ProductCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                mode: CrudModeEnum.Read
            }
        }).afterClosed().subscribe((product: ProductModel) => {
            if (product) {
                this.initData();
            }
        });
    }

    createItem(): void {
        this.dialog.open(ProductCreateUpdateDeleteComponent).afterClosed().subscribe((product: ProductModel) => {
            if (product) {
                if (product._id) {
                    //     this.snackbarService.warning('Cette nouvelle référence sera accessible au catalogue après validation d’un administrateur.');
                    this.router.navigateByUrl('/management/product/' + product._id).then();
                } else {
                    this.initData();
                }
            }
        });
    }

    updateItem(element: ProductModel): void {
        this.dialog.open(ProductCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                mode: CrudModeEnum.Update
            }
        }).afterClosed().subscribe((product: ProductModel) => {
            if (product) {
                this.initData();
            }
        });
    }

    deleteItem(element: ProductModel) {
        this.dialog.open(ProductCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                name: `${element.technicalIdentifier}`,
                mode: CrudModeEnum.Delete
            }
        }).afterClosed().subscribe((product: ProductModel) => {
            if (product) {
                this.initData();
            }
        });
    }

    archiveItem(element: ProductModel) {
        this.dialog.open(ProductCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                name: `${element.technicalIdentifier}`,
                mode: CrudModeEnum.Archive
            }
        }).afterClosed().subscribe((product: ProductModel) => {
            if (product) {
                this.initData();
            }
        });
    }

    redirectToElement(element: ProductModel) {
        if (element.quoteId) {
            this.router.navigateByUrl('/achat/quote/' + element.quoteId).then();
            return;
        } else {
            this.router.navigateByUrl('/management/engagement/' + element.contractId).then();
        }
    }

    importItems() {
        this.dialog.open(ProductCreateUpdateDeleteComponent, {
            data: {
                mode: CrudModeEnum.Import
            }
        }).afterClosed().subscribe((product: ProductModel[]) => {
            if (product) {
                this.initData();
            }
        });
    }
}
