<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content>

    <ng-container *ngIf="data?.additionalIcon == 'warning'">
        <icon-warning class='ml-2 mr-2' [show]='true'></icon-warning>
        <span>{{ data.description }}</span>
    </ng-container>

    <ng-container *ngIf="!data?.additionalIcon">
        {{ data.description }}
    </ng-container>

    <div *ngIf="data.supplementaryDescription?.label" class="card border-l-4 border-red text-orange-700 p-4 mt-6"
         role="alert">
        <div class="flex flex-row w-full justify-between">
            <p class="font-bold">{{ data.supplementaryDescription.label }}</p>
        </div>
    </div>


</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px" class="mt-4">
    <button mat-button (click)="close()">{{ data.cancelText | uppercase }}</button>
    <button mat-button color="primary" cdkFocusInitial (click)="validate()"
            [loading]="loading">{{ data.validText | uppercase }}
    </button>
</mat-dialog-actions>

