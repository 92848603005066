<ng-container *ngIf="isCreateMode() || isUpdateMode()">
    <form (ngSubmit)="save()" [formGroup]="form">
        <base-form-header [mode]='mode' createLabel="Ajouter une activité" [updateLabel]='form.get("label").value' ></base-form-header>

        <mat-dialog-content fxLayout="column">

            <base-form-input
                label='Libellé'
                controlName='label'>
            </base-form-input>

            <base-form-input
                label="Type d'activité"
                type="autocomplete"
                controlName="activityType"
                [items$]="activityTypeItem"
                displayProperty='label'
                (autocompleteChange)='autocompleteActivityTypeChange($event)'
            ></base-form-input>

            <base-form-input
                label='Typologie des résidents'
                type='autocomplete'
                controlName='publicType'
                [items$]='publicTypeItem'
                (autocompleteChange)='autocompletePublicTypeChange($event)'
                displayProperty='label'
            >
            </base-form-input>

            <base-form-input
                label="Pourcentage"
                type='number'
                controlName='percent'>
            </base-form-input>

            <base-form-input
                label='N° Finess'
                type='number'
                controlName='finess'>
            </base-form-input>

            <base-form-input
                label='Adresse'
                type='autocomplete'
                controlName='address'
                [items$]='addressItem$'
                displayProperty='address'
            >
            </base-form-input>

            <base-form-input
                label='Nombre de lits permanents'
                controlName='nbOfPermanantBed'
                type='number'
            >
            </base-form-input>

            <base-form-input
                label='Nombre de lits temporaires'
                controlName='nbOfTemporaryBed'
                type='number'
            >
            </base-form-input>

            <base-form-input
                    label="Capacité d'accueil"
                    controlName='accomodationCapacity'
                    type='number'
            >
            </base-form-input>

        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <base-form-input
                [loading]="loading"
                type='submit'
                [mode]='mode'
                entityLabel='SUPPLIER'
                (onCreate)='createItem()'
                (onUpdate)='updateItem()'
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>

<ng-container *ngIf="isDeleteMode()">
    <form-modal-delete-base
        [label]="data.name"
        defaultMessage="Etes-vous sûr de vouloir supprimer cette activité ?"
        (onClose)="close()"
        (onDelete)="deleteItem()"
    ></form-modal-delete-base>
</ng-container>
