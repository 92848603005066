import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MatFormFieldAppearance} from '@angular/material/form-field';

@Component({
    selector: 'form-search-input',
    templateUrl: './form-input-search.component.html',
    styleUrls: ['./form-input-search.component.scss']
})
export class SearchComponent implements OnInit {
    @Input() formGroup!: FormGroup;  // FormGroup provenant du parent
    @Input() controlName!: string;   // Nom du FormControl à utiliser pour la recherche
    @Input() placeholder = 'Tapez pour rechercher...';
    @Input() label = 'search';
    @Input() appearance: MatFormFieldAppearance = 'outline';

    ngOnInit(): void {
    }
}
