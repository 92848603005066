<div class="mt-6">

</div>


<div class="mt-6" *ngIf="(service.entity$ | async) as entity">
    <pre>
    </pre>
    <!-- TODO PERMISSION [editPermission]='[ENUM_PERMISSIONS.UPDATE_CP_INTERNAL_ROLE]'-->
    <div class='card'>
        <base-data-table-header
                label='Rôles'
                [canCreate]="false"
                [canEdit]="canEditPermission"
                (onEdit)="editRoles()"
                [backgroundColorWhite]="true"
                [showSearch]="false"
        ></base-data-table-header>
        <base-data-table
                [showPaginator]="false"
                [count]='entity.roles.length'
                [dataSource]='datasource'
                [displayedColumns]='displayedColumns'
                [feisColumns]='feisColumns'
        ></base-data-table>
    </div>
</div>
