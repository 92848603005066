<ng-container *ngIf="isCreateMode() || isUpdateMode()">
    <form (ngSubmit)="save()" [formGroup]="form">
        <base-form-header [mode]='mode' createLabel='Nouvelle affectation' updateLabel="Modifier l'affectation"></base-form-header>

        <mat-dialog-content fxLayout="column">

            <base-form-input
                label='Poste'
                controlName='employeePosition'
                type='autocomplete'
                [items$]='employeePosition$'
                (autocompleteChange)="employeePositionChange($event)"
                displayProperty='label'
            >
            </base-form-input>

            <base-form-input
                label='Pourcentage'
                controlName='percentage'
                type='number'
            >
            </base-form-input>

        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <base-form-input
                type='submit'
                [mode]='mode'
                entityLabel='USER'
                [loading]='loading'
                (onCreate)='createItem()'
                (onUpdate)='updateItem()'
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>

<ng-container *ngIf="isDeleteMode()">
    <form-modal-delete-base
        [label]='data.name'
        (onClose)='close()'
        (onDelete)='deleteItem()'
        [loading]='loading'
        defaultMessage='Êtes-vous sûr de vouloir supprimer ce poste ?'
    >
    </form-modal-delete-base>
</ng-container>
