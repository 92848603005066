import {Component, OnInit} from '@angular/core';
import {FeiColumn} from '../../../../../../core/base/interfaces/fei-column.interface';
import {MatTableDataSource} from '@angular/material/table';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {NewRoleModel, RoleModel, UserProfileRoleModel} from "../../../../../../core/models/role.model";
import {DialogConfirmComponent} from "../../../../../../../@vex/components/dialog-confirm/dialog-confirm.component";
import {UserProfileService} from "../../user-profile.service";
import {
    UserProfileDetailsRolesCreateUpdateDeleteComponent
} from "./user-profile-details-roles-create-update-delete/user-profile-details-roles-create-update-delete.component";
import {CrudModeEnum} from "../../../../../../core/base/enum/crud-mode.enum";
import {RequirementModel} from "../../../../../../core/models/requirement.model";
import {ENUM_PERMISSIONS} from '../../../../../../core/enums/permission.enum';
import {AuthService} from "../../../../../../core/services/auth.service";

@Component({
    selector: 'vex-user-profile-details-roles',
    templateUrl: './user-profile-details-roles.component.html',
    styleUrls: ['./user-profile-details-roles.component.scss']
})
export class UserProfileDetailsRolesComponent implements OnInit {
    private subscription = new Subscription();
    feisColumns: Array<FeiColumn> = [
        {label: 'Libellé', column: 'label', propertyDisplay: '_id.label'},
        {label: 'Date de début', column: 'start', type: 'date'},
        {label: 'Date de fin', column: 'end', type: 'date'},
    ];
    displayedColumns = [];
    datasource = new MatTableDataSource<UserProfileRoleModel>();
    entity;
    ENUM_PERMISSIONS = ENUM_PERMISSIONS;

    canEditPermission: boolean = false;

    constructor(
        public service: UserProfileService,
        private dialog: MatDialog,
        private authService: AuthService
    ) {
        this.displayedColumns = this.feisColumns.map(x => x.column);

    }

    ngOnInit(): void {
        this.initData();
    }

    initData() {

        this.subscription.add(
            this.service.entity$
                .subscribe(entity => {
                    if (entity) {
                        this.entity = entity;

                        // x.actions = x.actions.map(u => {
                        //     u.contributor.fullName = `${u.contributor.firstName} ${u.contributor.lastName}`;
                        //     u.createdBy.fullName = `${u.createdBy.firstName} ${u.createdBy.lastName}`;
                        //     return u;
                        // });

                        const roleToDisplay = [...entity.roles, {
                            _id: {
                                label: 'Déclarant système',
                                // soit DS-PRED-1253
                            }
                        }];

                        this.datasource = new MatTableDataSource<UserProfileRoleModel>(roleToDisplay);
                    }
                })
        );

        this.authService.getCurrentUserPermissions$().subscribe(permissions => {
            this.canEditPermission = permissions.includes(ENUM_PERMISSIONS.INPUT_CP_INTERNAL_CONTACT_ROLE)
        });

    }

    editRoles() {
        const dialogRef = this.dialog.open(UserProfileDetailsRolesCreateUpdateDeleteComponent, {
            width: '750px',
            data: {
                mode: CrudModeEnum.Create,
                assignedRoles: this.entity.roles,
                _id: this.entity._id,
            }
        }).afterClosed().subscribe((entity) => {
            if (entity) {
                this.initData();

            }
        });
    }

}
