import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '../../../../@vex/layout/layout.module';
import { ApplicationLayoutComponent } from './application-layout.component';
import { SidenavModule } from '../../../../@vex/layout/sidenav/sidenav.module';
import { ToolbarModule } from '../../../../@vex/layout/toolbar/toolbar.module';
import { FooterModule } from '../../../../@vex/layout/footer/footer.module';
import { SidebarModule } from '../../../../@vex/components/sidebar/sidebar.module';
import { QuickpanelModule } from '../../../../@vex/layout/quickpanel/quickpanel.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UiModule } from '../../ui/ui.module';
import { IconModule } from '@visurel/iconify-angular';
import { FlexModule } from '@angular/flex-layout';
import { ProfilComponent } from './profil/profil.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseModule } from '../../base/base.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PageLayoutModule } from '../../../../@vex/components/page-layout/page-layout.module';
import { MatSelectModule } from '@angular/material/select';
import { NotificationComponent } from './notification/notification.component';
import {
    NotificationCreateUpdateDeleteComponent,
} from './notification/notification-create-update-delete/notification-create-update-delete.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ForbiddenComponent } from './unauthorized/forbidden.component';

import { LottieModule } from 'ngx-lottie';
import { ManagementRoutingModule } from '../../../pages/application/management/management-routing.module';
import { MatTabsModule } from '@angular/material/tabs';
import { ProfilCreateUpdateDeleteComponent } from './profil/profil-create-update-delete/profil-create-update-delete.component';
import { MatDialogModule } from '@angular/material/dialog';
import { InprogressComponent } from './inprogress/inprogress.component';
import { HasPermissionModule } from '../../directives/has-permission/has-permission.module';
import { NgChartsModule } from 'ng2-charts';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { MatMenuModule } from '@angular/material/menu';
import { ContentSplitLayoutComponent } from './content-split-layout/content-split-layout.component';

export function playerFactory(): any {
    return import('lottie-web');
}

@NgModule({
    declarations: [
        ApplicationLayoutComponent,
        DashboardComponent,
        ProfilComponent,
        NotificationComponent,
        NotificationCreateUpdateDeleteComponent,
        ForbiddenComponent,
        MaintenanceComponent,
        ProfilCreateUpdateDeleteComponent,
        InprogressComponent,
        ContentSplitLayoutComponent
    ],
    exports: [
        ContentSplitLayoutComponent,
    ],
    imports: [
        CommonModule,
        LayoutModule,
        SidenavModule,
        ToolbarModule,
        FooterModule,
        SidebarModule,
        QuickpanelModule,
        UiModule,
        IconModule,
        FlexModule,
        PageLayoutModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        ReactiveFormsModule,
        BaseModule,
        MatSnackBarModule,
        MatSelectModule,
        MatTooltipModule,
        MatSlideToggleModule,
        FormsModule,
        ManagementRoutingModule,
        LottieModule.forRoot({ player: playerFactory }),
        MatTabsModule,
        MatDialogModule,
        HasPermissionModule,
        NgChartsModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatTableModule,
        MatMenuModule,
    ],
})
export class ApplicationLayoutModule {
}
