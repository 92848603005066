import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../../core/base/base.service';
import { GenericMetrikStatusModel } from '../../../../core/models/generic-metrik-status.model';

@Injectable({
    providedIn: 'root'
})
export class GenericMetrikStatusService extends BaseService<GenericMetrikStatusModel>{
    defaultSort = 'createdAt';

    constructor(public http: HttpClient) {
        super(http, 'generic-metrik-status');
    }
}


