import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import {CrudModeEnum} from '../../../enum/crud-mode.enum';

@Component({
    selector: 'base-sidenav',
    templateUrl: './sidenav-base-filter.component.html',
})
export  class SidenavBaseFilterComponent implements OnInit, OnDestroy {
    @Input() sidenav: MatSidenav;
    @Input() standardForms: TemplateRef<any>;
    @Output() onFilter = new EventEmitter<any>();
    @Output() onClear = new EventEmitter<any>();
    @Output() onValidate = new EventEmitter<any>();
    @Output() onClose = new EventEmitter<any>();
    mode: CrudModeEnum = CrudModeEnum.Filter;
    filters: any = {};
    forms: { [key: string]: FormGroup } = {};

    ngOnInit() {
        this.initialize();
    }

    ngOnDestroy() {}

    initialize() {}
    buildCustomFilters(){ return null; }

    validate() {
        const customFilters = this.buildCustomFilters();

        this.filters = this.validateCustomFilters(customFilters);

        this.onFilter.emit(this.filters);

        this.close();
    }

    validateCustomFilters(customFilters: any): any {
        return Object.keys(customFilters).reduce((acc, key) => {
            const value = customFilters[key];

            if (this.isValidFilter(value, key)) {
                acc[key] = value;
            }

            return acc;
        }, {});
    }

    isValidFilter(value: any, key: string): boolean {
        const isDateFilterValid = (filter: any) => filter && (filter.min || filter.max);

        return value !== null &&
            value !== undefined &&
            (
                typeof value === 'boolean' ||
                (Array.isArray(value) ? value.length > 0 : true) &&
                (
                    typeof value !== 'object' ||
                    (key.includes('Date') ? isDateFilterValid(value) :
                        Object.keys(value).some(k => value[k]))
                )
            );
    }

    clear() {
        Object.keys(this.forms).forEach(formKey => {
            this.forms[formKey].reset();
        });
        this.filters = {};
        this.onFilter.emit(this.filters);
    }

    addForm(key: string, formGroup: FormGroup) {
        this.forms[key] = formGroup;
    }

    close() {
        this.sidenav.close();
    }

    getFormGroup(key: string) {
        return this.forms[key];
    }
}
