import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { UserProfileMode } from '../enums/user-profile.mode.enum';

@Injectable({
    providedIn: 'root'
})
export class ModeTenantGuard implements CanActivate {

    constructor(
        private router: Router,
        private authService: AuthService
    ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        return this.authService.currentUserValue$()
            .pipe(
                map(user => {
                    if (user && user.mode === UserProfileMode.TENANT) {
                        return true;
                    }
                    this.router.navigate(['/']).then();
                }),
                catchError((err, caught) => {
                    return of(false);
                })
            );
    }

}
