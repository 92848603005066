<ng-container *ngIf="isCreateMode() || isUpdateMode()">
    <form (ngSubmit)="save()" [formGroup]="form">
        <base-form-header [mode]='mode' [updateLabel]='form.get("label").value'
                          createLabel='Ajouter un bâtiment'></base-form-header>

        <mat-dialog-content fxLayout="column">

            <base-form-input
                    label="Bâtiment générique"
                    [items$]="genericBuildings$"
                    (autocompleteChange)='genericBuildingsAutocompleteChange($event)'
                    type='autocomplete'
                    displayProperty='label'
                    controlName='genericBuilding'
                   >
            </base-form-input>

            <base-form-input
                    controlName='label'
                    label='Libellé'>
            </base-form-input>

            <base-form-input
                    controlName='surface'
                    label='Surface Totale (m²)'
                    type='number'>
            </base-form-input>

            <base-form-input
                    [items]="buildingTypes"
                    controlName='type'
                    displayProperty="label"
                    label='Type de bâtiment'
                    type='select'>
            </base-form-input>

        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <base-form-input
                    (onCreate)='createItem()'
                    (onUpdate)='updateItem()'
                    [mode]='mode'
                    entityLabel='BUILDING'
                    type='submit'
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>

<ng-container *ngIf="isDeleteMode()">
    <form-modal-delete-base
            (onClose)='close()'
            (onDelete)='deleteItem()'
            [label]='data.name'
            [loading]='loading'
    >
    </form-modal-delete-base>
</ng-container>

<ng-container *ngIf="isImportMode()">
    <vex-form-import-base
        [modalTitle]='"Importer des bâtiments"'
        [entityLabel]='"Bâtiment"'
        [form]='importForm'
        [loading]='loading'
        [service]='service'
        [mode]='mode'
        (saveForm)='save()'
        (importItems)='importItems()'
        [canDownloadTemplateModel]='canDownloadTemplateModel'
    ></vex-form-import-base>
</ng-container>

<ng-container *ngIf='isArchiveMode()'>
    <form-modal-delete-base
            (onClose)='close()'
            (onDelete)='archiveItem()'
            defaultMessage='Êtes-vous sûr de vouloir archiver ce bâtiment ?'
            title="Archiver le bâtiment"
    >
    </form-modal-delete-base>
</ng-container>

