import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {
  FormModalBaseComponent
} from '../../../../../../core/base/components/form-modal-base/form-modal-base.component';

import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ContractModel } from '../../../../../../core/models/contract.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContractService } from '../../contract.service';
import moment from 'moment';
import { SnackbarService } from '../../../../../../core/services/snackbar.service';
import { ContractValidityEnum } from '../../../../../../core/enums/contract.enum';

@Component({
  selector: 'vex-contract-resume-create-update-delete',
  templateUrl: './contract-resume-create-update-delete.component.html',
  styleUrls: ['./contract-resume-create-update-delete.component.scss']
})
export class
ContractResumeCreateUpdateDeleteComponent extends FormModalBaseComponent<ContractModel> implements OnInit {


  form: FormGroup;
  loading = false;
  contract: ContractModel;
  contractValidityEnum = Object.values(ContractValidityEnum);

  denunciationDate: Date;
  minDenunciationDate: Date;
  maxDenunciationDate: Date;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<FormModalBaseComponent<ContractModel>>,
              public service : ContractService,
              public snackbarService: SnackbarService,
  ) {
    super(data, dialogRef, service);
    this.data = data;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initData();
    this.initForm();
  }

  async initData() {
    this.contract = this.data.contract;
    this.denunciationDate = this.data.contract.denunciationDate;
  }

  initForm() {
    this.form = new FormGroup({
      _id: new FormControl(this.contract._id),
      denunciationDate: new FormControl(
          this.denunciationDate,[]
      )
    });

    this.MinMaxDenunciationDateFilter();
  }

  MinMaxDenunciationDateFilter(){
    this.minDenunciationDate = this.contract.startValidityDate;

    // Si le contrat est dénonçiable, la date doit être antérieure à la date de préavis
    if (this.contract.validity === ContractValidityEnum.DENIABLE) {
      const validityDateMoment = moment(this.contract.startValidityDate);
      this.maxDenunciationDate = validityDateMoment.add(this.contract.minNotice, 'months').startOf('day').toDate();

      // if maxDenunciationDate is after endValidityDate, then maxDenunciationDate = endValidityDate
        if (this.maxDenunciationDate > this.contract.endValidityDate) {
            this.maxDenunciationDate = this.contract.endValidityDate;
        }
    }else {
        this.maxDenunciationDate = this.contract.endValidityDate;
    }
  }

  addItem(): void {
    this.loading = true;

    if (this.form.valid) {


    const sub = this.service.addDenunciationDate(this.contract._id, {denunciationDate: this.form.getRawValue().denunciationDate}).subscribe(
        result => {
          this.snackbarService.success('La date de dénonciation a été ajoutée');
          this.loading = false;
          this.close(result);
        }, error => {
          this.snackbarService.warning('Une erreur est survenue');
          this.loading = false;
        }
    );
    this.subscription.add(sub);
  } else {
    this.onFormInvalid();
    this.loading = false;
  }
  }
}
