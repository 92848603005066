import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Observable, pipe} from "rxjs";
import {EstablishmentModel} from "../../../../../../core/models/establishment.model";
import {BuildingModel} from "../../../../../../core/models/building.model";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormModalBaseComponent} from "../../../../../../core/base/components/form-modal-base/form-modal-base.component";
import {BuildingService} from "../../building.service";
import {EstablishmentService} from "../../../establishment/establishment.service";
import {map} from "rxjs/operators";
import {ErrorApi} from "../../../../../../core/models/api/error-api";
import {EnergyIdService} from "../../../energy-id/energy-id.service";
import {convertToBuildingRelationEnergyId, EnergyIdModel} from "../../../../../../core/models/energy-id.model";
import icClose from '@iconify/icons-ic/twotone-close';
import { CrudModeEnum } from '../../../../../../core/base/enum/crud-mode.enum';
import icAdd from "@iconify/icons-ic/twotone-add";
import {ENUM_PERMISSIONS} from "../../../../../../core/enums/permission.enum";
import {
  BuildingCreateUpdateDeleteComponent
} from "../building-create-update-delete/building-create-update-delete.component";
import {
  EnergyIdCreateUpdateDeleteComponent
} from "../../../energy-id/energy-id-create-update-delete/energy-id-create-update-delete.component";
import {defaults} from "chart.js";
import {GenericEnergyIdTypeEnergyEnum} from "../../../../../../core/enums/generic-energy-id.enum";

@Component({
  selector: 'vex-building-energies-create-update',
  templateUrl: './building-energies-create-update.component.html',
  styleUrls: ['./building-energies-create-update.component.scss']
})
export class BuildingEnergiesCreateUpdateComponent implements OnInit {

  filters = {};
  ENUM_PERMISSION = ENUM_PERMISSIONS;

  form: FormGroup;
  mode: CrudModeEnum;
  icClose = icClose;
  icAdd = icAdd;

  loading = false;
  defaults: any;
  successCreateMessage: string;
  successUpdateMessage: string;
  successDeleteMessage: string;
  errorCreateMessage: string;
  errorUpdateMessage: string;
  errorDeleteMessage: string;

  energyIds$: Observable<EnergyIdModel[]>;
  building: BuildingModel;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<FormModalBaseComponent<BuildingModel>>,
              public service: BuildingService,
              private energyIdService: EnergyIdService,
              private dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.mode = this.data.mode;
    this.defaults = this.data.defaults;

    this.filters = {
      requiredFields: ['*'],
      buildingIds: [this.defaults._id],
      customSearchFields: ['energyIdId', 'genericEnergyId.fieldLabel'],
      energy: GenericEnergyIdTypeEnergyEnum.ELECTRICITY,
    }

    this.form = new FormGroup({
      energyId: new FormControl(this.defaults?.deliveryEnergyId, Validators.required),
    });

    this.energyIdsAutoCompleteChange();
  }

  isCreateMode() {
    return this.mode === CrudModeEnum.Create;
  }

  isUpdateMode() {
    return this.mode === CrudModeEnum.Update;
  }

  save() {
    this.updateItem();
  }

  setErrorsMessages(errors: Array<ErrorApi>) {
    errors?.forEach(error => {
      let err = this.form.controls[error.property].errors;
      if (err === null) {
        err = { messages: [] };
      }
      err.messages.push(error.message);
      this.form.controls[error.property].setErrors(err);
    });
    this.form.markAllAsTouched();
  }

  updateItem() {
    if (this.form.valid) {
      this.loading = true;
      const data = this.form.getRawValue();
      this.service.updateEnergy(data.energyId._id, this.defaults._id).subscribe(
          res => {
            this.close(res.data);
            this.loading = false;
          }, error => {
            this.setErrorsMessages(error.error.errors);
            this.form.markAllAsTouched();
            this.loading = false;
          }
      );
    }
  }

  close(res?) {
    this.dialogRef.close(res);
  }

  energyIdsAutoCompleteChange(state: any = '') {
    this.energyIds$ = this.energyIdService.findAll(null, null, 'energyIdId', 'asc', state, this.filters).pipe(map(x => {
      return x.data;
    }));
  }

  async addEnergyId(){

    const buildingRelation = convertToBuildingRelationEnergyId(this.defaults);

    const element = {
      buildings: [buildingRelation],
      establishments: this.defaults.establishments,
      favoriteBuilding: buildingRelation,
    };

    this.dialog.open(EnergyIdCreateUpdateDeleteComponent, {
      width: '500px',
      data: {
        defaults: element,
        mode: CrudModeEnum.Custom
      }
    }).afterClosed().subscribe((energyId: EnergyIdModel) => {
      if (energyId) {
        this.energyIdsAutoCompleteChange();
        this.form.controls.energyId.setValue(energyId);
      }
    });
  }
}
