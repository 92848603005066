import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef } from '@angular/core';

@Component({
  selector: 'vex-app-carrousel',
  templateUrl: './app-carrousel.component.html',
  styleUrls: ['./app-carrousel.component.scss'],
})
export class AppCarrouselComponent implements OnInit, OnChanges {
  @Input() items: Array<TemplateRef<any>> = [];

  currentIndex = 0;
  transitionEnabled = true;
  isVisible = true; // Contrôle l'affichage du carrousel
  hasNotItems = true;

  private rafId: number | null = null;
  private lastTimestamp = 0;
  private slideDelay = 4000;

  constructor() {}

  ngOnInit(): void {
    const carouselClosed = localStorage.getItem('carouselClosed');
    this.isVisible = !(carouselClosed && carouselClosed === 'true');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      this.hasNotItems = this.items.length === 0;
      if (this.items.length > 0 && this.rafId === null) {
        this.startAutoSlide();
      }
    }
  }

  getTransform(): string {
    return `translateX(-${this.currentIndex * 100}%)`;
  }

  getTransition(): string {
    return this.transitionEnabled ? 'transform 0.7s ease-out' : 'none';
  }

  prevSlide(): void {
    if (!this.isAtStart()) {
      this.currentIndex--;
    }
  }

  nextSlide(): void {
    if (!this.isAtEnd()) {
      this.currentIndex++;
    }
  }

  isAtStart(): boolean {
    return this.currentIndex <= 0;
  }

  isAtEnd(): boolean {
    return this.currentIndex >= this.items.length - 1;
  }

  infiniteSlide(): void {
    this.currentIndex =
        this.currentIndex % (this.items.length - 1) !== 0 ||
        this.currentIndex === 0
            ? this.currentIndex + 1
            : 0;
  }

  startAutoSlide(): void {
    if (this.items.length <= 1) {
      return;
    }
    this.lastTimestamp = performance.now();

    const update = (timestamp: number) => {
      const delta = timestamp - this.lastTimestamp;

      if (delta >= this.slideDelay) {
        this.infiniteSlide();
        this.lastTimestamp = timestamp;
      }
      this.rafId = requestAnimationFrame(update);
    };

    this.rafId = requestAnimationFrame(update);
  }

  pauseAutoSlide(): void {
    if (this.rafId !== null) {
      cancelAnimationFrame(this.rafId);
      this.rafId = null;
    }
  }

  resumeAutoSlide(): void {
    if (this.rafId === null) {
      this.startAutoSlide();
    }
  }

  goToSlide(index: number): void {
    this.currentIndex = index;
  }

  /**
   * Ferme le carrousel et enregistre cette préférence dans le localStorage
   */
  closeCarousel(): void {
    this.isVisible = false;
    localStorage.setItem('carouselClosed', 'true');
    this.pauseAutoSlide();
  }

  /**
   * Ouvre le carrousel, supprime l'information de fermeture dans le localStorage,
   * et redémarre l'auto-slide
   */
  openCarousel(): void {
    this.isVisible = true; // Toujours réafficher le carrousel
    localStorage.setItem('carouselClosed', 'false');
    // Définir hasNotItems selon le nombre d'éléments
    this.hasNotItems = this.items.length === 0;
    // Démarrer l'auto-slide uniquement s'il y a des items
    if (!this.hasNotItems) {
      this.startAutoSlide();
    }
  }

}
