
<ng-container *ngIf="isCreateMode() || isUpdateMode()">
    <form (ngSubmit)="save()" [formGroup]="form">
        <base-form-header [mode]='mode' createLabel="Ajouter un statut à l'équipement" updateLabel="Modifier le statut de l'équipement"
        ></base-form-header>

        <vex-metrik-status
            [module]="MetrikStatusModuleEnum.EQUIPMENT"
            [form]="form"
        >
        </vex-metrik-status>

        <mat-dialog-actions align="end">
            <base-form-input
                type='submit'
                [mode]='mode'
                entityLabel='Equipement Metrik'
                (onCreate)='createItem()'
                (onUpdate)='updateItem()'
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>

<ng-container *ngIf="isDeleteMode()">
    <form-modal-delete-base
        label="Supprimer le statut de l'équipement"
        [loading]="loading"
        (onClose)="close()"
        (onDelete)="deleteItem()"
    >
    </form-modal-delete-base>
</ng-container>

<ng-container *ngIf="isCustomMode()">
    <form (ngSubmit)="save()" [formGroup]="form">
        <base-form-header [mode]="mode" customLabel="Ajouter une date de fin de service"
        ></base-form-header>

        <base-form-input
            class="sm:mr-3 flex-auto"
            label="Date de début"
            type="date"
            controlName="startDate">
        </base-form-input>

        <div style="margin-bottom: 25px" *ngIf="form.controls.comment.enabled">
            <div class="card">
                <quill-editor
                    class="h-200"
                    [modules]="customModule"
                    placeholder="Insérez le commentaire ici"
                    [formControl]="form.controls.comment">
                </quill-editor>
                <mat-error *ngIf="form.controls.comment.hasError('required') && form.controls.comment.touched"
                           style="font-size: 75%">
                    Le champ commentaire est obligatoire.
                </mat-error>
            </div>
        </div>

        <mat-dialog-actions align="end">
            <base-form-input
                type='submit'
                [mode]='mode'
                entityLabel='Equipement Metrik'
                (onCustom)='setEndServiceDate()'
                customValidateButtonLabel='ENREGISTRER'
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>