<!-- https://empirys.atlassian.net/jira/software/c/projects/PRED/boards/13?selectedIssue=PRED-1245 -->
<vex-page-layout>
    <vex-page-layout-content>
        <!-- NOTIFICATIONS -->
        <div class='flex flex-col w-full h-full justify-between p-gutter gap-4'>
            <h1 class="font-bold self-start">Notifications</h1>
            <div *ngIf="alertes" class="flex flex-col md:flex-row w-full h-full text-center gap-4">
                <!-- Alert 1: High Severity -->
                <div (click)='navigateToNotification(NotificationSeverityEnum.HIGH)' [@slideFadeInDown]
                     class="h-32 card w-full md:w-1/3 cursor-pointer flex flex-row md:flex-row justify-between items-center p-4 gap-10"
                     id="alert1">
                    <div class="rounded-full w-12 h-12 flex items-center justify-center text-red bg-red-light">
                        <mat-icon aria-hidden="true"
                                  class="mat-icon notranslate material-icons mat-icon-no-color"
                                  data-mat-icon-type="font" role="img">
                            error_outline
                        </mat-icon>
                    </div>
                    <h3 class="subheading-2 font-medium text-secondary m-0">Alertes sévérité élevée non lues</h3>
                    <h2 class="display-2 font-bold mt-4 mb-2 ng-tns-c198-336">{{ alertes?.severityHigh }}</h2>
                </div>

                <!-- Alert 2: Medium Severity -->
                <div (click)='navigateToNotification(NotificationSeverityEnum.MEDIUM)' [@slideFadeInDown]
                     class="h-32 card w-full md:w-1/3 cursor-pointer flex flex-row md:flex-row justify-between items-center p-4 gap-10"
                     id="alert2">
                    <div class="rounded-full w-12 h-12 flex items-center justify-center text-orange bg-orange-light">
                        <mat-icon aria-hidden="true"
                                  class="mat-icon notranslate material-icons mat-icon-no-color"
                                  data-mat-icon-type="font" role="img">
                            warning_amber
                        </mat-icon>
                    </div>
                    <h3 class="subheading-2 font-medium text-secondary m-0">Alertes sévérité moyenne non lues</h3>
                    <h2 class="display-2 font-bold mt-4 mb-2 ng-tns-c198-336">{{ alertes?.severityMedium }}</h2>
                </div>

                <!-- Alert 3: Low Severity -->
                <div (click)='navigateToNotification(NotificationSeverityEnum.LOW)' [@slideFadeInDown]
                     class="h-32 card w-full md:w-1/3 cursor-pointer flex flex-row md:flex-row justify-between items-center p-4 gap-10"
                     id="alert3">
                    <div class="rounded-full w-12 h-12 flex items-center justify-center text-primary bg-primary-light">
                        <mat-icon aria-hidden="true"
                                  class="mat-icon notranslate material-icons mat-icon-no-color"
                                  data-mat-icon-type="font" role="img">
                            info_outline
                        </mat-icon>
                    </div>
                    <h3 class="subheading-2 font-medium text-secondary m-0">Alertes sévérité basse non lues</h3>
                    <h2 class="display-2 font-bold mt-4 mb-2 ng-tns-c198-336">{{ alertes?.severityLow }}</h2>
                </div>
            </div>
        </div>

        <!-- Predik charts -->
        <div *ngIf="establishments?.length > 0; else noEstablishment" class="w-full h-full p-gutter">
            <!--                Establishments selection-->
            <mat-form-field *ngIf="establishments?.length > 1" appearance="outline" class="w-full"
                            style="margin-bottom: -1rem;">
                <mat-label>Établissement(s)</mat-label>
                <mat-select [formControl]="selectedEstablishment" multiple>
                    <mat-select-trigger>
                        {{ selectedEstablishment.value?.length === establishments?.length ? 'Tous les établissements' : selectedEstablishment.value?.length + ' établissement(s) sélectionné(s)' }}
                    </mat-select-trigger>
                    <mat-option *ngFor="let option of establishments" [value]="option">{{ option.label }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-progress-bar *ngIf="!dataLoaded" mode='indeterminate'>
            </mat-progress-bar>
            <div *ngIf="charts" [ngClass]="{'hidden': !dataLoaded}"
                 class="flex flex-col justify-between gap-4">
                <!-- MES DEMANDES -->
                <div class="flex flex-col w-full items-center gap-4">
                    <div class="w-full flex flex-col md:flex-row gap-4">
                        <div [@fadeInLeft]="{value: dataLoaded ? 'true' : 'false', params: { delay: 0 }}"
                             class="w-full md:w-2/3 card barChart flex flex-col">
                            <div class="w-full flex flex-row justify-between">
                                <a (click)="redirectTo('tickets')" class="cardTitle">e-Tickets360</a>
                                <button (click)="exportChartData(IdentifierEnum.MESDEMANDES_ETICKET)"
                                        *ngIf="hasChart(IdentifierEnum.MESDEMANDES_ETICKET)"
                                        color="primary"
                                        mat-button>Exporter
                                </button>
                            </div>
                            <div *ngIf="hasChart(IdentifierEnum.MESDEMANDES_ETICKET); else noChart"
                                 [id]="IdentifierEnum.MESDEMANDES_ETICKET"></div>
                        </div>
                        <div [@fadeInRight]="{value: dataLoaded ? 'true' : 'false', params: { delay: 100 }}"
                             class="w-full md:w-1/3 card donutChart flex flex-col">
                            <div class="w-full flex flex-row justify-between">
                                <a (click)="redirectTo('tickets')" class="cardTitle">e-Shop360</a>
                                <button (click)="exportChartData(IdentifierEnum.MESDEMANDES_ESHOP)"
                                        *ngIf="hasChart(IdentifierEnum.MESDEMANDES_ESHOP)"
                                        color="primary"
                                        mat-button>Exporter
                                </button>
                            </div>
                            <div *ngIf="hasChart(IdentifierEnum.MESDEMANDES_ESHOP); else noChart"
                                 [id]="IdentifierEnum.MESDEMANDES_ESHOP"></div>
                        </div>
                    </div>
                </div>
                <!-- DEVIS -->
                <div class="flex flex-col w-full items-center gap-4">
                    <div class="w-full flex flex-col md:flex-row gap-4">
                        <div [@fadeInLeft]="{value: dataLoaded ? 'true' : 'false', params: { delay: 200 }}"
                             class="w-full md:w-1/3 card donutChart flex flex-col">
                            <div class="w-full flex flex-row justify-between">
                                <a (click)="redirectTo('contracts')" class="cardTitle">Contrats</a>
                                <button (click)="exportChartData(IdentifierEnum.CONTRACT_VALIDATION)"
                                        *ngIf="hasChart(IdentifierEnum.CONTRACT_VALIDATION)"
                                        color="primary"
                                        mat-button>Exporter
                                </button>
                            </div>
                            <div *ngIf="hasChart(IdentifierEnum.CONTRACT_VALIDATION); else noChart"
                                 [id]="IdentifierEnum.CONTRACT_VALIDATION"></div>
                        </div>
                        <div [@fadeInRight]="{value: dataLoaded ? 'true' : 'false', params: { delay: 300 }}"
                             class="w-full md:w-2/3 card barChart flex flex-col">
                            <div class="w-full flex flex-row justify-between">
                                <a (click)="redirectTo('quotes')" class="cardTitle">Devis</a>
                                <button (click)="exportChartData(IdentifierEnum.QUOTE_STATUSES)"
                                        *ngIf="hasChart(IdentifierEnum.QUOTE_STATUSES)"
                                        color="primary"
                                        mat-button>Exporter
                                </button>
                            </div>
                            <div *ngIf="hasChart(IdentifierEnum.QUOTE_STATUSES); else noChart"
                                 [id]="IdentifierEnum.QUOTE_STATUSES"></div>
                        </div>
                    </div>
                </div>
                <!-- BC/BCR -->
                <div class="flex flex-col w-full items-center gap-4">
                    <div class="w-full flex flex-col md:flex-row gap-4">
                        <div [@fadeInLeft]="{value: dataLoaded ? 'true' : 'false', params: { delay: 400 }}"
                             class="w-full md:w-2/3 card barChart flex flex-col">
                            <div class="w-full flex flex-row justify-between">
                                <a (click)="redirectTo('orders')" class="cardTitle">Commandes</a>
                                <button (click)="exportChartData(IdentifierEnum.BC_STATUSES)"
                                        *ngIf="hasChart(IdentifierEnum.BC_STATUSES)"
                                        color="primary"
                                        mat-button>Exporter
                                </button>
                            </div>
                            <div *ngIf="hasChart(IdentifierEnum.BC_STATUSES); else noChart"
                                 [id]="IdentifierEnum.BC_STATUSES"></div>
                        </div>
                        <div [@fadeInRight]="{value: dataLoaded ? 'true' : 'false', params: { delay: 500 }}"
                             class="w-full md:w-1/3 card barChart flex flex-col">
                            <div class="w-full flex flex-row justify-between">
                                <a (click)="redirectTo('bcr')" class="cardTitle">BCR</a>
                                <button (click)="exportChartData(IdentifierEnum.BCR_STATUSES)"
                                        *ngIf="hasChart(IdentifierEnum.BCR_STATUSES)"
                                        color="primary"
                                        mat-button>Exporter
                                </button>
                            </div>
                            <div *ngIf="hasChart(IdentifierEnum.BCR_STATUSES); else noChart"
                                 [id]="IdentifierEnum.BCR_STATUSES"></div>
                        </div>
                    </div>
                </div>
                <!-- FACTURES -->
                <div class="flex flex-col w-full items-center gap-4">
                    <div class="w-full flex flex-col md:flex-row gap-4">
                        <div [@fadeInLeft]="{value: dataLoaded ? 'true' : 'false', params: { delay: 600 }}"
                             class="w-full card barChart flex flex-col">
                            <div class="w-full flex flex-row justify-between">
                                <a (click)="redirectTo('bills')" class="cardTitle">Factures</a>
                                <button (click)="exportChartData(IdentifierEnum.BILL_STATUSES)"
                                        *ngIf="hasChart(IdentifierEnum.BILL_STATUSES)"
                                        color="primary"
                                        mat-button>Exporter
                                </button>
                            </div>
                            <div *ngIf="hasChart(IdentifierEnum.BILL_STATUSES); else noChart"
                                 [id]="IdentifierEnum.BILL_STATUSES"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Qualifications des données -->
        <div *ngIf="dataLoaded" class="flex flex-col w-full h-full p-gutter">
            <div class="card" style='min-height: 1px; background-color: var(--background-card);'>
                <div class="flex flex-row h-16 gap-3 px-2">
                    <div (click)="showDataIncidentMain()"
                         *ngIf="dataTableMode === DataTableModeEnum.DETAILS && detailsLoaded"
                         class='h-full flex items-center cursor-pointer'>
                        <mat-icon class='custor-pointer' color='primary'>arrow_back</mat-icon>
                    </div>
                    <div class='h-full flex items-center w-full'>
                        <h1 class="ml-2" style="font-size: 1.5rem; font-weight: 700">Qualifications des données</h1>
                    </div>
                </div>
                <mat-progress-bar *ngIf="!detailsLoaded" mode='indeterminate'></mat-progress-bar>
                <table [dataSource]="dataSource" class="w-full" mat-table>
                    <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
                        <th *matHeaderCellDef mat-header-cell> {{translateColumn(column)}}</th>
                        <td (click)="clickedRow(element)" *matCellDef="let element" mat-cell
                            style="cursor: pointer"> {{dataIncidentIdentifierTranslation(element[column])}}
                            <mat-icon *ngIf='dataTableMode === DataTableModeEnum.DETAILS && detailsLoaded'
                                      class='text-sm'>open_in_new
                            </mat-icon>
                        </td>
                    </ng-container>

                    <tr *matHeaderRowDef="columnsToDisplay" mat-header-row></tr>
                    <tr *matRowDef="let row; columns: columnsToDisplay;" mat-row></tr>
                </table>
            </div>
        </div>
    </vex-page-layout-content>
</vex-page-layout>

<ng-template #noEstablishment>
    <div class="flex flex-col w-full h-full justify-center items-center">
        <mat-icon class="text-9xl text-primary">info_outline</mat-icon>
        <h1 class="text-4xl font-bold text-center">Aucun établissement affecté</h1>
        <a (click)="redirectTo('user-profile', currentUser._id)" *ngIf="authService.currentUser | async as currentUser"
           class="text-center cursor-pointer">Cliquez-ici pour vous
            affecter un
            établissement</a>
    </div>
</ng-template>

<ng-template #noChart>
    <div class="flex flex-col w-full h-full justify-center items-center mt-5">
        <mat-icon class="text-lg text-primary">info_outline</mat-icon>
        <h1 class="text-lg font-bold text-center">Aucune donnée disponible</h1>
    </div>
</ng-template>
