import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {Observable} from "rxjs";
import icPlus from "@iconify/icons-ic/twotone-plus";
import icMinus from "@iconify/icons-ic/twotone-minus";
import icClose from "@iconify/icons-ic/twotone-close";
import faQuestion from "@iconify/icons-fa-solid/question-circle";
import {ContactTypeEnum} from "../../enums/contact-type.enum";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {TicketModel} from "../../models/ticket.model";
import {AuthService} from "../../services/auth.service";
import {TicketService} from "../../../pages/application/administrative/ticket/ticket.service";
import {BasketRowStatusEnum} from "../../enums/basketRowStatusEnum";

@Component({
    selector: 'basket-with-details-component',
    templateUrl: 'basket-with-details.component.html',
    styles: [`
        .custom-subheader {
            width: 100%;
            padding: 0.5rem 0;
            font-weight: bold;
        }

        .subheader-cell {
            background-color: #C6E4F3;
            padding: 0;
        }

        .number-no-arrows::-webkit-outer-spin-button,
        .number-no-arrows::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
            text-align: center;
        }
    `]
})

export class BasketWithDetailsComponent implements OnInit {
    ContactTypeEnum = ContactTypeEnum;
    articleHeader = {position: null, name: 'Articles', category: 'subheader'};
    prestationsHeader = {position: null, name: 'Prestations', category: 'subheader'};
    favoritesHeader = {position: null, name: 'Favoris', category: 'subheader'};
    displayedColumns: string[] = ['position', 'name', 'manufacturer', 'quantity', 'stock', 'movementOfStock', 'urgent', 'status', 'removeAction'];
    data = [];
    dataSource = new MatTableDataSource([]);
    icPlus = icPlus;
    icMinus = icMinus;
    icClose = icClose;


    faQuestion = faQuestion;

    @Input() basket$: Observable<[]>;
    @Input() entity: TicketModel;
    @Input() isBasketType: boolean = false;
    @Input() canChangeStockQuantity: boolean = false;
    @Input() canUpdate: boolean = true;
    @Input() canDelete: boolean = false;
    @Input() canRestore: boolean = false;
    @Input() showExpired: boolean = false;
    @Input() displayStock: boolean = false;
    @Input() disableActions: boolean = false;
    @Output() removeItem = new EventEmitter<any>();
    @Output() restoreItem = new EventEmitter<any>();
    @Output() updateQuantityItem = new EventEmitter<any>();
    @Output() updateEmergencyItem = new EventEmitter<any>();
    @Output() disabledItem = new EventEmitter<any>();
    @Input() canUpdateQuantityPermission: boolean = true;
    @Input() titleHeader: string = null;

    constructor(private authService: AuthService, private ticketService: TicketService) {
    }

    ngOnInit(): void {
        this.initData();
    }

    initData() {
        if (this.isBasketType) {
            // remove from display columns status and urgent
            this.displayedColumns = this.displayedColumns.filter(x => x !== 'status' && x !== 'urgent');

        }

        this.basket$.subscribe((basket) => {
            this.data.length = 0;

            const products = basket.filter(x => this.isProduct(x));
            const prestations = basket.filter(x => !this.isProduct(x));


            if (products.length > 0) {
                this.titleHeader === 'favoritesHeader' ? this.data.push(this.favoritesHeader) : this.data.push(this.articleHeader);
                products.forEach((article: any, index) => {

                    article.isAllowed = this.isBasketType ? true : article.isAllowed;

                    this.data.push({
                        ...article,
                        stock: article.stock ?? 0,
                        movementOfStock: this.updateStock(article) ?? 0,
                        position: index + 1,
                        status: article.status,
                        category: 'Article',
                    });
                });
            }


            if (prestations.length > 0) {
                this.titleHeader === 'favoritesHeader' ? this.data.push(this.favoritesHeader) : this.data.push(this.prestationsHeader);
                prestations.forEach((prestation: any, index) => {

                    prestation.isAllowed = this.isBasketType ? true : prestation.isAllowed;

                    this.data.push({
                        ...prestation,
                        stock: prestation.stock ?? 0,
                        movementOfStock: this.updateStock(prestation) ?? 0,
                        position: index + 1,
                        category: 'Prestations',
                        status: prestation.status,

                    });
                });
            }
            
            this.dataSource = new MatTableDataSource(this.data);
        });

        if (!this.displayStock) {
            this.displayedColumns = this.displayedColumns.filter(x => x !== 'stock' && x !== 'movementOfStock');
        }
    }

    isNotSubheader(row: any, index: number): boolean {
        return row.category !== 'subheader';
    }

    remove(item: any) {
        if (this.canDelete && (item.status === BasketRowStatusEnum.WAITING || item.status === BasketRowStatusEnum.EXPIRED)) {
            this.disabledItem.emit(item);
        } else if (this.canDelete) {
            this.removeItem.emit(item)
        }
    }

    updateQuantity(item: any, action: 'minus' | 'plus' | 'change', disabled: boolean = false) {
        if (disabled) return;
        this.updateStock(item);
        this.updateQuantityItem.emit({item, action});
    }

    updateEmergency($event: MatSlideToggleChange, item: any) {
        item.isUrgent = $event.checked;
        this.updateEmergencyItem.emit({
            item,
            isCreator: this.ticketService.isCreator(),
            isApproAndHaveFamilly: this.ticketService.isApproAndHaveFamilly(this.entity),
            isReferentAndHaveFamilly: this.ticketService.isReferentEngagementAndHaveFamilly(this.entity)
        });
    }


    _checkNumberInput($event: Event, item: any) {
        const inputElement = event.target as HTMLInputElement;
        const inputValue = inputElement.valueAsNumber;
        this.updateStock(item);
        if (inputValue < 1) {
            this.remove(item);
        } else {
            this.updateQuantity(item, 'change');
        }
    }

    canRemove(item: any) {
        return item.status === BasketRowStatusEnum.WAITING || item.status === BasketRowStatusEnum.EXPIRED || item.status === BasketRowStatusEnum.DRAFT || item.status === BasketRowStatusEnum.WAITING_APPRO || !item.status;
    }

    isProduct(element) {
        return element?.genericProduct !== undefined;
    }

    _stockChange($event: Event, item) {
        this.updateStock(item);
        this.updateQuantity(item, 'change');
    }

    updateStock(item) {
        item.movementOfStock = item?.quantity <= item?.stock ? item?.quantity : item?.stock;
        return item.movementOfStock;
    }
}
