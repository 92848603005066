<ng-container *ngIf="isCreateMode() || isUpdateMode()">
    <form (ngSubmit)="save()" [formGroup]="form">
        <base-form-header [mode]='mode' [createLabel]="'Ajouter une actualité'" [updateLabel]='generateModalHeader("edit")'></base-form-header>

        <mat-dialog-content fxLayout="column">

            <base-form-input
                label='Type'
                type="autocomplete"
                (autocompleteChange)='genericHotTopicAutocomplete($event)'
                [items$]="genericHotTopic$"
                controlName='genericHotTopic'
                displayProperty='type'>
            >
            </base-form-input>

            <base-form-input
                *ngIf="isNotFromContract"
                label='Engagement'
                type="autocomplete"
                (autocompleteChange)='contractAutocomplete($event)'
                [items$]="contract$"
                controlName='contract'
                separator=" - "
                displayProperty='technicalIdentifier, object'>
            </base-form-input>

            <base-form-input
                label='Date de début'
                type='date'
                controlName='startDate'>
            </base-form-input>

            <base-form-input
                label='Date de fin'
                type='date'
                controlName='endDate'>
            </base-form-input>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <base-form-input
                type='submit'
                [mode]='mode'
                [loading]='loading'
                entityLabel='Actualité'
                (onCreate)='createItem()'
                (onUpdate)='updateItem()'
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>

<ng-container *ngIf="isDeleteMode()">
    <form-modal-delete-base
        (onClose)='close()'
        (onDelete)='deleteItem()'
        [label]='generateModalHeader("delete")'
    >
    </form-modal-delete-base>

</ng-container>

<ng-template *ngIf="isArchiveMode()">
    <form-modal-archive-base
        (onClose)='close()'
        (onArchive)='archiveItem()'
        label="Archiver l'actualité"
    >
        <span>Même lorsque la période de dates correspond à un affichage en temps réel, l'actualité ne sera plus présente dans le carrousel.</span>
    </form-modal-archive-base>
</ng-template>
