<div
  class="mt-6"
        @fadeInRight
        [ngClass]="getBorderClasses()"
>
    <div class="flex flex-row w-full justify-between">
        <p class="font-bold">
          <span [innerHTML]="text"></span>
        </p>

        <div class="flex flex-row gap-3" *ngIf="showButton">
            <button
                    (click)="callButton()"
                    class="w-full"
                    matSuffix
                    mat-raised-button
                    [color]="colorButton"
            >
                {{ textButton }}
            </button>
        </div>
    </div>
</div>
