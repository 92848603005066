import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormModalBaseComponent} from '../../../../../../core/base/components/form-modal-base/form-modal-base.component';
import {EstablishmentModel} from '../../../../../../core/models/establishment.model';
import {EstablishmentService} from '../../establishment.service';
import icClose from '@iconify/icons-ic/twotone-close';
import {Subscription} from 'rxjs';

@Component({
    selector: 'vex-establishment-archive-delete',
    templateUrl: './establishment-archive-delete.component.html',
    styleUrls: ['./establishment-archive-delete.component.scss']
})
export class EstablishmentArchiveDeleteComponent extends FormModalBaseComponent<EstablishmentModel> implements OnInit, OnDestroy {

    establishmentToDelete: any;
    readonly icClose = icClose;
    subscription = new Subscription();
    isVerified = false;
    dependencies: any;
    hasDependencies = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<FormModalBaseComponent<EstablishmentModel>>,
                public service: EstablishmentService,
    ) {
        super(data, dialogRef, service);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.establishmentToDelete = this.data.defaults;
    }

    checkDependencies() {
        this.loading = true;
        this.hasDependencies = false;
        this.subscription.add(
            this.service.checkEstablishmentDependencies(this.establishmentToDelete._id).subscribe(x => {
                this.dependencies = x.data?.dependencies ? x.data.dependencies : null;
                this.isVerified = true;
                this.hasDependencies = !!this.dependencies;
            })
        );
        this.loading = false;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
