<ng-container *ngIf='!loading; else loadingContent'>
    <div mat-dialog-title fxLayout='row' fxLayoutAlign='space-between center'>
        <div>{{ fileName }}</div>
        <button type='button' mat-icon-button (click)='close(false)' tabindex='-1'>
            <mat-icon [icIcon]='icClose'></mat-icon>
        </button>
    </div>
    <ng-container *ngIf="isPdf else isImg">

        <div class="card w-full h-[70vh]">
            <object [data]="url" type="application/pdf" class="w-full h-full">
                <iframe class="h-full w-full" [src]="url">
                    <p>This browser does not support PDF!</p>
                </iframe>
            </object>
        </div>

    </ng-container>

    <ng-template #isImg>
        <div class="card w-full h-[70vh] flex justify-center items-center">
            <img [src]="url" alt="Image preview" class="max-w-full max-h-full object-contain">
        </div>
    </ng-template>

</ng-container>


<ng-template #loadingContent>
    <div class='card h-64 mt-6'>
        <div class='flex justify-center items-center h-full'>
            <mat-spinner color='primary'></mat-spinner>
        </div>
    </div>
</ng-template>