import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserProfileModel} from 'src/app/core/models/user-profile.model';
import {UserProfileService} from '../user-profile.service';
import {FormModalBaseComponent} from 'src/app/core/base/components/form-modal-base/form-modal-base.component';
import {Observable} from 'rxjs';
import {RoleModel} from '../../../../../core/models/role.model';
import {RoleService} from '../../../admin/role/role.service';
import {Gender} from '../../../../../core/enums/gender.enum';
import {ContactTypeService} from '../../../settings/contact-type/contact-type.service';
import {ContactTypeModel} from '../../../../../core/models/contact-type.model';
import {BaseContactType} from '../../../../../core/enums/contact-type.enum';
import {AuthService} from '../../../../../core/services/auth.service';
import {ROLE_USER_PROFILE} from '../../../../../core/enums/role_user_profile.enum';
import {
    UserProfileValidatorLevel,
    userProfileValidatorLevelTransalation
} from '../../../../../core/enums/user-profile-validator-seuil.enum';
import {Typology} from '../../../../../core/enums/typology.enum';
import {ResponseTypeEnum} from '../../../../../core/base/enum/response-type.enum';
import {CrudModeEnum} from '../../../../../core/base/enum/crud-mode.enum';
import {EstablishmentService} from '../../establishment/establishment.service';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {SnackbarService} from '../../../../../core/services/snackbar.service';
import {EstablishmentModel} from '../../../../../core/models/establishment.model';


@Component({
    selector: 'vex-user-profile-create-update',
    templateUrl: './user-profile-create-update-delete.component.html',
    styleUrls: ['./user-profile-create-update-delete.component.scss']
})
export class UserProfileCreateUpdateDeleteComponent extends FormModalBaseComponent<UserProfileModel> implements OnInit {

    allRoles$: Observable<RoleModel[]>;
    contactType: ContactTypeModel[] = [];
    genderItem: string[];
    levels = [];
    temporayRole: Array<string>;
    userFullName: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public dialogRef: MatDialogRef<FormModalBaseComponent<UserProfileModel>>,
                private roleService: RoleService,
                public service: UserProfileService,
                private contactTypeService: ContactTypeService,
                public authService: AuthService,
                private establishmentService: EstablishmentService,
                private router: Router,
                public snackbarService: SnackbarService
    ) {
        super(data, dialogRef, service);
    }

    ngOnInit() {
        if (this.isUpdateMode()) {
            this.service.findById(this.defaults._id).subscribe(
                (userData) => {
                    this.userFullName = userData.data.firstName + ' ' + userData.data.lastName;
                },
                (error) => {
                    console.log('Une erreur s\'est produite lors de la récupération des données :', error);
                }
            );
        }

        super.ngOnInit();
        this.genderItem = Object.values(Gender);
        // this.allRoles$ = this.roleService.selectItems();
        this.levels = Object.keys(UserProfileValidatorLevel);

        this.temporayRole = Object.values(ROLE_USER_PROFILE);

        this.contactTypeService.findAll().subscribe(x => {
            this.contactType = [...BaseContactType, ...x.data].filter(x => x.typology === Typology.intern);

            if (this.isUpdateMode()) {
                const a = [];
                this.defaults.contactType.forEach(x => {
                    const ct = this.contactType.findIndex(y => y._id === x._id);
                    if (ct !== -1) {
                        a.push(this.contactType[ct]);
                    }
                });
                this.form.controls.contactType.setValue(a);
            }
        });

        this.form = new FormGroup({
            _id: new FormControl(this.defaults?._id),
            firstName: new FormControl(this.defaults?.firstName, [Validators.required]),
            lastName: new FormControl(this.defaults?.lastName, [Validators.required]),
            mobileNumber: new FormControl(this.defaults?.mobileNumber, [Validators.required]),
            mcEmail: new FormControl(this.defaults?.mcEmail, [Validators.required, Validators.email]),
            email: new FormControl(this.defaults?.email, [Validators.required, Validators.email]),
            // role: new FormControl(this.defaults?.role, [Validators.required]),
            gender: new FormControl(this.defaults?.gender),
            // idDocuware: new FormControl(this.defaults?.idDocuware),
            contactType: new FormControl(this.defaults?.contactType ?? [], []),
            roles: new FormControl(this.defaults.roles ?? []),
            // validatorMatriceLevel: new FormControl(this.defaults?.validatorMatriceLevel, [Validators.required]),
            // isPredik: new FormControl(this.defaults?.isPredik ?? false, []),
            affectAllEstablishments: new FormControl((this.defaults?.affectAllEstablishments || this.defaults?.isPredik) ?? false, []),
        });

        if (this.isCreateMode()) {
            // this.allRoles$.subscribe(role => {
            //     this.form.controls.role.setValue(role.find(x => x.name === 'admin'));
            // });
        }

        if (this.authService.currentUserValue().isPredik) {
            this.form.addControl('isPredik', new FormControl(this.defaults?.isPredik ?? false));

            // Automatically check affectAllEstablishments checkbox if isPredik is checked
            this.subscription.add(
                this.form.get('isPredik').valueChanges.subscribe((isPredik: boolean) => {
                    if (isPredik) {
                        this.form.get('affectAllEstablishments').setValue(true);
                    }
                })
            );
        }


        if (this.isUpdateMode() && !this.authService.currentUserValue().isPredik) {
            this.form.controls.mcEmail.disable();
        }
    }

    beforeCreateItem() {
        // this.defaults.contactType = this.defaults?.contactType?.map(x => x.label);
        // Used to remove the "gender" property if null before sending to back-end. Otherwise, it won't accept it
        if (this.defaults?.gender == null) {
            delete this.defaults.gender;
        }
    }

    afterCreateItem(result?: any, error?: any) {
        if (result) {
            this.close(result.data);
        }
    }

    beforeUpdateItem() {
        // this.defaults.contactType = this.defaults?.contactType?.map(x => x.label);
        delete this.defaults.roles;
    }

    afterUpdateItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
        }
    }

    afterImportItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
        }
    }

    levelTranslation(level: string) {
        return userProfileValidatorLevelTransalation(UserProfileValidatorLevel[level]);
    }


    async createItem() {
        this.loading = true;
        this.defaults = this.form.getRawValue();
        this.beforeCreateItem();
        if (this.form.valid) {
            const sub = this.service.create(this.defaults).subscribe(
                result => {
                    this.afterCreateItem(result, null);
                    this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Create, `Création du profil réalisée avec succès`);
                }, error => {
                    this.afterCreateItem(null, error);
                    this.setErrorsMessages(error.error.errors);
                    this.loading = false;
                }
            );
            this.subscription.add(sub);
        } else {
            this.onFormInvalid();
            this.logFormErrors(this.form);
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }


    async updateItem() {
        this.loading = true;
        this.defaults = this.form.getRawValue();
        this.beforeUpdateItem();

        if (this.form.valid) {
            const sub = this.service.update(this.defaults).subscribe(
                result => {
                    this.afterUpdateItem(result, null);
                    this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Update, `Modification du profil ${this.userFullName} réalisée avec succès`);
                    location.reload();
                }, error => {
                    this.afterUpdateItem(null, error);
                    this.setErrorsMessages(error.error.errors);
                    this.loading = false;
                });
            this.subscription.add(sub);
        } else {
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }
}
