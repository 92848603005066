import { Injectable } from '@angular/core';
import { BuildingModel } from 'src/app/core/models/building.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseService } from 'src/app/core/base/base.service';
import { ObjectApiInterface } from '../../../../core/base/interfaces/object-api.interface';
import { StageModel } from '../../../../core/models/stage.model';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { EstablishmentModel } from '../../../../core/models/establishment.model';
import { ActivityModel } from '../../../../core/models/activity.model';
import { BuildingActivityModel } from '../../../../core/models/building-activity.model';
import { BuildingRoomModel, FloorModel } from '../../../../core/models/building-room.model';
import {UserProfileModel} from "../../../../core/models/user-profile.model";
import {AddressModel} from "../../../../core/models/address.model";
import {ContractModel} from "../../../../core/models/contract.model";
import {EquipmentModel} from "../../../../core/models/equipment.model";

@Injectable({
    providedIn: 'root'
})
export class BuildingService extends BaseService<BuildingModel>{
    defaultSort = 'technicalIdentifier';

    protected _rooms = new BehaviorSubject<BuildingRoomModel[]>([] as BuildingRoomModel[]);
    readonly rooms$ = this._rooms.asObservable();

    protected _addresses = new BehaviorSubject<ObjectApiInterface<AddressModel[]>>({} as ObjectApiInterface<AddressModel[]>);
    readonly addresses$ = this._addresses.asObservable();

    constructor(public http: HttpClient) {
        super(http, 'building');
    }

    findById(id: string): Observable<ObjectApiInterface<BuildingModel>> {
        return this.http.get<ObjectApiInterface<BuildingModel>>(`${this.baseUrlApi}/get-activity/${id}`).pipe(map(x => {
            x.data.establishments = x.data?.establishments.filter(x => x._id).map(establishment => {
                establishment.nbOfPermanentBed = establishment.activity?.reduce((p, c) => p + c.nbOfPermanantBed, 0);
                return establishment;
            });
            this._entity.next(x.data);
            return x;
        }));
    }

    update(entity: BuildingModel | any): Observable<ObjectApiInterface<BuildingModel>> {
        return this.http.put<ObjectApiInterface<BuildingModel>>(`${this.baseUrlApi}/update/${entity['_id']}`, entity).pipe(map(x => {
            x.data.establishments = x.data?.establishments.filter(x => x._id).map(establishment => {
                establishment.nbOfPermanentBed = establishment.activity?.reduce((p, c) => p + c.nbOfPermanantBed, 0);
                return establishment;
            });
            this._entity.next(x.data);
            console.log(x.data);
            return x;
        }));
    }

    getRoomCategoryRelation(_id): Observable<ObjectApiInterface<BuildingModel[]>> {
        return this.http.get<ObjectApiInterface<BuildingModel[]>>(`${this.baseUrlApi}/getRoomCategoryRelation/${_id}`);
    }

    findBuildingById(id: string): Observable<ObjectApiInterface<BuildingModel>> {
        return this.http.get<ObjectApiInterface<BuildingModel>>(`${this.baseUrlApi}/get/${id}`);
    }

    findAllActivities(page?: number, perPage?: number, sortField: string = this.defaultSort, sortOrder: string = 'desc', search?: string, filters?: ActivityModel | any): Observable<ObjectApiInterface<ActivityModel[]>> {
        return this.http.get<ObjectApiInterface<ActivityModel[]>>(`${this.baseUrlApi}/activity/list`, {
                params: new HttpParams()
                    .set('page', page?.toString() ?? '1')
                    .set('perPage', perPage?.toString() ?? '30')
                    .set('sort', `${sortField}@${sortOrder}`)
                    .set('search', search ?? '')
                    .set('filters', JSON.stringify(filters) ?? '')
            }
        );
    }

    createStage(buildingId: string, stage: any) {
        return this.http.post<ObjectApiInterface<StageModel>>(`${this.baseUrlApi}/${buildingId}/createStage`, stage)
            .pipe(map(x => {
                this._entity.value.stages.push(x.data);
                return x;
            }));
    }

    updateStage(buildingId: string, stage: any) {
        return this.http.put<ObjectApiInterface<StageModel>>(`${this.baseUrlApi}/${buildingId}/updateStage`, stage)
            .pipe(map(x => {
                const index = this._entity.value.stages.findIndex(y => y._id === x.data._id);
                this._entity.value.stages[index] = x.data;
                return x;
            }));
    }

    deleteStage(buildingId: string, stageId: any) {
        return this.http.delete<ObjectApiInterface<StageModel>>(`${this.baseUrlApi}/${buildingId}/deleteStage/${stageId}`)
            .pipe(map(x => {
                this._entity.value.stages = this._entity.value.stages.filter(y => y._id !== x['data']._id)
                return x;
            }));
    }


    createFloor(buildingId: string, floor: any, roomId: string) {
        return this.http.post<ObjectApiInterface<FloorModel>>(`${this.baseUrlApi}/${buildingId}/createFloor/${roomId}`, floor)
            .pipe(map(x => {
                const index = this._entity.value.rooms.findIndex(y => y._id === roomId);

                //check if rooms is an array
                if (this._entity.value.rooms[index].floor instanceof Array) {
                    this._entity.value.rooms[index].floor.push(x.data);
                } else {
                    this._entity.value.rooms[index].floor = [x.data];
                }
                return x;
            }));
    }

    updateFloor(buildingId: string, floor: any, roomId: string) {
        return this.http.put<ObjectApiInterface<FloorModel>>(`${this.baseUrlApi}/${buildingId}/updateFloor/${roomId}`, floor)
            .pipe(map(x => {
                const index = this._entity.value.rooms.findIndex(y => y._id === roomId);
                const floorIndex = this._entity.value.rooms[index].floor.findIndex(y => y._id === x.data._id);
                this._entity.value.rooms[index].floor[floorIndex] = x.data;
                return x;
            }));
    }

    deleteFloor(buildingId: string, roomId: any, floorId: string) {
        return this.http.delete<ObjectApiInterface<BuildingModel>>(`${this.baseUrlApi}/${buildingId}/deleteFloor/${roomId}/${floorId}`)
            .pipe(map(x => {
                this._entity.next(x.data);
                return x;
            }));
    }

    searchRooms(search: string) {
        if (search) {
            const match = this._entity.value.rooms.filter(x => {
                const label = x.label.toLowerCase().includes(search.toLowerCase());
                const stage = x.stage?.label.toLowerCase().includes(search.toLowerCase());
                // const roomCategory = x.roomCategory?.label.toLowerCase().includes(search.toLowerCase());
                const surface = x.surface.toString().toLowerCase().includes(search.toLowerCase());
                const nbOfBed = x.nbOfBed.toString().toLowerCase().includes(search.toLowerCase())
                // return label || stage || roomCategory || surface || nbOfBed;
                return label || stage || surface || nbOfBed;
            });
            this._rooms.next(match);
        } else {
            this._rooms.next(this._entity?.value?.rooms);
        }
    }

    findAllRoom(buildingId: string, page?: number, perPage?: number, sortField: string = this.defaultSort, sortOrder: string = 'desc', search?: string, filters?: BuildingRoomModel | any): Observable<ObjectApiInterface<BuildingRoomModel[]>> {
        return this.http.get<ObjectApiInterface<BuildingRoomModel[]>>(`${this.baseUrlApi}/roomList/${buildingId}`, {
                params: new HttpParams()
                    .set('page', page?.toString() ?? '1')
                    .set('perPage', perPage?.toString() ?? '30')
                    .set('sort', `${sortField}@${sortOrder}`)
                    .set('search', search ?? '')
                    .set('filters', JSON.stringify(filters) ?? '')
            }
        );
    }

    createRoom(buildingId: string, room: any) {
        return this.http.post<ObjectApiInterface<BuildingRoomModel>>(`${this.baseUrlApi}/${buildingId}/createRoom`, room)
            .pipe(map(x => {
                this._entity.value.rooms.push(x.data);
                return x;
            }));
    }

    updateRoom(buildingId: string, room: any) {
        return this.http.put<ObjectApiInterface<BuildingRoomModel>>(`${this.baseUrlApi}/${buildingId}/updateRoom`, room)
            .pipe(map(x => {
                const index = this._entity.value.rooms.findIndex(y => y._id === x.data._id);
                this._entity.value.rooms[index] = x.data;
                return x;
            }));
    }

    deleteRoom(buildingId: string, roomId: any) {
        return this.http.delete<ObjectApiInterface<BuildingRoomModel>>(`${this.baseUrlApi}/${buildingId}/deleteRoom/${roomId}`)
            .pipe(map(x => {
                const entity = this._rooms.value;
                const findIndex = entity.findIndex(y => y._id === x.data?._id);
                if (findIndex !== -1) {
                    entity.splice(findIndex, 1);
                }
                this._rooms.next(entity);
                return x;
            }));
    }

    createActivity(buildingId: string, activity: any) {
        return this.http.post<ObjectApiInterface<BuildingActivityModel>>(`${this.baseUrlApi}/${buildingId}/createActivity`, activity)
            .pipe(map(x => {
                this._entity.value.activity.push(x.data);
                return x;
            }));
    }

    updateActivity(buildingId: string, activity: any) {
        return this.http.put<ObjectApiInterface<BuildingActivityModel>>(`${this.baseUrlApi}/${buildingId}/updateActivity`, activity)
            .pipe(map(x => {
                const index = this._entity.value.activity.findIndex(y => y._id === x.data._id);
                this._entity.value.activity[index] = x.data;
                return x;
            }));
    }

    deleteActivity(buildingId: string, activityId: any) {
        return this.http.delete<ObjectApiInterface<BuildingActivityModel>>(`${this.baseUrlApi}/${buildingId}/deleteActivity/${activityId}`)
            .pipe(map(x => {
                this._entity.value.activity = this._entity.value.activity.filter(y => y._id !== x['data']._id)
                return x;
            }));
    }

    addEstablishment(buildingId: string, establishmentId: any, surface: any) {
        return this.http.post<ObjectApiInterface<EstablishmentModel>>(`${this.baseUrlApi}/${buildingId}/addEstablishment/${establishmentId}`, {surface})
            .pipe(map(x => {
                this._entity.value.establishments.push(x.data);
                return x;
            }));
    }

    updateMainEstablishment(buildingId: string, establishmentId: any, isMain){
        return this.http.put<ObjectApiInterface<BuildingModel>>(`${this.baseUrlApi}/${buildingId}/updateMainEstablishment/${establishmentId}`, {isMain})
            .pipe(map(x => {
                x.data.establishments = x.data?.establishments.filter(x => x._id).map(establishment => {
                    establishment.nbOfPermanentBed = establishment.activity?.reduce((p, c) => p + c.nbOfPermanantBed, 0);
                    return establishment;
                });
                this._entity.next(x.data);
                return x;
            }));
    }

    updateEstablishment(buildingId: string, establishmentId: any, data: any){
        return this.http.put<ObjectApiInterface<EstablishmentModel>>(`${this.baseUrlApi}/${buildingId}/updateEstablishment/${establishmentId}`, data)
            .pipe(map(x => {
                const index = this._entity.value.establishments.findIndex(y => y._id === establishmentId);
                this._entity.value.establishments[index] = x.data;
                return x;
            }));
    }

    deleteEstablishment(buildingId: string, establishmentId: any) {
        return this.http.delete<ObjectApiInterface<EstablishmentModel>>(`${this.baseUrlApi}/${buildingId}/deleteEstablishment/${establishmentId}`)
            .pipe(map(x => {
                this._entity.value.establishments = this._entity.value.establishments.filter(y => y._id !== x['data']._id)
                return x;
            }));
    }

    downloadExcelRoomTemplate() {
        return this.http.get<ObjectApiInterface<BuildingRoomModel>>(`${this.baseUrlApi}/download-excel-building-room-template`);
    }

    importRoom(entity, buildingId): Observable<ObjectApiInterface<BuildingRoomModel>> {
        return this.http.post<ObjectApiInterface<ActivityModel>>(`${this.baseUrlApi}/import-building-room/${buildingId}`, entity).pipe(map((x: any) => {
            this._rooms.next(x.data?.rooms);
            return x;
        }));
    }

    findAllAddress(page?: number, perPage?: number, sortField: string = null, sortOrder: string = 'desc', search?: string, filters?: any) {
        return this.http.get<ObjectApiInterface<any[]>>(`${this.baseUrlApi}/address/list`, {
            params: new HttpParams()
                .set('page', page?.toString() ?? '1')
                .set('perPage', perPage?.toString() ?? '30')
                .set('sort', `${sortField}@${sortOrder}`)
                .set('search', search ?? '')
                .set('filters', JSON.stringify(filters) ?? '')
        }).pipe(map(x => {
            this._addresses.next(x);
            return x;
        }));
    }

    importAddress(entity, buildingId): Observable<ObjectApiInterface<ActivityModel>> {
        return this.http.post<ObjectApiInterface<ActivityModel>>(`${this.baseUrlApi}/import-building-address/${buildingId}`, entity).pipe(map((x: any) => {
            this.findAllAddress(null, null, null, 'desc', null, {buildingId: buildingId}).subscribe();
            return x;
        }));
    }

    downloadExcelAddressTemplate(): Observable<ObjectApiInterface<AddressModel>> {
        return this.http.get<ObjectApiInterface<AddressModel>>(`${this.baseUrlApi}/download-excel-building-address-template`);
    }

    createAddress(buildingId: string, address: AddressModel): Observable<ObjectApiInterface<AddressModel>> {
        return this.http.post<ObjectApiInterface<AddressModel>>(`${this.baseUrlApi}/${buildingId}/createAddress`, address)
            .pipe(map(x => {
                const entity = this._entity.getValue();
                entity.addresses.push(x.data);
                this._entity.next(entity);
                return x;
            }));
    }

    deleteAddress(id: string, addressId: string): Observable<ObjectApiInterface<AddressModel>> {
        return this.http.delete<ObjectApiInterface<AddressModel>>(`${this.baseUrlApi}/${id}/deleteAddress/${addressId}`).pipe(map(x => {
                const entity = this._entity.getValue();
                const findedIdx = entity.addresses.findIndex(y => y._id === addressId);
                if (findedIdx !== -1) {
                    entity.addresses.splice(findedIdx, 1);
                    this._entity.next(entity);
                }
                return x;
            }
        ));
    }

    updateAddress(id: string, data: any): Observable<ObjectApiInterface<EstablishmentModel>> {
        return this.http.put<ObjectApiInterface<EstablishmentModel>>(`${this.baseUrlApi}/${id}/updateAddress/${data._id}`, data)
            .pipe(map(x => {
                const entity = this._addresses.getValue();
                const findedIdx = entity.data.findIndex(y => y._id === data._id);
                if (findedIdx !== -1) {
                    entity.data[findedIdx] = data;
                    this._addresses.next(entity);
                }
                return x;
            }));
    }

    findPerimetersByEstablishments(entity: ContractModel, data: string[]) {
        return this.http.post<ObjectApiInterface<BuildingModel[]>>(`${this.baseUrlApi}/find-by-establishments/${entity['_id']}`, {data}).pipe(map(x => {
            return x.data;
        }));
    }

    transformBuildingData(data: BuildingModel[]){
        const transformedData = [];

        for (const building of data) {
            if(building.establishments){
                for (const establishment of building.establishments) {
                    const transformedItem = {
                        _id: building._id,
                        label: building.label,
                        genericLabel: building.genericBuilding.label,
                        genericId: building.genericBuilding._id,
                        establishmentId: establishment._id,
                        establishmentLabel: establishment.label,
                        buildingId: building._id,
                        buildingLabel: building.label,
                    }
                    transformedData.push({ ...transformedItem });
                }
            }
        }

        transformedData.sort((a, b) => a.label.localeCompare(b.label));
        return transformedData;
    }

    findBuildingsByPrestation(prestationId: string) {
        return this.http.post<ObjectApiInterface<BuildingModel[]>>(`${this.baseUrlApi}/find-by-prestation/${prestationId}`, {}).pipe(map(x => {
            return x.data;
        }));
    }


}


