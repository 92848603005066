import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
    selector: 'vex-badge-component',
    template: `
        <div class="flex items-center gap-1">
            <div [ngClass]="[textColor, backgroundColor]"
                 [ngStyle]="ngStyle"
                 class="rounded-full px-2 py-1 font-medium text-xs flex-none">
                <ng-content></ng-content>
            </div>
        </div>`
})

export class BadgeComponent implements OnInit, OnChanges {

    @Input() color: string;
    @Input() defaultColor = 'primary';

    textColor: string;
    backgroundColor: string;

    ngStyle: any = {}

    constructor() {

    }

    ngOnInit() {
        this.initColor();
    }

    initColor() {
        if (!this.color) {
            this.color = this.defaultColor;
        }

        this.textColor = 'text-' + (this.color || 'primary') + "-400";
        this.backgroundColor = 'bg-' + (this.color || 'primary') + "-100";

        if (this.color?.includes('#')) {
            this.ngStyle = {
                'background-color': this.color + '20',
                'color': this.color
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.initColor();
    }
}