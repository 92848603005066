import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { EstablishmentModel } from '../../../../../../core/models/establishment.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormModalBaseComponent } from '../../../../../../core/base/components/form-modal-base/form-modal-base.component';
import { BuildingModel } from '../../../../../../core/models/building.model';
import { BuildingService } from '../../building.service';
import { EstablishmentService } from '../../../establishment/establishment.service';
import { map } from 'rxjs/operators';
import icClose from '@iconify/icons-ic/twotone-close';
import { CrudModeEnum } from '../../../../../../core/base/enum/crud-mode.enum';
import { ActivityModel } from '../../../../../../core/models/activity.model';
import { validate } from 'codelyzer/walkerFactory/walkerFn';
import { ErrorApi } from '../../../../../../core/models/api/error-api';

@Component({
  selector: 'vex-building-activity-create-update-delete',
  templateUrl: './building-activity-create-update-delete.component.html',
  styleUrls: ['./building-activity-create-update-delete.component.scss']
})
export class BuildingActivityCreateUpdateDeleteComponent implements OnInit {
  form: FormGroup;
  mode: CrudModeEnum;

  icClose = icClose;

  loading = false;
  defaults: any;
  successCreateMessage: string;
  successUpdateMessage: string;
  successDeleteMessage: string;
  errorCreateMessage: string;
  errorUpdateMessage: string;
  errorDeleteMessage: string;

  CrudModeEnum = CrudModeEnum;

  activity: ActivityModel[]= [];
  activity$: Observable<any>;


  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<FormModalBaseComponent<BuildingModel>>,
              public buildingService: BuildingService
  ) {
  }

  ngOnInit() {
    this.activityAutocompleteChange();

    this.buildingService.entity$.subscribe((building: BuildingModel) => {
      building.establishments.forEach((establishment: EstablishmentModel) => {
        establishment.activity.forEach(activity => {
          const finded = building.activity.findIndex(item => item.activity._id === activity._id);
            if (finded === -1) {
              this.activity.push(activity);
            }
        })
      })
    })


    this.mode = this.data.mode;
    this.defaults = this.data.defaults;

    this.form = new FormGroup({
      _id: new FormControl(this.defaults?._id),
      activity: new FormControl(this.defaults?.activity, Validators.required),
      mc: new FormControl(this.defaults?.mc, Validators.required)
    });
  }

  isCreateMode() {
    return this.mode === CrudModeEnum.Create;
  }

  isUpdateMode() {
    return this.mode === CrudModeEnum.Update;
  }

  isDeleteMode() {
    return this.mode === CrudModeEnum.Delete;
  }

  save() {
    if (this.isCreateMode()) {
      this.createItem();
    } else if (this.isUpdateMode()) {

      this.updateItem();
    }
  }

  createItem() {
    if (this.form.valid) {
      this.buildingService.createActivity(this.data.building._id, this.form.getRawValue()).subscribe(
          res => {
            this.close(res.data);
          }, err => {
            this.setErrorsMessages(err.error.errors);
          }
      );
    } else {
      this.form.markAllAsTouched();
    }
  }

  updateItem() {

    if (this.form.valid) {
      this.buildingService.updateActivity(this.data.building._id, this.form.getRawValue()).subscribe(
          res => {
            this.close(res.data);
          }, err => {
            this.setErrorsMessages(err.error.errors);
          }
      );
    } else {
      this.form.markAllAsTouched();
    }
  }

  deleteItem() {
    if (this.defaults) {
      this.buildingService.deleteActivity(this.data.building._id, this.defaults._id).subscribe(
          res => {
            this.close(res);
          }, err => {
          }
      );
    }
  }

  close(res?) {
    this.dialogRef.close(res);
  }


  async activityAutocompleteChange(state?:any) {
    // get id from route params

    this.activity$ = this.buildingService.findAllActivities(null,null,'label','asc',state, {buildingId: this.data?.building?._id }).pipe(map(x => {
      return x.data.filter(item => {
        const finded = this.data?.building?.activity?.findIndex(activity => activity.activity._id === item._id);
        return finded === -1;
      })


    }));
  }

  setErrorsMessages(errors: Array<ErrorApi>) {
    errors?.forEach(error => {
      let err = this.form.controls[error.property].errors;
      if (err === null) {
        err = {messages: []};
      }
      err.messages.push(error.message);
      this.form.controls[error.property].setErrors(err);
    });
    this.form.markAllAsTouched();
  }
}
