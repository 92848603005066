import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {PermissionGuardService} from '../services/permission-guard.service';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {map} from "rxjs/operators";
import {SnackbarService} from "../services/snackbar.service";
import {logger} from "codelyzer/util/logger";


@Injectable({
    providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

    constructor(
        private permissionService: PermissionGuardService,
        private router: Router,
        private snackbarService: SnackbarService
    ) {
    }

    canActivate(next: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.permissionService.hasPermission(next).pipe(
            map(response => {

                if (!response) {
                    const hasIdParam: boolean = next.params?.id !== undefined;
                    const pathEndsdWithId: boolean = next.routeConfig?.path?.endsWith(':id');
                    const lastSegmentIsObjectId: boolean = next.url[next.url?.length - 1]?.path?.match(/^[0-9a-fA-F]{24}$/) !== null;

                    if (hasIdParam && pathEndsdWithId && lastSegmentIsObjectId) {
                        this.snackbarService.danger('Vous n\'avez pas la permission d\'accéder à cette ressource');
                    } else {
                        // if the user try to acces to '/' and he is not allowed redirect him to administrative/ticket-home page

                        if (next.routeConfig?.path === 'dashboard') {
                            this.router.navigate(['/administrative/ticket-home']).then();
                            return false;
                        }

                        this.router.navigate(['/forbidden']).then();
                    }

                    return false;
                }
                return true;
            }));
    }
}
