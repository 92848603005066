<ng-container>
    <div class='flex flex-row items-stretch w-full h-full'>
        <div class='w-full md:w-2/3 mt-6 h-full' *ngIf="canShowProductsPrestationsAvailable">
            <div class='md:hidden mb-4'>
                <catalog-actions-base
                        *ngIf="isEditionAllowed && canDisplayActions"
                        [actions]="actions"
                >
                </catalog-actions-base>
            </div>
            <mat-accordion class="md:hidden">
                <mat-expansion-panel
                        (opened)="panelOpenState = true"
                        (closed)="panelOpenState = false"
                        class="remove-padding-body"
                >
                    <mat-expansion-panel-header>
                        <mat-panel-title class="h-12">
                            <div class='flex flex-row justify-between w-full'>
                                <div [matBadge]="(basket$ | async)?.length" matBadgeOverlap="false">Panier</div>
                                <div *ngIf='showTotalBasketAmount'>
                                    <p>Total HT : {{ totalBasketAmount }} €</p>
                                </div>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <catalog-basket-base
                            [canDelete]="isEditionAllowed"
                            [canChangeQuantity]="isEditionAllowed"
                            [showTotalBasketAmount]="showTotalBasketAmount"
                            [items$]="basket$"
                            [displayTitle]="false"
                            (removeItem)="onRemoveItemFromBasketEvent.emit($event)"
                            (updateQuantityItem)="onUpdateItemQuantityFromBasketEvent.emit({item: $event.item, action: $event.action})"
                            [canDeletePermission]="canDeletePermission"
                            [canUpdateBasketPermission]="canUpdateBasketPermission"
                    >
                    </catalog-basket-base>
                </mat-expansion-panel>
            </mat-accordion>
            <div class="card md:mr-4 h-full mt-4 md:mt-0" style="border-radius: 6px;">
                <catalog-search-bar-base (fillterEvent)="onFilterChangeEvent.emit($event)"
                                         [showFilterButton]='showFilterButton'
                                         [showOnlyPerimeterCategories]='showOnlyPerimeterCategories'
                                         [showCatalogPermission]="showCatalogPermission"
                                         [canSearchPermission]="canSearchPermission"
                >
                </catalog-search-bar-base>
            </div>
            <div class='hidden md:block card mt-4 mr-4 h-full'>
                <mat-tab-group #tabGroup
                               (selectedIndexChange)="selectedIndex = $event; onTabChangeEvent.emit(selectedIndex)">
                    <mat-tab *ngFor='let tab of tabs; let i = index' [label]='tab' [disabled]="tabsVisible[i]">
                        <base-data-table
                                [count]='count'
                                [pageSize]='pageSize'
                                [sortField]='sortField'
                                [sortOrder]='sortOrder'
                                [dataSource]='dataSourceCatalog'
                                [displayedColumns]='displayedColumnsCatalog'
                                [feisColumns]='feisColumnsCatalog'
                                [customColumnTemplate]="templateRef"
                                (onPageEvent)='onPageEvent.emit($event)'
                                (onSortEvent)='onSortEvent.emit($event)'
                                [accordeonRef]="accordionRef"
                        >
                        </base-data-table>
                    </mat-tab>
                </mat-tab-group>
            </div>
            <div class="md:hidden mt-4 ">
                <mat-tab-group #tabGroup
                               (selectedIndexChange)="selectedIndex = $event; onTabChangeEvent.emit(selectedIndex)">

                    <mat-tab *ngFor='let tab of tabs; let i = index' [label]='tab' [disabled]="tabsVisible[i]">
                        <div *ngFor="let data of dataSourceCatalog.data" class="card w-full mt-4 px-4">
                            <div class="flex flex-col">
                                <h4 class="pt-4">{{ data.label }}</h4>
                                <div class="flex flex-row pb-4 justify-between items-center">
                                    <p class='italic text-gray'>{{ data?.supplier?.label }}</p>
                                    <div class="flex flex-row items-center">
                                        <p class="mr-2">En stock : </p>
                                        <a class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-primary bg-primary-light"
                                           mat-icon-button>
                                            <mat-icon *ngIf="data.stockable" [icIcon]="icDone" size="18px"></mat-icon>
                                            <mat-icon *ngIf="!data.stockable" [icIcon]="icClose" size="18px"></mat-icon>
                                        </a>
                                    </div>
                                </div>
                                <div class="flex flex-row border-t pt-4 pb-4 justify-between items-center">
                                    <catalog-quantity-column-base
                                            *ngIf="isEditionAllowed"
                                            [column]="'quantity'"
                                            [row]="data"
                                            (addItem)="onAddToBasketEvent.emit($event)"
                                            (updateQuantityItem)="onUpdateItemQuantityFromCatalogEvent.emit({item: $event.item, action: $event.action})"
                                            [canAddToBasketPermission]="canAddToBasketPermission"
                                    >
                                    </catalog-quantity-column-base>
                                        <catalog-quantity-column-base
                                                *ngIf="isEditionAllowed && isRealyValidatedShowed"
                                                [column]="'addToCart'"
                                                [row]="data"
                                                (addItem)="onAddToBasketEvent.emit($event)"
                                                (updateQuantityItem)="onUpdateItemQuantityFromCatalogEvent.emit({item: $event.item, action: $event.action})"
                                                [canAddToBasketPermission]="canAddToBasketPermission"
                                        >
                                        </catalog-quantity-column-base>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
                <mat-paginator
                        class="mt-4"
                        #paginator
                        [length]='count'
                        showFirstLastButtons
                        (page)='onPageEvent.emit($event)'
                        [pageSize]='pageSize'
                        [pageSizeOptions]='[10, 25, 50, 100]'
                        [hidePageSize]="true"
                >
                </mat-paginator>
            </div>
        </div>
        <div class='hidden md:flex w-1/3 mt-6 flex-col gap-6'>
            <catalog-actions-base
                    *ngIf="isEditionAllowed && canDisplayActions && canHelpPermission"
                    [actions]="actions"
            >
            </catalog-actions-base>
            <catalog-basket-base
                    [showPrice]="showPrice"
                    [canDelete]="isEditionAllowed"
                    [canChangeQuantity]="isEditionAllowed"
                    [showTotalBasketAmount]="showTotalBasketAmount"
                    [canChangePrice]="canChangeBasketPrice"
                    [items$]="basket$"
                    (removeItem)="onRemoveItemFromBasketEvent.emit($event)"
                    (updateQuantityItem)="onUpdateItemQuantityFromBasketEvent.emit({item: $event.item, action: $event.action})"
                    (updatePriceItem)="onUpdateItemPriceFromCatalogEvent.emit({item: $event.item})"
                    [canDeletePermission]="canDeletePermission"
                    [canUpdateBasketPermission]="canUpdateBasketPermission"
            >
            </catalog-basket-base>
        </div>
    </div>
</ng-container>

<ng-template #templateRef let-column let-row="element" let-status="status">
    <catalog-quantity-column-base
            *ngIf="isEditionAllowed && isRealyValidatedShowed"
            [column]="column"
            [row]="row"
            (addItem)="onAddToBasketEvent.emit($event)"
            (updateQuantityItem)="onUpdateItemQuantityFromCatalogEvent.emit({item: $event.item, action: $event.action})"
            [canAddToBasketPermission]="canAddToBasketPermission"
    >
    </catalog-quantity-column-base>
</ng-template>


