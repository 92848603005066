import { Injectable } from '@angular/core';
import { PrestationModel } from 'src/app/core/models/prestation.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseService } from 'src/app/core/base/base.service';
import { map } from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import { ObjectApiInterface } from '../../../../core/base/interfaces/object-api.interface';
import { ProductModel } from '../../../../core/models/product.model';
import {QuoteModel} from "../../../../core/models/quote-model";

@Injectable({
    providedIn: 'root'
})
export class PrestationService extends BaseService<PrestationModel>{
    defaultSort = 'createdAt';

    constructor(public http: HttpClient) {
        super(http, 'prestation');
    }

    protected _prestation = new BehaviorSubject<PrestationModel[]>([]);
    readonly prestation$ = this._prestation.asObservable();

    findAll(page?: number, perPage?: number, sortField: string = this.defaultSort, sortOrder: string = 'desc', search?: string, filters?: any): Observable<ObjectApiInterface<any[]>> {
        // // TODO: add priceTTC in product model with aggregation
        if (sortField === 'priceTTC') {
            sortField = 'priceHT';
        }
        return super.findAll(page, perPage, sortField, sortOrder, search, filters);
    }

    // getOrderProductsPrestations(page?: number, perPage?: number, sortField: string = this.defaultSort, sortOrder: string = 'desc', search?: string, filters?: PrestationModel | any): Observable<ObjectApiInterface<any[]>> {
    //     if (sortField === 'priceTTC') {
    //         sortField = 'priceHT';
    //     }
    //     return super.getOrderProductsPrestations(page, perPage, sortField, sortOrder, search, filters);
    // }

    findFiltered(page?: number, perPage?: number, sortField: string = this.defaultSort, sortOrder: string = 'desc', search?: string, filters?: any): Observable<ObjectApiInterface<any[]>> {
        return this.http.get<ObjectApiInterface<PrestationModel[]>>(`${this.baseUrlApi}/list`, {
                params: new HttpParams()
                    .set('page', page?.toString() ?? '1')
                    .set('perPage', perPage?.toString() ?? '30')
                    .set('sort', `${sortField}@${sortOrder}`)
                    .set('search', search ?? '')
                    .set('filters', JSON.stringify(filters) ?? '')
            }
        );
    }

    getsupplierRelation(_id): Observable<ObjectApiInterface<ProductModel[]>> {
        return this.http.get<ObjectApiInterface<ProductModel[]>>(`${this.baseUrlApi}/getsupplierRelation/${_id}`);
    }

    addGenerics(data: any) {
        return this.http.post<ObjectApiInterface<any>>(`${this.baseUrlApi}/addGenerics`, data)
            .pipe(map(x => {
                // this._entity.next(x.data);
                return x;
            }));
    }

    getPrestationsInTechnicalPerimeters(data: any) {
        return this.http.post<ObjectApiInterface<any>>(`${this.baseUrlApi}/getPrestationsInTechnicalPerimeters`, {contractIds: data})
            .pipe(map(x => {
                // this._entity.next(x.data);
                return x;
            }));
    }


    importPrestationContractItems(entity: PrestationModel, contractId: string): Observable<any> {
        return this.http.post<ObjectApiInterface<PrestationModel>>(`${this.baseUrlApi}/importPrestationContract/${contractId}`, entity)
            // .pipe(map((res: ObjectApiInterface<PrestationModel[]>) => {
            // let value = this._prestation.value;
            // value = [...value, ...res.data];
            // this._prestation.next(value);
            // return res;
        // .pipe(map(x => {
        //     const contacts = this._contacts.value;
        //     const newContacts = x.data.contacts?.ticket-filter(contact => !contacts.map((c => c._id)).includes(contact._id))
        //     this._contacts.value.push(...newContacts);
        //     return x;
        // }));
    // }));
     }


    downloadExcelTemplatePrestationContract(contractId: string): Observable<ObjectApiInterface<PrestationModel>>{
        return this.http.get<ObjectApiInterface<PrestationModel>>(`${this.baseUrlApi}/downloadPrestationContractTemplate/${contractId}`);
    }

    validateGenericAdministration(data: any[]) {
        return this.http.patch<ObjectApiInterface<any>>(`${this.baseUrlApi}/validateGenericAdministration`, data)
            .pipe(map(x => {
                // this._entity.next(x.data);
                return x;
            }));
    }
}


