import { Injectable } from '@angular/core';
import { EquipmentTypeModel } from 'src/app/core/models/equipment-type.model';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/core/base/base.service';

@Injectable({
    providedIn: 'root'
})
export class EquipmentTypeService extends BaseService<EquipmentTypeModel>{
    defaultSort = 'createdAt';

    constructor(public http: HttpClient) {
        super(http, 'equipment-type');
    }
}


