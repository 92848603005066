import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { scaleIn400ms } from '../../../../../@vex/animations/scale-in.animation';
import { fadeInRight400ms } from '../../../../../@vex/animations/fade-in-right.animation';
import { stagger40ms } from '../../../../../@vex/animations/stagger.animation';
import { fadeInUp400ms } from '../../../../../@vex/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from '../../../../../@vex/animations/scale-fade-in.animation';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { ProductModel } from '../../../models/product.model';
import { PrestationModel } from '../../../models/prestation.model';
import { FeiColumn } from '../../interfaces/fei-column.interface';
import icPlus from '@iconify/icons-ic/twotone-plus';
import icMinus from '@iconify/icons-ic/twotone-minus';
import icDelete from '@iconify/icons-ic/twotone-delete';
import { BasketModel } from '../../../models/basket.model';
import { BasketRowStatusEnum } from '../../../enums/basketRowStatusEnum';
import { TicketService } from '../../../../pages/application/administrative/ticket/ticket.service';
import icFileCopy from '@iconify/icons-ic/twotone-file-copy';
import { MatDialog } from '@angular/material/dialog';
import {
    MoreInfoModalComponent
} from '../../../../pages/application/administrative/ticket/modals/more-info-modal/more-info-modal.component';

@Component({
    selector: 'list-services-base',
    templateUrl: 'list-services-base.component.html',
    animations: [
        scaleIn400ms,
        fadeInRight400ms,
        stagger40ms,
        fadeInUp400ms,
        scaleFadeIn400ms
    ],
    styleUrls: ['list-services-base.component.scss']
})

export class ListServicesBaseComponent implements OnInit {
    // feisColumns: Array<FeiColumn>;
    // prestationEmpty: boolean = false;
    // articleEmpty: boolean = false;


    constructor(
        private router: Router,
        private authService: AuthService,
        private ticketService: TicketService,
        private dialog: MatDialog
    ) {
    }
    @Input() dataSource!: MatTableDataSource<ProductModel | PrestationModel>;



    @Input() feisColumns!: Array<FeiColumn>;

    @Input() count!: number;
    @Input() pageSize: number;
    @Input() displayedColumnsCatalog: Array<string>;
    @Input() pageIndex!: number;
    @Input() basket?: BasketModel;
    @Input() canDeleteAllRow: boolean = false;
    @Output() onUpdateItemQuantityFromCatalogEvent = new EventEmitter<any>();
    @Output() onAddToBasketEvent = new EventEmitter<any>();
    @Output() onPageEvent = new EventEmitter();
    @Output() onSortEvent = new EventEmitter();
    @Output() onTabChangeEvent = new EventEmitter();
    @Output() onDeleteEvent = new EventEmitter<any>();



    // displayedColumnsArticle: any;
    // displayedColumnsPrestation: any;


    sortField: string;
    sortOrder: string;
    showComment: string | boolean = false;
    showPreviousReference: string | boolean = false;


    protected readonly icPlus = icPlus;
    protected readonly icMinus = icMinus;
    readonly icDelete = icDelete;
    readonly icFileCopy = icFileCopy;
    protected readonly basketRowStatusEnum = BasketRowStatusEnum;

    ngOnInit() {



        this.displayedColumnsCatalog = this.feisColumns.map((feiColumn: FeiColumn) => feiColumn.column);
        // this.feisColumnsArticle.map((feiColumn: FeiColumn) => feiColumn.column)
        // this.feisColumnsPrestation.map((feiColumn: FeiColumn) => feiColumn.column)




    }

    canDelete(row: any){

        // TODO : CETTE FONCTION EMPÊCHE D'UTILISER LE COMPOSANT DANS D'AUTRES CAS QUE CELUI DES TICKET IL FAUDRA L'ADAPTER

        if (this.canDeleteAllRow) {

            return (this.basket?.prestations.some(p => p._id === row._id) || this.basket?.products.some(p => p._id === row._id));
        }

        let status: BasketRowStatusEnum = BasketRowStatusEnum.ORDERED;
        if (this.ticketService.isProduct(row)) {
            const product = this.basket?.products.find(p => p._id === row._id);
            status = product?.status;
        } else {
            const prestation = this.basket?.prestations.find(p => p._id === row._id);
            status = prestation?.status;
        }

        return !(status === BasketRowStatusEnum.WAITING || status === BasketRowStatusEnum.EXPIRED) &&
            (this.basket?.prestations.some(p => p._id === row._id) ||
                this.basket?.products.some(p => p._id === row._id));
    }

    updateCatalogueQuantity(element, direction: string) {


            this.dataSource = new MatTableDataSource(this.dataSource.data.map((entity: any) => {
                if (entity._id === element._id) {
                    if (direction === 'minus' && entity.quantity === 1) { return entity; }
                    if (direction === 'change') { return entity; }
                    if (direction === 'plus' && entity.quantity === 9999) { return entity; }
                    direction === 'plus' ? entity.quantity++ : entity.quantity--;
                }
                return entity;
            }));

    }

    deleteReference(element) {
        this.dataSource = new MatTableDataSource(this.dataSource.data.map((entity: any) => {
            if (element._id === entity._id) {
                entity.quantity = 1;
            }
            return entity;
        }));
        this.onDeleteEvent.emit(element);
    }

    openMoreInfoModal(row: any) {
        this.dialog.open(MoreInfoModalComponent, {
            width: '500px',
            data: {
                data: row
            }
        });
    }
}
