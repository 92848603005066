import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import icDone from '@iconify/icons-ic/twotone-done';
import icSearch from '@iconify/icons-ic/twotone-search';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';
import icAdd from '@iconify/icons-ic/twotone-add';
import icClose from '@iconify/icons-ic/twotone-close';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icEdit from '@iconify/icons-ic/twotone-edit';
import { Subscription } from 'rxjs';
import { DataTableDropdownIconComponent } from '../data-table-dropdown/data-table-dropdown-icon.component';
import { ENUM_PERMISSIONS } from '../../../enums/permission.enum';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { IDynamicTemplates } from '../data-table-header-layout-base/data-table-header-layout-base.component';
import { SearchInputDataHeaderStore } from '../../store/search-input-data-header-store';
import { AuthService } from '../../../services/auth.service';

@Component({
    selector: 'base-data-table-header',
    templateUrl: 'data-table-header-base.component.html'
})

export class DataTableHeaderBaseComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() headerTemplate: TemplateRef<any>;
    @Input() backgroundColorWhite = false;
    @Input() label: string;
    @Input() loading: boolean;
    @Input() canCreate = true;
    @Input() canEdit = false;
    @Input() showArchives = false;
    @Input() showExpires = false;
    @Input() showSynchronization = false;
    @Input() showCustomHeaderCheckbox = false;
    @Input() customHeaderCheckboxLabel = '';
    @Input() searchWidthFull = false;
    @Input() createOnlyAsPredik = false;

    @Input() canImport = false;
    @Input() canExport = false;
    @Output() onSearchChange = new EventEmitter();
    @Output() onClearTextFilter = new EventEmitter();
    @Output() onCreate = new EventEmitter();
    @Output() onImport = new EventEmitter();
    @Output() onExport = new EventEmitter();
    @Output() onEdit = new EventEmitter();
    @Output() onShowArchived = new EventEmitter();
    @Output() onShowExpired = new EventEmitter();
    @Output() onSynchronization = new EventEmitter();
    @Output() onShowCustomHeaderCheckbox = new EventEmitter();

    @Input() createTooltip = 'Ajouter';
    @Input() editTooltip = 'Editer';
    @Input() popupFilter = false;
    @Input() headerPopupTitle: string;
    @Input() contentPopupTemplate: TemplateRef<any>;
    @Input() countPopupFilter = 0;
    @Input() popupWidth = 400;
    @Output() clearPopupFilter = new EventEmitter();
    @Output() validatePopupFilter = new EventEmitter();

    @Input() actionsTemplate: TemplateRef<any>;

    @Input() showSearch = true;
    @Input() showCloseButton = false;

    @Input() createPermission: Array<ENUM_PERMISSIONS>;
    @Input() importPermission: Array<ENUM_PERMISSIONS>;
    @Input() exportPermission: Array<ENUM_PERMISSIONS>;
    @Input() editPermission: Array<ENUM_PERMISSIONS>;
    @Input() templates: Array<IDynamicTemplates> = [];

    private subscription = new Subscription();

    icFilterList = icFilterList;
    icAdd = icAdd;
    icClose = icClose;
    icDone = icDone;
    icMoreVert = icMoreVert;
    icDelete = icDelete;
    icEdit = icEdit;
    icSearch = icSearch;

    showArchived = false;
    showExpired = false;
    customHeaderCheckbox = false;
    filters: any;
    isPredikValue: boolean;

    @ViewChild('search') search: ElementRef;
    @ViewChild('popup') popup: DataTableDropdownIconComponent;

    searchControl: FormControl = new FormControl();
    private subscriptions = new Subscription();
    protected searchStore: SearchInputDataHeaderStore = SearchInputDataHeaderStore.getInstance();

    constructor(
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        // S'abonner au store pour synchroniser la valeur de l'input avec l'état global
        this.subscriptions.add(
            this.searchStore.search$.subscribe((value) => {
                if (this.search?.nativeElement && this.search.nativeElement.value !== value) {
                    this.search.nativeElement.value = value;
                }
            })
        );

        this.isPredikValue = this.authService.checkCurrentIsPredik();
    }

    ngAfterViewInit(): void {
        // Synchroniser les changements locaux avec le store
        this.subscribeToSearchControl();
    }

    subscribeToSearchControl(): void {
        this.subscriptions.add(
            this.searchControl.valueChanges
                .pipe(
                    debounceTime(150),
                    distinctUntilChanged(),
                    tap((value) => {
                        this.onSearchChange.emit(value);
                        this.searchStore.updateSearch(value); // Met à jour le store avec la nouvelle valeur
                    })
                )
                .subscribe()
        );
    }

    clearTextFilter(): void {
        this.resetSearch();
        this.onClearTextFilter.emit();
    }

    resetSearch(): void {
        if (this.search) {
            this.search.nativeElement.value = '';
            this.searchStore.clearSearch(); // Réinitialise l'état global du store
        }
    }

    createItem(): void {
        this.onCreate.emit();
    }

    editItem(): void {
        this.onEdit.emit();
    }

    importItems(): void {
        this.onImport.emit();
    }

    exportItems(): void {
        this.onExport.emit();
    }

    synchronization(): void {
        this.onSynchronization.emit();
    }

    clearFilter(): void {
        this.countPopupFilter = 0;
        this.clearPopupFilter.emit();
    }

    validateFilter(): void {
        this.validatePopupFilter.emit();
        this.popup.closePopover();
    }

    ngOnDestroy(): void {
        this.popupFilter = false;
        this.subscriptions.unsubscribe(); // Nettoyer tous les abonnements
    }
}
