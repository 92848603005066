<div class='card h-full'>
    <div *ngIf="displayTitle" class=' flex flex-row justify-between w-full p-4 border-b'>
        <h2 class='title m-0 mr-2'>Panier</h2>
        <h2 *ngIf='showTotalBasketAmount' class='title m-0'>Total : {{ totalBasketAmount | number: '1.2-2' }} €</h2>
    </div>
    <div class="overflow-auto h-full" style="max-height: 33.9rem">
        <div @fadeInRight *ngFor='let item of items$ | async; trackBy: trackByItemId' class='card mt-1'>
            <div class='p-4 flex flex-col justify-between w-full h-full'>
                <mat-icon *ngIf="canDelete && item.isAllowed && canDeletePermission" (click)='remove(item)'
                          class='flex self-end'
                          [icIcon]="icClose" size="18px"></mat-icon>
                <h4>{{ item.label }}</h4>
                <p *ngIf='showPrice' class='italic text-gray mt-2'>{{ item.label }}</p>
                <div class='flex flex-row justify-between mt-2'>
                    <p *ngIf='!showPrice' class='italic text-gray'> {{ item.subLabel }} </p>
                    <p *ngIf='showPrice' class='text-base'> {{ item.priceHT | number:'1.3-3' }} €</p>

                    <div class='flex flex-row justify-between w-20 p-0'
                         *ngIf="item.quantity && canChangeQuantity && item.isAllowed">

                        <a *ngIf="canUpdateBasketPermission" (click)="updateQuantity(item, 'minus')"
                           class="w-5 h-5 leading-none flex items-center justify-center hover:bg-hover hover:text-primary text-white bg-primary"
                           mat-icon-button>
                            <mat-icon [icIcon]="icMinus" size="18px"></mat-icon>
                        </a>
                        <input (change)='_checkNumberInput($event, item)'
                               class='number-no-arrows w-1/3 min-w-10 text-center' type='number' min='1'
                               oninput="this.value = (this.value > 9999 ? 9999 : Math.max(1, this.value))"
                               [(ngModel)]='item.quantity'
                               [disabled]="!canUpdateBasketPermission"
                        >
                        <a *ngIf="canUpdateBasketPermission" (click)="updateQuantity(item, 'plus')"
                           class="w-5 h-5 leading-none flex items-center justify-center hover:bg-hover hover:text-primary text-white bg-primary"
                           mat-icon-button>
                            <mat-icon [icIcon]="icPlus" size="18px"></mat-icon>
                        </a>
                    </div>
                    <div class='flex flex-row justify-center w-20 p-0' *ngIf="item.quantity && !canChangeQuantity">
                        {{ item.quantity }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
