<vex-ged-layout [loading]="loading">
    <!-- TODO PERMISSION  [deletePermission]="[ENUM_PERMISSION.DELETE_FM_BF_SUPPLIER_DOCUMENT]"-->
    <base-data-table
            [dataSource]='dataSource'
            [actionsTemplate]="customAction"
            [displayedColumns]='displayedColumns'
            [feisColumns]='feisColumns'
            [showPaginator]="false"
            (onDeleteItem)="openDelete($event)"

            [showSort]="false"
    ></base-data-table>
    <ng-template #customAction let-row="value">
        <button *hasPermission="[ENUM_PERMISSION.DOWNLOAD_FM_REF_PURCHASE_SUPPLIER_DOCUMENT]" class="mat-menu-item" (click)="downloadDocument(row.DWDOCID)">
            <mat-icon>file_download</mat-icon>
            Télécharger
        </button>
    </ng-template>
    <div dragFormZone *hasPermission="[ENUM_PERMISSION.UPLOAD_FM_REF_PURCHASE_SUPPLIER_DOCUMENT]">
        <div  fxLayout="column" *ngIf="!loading">
            <div class="w-full" [formGroup]="form">
                <mat-form-field class="w-full">
                    <mat-label>Type de document</mat-label>
                    <mat-select formControlName="documentType">
                        <mat-option *ngFor='let type of documentTypes' [value]="type">{{ type }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <vex-drop-zone [files]="files"></vex-drop-zone>
            <button mat-raised-button color="primary" class="mt-4" fxFlexAlign="end" (click)="validForm()" [loading]="loadingSave"
                    [disabled]="form.invalid || files.length === 0">ENREGISTRER
            </button>
        </div>
    </div>

</vex-ged-layout>

