<vex-ged-layout [loading]="loading">
        <base-data-table
                [dataSource]='dataSource'
                [displayedColumns]='displayedColumns'
                [feisColumns]='feisColumns'
                [showPaginator]="false"
                (onDeleteItem)="openDelete($event)"
                [showSort]="false"
                (onDownloadItem)="downloadDocument($event.DWDOCID)"
                [customColumnTemplate]='customColumnTemplateRef'
        ></base-data-table>

    <ng-template #customColumnTemplateRef let-column let-row="element" let-status="status">
        <div *ngIf="column === 'btnToPrev'">
            <button mat-icon-button (click)='preview(row)' [loading]="row.isLoading" color="primary">
                <mat-icon>remove_red_eye</mat-icon>
            </button>
        </div>
    </ng-template>

    <div dragFormZone *hasPermission="[ENUM_PERMISSION.UPDATE_BUILDING]">
        <div fxLayout="column" *ngIf="!loading">
            <div  class="w-full" [formGroup]="form">
                <mat-form-field class="w-full">
                    <mat-label>Type de document</mat-label>
                    <mat-select formControlName="documentType">
                        <mat-option *ngFor='let type of documentTypes' [value]="type">{{ type }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <vex-drop-zone [files]="files"></vex-drop-zone>
            <button mat-raised-button color="primary" class="mt-4" fxFlexAlign="end" (click)="validForm()" [loading]="loadingSave"
                    [disabled]="form.invalid || files.length === 0">ENREGISTRER
            </button>
        </div>
    </div>
</vex-ged-layout>

