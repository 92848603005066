//Angular
import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

//Services
import {EstablishmentService} from '../../establishment.service';

//Animations
import {scaleIn400ms} from '../../../../../../../@vex/animations/scale-in.animation';
import {fadeInRight400ms} from '../../../../../../../@vex/animations/fade-in-right.animation';
import {stagger40ms} from '../../../../../../../@vex/animations/stagger.animation';
import {fadeInUp400ms} from '../../../../../../../@vex/animations/fade-in-up.animation';
import {scaleFadeIn400ms} from '../../../../../../../@vex/animations/scale-fade-in.animation';
import {MatTableDataSource} from "@angular/material/table";
import {FeiColumn} from "../../../../../../core/base/interfaces/fei-column.interface";
import {EstablishmentModel} from "../../../../../../core/models/establishment.model";
import {Sort} from "@angular/material/sort";
import {PageEvent} from "@angular/material/paginator";
import {CrudModeEnum} from "../../../../../../core/base/enum/crud-mode.enum";
import {
    EstablishmentAnalyticsCreateUpdateDeleteComponent
} from "../../modals/establishment-analytics-create-update-delete/establishment-analytics-create-update-delete.component";
import {Subscription} from "rxjs";
import {ENUM_PERMISSIONS} from '../../../../../../core/enums/permission.enum';
import {DataTableBase} from "../../../../../../core/base/components/data-table-base/data-table-base";
import {AuthService} from "../../../../../../core/services/auth.service";


@Component({
    selector: 'vex-establishment-details-analytics',
    templateUrl: './establishment-details-analytics.component.html',
    styleUrls: ['./establishment-details-analytics.component.scss'],
    animations: [
        scaleIn400ms,
        fadeInRight400ms,
        stagger40ms,
        fadeInUp400ms,
        scaleFadeIn400ms
    ]
})
export class EstablishmentDetailsAnalyticsComponent extends DataTableBase<EstablishmentModel> implements OnInit, OnDestroy {

    ENUM_PERMISSION = ENUM_PERMISSIONS;
    loadingData = false;
    // dataSource = new MatTableDataSource<EstablishmentAnalyticsLine>();
    sortField: string;
    sortOrder: string;
    pageIndex: number;
    pageSize: number;
    count = 0;
    search: string;
    filters: any = {
        isArchive: false
    };
    isArchive: boolean = false;
    displayedColumns = ['actions'];
    canShowArchivePermissions: boolean = false;
    feisColumns: Array<FeiColumn> = [
        {label: 'Code analytique', column: 'accountNumber', propertyDisplay: null, canUpdate: false, canDelete: false},
        {label: 'Libellé', column: 'label'},
        {
            label: '',
            column: 'actions',
            canDelete: false,
            canUpdate: this.authService.hasPermission(ENUM_PERMISSIONS.UPDATE_CP_ESTABLISHMENT_ANALYTIC_PLAN),
            canArchive: this.authService.hasPermission(ENUM_PERMISSIONS.INPUT_CP_ESTABLISHMENT_ANALYTIC_PLAN_ARCHIVE)
        },
    ];
    private subscriptions = new Subscription();

    constructor(
        public service: EstablishmentService,
        private dialog: MatDialog,
        private authService: AuthService,
    ) {
        super();
        this.displayedColumns = this.feisColumns.map(x => x.column);
    }

    ngOnInit(): void {
        this.initData();
    }

    initData(): void {
        if (this.isArchive) {
            this.feisColumns = this.feisColumns.map(x => {
                if (x.column === 'actions') {
                    x.canArchive = false;
                }
                return x;
            });
        } else {
            this.feisColumns = this.feisColumns.map(x => {
                if (x.column === 'actions') {
                    x.canArchive = true;
                }
                return x;
            });
        }


        let isArchive = this.filters.isArchive;

        const sub = this.service.entity$.subscribe((entity) => {
            this.loadingData = true;
            this.filters = {
                establishmentId: entity._id,
                isArchive
            }

            this.service.findAllAnalyticsLines(this.pageIndex, this.pageSize, this.sortField, this.sortOrder, this.search, this.filters).subscribe(res => {
                this.dataSource = new MatTableDataSource<any>(res.data);
                this.count = res.totalData;
                this.loadingData = false;
            })
        })

        this.subscriptions.add(sub);

        this.authService.getCurrentUserPermissions$().subscribe(permissions => {
            this.canShowArchivePermissions = permissions.includes(ENUM_PERMISSIONS.INPUT_CP_ESTABLISHMENT_ANALYTIC_PLAN)
        })


    }


    pageEvent(event: PageEvent): void {
        this.pageIndex = event.pageIndex + 1;
        this.pageSize = event.pageSize;
        this.initData();
    }

    sortEvent(event: Sort): void {
        this.sortField = event.active;
        this.sortOrder = event.direction;
        this.initData();
    }

    async createAnalyticLine() {
        // get current establishment id
        const entity = await this.service.entity;
        this.dialog.open(EstablishmentAnalyticsCreateUpdateDeleteComponent, {
            data: {
                mode: CrudModeEnum.Create,
            }
        }).afterClosed().subscribe((res) => {
            if (res) {
                this.initData();
            }
        });
        // })
    }

    archiveItem(element: EstablishmentModel) {
        this.dialog.open(EstablishmentAnalyticsCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                name: `${element._id}`,
                mode: CrudModeEnum.Archive
            }
        }).afterClosed().subscribe((establishment: EstablishmentModel) => {
            if (establishment) {
                this.initData();
            }
        });
    }

    updateItem(element: EstablishmentModel) {
        this.dialog.open(EstablishmentAnalyticsCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                mode: CrudModeEnum.Update
            }
        }).afterClosed().subscribe((establishment: EstablishmentModel) => {
            if (establishment) {
                this.initData();
            }
        });
    }

    onShowArchived(event) {
        this.isArchive = event;
        this.filters = {...this.filters, isArchive: event};
        this.initData();
    }

    async importItems() {
        this.dialog.open(EstablishmentAnalyticsCreateUpdateDeleteComponent, {
            data: {
                mode: CrudModeEnum.Import,

            }
        }).afterClosed().subscribe((res) => {
            if (res) {
                this.initData();
            }
        });

    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }


}
