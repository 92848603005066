    import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


export interface ICatalogAction {
    label: string;
    click: () => void;
    displayBtn?: boolean;
    key?: string;
    disabled?: boolean;
}

@Component({
    selector: 'catalog-actions-component',
    templateUrl: 'catalog-actions.component.html'
})

export class CatalogActionsComponent implements OnInit {
    @Input() actions: Array<ICatalogAction>;
    constructor() {
    }

    ngOnInit() {
    }
}
