import {ContractStatusEnum} from '../../enums/contract-status.enum';

export type bubbleColor = 'red' | 'yellow' | 'blue' | '';


export enum BUBBLE_COLOR_ENUM {
    RED = 'red',
    YELLOW = 'yellow',
    BLUE = 'blue',
    EMPTY = ''
}

export interface FeiColumn {
    statusClassFunc?: (element: any) => bubbleColor;
    previousDisplay?: string;
    label: string;
    column: 'actions' | string;
    width?: 'auto' | string;
    propertyDisplay?: string | ContractStatusEnum;
    overrideDisplay?: string;
    titleCase?: boolean;
    upperCase?: boolean;
    canUpdate?: boolean;
    canDelete?: boolean;
    canUpdateDynamically?: (element: any) => boolean;
    canDeleteDynamically?: (element: any) => boolean;
    showAction?: boolean;
    canDuplicate?: boolean;
    canFavorite?: boolean;
    canArchive?: boolean;
    canChangeStatus?: boolean;
    canDownload?: boolean;
    type?: 'text' |
        'boolean' |
        'booleanQuote' |
        'date' |
        'number' |
        'category' |
        'technicalIdentifier' |
        'catalogue' |
        'dateTime' |
        'notificationStatus' |
        'custom' |
        'attentionRequiered' |
        'interventionRequired' |
        'favorite' |
        'isSpecialConditions';
    customColHeader?: boolean;
    additionalDisplay?: string;
    checkboxTooltip?: string;
    value?: any;
    status?: 'enabled' | 'disabled';
    customDisplay?: any;
    click?: (element: any ) => void;
    customExtraHtml?: string;
    isPredik?: boolean;
    canSort?: boolean;
    maxDecimal?: number;
    canCreate?: boolean;
    statusPipe?: string;
}
