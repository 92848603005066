import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { stripHtmlTags } from '../../../utils/html-utils';
import { quillConfigEmpty } from '../../../config/quill.config';

export interface HistoriqueEvent {
    date: string;
    titre: string;
    description: string;
    active?: boolean;
}

@Component({
    selector: 'vex-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss',
        '../../../../../../node_modules/quill/dist/quill.snow.css',
        '../../../../../@vex/styles/partials/plugins/_quill.scss'
    ],
    encapsulation: ViewEncapsulation.None,
})
export class HistoryComponent implements OnInit {

    @Input() events: HistoriqueEvent[] = [];
    readonly stripHtmlTags = stripHtmlTags;

    quillConfig = quillConfigEmpty;

    constructor(
    ) {
    }

    ngOnInit(): void {
    }

}

