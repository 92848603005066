<!--<ng-container *ngIf="isCreateMode() || isUpdateMode()">-->
<!--    <form (ngSubmit)="save()" [formGroup]="form">-->
<!--        <base-form-header [mode]='mode' createLabel='New Notification' [updateLabel]='form.get("type").value'></base-form-header>-->

<!--        <mat-dialog-content fxLayout="column">-->
<!--            -->
<!--             <base-form-input-->
<!--                label='type'-->
<!--                controlName='type'>-->
<!--            </base-form-input>-->
<!--            -->
<!--             <base-form-input-->
<!--                label='message'-->
<!--                controlName='message'>-->
<!--            </base-form-input>-->
<!--            -->
<!--             <base-form-input-->
<!--                label='severity'-->
<!--                controlName='severity'>-->
<!--            </base-form-input>-->
<!--            -->
<!--             <base-form-input-->
<!--                label='sendDate'-->
<!--                controlName='sendDate'>-->
<!--            </base-form-input>-->
<!--            -->
<!--             <base-form-input-->
<!--                label='sender'-->
<!--                controlName='sender'>-->
<!--            </base-form-input>-->
<!--            -->
<!--             <base-form-input-->
<!--                label='recever'-->
<!--                controlName='recever'>-->
<!--            </base-form-input>-->
<!--            -->
<!--             <base-form-input-->
<!--                label='status'-->
<!--                controlName='status'>-->
<!--            </base-form-input>-->
<!--            -->
<!--        </mat-dialog-content>-->

<!--        <mat-dialog-actions align="end">-->
<!--            <base-form-input-->
<!--                type='submit'-->
<!--                [mode]='mode'-->
<!--                entityLabel='NOTIFICATION'-->
<!--                (onCreate)='createItem()'-->
<!--                (onUpdate)='updateItem()'-->
<!--            >-->
<!--            </base-form-input>-->
<!--        </mat-dialog-actions>-->
<!--    </form>-->
<!--</ng-container>-->

<ng-container *ngIf="isDeleteMode()">
    <form-modal-delete-base
        (onClose)='close()'
        (onDelete)='deleteItem()'
        [loading]='loading'
        label='la notification'
    >
    </form-modal-delete-base>
</ng-container>
