import { Component, ViewChild } from '@angular/core';
import { DataTableBase } from '../../../../core/base/components/data-table-base/data-table-base';
import { ContractModel } from '../../../../core/models/contract.model';
import { ContractService } from './contract.service';
import { BUBBLE_COLOR_ENUM, bubbleColor, FeiColumn } from '../../../../core/base/interfaces/fei-column.interface';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmComponent } from '../../../../../@vex/components/dialog-confirm/dialog-confirm.component';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EstablishmentService } from '../establishment/establishment.service';
import { EstablishmentModel } from '../../../../core/models/establishment.model';
import { BuildingService } from '../building/building.service';
import { BuildingModel } from '../../../../core/models/building.model';
import { GenericEquipmentService } from '../../settings/generic-equipment/generic-equipment.service';
import { GenericEquipmentModel } from '../../../../core/models/generic-equipment.model';
import { ContractStatusEnum, ContractStatusTranslation } from '../../../../core/enums/contract-status.enum';
import { MatTableDataSource } from '@angular/material/table';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from '../../../../core/services/auth.service';
import { NotificationService } from '../../../../core/layout/application-layout/notification/notification.service';
import { ENUM_PERMISSIONS } from '../../../../core/enums/permission.enum';

@Component({
    selector: 'vex-contract',
    templateUrl: './contract.component.html',
    styleUrls: ['./contract.component.scss']
})
export class ContractComponent extends DataTableBase<ContractModel> {
    entityName = 'Engagements';
    ENUM_PERMISSION = ENUM_PERMISSIONS;
    establishments$: Observable<EstablishmentModel[]>;
    buildings$: Observable<BuildingModel[]>;
    genericEquipments$: Observable<GenericEquipmentModel[]>;
    contractVersion = [1];
    pageSize = 50;
    gedNumberContract = ['N/A'];

    feisColumns: Array<FeiColumn> = [
        // {label: 'ID Technique', column: 'technicalIdentifier', propertyDisplay: null},
        // {label: 'ID GED contrat', column: 'referenceInterne', propertyDisplay: null},
        // {
        //     label: 'Catégorie',
        //     column: 'genericEngagement.engagementCategory',
        //     propertyDisplay: 'genericEngagement.engagementCategory.label'
        // },
        {label: 'Type d\'engagement', column: 'genericEngagement', propertyDisplay: 'genericEngagement.format'},
        {label: 'Objet', column: 'object', propertyDisplay: 'object'},
        {label: 'Fournisseur', column: 'supplier', propertyDisplay: 'supplier.label'},
        // {
        //     label: 'Réf contrat frs',
        //     column: 'supplierContractReference.',
        //     propertyDisplay: 'supplierContractReference'
        // },
        {label: 'Date de début', column: 'startValidityDate', propertyDisplay: 'startValidityDate', type: 'date'},
        {
            label: 'Date révision prix',
            column: 'nextRevisionDate',
            propertyDisplay: 'priceRevision.nextRevisionDate',
            type: 'date',
            statusClassFunc: this.displayRenewalDateStatus.bind(this)
        },
        {
            label: 'Date de préavis',
            column: 'noticeDate',
            type: 'date',
            statusClassFunc: this.displayNoticeDateStatus.bind(this)
        },
        {
            label: 'Date de fin',
            column: 'endValidityDate',
            propertyDisplay: 'endValidityDate',
            type: 'date',
            statusClassFunc: this.displayEndValidityDateStatus.bind(this)
        },
        // {label: 'Conditions particulières', column: 'specialConditions', type: 'isSpecialConditions'},
        {
            label: 'Statut',
            column: 'status',
            propertyDisplay: 'displayStatus',
            statusClassFunc: this.displayStatusField
        },
        {label: 'Référent engagement', column: 'engagementContact', propertyDisplay: 'engagementContact.fullName'},

    ];

    legendData = [
        { description: 'Échéances proches', color: '#FFC0CB' }, // Rose clair
    ];

    constructor(
        public service: ContractService,
        private dialog: MatDialog,
        private router: Router,
        public authService: AuthService,
        public establishmentService: EstablishmentService,
        public buildingService: BuildingService,
        public genericEquipmentService: GenericEquipmentService,
        public notificationService: NotificationService,
    ) {
        super();
    }

    initData() {

        // We set here items to translate the statut of colum 'statut' from english to french;
        this.loading = true;
        const items = this.service
            .findAll(this.pageIndex, this.pageSize, this.sortField, this.sortOrder, this.search, {...this.filters, requiredFields: ['*']})
            .subscribe(res => {

                res.data = this.transformData(res.data);

                if (!this.dataSource) {
                this.dataSource = new MatTableDataSource<ContractModel>(res.data);
            } else {
                this.dataSource.data = res.data;
            }
                this.count = res.totalData;
                this.loading = false;
        });

        this.subscription.add(items);
        this.establishmentsAutocompleteChange(null);
        this.buildingsAutocompleteChange(null);
        this.equipmentsAutocompleteChange(null);
        this.popupForm = new FormGroup({
            establishment: new FormControl(null),
            building: new FormControl(null),
            genericEquipment: new FormControl(null),
            contractVersion: new FormControl(null),
            gedNumberContract: new FormControl(null),
        });
    }

    protected override transformData(data: any[]): any[] {
        return data.map((contract: ContractModel) => {
                contract.displayStatus = ContractStatusTranslation.translate(contract.status);
                return contract;
        });
    }

    displayStatusField(element: ContractModel): bubbleColor  {
        switch (element.status) {
            case ContractStatusEnum.DRAFT:
                return 'yellow';
            default:
                return '';
        }
    }

    displayRenewalDateStatus(element: ContractModel): bubbleColor {
        return this.compareDateWithCurrent(element.priceRevision.nextRevisionDate, 3) ?
            BUBBLE_COLOR_ENUM.RED :
            BUBBLE_COLOR_ENUM.EMPTY;
    }

    displayNoticeDateStatus(element: ContractModel): bubbleColor {
        return this.compareDateWithCurrent(element.noticeDate, 3) ? BUBBLE_COLOR_ENUM.RED : BUBBLE_COLOR_ENUM.EMPTY;
    }

    displayEndValidityDateStatus(element: ContractModel): bubbleColor {
        return this.compareDateWithCurrent(element.endValidityDate, 3) ? BUBBLE_COLOR_ENUM.RED : BUBBLE_COLOR_ENUM.EMPTY;
    }

    compareDateWithCurrent(inputDate: Date | string, months: number, days: number = 0): boolean {

        if (!inputDate) {
            return false;
        }

        const date = (typeof inputDate === 'string') ? new Date(inputDate) : inputDate;
        const currentDate = new Date();

        if (isNaN(date.getTime()) || date.getTime() < currentDate.getTime()) {
            return false;
        }

        const yearDifference = currentDate.getFullYear() - date.getFullYear();
        const monthDifference = currentDate.getMonth() - date.getMonth();
        const totalMonthDifference = yearDifference * 12 + monthDifference;

        const dayDifference = currentDate.getDate() - date.getDate();

        if (Math.abs(totalMonthDifference) > months) {
            return false;
        }

        return !(Math.abs(totalMonthDifference) === months && dayDifference > days);

    }

    createItem(): void {
        this.dialog.open(DialogConfirmComponent, {
            data: {
                title: 'Création d\'un engagement',
                description: 'Êtes-vous sûr de vouloir créer cet engagement ?',
                cancelText: 'Annuler',
                validText: 'Créer',
                call$: this.service.create(null)
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.router.navigate(['/management/engagement/', result.data._id]);
            }
        });
    }

    establishmentsAutocompleteChange(search: any = '') {
        this.establishments$ = this.establishmentService.findAll(null, null, 'label', 'asc', search, null).pipe(map(x => x.data));
    }

    buildingsAutocompleteChange(search: any = '') {
        this.buildings$ = this.buildingService.findAll(null, null, 'label', 'asc', search, null).pipe(map(x => x.data));
    }

    equipmentsAutocompleteChange(search: any = '') {
        this.genericEquipments$ = this.genericEquipmentService.findAll(null, null, 'label', 'asc', search, null).pipe(map(x => x.data));
    }

    beforeValidatePopupFilter() {
        if (this.popupForm.get('establishment').value) {
            this.filters.establishmentId = this.popupForm.get('establishment').value._id;
        }
        if (this.popupForm.get('building').value) {
            this.filters.buildingId = this.popupForm.get('building').value._id;
        }
        if (this.popupForm.get('genericEquipment').value) {
            this.filters.equipmentId = this.popupForm.get('genericEquipment').value._id;
        }
    }

}
