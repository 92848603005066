import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import icPlus from '@iconify/icons-ic/twotone-plus';
import icMinus from '@iconify/icons-ic/twotone-minus';

@Component({
    selector: 'catalog-quantity-column-base',
    templateUrl: './catalog-quantity-column-base.component.html',
    styles: [`
        .number-no-arrows::-webkit-outer-spin-button,
        .number-no-arrows::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
            text-align: center;
        }
    `]
})
export class CatalogQuantityColumnBaseComponent implements OnInit {

    @Input() column: string;
    @Input() row: any;

    @Input() readonly = false;
    @Input() canAddToBasketPermission = true;

    @Output() addItem: EventEmitter<any> = new EventEmitter<any>();
    @Output() updateQuantityItem = new EventEmitter<any>();
    @Output() removeItem = new EventEmitter<any>();


    @Input() isContractColumnQuantity = false;

    icPlus = icPlus;
    icMinus = icMinus;

    constructor() {
    }

    ngOnInit() {
    }

    add(row: any) {
        this.addItem.emit(row);
    }

    remove(item: any) {
        this.removeItem.emit(item);
    }

    updateQuantity(item: any, action: 'minus' | 'plus' | 'change') {
        this.updateQuantityItem.emit({item, action});
    }

    _checkNumberInput($event: Event, item: any) {
        const inputElement = event.target as HTMLInputElement;
        const inputValue = inputElement.valueAsNumber;
        if (inputValue < 1) {
            this.remove(item);
        } else {
            this.updateQuantity(item, 'change');
        }
    }

}
