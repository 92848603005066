export const stripHtmlTags = (html: string): string => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
};


export const stripHtmlTagsRemove = (html: string): string => {
    return html.replace(/<\/?[^>]+(>|$)/g, '');
};


export function truncateText(text: string, maxLength: number): string {
    const cleanText = stripHtmlTags(text);
    return cleanText.length > maxLength ? cleanText.substring(0, maxLength) + '...' : cleanText;
}
