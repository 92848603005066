<div class="mt-6 flex flex-col md:flex-row md:items-start">
    <div class="flex-auto">
        <div class='card'>
            <base-data-table-header
                label="Contrats rattachés à l'équipement"
                [loading]='loading'
                (onSearchChange)='onSearchChange($event)'
                (onClearTextFilter)='clearTextFilter()'
                [showSearch]='false'
                [backgroundColorWhite]='true'
                [canCreate]='false'
            ></base-data-table-header>

            <!-- TODO PERMISSION [rowClickPermission]="ENUM_PERMISSION.READ_HM_EQUIPMENT_LINK"-->
            <base-data-table
                [count]='count'
                [pageSize]='pageSize'
                [sortField]='sortField'
                [sortOrder]='sortOrder'
                [dataSource]='dataSource'
                [displayedColumns]='displayedColumns'
                [feisColumns]='feisColumns'
                (onRowClick)='redirectTo($event)'
                (onPageEvent)='pageEvent($event)'
                (onSortEvent)='sortEvent($event)'
                (onUpdateItem)='updateItem($event)'
                (onDeleteItem)='deleteItem($event)'
                [rowClickPermission]="ENUM_PERMISSION.ACCESS_EQUIPMENT_INFO"
            ></base-data-table>
        </div>
    </div>
</div>