import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit, Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, startWith} from 'rxjs/operators';
import icSearch from '@iconify/icons-ic/twotone-search';
import icClose from '@iconify/icons-ic/twotone-close';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from '@angular/material/chips';
import {MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {GenericProductService} from "../../../pages/application/settings/generic-product/generic-product.service";


export interface AllGenerics {
    type: string;
    items: any[];
}

@Component({
    selector: 'vex-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
    encapsulation: ViewEncapsulation.None,

})
export class SearchBarComponent implements OnInit {
    @Output() fillterEvent = new EventEmitter<any>();


    searchWidthFull = true;
    icSearch = icSearch;
    icFilterList = icFilterList;
    popupStatus: Array<string>;
    popupForm: FormGroup = new FormGroup({});
    icClose = icClose;
    genericControl = new FormControl();
    search = new FormControl();


    generics: AllGenerics[] = [
        {type: 'Prestation', items: []},
        {type: 'Article', items: []},
        // {type: 'Equipement', items: []},
    ];

    filteredGenericsSubject: BehaviorSubject<AllGenerics[]> = new BehaviorSubject([]);
    filteredGenerics: Observable<AllGenerics[]> = this.filteredGenericsSubject.asObservable();

    // matchips
    count: number = 0;


    constructor(
        private genericProductService: GenericProductService
    ) {
        this.genericControl.valueChanges.pipe(
            startWith(''),
            debounceTime(200),
            distinctUntilChanged()).subscribe((value) => {
            this._filterGenerics(value);
        });
    }

    ngOnInit() {


        this.search.valueChanges
            .pipe(debounceTime(300))
            .subscribe(searchText => this.emitSearchData());

        this.genericProductService.findAllShopGenerics().subscribe((data) => {


            this.generics.find(x => x.type === 'Prestation').items = data[0].prestations;
            this.generics.find(x => x.type === 'Article').items = data[0].articles;
            // this.generics.find(x => x.type === 'Equipement').items = data[0].equipments;
            this._filterGenerics('');
        })


        this.popupForm = new FormGroup({
            isLastOrder: new FormControl(false),
            isInStock: new FormControl(false),
        });
    }

    emitSearchData() {
        const searchData = {
            genericProduct: this.genericControl.value?.type === 'product' ? this.genericControl.value._id : null,
            genericEquipment: this.genericControl.value?.type === 'equipment' ? this.genericControl.value._id : null,
            genericPrestation: this.genericControl.value?.type === 'prestation' ? this.genericControl.value._id : null,
            search: this.search.value,
            filters: this.popupForm.value,
        };
        const filteredSearchData = Object.fromEntries(
            Object.entries(searchData).filter(([key, value]) => value !== null)
        );
        this.fillterEvent.emit(filteredSearchData);
    }


    _filterGenerics(value: string): void {
        const filterValue = value;

        if (value) {
            const newFilterdGenerics = this.generics.map(generic => {
                const filteredItems = generic.items.filter(
                    item => item.label.toLowerCase().indexOf(filterValue) !== -1
                );

                return {...generic, items: filteredItems};
            }).filter(group => group.items.length > 0);
            this.filteredGenericsSubject.next(newFilterdGenerics);

        } else {
            this.filteredGenericsSubject.next(this.generics);

        }


    }

    clearTextFilter() {
        this.search.reset();
    }

    displayGeneric(item: any): string {
        return item && item.label ? item.label : '';
    }

    // Ajoutez cette méthode à la classe SearchBarComponent


    clearFilter(): void {
        this.popupForm.reset();
        this.updateCount();
    }

    clearSelection(): void {
        this.genericControl.setValue(null);
        this._filterGenerics('');
        this.filteredGenericsSubject.next(this.generics);
        this.emitSearchData()

    }

    validateFilter() {
        // Mettez à jour le compteur
        this.updateCount();
        this.emitSearchData();
    }


    updateCount() {
        this.count = Object.values(this.popupForm.value).filter((value) => value === true).length;

    }

}
