<div class="h-full" fxLayout="column">
    <div class="p-6" fxFlex="none">
        <div vexContainer>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div class='bg-card rounded-full border px-4'
                     fxFlex='400px'
                     fxFlex.lt-md='auto'
                     fxHide.xs
                     fxLayout='row'
                     fxLayoutAlign='start center'>
                    <ic-icon [icIcon]='icSearch' size='20px'></ic-icon>
                    <input
                        class='px-4 py-3 border-0 outline-none w-full bg-transparent'
                        placeholder='Recherche...'
                        #search
                    >
                    <button *ngIf='search.value' matSuffix mat-icon-button aria-label='EFFACER'
                            (click)='clearTextFilter()'>
                        <ic-icon [icIcon]='icClose' size='20px'></ic-icon>
                    </button>
                </div>

<!--                <button (click)="createContact()"-->
<!--                        class="ltr:ml-4 rtl:mr-4"-->
<!--                        color="primary"-->
<!--                        fxFlex="none"-->
<!--                        fxHide.xs-->
<!--                        mat-raised-button-->
<!--                        type="button">-->
<!--                    <ic-icon [icon]="icPersonAdd"-->
<!--                             class="ltr:mr-2 rtl:ml-2 ltr:-ml-1 rtl:-mr-1"-->
<!--                             inline="true"-->
<!--                             size="20px"></ic-icon>-->
<!--                    <span>Ajouter un contact</span>-->
<!--                </button>-->

                <!--                <button (click)="createContact()"-->
                <!--                        class="ltr:ml-4 rtl:mr-4"-->
                <!--                        color="accent"-->
                <!--                        fxFlex="none"-->
                <!--                        fxHide.xs-->
                <!--                        mat-raised-button-->
                <!--                        type="button">-->
                <!--                    <ic-icon [icon]="icPersonAdd"-->
                <!--                             class="ltr:mr-2 rtl:ml-2 ltr:-ml-1 rtl:-mr-1"-->
                <!--                             inline="true"-->
                <!--                             size="20px"></ic-icon>-->
                <!--                    <span>ADD CONTACT</span>-->
                <!--                </button>-->
            </div>
        </div>
    </div>

    <div *ngIf="(establishmentService.contacts$ | async)?.length > 0"
         @stagger
         class="overflow-y-auto"
         fxFlex="auto">
        <div class="p-gutter"
             vexContainer
             gdColumns="1fr 1fr 1fr 1fr"
             gdColumns.lt-md="1fr 1fr"
             gdColumns.xs="1fr"
             gdGap="24px">
            <vex-contacts-card (openContact)="openContact($event)"
                               *ngFor="let contact of (establishmentService.contacts$ | async)"
                               @fadeInUp
                               [contact]="contact"
                               [canUpdate]='false'
                               [canDelete]='false'
                               [callPermission]="[ENUM_PERMISSION.CALL_CP_ESTABLISHMENT_INTERNAL_CONTACT]"
                               [sendPermission]="[ENUM_PERMISSION.SEND_CP_ESTABLISHMENT_INTERNAL_CONTACT]"
            >
                <div class="body-2 text-secondary">
<!--                    <ic-icon class="ltr:mr-1 rtl:ml-1" inline="true"></ic-icon>-->
                    <span>{{ contact?.role?.name }}</span>
                </div>
            </vex-contacts-card>
        </div>
    </div>

    <div *ngIf="(establishmentService.contacts$ | async)?.length === 0"
         @scaleFadeIn
         fxFlex="auto"
         fxLayout="column"
         fxLayoutAlign="center center">
        <img class="m-12 h-64" src="assets/img/illustrations/idea.svg">
        <h2 class="headline m-0 text-center">Il n'y a pas de contact pour cet établissement</h2>
    </div>
</div>

