import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { scaleIn400ms } from '../../../../../../../@vex/animations/scale-in.animation';
import { fadeInRight400ms } from '../../../../../../../@vex/animations/fade-in-right.animation';
import { stagger40ms } from '../../../../../../../@vex/animations/stagger.animation';
import { fadeInUp400ms } from '../../../../../../../@vex/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from '../../../../../../../@vex/animations/scale-fade-in.animation';
import {Observable, Subscription} from 'rxjs';
import icClose from '@iconify/icons-ic/twotone-close';
import icMinus from '@iconify/icons-ic/twotone-minus';
import icPlus from '@iconify/icons-ic/twotone-plus';
import {ENUM_PERMISSIONS} from '../../../../../enums/permission.enum';
import {BaseBasketItemModel} from '../../../../models/base-basket-item.model';

@Component({
  selector: 'catalog-basket-base',
  templateUrl: './catalog-basket-base.component.html',
  animations: [
    scaleIn400ms,
    fadeInRight400ms,
    stagger40ms,
    fadeInUp400ms,
    scaleFadeIn400ms
  ],
  styles: [`
    .number-no-arrows::-webkit-outer-spin-button,
    .number-no-arrows::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        text-align: center;
    }
  `]
})
export class CatalogBasketBaseComponent implements OnInit, OnDestroy {

  @Input() items$: Observable<any | BaseBasketItemModel> = new Observable<any>();
  @Input() canChangeQuantity: boolean = false;
  @Input() canDelete: boolean = false;
  @Input() displayTitle: boolean = true;

  @Output() removeItem = new EventEmitter<any>();
  @Output() updateQuantityItem = new EventEmitter<any>();
  @Output() updatePriceItem = new EventEmitter<any>();
  @Output() updateEmergencyItem = new EventEmitter<any>();
  @Input() showPrice: boolean = false;
  @Input() canChangePrice: boolean = false;
  @Input() showTotalBasketAmount: boolean = false;
  @Input() canDeletePermission?: boolean = true;
  @Input() canUpdateBasketPermission?: boolean = true;


  totalBasketAmount: number = 0.00;
  icPlus = icPlus;
  icMinus = icMinus;
  icClose = icClose;

  private subscription = new Subscription();
  protected readonly ENUM_PERMISSIONS = ENUM_PERMISSIONS;

  constructor() {
  }

  ngOnInit() {


    const items = this.items$.subscribe(items => {
      // TODO A REFACTO PLUS TARD PATCH TEMPORAIRE POUR RENDRE LE COMPOSANT VRAIMENT GENERIQUE

      if (!items) {
        return;
      }
      for (let item of items) {
        console.log('item', item);
        if (item.supplier)
        {
          item.label = item.label; // PRED-1410 : item.label = item.supplier.label ?
          item.subLabel = item.supplier.label;
        }
      }
      this.totalBasketAmount = items.reduce((acc, item) => acc + item.priceHT * item.quantity, 0).toFixed(3);
    });
    this.subscription.add(items);
  }

  trackByItemId(index: number, item: any): any {
    return item.id; // Assurez-vous que chaque élément a un identifiant unique
  }

  remove(item: any) {
      this.removeItem.emit(item);
  }

  updateQuantity(item: any, action: 'minus' | 'plus' | 'change') {


    this.updateQuantityItem.emit({item, action});
  }

  _checkNumberInput($event: Event, item: any) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.valueAsNumber;
    if (inputValue < 1) {
      this.remove(item);
    } else {
      this.updateQuantity(item, 'change');
    }
  }

  _changePriceInput($event: Event, item: any) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.valueAsNumber;
    this.updatePriceItem.emit({item});
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
