export enum GenericEngagementDataPatrimonyEnum {
    EQUIPMENT = 'Equipement GEN',
    REFERENCE = 'Référence GEN',
    BUILDING = 'Bâtiment GEN',
    ID_ENERGIE = 'ID Energie',
}

export enum GenericEngagementEquipmentCondEnum {
    MAINTENANCE_EQ = 'Maintenance EQ',
    MAINTENANCE_EQ_AC = 'Maintenance EQ (achat pièces détachées)',
    MAINTENANCE_REG = 'Contrôle règlementaire EQ (forfait intervention)',
    LOCATION_EQ = 'Location EQ',
    ACQUISITION_EQ = 'Acquisition EQ',
    PRODUCT = 'Articles'
}

export enum GenericEngagementReferenceCondEnum {
    ARTICLES = 'Articles',
    PRESTATIONS = 'Prestations',
}

export enum GenericBuildingCondENum {
    MAINTENANCE_BAT = 'Maintenance Bâtiment',
    MAINTENANCE_BAT_AC = 'Maintenance Bâtiment (achat fournitures)',
    MAINTENANCE_REG = 'Contrôle réglementaire Bâtiment (forfait intervention)',
    BUILDING_LOCATION = 'Location Bâtiment',
    BUILDING_ACQUISITION = 'Acquisition Bâtiment'

}
