import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/core/base/base.service';
import { GenericHotTopicModel } from '../../../../core/models/generic-hot-topic.model';

@Injectable({
    providedIn: 'root'
})
export class GenericHotTopicService extends BaseService<GenericHotTopicModel>{
    defaultSort = 'createdAt';

    constructor(public http: HttpClient) {
        super(http, 'generic-hot-topic');
    }
}


