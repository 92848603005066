import {BaseService} from '../../../../core/base/base.service';
import {HttpClient} from '@angular/common/http';
import {GenericPaymentConditionModel} from '../../../../core/models/generic-payment-condition.model';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GenericPaymentConditionService extends BaseService<GenericPaymentConditionModel> {
    defaultSort = 'createdAt';

    constructor(public http: HttpClient) {
        super(http, 'generic-payment-condition');
    }
}
