import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import {DeepPartial} from '../interfaces/deep-partial.type';
import {mergeDeep} from '../utils/merge-deep';
import {LayoutService} from './layout.service';
import {configs} from './configs';
import {ConfigName} from '../interfaces/config-name.model';
import {Config} from '../interfaces/config.model';
import {HttpClient} from '@angular/common/http';
import {ObjectApiInterface} from '../../app/core/base/interfaces/object-api.interface';
import {map} from 'rxjs/operators';

interface IEnvBackend {
    apiUrl: string;
    azureAudienceId: string;
    mongodbChartsBaseUrl: string;
    energismeUrl: string;
    gedMenuUrl: string;
    applicationEnvironment: string;
}

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    defaultConfig = ConfigName.apollo;

    configs: Config[] = configs;

    env: IEnvBackend;

    private _configSubject = new BehaviorSubject(this.configs.find(c => c.id === this.defaultConfig));
    config$ = this._configSubject.asObservable();

    constructor(@Inject(DOCUMENT) private document: Document,
                private layoutService: LayoutService, private http: HttpClient) {
        this.config$.subscribe(config => this._updateConfig(config));
    }

    setConfig(config: ConfigName) {
        const settings = this.configs.find(c => c.id === config);

        if (settings) {
            this._configSubject.next(settings);
        }
    }

    updateConfig(config: DeepPartial<Config>) {
        this._configSubject.next(mergeDeep({...this._configSubject.getValue()}, config));
    }

    private _updateConfig(config: Config) {
        const body = this.document.body;

        this.configs.forEach(c => {
            if (body.classList.contains(c.id)) {
                body.classList.remove(c.id);
            }
        });

        body.classList.add(config.id);

        config.sidenav.state === 'expanded' ? this.layoutService.expandSidenav() : this.layoutService.collapseSidenav();

        this.document.body.dir = config.rtl ? 'rtl' : 'ltr';

        // Workaround so charts and other externals know they have to resize on Layout switch
        if (window) {
            window.dispatchEvent(new Event('resize'));

            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 200);
        }
    }

    initEnv(): Observable<any> {
        return this.http.get<ObjectApiInterface<any>>(`api/initEnv`).pipe(map(x => {
            this.env = x.data;
            this._configSubject.next({...this._configSubject.getValue(), ...x.data});
            return x;
        }))
    }
}
