<ng-template #prestationTemplate>
    <vex-page-layout>
        <vex-page-layout-header class='pb-4' fxLayout='column' fxLayoutAlign='center start'>
        </vex-page-layout-header>

        <vex-page-layout-content class='-mt-6'>
            <div class='card overflow-auto -mt-16'>

                <base-data-table-header
                    label='Prestations'
                    [loading]='loading'
                    [canImport]="false"
                    [canCreate]='false'
                    (onSearchChange)='onSearchChange($event)'
                    [templates]="[{ template: advancedFilters, position: 2, place: 'before' }]"
                    (onClearTextFilter)='clearTextFilter()'
                    (onCreate)='createItem()'
                    (onImport)="importItem()"
                    [showArchives]="canShowArchive"
                    [showExpires]="false"

                    (onShowExpired)="onShowExpired($event)"
                    (onShowArchived)="onShowArchived($event)"
                    [createPermission]="[ENUM_PERMISSION.CREATE_SERVICE]"
                    [importPermission]="[ENUM_PERMISSION.CREATE_SERVICE]"
                ></base-data-table-header>

                <base-data-table
                    [count]='count'
                    [pageSize]='pageSize'
                    [sortField]='sortField'
                    [sortOrder]='sortOrder'
                    [dataSource]='dataSource'
                    [displayedColumns]='displayedColumns'
                    [feisColumns]='feisColumns'
                    [stickyTop]='true'
                    (onPageEvent)='pageEvent($event)'
                    (onSortEvent)='sortEvent($event)'
                    (onUpdateItem)='updateItem($event)'
                    (onDeleteItem)='deleteItem($event)'
                    (onArchiveItem)="archiveItem($event)"
                    (onRowClick)='rowClickItem($event)'
                    [customColumnTemplate]="templateRef"
                    [deletePermission]="[ENUM_PERMISSION.DELETE_SERVICE]"
                    [updatePermission]="[ENUM_PERMISSION.UPDATE_SERVICE]"
                    [actionText]='actionText'
                    [isProductPrestation]="true"
                ></base-data-table>
            </div>
        </vex-page-layout-content>
    </vex-page-layout>
    <ng-template #advancedFilters>
        <button-filter
            [nbFilters]="activeFiltersCount"
            (clickButton)="toggleSidenav()">
        </button-filter>
    </ng-template>
</ng-template>

<ng-template #filters>
    <ng-container *ngIf="isFilterOpen">
        <prestation-filter [sidenav]='sideNav' (onClear)="clearTextFilter()" (onFilter)="applyAdvancedFilter($event)">
        </prestation-filter>
    </ng-container>
</ng-template>

<app-sidenav
    #sidenav
    [content]="prestationTemplate"
    [contentSidenav]="filters">
</app-sidenav>



<ng-template #templateRef let-column let-row="element" let-status="status">
  <div *ngIf="column === 'quoteId'" class="relative flex items-center" >

    <button [matTooltip]="row.quoteId ? row?.quote?.technicalIdentifier : row?.contract?.technicalIdentifier"
            (click)="redirectToElement(row)"
            matTooltipClass="example-tooltip-uppercase"
    >
        <mat-icon color="{{(row.quoteId || row.contract?.format === 'Devis')? 'secondary' : 'primary' }}">
            {{(row.quoteId || row.contract?.format === 'Devis')? 'description' : 'assignment' }}
        </mat-icon>
    </button>
  </div>

    <div *ngIf="column === 'tva'" class="relative flex items-center">
        <span>{{ getTvaRate(row.tva) }}</span>
    </div>

    <div *ngIf="column === 'label'" class="relative flex items-center" (mouseenter)="showComment = row._id" (mouseleave)="showComment = null">
        <span>{{ row.label }}</span>
    </div>

    <div *ngIf="column === 'reference'" class="relative flex items-center" (mouseenter)="showPreviousReference = row._id" (mouseleave)="showPreviousReference = null">
        <span>{{ row.reference }}</span>
        <div [hidden]="showPreviousReference !== row._id" class="absolute bg-white z-10 top-0 translate-y-5">
            <div *ngIf="row.previousReference" class="p-2 bg-gray-100 border rounded shadow-lg max-w-3xl overflow-auto" [innerHTML]="row.previousReference"></div>
        </div>
    </div>

</ng-template>

