import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BuildingRoomModel, FloorModel } from '../../../../../../../../core/models/building-room.model';
import { CrudModeEnum } from '../../../../../../../../core/base/enum/crud-mode.enum';
import icClose from '@iconify/icons-ic/twotone-close';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormModalBaseComponent } from '../../../../../../../../core/base/components/form-modal-base/form-modal-base.component';
import { BuildingModel } from '../../../../../../../../core/models/building.model';
import { BuildingService } from '../../../../building.service';
import { RoomCategoryService } from '../../../../../../settings/room-category/room-category.service';
import { Observable } from 'rxjs';
import { FloorTypeModel } from '../../../../../../../../core/models/floor-type.model';
import { FloorTypeService } from '../../../../../../settings/floor-type/floor-type.service';
import { map } from 'rxjs/operators';
import { ErrorApi } from '../../../../../../../../core/models/api/error-api';

@Component({
  selector: 'vex-building-room-floor-create-update-delete',
  templateUrl: './building-room-floor-create-update-delete.component.html',
  styleUrls: ['./building-room-floor-create-update-delete.component.scss']
})
export class BuildingRoomFloorCreateUpdateDeleteComponent implements OnInit {
  form: FormGroup;
  mode: CrudModeEnum;

  icClose = icClose;

  loading = false;
  defaults: FloorModel;
  successCreateMessage: string;
  successUpdateMessage: string;
  successDeleteMessage: string;
  errorCreateMessage: string;
  errorUpdateMessage: string;
  errorDeleteMessage: string;

  CrudModeEnum = CrudModeEnum;

  floorType$: Observable<FloorTypeModel[]>;

  constructor(
      @Inject(MAT_DIALOG_DATA) public data,
      public dialogRef: MatDialogRef<FormModalBaseComponent<FloorModel>>,
      public service: BuildingService,
      public floorTypeService: FloorTypeService
  ) { }

  ngOnInit(): void {
    this.mode = this.data.mode;
    this.defaults = this.data.defaults;

    // this.floorType$ = this.floorTypeService.findAll(null, null, null, 'desc', null, {floorIds: this.data?.floorIds}).pipe(map(res => res.data));
    this.flootTypeAutocompleteChange();
    this.form = new FormGroup({
      _id: new FormControl(this.defaults?._id),
      floorType: new FormControl(this.defaults?.floorType, [Validators.required]),
      percent: new FormControl(this.defaults?.percent, [Validators.required]),
    })
  }

  isCreateMode() {
    return this.mode === CrudModeEnum.Create;
  }

  isUpdateMode() {
    return this.mode === CrudModeEnum.Update;
  }

  isDeleteMode() {
    return this.mode === CrudModeEnum.Delete;
  }

  save() {
    if (this.isCreateMode()) {
      this.createItem();
    } else if (this.isUpdateMode()) {
      this.updateItem();
    }
  }

  createItem() {
    if (this.form.valid) {
      this.service.createFloor(this.data.building._id, this.form.getRawValue(), this.data.roomId).subscribe(
          res => {
            this.close(res.data);
          }, err => {
            this.setErrorsMessages(err.error.errors);
          }
      );
    }
  }

  updateItem() {
    if (this.form.valid) {
      this.service.updateFloor(this.data.building._id, this.form.getRawValue(), this.data.roomId).subscribe(
          res => {
            this.close(res.data);
          }, err => {
            this.setErrorsMessages(err.error.errors);
          }
      );
    }
  }

  deleteItem() {
    if (this.defaults) {
      this.service.deleteFloor(this.data.building._id, this.defaults._id, this.data.roomId).subscribe(
          res => {
            this.close(res);
          }, err => {
            console.log(err);
          }
      );
    }
  }

  close(res?) {
    this.dialogRef.close(res);
  }


  setErrorsMessages(errors: Array<ErrorApi>) {
    errors?.forEach(error => {
      let err = this.form.controls[error.property].errors;
      if (err === null) {
        err = {messages: []};
      }
      err.messages.push(error.message);
      this.form.controls[error.property].setErrors(err);
    });
    this.form.markAllAsTouched();
  }

    flootTypeAutocompleteChange(state: any = '') {
        this.floorType$ = this.floorTypeService.findAll(null, null, 'label', 'asc', state, {floorIds: this.data?.floorIds}).pipe(map(res => res.data));
    }
}
