import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplicationLayoutComponent } from './core/layout/application-layout/application-layout.component';
import { AuthGuard } from './core/guards/auth.guard';
import { VexRoutes } from '../@vex/interfaces/vex-route.interface';
import { DashboardComponent } from './core/layout/application-layout/dashboard/dashboard.component';
import { MsalGuard } from '@azure/msal-angular';
import { ModeCoreGuard } from './core/guards/mode-core.guard';
import { ModeTenantGuard } from './core/guards/mode-tenant.guard';
import { ProfilComponent } from './core/layout/application-layout/profil/profil.component';
import { NotificationComponent } from './core/layout/application-layout/notification/notification.component';
import { RedirectCoreDashboardGuard } from './core/guards/redirect-core-dashboard.guard';
import { PermissionGuard } from './core/guards/permission.guard';
import { ENUM_PERMISSIONS } from './core/enums/permission.enum';
import { ForbiddenComponent } from './core/layout/application-layout/unauthorized/forbidden.component';
import { InprogressComponent } from './core/layout/application-layout/inprogress/inprogress.component';
import { MaintenanceComponent } from './core/layout/application-layout/maintenance/maintenance.component';
import { MaintenanceGuard } from './core/guards/maintenance.guard';
import { TicketHomeComponent } from './pages/application/administrative/ticket/ticket-home/ticket-home.component';

const applicationRoutes: VexRoutes = [
    // {
    //   path: 'client',
    //   children: [
    //     {
    //       path: 'dashboard',
    //       loadChildren: () => import('./pages/application/client/dashboard/dashboard.module').then(m => m.DashboardModule),
    //       data: {
    //         toolbarShadowEnabled: true
    //       },
    //       runGuardsAndResolvers: "always"
    //     },
    //     {
    //       path: 'mydocuments',
    //       loadChildren: () => import('./pages/application/wip/wip.module').then(m => m.WipModule),
    //       data: {
    //         toolbarShadowEnabled: true
    //       },
    //       runGuardsAndResolvers: "always"
    //     }
    //   ]
    // },
    // {
    //   path: 'compta',
    //   children: [
    //     {
    //       path: 'inbox',
    //       // loadChildren: () => import('./pages/application/wip/wip.module').then(m => m.WipModule),
    //       loadChildren: () => import('./pages/application/compta/inbox/dashboard.module').then(m => m.DashboardModule),
    //       data: {
    //         toolbarShadowEnabled: true
    //       },
    //       runGuardsAndResolvers: "always"
    //     },
    //     {
    //       path: 'transfer',
    //       loadChildren: () => import('./pages/application/compta/transfer/compta-transfert.module').then(m => m.ComptaTransfertModule),
    //       data: {
    //         toolbarShadowEnabled: true
    //       },
    //       runGuardsAndResolvers: "always"
    //     },
    //     {
    //       path: 'documents',
    //       loadChildren: () => import('./pages/application/compta/documents/documents.module').then(m => m.DocumentsModule),
    //       data: {
    //         toolbarShadowEnabled: true
    //       },
    //       runGuardsAndResolvers: "always"
    //     },
    //     {
    //       path: 'entities',
    //       loadChildren: () => import('./pages/application/compta/entities/entities.module').then(m => m.EntitiesModule),
    //       data: {
    //         toolbarShadowEnabled: true
    //       },
    //       runGuardsAndResolvers: "always"
    //     }
    //   ]
    // },
    // {
    //   path: 'redirect',
    //   redirectTo: 'dashboard', //TODO need to be dynamic
    //   pathMatch: 'full',
    // },
    {
        path: '',
        redirectTo: 'ticket-home', //TODO need to be dynamic,
        pathMatch: 'full'
    },
    {
        path: 'ticket-home',
        component: TicketHomeComponent,
        canActivate: [MsalGuard, RedirectCoreDashboardGuard],
        data: {
            toolbarShadowEnabled: true,
            containerEnabled: true
        },
    },
    {
        path: 'unauthorized',
        component: ForbiddenComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [MsalGuard, RedirectCoreDashboardGuard, PermissionGuard],
        data: {
            permission: ENUM_PERMISSIONS.READ_TB
        }
    },
    {
        path: 'notification',
        component: NotificationComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'profil',
        component: ProfilComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'admin',
        loadChildren: () => import('./pages/application/admin/admin.module').then(m => m.AdminModule),
        canActivate: [MsalGuard, ModeCoreGuard]
    },
    {
        path: 'achat',
        loadChildren: () => import('./pages/application/achat/achat.module').then(m => m.AchatModule),
        canActivate: [MsalGuard, ModeTenantGuard]
    },
    {
        path: 'administrative',
        loadChildren: () => import('./pages/application/administrative/administrative.module').then(m => m.AdministrativeModule),
        canActivate: [MsalGuard, ModeTenantGuard]
    },
    {
        path: 'technique',
        loadChildren: () => import('./pages/application/technique/technique.module').then(m => m.TechniqueModule),
        canActivate: [MsalGuard, ModeTenantGuard]
    },
    {
        path: 'management',
        loadChildren: () => import('./pages/application/management/management.module').then(m => m.ManagementModule),
        canActivate: [MsalGuard, ModeTenantGuard]
    },
    {
        path: 'settings',
        loadChildren: () => import('./pages/application/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [MsalGuard]
    },
    {
        path: 'ressource',
        loadChildren: () => import('./pages/application/ressource/ressource.module').then(m => m.RessourceModule),
        canActivate: [MsalGuard]
    },
    {
        path: 'budget',
        loadChildren: () => import('./pages/application/budget/budget.module').then(m => m.BudgetModule),
        canActivate: [MsalGuard]
    },
    {
        path: 'restore',
        component: InprogressComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'forbidden',
        component: ForbiddenComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'maintenance',
        component: MaintenanceComponent,
        canActivate: [MsalGuard]
    }
    // {
    //     path: '**',
    //     redirectTo: 'dashboard',
    //     pathMatch: 'full'
    // }
];

const routes: Routes = [
    // {
    //   path: 'login',
    //   loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginModule),
    // },
    // {
    //   path: 'redirect',
    //   redirectTo: '', //TODO need to be dynamic
    //   pathMatch: 'full',
    // },
    {
        path: '',
        component: ApplicationLayoutComponent,
        canActivate: [AuthGuard],
        children: applicationRoutes,
        runGuardsAndResolvers: 'always'
    }

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        // preloadingStrategy: PreloadAllModules,
        scrollPositionRestoration: 'enabled',
        relativeLinkResolution: 'corrected',
        anchorScrolling: 'enabled',
        // enableTracing: true
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
