import { Component, OnInit } from '@angular/core';
import { EstablishmentModel } from 'src/app/core/models/establishment.model';
import { EstablishmentService } from './establishment.service';
import { MatDialog } from '@angular/material/dialog';
import {
    EstablishmentCreateUpdateDeleteComponent
} from './modals/establishment-create-update-delete/establishment-create-update-delete.component';
import {bubbleColor, FeiColumn} from 'src/app/core/base/interfaces/fei-column.interface';
import { DataTableBase } from 'src/app/core/base/components/data-table-base/data-table-base';
import { CrudModeEnum } from 'src/app/core/base/enum/crud-mode.enum';
import { Router } from '@angular/router';
import { ENUM_PERMISSIONS } from '../../../../core/enums/permission.enum';
import { EquipmentService } from '../equipment/equipment.service';
import { SecurityCommissionService } from '../../technique/security-commission/security-commission.service';
import { AuthService } from '../../../../core/services/auth.service';
import {SnackbarService} from "../../../../core/services/snackbar.service";
import {
    EstablishmentArchiveDeleteComponent
} from "./modals/establishment-archive-delete/establishment-archive-delete.component";
import {
    EstablishmentStatusEnum,
    EstablishmentStatusTranslation
} from "../../../../core/enums/establishment-status.enum";

@Component({
    selector: 'vex-establishment',
    templateUrl: './establishment.component.html',
    styleUrls: ['./establishment.component.scss'],
})
export class EstablishmentComponent extends DataTableBase<EstablishmentModel> implements OnInit {

    feisColumns: Array<FeiColumn> = [
        {label: 'Identifiant Technique', column: 'technicalIdentifier'},
        {label: 'Dénomination Établissement', column: 'label'},
        {label: 'N° Siren', column: 'sirenNumber'},
        {label: 'N° Siret', column: 'siretNumber'},
        {label: 'Identifiant entreprise', column: 'dunsNumber'},
        {label: 'Code ape', column: 'ape'},
        {label: 'Code TVA', column: 'tva'},
        {
            label: 'Statut',
            column: 'status',
            propertyDisplay: 'status',
        },
        {label: '',
            column: 'actions',
            canUpdate: false,
            canArchive: this.authService.hasPermission(ENUM_PERMISSIONS.DELETE_ESTABLISHMENT)
        },

    ];
    ENUM_PERMISSIONS = ENUM_PERMISSIONS;

    canCreatePermissions = false;
    canShowArchivePermissions = false;

    constructor(
        public service: EstablishmentService,
        private dialog: MatDialog,
        private router: Router,
        private equipmentService: EquipmentService,
        private securityCommissionService: SecurityCommissionService,
        private authService: AuthService,
        private snackbarService: SnackbarService
    ) {
        super();
        this.displayedColumns = this.feisColumns.map(x => x.column);
    }

    ngOnInit() {
        super.ngOnInit();

        this.authService.getCurrentUserPermissions$().subscribe(permissions => {
            this.canCreatePermissions = permissions.includes(ENUM_PERMISSIONS.CREATE_ESTABLISHMENT);
            this.canShowArchivePermissions = permissions.includes(ENUM_PERMISSIONS.READ_ESTABLISHMENT);
        });
    }

    initData() {
        super.initData();
        this.requiredFields = [
            'label',
            'sirenNumber',
            'siretNumber',
            'dunsNumber',
            'ape',
            'tva',
            'status',
            'technicalIdentifier',
        ];
    }

    protected override transformData(data: any[]): any[] {
        return data.map((establishment: EstablishmentModel) => {
            establishment.status = EstablishmentStatusTranslation.translate(establishment.status as EstablishmentStatusEnum);
            return establishment;
        });
    }

    createItem(): void {
        this.dialog.open(EstablishmentCreateUpdateDeleteComponent).afterClosed().subscribe((establishment: EstablishmentModel) => {
            if (establishment) {
                if (establishment._id) {
                    this.router.navigateByUrl('/management/establishment/' + establishment._id).then();
                } else {
                    this.initData();
                }
            }
        });
    }

    importItem(): void {
        this.dialog.open(EstablishmentCreateUpdateDeleteComponent, {
            data: {
                mode: CrudModeEnum.Import,
            }
        }).afterClosed().subscribe((establishment: EstablishmentModel[]) => {
            if (establishment) {
                this.initData();
            }
        });
    }

    updateItem(element: EstablishmentModel): void {
        this.dialog.open(EstablishmentCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                mode: CrudModeEnum.Update
            }
        }).afterClosed().subscribe((establishment: EstablishmentModel) => {
            if (establishment) {
                this.initData();
            }
        });
    }

    deleteItem(element: EstablishmentModel) {
        console.log('dans le delte')
        this.dialog.open(EstablishmentCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                name: `${element.label}`,
                mode: CrudModeEnum.Delete
            }
        }).afterClosed().subscribe((establishment: EstablishmentModel) => {
            if (establishment) {
                this.initData();
            }
        });
    }

    async archiveItem(element: EstablishmentModel) {


        this.dialog.open(EstablishmentArchiveDeleteComponent, {
            data: {
                defaults: element
            }
        }).afterClosed().subscribe((res) => {
            if (res) {
                this.snackbarService.success("L'établissement a été archivé");
                this.initData();
            }
        });



        // const securityCommission = await lastValueFrom(this.securityCommissionService.getEstablishmentRelation(element._id));
        // const equipment = await lastValueFrom(this.equipmentService.getEstablishmentRelation(element._id));
        // const securityCAndEquipment = [...equipment.data.map(x => `Equipement - ${x.reference}`), ...securityCommission.data.map(x => `Commission de sécurité - ${x.label}`)];
        // if (securityCAndEquipment.length > 0) {
        //     this.dialog.open(FormModalConfirmWithListComponent, {
        //         data: {
        //             title: 'Archivage impossible',
        //             message: `Vous ne pouvez pas archiver cet établissement car il est affecté à des équipements / commissions de sécurité.`,
        //             userList: securityCAndEquipment
        //         }
        //     });
        // } else {
        //     this.dialog.open(EstablishmentCreateUpdateDeleteComponent, {
        //         data: {
        //             defaults: element,
        //             name: `${element.label}`,
        //             mode: CrudModeEnum.Archive
        //         }
        //     }).afterClosed().subscribe((establishment: EstablishmentModel) => {
        //         if (establishment) {
        //             this.initData();
        //         }
        //     });
        // }
    }

}
