<ng-container *ngIf="isCreateMode()">
    <base-form-header (onClose)='close(false)' [mode]='mode' createLabel='Description de l’anomalie'
                      updateLabel='Modifier le ticket  '></base-form-header>
    <mat-horizontal-stepper [linear]="isLinear" class="pb-4">
        <mat-step [stepControl]="firstFormGroup">
            <form [formGroup]="firstFormGroup">
                <ng-template matStepLabel>De quel équipement s’agit-il ?</ng-template>
                <mat-divider class="-mx-6 text-border mb-6"></mat-divider>

                <base-form-input
                        label="Équipement générique"
                        controlName='genericEquipment'
                        type='autocomplete'
                        [items$]='genericEquipment$'
                        (autocompleteChange)='autocompleteGenericEquipment($event)'
                        displayProperty='label'>
                </base-form-input>

                <base-form-input
                        label='Equipement'
                        controlName='equipment'
                        type='autocomplete'
                        [items$]='equipments$'
                        (autocompleteChange)='autocompleteEquipment($event)'
                        (optionChange)='equipmentSelected($event)'
                        displayProperty='customDisplay'>
                </base-form-input>
                <div class="flex justify-between">
                    <button *ngIf='!isUpdateMode()' (click)='toDraft()' mat-button color="primary"
                            class="self-align-right uppercase" type="button"
                            [ngStyle]="{'pointer-events': isEnterKeyPressed ? 'none' : 'auto'}"
                            (keydown)="onKeyDown($event)" (keyup)="onKeyUp($event)"
                    >
                        Enregistrer sans transmission
                    </button>
                    <button mat-button matStepperNext class="uppercase" color="primary">Suivant</button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup">
            <form [formGroup]="secondFormGroup">
                <ng-template matStepLabel>Où l’équipement se trouve-t ’il ?</ng-template>
                <mat-divider class="-mx-6 text-border mb-6"></mat-divider>

                <base-form-input
                        label='Etablissement'
                        controlName='establishment'
                        type='autocomplete'
                        [items$]='establishments$'
                        (autocompleteChange)='autocompleteEstablishment($event)'
                        displayProperty='label'>
                </base-form-input>

                <base-form-input
                        label='Bâtiment'
                        controlName='building'
                        type='autocomplete'
                        [items$]='buildings$'
                        (autocompleteChange)='autocompleteBuilding($event)'
                        displayProperty='label'>
                </base-form-input>

                <base-form-input
                        label='Salle'
                        controlName='room'
                        type='autocomplete'
                        [items$]='rooms$'
                        (autocompleteChange)='autocompleteRoom($event)'
                        displayProperty='label'>
                </base-form-input>

                <quill-editor
                        [ngClass]="{'quill-valid': secondFormGroup?.controls?.locationDescription.invalid && secondFormGroup?.controls?.locationDescription?.touched }"
                        class='h-200'
                        placeholder="Précisez toute information complémentaire permettant de situer et d’identifier l’équipement concerné…"
                        [modules]="customModule"
                        [formControl]='secondFormGroup?.controls?.locationDescription'>
                </quill-editor>

                <!--                    <mat-error @fadeInUp style="font-size: 75%"-->
                <!--                               *ngIf="form?.controls?.description.invalid && form?.controls?.description?.touched">Le-->
                <!--                        champ-->
                <!--                        description est obligatoire-->
                <!--                    </mat-error>-->

                <div class="flex justify-between">

                    <button *ngIf='!isUpdateMode()' (click)='toDraft()' mat-button color="primary"
                            class="self-align-right uppercase" type="button"
                            [ngStyle]="{'pointer-events': isEnterKeyPressed ? 'none' : 'auto'}"
                            (keydown)="onKeyDown($event)" (keyup)="onKeyUp($event)"
                    >
                        Enregistrer sans transmission
                    </button>
                    <div>
                        <button mat-button matStepperPrevious class="uppercase" color="primary">Précédent</button>
                        <button mat-button matStepperNext class="uppercase" color="primary">Suivant</button>
                    </div>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="thirdFormGroup">
            <form [formGroup]="thirdFormGroup">
                <ng-template matStepLabel>Décrivez l'anomalie</ng-template>
                <mat-divider class="-mx-6 text-border mb-6"></mat-divider>

                <quill-editor
                        [ngClass]="{'quill-valid': thirdFormGroup?.controls?.description.invalid && thirdFormGroup?.controls?.description?.touched }"
                        class='h-200'
                        placeholder="Veuillez décrire l'incident"
                        [modules]="customModule"
                        [formControl]='thirdFormGroup?.controls?.description'>
                </quill-editor>

                <mat-error @fadeInUp style="font-size: 75%"
                           *ngIf="form?.controls?.description.invalid && form?.controls?.description?.touched">Le
                    champ
                    description est obligatoire
                </mat-error>

                <ng-template matStepLabel>Ajoutez des pièces jointes</ng-template>

                <vex-drop-zone *ngIf="canUpload" class="mb-6 mt-6" [files]="files"></vex-drop-zone>

                <div class="flex justify-between">
                    <!--                    <button *ngIf='!isUpdateMode() ' (click)='toDraft()' mat-button color="primary"-->
                    <!--                            class="self-align-right uppercase" type="button">-->
                    <!--                        Enregistrer sans transmission-->
                    <!--                    </button>-->
                    <button *ngIf='!isUpdateMode()' (click)='toDraft()' mat-button color="primary"
                            class="self-align-right uppercase" type="button"
                            [ngStyle]="{'pointer-events': isEnterKeyPressed ? 'none' : 'auto'}"
                            (keydown)="onKeyDown($event)" (keyup)="onKeyUp($event)"
                    >
                        Enregistrer sans transmission
                    </button>
                    <div>
                        <button mat-button matStepperPrevious class="uppercase" color="primary">Précédent</button>
                        <button mat-button matStepperNext class="uppercase"
                                (click)='createItem(false, ticketStatusEnum.WAITING)' color="primary">
                            Confirmer pour transmission
                        </button>
                    </div>
                </div>
            </form>
        </mat-step>

    </mat-horizontal-stepper>

    <!--        <mat-dialog-actions align="end">-->
    <!--            <button *ngIf='!isUpdateMode()' (click)='toDraft()' mat-button type="button">BROUILLON</button>-->
    <!--            <base-form-input-->
    <!--                    type='submit'-->
    <!--                    [loading]="loading"-->
    <!--                    [mode]='mode'-->
    <!--                    saveButtonLabel="OUVRIR LE TICKET"-->
    <!--                    [updateButtonLabel]="form.valid ? 'OUVRIR LE TICKET' : 'ENREGISTRER'"-->
    <!--                    entityLabel='TICKET-EQUIPMENT'-->
    <!--                    (onCreate)='createItem(false)'-->
    <!--                    (onUpdate)='updateItem()'-->
    <!--                    [showCancelButton]='isUpdateMode()'>-->
    <!--            </base-form-input>-->
    <!--        </mat-dialog-actions>-->
</ng-container>

<ng-container *ngIf="isUpdateMode()">
    <form (ngSubmit)="save()" [formGroup]="firstFormGroup" class="flex flex-col h-full justify-between">
        <base-form-header (onClose)='close(false)' [mode]='mode' createLabel='Description de l’anomalie'
                          updateLabel='Description de l’anomalie'></base-form-header>
        <div [formGroup]="firstFormGroup">
            <base-form-input
                    label="Équipement générique"
                    controlName='genericEquipment'
                    type='autocomplete'
                    [items$]='genericEquipment$'
                    (autocompleteChange)='autocompleteGenericEquipment($event)'
                    displayProperty='label'>
            </base-form-input>
            <div class="flex flex-row justify-between items-center">
                <base-form-input
                        class="w-full"
                        label='Equipement'
                        controlName='equipment'
                        type='autocomplete'
                        [items$]='equipments$'
                        (autocompleteChange)='autocompleteEquipment($event)'
                        (optionChange)='equipmentSelected($event)'
                        displayProperty='customDisplay'>
                </base-form-input>
                <button *ngIf="isReferent()" mat-mini-fab color="primary" class="ml-3 mb-6"
                        (click)="$event.preventDefault();createEquipment()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </div>
        <div [formGroup]="secondFormGroup">
            <base-form-input
                    label='Etablissement'
                    controlName='establishment'
                    type='autocomplete'
                    [items$]='establishments$'
                    (autocompleteChange)='autocompleteEstablishment($event)'
                    displayProperty='label'>
            </base-form-input>

            <base-form-input
                    label='Bâtiment'
                    controlName='building'
                    type='autocomplete'
                    [items$]='buildings$'
                    (autocompleteChange)='autocompleteBuilding($event)'
                    displayProperty='label'>
            </base-form-input>

            <base-form-input
                    label='Salle'
                    controlName='room'
                    type='autocomplete'
                    [items$]='rooms$'
                    (autocompleteChange)='autocompleteRoom($event)'
                    displayProperty='label'>
            </base-form-input>
            <h3 class="mb-4">Description de l'emplacement</h3>

            <quill-editor
                    [ngClass]="{'quill-valid': secondFormGroup?.controls?.locationDescription.invalid && secondFormGroup?.controls?.locationDescription?.touched }"
                    placeholder="Précisez toute information complémentaire permettant de situer et d’identifier l’équipement concerné…"
                    [modules]="customModule"
                    [formControl]='secondFormGroup?.controls?.locationDescription'>
            </quill-editor>
        </div>
        <div [formGroup]="thirdFormGroup">
            <h3 class="mb-4">Description de l'anomalie</h3>

            <quill-editor
                    [ngClass]="{'quill-valid': thirdFormGroup?.controls?.description.invalid && thirdFormGroup?.controls?.description?.touched }"
                    placeholder="Veuillez décrire l'incident"
                    [modules]="customModule"
                    [formControl]='thirdFormGroup?.controls?.description'>
            </quill-editor>

            <mat-error @fadeInUp style="font-size: 75%"
                       *ngIf="thirdFormGroup?.controls?.description.invalid && thirdFormGroup?.controls?.description?.touched">
                Le
                champ
                description est obligatoire
            </mat-error>
        </div>

        <mat-dialog-actions align="end">
            <base-form-input
                    type='submit'
                    [loading]="loading"
                    [mode]='mode'
                    updateButtonLabel='ENREGISTRER'
                    entityLabel='TICKET-EQUIPMENT'
                    (onUpdate)='updateItem()'
                    [showCancelButton]='isUpdateMode()'>
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>

<ng-container *ngIf="isDeleteMode()">
    <form-modal-delete-base
            [label]='data.defaults.technicalIdentifier'
            defaultMessage='Êtes vous sur de vouloir supprimer ce ticket ?'
            (onDelete)='deleteItem()'
            [loading]='loading'
            (onClose)='close(null)'
    >
    </form-modal-delete-base>
</ng-container>


<ng-container *ngIf='isArchiveMode()'>
    <form-modal-delete-base
            (onClose)='close()'
            (onDelete)='archiveItem()'
            title="Archiver la récéption"
            defaultMessage='Êtes-vous sûr de vouloir archiver cette récéption ?'
    >
    </form-modal-delete-base>
</ng-container>
