export enum NotificationSeverityEnum {
  //  MINIMAL = 'Minimale',
    LOW = 'Basse',
    MEDIUM = 'Moyenne',
    HIGH = 'Élevée',
  //  MAXIMAL = 'Maximale',
}

// b o r
// -> ?? b o r, what does it mean?
