        <div id="establishment-details-buildings-card" class="card mt-6">
            <div class='px-gutter py-4 border-b' fxLayout='row' fxLayoutAlign='space-between center'>
                    <h2 class='title m-0'>{{ entityName }}</h2>
            </div>

            <div @stagger
                 class='px-gutter py-4'
                 gdGap='24px'>
                <base-data-table
                    [showPaginator]='true'
                    [count]='count'
                    [sortField]='sortField'
                    [sortOrder]='sortOrder'
                    [dataSource]='dataSource'
                    [displayedColumns]='displayedColumns'
                    [feisColumns]='feisColumns'
                    (onPageEvent)='pageEvent($event)'
                    (onSortEvent)='sortEvent($event)'
                    [routerLinkDetailRow]='routerLinkBuilding'
                ></base-data-table>
            </div>
        </div>
<!--</div>-->