import { Component, OnInit } from '@angular/core';
import {UserProfileModel} from "../../../models/user-profile.model";
import {UserProfileService} from "../../../../pages/application/management/user-profile/user-profile.service";
import { Router} from "@angular/router";
import {CustomerGroupService} from "../../../../pages/application/settings/customer-group/customer-group.service";
import {AuthService} from "../../../services/auth.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {SnackbarService} from "../../../services/snackbar.service";
import {CrudModeEnum} from "../../../base/enum/crud-mode.enum";
import {ENUM_PERMISSIONS} from "../../../enums/permission.enum";
import {MatDialog} from "@angular/material/dialog";
import icPhone from '@iconify/icons-ic/twotone-phone';
import icPersonAdd from '@iconify/icons-ic/twotone-person-add';
import icCheck from '@iconify/icons-ic/twotone-check';
import icAccessTime from '@iconify/icons-ic/twotone-access-time';
import icAdd from '@iconify/icons-ic/twotone-add';
import icWork from '@iconify/icons-ic/twotone-work';
import icMail from '@iconify/icons-ic/twotone-mail';
import icWhatshot from '@iconify/icons-ic/twotone-whatshot';
import icDone from '@iconify/icons-ic/twotone-done';
import icClose from '@iconify/icons-ic/twotone-close';
import {scaleIn400ms} from "../../../../../@vex/animations/scale-in.animation";
import {fadeInRight400ms} from "../../../../../@vex/animations/fade-in-right.animation";
import {stagger40ms} from "../../../../../@vex/animations/stagger.animation";
import {fadeInUp400ms} from "../../../../../@vex/animations/fade-in-up.animation";
import {scaleFadeIn400ms} from "../../../../../@vex/animations/scale-fade-in.animation";
import {ProfilCreateUpdateDeleteComponent} from "./profil-create-update-delete/profil-create-update-delete.component";
import {Subscription} from "rxjs";

@Component({
    selector: 'vex-profil',
    templateUrl: './profil.component.html',
    styleUrls: ['./profil.component.scss'],
    animations: [
        scaleIn400ms,
        fadeInRight400ms,
        stagger40ms,
        fadeInUp400ms,
        scaleFadeIn400ms
    ]
})
export class ProfilComponent implements OnInit {

    subscriptions= new Subscription();
    init = false;
    entityName: string;
    loading = false;
    ENUM_PERMISSION = ENUM_PERMISSIONS;
    icWork = icWork;
    icPhone = icPhone;
    icPersonAdd = icPersonAdd;
    icCheck = icCheck;
    icMail = icMail;
    icAccessTime = icAccessTime;
    icAdd = icAdd;
    icWhatshot = icWhatshot;
    icDone = icDone;
    icClose = icClose;
    object: UserProfileModel;
    translateValidatorMatriceLevel = '';
    currentUser: UserProfileModel;

    constructor(
        private router: Router,
        public userProfileService: UserProfileService,
        private snackbar: SnackbarService,
        private customerGroupService: CustomerGroupService,
        private dialog: MatDialog,
        private authService: AuthService) {
    }

    ngOnInit() {
        this.loading = true;

        this.initData();
        this.init = false;
    }

    initData() {


        this.subscriptions.add(this.authService.refreshCurrentUser().subscribe());

        this.subscriptions.add(this.authService.currentUserValue$()
            .subscribe(user => {
                this.currentUser = user;
            }));
    }



    async updateItem() {
        this.dialog.open(ProfilCreateUpdateDeleteComponent, {
            data: {
                defaults: this.currentUser,
                mode: CrudModeEnum.Update
            }
        }).afterClosed().subscribe((userProfile: UserProfileModel) => {
            if (userProfile) {
                this.initData();
            }
        });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
