<ng-container *ngIf='!isVerified'>
    <div mat-dialog-title fxLayout='row' fxLayoutAlign='space-between center'>
        <div>Supprimer un Établissement</div>
        <button type='button' mat-icon-button (click)='close()' tabindex='-1'>
            <mat-icon [icIcon]='icClose'></mat-icon>
        </button>
    </div>

    <mat-dialog-content>
        <p>Avant de supprimer un établissement, il est important de vérifier ses dépendances.</p>
        <p>Souhaitez-vous lancer cette vérification ?</p>
    </mat-dialog-content>

    <mat-dialog-actions align='end'>
        <button mat-button (click)='close()'>Annuler</button>
        <button mat-button color='primary' [loading]='loading' (click)='checkDependencies()'>Vérifier</button>
    </mat-dialog-actions>
</ng-container>


<ng-container *ngIf='isVerified && hasDependencies'>

    <div mat-dialog-title fxLayout='row' fxLayoutAlign='space-between center'>
        <div>Oops, impossible de supprimer cet établissement</div>
        <button type='button' mat-icon-button (click)='close()' tabindex='-1'>
            <mat-icon [icIcon]='icClose'></mat-icon>
        </button>
    </div>

    <mat-dialog-content>
        <section class="card p-5 mb-4">

            <p *ngIf="dependencies.buildings.length > 0"
               class="font-semibold text-lg text-gray-700 mt-4 mb-2">
                Bâtiments
                <span class="item-badge" fxFlex="none">
                    {{ dependencies.buildings.length }}
                </span>
            </p>

            <p>{{ dependencies.buildings }}</p>


            <p *ngIf="dependencies.equipments.length > 0"
               class="font-semibold text-lg text-gray-700 mt-4 mb-2">
                Équipements
                <span class="item-badge" fxFlex="none">
                                {{ dependencies.equipments.length }}
                            </span>
            </p>

            <p>{{ dependencies.equipments }}</p>

            <p *ngIf="dependencies.orders.length > 0"
               class="font-semibold text-lg text-gray-700 mt-4 mb-2">
                Commandes
                <span class="item-badge" fxFlex="none">
                                {{ dependencies.orders.length }}
                            </span>
            </p>

            <p>{{ dependencies.orders }}</p>


            <p *ngIf="dependencies.tickets.length > 0"
               class="font-semibold text-lg text-gray-700 mt-4 mb-2">
                Tickets
                <span class="item-badge" fxFlex="none">
                                {{ dependencies.tickets.length }}
                            </span>
            </p>

            <p>{{ dependencies.tickets }}</p>

            <p *ngIf="dependencies.quotes.length > 0"
               class="font-semibold text-lg text-gray-700 mt-4 mb-2">
                Devis
                <span class="item-badge" fxFlex="none">
                                {{ dependencies.quotes.length }}
                            </span>
            </p>

            <p>{{ dependencies.quotes }}</p>

        </section>
    </mat-dialog-content>


    <mat-dialog-actions align='end'>
        <button mat-button (click)='close()'>Fermer</button>
    </mat-dialog-actions>
</ng-container>


<ng-container *ngIf='isVerified && !hasDependencies'>
    <div mat-dialog-title fxLayout='row' fxLayoutAlign='space-between center'>
        <div>Confirmer la suppression.</div>
        <button type='button' mat-icon-button (click)='close()' tabindex='-1'>
            <mat-icon [icIcon]='icClose'></mat-icon>
        </button>
    </div>

    <mat-dialog-content>
        <p>Super, aucune dépendance n'a été trouvée. Les contacts internes et le périmètre des engagements seront impactés</p>
        <p>Souhaitez-vous confirmer l'archivage ?</p>
    </mat-dialog-content>

    <mat-dialog-actions align='end'>
        <button mat-button (click)='close()'>Annuler</button>
        <button mat-button color='primary' [loading]='loading' (click)='archiveItem()'>Archiver</button>
    </mat-dialog-actions>
</ng-container>
