import { Component, Input, OnInit } from '@angular/core';
import {TicketTypeEnum} from "../../enums/ticket-type.enum";

@Component({
    selector: 'tab-badge',
    templateUrl: 'tab-badge.component.html',
    styles: [`
        .item-badge {
            border-radius: 999px;
            font-size: 11px;
            line-height: 20px;
            padding: 3px 8px 3px 8px;
            text-align: center;
        }
    `]
})

export class TabBadgeComponent implements OnInit {

    @Input()
    value: string;

    @Input()
    status: string;

    @Input()
    icon: string;

    color: string;
    background: string;

    constructor() {
    }

    ngOnInit() {

        this.color = '#ffffff';
        this.background = '#ffffff';

        switch (this.status) {
            case 'GREY':
                this.background = 'rgb(35 43 62)'; //'#ff2d2d';
                break;
            case 'product':
                this.background = '#e63315'; //'#ff2d2d';
                break;
            case 'urgent':
                this.background = '#e63315'; //'#ff2d2d';
                break;
            case TicketTypeEnum.REQUIREMENT:
                this.background = '#f3573e'; //'#ff2d2d';
                break;
            case 'prestation':
                this.background = '#0372E5'; //'#ff2d2d';
                break;
            case TicketTypeEnum.INCIDENT:
                this.background = '#0372E5'; //'#ff2d2d';
                break;
            case 'standard':
                this.background = '#0372E5'; //'#ff2d2d';
                break;
            case 'TO_REVIEW':
                this.background = '#6c3cff'; //'#ff00A5';
                break;
            case 'TO_CONFIRM':
                this.background = '#6c3cff'; //'#ff00A5';
                break;
            case 'TO_EXPORT':
                this.background = '#ab9f18'; //'#ff00A5';
                break;
            case 'equipment':
                this.background = '#33b679'; //#2C9FB9 //'#E824B5'; //#f4511e //'#8B36cb';
                break;
            case 'BATCHED':
                this.background = '#33b679'; //'#1d82ff';
                break;
            case 'QUESTION':
                this.background = 'orange'; //'#F0B40B'; //'#f6bf26'; //'#cd8d18';
                break;
            case 'ERROR':
                this.background = '#d50000'; //'#949494';
                break;
            case 'REJECTED':
                this.background = '#616161'; //'#949494';
                break;
            case 'EXPORTED':
                this.background = '#33b679'; //'#2aa00c';
                break;
            case 'SPLIT':
                this.background = '#AF27A9';
                break;
            case 'COUNTED':
                this.background = '#0b8043'; //'#2aa00c';
                break;
        }
    }
}
