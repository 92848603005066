import {Component, OnDestroy, OnInit} from '@angular/core';
import {FlatTreeControl, NestedTreeControl} from '@angular/cdk/tree';
import {CollectionViewer, DataSource, SelectionChange} from '@angular/cdk/collections';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {first, map, take} from 'rxjs/operators';
import {BehaviorSubject, merge, Observable, Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {FileSaverService} from 'ngx-filesaver';
import {CategoryModel} from "../../../../../../core/models/category.model";
import {CategoryService} from "../../../../settings/category/category.service";
import {SnackbarService} from "../../../../../../core/services/snackbar.service";
import {UserProfileService} from "../../user-profile.service";
import {AuthService} from "../../../../../../core/services/auth.service";
import {ENUM_PERMISSIONS} from "../../../../../../core/enums/permission.enum";

export class DynamicDataSource implements DataSource<CategoryModel> {
    dataChange = new BehaviorSubject<CategoryModel[]>([]);

    get data(): CategoryModel[] {
        return this.dataChange.value;
    }

    set data(value: CategoryModel[]) {
        this._treeControl.dataNodes = value;
        this.dataChange.next(value);
    }

    constructor(
        private _treeControl: FlatTreeControl<CategoryModel>,
        private _database: CategoryService,
        private dialog: MatDialog,
    ) {
    }

    connect(collectionViewer: CollectionViewer): Observable<CategoryModel[]> {
        this._treeControl.expansionModel.changed.subscribe(change => {
            if (
                (change as SelectionChange<CategoryModel>).added ||
                (change as SelectionChange<CategoryModel>).removed
            ) {
                this.handleTreeControl(change as SelectionChange<CategoryModel>);
            }
        });

        return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
    }

    disconnect(collectionViewer: CollectionViewer): void {
    }

    /** Handle expand/collapse behaviors */
    handleTreeControl(change: SelectionChange<CategoryModel>) {
        if (change.added) {
            change.added.forEach(node => this.toggleNode(node, true));
        }
        if (change.removed) {
            change.removed
                .slice()
                .reverse()
                .forEach(node => this.toggleNode(node, false));
        }
    }

    /**
     * Toggle the node, remove from display list
     */
    toggleNode(node: CategoryModel, expand: boolean) {
        node.isLoading = true;
        this._database.findByLevel(node.level + 1, node.children.map(x => x._id)).subscribe(res => {
            const children = this._database.getChildren(node);
            const index = this.data.indexOf(node);
            if (!children || index < 0) {
                // If no children, or cannot find the node, no op
                node.isLoading = false;
                return;
            }
            if (expand) {
                this.data.splice(index + 1, 0, ...children);
            } else {
                let count = 0;
                for (
                    let i = index + 1;
                    i < this.data.length && this.data[i].level > node.level;
                    i++, count++
                ) {
                }
                this.data.splice(index + 1, count);
            }

            // notify the change
            this.dataChange.next(this.data);
            node.isLoading = false;
        });
    }
}

@Component({
    selector: 'vex-user-profile-details-technical-assignation',
    templateUrl: './user-profile-details-technical-assignation.component.html',
    styleUrls: ['./user-profile-details-technical-assignation.component.scss']
})
export class UserProfileDetailsTechnicalAssignationComponent implements OnInit, OnDestroy {

    treeControl: NestedTreeControl<CategoryModel>;
    userProfile: any = null;

    dataSource: MatTreeNestedDataSource<CategoryModel>;

    getLevel = (node: CategoryModel) => node.level;

    isExpandable = (node: CategoryModel) => node.children?.length > 0;

    hasChild = (_: number, _nodeData: CategoryModel) => _nodeData.children?.length > 0;

    loading = false;
    loadingExport = false;
    subscription = new Subscription();
    init = true;

    canInputContactPermiter: boolean = false;

    constructor(public service: CategoryService,
                private dialog: MatDialog,
                private fileSaver: FileSaverService,
                private snackbarService: SnackbarService,
                private userProfileService: UserProfileService,
                private authService: AuthService) {
    }

    ngOnInit(): void {
        this.initData();
    }

    initData(): void {
        this.loading = true;
        this.treeControl = new NestedTreeControl<CategoryModel>(node => node.children);
        this.dataSource = new MatTreeNestedDataSource<CategoryModel>();
        this.subscription.add(this.userProfileService.entity$.subscribe(userProfile => {

            this.userProfile = userProfile;
            this.service.findByLevel(0).subscribe(res => {

                // map selected field to true for all res.data


                res.data.map(x => {
                    if (userProfile?.technicalCategories?.find(y => y._id === x._id)) {
                        x.selected = true
                    }
                    // if a userProfile.technicalCategories have a parent === x._id, then x.ideterminate = true
                    if (userProfile?.technicalCategories?.find(y => y.parents?.find(z => z.parent === x._id))) {
                        x.indeterminate = true
                    }
                })
                if (this.init) {
                    this.dataSource.data = res.data;
                    this.init = false;
                }

                this.loading = false;
            });
        }))

        this.authService.getCurrentUserPermissions$().subscribe(permissions => {
            this.canInputContactPermiter = permissions.includes(ENUM_PERMISSIONS.INPUT_CP_INTERNAL_CONTACT_PERIMETER)
        })
    }

    async openLevel(node) {
        if (this.treeControl.isExpanded(node) && !node.expandedOnce) {
            node.isLoading = true;
            node.expandedOnce = true;

            this.service.findByLevel(node.level + 1, node.children.map(x => x._id)).subscribe(res => {
                let firstLevelParent = node.level === 0 ? res.data.find(x => x._id === node._id) : res.data.find(x => x._id === node.parents.find(parent => parent.level === 0).parent)


                if (firstLevelParent.selected) {
                    this.recusivelySetSelectedToAllChild(firstLevelParent)
                } else if (firstLevelParent.indeterminate) {
                    this.recursivelySetStateToAllChild(firstLevelParent, this.userProfile)

                }

                this.dataSource.data = [];
                this.dataSource.data = res.data;
                node.isLoading = false;
            });
        }
    }


    private recusivelySetSelectedToAllChild(categoryStart: CategoryModel, toggleValue: boolean = true) {

        if (!categoryStart.children) {
            return
        }

        if (categoryStart.selected === toggleValue) {
            categoryStart.children?.map(child => child.selected = toggleValue)
            categoryStart.children?.map(child => child.indeterminate = false)
            categoryStart.children?.map(child => this.recusivelySetSelectedToAllChild(child, toggleValue))
        }


    }

    private recursivelySetStateToAllChild(categoryStart: CategoryModel, userProfile: any) {

        if (!categoryStart?.children) {
            return
        }

        if (userProfile.technicalCategories.find(x => x._id === categoryStart._id)) {
            categoryStart.selected = true
            categoryStart.indeterminate = false
            this.recusivelySetSelectedToAllChild(categoryStart, true)
        } else {
            categoryStart.children?.map(child => {
                if (userProfile?.technicalCategories?.find(y => y.parents.find(z => z.parent === child._id))) {
                    child.indeterminate = true
                }

            })
            categoryStart.children?.map(child => this.recursivelySetStateToAllChild(child, userProfile))
        }

    }

    // private recursivelyGetFirstSelectedNode(categoryStart: CategoryModel, userProfile: any): CategoryModel {
    //     if (!categoryStart.children) {
    //         return
    //     }
    //
    //     categoryStart.children?.map(child => {
    //
    //         if (userProfile.technicalCategories.find(x => x._id === child._id)) {
    //
    //             return categoryStart
    //             // child.selected = toggleValue
    //             // child.indeterminate = false
    //             //
    //             //
    //             // categoryStart.children.map(child => this.recusivelySetSelectedToAllChild(child, toggleValue))
    //
    //         } else {
    //             return this.recursivelyGetFirstSelectedNode(child, userProfile)
    //         }
    //     })
    // }


    async itemToggle(toogleValue, target) {
        // get id from route params
        // this.userProfileService.entity$.subscribe(res => {

        this.userProfileService.assignCategories(this.userProfile._id, target, toogleValue).subscribe(async res => {
            this.userProfile = res.data;
            if (toogleValue === true) {
                let firstLevelParent = null

                if (target.level != 0) {
                    firstLevelParent = this.dataSource.data.find(x => x._id === target.parents.find(parent => parent.level === 0).parent)
                    if (firstLevelParent.children.filter(y => y._id != target._id).every(x => x.selected)) {
                        firstLevelParent.selected = true
                        firstLevelParent.indeterminate = false
                    } else {
                        //
                        //
                        firstLevelParent.indeterminate = true
                        firstLevelParent.selected = false
                    }

                } else {
                    firstLevelParent = this.dataSource.data.find(x => x._id === target._id)
                    firstLevelParent.selected = true
                    firstLevelParent.indeterminate = false

                }

                if (firstLevelParent.selected) {
                    this.recusivelySetSelectedToAllChild(firstLevelParent, true)
                } else if (firstLevelParent.indeterminate) {
                    this.recursivelySetStateToAllChild(firstLevelParent, res.data)
                }
            } else {
                let firstLevelParent = null

                //TODO
                if (target.level === 0) {
                    firstLevelParent = this.dataSource.data.find(x => x._id === target._id)
                    firstLevelParent.selected = false
                    firstLevelParent.indeterminate = false
                    this.recusivelySetSelectedToAllChild(firstLevelParent, false)


                } else {

                    // trouver le permier parent existant
                    let firstParent = this.dataSource.data.find(x => x._id === target.parents.find(parent => parent.level === 0).parent)

                    if (firstParent.children.filter(y => y._id != target._id).every(x => !x.selected && !x.indeterminate)) {

                        firstParent.selected = false
                        firstParent.indeterminate = false
                    } else {
                        firstParent.indeterminate = true
                        firstParent.selected = false

                    }

                    if (firstParent) {
                        await this.recursivelyUnselect(firstParent, target, 1)
                        await this.recursivelySetStateToAllParent(firstParent, target)
                        if (firstParent.children.filter(y => y._id != target._id).every(x => !x.selected && !x.indeterminate)) {

                            firstParent.selected = false
                            firstParent.indeterminate = false
                        }
                    }


                }

            }
        })
        // })


    }

    recursivelySetStateToAllParent(categoryStart: CategoryModel, target: any) {

        categoryStart.children.forEach(child => {
            if (child._id === target._id) {
                if (child.children.filter(y => y._id != target._id).every(x => !x.selected && !x.indeterminate)) {
                    child.indeterminate = false
                    child.selected = false
                    return
                }

            } else {
                this.recursivelySetStateToAllParent(child, target)

            }
        })
    }


    recursivelyUnselect(categoryStart: CategoryModel, target: any, level: number = 1) {
        if (!categoryStart.children) {
            return
        }


        if (categoryStart.children.filter(y => y._id != target._id).every(x => !x.selected && !x.indeterminate)) {
            categoryStart.indeterminate = false
            categoryStart.selected = false
            this.recusivelySetSelectedToAllChild(categoryStart, false)
            return
        }

        //
        if (target.level == categoryStart.level) {
            categoryStart.indeterminate = false
            categoryStart.selected = false
        }

        categoryStart.children?.map(child => {
            if (child._id === target.parents.find(parent => parent.level === level)?.parent) {
                child.indeterminate = true
                child.selected = false
                this.recursivelyUnselect(child, target, level + 1)
            } else if (child._id === target._id) {
                child.indeterminate = false
                child.selected = false
                this.recusivelySetSelectedToAllChild(child, false)

            }
        })

    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
