import { Component } from '@angular/core';
import { PrestationModel } from 'src/app/core/models/prestation.model';
import { PrestationService } from './prestation.service';
import { MatDialog } from '@angular/material/dialog';
import {
    PrestationCreateUpdateDeleteComponent
} from './prestation-create-update-delete/prestation-create-update-delete.component';
import { FeiColumn } from 'src/app/core/base/interfaces/fei-column.interface';
import { DataTableBase } from 'src/app/core/base/components/data-table-base/data-table-base';
import { CrudModeEnum } from 'src/app/core/base/enum/crud-mode.enum';
import { ENUM_PERMISSIONS } from '../../../../core/enums/permission.enum';
import { AuthService } from '../../../../core/services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { ProductModel } from '../../../../core/models/product.model';
import { Router } from '@angular/router';
import { TvaModel } from '../../../../core/models/tva.model';
import { TvaService } from '../../settings/tva/tva.service';

@Component({
    selector: 'vex-prestation',
    templateUrl: './prestation.component.html',
    styleUrls: ['./prestation.component.scss']
})
export class PrestationComponent extends DataTableBase<PrestationModel> {
    ENUM_PERMISSION = ENUM_PERMISSIONS;

    feisColumns: Array<FeiColumn> = [
        {label: 'Type', column: 'quoteId', type: 'custom'},
        {
            label: 'Réf. Fournisseur',
            column: 'reference',
            propertyDisplay: null,
            canUpdate: false,
            canDelete: false,
            type: 'custom'
        },
        { label: 'Fournisseurs', column: 'supplier', propertyDisplay: 'supplier.label' },
        {
            label: 'Prestation générique',
            column: 'genericPrestation',
            propertyDisplay: 'genericPrestation.label',
            canUpdate: false,
            canDelete: false
        },
        {label: 'Libellé', column: 'label', propertyDisplay: null, canUpdate: false, canDelete: false, type: 'custom'},
        {label: 'Prix H.T.', column: 'priceHT', additionalDisplay: ' €', type: 'number'},
        {label: 'Taux de TVA', column: 'tva', type: 'custom'},
        {label: 'Prix T.T.C.', column: 'priceTTC', additionalDisplay: ' €', type: 'number'},
        {
            label: 'Unité d\'achat',
            column: 'purchaseUnit',
            propertyDisplay: null
        },
        {
            label: 'Nombre d\'interventions/heures',
            column: 'purchaseValue',
            propertyDisplay: null
        },
        {label: 'Disponible au catalogue', column: 'isAvailable', type: 'boolean'},
        // { label: 'Catégorie', column: 'category', propertyDisplay: 'category.label', type: 'category' },
        //     {label: '', column: 'actions', canUpdate : this.authService.hasPermission(ENUM_PERMISSIONS.UPDATE_FM_REF_PURCHASE_SERVICE_INFO)}
        {label: '', column: 'actions', canArchive: this.authService.hasPermission(ENUM_PERMISSIONS.DELETE_SERVICE), canUpdate: this.authService.hasPermission(ENUM_PERMISSIONS.UPDATE_SERVICE)}
    ];

    canShowArchive = true;
    actionText = 'Expiré';
    showComment;
    showPreviousReference;


    constructor(public service: PrestationService, private dialog: MatDialog,
                private router: Router,
                private authService: AuthService,
                private tvaService: TvaService
    ) {
        super();
        this.displayedColumns = this.feisColumns.map(x => x.column);
    }

    initData() {

        this.filters.isWithRecurrence = true;
        const items = this.service
            .findAll(this.pageIndex, this.pageSize, this.sortField, this.sortOrder, this.search, { ...this.filters, requiredFields: ['*'] })
            .subscribe(res => {
                res.data = res.data.map((entity: any) => ({
                    ...entity,
                    quoteId: entity.quoteId ? entity.quoteId : null
                }));

                this.dataSource = new MatTableDataSource<PrestationModel>(res.data);
                this.count = res.totalData;
                this.loading = false;
            });

        this.subscription.add(items);
    }

    createItem(): void {
        this.dialog.open(PrestationCreateUpdateDeleteComponent)
            .afterClosed()
            .subscribe((prestation: PrestationModel) => {
                if (prestation) {
                    //      this.snackbarService.warning('Cette nouvelle référence sera accessible au catalogue après validation d’un administrateur.');
                    this.initData();
                }
            });
    }

    hasPermission() {
        return this.authService.isReferentEngagement();
    }

    updateItem(element: PrestationModel): void {
        this.dialog.open(PrestationCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                mode: CrudModeEnum.Update
            }
        }).afterClosed().subscribe((prestation: PrestationModel) => {
            if (prestation) {
                this.initData();
            }
        });
    }

    rowClickItem(element: PrestationModel): void {
        if (this.hasPermission()) {
            this.updateItem(element);
        } else {
            this.readItem(element);
        }
    }

    onShowArchived(event) {
        this.isArchive = event;
        this.isExpired = event;
        this.filters = {...this.filters, isArchive: event, isExpired: event};
        this.initData();

        this.changeCanUpdateStatus(event);
    }

    deleteItem(element: PrestationModel) {
        this.dialog.open(PrestationCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                name: `${element.technicalIdentifier}`,
                mode: CrudModeEnum.Delete
            }
        }).afterClosed().subscribe((prestation: PrestationModel) => {
            if (prestation) {
                this.initData();
            }
        });
    }

    archiveItem(element: PrestationModel) {
        this.dialog.open(PrestationCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                name: `${element.technicalIdentifier}`,
                mode: CrudModeEnum.Archive
            }
        }).afterClosed().subscribe((prestation: PrestationModel) => {
            if (prestation) {
                this.initData();
            }
        });
    }

    readItem(element: any) {
        this.dialog.open(PrestationCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                name: `${element.reference}`,
                mode: CrudModeEnum.Read
            }
        }).afterClosed().subscribe((prestation: PrestationModel) => {
            if (prestation) {
                this.initData();
            }
        });
    }

    importItem() {
        this.dialog.open(PrestationCreateUpdateDeleteComponent, {
            data: {
                mode: CrudModeEnum.Import
            }
        }).afterClosed().subscribe((prestation: PrestationModel) => {
            if (prestation) {
                this.initData();
            }
        });
    }

    getTvaRate(tva: TvaModel) {
        return `${this.tvaService.getRateByDate(tva).rate.toFixed(2)}%`;
    }

    redirectToElement(element: ProductModel) {
        if (element.quoteId) {
            this.router.navigateByUrl('/achat/quote/' + element.quoteId).then();
            return;
        } else {
            this.router.navigateByUrl('/management/engagement/' + element.contractId).then();
        }
    }

    changeCanUpdateStatus(event) {
        this.feisColumns = this.feisColumns.map(x => {
            if (x.column === 'actions') {
                x.canUpdate = !event;
            }
            return x;
        });
    }
}
