import { Component, OnInit } from '@angular/core';
import {GedBaseComponent, IGedBaseComponent} from "../../../../../../core/base/components/ged-base/ged-base.component";
import {AuthService} from "../../../../../../core/services/auth.service";
import {GedService} from "../../../../admin/playground/docuware-playground/ged.service";
import {MatDialog} from "@angular/material/dialog";
import {FileSaverService} from "ngx-filesaver";
import {GED_CONFIG} from "../../../../../../core/enums/ged-type.enum";
import {EstablishmentService} from "../../establishment.service";
import {FeiColumn} from "../../../../../../core/base/interfaces/fei-column.interface";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {
  DocumentPreviewModalComponent
} from "../../../../../../core/base/components/document-preview-modal/document-preview-modal.component";
import {EstablishmentModel} from "../../../../../../core/models/establishment.model";

@Component({
  selector: 'vex-establishment-details-documents',
  templateUrl: './establishment-details-documents.component.html',
  styleUrls: ['./establishment-details-documents.component.scss']
})
export class EstablishmentDetailsDocumentsComponent extends GedBaseComponent<EstablishmentModel> implements IGedBaseComponent {

  documentTypes = [
    'Attestation d\'assurance',
    'Identification entreprise',
    'RIB',
    'Plan',
    'Autres'
  ];

  feisColumns: Array<FeiColumn> = [
    {label: 'Nom du fichier', column: 'NOM_DU_DOCUMENT'},
    {label: 'Type de document', column: 'DOCUMENT_TYPE'},
    {label: 'Nombre de pages', column: 'DWPAGECOUNT'},
    {label: 'Date du dépôt', column: 'DATE_DU_DEPOT', type: 'date'},
    {label: '', column: 'btnToPrev', type: 'custom'},
    {
      label: '',
      column: 'actions',
      canUpdate: false,
      canDelete: true,
      canDownload: true,
    },
  ];
  displayedColumns = [];
  loadingPreview = false;

  constructor(
      public service: EstablishmentService,
      private authService: AuthService,
      private gedService: GedService,
      public dialog: MatDialog,
      public fileSaver: FileSaverService
  ) {
    super(GED_CONFIG.establishment, gedService, service, dialog, fileSaver);
    this.displayedColumns = this.feisColumns.map(x => x.column);
  }

  initForm() {
    this.form = new FormGroup({
      documentType: new FormControl(null, [Validators.required]),
      predikId: new FormControl(this.id, [Validators.required]),
    });

    this.loading = false;
  }

  afterUpload() {
    this.service.findById(this.id).subscribe();
  }

  afterDelete() {
    this.service.findById(this.id).subscribe();
  }

}
