<!--<mat-progress-bar *ngIf='loading' mode="indeterminate" color="primary"></mat-progress-bar>-->
<div class="px-gutter py-gutter">
    <div fxLayout='row' fxLayoutAlign='start center' fxLayoutGap='16px'>
        <div class='h-16 flex items-center custor-pointer'>
            <mat-icon class='custor-pointer' (click)="previousRoute()" color='primary'>arrow_back</mat-icon>
        </div>
        <div class='h-16 flex items-center w-full'>
            <vex-skeleton *ngIf='loading' class='w-1/5' heigthTailwind='h-10'></vex-skeleton>
            <h1 @fadeInRight *ngIf='!loading'
                class='headline m-0'>{{ object?.firstName | titlecase }} {{ object?.lastName | uppercase }}</h1>
        </div>
    </div>
    <div class="card overflow-hidden">
        <div class="z-10 relative -mt-16 flex items-center">
            <div class="max-w-full flex-auto sm:rtl:mr-6">
                <div class="h-16 flex items-center">
                    <!--                    <h1 @fadeInRight class="headline m-0"> cc</h1>-->
                </div>

                <nav class="vex-tabs vex-tabs-dense border-0" mat-tab-nav-bar>
                    <a #rla="routerLinkActive"
                       *ngFor="let link of getDisplayableTabs()"
                       [active]="rla.isActive"
                       [disabled]="link.disabled"
                       [routerLink]="link.route"
                       mat-tab-link
                       queryParamsHandling="preserve"
                       [routerLinkActiveOptions]="link.routerLinkActiveOptions || { exact: false }"
                       routerLinkActive>
                        {{ link.label }}
                    </a>
                </nav>
            </div>
        </div>
    </div>

    <router-outlet></router-outlet>
</div>
