import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { EstablishmentService } from '../../establishment/establishment.service';
import { FormControl, FormGroup } from '@angular/forms';
import { EstablishmentModel } from '../../../../../core/models/establishment.model';
import { Observable } from 'rxjs';
import { SupplierService } from '../../supplier/supplier.service';
import { SupplierModel } from '../../../../../core/models/supplier.model';
import {
    GenericEngagementFormatEnum,
    genericEngagementTranslationObject
} from '../../../../../core/enums/generic-engagement-format.enum';
import { ContractStatusEnum, contractStatusTranslationObject } from '../../../../../core/enums/contract-status.enum';
import { AuthService } from '../../../../../core/services/auth.service';
import { UserProfileModel } from '../../../../../core/models/user-profile.model';
import { UserProfileService } from '../../user-profile/user-profile.service';
import { ContractService } from '../contract.service';
import { ContractModel } from '../../../../../core/models/contract.model';
import {
    SidenavBaseFilterComponent
} from '../../../../../core/base/components/sidenav/base-filter/sidenav-base-filter.component';
import { ActivityTypeService } from '../../../settings/activity-type/activity-type.service';
import { ActivityTypeModel } from '../../../../../core/models/activity-type.model';

@Component({
    selector: 'contract-filter',
    templateUrl: './contract-filter.component.html',
    styleUrls: ['./contract-filter.component.scss']
})
export class ContractFilterComponent extends SidenavBaseFilterComponent {

    genericEngagementFormat = Object.values(GenericEngagementFormatEnum).map(g => genericEngagementTranslationObject(g));
    businessReferentUsers$: Observable<UserProfileModel[]>;
    contractStatusOptions = [];
    establishments$: Observable<EstablishmentModel[]>;
    suppliers$: Observable<SupplierModel[]>;
    supplierContractRef$: Observable<ContractModel[]>;
    activities$: Observable<ActivityTypeModel[]>;

    initialize(): void {
        this.autocompleteEstablishment('');
        this.autocompleteSupplier('');
        this.autocompleteBusinessReferentUsers('');
        this.autocompleteContractSupplierContractRef('');
        this.autocompleteActivity('');
    }

    constructor(
        public establishmentService: EstablishmentService,
        public suppliersService: SupplierService,
        public authService: AuthService,
        public userProfileService: UserProfileService,
        public contractService: ContractService,
        public activityService: ActivityTypeService,
    ) {
        super();

        this.addForm('standardForm', new FormGroup({
            technicalIdentifier: new FormControl(),
            establishments: new FormControl(),
            suppliers: new FormControl(),
            genericEngagementFormat: new FormControl(),
            supplierContractRef: new FormControl(),
            status: new FormControl(),
            businessReferentUsers: new FormControl(),
            activities: new FormControl(),
        }));

        this.addForm('applicationPeriodForm', new FormGroup({
            comparison: new FormControl(),
            min: new FormControl(),
            max: new FormControl(),
        }));

        this.addForm('endApplicationPeriodForm', new FormGroup({
            comparison: new FormControl(),
            min: new FormControl(),
            max: new FormControl(),
        }));

        this.addForm('noticePeriodForm', new FormGroup({
            comparison: new FormControl(),
            min: new FormControl(),
            max: new FormControl(),
        }));

        this.addForm('revisionPeriodForm', new FormGroup({
            comparison: new FormControl(),
            min: new FormControl(),
            max: new FormControl(),
        }));

        const allStatuses = Object.values(ContractStatusEnum).map(status => contractStatusTranslationObject(status));

        this.contractStatusOptions = this.authService.isReferentMetier()
            ? allStatuses
            : allStatuses.filter(status => status.value !== ContractStatusEnum.DRAFT);
    }

    buildCustomFilters() {
        const {
            suppliers,
            supplierContractRef,
            status,
            businessReferentUsers,
            genericEngagementFormat,
            ...rest
        } = this.forms.standardForm.getRawValue();

        return {
            genericEngagementFormat: genericEngagementFormat?.map(x => x.value),
            suppliers: suppliers ? suppliers.map(x => x._id) : [],
            referentUserIds: businessReferentUsers ? businessReferentUsers.map(x => x._id) : [],
            supplierContractRef: supplierContractRef ? supplierContractRef.map(x => x.supplierContractReference) : [],
            status: status ? status.map(s => s.value) : null,
            ...rest,
            startValidityDate: {...this.forms.applicationPeriodForm.getRawValue()},
            endValidityDate: {...this.forms.endApplicationPeriodForm.getRawValue()},
            noticeDate: {...this.forms.noticePeriodForm.getRawValue()},
            revisionDate: {...this.forms.revisionPeriodForm.getRawValue()},
        };
    }

    autocompleteEstablishment(search: string = '') {
        this.establishments$ = this.establishmentService
            .findAll(null, 10000, 'label', 'asc', search, { onlyMine: true})
            .pipe(map(x => x.data));
    }

    autocompleteSupplier(search: string = '') {
        this.suppliers$ = this.suppliersService
            .findAll(null, 10000, 'label', 'asc', search)
            .pipe(map(x => x.data));
    }

    autocompleteBusinessReferentUsers(search: string = '') {
        this.businessReferentUsers$ = this.userProfileService
            .findAll(null, 10000, 'lastName', 'asc', search, {isBusinessReferent: true})
            .pipe(map(x => x.data));
    }

    autocompleteContractSupplierContractRef(search: string = '') {
        this.supplierContractRef$ = this.contractService
            .findAll(null, 10000, 'supplierContractReference', 'asc', search, {supplierContractRefOnly: true})
            .pipe(map(x => x.data));
    }

    autocompleteActivity(search: string = '') {
        this.activities$ = this.activityService
            .findAll(null, 10000, 'label', 'asc', search)
            .pipe(map(x => x.data));
    }
}
