<form [formGroup]='form'>
    <base-form-header [mode]='null' createLabel="Ajouter une date de dénonciation"></base-form-header>

    <mat-dialog-content fxLayout="column">
        <mat-form-field appearance="fill">
            <mat-label>Date de dénonciation</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="denunciationDate"
                   [min]="minDenunciationDate"
                   [max]="maxDenunciationDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>

            <!-- Display a custom error message for the validation -->
<!--            <mat-error *ngIf="form.get('denunciationDate').hasError('customValidation')">-->
<!--                {{ form.get('denunciationDate').getError('customValidation') }}-->
<!--            </mat-error>-->

        </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions align='end'>
        <button mat-button color='primary' [loading]='loading' (click)='addItem()'>VALIDER</button>
    </mat-dialog-actions>
</form>

