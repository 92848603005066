import {GenericEnergyIdModel, GenericIdEnergyMinifiedModel} from './generic-energy-id.model';
import { EstablishmentModel } from './establishment.model';
import { BuildingModel } from './building.model';


export class EnergyIdModel {
    _id?: string;
    genericEnergyId: GenericEnergyIdModel;
    energyIdId: string;
   // establishment: EstablishmentModel;
   // building: BuildingModel;
    buildings: BuildingModel[];
    establishments: EstablishmentModel[] | null;
    favoriteBuilding: BuildingModel;

    isUserEnergyId?: boolean;
    isDelete?: boolean;
    isArchive?: boolean;
    createdAt?: Date;
    updatedAt?: Date;

    customDisplay?: string;
}

export class EnergyIdAsTreeModel {
    _id: string;
    energyIdId: string;
    genericEnergyId: GenericIdEnergyMinifiedModel;
}

export const convertToBuildingRelationEnergyId = (data: any) => {
    return {
        _id: data._id,
        technicalIdentifier: data.technicalIdentifier,
        label: data.label,
        establishments: data.establishments,
    }
}