import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  DocumentElement,
  GedService
} from '../../../../pages/application/admin/playground/docuware-playground/ged.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { FileSaverService } from 'ngx-filesaver';

@Component({
  selector: 'vex-drop-zone',
  templateUrl: './drop-zone.component.html',
  styleUrls: ['./drop-zone.component.scss']
})
export class DropZoneComponent implements OnInit {

  focusDropzone = false;
  @Input() files: DocumentElement[] = [];

  @Output() onFileDropped = new EventEmitter<any>();

  constructor(
      private snackbarService: SnackbarService,
      private _gedService: GedService,
      public fileSaver: FileSaverService
  ) { }

  ngOnInit(): void {

  }

  fileDropped($event: any) {
    for (const item of $event) {
      this.files.push(this.prepareDocument(item));
      // if (item.size > 10000000) {
      //   this.snackbarService.danger(`Le fichier ${item.name} doit faire moins de 10Mo.`);
      // } else {
      //   this.files.push(this.prepareDocument(item));
      // }
    }
    this.onFileDropped.emit(this.files);
  }

  onFileSelected($event: any) {
    this.fileDropped($event.target.files);
  }

  deleteFileInList(index) {
    this.files.splice(index, 1);
  }

  private prepareDocument(file: File) {
    return {
      id: this.uuidv4(),
      dateUpload: new Date().toISOString(),
      originalFileName: file.name,
      size: file.size,
      contentType: file.type,
      status: 'IMPORTING',
      _file: file,
      progress: new BehaviorSubject<number>(0),
      uploadError: '',
      errorType: ''
    };
  }

  private uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  downloadDocument(id) {
    this._gedService.downloadDocument(id)
        .subscribe((res: any) => {
          this.fileSaver.save(res.body, res.headers.get('filename'));
        });
  }
}
