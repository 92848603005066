import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { MetrikStatusModuleEnum, MetrikStatusTypeEnum } from '../../../enums/metrik-status.enum';
import { GenericMetrikStatusModel } from '../../../models/generic-metrik-status.model';
import {
  GenericMetrikStatusService
} from '../../../../pages/application/settings/generic-metrik-status/generic-metrik-status.service';

@Component({
  selector: 'vex-metrik-status',
  templateUrl: './metrik-status.component.html',
  styleUrls: [
    './metrik-status.component.scss',
    '../../../../../../node_modules/quill/dist/quill.snow.css',
    '../../../../../@vex/styles/partials/plugins/_quill.scss'
  ],
  encapsulation: ViewEncapsulation.None,
})
export class MetrikStatusComponent implements OnInit, OnDestroy {

  public genericMetrikStatus$: Observable<GenericMetrikStatusModel[]>;
  public loadingSave = false;
  public minEndDate = new Date();
  protected subscription = new Subscription();

  @Input() form: FormGroup;
  @Input() module: MetrikStatusModuleEnum;
  @Input() isOver = false;

  // Configuration personnalisée pour l'éditeur Quill
  customModule = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ align: [] }],
      ['clean'],
      ['link']
    ]
  };

  constructor(
      protected genericMetrikStatusService: GenericMetrikStatusService,
  ) {}

  ngOnInit() {
    this.setMinEndDate();
    this.metrikStatusAutocompleteChange(this.form.controls?.genericMetrikStatus?.value ?? '');
    this.setupValueChangeListeners();
  }

  private setupValueChangeListeners() {
    this.form.controls.genericMetrikStatus.valueChanges.subscribe((metrik) => {
      const isDateNeeded = metrik?.isDateNeeded || false;
      const isCommentNeeded = metrik?.isCommentNeeded || false;

      this.toggleField(this.form.controls.startDate, isDateNeeded, [Validators.required]);
      this.toggleField(this.form.controls.endDate, isDateNeeded, [
        Validators.nullValidator,
        this.endDateValidator(() => this.form.controls.startDate.value),
      ]);
      this.toggleField(this.form.controls.comment, isCommentNeeded, [Validators.required]);

      if (isDateNeeded) {
        this.setMinEndDate();
      }
    });

    // Mise à jour de la date de fin en fonction de la date de début
    this.form.controls.startDate.valueChanges.subscribe(() => {
      this.setMinEndDate();
      this.form.controls.endDate.updateValueAndValidity();
    });
  }

  private toggleField(control: AbstractControl, shouldEnable: boolean, validators: any[]) {
    if (shouldEnable) {
      control.setValidators(validators);
      control.enable();
    } else {
      control.clearValidators();
      control.setValue(null);
      control.disable();
    }
    control.updateValueAndValidity();
  }
  /**
   * Validator personnalisé pour la date de fin.
   * Vérifie que, si une date de fin est renseignée, elle n'est pas antérieure à la date de début.
   *
   * @param getStartDate Une fonction retournant la valeur de la date de début.
   */
  endDateValidator(getStartDate: () => any): ValidatorFn {
    return (control: AbstractControl) => {
      const startDate = getStartDate();
      const endDate = control.value;
      if (startDate && endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);
        if (end < start) {
          return { dateBeforeStart: true };
        }
      }
      return null;
    };
  }

  /**
   * Lance la recherche pour l'autocomplete du statut.
   */
  metrikStatusAutocompleteChange(search: any = '') {
    let filters = {};
    if (this.isOver) {
      filters = {
        modules: [this.module],
        isProcessExited: true,
        requiredFields: ['*']
      };
    } else {
        filters = {
            modules: [this.module],
            requiredFields: ['*']
        };
    }

    this.genericMetrikStatus$ = this.genericMetrikStatusService
        .findAll(null, null, 'type', 'asc', search, filters)
        .pipe(map((x: any) => x.data.map((metrik) => ({
          ...metrik,
          displayLabel: metrik.reason ? `${metrik.type} - ${metrik.reason}` : metrik.type
        }))));
  }

  /**
   * Met à jour la date minimale pour la date de fin.
   * Si la date de début est renseignée et postérieure à aujourd'hui, la date minimale devient la date de début.
   * Sinon, c'est la date d'aujourd'hui.
   */
  setMinEndDate() {
    const today = new Date();
    const startDateValue = this.form.controls.startDate?.value;
    const startDate = startDateValue ? new Date(startDateValue) : null;
    this.minEndDate = startDate && startDate > today ? new Date(startDate) : today;
    
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
