import { Pipe, PipeTransform } from '@angular/core';
import { truncateText } from '../utils/html-utils';

@Pipe({
    name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
    transform(value: string, maxLength: number): string {
        return truncateText(value, maxLength);
    }
}
