import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormModalBaseComponent } from '../../../../../../core/base/components/form-modal-base/form-modal-base.component';
import { BuildingModel } from '../../../../../../core/models/building.model';
import { BuildingService } from '../../building.service';
import { CrudModeEnum } from '../../../../../../core/base/enum/crud-mode.enum';
import icClose from '@iconify/icons-ic/twotone-close';
import { EstablishmentModel } from '../../../../../../core/models/establishment.model';
import { EstablishmentService } from '../../../establishment/establishment.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {ErrorApi} from "../../../../../../core/models/api/error-api";

@Component({
    selector: 'building-establishment-create-update-delete-component',
    templateUrl: 'building-establishment-create-update-delete.component.html'
})

export class BuildingEstablishmentCreateUpdateDeleteComponent implements OnInit {
    form: FormGroup;
    mode: CrudModeEnum;


    icClose = icClose;

    loading = false;
    defaults: any;
    successCreateMessage: string;
    successUpdateMessage: string;
    successDeleteMessage: string;
    errorCreateMessage: string;
    errorUpdateMessage: string;
    errorDeleteMessage: string;

    CrudModeEnum = CrudModeEnum;

    establishments$: Observable<EstablishmentModel[]>;

    building: BuildingModel;


    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public dialogRef: MatDialogRef<FormModalBaseComponent<BuildingModel>>,
                public service: BuildingService,
                private establishmentService: EstablishmentService
                ) {
    }

    ngOnInit() {

        this.establishments$ = this.establishmentService.findAll().pipe(map(x => x.data));

        this.mode = this.data.mode;
        this.defaults = this.data.defaults;

        this.form = new FormGroup({
            establishment: new FormControl(this.defaults, Validators.required),
            surface: new FormControl(this.defaults?.surface, Validators.required)
        });
    }

    isCreateMode() {
        return this.mode === CrudModeEnum.Create;
    }

    isUpdateMode() {
        return this.mode === CrudModeEnum.Update;
    }

    isDeleteMode() {
        return this.mode === CrudModeEnum.Delete;
    }

    save() {
        if (this.isCreateMode()) {
            this.createItem();
        } else if (this.isUpdateMode()) {
            this.updateItem();
        }
    }

    setErrorsMessages(errors: Array<ErrorApi>) {
        errors?.forEach(error => {
            let err = this.form.controls[error.property].errors;
            if (err === null) {
                err = { messages: [] };
            }
            err.messages.push(error.message);
            this.form.controls[error.property].setErrors(err);
        });
        this.form.markAllAsTouched();
    }

    createItem() {
        if (this.form.valid) {
            this.loading = true;
            this.service.addEstablishment(this.data.building._id, this.form.getRawValue().establishment._id, this.form.getRawValue().surface).subscribe(
                res => {
                    this.close(res.data);
                    this.loading = false;
                }, error => {
                    this.setErrorsMessages(error.error.errors);
                    this.form.markAllAsTouched();
                    this.loading = false;
                }
            );
        }
    }

    updateItem() {
        if (this.form.valid) {
            this.loading = true;
            const data = this.form.getRawValue();
            this.service.updateEstablishment(this.data.building._id, this.defaults._id, {surface: data?.surface, establishmentId: data?.establishment?._id}).subscribe(
                res => {
                    this.close(res.data);
                    this.loading = false;
                }, error => {
                    this.setErrorsMessages(error.error.errors);
                    this.form.markAllAsTouched();
                    this.loading = false;
                }
            );
        }
    }

    deleteItem() {
        if (this.defaults) {
            this.service.deleteEstablishment(this.data.building._id, this.defaults._id).subscribe(
                res => {
                    this.close(res);
                }, err => {
                    console.log(err);
                }
            );
        }
    }

    close(res?) {
        this.dialogRef.close(res);
    }

    establishmentAutoCompleteChange(state: any) {
        this.establishments$ = this.establishmentService.findAll(null, null, null, null, state).pipe(map(x => x.data));
    }
}
