<mat-card class="legend-card">
    <div class="legend-container">
        <div class="legend-title-container">
            <mat-card-title class="legend-title">Légende</mat-card-title>
        </div>
        <div class="legend-list-container">
            <div class="legend-list">
                <div class="legend-list-item" *ngFor="let item of legendItems">
                    <mat-icon class="legend-icon" [ngStyle]="{'color': item.color}">circle</mat-icon>
                    <span class="legend-item-description">{{ item.description }}</span>
                </div>
            </div>
        </div>
    </div>
</mat-card>
