export enum ENUM_PERMISSIONS {
    READ_TB = 'READ_TB',
    READ_NOTIFICATION = 'READ_NOTIFICATION',
    DELETE_NOTIFICATION = 'DELETE_NOTIFICATION',
    CREATE_ESTABLISHMENT = 'CREATE_ESTABLISHMENT',
    READ_ESTABLISHMENT = 'READ_ESTABLISHMENT',
    UPDATE_ESTABLISHMENT = 'UPDATE_ESTABLISHMENT',
    DELETE_ESTABLISHMENT = 'DELETE_ESTABLISHMENT',
    CREATE_INTERNAL_CONTACT = 'CREATE_INTERNAL_CONTACT',
    READ_INTERNAL_CONTACT = 'READ_INTERNAL_CONTACT',
    UPDATE_INTERNAL_CONTACT = 'UPDATE_INTERNAL_CONTACT',
    DELETE_INTERNAL_CONTACT = 'DELETE_INTERNAL_CONTACT',
    CREATE_BASKET_TYPE = 'CREATE_BASKET_TYPE',
    READ_BASKET_TYPE = 'READ_BASKET_TYPE',
    UPDATE_BASKET_TYPE = 'UPDATE_BASKET_TYPE',
    DELETE_BASKET_TYPE = 'DELETE_BASKET_TYPE',
    CREATE_TVA = 'CREATE_TVA',
    READ_TVA = 'READ_TVA',
    UPDATE_TVA = 'UPDATE_TVA',
    DELETE_TVA = 'DELETE_TVA',
    CREATE_BUILDING = 'CREATE_BUILDING',
    READ_BUILDING = 'READ_BUILDING',
    UPDATE_BUILDING = 'UPDATE_BUILDING',
    DELETE_BUILDING = 'DELETE_BUILDING',
    CREATE_EQUIPMENT = 'CREATE_EQUIPMENT',
    READ_EQUIPMENT = 'READ_EQUIPMENT',
    UPDATE_EQUIPMENT = 'UPDATE_EQUIPMENT',
    DELETE_EQUIPMENT = 'DELETE_EQUIPMENT',
    CREATE_ENERGY_ID = 'CREATE_ENERGY_ID',
    READ_ENERGY_ID = 'READ_ENERGY_ID',
    UPDATE_ENERGY_ID = 'UPDATE_ENERGY_ID',
    DELETE_ENERGY_ID = 'DELETE_ENERGY_ID',
    CREATE_ORDER = 'CREATE_ORDER',
    READ_ORDER = 'READ_ORDER',
    UPDATE_ORDER = 'UPDATE_ORDER',
    DELETE_ORDER = 'DELETE_ORDER',
    CREATE_QUOTE = 'CREATE_QUOTE',
    READ_QUOTE = 'READ_QUOTE',
    UPDATE_QUOTE = 'UPDATE_QUOTE',
    DELETE_QUOTE = 'DELETE_QUOTE',
    CREATE_RECEPTION = 'CREATE_RECEPTION',
    READ_RECEPTION = 'READ_RECEPTION',
    UPDATE_RECEPTION = 'UPDATE_RECEPTION',
    DELETE_RECEPTION = 'DELETE_RECEPTION',
    CREATE_INTERVENTION = 'CREATE_INTERVENTION',
    READ_INTERVENTION = 'READ_INTERVENTION',
    UPDATE_INTERVENTION = 'UPDATE_INTERVENTION',
    DELETE_INTERVENTION = 'DELETE_INTERVENTION',
    CREATE_BILL = 'CREATE_BILL',
    READ_BILL = 'READ_BILL',
    UPDATE_BILL = 'UPDATE_BILL',
    DELETE_BILL = 'DELETE_BILL',
    CREATE_SUPPLIER = 'CREATE_SUPPLIER',
    READ_SUPPLIER = 'READ_SUPPLIER',
    UPDATE_SUPPLIER = 'UPDATE_SUPPLIER',
    DELETE_SUPPLIER = 'DELETE_SUPPLIER',
    CREATE_PRODUCT = 'CREATE_PRODUCT',
    READ_PRODUCT = 'READ_PRODUCT',
    UPDATE_PRODUCT = 'UPDATE_PRODUCT',
    DELETE_PRODUCT = 'DELETE_PRODUCT',
    CREATE_SERVICE = 'CREATE_SERVICE',
    READ_SERVICE = 'READ_SERVICE',
    UPDATE_SERVICE = 'UPDATE_SERVICE',
    DELETE_SERVICE = 'DELETE_SERVICE',
    CREATE_CONTRACT = 'CREATE_CONTRACT',
    READ_CONTRACT = 'READ_CONTRACT',
    UPDATE_CONTRACT = 'UPDATE_CONTRACT',
    DELETE_CONTRACT = 'DELETE_CONTRACT',
    CREATE_LITIGATION = 'CREATE_LITIGATION',
    READ_LITIGATION = 'READ_LITIGATION',
    UPDATE_LITIGATION = 'UPDATE_LITIGATION',
    DELETE_LITIGATION = 'DELETE_LITIGATION',
    CREATE_HOTTOPIC = 'CREATE_HOTTOPIC',

}
