<div class='card overflow-auto p-6 mt-6' *ngIf='!loading'>
<!--    <mat-select disabled [(ngModel)]="currentLevel" (ngModelChange)="onLevelChange()">-->
<!--        <mat-option *ngFor='let level of levels' [value]="level">{{ levelTranslation(level) }}</mat-option>-->
<!--    </mat-select>-->
    <p>Capacité budgétaire : <b>{{ levelTranslation(currentLevel) }}</b></p>
</div>

<ng-container *ngIf="(userProfileService.entity$ | async)?.affectations?.length !== 0; else noAffectationTemplate">
    <div class='card overflow-auto p-6 mt-6'>
        <mat-progress-bar *ngIf='loading' mode='indeterminate' color='primary'></mat-progress-bar>
        <div class="flex justify-between pb-2">
            <div style='padding-left: 40px;' class="font-bold">
                TOUT SÉLECTIONNER
            </div>
            <mat-radio-group  color="accent" fxLayout="row"
                              fxLayoutGap="16px">
                <mat-radio-button  class='w-40 font-bold' color="primary"
                                   value="A"
                                   (click)='setAllValidator(UserProfileValidatorSeuilEnum.A)'>Elevé
                </mat-radio-button>
                <mat-radio-button  class='w-40 font-bold' color="accent"
                                   value="B"
                                   (click)='setAllValidator(UserProfileValidatorSeuilEnum.B)'>Moyen
                </mat-radio-button>
                <mat-radio-button  class='w-40 font-bold' color="warn"
                                   value="C"
                                   (click)='setAllValidator(UserProfileValidatorSeuilEnum.C)'>Bas
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <mat-tree *ngIf='!loading' [dataSource]='dataSource' [treeControl]='treeControl' class='example-tree'>
            <mat-tree-node *matTreeNodeDef='let node' matTreeNodeToggle class='justify-between'>
                <div [ngStyle]="{ 'padding-left': node.level === 0 ? '40px': 0 }" style='padding-left: 40px'>
                    {{node.label}}
                </div>
                <!--            <div class='border-dotted border-b-2 border-grey-500 w-full mx-6 mt-2'></div>-->
                <mat-radio-group [disabled]='!havePermissionForInput' [value]='node.seuil' color="accent" fxLayout="row"
                                 fxLayoutGap="16px">
                    <mat-radio-button [disabled]="!havePermissionForInput" class='w-40' color="primary"
                                      [value]="UserProfileValidatorSeuilEnum.A"
                                      (click)='setValidator(node, UserProfileValidatorSeuilEnum.A)'>Elevé : {{ node.seuilA | number }} €
                    </mat-radio-button>
                    <mat-radio-button [disabled]="!havePermissionForInput" class='w-40' color="accent"
                                      [value]="UserProfileValidatorSeuilEnum.B"
                                      (click)='setValidator(node, UserProfileValidatorSeuilEnum.B)'>Moyen : {{ node.seuilB | number }} €
                    </mat-radio-button>
                    <mat-radio-button [disabled]="!havePermissionForInput" class='w-40' color="warn"
                                      [value]="UserProfileValidatorSeuilEnum.C"
                                      (click)='setValidator(node, UserProfileValidatorSeuilEnum.C)'>Bas : {{ node.seuilC | number }} €
                    </mat-radio-button>
                </mat-radio-group>
            </mat-tree-node>
            <!-- This is the tree node template for expandable nodes -->
            <mat-nested-tree-node *matTreeNodeDef='let node; when: hasChild'>
                <div class='mat-tree-node justify-between'>
                    <div>
                        <button mat-icon-button matTreeNodeToggle
                                [attr.aria-label]="'Toggle ' + node.label">
                            <mat-icon class='mat-icon-rtl-mirror'>
                                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                            </mat-icon>
                        </button>
                        {{node.label}}
                    </div>
                    <mat-radio-group [disabled]='!havePermissionForInput' [value]='node.seuil' color="accent" fxLayout="row"
                                     fxLayoutGap="16px">
                        <mat-radio-button [disabled]="!havePermissionForInput" class='w-40' color="primary"
                                          [value]="UserProfileValidatorSeuilEnum.A"
                                          (click)='setValidator(node, UserProfileValidatorSeuilEnum.A)'>Elevé
                            : {{ node.seuilA | number}} €
                        </mat-radio-button>
                        <mat-radio-button [disabled]="!havePermissionForInput" class='w-40' color="accent"
                                          [value]="UserProfileValidatorSeuilEnum.B"
                                          (click)='setValidator(node, UserProfileValidatorSeuilEnum.B)'>Moyen
                            : {{ node.seuilB | number }} €
                        </mat-radio-button>
                        <mat-radio-button [disabled]="!havePermissionForInput" class='w-40' color="warn"
                                          [value]="UserProfileValidatorSeuilEnum.C"
                                          (click)='setValidator(node, UserProfileValidatorSeuilEnum.C)'>Bas
                            : {{ node.seuilC | number }} €
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <!-- There is inline padding applied to this div using styles.
                    This padding value depends on the mat-icon-button width.  -->
                <div [class.example-tree-invisible]='!treeControl.isExpanded(node)'
                     role='group'>
                    <ng-container *ngIf='!node.isLoading' matTreeNodeOutlet></ng-container>
                </div>
            </mat-nested-tree-node>
        </mat-tree>

    </div>
</ng-container>

<ng-template #noAffectationTemplate>
    <div fxLayout="column" fxLayoutAlign='center' fxLayoutGap="20px" class="p-6 text-center">
        <span class="text-2xl">Veuillez saisir une affectation pour ce contact</span>
        <img class="w-full max-w-lg mx-auto mb-6 h-64" src="assets/img/illustrations/checklist.svg" alt="waiting">
    </div>
</ng-template>

