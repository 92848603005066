import { Component } from '@angular/core';
import {
    SidenavBaseFilterComponent
} from '../../../../../core/base/components/sidenav/base-filter/sidenav-base-filter.component';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EstablishmentModel } from '../../../../../core/models/establishment.model';
import { UserProfileModel } from '../../../../../core/models/user-profile.model';
import { EstablishmentService } from '../../establishment/establishment.service';
import { UserProfileService } from '../../user-profile/user-profile.service';
import { GenericEquipmentService } from '../../../settings/generic-equipment/generic-equipment.service';
import { GenericEquipmentModel } from '../../../../../core/models/generic-equipment.model';
import { EquipmentService } from '../equipment.service';
import { AuthService } from '../../../../../core/services/auth.service';
import { safeMap } from '../../../../../core/utils/array-utils';
import { EquipmentModel } from '../../../../../core/models/equipment.model';
import { SnackbarService } from '../../../../../core/services/snackbar.service';
import { AutocompleteService } from '../../../../../core/services/scroll.service';
import { MetrikStatusModuleEnum } from '../../../../../core/enums/metrik-status.enum';
import { GenericMetrikStatusService } from '../../../settings/generic-metrik-status/generic-metrik-status.service';
import { GenericMetrikStatusModel } from '../../../../../core/models/generic-metrik-status.model';

@Component({
    selector: 'equipment-filter',
    templateUrl: './equipment-filter.component.html',
    styleUrls: ['./equipment-filter.component.scss']
})
export class EquipmentFilterComponent extends SidenavBaseFilterComponent {

    showEstablishments: boolean;
    public metrikStatus$: Observable<GenericMetrikStatusModel[]>;

    constructor(
        public establishmentService: EstablishmentService,
        public userService: UserProfileService,
        public genericEquipmentService: GenericEquipmentService,
        public metrikStatusService: GenericMetrikStatusService,
        public equipmentService: EquipmentService,
        public authService: AuthService,
        public snackbarService: SnackbarService,
        public autocompleteService: AutocompleteService,
    ) {
        super(snackbarService, autocompleteService);

        this.showEstablishments = this.authService.currentUserValue().affectations.length > 1;

        this.addForm('standardForm', new FormGroup({
            technicalIdentifier: new FormControl(),
            reference: new FormControl(),
            establishments: new FormControl(),
            genericEquipments: new FormControl(),
            serialNumber: new FormControl(),
            mark: new FormControl(),
            model: new FormControl(),
            contactType: new FormControl(),
            metrikStatus: new FormControl(),
            isAvailable: new FormControl(),
        }));

        this.addForm('commissioningDate', new FormGroup({
            comparison: new FormControl(),
            min: new FormControl(),
            max: new FormControl(),
        }));
    }

    initialize(): void {

        this.metrikStatusAutocompleteChange();
        // this.autocompleteEstablishment('');
        // this.autocompleteUsers('');
        // this.autocompleteGenericEquipments('');
        // this.autocompleteEquipmentInfiniteScrollField('', 'serialNumber');
    }

    buildCustomFilters(): any {
        const { mark, model, serialNumber, genericEquipments, establishments, contactType,isAvailable, metrikStatus, ...rest} =
            this.forms.standardForm.getRawValue();

        return {
            ...rest,
            contactType: safeMap(contactType, (x: UserProfileModel) => x._id),
            establishments: safeMap(establishments, (x: EstablishmentModel) => x._id),
            genericEquipments: safeMap(genericEquipments, (x: GenericEquipmentModel) => x._id),
            metrikStatus: safeMap(metrikStatus, (x: GenericMetrikStatusModel) => x._id),
            markList: safeMap(mark, (x: EquipmentModel) => x.mark),
            modelList: safeMap(model, (x: EquipmentModel) => x.model),
            serialNumberList: safeMap(serialNumber, (x: EquipmentModel) => x.serialNumber),
            commissioningDate: { ...this.forms.commissioningDate.getRawValue() },
            isAvailable: isAvailable?.value,
        };
    }

    autocompleteEstablishment(search: string = '', pageToGo?: 'next' | 'previous') {
        this.autocompleteService.autocompleteInfiniteScroll(
            search,
            'establishments',
            '_id',
            pageToGo,
            (page, size) =>
                this.establishmentService.findAll(page, size, 'label', 'asc', search, { onlyMine: true })
        )?.subscribe();
    }

    autocompleteUsers(search: string = '', pageToGo?: 'next' | 'previous') {
        this.autocompleteService.autocompleteInfiniteScroll(
            search,
            'users',
            '_id',
            pageToGo,
            (page, size) =>
                this.userService.findAll(page, size, 'lastName', 'asc', search)
        )?.subscribe();
    }

    autocompleteGenericEquipments(search: string = '', pageToGo?: 'next' | 'previous') {
        this.autocompleteService.autocompleteInfiniteScroll(
            search,
            'genericEquipments',
            '_id',
            pageToGo,
            (page, size) =>
                this.genericEquipmentService.findAll(page, size, 'label', 'asc', search)
        )?.subscribe();
    }


    metrikStatusAutocompleteChange(search: any = '') {
        const filters = {
            modules: [MetrikStatusModuleEnum.EQUIPMENT],
            requiredFields: ['*']
        }


        this.metrikStatus$ = this.metrikStatusService
          .findAll(null, null, 'type', 'asc', search, filters)
          .pipe(map(x => {
              return x.data.map((metrik) => {
                  return {
                      ...metrik,
                      displayLabel: metrik.reason ?`${metrik.type} - ${metrik.reason}` : metrik.type
                  };


              })
          }))
    }


    autocompleteEquipmentInfiniteScrollField(search: string = '', filterName: string, pageToGo?: 'next' | 'previous') {
        const data = this.autocompleteService.autocompleteInfiniteScroll(
            search,
            filterName,
            filterName,
            pageToGo,
            (page, size) =>
                this.equipmentService.findAll(page, size, filterName, 'asc', search, {
                    onlyDistinctField: filterName,
                    requiredFields: [filterName]
                })
        );

        data.subscribe();
    }

    onScrollLine(pageToGo: 'next' | 'previous', filterField: string) {
        switch (filterField) {
            case 'establishments':
                this.autocompleteEstablishment('', pageToGo);
                break;
            case 'users':
                this.autocompleteUsers('', pageToGo);
                break;
            case 'genericEquipments':
                this.autocompleteGenericEquipments('', pageToGo);
                break;
            case 'mark':
            case 'model':
            case 'serialNumber':
                this.autocompleteEquipmentInfiniteScrollField('', filterField, pageToGo);
                break;
            default:
                console.error('Unknown filterField:', filterField);
        }
    }

}
