 <div  class="mt-6 flex flex-col md:flex-row md:items-start">
    <div class='flex-auto'>
        <div class='card'>
            <div class='px-gutter py-4 border-b' fxLayout='row' fxLayoutAlign='space-between center'>
                <h2 class='title m-0'>Détails</h2>

                <button color='primary' mat-raised-button type='button' (click)='updateItem()' *hasPermission="[ENUM_PERMISSION.UPDATE_FM_REF_PURCHASE_INFO]">MODIFIER</button>
            </div>
            <ng-container *ngIf='(service.entity$ | async)?._id; else loading'>
                <div *ngIf='service.entity$ | async as entity' class="px-gutter py-4" gdColumns="1fr 1fr" gdColumns.xs="1fr" gdGap="16px">
                    <details-line-base
                            hint='Identifiant Technique'
                            [label]='entity.technicalIdentifier'
                    >
                    </details-line-base>

                    <details-line-base
                            hint='Libellé'
                            [label]='entity.label'
                    >
                    </details-line-base>

                    <details-line-base
                            *ngIf="entity.genericProduct"
                            hint='Article générique'
                            [label]='entity.genericProduct.label'
                    >
                    </details-line-base>

                    <details-line-base
                            hint='Fournisseur'
                            [label]='entity.supplier?.label'
                    >
                    </details-line-base>

                    <details-line-base
                            hint='SIRET Fournisseur'
                            [label]='entity.supplier?.siretNumber'
                    >
                    </details-line-base>

                    <details-line-base
                            hint='Référence'
                            [label]='entity.reference'
                    >
                    </details-line-base>

                    <details-line-base
                            hint='Référence précédente'
                            [label]='entity.previousReference'
                    >
                    </details-line-base>

                    <details-line-base
                            hint='Fabricant'
                            [label]='entity.manufacturer'
                    >
                    </details-line-base>

                    <details-line-base
                            hint='Modèle'
                            [label]='entity?.model'
                    >
                    </details-line-base>

                    <details-line-base
                            hint='Prix H.T'
                            [label]='entity.priceHT'
                            type='number'
                            additionalDisplay=' €'
                    >
                    </details-line-base>

                    <details-line-base
                            hint='Prix T.T.C'
                            [label]='round(entity.priceTTC)'
                            type='number'
                            additionalDisplay=' €'
                    >
                    </details-line-base>

                    <details-line-base
                            hint="TVA"
                            [label]='entity?.tva?.label'
                    >
                    </details-line-base>

                    <details-line-base
                            hint="Unité d'achat"
                            [label]='entity.purchaseUnit'
                    >
                    </details-line-base>

                    <details-line-base
                            hint='Unité de conditionnement'
                            [label]='entity.conditioningUnit'
                    >
                    </details-line-base>


                    <!--                    <details-line-base-->
<!--                            hint='Quantité de conditionnement'-->
<!--                            [label]='entity.conditioningQuantity'-->
<!--                    >-->
<!--                    </details-line-base>-->

                    <details-line-base
                            hint='Périssable'
                            type='boolean'
                            [label]='entity.genericProduct?.perishable'
                    >
                    </details-line-base>

                    <details-line-base
                            hint='Stockable'
                            type='boolean'
                            [label]='entity.stockable'
                    >
                    </details-line-base>

                    <details-line-base
                            hint="Créer depuis une demande de devis"
                            type="boolean"
                            [label]='!!entity?.quoteId'
                    >
                    </details-line-base>

                    <details-line-base
                            hint="Disponible dans le catalogue"
                            type="boolean"
                            [label]='entity?.isAvailable'
                    >
                    </details-line-base>

                    <!--                <details-line-base-->
                    <!--                    [label]='entity.category?.label'-->
                    <!--                    hint='Catégorie'-->
                    <!--                >-->
                    <!--                </details-line-base>-->

                    <details-line-base
                            *ngIf="entity.comment"
                            hint='Informations complémentaires'
                            type="html"
                            [label]='entity.comment'
                    >
                    </details-line-base>
                </div>
            </ng-container>

        </div>
<!--        PRED-519- Temporary disabled unil finished function -->
<!--        <div class="card mt-6">-->
<!--            <div class="px-gutter py-4 border-b" fxLayout='row' fxLayoutAlign='space-between center'>-->
<!--                <h2 class="title m-0">Caractéristique</h2>-->
<!--&lt;!&ndash;                <button color='primary' mat-raised-button type='button' (click)='addCharacteristic()'>AJOUTER</button>&ndash;&gt;-->
<!--            </div>-->

<!--            <base-data-table-->
<!--                [showPaginator]='false'-->
<!--                [showSort]='false'-->
<!--                [dataSource]='dataSourceCharacteristic'-->
<!--                [displayedColumns]='displayedColumnsCharacteristic'-->
<!--                [feisColumns]='feisColumnsCharacteristic'-->
<!--                (onDeleteItem)='deleteCharacteristic($event)'-->
<!--                (onUpdateItem)='updateCharacteristic($event)'-->
<!--            ></base-data-table>-->
<!--        </div>-->

<!--        <div class="card mt-6">-->
<!--            <div class="px-gutter py-4 border-b" fxLayout='row' fxLayoutAlign='space-between center'>-->
<!--                <h2 class="title m-0">Équipement compatible</h2>-->
<!--                <button color='primary' mat-raised-button type='button' (click)='addEquipment()'>AJOUTER</button>-->
<!--            </div>-->

<!--            <base-data-table-->
<!--                [showPaginator]='false'-->
<!--                [showSort]='false'-->
<!--                [dataSource]='dataSourceCompatibleEquipment'-->
<!--                [displayedColumns]='displayedColumnsCompatibleEquipment'-->
<!--                [feisColumns]='feisColumnsCompatibleEquipment'-->
<!--                (onDeleteItem)='deleteCompatibleEquipment($event)'-->
<!--            ></base-data-table>-->
<!--        </div>-->
    </div>
</div>

<ng-template #loading>
    <div class="px-gutter py-4" gdColumns="1fr 1fr" gdColumns.xs="1fr" gdGap="16px">
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
    </div>
</ng-template>
