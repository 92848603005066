import {BaseService} from '../../../../core/base/base.service';
import {HttpClient} from '@angular/common/http';
import {GenericEngagementModel} from '../../../../core/models/generic-engagement.model';
import {Injectable} from '@angular/core';
import { ObjectApiInterface } from '../../../../core/base/interfaces/object-api.interface';
import { ContractModel } from '../../../../core/models/contract.model';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class GenericEngagementService extends BaseService<GenericEngagementModel> {
    defaultSort = 'createdAt';

    constructor(public http: HttpClient) {
        super(http, 'generic-engagement');
    }

    fetchActiveContractByCondition(condition: any): any {
        return this.http.post<ObjectApiInterface<ContractModel>>(`${this.baseUrlApi}/fetchActiveContractByCondition`, condition).pipe(map(x => {
            return x.data;
        }));
    }

    findAllByGenericEngagementCategory(id: string) :any{
        return this.http.get<ObjectApiInterface<GenericEngagementModel>>(`${this.baseUrlApi}/findAllByGenericEngagementCategory/${id}`).pipe(map(x => {
            return x.data;
        }));
    }
}
