import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EquipmentModel} from 'src/app/core/models/equipment.model';
import {EquipmentService} from '../equipment.service';
import {FormModalBaseComponent} from 'src/app/core/base/components/form-modal-base/form-modal-base.component';
import {GenericEquipmentService} from '../../../settings/generic-equipment/generic-equipment.service';
import {Observable} from 'rxjs';
import {GenericEquipmentModel} from '../../../../../core/models/generic-equipment.model';
import {map} from 'rxjs/operators';
import {EstablishmentService} from '../../establishment/establishment.service';
import {EstablishmentModel} from '../../../../../core/models/establishment.model';
import {setValidity, ValidityType} from '../../../../../core/base/models/validity.model';
import {EquipmentTypeService} from "../../../settings/equipment-type/equipment-type.service";
import {EquipmentTypeModel} from "../../../../../core/models/equipment-type.model";
import moment from 'moment';
import {UserProfileService} from "../../user-profile/user-profile.service";
import {ContactTypeEnum} from "../../../../../core/enums/contact-type.enum";
import {AuthService} from "../../../../../core/services/auth.service";
import {ENUM_PERMISSIONS} from "../../../../../core/enums/permission.enum";


@Component({
    selector: 'vex-equipment-create-update',
    templateUrl: './equipment-create-update-delete.component.html',
    styleUrls: ['./equipment-create-update-delete.component.scss']
})
export class EquipmentCreateUpdateDeleteComponent extends FormModalBaseComponent<EquipmentModel> implements OnInit {

    genericEquipment$: Observable<GenericEquipmentModel[]>;
    establisment$: Observable<EstablishmentModel[]>;
    equipmentType$: Observable<EquipmentTypeModel[]>;

    date: Date = new Date();

    canDownloadTemplateModel: boolean = false;
    filter = (date: Date): boolean => {
        return true;
    }
    contacts$: Observable<any[]>;

    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public dialogRef: MatDialogRef<FormModalBaseComponent<EquipmentModel>>,
                public service: EquipmentService,
                private genericEquipmentService: GenericEquipmentService,
                private establishmentService: EstablishmentService,
                private equipmentTypeService: EquipmentTypeService,
                private userProfileService: UserProfileService,
                private authService: AuthService
    ) {
        super(data, dialogRef, service);
    }

    ngOnInit() {
        super.ngOnInit();

        this.genericEquipment$ = this.genericEquipmentService.findAll().pipe(map(x => x.data));
        this.establisment$ = this.establishmentService.findAll().pipe(map(x => x.data));
        if (this.defaults?.establishment) {
            console.log(this.defaults?.establishment)
            this.contacts$ = this.userProfileService.findAll(null, null, null, null, null, {establishment: this.defaults?.establishment?._id})
                .pipe(
                    map(x => x.data.filter(x => {
                        console.log(x)
                        return x.contactType.map(y => y.label).includes(ContactTypeEnum.REFERENT_TECHNIC)
                    })),
                    map(x => x.map(y => ({...y, fullName: `${y.firstName} ${y.lastName}`})))
                );
        }
        // this.equipmentTypeAutoCompleteChange();

        this.form = new FormGroup({
            _id: new FormControl(this.defaults?._id),
            reference: new FormControl(this.defaults?.reference, [Validators.required]),
            establishment: new FormControl(this.defaults?.establishment, [Validators.required]),
            // equipmentType: new FormControl(this.defaults?.equipmentType,[Validators.required] ),
            establishmentDisable: new FormControl(this.defaults?.establishment?.label),
            serialNumber: new FormControl(this.defaults?.serialNumber, []),
            mark: new FormControl(this.defaults?.mark, [Validators.required]),
            model: new FormControl(this.defaults?.model),
            genericEquipment: new FormControl(this.defaults?.genericEquipment, [Validators.required]),
            commissioningDate: new FormControl(this.defaults?.commissioningDate, [Validators.required]),
            endManufacturerDate: new FormControl(this.defaults?.endManufacturerDate, []),
            purchaseDate: new FormControl(this.defaults?.purchaseDate, []),
            startAmortizationDate: new FormControl(this.defaults?.startAmortizationDate, null),
            endAmortizationDate: new FormControl(this.defaults?.endAmortizationDate),
            valueAmortization: new FormControl(this.defaults?.valueAmortization, []),
            depreciationPeriod: new FormControl(this.defaults?.depreciationPeriod?.value, null),
            contactIntern: new FormControl(this.defaults?.contactIntern, null),
            // equipmentType: new FormControl(this.defaults?.equipmentType, [Validators.required]),
        });

        this.form.controls.purchaseDate.valueChanges.subscribe(value => {
            if (value) {
                this.filter = (date: Date) => date >= moment(value).toDate();
            } else {
                this.filter = (date: Date) => true;
            }
        })

        if (this.isUpdateMode()) {
            this.form.addControl('startAmortizationDate', new FormControl(this.defaults?.startAmortizationDate, [Validators.required]));
        }

        this.form.controls.commissioningDate.valueChanges.subscribe(value => {
            if (value) this.form.controls.startAmortizationDate.setValue(value);
        })

        this.form.valueChanges.subscribe(control => {
            //check if commissioningDate and depreciationPeriod are not empty
            if (control.commissioningDate && control.depreciationPeriod) {
                //set value of enAmortizationDate with commissioningDate + depreciationPeriod (year)
                const endDate = new Date(control.commissioningDate);
                endDate.setFullYear(endDate.getFullYear() + control.depreciationPeriod)
                this.form.controls.endAmortizationDate.setValue(endDate.toISOString(), {emitEvent: false});
            } else {
                this.form.controls.endAmortizationDate.setValue(null, {emitEvent: false});
            }

            if (control.valueAmortization) {
                if (control.valueAmortization >= 500) {
                    this.form.controls.depreciationPeriod.setValidators(Validators.required);
                } else {
                    this.form.controls.depreciationPeriod.clearValidators();
                }
            }
        });

        this.form.controls.establishment.valueChanges.subscribe(value => {
            if (value) {
                this.contacts$ = this.userProfileService.findAll(null, null, null, null, null, {establishment: value?._id})
                    .pipe(
                        map(x => x.data.filter(x => x.contactType.map(y => y.label).includes(ContactTypeEnum.REFERENT_TECHNIC))),
                        map(x => x.map(y => ({...y, fullName: `${y.firstName} ${y.lastName}`})))
                    );
                this.form.controls.contactIntern.setValue(null);
            } else {
                this.contacts$ = null;
            }
        });

        if (this.defaults?.establishment) {
            this.contacts$ = this.userProfileService.findAll(null, null, null, null, null, {establishment: this.defaults?.establishment?._id})
                .pipe(
                    map(x => x.data.filter(x => x.contactType.map(y => y.label).includes(ContactTypeEnum.REFERENT_TECHNIC))),
                    map(x => x.map(y => ({...y, fullName: `${y.firstName} ${y.lastName}`})))
                );
        }


        this.authService.getCurrentUserPermissions$().subscribe(permissions => {
            this.canDownloadTemplateModel = permissions.includes(ENUM_PERMISSIONS.DOWNLOAD_EQUIPMENT);
        });
    }

    dateFilter(date: Date): boolean {
        return date >= moment(this.date).toDate();
    }

    beforeCreateItem() {
        this.defaults.depreciationPeriod = setValidity(ValidityType.YEAR, this.form.controls.depreciationPeriod.value);
        this.defaults.startAmortizationDate = this.defaults.commissioningDate;
        // this.defaults.amortization = this.service.createAmortization(this.defaults?.depreciationPeriod?.value, this.defaults?.valueAmortization, new Date(this.defaults?.startAmortizationDate));
    }

    afterCreateItem(result?: any, error?: any) {
        if (result) {
            this.close(result.data);
        }
    }

    afterImportItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
        }
    }

    beforeUpdateItem() {
        this.defaults.endAmortizationDate = new Date(this.form.getRawValue().endAmortizationDate);
        this.defaults.depreciationPeriod = setValidity(ValidityType.YEAR, this.form.controls.depreciationPeriod.value);
        this.defaults.amortization = this.service.createAmortization(this.defaults?.depreciationPeriod?.value, this.defaults?.valueAmortization, new Date(this.defaults?.startAmortizationDate));
    }

    afterUpdateItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
        }
    }

    genericEquipmentAutoCompleteChange(state: any) {
        this.genericEquipment$ = this.genericEquipmentService.findAll(null, null, null, null, state).pipe(map(x => x.data));
    }

    establishmentAutoCompleteChange($event: any) {
        this.establisment$ = this.establishmentService.findAll(null, null, null, null, $event).pipe(map(x => x.data));
    }

    equipmentTypeAutoCompleteChange($event = '') {
        this.equipmentType$ = this.equipmentTypeService.findAll(null, null, null, null, $event).pipe(map(x => x.data));
    }
}
