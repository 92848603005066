<!--<mat-progress-bar *ngIf='loading' mode="indeterminate" color="primary"></mat-progress-bar>-->
<div class="px-gutter py-gutter">
    <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="service.entity$ | async as contract">
        <div fxLayout='row' fxLayoutAlign='start center' fxLayoutGap='16px'>
            <div class='h-16 flex items-center custor-pointer'>
                <mat-icon class='cursor-pointer' (click)="previousRoute()" color='primary'>arrow_back</mat-icon>
            </div>
            <div class='h-16 flex items-center w-full'>
                <h1 @fadeInRight
                    class='headline m-0'>{{ contract?.technicalIdentifier | uppercase }}</h1>
            </div>
        </div>
        <div fxLayout='row' fxLayoutAlign='end center' fxLayoutGap='16px'>

            <button *ngIf="contract?.status === contractStatusEnum.DRAFT"
                    color='warn' mat-raised-button type='button' (click)="deleteDraft()">SUPPRIMER LE BROUILLON</button>

            <div>
                <ng-container *ngIf="contractStatus === contractStatusEnum.VALIDATED && !checkStartDateValidationIsPassed(contract?.startValidityDate)">
                    <button color='primary'
                            mat-raised-button type='button' (click)="invalidateContract()">DÉVALIDER L'ENGAGEMENT</button>
                </ng-container>
            </div>

            <div>
                <ng-container *ngIf="isValidable && creationStep >= 4">
                <button color='primary'
                        mat-raised-button type='button' (click)="validatesContract()" [disabled]="contractStatus !== contractStatusEnum.DRAFT">VALIDER L'ENGAGEMENT</button>
                </ng-container>
            </div>

            <div class='py-3' fxLayout='row' fxLayoutAlign='start center'>

                <div @scaleIn
                     *ngIf="contract?.status === contractStatusEnum.DRAFT"
                     class='w-10 h-10 rounded-full ltr:mr-3 rtl:ml-3 flex items-center justify-center text-primary'
                     [ngClass]='{"bg-green-light text-green" : contract?.status === contractStatusEnum.DRAFT}'
                     [matTooltip]="'Brouillon'"
                >

                    <mat-icon >drafts</mat-icon>

                </div>
                <div @scaleIn
                     *ngIf="contract?.status === contractStatusEnum.VALIDATED"
                     class='w-10 h-10 rounded-full ltr:mr-3 rtl:ml-3 flex items-center justify-center bg-primary-light text-primary'>
                    <mat-icon>done</mat-icon>
                </div>
                <div @fadeInRight class="hidden md:flex ">
                    <p class='m-0 caption text-hint'>{{contractStatusTranslation(contract?.status)}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="card overflow-hidden">
        <div class="z-10 relative -mt-16 flex items-center">
            <div class="max-w-full flex-auto sm:rtl:mr-6">
                <div class="h-16 flex items-center">
                    <!--                    <h1 @fadeInRight class="headline m-0"> cc</h1>-->
                </div>

                <nav class="vex-tabs vex-tabs-dense border-0" mat-tab-nav-bar>
                    <a #rla="routerLinkActive"
                       *ngFor="let link of getDisplayableTabs()"
                       [active]="rla.isActive"
                       [disabled]="link.disabled"
                       [routerLink]="link.route"
                       mat-tab-link
                       queryParamsHandling="preserve"
                       [routerLinkActiveOptions]="link.routerLinkActiveOptions || { exact: false }"
                       routerLinkActive>
                        {{ link.label }}
                    </a>
                </nav>
            </div>
        </div>
    </div>

    <router-outlet></router-outlet>
</div>
