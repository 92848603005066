import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BuildingModel} from 'src/app/core/models/building.model';
import {BuildingService} from '../../building.service';
import {FormModalBaseComponent} from 'src/app/core/base/components/form-modal-base/form-modal-base.component';
import {AuthService} from "../../../../../../core/services/auth.service";
import {ENUM_PERMISSIONS} from "../../../../../../core/enums/permission.enum";
import {GenericBuildingService} from "../../../../settings/generic-building/generic-building.service";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {GenericBuildingModel} from "../../../../../../core/models/generic-building.model";
import {ObjectApiInterface} from "../../../../../../core/base/interfaces/object-api.interface";


@Component({
    selector: 'vex-building-create-update',
    templateUrl: './building-create-update-delete.component.html',
    styleUrls: ['./building-create-update-delete.component.scss']
})
export class BuildingCreateUpdateDeleteComponent extends FormModalBaseComponent<BuildingModel> implements OnInit {
    buildingTypes = [
        {
            label: 'Principal',
            value: 'main'
        },
        {
            label: 'Autre',
            value: 'other'
        }
    ];

    canDownloadTemplateModel: boolean = false;
    genericBuildings$: Observable<GenericBuildingModel[]>;

    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public dialogRef: MatDialogRef<FormModalBaseComponent<BuildingModel>>,
                public service: BuildingService,
                private authService: AuthService,
                private genericBuildingService: GenericBuildingService
    ) {
        super(data, dialogRef, service);
    }

    ngOnInit() {
        super.ngOnInit();
        this._initData();

        this.form = new FormGroup({
            _id: new FormControl(this.defaults?._id),
            genericBuilding: new FormControl(this.defaults?.genericBuilding),
            label: new FormControl(this.defaults?.label, [Validators.required]),
            surface: new FormControl(this.defaults?.surface, [Validators.required]),
            type: new FormControl(this.buildingTypes.find(x => x.value === this.defaults?.type) ?? this.buildingTypes.find(x => x.value === "other"), [Validators.required]),
        });

        this.authService.getCurrentUserPermissions$().subscribe(permissions => {
            this.canDownloadTemplateModel = permissions.includes(ENUM_PERMISSIONS.DOWNLOAD_BUILDING);
        });

    }

    async _initData() {
        this.genericBuildings$ = await this.genericBuildingService.findAll(null, null, 'label', 'asc', null, null).pipe(
            map((data: ObjectApiInterface<GenericBuildingModel[]>) => data.data)
        );
    }

    beforeCreateItem() {
        this.defaults.type = this.form.controls.type.value.value;
    }

    afterCreateItem(result?: any, error?: any) {
        if (result) {
            this.close(result.data);
        }
    }

    beforeUpdateItem() {
        this.defaults.type = this.form.controls.type.value.value;
    }

    afterUpdateItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
        }
    }

    afterImportItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
        }
    }

    genericBuildingsAutocompleteChange(state: any) {
        this.genericBuildings$ = this.genericBuildingService.findAll(null,
            null,
            null,
            null,
            state,
            null
        ).pipe(map((x: ObjectApiInterface<GenericBuildingModel[]>) => x.data));
    }
}
