<vex-page-layout>
    <vex-page-layout-header class='pb-4' fxLayout='column' fxLayoutAlign='center start'>
        <!--        <div class='w-full flex flex-col sm:flex-row justify-between container'>-->
        <!--            <div>-->
        <!--                <h1 class='title mt-0 mb-1'>Notifications</h1>-->
        <!--            </div>-->
        <!--        </div>-->
    </vex-page-layout-header>

    <vex-page-layout-content class='-mt-6'>
        <div class='card overflow-auto -mt-16'>
            <base-data-table-header
                    (clearPopupFilter)='clearPopupFilter()'
                    (onClearTextFilter)='clearTextFilter()'
                    (onSearchChange)='onSearchChange($event)'
                    (validatePopupFilter)='validatePopupFilter()'
                    [templates]="[{ template: customAction, position: 1, place: 'after'}]"
                    [canCreate]='false'
                    [contentPopupTemplate]='contentPopupTemplate'
                    [countPopupFilter]='countPopupFilter'
                    [loading]='loading'
                    [popupFilter]='true'
                    headerPopupTitle='Filtres avancés'
                    label='Notifications'
            ></base-data-table-header>

            <base-data-table
                    (onDeleteItem)='deleteItem($event)'
                    (onChangeReadStatus)='changeReadStatus($event)'
                    (onPageEvent)='pageEvent($event)'
                    (onRowClick)='readNotification($event)'
                    (onSelectedRow)='selectedRow($event)'
                    (onSortEvent)='sortEvent($event)'
                    (onUpdateItem)='updateItem($event)'
                    [accordeonRef]='accordeonRef'
                    [clearSelectedRow]='clearSelectedEl'
                    [count]='count'
                    [dataSource]='dataSource'
                    [displayedColumns]='displayedColumns'
                    [feisColumns]='feisColumns'
                    [selectionActionTemplate]="selectionActionTemplateRef"
                    [sortField]='sortField'
                    [sortOrder]='sortOrder'
            ></base-data-table>
        </div>
    </vex-page-layout-content>
</vex-page-layout>

<ng-template #selectionActionTemplateRef let-selectedElem>
    <div *ngIf='selectedElem.length > 0' @transformPopover
         class='card w-full bg-warn-light flex flex-row justify-between'>
        <h4 class='p-4 pl-10 w-full'>{{ selectedElem.length }} Notifications sélectionnées</h4>
        <div class='p-4 pr-10 h-full w-full flex justify-end'>
            <!-- Utilisation du display flex dans le bouton pour aligner le texte et l'icône -->
            <div class='flex flex-row space-x-8 '>
                <button mat-raised-button color='primary' [matMenuTriggerFor]="menuForMassiveChangeStatus">
                    Modifier Statut
                </button>
                <button (click)="removeNotifications(selectedElem)">
                    <mat-icon [icIcon]="icDelete"></mat-icon>
                </button>
            </div>
        </div>
    </div>

    <mat-menu #menuForMassiveChangeStatus="matMenu">
        <div class="menu-content">
            <button mat-menu-item
                    *ngIf="!allSelectedAreRead(selectedElem)"
                    (click)="changeMassiveReadStatus(NotificationStatusEnum.READ, selectedElem)">
                Marquer comme lu
            </button>
            <button mat-menu-item
                    *ngIf="!allSelectedAreUnread(selectedElem)"
                    (click)="changeMassiveReadStatus(NotificationStatusEnum.UN_READ, selectedElem)">
                Marquer comme non lu
            </button>
        </div>
    </mat-menu>

</ng-template>

<ng-template #accordeonRef let-row="value">
    <div class='py-gutter flex flex-row'>
        <div class='flex-auto w-1/12 self-center'>
            <mat-icon (click)='goToLocation(row)' *ngIf='row.source' class='cursor-pointer'>my_location</mat-icon>
        </div>
        <div class='flex-auto w-11/12 text-left'>
            {{ row.message }}
        </div>
        <div *ngIf="row?.sourceObject?.type === 'bills'" class='flex-auto w-12/12 self-center'>
            <mat-icon (click)='displayBill(row)' *ngIf='row.source' class='cursor-pointer'>remove_red_eye</mat-icon>
        </div>
    </div>
</ng-template>

<ng-template #customAction let-row="value">
    <button (click)="refresh()"
            class='ml-4'
            color='primary'
            fxFlex='none'
            mat-mini-fab
            matTooltip='Rafraichir' type='button'>
        <mat-icon>refresh</mat-icon>
    </button>
</ng-template>

<ng-template #contentPopupTemplate>
    <form [formGroup]='popupForm'>
        <!--        <base-form-input-->
        <!--            label='test'-->
        <!--            controlName='test'-->
        <!--        >-->
        <!--        </base-form-input>-->
        <base-form-input
                [items]='listSeverity'
                controlName='severity'
                label='Sévérité'
                type='multiple'
        >

        </base-form-input>
    </form>
</ng-template>
