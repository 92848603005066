import { Component, OnDestroy, OnInit, Self } from '@angular/core';
import { GedConfig, GedTypeEnum } from '../../../enums/ged-type.enum';
import {
  DocumentElement,
  GedService
} from '../../../../pages/application/admin/playground/docuware-playground/ged.service';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, concatMap, debounce, delay, from, interval, of, Subscription } from 'rxjs';
import { BaseService } from '../../base.service';
import { IDocumentsQueryResult } from '../../../ui/ged-ui/interface/docuware.interface';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmComponent } from '../../../../../@vex/components/dialog-confirm/dialog-confirm.component';
import { FileSaverService } from 'ngx-filesaver';
import { HttpResponse } from '@angular/common/http';
import { ENUM_PERMISSIONS } from '../../../enums/permission.enum';

@Component({
  selector: 'vex-ged-base',
  template: ``
})
export class GedBaseComponent<T> implements OnInit, OnDestroy {
  ENUM_PERMISSION = ENUM_PERMISSIONS;
  subscription = new Subscription();
  gedType: GedTypeEnum;
  files: DocumentElement[] = [];
  documents = [];
  form: FormGroup;
  loading = false;
  defaults;
  id: string;
  loadingSave = false;
  dataSource: any[];
  nbTotal$ = new BehaviorSubject<number>(0);
  totalPercentage = 0;
  private currentBar$ = new BehaviorSubject<number>(0);
  nbErrors$ = new BehaviorSubject<number>(0);

  constructor(
      private gedConfig: GedConfig,
      private _gedService: GedService,
      public service: BaseService<T>,
      public dialog: MatDialog,
      @Self() public fileSaver: FileSaverService
  ) {
    this.gedType = gedConfig.gedType;
    this._gedService.setUrl(gedConfig.apiUrl);
    this._gedService.setType(gedConfig.gedType);
  }

  ngOnInit() {
    this.loading = true;
    this._gedService.errorMessage.next(null);
    this.testConnection()
        .subscribe(x => {
          this.service.entity$.subscribe(entity => {
            if (entity != null) {
              // @ts-ignore
              this.id = entity._id;
              this.initData();
            }
          });
        }, error => {
          this._gedService.errorMessage.next(error.error.message);
        });
  }

  initData() {
    this.subscription.add(
      this._gedService.allDocumentsByPredikId(this.id)
        .subscribe((res: IDocumentsQueryResult) => {
          this.dataSource  = this._gedService.mapToDataSource(res);
          this.loadingSave = false;
          this.files = [];
          this.initForm();
        })
    );
  }

  initForm() {
  }

  openDelete(event) {
    console.log(event)
    const ref = this.dialog.open(DialogConfirmComponent, {
      data: {
        description: 'Etes-vous sûr de vouloir supprimer ce document ?',
        cancelText: 'NON',
        title: 'Supprimer un document',
        validText: 'OUI',
        call$: this._gedService.deleteDocument(event.DWDOCID)
      }
    });

    ref.afterClosed().subscribe(x => this.initData());
  }

  testConnection() {
    return this._gedService.testConnection();
  }

  beforeUpload(){
  }

  validForm() {

    this.nbTotal$.next(this.files.length);
    this.totalPercentage = 100 * this.files.length;
    this.currentBar$.next(0);
    this.nbErrors$.next(0);

    this.loadingSave = true;
    from(this.files).pipe(
        concatMap(item => of(item).pipe(delay(500)))
    ).subscribe( document => {
        document.status = 'ON_LOAD';
        this.defaults = this.form.getRawValue();
        this.beforeUpload();
        const documentElement = this._gedService.uploadDocument(document, this.defaults, this.loadingSave);

        documentElement.progress.pipe(debounce(() => interval(100)))
            .subscribe(value => {
              if (value === 500) {
                this.loadingSave = false;
              }
              if (this.files[this.files.length - 1].id === document.id && value === 101) {

                this.initData();
              }
          });
      },
        error => console.error('toto'),
    );
    // this._gedService.uploadDocument(this.files[0], this.form.getRawValue())
    //     .subscribe(res => {
    //       this.form.reset({
    //         predikId: this.form.get('predikId').value
    //       });
    //       // this.form.get('predikId').setValue(this.quote._id);
    //       this.loadingSave = false;
    //       this.initData();
    //     });
  }

  downloadDocument(id) {
    this._gedService.downloadDocument(id)
      .subscribe((res: any) => {
        this.fileSaver.save(res.body, res.headers.get('filename'));
      });
  }

  extractFileName(res: HttpResponse<any>): string {
    const contentDisposition = res.headers.get('Content-Disposition');
    const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = fileNameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    } else {
      return 'error';
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}

export interface IGedBaseComponent {
  documentTypes: string[];
  files: DocumentElement[];
  initForm: () => void;
}
