import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import icDone from "@iconify/icons-ic/twotone-done";
import icPlus from "@iconify/icons-ic/twotone-plus";
import icMinus from "@iconify/icons-ic/twotone-minus";
import icClose from "@iconify/icons-ic/twotone-close";
import {MatTabGroup} from '@angular/material/tabs';
import {TicketModel} from "../../../models/ticket.model";

@Component({
    selector: 'vex-catalog-base',
    templateUrl: './catalog-base.component.html',
    styleUrls: ['./catalog-base.component.scss']
})
export class CatalogBaseComponent implements OnInit {
    @ViewChild('tabGroup') tabGroup: MatTabGroup;
    @Input() isEditionAllowed: boolean = true;
    @Input() ticket$: Observable<TicketModel>;
    @Input() actions: Array<any> = [];
    @Input() selectedIndex: number;
    @Input() basket$: Observable<any>;
    @Input() tabs: Array<string>;
    @Input() tabsVisible: Array<boolean>;
    @Input() dataSourceCatalog: any;
    @Input() displayedColumnsCatalog: Array<string>;
    @Input() feisColumnsCatalog: Array<any>;
    @Input() count: number;
    @Input() pageSize: number;
    @Input() sortOrder: string;
    @Input() sortField: string;
    @Input() showPrice: boolean = false;
    @Input() showFilterButton: boolean = true;
    @Input() showOnlyPerimeterCategories: boolean = false;
    @Input() showTotalBasketAmount: boolean = false;
    @Input() canChangeBasketPrice: boolean = false;
    @Input() accordionRef: TemplateRef<any> = null;
    @Input() isRealyValidatedShowed: boolean = true;
    @Input() canShowProductsPrestationsAvailable: boolean = true;



    @Output() onTabChangeEvent = new EventEmitter<any>();
    @Output() onRemoveItemFromBasketEvent = new EventEmitter<any>();
    @Output() onUpdateItemQuantityFromBasketEvent = new EventEmitter<any>();
    @Output() onFilterChangeEvent = new EventEmitter<any>();
    @Output() onPageEvent = new EventEmitter<any>();
    @Output() onSortEvent = new EventEmitter<any>();
    @Output() onAddToBasketEvent = new EventEmitter<any>();
    @Output() onUpdateItemQuantityFromCatalogEvent = new EventEmitter<any>();
    @Output() onUpdateItemPriceFromCatalogEvent = new EventEmitter<any>();
    @Input() showCatalogPermission?: boolean = true;
    @Input() canAddToBasketPermission?: boolean = true;
    @Input() canSearchPermission?: boolean = true;
    @Input() canDeletePermission?: boolean = true;
    @Input() canHelpPermission?: boolean = true;
    @Input() canUpdateBasketPermission?: boolean = true;

    panelOpenState = false;
    totalBasketAmount: number = 0;
    icDone = icDone;
    icPlus = icPlus;
    icMinus = icMinus;
    icClose = icClose;

    constructor() {
    }

    ngOnInit(): void {
        this.basket$.subscribe((basket) => {
            this.totalBasketAmount = basket.reduce((acc, item) => acc + item.priceHT * item.quantity, 0).toFixed(2);
        });

    }

    changeTab(event) {
        this.tabGroup.selectedIndex = event;
    }

    get canDisplayActions(): boolean {
        return this.actions.some(action => action.displayBtn);
    }

}
