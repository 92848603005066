import { Injectable } from '@angular/core';
import { GenericProductModel } from 'src/app/core/models/generic-product.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import { BaseService } from 'src/app/core/base/base.service';
import { ObjectApiInterface } from '../../../../core/base/interfaces/object-api.interface';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GenericProductService extends BaseService<GenericProductModel>{
    defaultSort = 'createdAt';

    constructor(public http: HttpClient) {
        super(http, 'generic-product');
    }

    findAllShopGenerics() {
        return this.http.get<ObjectApiInterface<any>>(`${this.baseUrlApi}/list/generics`)
            .pipe(map(x => {
                this._entity.next(x.data);
                return x.data;
            }));
    }

    addCharacteristic(genericProductId: string, data: any): Observable<ObjectApiInterface<GenericProductModel>> {
        return this.http.post<ObjectApiInterface<GenericProductModel>>(`${this.baseUrlApi}/${genericProductId}/addCharacteristic`, data)
            .pipe(map(x => {
                this._entity.next(x.data);
                return x;
            }));
    }

    updateCharacteristic(genericProductId: string, data: any): Observable<ObjectApiInterface<GenericProductModel>> {
        return this.http.put<ObjectApiInterface<GenericProductModel>>(`${this.baseUrlApi}/${genericProductId}/updateCharacteristic/${data._id}`, data)
            .pipe(map(x => {
                this._entity.next(x.data);
                return x;
            }));
    }

    deleteCharacteristic(genericProductId: string, dataId: any): Observable<ObjectApiInterface<GenericProductModel>> {
        return this.http.delete<ObjectApiInterface<GenericProductModel>>(`${this.baseUrlApi}/${genericProductId}/deleteCharacteristic/${dataId}`)
            .pipe(map(x => {
                this._entity.value.characteristics = this._entity.value.characteristics.filter(y => y._id !== dataId);
                return x;
            }));
    }


    findAll(
        page?: number,
        perPage?: number,
        sortField: string = this.defaultSort,
        sortOrder: string = 'desc', search?: string, filters?: GenericProductModel | any, option?: any
    ): Observable<ObjectApiInterface<GenericProductModel[]>> {

        if (option)
        {
            return this.http.get<ObjectApiInterface<GenericProductModel[]>>(`${this.baseUrlApi}/list/${option}`, {
                    params: new HttpParams()
                        .set('page', page?.toString() ?? '1')
                        .set('perPage', perPage?.toString() ?? '30')
                        .set('sort', `${sortField}@${sortOrder}`)
                        .set('search', search ?? '')
                        .set('filters', JSON.stringify(filters) ?? '')
                }
            );
        }else {
            return this.http.get<ObjectApiInterface<GenericProductModel[]>>(`${this.baseUrlApi}/list`, {
                    params: new HttpParams()
                        .set('page', page?.toString() ?? '1')
                        .set('perPage', perPage?.toString() ?? '30')
                        .set('sort', `${sortField}@${sortOrder}`)
                        .set('search', search ?? '')
                        .set('filters', JSON.stringify(filters) ?? '')
                }
            );
        }

    }

    findAllGenItemByGenEngagement(data: string | string [], search?: string, filters?: any) {
        return this.http.get<ObjectApiInterface<GenericProductModel[]>>(`${this.baseUrlApi}/listByGenContract`,
            {
                params: new HttpParams()
                    .set('search', search ?? '')
                    .set('filters', JSON.stringify(filters) ?? '')
                    .set('data', JSON.stringify(data) ?? '')
            }
            )
            .pipe(map(x => {
                return x.data;
            }));
    }
}


