import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Observable, Subscription} from 'rxjs';
import {SnackbarService} from '../../../app/core/services/snackbar.service';

export interface IDialogConfirm {
  description: string;
  supplementaryDescription: {
    label: string;
    color: string;

  };
  title: string;
  validText: string;
  cancelText: string;
  cancelDisabled: boolean;
  call$: Observable<any>;
  additionalIcon?: string;
}

@Component({
  selector: 'vex-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss']
})
export class DialogConfirmComponent implements OnInit, OnDestroy {

  subscription = new Subscription();
  loading = false;

  constructor(
      private snackbarService: SnackbarService,
      private dialog: MatDialogRef<DialogConfirmComponent>,
              @Inject(MAT_DIALOG_DATA) public data: IDialogConfirm) { }

  ngOnInit(): void {
  }

  validate() {
    this.loading = true;
    this.subscription.add(
        this.data.call$.subscribe(x => {
              this.dialog.close(x);
              this.loading = false;
            },
            error => {

              if (error?.error?.statusCode === 5509) {
                this.snackbarService.danger(error.error.message);
              }

              this.loading = false;
              this.dialog.close();
            })
    );
  }

  close() {
    this.dialog.close();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
