import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../../core/base/base.service';
import { HotTopicModel } from '../../../../core/models/hot-topic.model';
import { AddressModel } from '../../../../core/models/address.model';
import { Observable } from 'rxjs';
import { ObjectApiInterface } from '../../../../core/base/interfaces/object-api.interface';
import { map } from 'rxjs/operators';
import { DialogConfirmComponent } from '../../../../../@vex/components/dialog-confirm/dialog-confirm.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
})
export class HotTopicService extends BaseService<HotTopicModel>{
    defaultSort = 'createdAt';
    constructor(
        public http: HttpClient,
        public dialog: MatDialog
    ) {
        super(http, 'hot-topic');
    }

    createContractTopic(hotTopic: HotTopicModel): Observable<ObjectApiInterface<HotTopicModel>> {
        return this.http.post<ObjectApiInterface<HotTopicModel>>(`${this.baseUrlApi}/createContractTopic`, hotTopic)
            .pipe(map(x => {
                return x;
            }));
    }

    updateContractTopic(hotTopic: HotTopicModel): Observable<ObjectApiInterface<HotTopicModel>> {
        return this.http.put<ObjectApiInterface<HotTopicModel>>(`${this.baseUrlApi}/updateContractTopic/` + hotTopic._id, hotTopic)
            .pipe(map(x => {
                return x;
            }));
    }

    archiveItem(element: HotTopicModel) {

        const description = (isArchive: boolean) => {
            let text = '';
            if (isArchive) {
                text = 'Voulez-vous désarchiver l\'actualité ?';
            } else {
                text = 'Voulez-vous archiver l\'actualité ?\n L\'actualité ne sera plus visible dans le carrousel';
            }
            return text;
        };

        return this.dialog.open(DialogConfirmComponent, {
            data: {
                title: (element.isArchive ? 'Désarchivage' : 'Archivage') + ' de l\'actualité',
                description: description(element.isArchive),
                cancelText: 'NON',
                validText: 'OUI',
                call$: (element.isArchive ? this.unarchive(element) : this.archive(element))
            }
        });
    }

}

