import { RandomUtils } from './random-utils';


export enum TimeUnit {
   MINUTE = 'minute',
   HOUR = 'hour',
   DAY = 'day',
   BUSINESS_DAY = 'businessDay'
}

export enum TimeUnitDisplay {
   HOUR = 'hour',
   DAY = 'day',
   BUSINESS_DAY = 'businessDay'
}

/**
 * quantity always in minutes
 */
export interface TimeDelay {
   displayedUnit: TimeUnit;
   quantity: number;
}

/**
 * Convertit une valeur en minutes dans l'unité donnée en une quantité affichée.
 */
export const convertMinutesToUnit = (minutes: number, unit: TimeUnit): number => {
   switch (unit) {
      case TimeUnit.MINUTE:
         return minutes;
      case TimeUnit.HOUR:
         return minutes / 60;
      case TimeUnit.DAY:
         return minutes / 1440;
      case TimeUnit.BUSINESS_DAY:
         return minutes / 480;
      default:
         throw new Error('Unsupported time unit');
   }
};

export const convertToMinutes = (quantity: number, unit: TimeUnit) => {
   switch (unit) {
      case TimeUnit.MINUTE:
         return quantity;
      case TimeUnit.HOUR:
         return quantity * 60;
      case TimeUnit.DAY:
         return quantity * 1440; // 24 * 60
      case TimeUnit.BUSINESS_DAY:
         return quantity * 480; // 8 * 60
      default:
         throw new Error('Unsupported time unit');
   }
};

export const convertToUnit = (delay: TimeDelay): number => {
   switch (delay.displayedUnit) {
      case TimeUnit.MINUTE:
         return delay.quantity;
      case TimeUnit.HOUR:
         return delay.quantity / 60;
      case TimeUnit.DAY:
         return delay.quantity / 1440; // 24 * 60
      case TimeUnit.BUSINESS_DAY:
         return delay.quantity / 480; // 8 * 60
      default:
         throw new Error('Unsupported time unit');
   }
};


export const translateTimeUnit = (unit: TimeUnitDisplay): string => {
   switch (unit) {
      case TimeUnitDisplay.HOUR:
         return 'heures';
      case TimeUnitDisplay.DAY:
         return 'jours';
      case TimeUnitDisplay.BUSINESS_DAY:
         return 'jours ouvrés';
      default:
         throw new Error('Unsupported time unit');
   }
};


export const translateTimeUnitObject = (status: TimeUnitDisplay) => {
   return {
      _id: RandomUtils.generateId(),
      value: status,
      label: translateTimeUnit(status)
   };
};

export const translateTimeDelay = (delay: TimeDelay) => {
   return {
      _id: RandomUtils.generateId(),
      value: delay,
      label: `${translateTimeUnit(delay.displayedUnit as any)}`
   };
};
