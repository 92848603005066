import {MsalGuardConfiguration, MsalInterceptorConfiguration} from '@azure/msal-angular';
import {environment} from '../../../environments/environment';
import {BrowserCacheLocation, InteractionType, IPublicClientApplication, PublicClientApplication} from '@azure/msal-browser';
import { ConfigService } from '../../../@vex/services/config.service';
import { MsalConfigService } from '../services/msal.config.service';

export function MSALInterceptorConfigFactory(msalConfigService: MsalConfigService): MsalInterceptorConfiguration {
    return msalConfigService.MSALInterceptorConfigFactory();
}

export function MSALGuardConfigFactory(msalConfigService: MsalConfigService): MsalGuardConfiguration {
    return msalConfigService.MSALGuardConfigFactory();
}

export function MSALInstanceFactory(msalConfigService: MsalConfigService): IPublicClientApplication {
    return msalConfigService.MSALInstanceFactory();
}
