import {Component} from '@angular/core';
import {ProductModel} from 'src/app/core/models/product.model';
import {ProductService} from './product.service';
import {MatDialog} from '@angular/material/dialog';
import {
    ProductCreateUpdateDeleteComponent
} from './product-create-update-delete/product-create-update-delete.component';
import {FeiColumn} from 'src/app/core/base/interfaces/fei-column.interface';
import {DataTableBase} from 'src/app/core/base/components/data-table-base/data-table-base';
import {CrudModeEnum} from 'src/app/core/base/enum/crud-mode.enum';
import {MatTableDataSource} from '@angular/material/table';
import {map} from 'rxjs/operators';
import {GenericProductService} from '../../settings/generic-product/generic-product.service';
import {SupplierService} from '../supplier/supplier.service';
import {Observable} from 'rxjs';
import {SupplierModel} from '../../../../core/models/supplier.model';
import {GenericProductModel} from '../../../../core/models/generic-product.model';
import {FormControl, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {SnackbarService} from '../../../../core/services/snackbar.service';
import {AuthService} from '../../../../core/services/auth.service';
import { TvaModel } from '../../../../core/models/tva.model';
import { TvaService } from '../../settings/tva/tva.service';

@Component({
    selector: 'vex-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss'],
})
export class ProductComponent extends DataTableBase<ProductModel> {

    suppliers$: Observable<SupplierModel[]>;
    genericProducts$: Observable<GenericProductModel[]>;

    feisColumns: Array<FeiColumn> = [
        {label: 'Type', column: 'quoteId', type: 'custom'},
        {label: 'Identifiant Technique', column: 'technicalIdentifier'},
        {label: 'Référence', column: 'reference', type: 'custom'},
        {label: 'Fournisseur', column: 'supplier', propertyDisplay: 'supplier.label'},
        {label: 'Article générique', column: 'genericProduct', propertyDisplay: 'genericProduct.label'},
        {label: 'Libellé', column: 'label', type: 'custom'},
        {label: 'Fabricant', column: 'manufacturer'},
        {label: 'Prix H.T', column: 'priceHT', additionalDisplay: ' €', type: 'number'},
        {label: 'Taux de TVA', column: 'tva', type: 'custom'},
        {label: 'Prix TTC', column: 'priceTTC', additionalDisplay: ' €', type: 'number'},
        {label: 'Périssable', column: 'genericProduct.perishable', type: 'boolean'},
        {label: 'Stockable', column: 'stockable', type: 'boolean'},
        {label: 'Disponible au catalogue', column: 'isAvailable', type: 'boolean'},
        // { label: 'Catégorie', column: 'category', propertyDisplay: 'category.label', type: 'category' },
        {label: '', column: 'actions', canDelete: false, canArchive: true, canUpdate: false, canCreate: false} // Pred 1283
    ];

    canShowArchive = true;
    actionText = 'Expiré';
    showComment;
    showPreviousReference;

    constructor(public service: ProductService, private dialog: MatDialog,
                private genericProductService: GenericProductService,
                private supplierService: SupplierService,
                private router: Router,
                private tvaService: TvaService,
                private snackbarService: SnackbarService,
                private authService: AuthService
    ) {
        super();
        this.displayedColumns = this.feisColumns.map(x => x.column);
    }

    initData() {
        this.loading = true;

        this.supplierAutocompleteChange('');
        this.genericProductAutocompleteChange('');


        const items = this.service
            .findAll(this.pageIndex, this.pageSize, this.sortField, this.sortOrder, this.search, {...this.filters} )
            .subscribe(res => {
                this.dataSource = new MatTableDataSource<ProductModel>(res.data);
                this.count = res.totalData;
                this.loading = false;

            });

        this.subscription.add(items);

        this.popupForm = new FormGroup({
            supplier: new FormControl(null),
            genericProduct: new FormControl(null),
        });
    }

    getTvaRate(tva: TvaModel) {
        return `${this.tvaService.getRateByDate(tva).rate.toFixed(2)}%`;
    }
    hasPermission() {
        return this.authService.isReferentMetier();
    }

    rowClickItem(element: ProductModel): void {
        if (this.hasPermission()) {
            this.updateItem(element);
        } else {
            this.readItem(element);
        }
    }

    readItem(element: ProductModel): void {
        this.dialog.open(ProductCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                mode: CrudModeEnum.Read
            }
        }).afterClosed().subscribe((product: ProductModel) => {
            if (product) {
                this.initData();
            }
        });
    }

    createItem(): void {
        this.dialog.open(ProductCreateUpdateDeleteComponent).afterClosed().subscribe((product: ProductModel) => {
            if (product) {
                if (product._id) {
                    //     this.snackbarService.warning('Cette nouvelle référence sera accessible au catalogue après validation d’un administrateur.');
                    this.router.navigateByUrl('/management/product/' + product._id).then();
                } else {
                    this.initData();
                }
            }
        });
    }

    updateItem(element: ProductModel): void {
        this.dialog.open(ProductCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                mode: CrudModeEnum.Update
            }
        }).afterClosed().subscribe((product: ProductModel) => {
            if (product) {
                this.initData();
            }
        });
    }

    deleteItem(element: ProductModel) {
        this.dialog.open(ProductCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                name: `${element.technicalIdentifier}`,
                mode: CrudModeEnum.Delete
            }
        }).afterClosed().subscribe((product: ProductModel) => {
            if (product) {
                this.initData();
            }
        });
    }

    archiveItem(element: ProductModel) {
        this.dialog.open(ProductCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                name: `${element.technicalIdentifier}`,
                mode: CrudModeEnum.Archive
            }
        }).afterClosed().subscribe((product: ProductModel) => {
            if (product) {
                this.initData();
            }
        });
    }

    redirectToElement(element: ProductModel) {
        if (element.quoteId) {
            this.router.navigateByUrl('/achat/quote/' + element.quoteId).then();
            return;
        } else {
            this.router.navigateByUrl('/management/engagement/' + element.contractId).then();
        }
    }

    importItems() {
        this.dialog.open(ProductCreateUpdateDeleteComponent, {
            data: {
                mode: CrudModeEnum.Import
            }
        }).afterClosed().subscribe((product: ProductModel[]) => {
            if (product) {
                this.initData();
            }
        });
    }


    supplierAutocompleteChange(search: any) {
        this.suppliers$ = this.supplierService.findAll(null, null, 'label', 'asc', search, null).pipe(map(x => x.data));
    }

    genericProductAutocompleteChange(search: any) {
        this.genericProducts$ = this.genericProductService.findAll(null, null, 'label', 'asc', search, null).pipe(map(x => x.data));
    }

    beforeValidatePopupFilter() {
        if (this.popupForm.get('supplier').value) {
            this.filters.supplier = this.popupForm.get('supplier').value._id;
        }
        if (this.popupForm.get('genericProduct').value) {
            this.filters.genericProduct = this.popupForm.get('genericProduct').value._id;
        }
    }
}
