<vex-page-layout>
    <vex-page-layout-content>
            <div class="mt-6 flex flex-col md:flex-row md:items-start">
                <div class="flex-auto">
                    <div class="card">
                        <div class="px-gutter py-4 border-b" fxLayout='row' fxLayoutAlign='space-between center'>
                            <h2 class='title m-0'>Mon profil</h2>
                            <button color='primary' mat-raised-button type='button' (click)='updateItem()'>MODIFIER</button>
                        </div>

                        <div *ngIf='currentUser?._id; else loading' class="px-gutter py-4" gdColumns="1fr 1fr" gdColumns.xs="1fr" gdGap="16px">

                            <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                                <div @scaleIn
                                     class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                                    <ic-icon [icon]="icWork" size="20px"></ic-icon>
                                </div>

                                <div @fadeInRight>
                                    <p class="m-0 body-1">{{ currentUser.lastName | titlecase }}</p>
                                    <p class="m-0 caption text-hint">Nom</p>
                                </div>
                            </div>

                            <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                                <div @scaleIn
                                     class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                                    <ic-icon [icon]="icPhone" size="20px"></ic-icon>
                                </div>

                                <div @fadeInRight>
                                    <p class="m-0 body-1">{{ currentUser.firstName | titlecase }}</p>
                                    <p class="m-0 caption text-hint">Prénom</p>
                                </div>
                            </div>

                            <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                                <div @scaleIn
                                     class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                                    <ic-icon [icon]="icMail" size="20px"></ic-icon>
                                </div>

                                <div @fadeInRight>
                                    <p class="m-0 body-1">{{ currentUser.mobileNumber }}</p>
                                    <p class="m-0 caption text-hint">Téléphone</p>
                                </div>
                            </div>

                            <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                                <div @scaleIn
                                     class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                                    <ic-icon [icon]="icAccessTime" size="20px"></ic-icon>
                                </div>

                                <div @fadeInRight>
                                    <p class="m-0 body-1">{{ currentUser.email }}</p>
                                    <p class="m-0 caption text-hint">Email</p>
                                </div>
                            </div>

                            <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                                <div @scaleIn
                                     class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                                    <ic-icon [icon]="icAccessTime" size="20px"></ic-icon>
                                </div>

                                <div @fadeInRight>
                                    <p class="m-0 body-1">{{ currentUser.mcEmail }}</p>
                                    <p class="m-0 caption text-hint">Email Microsoft</p>
                                </div>
                            </div>

                            <!--                       TODO: Delete this part if role work for predik

                                <div class="py-3" fxLayout="row" fxLayoutAlign="start center">-->
                            <!--                    <div @scaleIn-->
                            <!--                         class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">-->
                            <!--                        <ic-icon [icon]="icAccessTime" size="20px"></ic-icon>-->
                            <!--                    </div>-->

                            <!--                    <div @fadeInRight>-->
                            <!--                        <p class="m-0 body-1">{{ currentUser?.role?.name | titlecase }}</p>-->
                            <!--                        <p class="m-0 caption text-hint">Rôle</p>-->
                            <!--                    </div>-->

                            <!--                </div>-->

                            <div class="py-3" fxLayout="row" fxLayoutAlign="start center" *ngIf='currentUser?.contactType?.length > 0'>
                                <div @scaleIn
                                     class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                                    <mat-icon>assignment</mat-icon>
                                </div>

                                <div @fadeInRight>
                        <span class="m-0 body-1" *ngFor='let contactType of currentUser?.contactType; index as i'>
                            <span *ngIf='i !== 0'>, </span>
                            {{ contactType.label }}
                        </span>
                                    <p class="m-0 caption text-hint">Type d'acteur</p>
                                </div>
                            </div>

                            <!--           TODO: Delete this part if role work for predik
                                   <div class="py-3" fxLayout="row" fxLayoutAlign="start center" *ngIf='currentUser?.contactType?.length > 0'>-->
                            <!--                    <div @scaleIn-->
                            <!--                         class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">-->
                            <!--                        <mat-icon>assignment</mat-icon>-->
                            <!--                    </div>-->

                            <!--                    <div @fadeInRight>-->
                            <!--                        <span class="m-0 body-1" *ngFor='let role of currentUser?.roles; index as i'>-->
                            <!--                            <span *ngIf='i !== 0'>, </span>-->
                            <!--                            {{ role }}-->
                            <!--                        </span>-->
                            <!--                        <p class="m-0 caption text-hint">Rôle</p>-->
                            <!--                    </div>-->
                            <!--                </div>-->

                            <div class="py-3" fxLayout="row" fxLayoutAlign="start center" *ngIf='currentUser?.isPredik'>
                                <div @scaleIn
                                     class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                                    <ic-icon [icon]="icWork" size="20px"></ic-icon>
                                </div>

                                <div @fadeInRight>
                                    <p class="m-0 body-1">Oui</p>
                                    <p class="m-0 caption text-hint">Contact Predik</p>
                                </div>
                            </div>

<!--                            https://empirys.atlassian.net/jira/software/c/projects/PRED/boards/13?selectedIssue=PRED-1301-->
<!--                            <div class="py-3" fxLayout="row" fxLayoutAlign="start center">-->
<!--                                <div @scaleIn-->
<!--                                     class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">-->
<!--                                    <ic-icon [icon]="icWork" size="20px"></ic-icon>-->
<!--                                </div>-->

<!--                                <div @fadeInRight>-->
<!--                                    <p class="m-0 body-1">{{ translateValidatorMatriceLevel }}</p>-->
<!--                                    <p class="m-0 caption text-hint">Niveau de validation</p>-->
<!--                                </div>-->
<!--                            </div>-->
                            <!--<div class="py-3" fxLayout="row" fxLayoutAlign="start center" *hasPermission="[ENUM_PERMISSION.RECEIVE_NOTIFICATION]">-->
                            <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                                <div @scaleIn
                                     class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                                    <ic-icon [icon]="currentUser?.notifyByEmail ? icCheck : icClose" size="20px"></ic-icon>
                                </div>

                                <div @fadeInRight>
                                    <p class="m-0 caption text-hint">Notification par e-mail</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ng-template #loading>
                <div class="px-gutter py-4" gdColumns="1fr 1fr" gdColumns.xs="1fr" gdGap="16px">
                    <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                        <vex-skeleton class='w-14 mr-2' heigthTailwind='h-12' [circle]='true'></vex-skeleton>
                        <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
                    </div>

                    <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                        <vex-skeleton class='w-14 mr-2' heigthTailwind='h-12' [circle]='true'></vex-skeleton>
                        <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
                    </div>

                    <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                        <vex-skeleton class='w-14 mr-2' heigthTailwind='h-12' [circle]='true'></vex-skeleton>
                        <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
                    </div>
                    <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                        <vex-skeleton class='w-14 mr-2' heigthTailwind='h-12' [circle]='true'></vex-skeleton>
                        <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
                    </div>

                    <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                        <vex-skeleton class='w-14 mr-2' heigthTailwind='h-12' [circle]='true'></vex-skeleton>
                        <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
                    </div>

                    <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                        <vex-skeleton class='w-14 mr-2' heigthTailwind='h-12' [circle]='true'></vex-skeleton>
                        <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
                    </div>
                </div>
            </ng-template>

    </vex-page-layout-content>
</vex-page-layout>
