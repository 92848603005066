import {Component} from '@angular/core';
import {PrestationModel} from 'src/app/core/models/prestation.model';
import {PrestationService} from './prestation.service';
import {MatDialog} from '@angular/material/dialog';
import {
    PrestationCreateUpdateDeleteComponent
} from './prestation-create-update-delete/prestation-create-update-delete.component';
import {FeiColumn} from 'src/app/core/base/interfaces/fei-column.interface';
import {DataTableBase} from 'src/app/core/base/components/data-table-base/data-table-base';
import {CrudModeEnum} from 'src/app/core/base/enum/crud-mode.enum';
import {FormControl, FormGroup} from '@angular/forms';
import {SupplierService} from '../supplier/supplier.service';
import {Observable} from 'rxjs';
import {SupplierModel} from '../../../../core/models/supplier.model';
import {GenericPrestationModel} from '../../../../core/models/generic-prestation.model';
import {map} from 'rxjs/operators';
import {GenericPrestationService} from '../../settings/generic-prestation/generic-prestation.service';
import {ENUM_PERMISSIONS} from '../../../../core/enums/permission.enum';
import {SnackbarService} from '../../../../core/services/snackbar.service';
import {AuthService} from '../../../../core/services/auth.service';
import {MatTableDataSource} from '@angular/material/table';
import {ProductModel} from '../../../../core/models/product.model';
import {Router} from '@angular/router';
import { TvaModel } from '../../../../core/models/tva.model';
import { TvaService } from '../../settings/tva/tva.service';

@Component({
    selector: 'vex-prestation',
    templateUrl: './prestation.component.html',
    styleUrls: ['./prestation.component.scss']
})
export class PrestationComponent extends DataTableBase<PrestationModel> {
    ENUM_PERMISSION = ENUM_PERMISSIONS;
    suppliers$: Observable<SupplierModel[]>;
    genericPrestations$: Observable<GenericPrestationModel[]>;

    feisColumns: Array<FeiColumn> = [
        {label: 'Type', column: 'quoteId', type: 'custom'},
        {label: 'Identifiant Technique', column: 'technicalIdentifier', propertyDisplay: null},
        {
            label: 'Référence',
            column: 'reference',
            propertyDisplay: null,
            canUpdate: false,
            canDelete: false,
            type: 'custom'
        },
        {
            label: 'Fournisseur',
            column: 'supplier',
            propertyDisplay: 'supplier.label',
            canUpdate: false,
            canDelete: false
        },
        {
            label: 'Prestation générique',
            column: 'genericPrestation',
            propertyDisplay: 'genericPrestation.label',
            canUpdate: false,
            canDelete: false
        },
        {label: 'Libellé', column: 'label', propertyDisplay: null, canUpdate: false, canDelete: false, type: 'custom'},
        {label: 'Prix HT', column: 'priceHT', additionalDisplay: ' €', type: 'number'},
        {label: 'Taux de TVA', column: 'tva', type: 'custom'},
        {label: 'Prix TTC', column: 'priceTTC', additionalDisplay: ' €', type: 'number'},
        {
            label: 'Unité d\'achat',
            column: 'purchaseUnit',
            propertyDisplay: null
        },
        {
            label: 'Nombre d\'interventions/heures',
            column: 'purchaseValue',
            propertyDisplay: null
        },
        {label: 'Disponible au catalogue', column: 'isAvailable', type: 'boolean'},
        // { label: 'Catégorie', column: 'category', propertyDisplay: 'category.label', type: 'category' },
        //     {label: '', column: 'actions', canUpdate : this.authService.hasPermission(ENUM_PERMISSIONS.UPDATE_FM_REF_PURCHASE_SERVICE_INFO)}
        {label: '', column: 'actions', canArchive: true, canUpdate: true}
    ];

    canShowArchive = true;
    actionText = 'Expiré';
    showComment;
    showPreviousReference;


    constructor(public service: PrestationService, private dialog: MatDialog,
                private genericPrestationService: GenericPrestationService,
                private router: Router,
                private supplierService: SupplierService,
                private snackbarService: SnackbarService,
                private authService: AuthService,
                private tvaService: TvaService
    ) {
        super();
        this.displayedColumns = this.feisColumns.map(x => x.column);
    }

    initData() {

        this.filters.isWithRecurrence = true;

        this.supplierAutocompleteChange(null);
        this.genericPrestationAutocompleteChange(null);
        const items = this.service
            .findAll(this.pageIndex, this.pageSize, this.sortField, this.sortOrder, this.search, this.filters)
            .subscribe(res => {
                res.data = res.data.map((entity: any) => ({
                    ...entity,
                    quoteId: entity.quoteId ? entity.quoteId : null
                }));

                this.dataSource = new MatTableDataSource<PrestationModel>(res.data);
                this.count = res.totalData;
                this.loading = false;
            });

        this.subscription.add(items);

        this.popupForm = new FormGroup({
            supplier: new FormControl(null),
            genericPrestation: new FormControl(null),
        });

        console.log("this.popupForm", this.isArchive);
    }

    createItem(): void {
        this.dialog.open(PrestationCreateUpdateDeleteComponent)
            .afterClosed()
            .subscribe((prestation: PrestationModel) => {
                if (prestation) {
                    //      this.snackbarService.warning('Cette nouvelle référence sera accessible au catalogue après validation d’un administrateur.');
                    this.initData();
                }
            });
    }

    hasPermission() {
        return this.authService.isReferentMetier();
    }

    updateItem(element: PrestationModel): void {
        this.dialog.open(PrestationCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                mode: CrudModeEnum.Update
            }
        }).afterClosed().subscribe((prestation: PrestationModel) => {
            if (prestation) {
                this.initData();
            }
        });
    }

    rowClickItem(element: PrestationModel): void {
        if (this.hasPermission()) {
            this.updateItem(element);
        } else {
            this.readItem(element);
        }
    }

    onShowArchived(event) {
        this.isArchive = event;
        this.isExpired = event;
        this.filters = {...this.filters, isArchive: event, isExpired: event};
        this.initData();

        this.changeCanUpdateStatus(event);
    }

    deleteItem(element: PrestationModel) {
        this.dialog.open(PrestationCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                name: `${element.technicalIdentifier}`,
                mode: CrudModeEnum.Delete
            }
        }).afterClosed().subscribe((prestation: PrestationModel) => {
            if (prestation) {
                this.initData();
            }
        });
    }

    archiveItem(element: PrestationModel) {
        this.dialog.open(PrestationCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                name: `${element.technicalIdentifier}`,
                mode: CrudModeEnum.Archive
            }
        }).afterClosed().subscribe((prestation: PrestationModel) => {
            if (prestation) {
                this.initData();
            }
        });
    }

    readItem(element: any) {
        this.dialog.open(PrestationCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                name: `${element.reference}`,
                mode: CrudModeEnum.Read
            }
        }).afterClosed().subscribe((prestation: PrestationModel) => {
            if (prestation) {
                this.initData();
            }
        });
    }

    importItem() {
        this.dialog.open(PrestationCreateUpdateDeleteComponent, {
            data: {
                mode: CrudModeEnum.Import
            }
        }).afterClosed().subscribe((prestation: PrestationModel) => {
            if (prestation) {
                this.initData();
            }
        });
    }

    getTvaRate(tva: TvaModel) {
        return `${this.tvaService.getRateByDate(tva).rate.toFixed(2)}%`;
    }

    beforeValidatePopupFilter() {
        if (this.popupForm.get('supplier').value) {
            this.filters.supplier = this.popupForm.get('supplier').value._id;
        }
        if (this.popupForm.get('genericPrestation').value) {
            this.filters.genericPrestation = this.popupForm.get('genericPrestation').value._id;
        }
    }

    supplierAutocompleteChange(search: any = '') {
        this.suppliers$ = this.supplierService.findAll(null, null, 'label', 'asc', search, null).pipe(map(x => x.data));
    }

    redirectToElement(element: ProductModel) {
        if (element.quoteId) {
            this.router.navigateByUrl('/achat/quote/' + element.quoteId).then();
            return;
        } else {
            this.router.navigateByUrl('/management/engagement/' + element.contractId).then();
        }
    }

    genericPrestationAutocompleteChange(search: any = '') {
        this.genericPrestations$ = this.genericPrestationService.findAll(null, null, 'label', 'asc', search, null).pipe(map(x => x.data));
    }

    changeCanUpdateStatus(event) {
        this.feisColumns = this.feisColumns.map(x => {
            if (x.column === 'actions') {
                x.canUpdate = !event;
            }
            return x;
        });
    }
}
