import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { InputTypeInterface } from '../../../interfaces/input-type.interface';

@Component({
    selector: 'form-text-input',
    templateUrl: './form-text-input.component.html',
    styleUrls: ['./form-text-input.component.scss']
})
export class TextInputComponent {
    @Input() formGroup: FormGroup;
    @Input() controlName: string;
    @Input() label: string;
    @Input() placeholder = '';
    @Input() hint = '';
    @Input() icon: any;
    @Input() obscure = false;
    @Input() defaultErrorMessage: any;
    @Input() invalidPatternMessage: string;
    @Input() customErrorMessage: string;
    @Output() onLostFocus = new EventEmitter<void>();
    @Input() appearance: MatFormFieldAppearance = 'outline';
    @Input() type: typeof InputTypeInterface = 'text';
    @Input() inputTextWidthAuto = false;
    @Input() withoutPadding = false;

    visible = false;
    icVisibility = icVisibility;
    icVisibilityOff = icVisibilityOff;

    toggleInput() {
        this.visible = !this.visible;
        this.type = this.visible ? 'text' : 'password';
    }
}
