<h2 mat-dialog-title>{{ data.title }}</h2>

<mat-dialog-content>

    <div>
        {{ data.description }}
    </div>

    <div class="table-container" style="padding-top: 20px;">
        <table mat-table [dataSource]="dataSource.data">

            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox
                            (change)="$event ? masterToggle() : null"
                            [checked]="isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"
                            [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox
                            (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)"
                            [aria-label]="checkboxLabel(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="label">
                <th mat-header-cell *matHeaderCellDef> Nom du panier</th>
                <td mat-cell *matCellDef="let element"> {{ element?.label }}</td>
            </ng-container>

            <ng-container matColumnDef="previousDate">
                <th mat-header-cell *matHeaderCellDef> Date de l’intervention antérieure</th>
                <td mat-cell *matCellDef="let element"> {{ element?.confirmationDelayDate | date : 'dd/MM/yyyy' }}</td>
            </ng-container>

            <ng-container matColumnDef="nextRecurrence">
                <th mat-header-cell *matHeaderCellDef> Date de la prochaine intervention</th>
                <td mat-cell *matCellDef="let element"> {{ element?.nextRecurrence | date : 'dd/MM/yyyy' }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
            </tr>
        </table>
    </div>

</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px" class="mt-4">

    <button mat-button (click)="close()">{{ data.cancelText | uppercase }}</button>
    <button mat-button color="primary" cdkFocusInitial (click)="validate()"
            [loading]="loading">{{ data.validText | uppercase }}
    </button>

</mat-dialog-actions>
