import {BrowserModule} from '@angular/platform-browser';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
  NO_ERRORS_SCHEMA
} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {VexModule} from '../@vex/vex.module';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpXhrBackend} from '@angular/common/http';
import {ApplicationLayoutModule} from './core/layout/application-layout/application-layout.module';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalRedirectComponent,
  MsalService
} from '@azure/msal-angular';
import {MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory} from './core/config/msal.config';
import {AuthInterceptor} from './core/interceptors/auth.interceptor';
import {FileSaverModule} from 'ngx-filesaver';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {MatChipsModule} from '@angular/material/chips';

import {MatPaginatorIntlCro} from './core/base/providers/mat-paginator-intl-cro.provider';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {lastValueFrom, tap} from 'rxjs';
import {ConfigService} from '../@vex/services/config.service';
import {MsalConfigService} from './core/services/msal.config.service';
import {SnackbarMessageInterceptor} from './core/interceptors/snackbar-message.interceptor';
import {ChunckLoadInterceptor} from './core/interceptors/chunck-load.interceptor';
import {RequestToLargeInterceptor} from './core/interceptors/request-to-large.interceptor';
import {PermissionInterceptor} from './core/interceptors/permission.interceptor';
import {ErrorCrudInterceptor} from './core/interceptors/error-crud.interceptor';

registerLocaleData(localeFr, 'fr');

export function initEnv(configService: ConfigService) {
    const httpClient = new HttpClient(new HttpXhrBackend({
        build: () => new XMLHttpRequest()
    }));
    const url = `${window.location.origin}/api/initEnv`;
    return () => lastValueFrom(httpClient.get<any>(url).pipe(tap(r => configService.env = r.data)));
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        VexModule,
        ApplicationLayoutModule,
        FileSaverModule,
        MatChipsModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        })
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initEnv,
            multi: true,
            deps: [ConfigService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
            deps: [MsalConfigService]
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
            deps: [MsalConfigService]
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
            deps: [MsalConfigService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: PermissionInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestToLargeInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SnackbarMessageInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorCrudInterceptor,
            multi: true
        },
        {provide: ErrorHandler, useClass: ChunckLoadInterceptor},
        {provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro},
        {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
        {provide: LOCALE_ID, useValue: 'fr'},
        MsalService,
        MsalGuard,
        MsalBroadcastService,

    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
    exports: []
})
export class AppModule {
}
