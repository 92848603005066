import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SupplierContactModel} from '../../../../../../core/models/supplier-contact.model';
import {CrudModeEnum} from '../../../../../../core/base/enum/crud-mode.enum';
import {Subscription} from 'rxjs';
import {ContactTypeModel} from '../../../../../../core/models/contact-type.model';
import icClose from '@iconify/icons-ic/twotone-close';
import {ContactTypeService} from '../../../../settings/contact-type/contact-type.service';
import {SupplierService} from '../../supplier.service';
import {ActivatedRoute, Router} from '@angular/router';
import {setErrorsMessages} from '../../../../../../core/helpers/form.error.helper';
import {ErrorApi} from '../../../../../../core/models/api/error-api';
import {SupplierContactService} from '../../supplier-contact.service';
import {BaseContactType} from '../../../../../../core/enums/contact-type.enum';
import {GenderWithEntity} from '../../../../../../core/enums/gender-with-entity.enum';
import {Typology} from '../../../../../../core/enums/typology.enum';
import {SnackbarService} from '../../../../../../core/services/snackbar.service';
import {ResponseTypeEnum} from '../../../../../../core/base/enum/response-type.enum';
import {BaseService} from '../../../../../../core/base/base.service';
import {FormBaseComponent} from '../../../../../../core/base/components/form-base/form-base.component';
import {FormModalBaseComponent} from '../../../../../../core/base/components/form-modal-base/form-modal-base.component';
import {SupplierModel} from '../../../../../../core/models/supplier.model';


@Component({
    selector: 'vex-supplier-contact-create-update',
    templateUrl: './supplier-contact-create-update-delete.component.html',
    styleUrls: ['./supplier-contact-create-update-delete.component.scss']
})
export class SupplierContactCreateUpdateDeleteComponent extends FormModalBaseComponent<SupplierContactModel> implements OnInit, OnDestroy {

    protected subscription = new Subscription();

    icClose = icClose;

//    loading = false;
    form: FormGroup;
    defaults: SupplierContactModel;
    isQuote = false;
    genderItem: string[];
    contactTypes: ContactTypeModel[] = [];
    supplierId: string;

    importDefaults: any;
    canCreateMode = false;
    canUpdateMode = false;
    canDeleteMode = false;
    canReadMode = false;
    canImportMode = false;


    successCreateMessage: string;
    successUpdateMessage: string;
    successDeleteMessage: string;
    errorCreateMessage: string;
    errorUpdateMessage: string;
    errorDeleteMessage: string;


    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public dialogRef: MatDialogRef<FormModalBaseComponent<SupplierContactModel>>,
                private contactTypeService: ContactTypeService,
                private supplierService: SupplierService,
                public activatedRoute: ActivatedRoute,
                private router: Router,
                public snackbarService: SnackbarService,
                public service: SupplierContactService
                ) {
        super(data, dialogRef, service);
        if (this.data) {
            this.defaults = this.data?.defaults ?? {} as SupplierContactModel;
            this.mode = this.data.mode;
            this.isQuote = this.data.isQuote ?? false;
        }
    }

    ngOnInit() {
        super.ngOnInit();

        this.canCreateMode = this.isCreateMode();
        this.canUpdateMode = this.isUpdateMode();
        this.canDeleteMode = this.isDeleteMode();
        this.canReadMode = this.isReadMode();
        this.canImportMode = this.isImportMode();

        // this.contactType$ = this.contactTypeService.findAll().pipe(map(d => [...d.data, ...BaseContactType]));
        this.genderItem = Object.values(GenderWithEntity);
        this.supplierId = this.data.id;
        this.contactTypeService.findAll().subscribe(x => {

            this.contactTypes = [...x.data, ...BaseContactType].filter(x => x.typology === Typology.supplier);

            if (this.isUpdateMode()) {
                const a = [];
                this.defaults.contactTypes.forEach(x => {
                    const ct = this.contactTypes.findIndex(y => y._id === x._id);
                    if (ct !== -1) {
                        a.push(this.contactTypes[ct]);
                    }
                });
                this.form.controls.contactTypes.setValue(a);
            }

            if (this.isQuote) {
                const a = this.contactTypes.filter(x => x.label === 'Devis');
                this.form.controls.contactTypes.setValue(a);
            }
        });

        this.form = new FormGroup({
            _id: new FormControl(this.defaults?._id),
            firstName: new FormControl(this.defaults?.firstName, [Validators.required]),
            lastName: new FormControl(this.defaults?.lastName, [Validators.required]),
            mobileNumber: new FormControl(this.defaults?.mobileNumber, [Validators.required, Validators.maxLength(10)]),
            email: new FormControl(this.defaults?.email, [Validators.required, Validators.email]),
            contactTypes: new FormControl(this.defaults?.contactTypes, [Validators.required]),
            gender: new FormControl(this.defaults?.gender)
        });

        // if (this.isReadMode()) {
        //     this.form.controls.type.setValue(this.defaults.contactTypes.label);
        // }
    }


    afterCreateItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
        }
    }

    afterImportItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
            super.ngOnInit();
        }
    }

    afterUpdateItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
        }
    }

    close(data?: any): void {
        this.dialogRef.close(data);
    }

    // save(): void {
    //     //     if (this.isCreateMode()) {
    //     //         this.createItem();
    //     //     } else if (this.isUpdateMode()) {
    //     //         this.updateItem();
    //     //     }
    //     // }

    deleteItem() {
        this.loading = true;
        const sub = this.supplierService.deleteContact(this.supplierId, this.defaults?._id).subscribe(
            result => {
                this.close(result);
                this.loading = false;
            }, error => {
                console.log(error);
                this.snackbarService.danger(error.error.message);
                this.loading = false;
                this.close(false);
            }
        );
        this.subscription.add(sub);
    }

    setErrorsMessages(errors: Array<ErrorApi>) {
        errors?.forEach(error => {
            let err = this.form.controls[error.property].errors;
            if (err === null) {
                err = {messages: []};
            }
            err.messages.push(error.message);
            this.form.controls[error.property].setErrors(err);
        });
        this.form.markAllAsTouched();
    }

    public beforeCreateItem() {
        // Delete gender from form if null so the backend can update it to null
        if (this.defaults?.gender === null) {
            delete this.defaults?.gender;
        }
        if (this.data?.isCreateInContract) {
            this.defaults.isCreateInContract = true;
        }
    }

    createItem() {
        this.loading = true;
        this.defaults = this.form.value;
        this.beforeCreateItem();
        if (this.form.valid) {
            const sub = this.supplierService.createContact(this.supplierId, this.defaults).subscribe(
                res => {
                    console.log('res', res);
                    this.close(res);
                    this.loading = false;
                }, err => {
                    console.log('err', err);
                    setErrorsMessages(err?.error?.errors, this.form);
                    this.loading = false;
                });
            this.subscription.add(sub);
        } else {
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    updateItem() {
        this.loading = true;
        this.defaults = this.form.value;

        this.beforeUpdateItem();
        if (this.form.valid) {
            const sub = this.supplierService.updateContact(this.supplierId, this.defaults).subscribe(
                result => {
                    this.close(result);
                    this.loading = false;
                }, error => {
                    this.setErrorsMessages(error.error.errors);
                    this.loading = false;
                });
            this.subscription.add(sub);
        } else {
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    async importItems() {
        const supplier = await this.supplierService.entity;
        this.importDefaults = this.importForm.value;
        this.beforeImportItem();
        if (this.importForm.valid) {
            this.loading = true;
            const sub = this.supplierService.importContact(this.importDefaults, supplier._id).subscribe(
                result => {
                    this.afterImportItem(result, null);
                    this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Import, result.message);
                }, error => {
                    this.afterImportItem(null, error);
                    this.setImportErrorsMessages(error.error.errors);
                    this.loading = false;
                });
            this.subscription.add(sub);
        } else {
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    isDeleteMode() {
        return this.mode === CrudModeEnum.Delete;
    }

    isCreateMode() {
        return this.mode === CrudModeEnum.Create;
    }

    isUpdateMode() {
        return this.mode === CrudModeEnum.Update;
    }

    isReadMode() {
        return this.mode === CrudModeEnum.Read;
    }

    isImportMode(): boolean {
        return this.mode === CrudModeEnum.Import;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    // contactTypeAutocompleteChange(state: any) {
    //     this.contactType$ = this.contactTypeService.findAll(null, null, null, null, state).pipe(map(d => [...d.data, ...BaseContactType]));
    // }

    public beforeUpdateItem() {
        // Delete gender from form if null so the backend can update it to null
        if (this.defaults?.gender === null) {
            delete this.defaults?.gender;
        }
    }


}
