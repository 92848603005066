import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent {

    @ViewChild('sidenav') sidenav: MatSidenav;

    @Input() content: TemplateRef<any>;
    @Input() contentSidenav: TemplateRef<any>;
    @Input() mode: 'over' | 'side' | 'push' = 'over';
    @Input() position: 'start' | 'end' = 'end';

    open() {
        this.sidenav.open().then(r => console.log('Sidenav is opened'));
    }

    toggle() {
        this.sidenav.toggle().then(r => console.log('Sidenav is toggled'));
    }

    close() {
        this.sidenav.close().then(r => console.log('Sidenav is closed'));
    }
}
