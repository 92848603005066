<!-- Carrousel affiché uniquement s'il est visible -->
<div *ngIf="isVisible" style="margin: 30px" (mouseenter)="pauseAutoSlide()" (mouseleave)="resumeAutoSlide()">
    <mat-card style="position: relative;">
        <!-- Bouton de fermeture dans le coin supérieur droit -->
        <button mat-icon-button
                class="close-button"
                style="position: absolute; top: 8px; left: 8px; z-index: 10;"
                (click)="closeCarousel()">
            <mat-icon>close</mat-icon>
        </button>

        <div *ngIf="hasNotItems" style="margin: 30px">
            <mat-card-content>
                <p>Aucun élément à afficher</p>
            </mat-card-content>
        </div>

        <div *ngIf="!hasNotItems">

            <div fxLayout="row" fxLayoutAlign="space-between center">
                <button
                    mat-icon-button
                    fxFlex="none"
                    (click)="prevSlide()"
                    [disabled]="isAtStart()"
                    [class.disabled-btn]="isAtStart()">
                    <mat-icon>chevron_left</mat-icon>
                </button>

                <div class="carousel-container">
                    <div class="carousel-track"
                         [style.transform]="getTransform()"
                         [style.transition]="getTransition()">
                        <div class="carousel-slide" *ngFor="let item of items; let i = index;">
                            <ng-container *ngTemplateOutlet="item"></ng-container>
                        </div>
                    </div>
                </div>

                <button
                    mat-icon-button
                    fxFlex="none"
                    (click)="nextSlide()"
                    [disabled]="isAtEnd()"
                    [class.disabled-btn]="isAtEnd()">
                    <mat-icon>chevron_right</mat-icon>
                </button>
            </div>

            <div class="carousel-dots"
                 style="position: absolute; bottom: 10px; left: 50%; transform: translateX(-50%);">
              <span *ngFor="let item of items; let i = index"
                    (click)="goToSlide(i)"
                    [class.active]="i === currentIndex"
                    class="dot"></span>
            </div>
        </div>
    </mat-card>
</div>

<!-- Bouton pour réouvrir le carrousel si celui-ci est fermé -->
<div *ngIf="!isVisible" style="margin: 40px">
    <button mat-fab color="primary" (click)="openCarousel()" aria-label="Ouvrir le carrousel">
        <mat-icon>view_carousel</mat-icon>
    </button>
</div>
