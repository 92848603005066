import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { EstablishmentService } from '../../establishment.service';
import { UserProfileService } from '../../../user-profile/user-profile.service';
import icSearch from '@iconify/icons-ic/twotone-search';
import icClose from '@iconify/icons-ic/twotone-close';
import icPersonAdd from '@iconify/icons-ic/twotone-person-add';
import icContacts from '@iconify/icons-ic/twotone-contacts';
import { scaleIn400ms } from '../../../../../../../@vex/animations/scale-in.animation';
import { fadeInRight400ms } from '../../../../../../../@vex/animations/fade-in-right.animation';
import { stagger40ms } from '../../../../../../../@vex/animations/stagger.animation';
import { fadeInUp400ms } from '../../../../../../../@vex/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from '../../../../../../../@vex/animations/scale-fade-in.animation';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {ENUM_PERMISSIONS} from '../../../../../../core/enums/permission.enum';
import {EstablishmentModel} from "../../../../../../core/models/establishment.model";

@Component({
  selector: 'vex-establishment-details-contact',
  templateUrl: './establishment-details-contact.component.html',
  styleUrls: ['./establishment-details-contact.component.scss'],
  animations: [
    scaleIn400ms,
    fadeInRight400ms,
    stagger40ms,
    fadeInUp400ms,
    scaleFadeIn400ms
  ]
})
export class EstablishmentDetailsContactComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscription = new Subscription();

  icClose = icClose;
  icSearch = icSearch;
  icPersonAdd = icPersonAdd;
  establishment: EstablishmentModel;
  icContacts = icContacts;
  private typingTimeout: any = null;

  @ViewChild('search') search: ElementRef;

  ENUM_PERMISSION = ENUM_PERMISSIONS;

  constructor(
      public establishmentService: EstablishmentService,
      private dialog: MatDialog,
      private router: Router,
  ) { }

  ngOnDestroy(): void {
        this.subscription.unsubscribe();
  }

  async ngOnInit() {
    this.initData();
  }
  ngAfterViewInit() {
    this.subscription.add(
        fromEvent(this.search.nativeElement, 'keyup')
            .pipe(
                map(value => {
                  return value;
                }),
                debounceTime(500),
                distinctUntilChanged(),
                tap((value) => {
                  this.establishmentService.getContact(this.establishment._id, this.search.nativeElement.value).subscribe();
                })
            )
            .subscribe()
    );
  }
  initData() {
    this.subscription.add(
        this.establishmentService.entity$.subscribe(entity => {
          this.establishment = entity;
          this.establishmentService.getContact(this.establishment._id).subscribe();
        })
    );
  }

  clearTextFilter() {
    this.search.nativeElement.value = '';
    this.initData();
  }

  openContact(contact: any) {
    this.router.navigate([`/management/user-profile/${contact._id}`]);

    // this.dialog.open(EstablishmentContactCreateUpdateDeleteComponent, {
    //   data: {
    //     defaults: contact,
    //     mode: CrudModeEnum.Read
    //   }
    // }).afterClosed().subscribe((res) => {
    //   if (res) {
    //     // this.initData();
    //   }
    // });
  }
}
