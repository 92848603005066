import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BuildingService} from '../building.service';
import {DetailsBaseComponent} from '../../../../../core/base/components/details-base/details-base.component';
import {BuildingModel} from '../../../../../core/models/building.model';
import {scaleIn400ms} from '../../../../../../@vex/animations/scale-in.animation';
import {fadeInRight400ms} from '../../../../../../@vex/animations/fade-in-right.animation';
import {stagger40ms} from '../../../../../../@vex/animations/stagger.animation';
import {fadeInUp400ms} from '../../../../../../@vex/animations/fade-in-up.animation';
import {scaleFadeIn400ms} from '../../../../../../@vex/animations/scale-fade-in.animation';
import {AuthService} from "../../../../../core/services/auth.service";
import {ENUM_PERMISSIONS} from "../../../../../core/enums/permission.enum";
import {BuildingStatusEnum, buildingStatusTranslation,} from "../../../../../core/enums/building-status.enum";
import {GedDocumentTypeEnum} from "../../../../../core/enums/ged-document-type.enum";

@Component({
    selector: 'vex-building-details',
    templateUrl: './building-details.component.html',
    styleUrls: ['./building-details.component.scss'],
    animations: [
        scaleIn400ms,
        fadeInRight400ms,
        stagger40ms,
        fadeInUp400ms,
        scaleFadeIn400ms
    ]
})
export class BuildingDetailsComponent extends DetailsBaseComponent<BuildingModel> {

    readonly BuildingStatusEnum = BuildingStatusEnum;
    readonly buildingStatusTranslation = buildingStatusTranslation;
    warningMessages = [];
    hasAddress = false;
    hasInsurance = false;
    hasDeliveryEnergyId = false;

    defaultTabs = [
        {
            label: 'INFORMATIONS',
            route: './',
            routerLinkActiveOptions: {exact: true},
            permission : ENUM_PERMISSIONS.READ_BUILDING
        },
        {
            label: 'SALLES',
            route: './room',
            permission: ENUM_PERMISSIONS.READ_BUILDING
        },
        {
            label: 'DOCUMENTS',
            route: './document',
        }
    ];

    constructor(public service: BuildingService, public activatedRoute: ActivatedRoute, public authService: AuthService) {
        super(service, activatedRoute, authService);
    }

    ngOnInit() {
        super.ngOnInit();

        const item = this.service.entity$.subscribe(building => {
                this.generateWarningText(building);
            }
        );

        this.subscription.add(item);
    }
    generateWarningText(entity: BuildingModel) {

        this.hasDeliveryEnergyId = entity.deliveryEnergyId && !!entity.deliveryEnergyId?._id;
        this.hasAddress = entity.addresses && entity.addresses.length > 0;
        this.hasInsurance = entity.requiredDocuments && !!entity.requiredDocuments.find(x => x.documentType === GedDocumentTypeEnum.INSURANCE_CERTIFICATE);

        this.warningMessages = [];

        if (!this.hasDeliveryEnergyId) {
            this.warningMessages.push('Un point de livraison énergie est nécessaire');
        }

        if (!this.hasAddress) {
            this.warningMessages.push('Une adresse doit être renseignée');
        }

        if (!this.hasInsurance) {
            this.warningMessages.push('Une attestation d\'assurance doit être renseignée dans les documents');
        }

   }
}

