import { ErrorApi } from '../models/api/error-api';
import { FormGroup } from '@angular/forms';

export const setErrorsMessages = (errors: Array<ErrorApi>, form: FormGroup): FormGroup => {
    errors?.forEach(error => {
        let err = form.controls[error.property].errors;
        if (err === null) {
            err = {messages: []};
        }
        err.messages.push(error.message);
        form.controls[error.property].setErrors(err);
    });
    return form;
}
