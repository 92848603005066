<ng-container *ngIf="isCreateMode() || isUpdateMode()">
    <form (ngSubmit)="save()" [formGroup]="form">
        <base-form-header [mode]='mode'
                          updateLabel='Ajouter un point de livraison électrique'></base-form-header>

        <mat-dialog-content class="flex items-center">
            <base-form-input
                    label="PDL (point de livraison)"
                    type="autocomplete"
                    [items$]="energyIds$"
                    (autocompleteChange)="energyIdsAutoCompleteChange($event)"
                    displayProperty="energyIdId"
                    controlName="energyId"
                    class="flex-1">
            </base-form-input>

            <button *hasPermission="[ENUM_PERMISSION.UPDATE_BUILDING]"
                    (click)="addEnergyId()"
                    class="ml-4 mb-4"
                    color="primary"
                    mat-mini-fab
                    matTooltip="Créer un nouvel id energy"
                    type="button">
                <mat-icon [icIcon]="icAdd"></mat-icon>
            </button>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <base-form-input
                    type='submit'
                    [mode]='mode'
                    entityLabel='ENERGYID'
                    [loading]='loading'
                    (onUpdate)='updateItem()'
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>
