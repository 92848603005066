import { OrderEmailStatusEnum } from '../../enums/order-status.enum';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OrderStatusTranslationService {
  translateEmailStatus(status: OrderEmailStatusEnum): string {

    const statusMap = {
      [OrderEmailStatusEnum.IN_PROGRESS]: 'En cours',
      [OrderEmailStatusEnum.DELIVERED]: 'Délivré',
      [OrderEmailStatusEnum.OPENED]: 'Ouvert',
      [OrderEmailStatusEnum.FAILED]: 'En anomalie',
      [OrderEmailStatusEnum.UNKNOWN]: 'Inconnu',
    };
    return status ? statusMap[status] : 'Non défini';
  }
}
