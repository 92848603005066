export enum UserProfileValidatorSeuil {
    A = 'A',
    B = 'B',
    C = 'C'
}

export enum UserProfileValidatorLevel {
    HIGH = 'HIGH',
    MEDIUM = 'MEDIUM',
    LOW = 'LOW',
}

export const userProfileValidatorLevelTransalation = (level: UserProfileValidatorLevel) => {
    switch (level) {
        case UserProfileValidatorLevel.LOW.toString():
            return 'Minimum';
        case UserProfileValidatorLevel.MEDIUM.toString():
            return 'Medium';
        case UserProfileValidatorLevel.HIGH.toString():
            return 'Maximum';
    }
}