<ng-container *ngIf="isUpdateMode() || isReadMode()">
    <div class="flex flex-row">
        <form (ngSubmit)="save()" [formGroup]="form">
            <base-form-header [mode]='mode' createLabel="Ajout d'une prestation"
                              [updateLabel]='form?.get("reference")?.value'></base-form-header>

        <mat-dialog-content fxLayout="column">

          <base-form-input
            *ngIf="isPredik()"
            label='Prestation Générique'
            [disabled]='isReadMode()'
            type='autocomplete'
            [items$]='genericPrestations$'
            (autocompleteChange)='genericPrestationAutocompleteChange($event)'
            displayProperty='label'
            controlName='genericPrestation'>
          </base-form-input>

             <base-form-input
                 [disabled]='isReadMode()'
                label='Référence'
                controlName='reference'>
            </base-form-input>

            <base-form-input
                    [disabled]='isReadMode()'
                    label='Libellé'
                    controlName='label'>
            </base-form-input>

            <!--  Demande d'Augustine  -->
            <mat-form-field color='primary' class="flex-auto w-full">
                <mat-label>SIRET Fournisseur</mat-label>
                <input disabled placeholder="n° SIRET Frs" matInput
                       [value]="form?.controls?.supplier?.value?.siretNumber">
            </mat-form-field>

            <base-form-input
                    label="Unité d'achat"
                    controlName='purchaseUnit'
                    type="select"
                    [items]="purchaseUnit"
                    [disabled]='isReadMode()'
            >
            </base-form-input>

            <div class="flex space-x-3">
                <base-form-input class="w-1/2"
                                 [disabled]="isReadMode()"
                                 label="Prix HT"
                                 type="number"
                                 controlName="priceHT">
                </base-form-input>
                <base-form-input class="w-1/2"
                                 [disabled]=true
                                 label="Prix TTC"
                                 type="number"
                                 controlName="priceTTC">
                </base-form-input>
            </div>

            <base-form-input
                    label="Catalogue public du fournisseur"
                    controlName="generalSupplierCatalog"
                    invalidPatternMessage='Le champs doit être rempli avec une URL'
                    [disabled]='isReadMode()'
            >
            </base-form-input>

            <base-form-input
                    label="Fiche méthode"
                    controlName="technicalSheet"
                    invalidPatternMessage='Le champs doit être rempli avec une URL'
                    [disabled]='isReadMode()'
            >
            </base-form-input>

            <base-form-input
                    label="Protocole de sécurité"
                    controlName="safetyUsageProtocol"
                    invalidPatternMessage='Le champs doit être rempli avec une URL'
                    [disabled]='isReadMode()'
            >
            </base-form-input>

                <div class="pb-2 text-input">
                    <p>Informations complémentaires</p>
                </div>
                <quill-editor
                        placeholder='Veuillez saisir les informations complémentaires ici'
                        [modules]="quillConfig"
                        [formControl]='form?.controls?.comment'
                        [disabled]='isReadMode()'
                >
                </quill-editor>

<!--                <details-line-base-->
<!--                        *ngIf='isReadMode()'-->
<!--                        hint="Créer depuis une demande de devis"-->
<!--                        type="boolean"-->
<!--                        [label]='!!form?.get("quoteId").value'-->
<!--                >-->
<!--                </details-line-base>-->

                <base-form-input
                        *ngIf='hasPermission()'
                        label="Disponible au catalogue"
                        type='checkbox'
                        controlName='isAvailable'
                        [disabled]='isReadMode() || !canUpdateAvailableCatalogPermission'
                >
                </base-form-input>

                <details-line-base
                        *ngIf='isReadMode()'
                        hint="Intervention nécessaire"
                        type="boolean"
                        [label]='!!form?.get("interventionRequired").value'
                >
                </details-line-base>

            </mat-dialog-content>

            <mat-dialog-actions align="end" *ngIf="hasPermission()">
                <base-form-input
                        type='submit'
                        [mode]='mode'
                        [loading]='loading'
                        entityLabel='PRESTATION'
                        (onCreate)='createItem()'
                        (onUpdate)='updateItem()'
                >
                </base-form-input>
            </mat-dialog-actions>
        </form>
    </div>
</ng-container>

<ng-container *ngIf="isCreateMode()">

    <div class="flex flex-row">
        <form (ngSubmit)="save()" [formGroup]="form">
            <base-form-header [mode]='mode' createLabel="Ajout d'une prestation"
                              [updateLabel]='form?.get("reference")?.value'></base-form-header>

            <mat-dialog-content fxLayout="column">

                <base-form-input
                        *ngIf="hasPermission()"
                        label='Prestation Générique'
                        type='autocomplete'
                        [items$]='genericPrestations$'
                        (autocompleteChange)='genericPrestationAutocompleteChange($event)'
                        displayProperty='label'
                        controlName='genericPrestation'>
                </base-form-input>

                <base-form-input
                        label='Libellé'
                        controlName='label'>
                </base-form-input>

                <base-form-input
                        label='Fournisseur'
                        type='autocomplete'
                        [items$]='suppliers$'
                        [disabled]='isReadMode()'
                        (autocompleteChange)='supplierAutocompleteChange($event)'
                        displayProperty='label'
                        controlName='supplier'>
                </base-form-input>

                <!--  Demande d'Augustine  -->
                <mat-form-field color='primary' class="flex-auto w-full">
                    <mat-label>SIRET Fournisseur</mat-label>
                    <input disabled placeholder="n° SIRET Frs" matInput
                           [value]="form?.controls?.supplier?.value?.siretNumber">
                </mat-form-field>

                <base-form-input
                        label='Référence Fournisseur'
                        controlName='reference'>
                </base-form-input>

                <base-form-input
                        label="Référence précédente"
                        controlName="previousReference"
                >
                </base-form-input>

                <base-form-input
                        label='Taux TVA'
                        type='autocomplete'
                        [items$]='tva$'
                        [disabled]='isReadMode()'
                        (autocompleteChange)='tvaAutocompleteChange($event)'
                        displayProperty='genericTva.label'
                        controlName='tva'>
                </base-form-input>

                <base-form-input
                        label='Compte comptable TVA'
                        [disabled]='isReadMode()'
                        type='select'
                        [items]='tvaAccountingAccounts'
                        displayProperty='display'
                        controlName='tvaAccountingAccount'>
                </base-form-input>

                <base-form-input
                        label="Unité d'achat"
                        controlName='purchaseUnit'
                        type="select"
                        [items]="purchaseUnit"
                        [disabled]='isReadMode()'
                >
                </base-form-input>

                <div class="flex flex-col sm:flex-row">
                    <base-form-input class='sm:mr-3'
                                     [disabled]='isReadMode()'
                                     label='Prix HT'
                                     type='number'
                                     controlName='priceHT'>
                    </base-form-input>
                    <base-form-input class='sm:mr-3'
                                     [disabled]='isReadMode()'
                                     label='Prix TTC'
                                     type='number'
                                     controlName='priceTTC'>
                    </base-form-input>
                    <base-form-input class='sm:ml-3'
                                     [disabled]='isReadMode()'
                                     [label]="purchaseValueLabel"
                                     type='number'
                                     controlName='purchaseValue'>
                    </base-form-input>
                </div>

                <base-form-input
                        label="Catalogue public du fournisseur"
                        controlName="generalSupplierCatalog"
                        invalidPatternMessage='Le champs doit être rempli avec une URL'
                        [disabled]='isReadMode()'
                >
                </base-form-input>

                <base-form-input
                        label="Fiche méthode"
                        controlName="technicalSheet"
                        invalidPatternMessage='Le champs doit être rempli avec une URL'
                        [disabled]='isReadMode()'
                >
                </base-form-input>

                <base-form-input
                        label="Protocole de sécurité"
                        controlName="safetyUsageProtocol"
                        invalidPatternMessage='Le champs doit être rempli avec une URL'
                        [disabled]='isReadMode()'
                >
                </base-form-input>

                <details-line-base
                        *ngIf='!hasPermission()'
                        hint='Informations complémentaires'
                        type="html"
                        [label]='form?.get("comment").value'
                >
                </details-line-base>

                <div class="pb-2 text-input">
                    <p>Informations complémentaires</p>
                </div>
                <quill-editor
                        placeholder='Veuillez saisir les informations complémentaires ici'
                        [modules]="quillConfig"
                        [formControl]='form?.controls?.comment'
                >
                </quill-editor>

                <base-form-input
                        *ngIf='hasPermission()'
                        label="Disponible au catalogue"
                        type='checkbox'
                        controlName='isAvailable'
                        [disabled]='isReadMode() || !canUpdateAvailableCatalogPermission'
                >
                </base-form-input>

            </mat-dialog-content>

            <mat-dialog-actions align="end" *ngIf="hasPermission()">
                <base-form-input
                        type='submit'
                        [mode]='mode'
                        [loading]='loading'
                        entityLabel='PRESTATION'
                        (onCreate)='createItem()'
                        (onUpdate)='updateItem()'
                >
                </base-form-input>
            </mat-dialog-actions>
        </form>
    </div>

</ng-container>

<ng-container *ngIf="isDeleteMode()">
    <form-modal-delete-base
            [loading]='loading'
            [label]='data.name'
            (onClose)='close()'
            (onDelete)='deleteItem()'
    >
    </form-modal-delete-base>
</ng-container>

<ng-container *ngIf="isArchiveMode()">
    <form-modal-archive-base
            [loading]="loading"
            [label]="data.name"
            (onClose)="close()"
            (onArchive)="archiveItem()"
            [title]="isArchived()? 'Désarchiver ' + data.name : 'Archiver ' + data.name"
            [defaultMessage]="isArchived() ? 'Êtes-vous sûr de vouloir désarchiver ' + data.name + ' ?' : 'Êtes-vous sûr de vouloir archiver ' + data.name + ' ?'"
    >
    </form-modal-archive-base>

</ng-container>

<ng-container *ngIf='isImportMode()'>
    <vex-form-import-base
            [modalTitle]='"Importer des prestations"'
            [entityLabel]='"PRESTATIONS"'
            [form]='importForm'
            [service]='service'
            [loading]='loading'
            [mode]='mode'
            (saveForm)='save()'
            (importItems)='importItems()'
            (close)='close()'
            [canDownloadTemplateModel]="canDownloadTemplateModel"
    ></vex-form-import-base>
</ng-container>
