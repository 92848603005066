<div
        class="bg-card  ltr:pr-4 rtl:pr-4 rtl:ml-4 ltr:border-r rtl:border-l search-bar-component"
        [ngStyle]="{ width: searchWidthFull ? '100%' : '400px' }"
        style="border-radius: 6px"
        fxFlex.lt-md="auto"
        fxLayout="row"
        fxLayoutAlign="start center"
>
    <mat-form-field
            class="border-0 md:w-2/5 w-4/5"
            appearance="outline"
            floatLabel="never"
    >
        <input
                class="hidden md:flex"
                type="text"
                placeholder="Toutes les catégories"
                aria-label="Groupe"
                matInput
                [formControl]="genericControl"
                [matAutocomplete]="groupAuto"
        />
        <input
                class="md:hidden"
                type="text"
                placeholder="Catégories"
                aria-label="Groupe"
                matInput
                [formControl]="genericControl"
                [matAutocomplete]="groupAuto"
        />
        <mat-icon *ngIf="!genericControl.value" matSuffix>arrow_drop_down</mat-icon>
        <button *ngIf="genericControl.value" matSuffix mat-icon-button aria-label="Effacer" (click)="clearSelection()">
            <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete #groupAuto="matAutocomplete" panelWidth="auto" [displayWith]="displayGeneric" (optionSelected)="emitSearchData()">
            <mat-optgroup *ngFor="let generic of filteredGenerics | async" [label]="generic.type">
                <mat-option *ngFor="let item of generic.items" [value]="item">
                    {{item.label}}
                </mat-option>
            </mat-optgroup>
        </mat-autocomplete>
    </mat-form-field>

    <!--    <ic-icon [icIcon]="icSearch" class="ml-4" size="20px"></ic-icon>-->
    <input
            matInput
            class="px-4 py-3 border-0 outline-none w-full bg-transparent"
            placeholder="Recherche..."
            [formControl]="search"

    />
    <button
            *ngIf="search.value"
            matSuffix
            mat-icon-button
            aria-label="EFFACER"
            (click)="clearTextFilter()"
    >
        <ic-icon [icIcon]="icClose" size="20px"></ic-icon>
    </button>
    <button
        #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="filterMenu"
        [matMenuTriggerData]="{ closeOnItemClick: false }"
        aria-label="Ouvrir le menu contextuel"
    >
        <ic-icon *ngIf='count === 0' class="filter-list-icon" [icIcon]="icFilterList" size="20px"></ic-icon>
        <ng-container *ngIf='count > 0'>
            <div fxLayout='row' fxLayoutGap='20px' fxLayoutAlign='start center'>
                <!--                <div fxLayout='row' fxLayoutGap='10px' fxLayoutAlign='start center' style='margin-left: 20px' (click)='clear(); $event.stopPropagation();'>-->
                <!--                    <span>EFFACER FILTRES</span>-->
                <!--                </div>-->
                <mat-icon class='cursor-pointer' [matBadge]='count'>
                    filter_list
                </mat-icon>
            </div>
        </ng-container>
    </button>


</div>

<mat-menu #filterMenu="matMenu">
    <ng-container *ngTemplateOutlet="contentPopupTemplate; context: { menuTrigger: menuTrigger }"></ng-container>
</mat-menu>

<ng-template let-menu="closeOnItemClick" #contentPopupTemplate>
    <form [formGroup]="popupForm" class="p-6" (click)="$event.stopPropagation()">
        <div class='title mb-4' header>
            Filtres avancés
        </div>
        <div class='flex flex-col justify-between' content>
            <base-form-input
                    label='Afficher les 20 derniers articles commandés'
                    type='checkbox'
                    controlName='isLastOrder'>
                >
            </base-form-input>
            <base-form-input
                    label='Stockable'
                    type='checkbox'
                    controlName='isInStock'>
                >
            </base-form-input>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="pt-4">
                <button mat-stroked-button color="primary" (click)="clearFilter()">EFFACER FILTRES</button>
                <button mat-raised-button color="primary" (click)="validateFilter(); menuTrigger.closeMenu()">OK</button>
            </div>
        </div>
    </form>
</ng-template>