import {Component, OnInit} from '@angular/core';
import {DataTableBase} from '../../../../../../core/base/components/data-table-base/data-table-base';
import {Router} from '@angular/router';
import {FeiColumn} from '../../../../../../core/base/interfaces/fei-column.interface';
import {EquipmentService} from '../../equipment.service';
import {ENUM_PERMISSIONS} from "../../../../../../core/enums/permission.enum";
import {MatTableDataSource} from "@angular/material/table";
import {ContractService} from "../../../contract/contract.service";
import {ContractModel} from "../../../../../../core/models/contract.model";
import {ObjectApiInterface} from "../../../../../../core/base/interfaces/object-api.interface";
import {ContractStatusEnum, contractStatusTranslationObject} from "../../../../../../core/enums/contract-status.enum";

@Component({
    selector: 'vex-equipment-details-contract',
    templateUrl: './equipment-details-contract.component.html',
    styleUrls: ['./equipment-details-contract.component.scss']
})
export class EquipmentDetailsContractComponent extends DataTableBase<ContractModel> implements OnInit {

    feisColumns: Array<FeiColumn> = [
        {label: 'Identifiant GED', column: 'technicalIdentifier'},
        {label: 'Fournisseur', column: 'supplier', propertyDisplay: 'supplier.label'},
        {label: 'Objet du contrat', column: 'object'},
        {label: 'Début de validité', column: 'startValidityDate', type: 'date'},
        {label: 'Fin de validité', column: 'endValidityDate', type: 'date'},
        {label: 'Statut', column: 'status'},
        //  {label: 'Date d\'échéance', column: 'dueDate', propertyDisplay: 'contract.dueDate', type: 'date'},
    ];

    ENUM_PERMISSION = ENUM_PERMISSIONS;
    equipmentId: string;
    dataSource: MatTableDataSource<ContractModel>;

    constructor(
        private equipmentService: EquipmentService,
        private router: Router,
        private contractService: ContractService,
    ) {
        super();
        this.displayedColumns = this.feisColumns.map(x => x.column);
    }

    ngOnInit(): void {
        this.equipmentService.entity$.subscribe(equipment => {
            this.filters.equipmentId = equipment._id;
            this.equipmentId = equipment._id;
            this.initData();
        });
    }

    initData() {
        this.loading = true;
        const items = this.contractService
            .findAll(this.pageIndex, this.pageSize, this.sortField, this.sortOrder, this.search, {equipmentId: this.equipmentId})
            .subscribe((res: ObjectApiInterface<ContractModel[]>) => {
                if (!this.dataSource) {
                    console.log(res.data, 'error data');
                } else {

                    this.dataSource.data = res.data;
                    // this.dataSource.filteredData.forEach((dataItem, index) => {
                    //
                    //     switch (dataItem.status) {
                    //         case ContractStatusEnum.DRAFT:
                    //             dataItem.status = ContractStatusEnum.BROUILON;
                    //             break;
                    //         case ContractStatusEnum.VALIDATED:
                    //             dataItem.status = ContractStatusEnum.ACTIF;
                    //             break;
                    //         case ContractStatusEnum.EXPIRED:
                    //             dataItem.status = ContractStatusEnum.ECHU;
                    //             break;
                    //     }
                    // });

                    //   this.dataSource = new MatTableDataSource<ContractModel>(res.data);

                    console.log(res.data, 'data source');
                }
                this.count = res.totalData;
                this.loading = false;
            });
        this.subscription.add(items);
    }

    async redirectTo(e: any) {
        await this.router.navigate(['/management/engagement/' + e?._id]);
    }
}
