import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormModalBaseComponent} from 'src/app/core/base/components/form-modal-base/form-modal-base.component';
import {Observable} from 'rxjs';
import {RoleModel, UserProfileRoleModel} from "../../../../../../../core/models/role.model";
import {UserProfileService} from "../../../user-profile.service";
import {UserProfileModel} from "../../../../../../../core/models/user-profile.model";
import {RoleService} from "../../../../../admin/role/role.service";
import {map} from "rxjs/operators";
import {SnackbarService} from "../../../../../../../core/services/snackbar.service";
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {DateAdapter} from "angular-calendar";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {AuthService} from "../../../../../../../core/services/auth.service";
import {ENUM_PERMISSIONS} from "../../../../../../../core/enums/permission.enum";


@Component({
    selector: 'vex-user-profile-details-roles-create-update-delete',
    templateUrl: './user-profile-details-roles-create-update-delete.component.html',
    styleUrls: ['./user-profile-details-roles-create-update-delete.component.scss'],
    providers: [
        {provide: MAT_DATE_LOCALE, useValue: 'fr'},
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        {
            provide: MAT_DATE_FORMATS, useValue: {
                parse: {
                    dateInput: 'DD/MM/YYYY',
                },
                display: {
                    dateInput: 'DD/MM/YYYY',
                    monthYearLabel: 'DD/MM/YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'DD/MM/YYYY'
                },
            }
        }
    ],
})
export class UserProfileDetailsRolesCreateUpdateDeleteComponent extends FormModalBaseComponent<UserProfileModel> implements OnInit, OnDestroy {

    roles$: Observable<RoleModel[]>;

    formAdd: FormGroup;
    formUser: FormGroup;

    assignedRoles: Array<UserProfileRoleModel> = [];
    availableRoles: Array<RoleModel> = [];
    users: Array<UserProfileModel> = [];
    loading = false;

    disabledApplyButton: boolean = true;
    displayAddForm: boolean;

    canAddRolePermission: boolean = false;
    canRemovePermission: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public dialogRef: MatDialogRef<FormModalBaseComponent<UserProfileModel>>,
                public service: UserProfileService,
                public roleService: RoleService,
                public snackbarService: SnackbarService,
                private authService: AuthService
    ) {
        super(data, dialogRef, service);
    }


    ngOnInit() {
        this.loading = true;
        super.ngOnInit();
        this.displayAddForm = false;
        this.initUserForm();
        this.initAddForm();

        this.assignedRoles = Object.assign([], this.data.assignedRoles);
        // refresh assignedRole hint
        this.assignedRoles.forEach(role => {
            role.hint = '';
        });

        // load all roles from api that are not assigned to user
        this.roles$ = this.roleService.findAll(null, null, 'label', 'asc', null).pipe(map(
            x => {
                return x.data.filter(role => !this.assignedRoles.map(x => x._id._id).includes(role._id))
            }
        ));
        //foreach role to display init a form control
        this.assignedRoles.forEach(role => {

            role.form = new FormGroup({
                role: new FormControl(role._id),
                range: new FormGroup({
                    start: new FormControl(role.start),
                    end: new FormControl(role.end),
                })
            })


        });

        // if value change in form remove hint from role changed
        this.assignedRoles.forEach(role => {
            role.form.valueChanges.subscribe(() => {
                role.hint = '';
            });
        });

        //init a form control for adding a new role

        this.subscription.add(this.roles$.subscribe(res => {
                this.loading = false;
                this.availableRoles = res;
                if (this.availableRoles?.length > 0) {
                    this.displayAddForm = true;
                }
            })
        );
        this.loading = false


        this.authService.getCurrentUserPermissions$().subscribe(permissions => {
            this.canAddRolePermission = permissions.includes(ENUM_PERMISSIONS.ADD_CP_INTERNAL_CONTACT_ROLE)
            this.canRemovePermission = permissions.includes(ENUM_PERMISSIONS.DELETE_CP_INTERNAL_CONTACT_ROLE)
        })

    }

    initUserForm() {
        this.subscription.add(this.service.findAll().subscribe(res => {
                this.users = res.data.filter(x => x._id !== this.data._id);
            }
        ));

        this.formUser = new FormGroup({
            user: new FormControl(),
            range: new FormGroup({
                start: new FormControl(),
                end: new FormControl(),
            })
        });

        this.formUser.valueChanges.subscribe(() => {
            this.disabledApplyButton = false;

        });
    }

    initAddForm() {
        this.formAdd = new FormGroup({
            role: new FormControl(),
            range: new FormGroup({
                start: new FormControl(),
                end: new FormControl(),
            })
        });


        this.formAdd.controls.role.valueChanges.subscribe(res => {
            this.displayAddForm = false;
            if (res) {

                const savedRes = Object.assign({}, res);

                res.form = new FormGroup({
                    role: new FormControl(res),
                    range: new FormGroup({
                        start: new FormControl(this.formAdd.value?.range?.start),
                        end: new FormControl(this.formAdd.value?.range?.end),
                    })
                })
                res.start = this.formAdd.value?.range?.start;
                res.end = this.formAdd.value?.range?.end;
                res._id = savedRes


                // push test object in assignedRoles
                this.assignedRoles.push(res)

                // reset formAdd and init new role$ to display
                this.formAdd.reset()
                this.subscription.add(this.roles$.subscribe(res => {
                    this.loading = false;
                    this.availableRoles = res;

                    if (this.availableRoles?.length > 0) {
                        this.displayAddForm = true;
                    }
                }));
            }

        });

    }

    removeRole(role) {
        this.assignedRoles = this.assignedRoles.filter(x => x._id !== role._id)
        this.subscription.add(this.roles$.subscribe(res => {
                this.loading = false;
                this.availableRoles = res;
                if (this.availableRoles?.length > 0) {
                    this.displayAddForm = true;
                }
            })
        );
    }


    updateUserProfileRoles() {

        // map assignedRoles to objectApi with start and end as props
        const roles = this.assignedRoles.map(x => {
            return {
                _id: x._id._id,
                // if x.form.value.range.start isn't a type string  use toISOString() to convert it to string
                start: typeof x.form.value.range?.start === 'string' ? x.form.value.range.start : x.form.value.range.start?.toISOString(),
                end: typeof x.form.value.range?.end === 'string' ? x.form.value.range.end : x.form.value.range.end?.toISOString(),
            }
        });


        const subscription = this.service.updateUserProfileRoles(this.data._id, roles).subscribe(res => {
            this.snackbarService.success('Rôles modifiés avec succès');
            this.dialogRef.close(res);
            this.service.setEntity(res.data);
        }, error => {
            console.log(error);
            this.loading = false
        });

    }


    //foreach selected users.roles add role to assignedRoles
    applyCopy() {
        // if user is selected
        if (this.formUser.value.user) {
            this.formUser.value.user.roles.forEach(role => {
                // if role is already in assignedRoles don't add it just update form start and end and continue
                if (this.assignedRoles.map(x => x._id._id).includes(role._id)) {
                    if (role.start && role.end) {
                        const index = this.assignedRoles.findIndex(x => x._id._id === role._id);


                        // if(!this.assignedRoles[index].form.value.range.start && !this.assignedRoles[index].form.value.range.end)
                        // {
                        //     // this.assignedRoles[index].form.controls.range.setValue({
                        //     //     start: role.start,
                        //     //     end: role.end
                        //     // })
                        // }else{
                        //     this.assignedRoles[index].hint = 'Date non modifiée car déjà existante'
                        // }

                    }
                    return;
                }


                //get complet role object from availableRoles by id
                const roleObject = this.availableRoles.find(x => x._id === role._id);

                // init a new role object with form control and start and end props to push in assignedRoles array
                const newRole = {
                    _id: roleObject,
                    form: new FormGroup({
                        role: new FormControl(roleObject),
                        range: new FormGroup({
                            start: new FormControl(),
                            end: new FormControl(),
                        })
                    }),
                }

                this.assignedRoles.push(newRole);
                this.subscription.add(this.roles$.subscribe(res => {
                    this.loading = false;
                    this.availableRoles = res;
                    this.displayAddForm = this.availableRoles?.length > 0;
                }));

            });

        }
        // if formUser have range start and end set lauch function applyDateUpdate
        if (this.formUser.value.range?.start && this.formUser.value.range?.end) {
            this.applyDateUpdate();
        }

        this.formUser.reset();
        this.disabledApplyButton = true;
    }

    // update start and end date for each role in assignedRoles
    applyDateUpdate() {
        this.assignedRoles.forEach(role => {
            if (!role.start && !role.end) {
                role.form.controls.range.setValue({
                    start: this.formUser.value.range.start,
                    end: this.formUser.value.range.end
                })
            } else {
                role.hint = 'Date non modifiée car déjà existante'
            }

        })

    }

    ngOnDestroy(): void {

        this.subscription.unsubscribe();

    }
}
