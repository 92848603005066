import { Component, OnInit } from '@angular/core';
import {BuildingModel} from 'src/app/core/models/building.model';
import {BuildingService} from './building.service';
import {MatDialog} from '@angular/material/dialog';
import {
    BuildingCreateUpdateDeleteComponent
} from './modals/building-create-update-delete/building-create-update-delete.component';
import {FeiColumn} from 'src/app/core/base/interfaces/fei-column.interface';
import {DataTableBase} from 'src/app/core/base/components/data-table-base/data-table-base';
import {CrudModeEnum} from 'src/app/core/base/enum/crud-mode.enum';
import {FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {EstablishmentModel} from '../../../../core/models/establishment.model';
import {map} from 'rxjs/operators';
import {EstablishmentService} from '../establishment/establishment.service';
import {Router} from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import {ENUM_PERMISSIONS} from '../../../../core/enums/permission.enum';
import {AuthService} from '../../../../core/services/auth.service';
import {BuildingStatusEnum, BuildingStatusTranslation} from '../../../../core/enums/building-status.enum';

@Component({
    selector: 'vex-building',
    templateUrl: './building.component.html',
    styleUrls: ['./building.component.scss']
})
export class BuildingComponent extends DataTableBase<BuildingModel> implements OnInit {

    feisColumns: Array<FeiColumn> = [
        {label: 'Établissements', column: 'establishments',  propertyDisplay: 'listEstablishment'},
        {label: 'Identifiant Technique', column: 'technicalIdentifier'},
        {label: 'Dénomination client', column: 'label'},
        {label: 'Surface Totale (m²)', column: 'surface'},
        {label: 'Nombre d\'étages', column: 'stages', propertyDisplay: 'nbOfLvl'},
        {label: 'Statut de fonctionnent', column: 'isActive', type: 'boolean'},
        {
            label: 'Statut',
            column: 'status',
            propertyDisplay: 'status',
        },
        {
            label: '',
            column: 'actions',
            canUpdate: false,
            canArchive: this.authService.hasPermission(ENUM_PERMISSIONS.DELETE_BUILDING)
        }
    ];

    establishment$: Observable<EstablishmentModel[]>;

    entityName = 'Descriptif bâtiment par établissement';

    ENUM_PERMISSION = ENUM_PERMISSIONS;

    canShowArchivePermission = false;

    constructor(
        public service: BuildingService,
        private dialog: MatDialog,
        private establishmentService: EstablishmentService,
        private router: Router,
        private authService: AuthService,
    ) {
        super();
        this.displayedColumns = this.feisColumns.map(x => x.column);
        this.filters.isStatusNeeded = true;
    }


    initData() {
        super.initData();

        this.establishmentAutoCompleteChange();
        this.popupForm = new FormGroup({
                establishmentId: new FormControl(null),
                stages: new FormControl(null)
            });

        this.loading = true;
        this.requiredFields = ['listEstablishment'];
        const items = this.service.findAll(
                this.pageIndex,
                this.pageSize,
                this.sortField,
                this.sortOrder,
                this.search,
                {...this.filters, requiredFields: this.requiredFields}
            ).subscribe(res => {

                if (!this.dataSource) {
                    this.dataSource = new MatTableDataSource<BuildingModel>(res.data);
                } else {
                    this.dataSource.data = res.data;
                }
                this.count = res.totalData;
                this.loading = false;

        });

        this.subscription.add(items);

        this.authService.getCurrentUserPermissions$().subscribe(permissions => {
            this.canShowArchivePermission = permissions.includes(ENUM_PERMISSIONS.READ_BUILDING);
        });

    }

    createItem(): void {
        this.dialog.open(BuildingCreateUpdateDeleteComponent).afterClosed().subscribe((building: BuildingModel) => {
            if (building) {
                if (building._id) {
                    this.router.navigateByUrl('/management/building/' + building._id).then();
                } else {
                    this.initData();
                }
            }
        });
    }

    updateItem(element: BuildingModel): void {
        this.dialog.open(BuildingCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                mode: CrudModeEnum.Update
            }
        }).afterClosed().subscribe((building: BuildingModel) => {
            if (building) {
                this.initData();
            }
        });
    }

    deleteItem(element: BuildingModel) {
        this.dialog.open(BuildingCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                name: `${element.label}`,
                mode: CrudModeEnum.Delete
            }
        }).afterClosed().subscribe((building: BuildingModel) => {
            if (building) {
                this.initData();
            }
        });
    }

    archiveItem(element: BuildingModel) {
        this.dialog.open(BuildingCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                name: `${element.label}`,
                mode: CrudModeEnum.Archive
            }
        }).afterClosed().subscribe((building: BuildingModel) => {
            if (building) {
                this.initData();
            }
        });
    }

    importItems() {
        this.dialog.open(BuildingCreateUpdateDeleteComponent, {
            data: {
                mode: CrudModeEnum.Import
            }
        }).afterClosed().subscribe((building: BuildingModel) => {
            if (building) {
                this.initData();
            }
        });
    }

    beforeValidatePopupFilter() {
        this.filters.establishmentId = this.popupForm?.controls?.establishmentId?.value?._id;
    }

    establishmentAutoCompleteChange(state: any = '') {
        this.establishment$ = this.establishmentService.findAll(null, null, 'label', 'asc', state).pipe(map((x: any) => x.data));
    }

    protected override transformData(data: any[]): any[] {
        return data.map((building: BuildingModel) => {
            building.status = BuildingStatusTranslation.translate(building.status as BuildingStatusEnum);
            return building;
        });
    }
}
