import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CrudModeEnum } from '../../../../../../../core/base/enum/crud-mode.enum';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserProfileService } from '../../../user-profile.service';
import { filter, map } from 'rxjs/operators';
import icClose from '@iconify/icons-ic/twotone-close';
import { EmployeePositionService } from '../../../../../settings/employee-position/employee-position.service';
import { EmployeePositionModel } from '../../../../../../../core/models/employee-position.model';

@Component({
    selector: 'user-profile-details-resume-poste-form-component',
    templateUrl: 'user-profile-details-resume-poste-form.component.html'
})

export class UserProfileDetailsResumePosteFormComponent implements OnInit {
    form: FormGroup;
    mode: CrudModeEnum = CrudModeEnum.Create;

    employeePosition$: Observable<EmployeePositionModel[]>;
    employeePosition: EmployeePositionModel[];

    icClose = icClose;

    userProfileId: string;

    defaults: EmployeePositionModel;

    loading = false;

    oldEmployeePositionId: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public dialogRef: MatDialogRef<UserProfileDetailsResumePosteFormComponent>,
                public service: UserProfileService,
                private employeePositionService: EmployeePositionService,
    ) {
    }

    ngOnInit() {
        this.userProfileId = this.data.userProfile._id;
        this.mode = this.data.mode;
        this.defaults = this.data?.defaults;
        this.oldEmployeePositionId = this.data?.defaults?._id;


        this.employeePositionChange('');

        this.employeePosition$.subscribe(res => {

            if (this.isUpdateMode()) {
                this.form.controls.employeePosition.setValue(this.data.defaults);
                this.employeePosition = res.filter(x => this.data?.userProfile?.employeePositions.map(y => y.label).indexOf(x.label) < 0 || this.data.defaults.label === x.label);
                if (this.data) {
                    this.data.employeePosition = res.find(x => x.label === this.defaults.label);
                }
            }

            if (this.isCreateMode()) {
                this.employeePosition = res.filter(x => this.data?.userProfile?.employeePositions.map(y => y.label).indexOf(x.label) < 0);
            }

        });


        this.form = new FormGroup({
            // _id: new FormControl(this.defaults?._id),
            // imageSrc: this.defaults.imageSrc,
            employeePosition: new FormControl(this.data?.employeePosition, Validators.required),
            percentage: new FormControl(this.data?.percentage, Validators.required),
        });

        if (this.isUpdateMode()) {
            this.form.controls.employeePosition.setValue(this.data.defaults);
            this.form.controls.percentage.setValue(this.data.defaults.percentage);
        }

    }

    createItem() {
        if (this.form.valid && this.validPercentage()) {
            this.loading = true;
            this.defaults = { _id: this.form.controls.employeePosition.value._id, label: this.form.controls.employeePosition.value.label, percentage: this.form.controls.percentage.value }
            this.service.addEmployeePosition(this.userProfileId, this.defaults).subscribe(res => {
                this.dialogRef.close(res);
            }, error => this.loading = false);
        }
    }

    updateItem() {
        if (this.form.valid && this.validPercentage(true)) {
            this.loading = true;
            this.defaults = { _id: this.form.controls.employeePosition.value._id, label: this.form.controls.employeePosition.value.label, percentage: this.form.controls.percentage.value }
            this.service.updateEmployeePosition(this.userProfileId, this.oldEmployeePositionId, this.defaults).subscribe(res => {
                this.dialogRef.close(res);
            }, error => this.loading = false);
        }
    }

    deleteItem() {
        this.service.deleteEmployeePosition(this.userProfileId, this.data.employeePosition._id).subscribe(res => {
            this.dialogRef.close(res);
        });
    }

    close() {
        this.dialogRef.close();
    }

    save() {

    }

    isCreateMode() {
        return this.mode === CrudModeEnum.Create;
    }

    isUpdateMode() {
        return this.mode === CrudModeEnum.Update;
    }

    isDeleteMode(): boolean {
        return this.mode === CrudModeEnum.Delete;
    }

    validPercentage(isUpdate = false): boolean {
        const newPercentage = this.form.controls.percentage.value;
        const percentage = this.data.userProfile.employeePositions.reduce((a,b) => a + b.percentage, 0);

        if (isUpdate) {
            const oldPercentage = this.data.defaults.percentage;
            console.log(newPercentage + percentage - oldPercentage <= 100);
            return newPercentage + percentage - oldPercentage <= 100;
        }

        if (!(newPercentage + percentage <= 100)){
            this.form.controls.percentage.markAsDirty();
            this.form.controls.percentage.setErrors({ messages: ['Le total des pourcentages doit être inférieur ou égal à 100'] })
        }

        return newPercentage + percentage <= 100;
    }

    employeePositionChange(state) {
        // const employeePositionsLabel = this.data.userProfile.employeePositions.map(x => x.label);
        // @ts-ignore
        this.employeePosition$ = this.employeePositionService.findAll(null, null, null, null ,state).pipe(map(x => x.data));
    }
}
