import {Component, OnDestroy, OnInit} from '@angular/core';
import icSearch from '@iconify/icons-ic/twotone-search';
import {
    ChartData,
    DashboardService,
    DataIncident,
    DataIncidentEnum,
    DetailsDataIncident,
    IdentifierEnum
} from './dashboard.service';
import {NotificationService} from '../notification/notification.service';
import {Router} from '@angular/router';
import {NotificationSeverityEnum} from '../../../enums/notification-severity.enum';
import {ConfigService} from '../../../../../@vex/services/config.service';
import {AuthService} from '../../../services/auth.service';
import {Subscription} from 'rxjs';
import {ENUM_PERMISSIONS} from '../../../enums/permission.enum';
import {MatTableDataSource} from '@angular/material/table';
import {debounceTime, map} from 'rxjs/operators';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {UserProfileModel} from '../../../models/user-profile.model';
import {AffectationModel} from '../../../models/affectation.model';
import {FormControl} from '@angular/forms';

enum DataTableModeEnum {
    MAIN,
    DETAILS
}

class EstablishmentForList {
    id: string;
    label: string;

    constructor(id: string, label: string) {
        this.id = id;
        this.label = label;
    }
}

@Component({
    selector: 'vex-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    providers: [DashboardService],
    animations: [
        trigger('fadeInLeft', [
            transition('false => true', [
                style({opacity: 0, transform: 'translateX(-100%)'}),
                animate('0.7s {{delay}}ms ease-out', style({opacity: 1, transform: 'translateX(0)'})),
            ], {params: {delay: 0}}) // default delay parameter
        ]),
        trigger('fadeInRight', [
            transition('false => true', [
                style({opacity: 0, transform: 'translateX(100%)'}),
                animate('0.7s {{delay}}ms ease-out', style({opacity: 1, transform: 'translateX(0)'})),
            ], {params: {delay: 0}})
        ]),
        trigger('slideFadeInDown', [
            state('void', style({opacity: 0, transform: 'translateY(-20px)'})),
            state('*', style({opacity: 1, transform: 'translateY(0)'})),
            transition(':enter', [ // :enter is alias to 'void => *'
                animate('0.3s ease-out')
            ]),
        ])
    ]
})

export class DashboardComponent implements OnInit, OnDestroy {
    icSearch = icSearch;
    alertes: any;
    NotificationSeverityEnum = NotificationSeverityEnum;
    public IdentifierEnum = IdentifierEnum;
    dataLoaded = false;
    subscription = new Subscription();
    displayedColumns = ['identifier', 'value'];
    columnsToDisplay = this.displayedColumns.slice();
    dataSource = new MatTableDataSource<DataIncident | DetailsDataIncident>();
    dataTableMode: DataTableModeEnum = DataTableModeEnum.MAIN;
    readonly DataTableModeEnum = DataTableModeEnum;
    detailsLoaded = true;
    establishments: EstablishmentForList[] = [];
    selectedEstablishment = new FormControl();
    // Used to set the data incidents table data
    charts: ChartData[] = [];
    protected readonly ENUM_PERMISSIONS = ENUM_PERMISSIONS;
    private lastDataIncidentDataSource: DataIncident[] = [];
    private chosenDataIncidentIdentifier: DataIncidentEnum;

    constructor(
        public dashboardService: DashboardService,
        private router: Router,
        public configService: ConfigService,
        public authService: AuthService) {
    }

    ngOnInit(): void {
        // Used to track establishment changes (we need to load the charts according to the selected establishment)
        this.subscription.add(
            this.selectedEstablishment.valueChanges
                .pipe(debounceTime(800)) // Wait for the user to stop selecting establishments
                .subscribe((establishments: EstablishmentForList[]) => {
                    this.dashboardService.refreshDashboardData(establishments?.map(x => x.id));
                })
        );

        // Used to know if the dashboard data (charts + data incidents) are loaded
        this.subscription.add(
            this.dashboardService.loaded$.subscribe((value) => {
                this.dataLoaded = value;
            })
        );

        this.initData();
    }

    initData() {
        // Used to get charts from service in component
        this.subscription.add(
            this.dashboardService.getCharts().subscribe((data: ChartData[]) => {
                this.charts = data;
            })
        );

        // Used to get the current user's establishments and provide them to the establishment select
        this.subscription.add(
            this.authService.currentUser$()
                .pipe(
                    map((user: UserProfileModel) => {
                            return user.affectations?.map((affectation: AffectationModel) => {
                                return new EstablishmentForList(affectation.establishment._id, affectation.establishment.label);
                            });
                        }
                    )
                ).subscribe((establishments: EstablishmentForList[]) => {
                this.establishments = establishments;

                this.dashboardService.refreshDashboardData(establishments?.map(x => x.id));

                // Every time the establishments are loaded, we need to ticket-filter out the selected establishments that are not in
                // the user's establishments anymore
                this.selectedEstablishment.setValue(
                    this.selectedEstablishment.value?.length > 0 ?
                        this.selectedEstablishment.value.filter((establishment: EstablishmentForList) => {
                                return this.establishments?.some((establishmentFromService: EstablishmentForList) => {
                                    return establishmentFromService.id === establishment.id;
                                });
                            }
                        ) : this.establishments
                    , {emitEvent: false});
            })
        );

        // Used to get the data incidents (charts rendering in DOM is handled by the dashboard service)
        this.subscription.add(
            this.dashboardService.getDataIncidents()
                .subscribe((data: DataIncident[]) => {
                    this.lastDataIncidentDataSource = data;
                    this.dataSource.data = this.lastDataIncidentDataSource;
                })
        );

        // Used to get notifications count
        this.subscription.add(
            this.dashboardService.getNotificationsCount()
                .subscribe((alertes: {
                    severityMedium: number,
                    severityLow: number,
                    severityHigh: number
                }) => this.alertes = alertes)
        );
    }

    // Used to navigate to the notification page with the selected severity
    async navigateToNotification(severity: NotificationSeverityEnum) {
        await this.router.navigate(['/notification'], {queryParams: {severity}});
        // await this.router.navigate(['/notification']);
    }

    // Used to navigate to the relevant page when clicking on the title of a chart
    redirectTo(module: 'tickets' | 'contracts' | 'quotes' | 'orders' | 'bcr' | 'bills' | 'user-profile', id?: string) {
        let baseRoute = '';

        switch (module) {
            case 'tickets':
                baseRoute = '/administrative/ticket';
                break;
            case 'contracts':
                baseRoute = '/management/engagement';
                break;
            case 'quotes':
                baseRoute = '/achat/quote';
                break;
            case 'orders':
                baseRoute = '/achat/order';
                break;
            case 'bcr':
                baseRoute = '/achat/orderRegularization';
                break;
            case 'bills':
                baseRoute = '/administrative/bill';
                break;
            case 'user-profile':
                baseRoute = '/management/user-profile';
                break;
        }

        const completeRoute = [baseRoute];

        if (id) {
            completeRoute.push(id);
        }

        this.router.navigate(completeRoute);
    }

    // Used to navigate to the relevant element's details page when clicking on a row in the data incidents table
    seeDetails(identifier: DataIncidentEnum, id: string, subId?: string) {
        switch (identifier) {
            case DataIncidentEnum.ESTABLISHMENTS_WITHOUT_BUILDING:
                this.router.navigate(['/management/establishment', id, 'buildings']);
                break;
            case DataIncidentEnum.ESTABLISHMENTS_WITHOUT_ACCOUNTANT:
                this.router.navigate(['/management/establishment', id]);
                break;
            case DataIncidentEnum.ESTABLISHMENTS_WITHOUT_AXIS:
                this.router.navigate(['/management/establishment', id, 'analytics']);
                break;
            case DataIncidentEnum.BUILDINGS_WITHOUT_ACTIVITY:
                this.router.navigate(['/management/building', id]);
                break;
            case DataIncidentEnum.BUILDINGS_WITHOUT_ESTABLISHMENT:
                this.router.navigate(['/management/building', id]);
                break;
            case DataIncidentEnum.BUILDINGS_WITHOUT_ROOM:
                this.router.navigate(['/management/building', id, 'room']);
                break;
            case DataIncidentEnum.EQUIPMENT_WITHOUT_CONTACT_INTERN:
                this.router.navigate(['/management/equipment', id]);
                break;
            case DataIncidentEnum.SUPPLIERS_WITHOUT_FAMILY:
                this.router.navigate(['/management/supplier', id]);
                break;
            case DataIncidentEnum.FAMILY_WITHOUT_ACTOR:
                this.router.navigate(['/management/establishment', id, 'validators', subId]);
                break;
            case DataIncidentEnum.ESTABLISHMENTS_WITHOUT_ACTIVITY_ADDRESS:
                this.router.navigate(['/management/establishment', id]);
                break;
            case DataIncidentEnum.ESTABLISHMENTS_WITHOUT_DELIVERY_ADDRESS:
                this.router.navigate(['/management/establishment', id]);
                break;
            case DataIncidentEnum.ESTABLISHMENTS_WITHOUT_BILLING_ADDRESS:
                this.router.navigate(['/management/establishment', id]);
                break;
            default:
                break;
        }
    }

    // Used to handle the click on a row in the data incidents table
    clickedRow(data: DataIncident | DetailsDataIncident | any) {
        if (!this.detailsLoaded) {
            return;
        }

        if (this.dataTableMode === DataTableModeEnum.MAIN) {
            this.detailsLoaded = false;
            this.dataTableMode = DataTableModeEnum.DETAILS;
            this.dashboardService.getDataIncident(data.identifier).subscribe((dataIncident: DetailsDataIncident[]) => {
                this.chosenDataIncidentIdentifier = data.identifier;
                this._setColumns('id', 'label', 'identifier');
                this.dataSource.data = [];
                this._setDetailsDataIncident(dataIncident);
                this.detailsLoaded = true;
            });

            return;
        } else if (this.dataTableMode === DataTableModeEnum.DETAILS) {
            this.seeDetails(data.identifier, data.id, data.subId);
            return;
        }
    }

    // Used to go back to the list of data incidents
    async showDataIncidentMain() {
        this.dataTableMode = DataTableModeEnum.MAIN;
        this._setColumns('identifier', 'value');
        this.dataSource.data = this.lastDataIncidentDataSource;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    // Used to translate the columns of the data incidents table
    translateColumn(column: string): string {
        switch (column) {
            case 'identifier':
                return 'Incident';
            case 'value':
                return 'Quantité';
            case 'label':
                if (this.dataTableMode === DataTableModeEnum.DETAILS) {
                    return this.dataIncidentIdentifierTranslation(this.chosenDataIncidentIdentifier) ?? 'Libellé';
                } else {
                    return 'Libellé';
                }
            default:
                return column;
        }
    }

    // Used to translate the data incidents identifiers to human readable strings
    dataIncidentIdentifierTranslation(identifier: DataIncidentEnum): string {
        return DashboardService.dataIncidentIdentifierTranslation(identifier);
    }

    hasChart(MESDEMANDES_ETICKET: IdentifierEnum): boolean {
        return this.charts?.some((chart: ChartData) => chart.identifier === MESDEMANDES_ETICKET);
    }

    exportChartData(chart: IdentifierEnum) {
        this.dashboardService.exportChartData(this.selectedEstablishment.value.map(x => x.id), chart);
    }

    // Used to set the columns of the data incidents table
    private _setColumns(...columns: string[]) {
        this.displayedColumns = columns;
        this.columnsToDisplay = this.displayedColumns.slice();

        if (this.dataTableMode === DataTableModeEnum.DETAILS) {
            this.columnsToDisplay = this.columnsToDisplay.filter((column) => {
                return !['identifier', 'id'].includes(column);
            });
        }
    }

    private _setDetailsDataIncident(incident: DetailsDataIncident[]) {
        for (let i = 0; i < incident.length; i++) {
            this.dataSource.data.push(incident[i]);
        }
    }
}
