import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationModel } from 'src/app/core/models/notification.model';
import { NotificationService } from '../notification.service';
import { FormModalBaseComponent } from 'src/app/core/base/components/form-modal-base/form-modal-base.component';
import {ResponseTypeEnum} from "../../../../base/enum/response-type.enum";
import {CrudModeEnum} from "../../../../base/enum/crud-mode.enum";


@Component({
    selector: 'vex-notification-create-update',
    templateUrl: './notification-create-update-delete.component.html',
    styleUrls: ['./notification-create-update-delete.component.scss']
})
export class NotificationCreateUpdateDeleteComponent extends FormModalBaseComponent<NotificationModel> implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public dialogRef: MatDialogRef<FormModalBaseComponent<NotificationModel>>,
                public service: NotificationService) {
        super(data, dialogRef, service);
    }

    ngOnInit() {
        super.ngOnInit();

        this.form = new FormGroup({
            _id: new FormControl(this.defaults?._id),
            type: new FormControl(this.defaults?.type, [Validators.required]),
            message: new FormControl(this.defaults?.message, [Validators.required]),
            severity: new FormControl(this.defaults?.severity, [Validators.required]),
            sendDate: new FormControl(this.defaults?.sendDate, [Validators.required]),
            sender: new FormControl(this.defaults?.sender, [Validators.required]),
            recever: new FormControl(this.defaults?.recever, [Validators.required]),
            status: new FormControl(this.defaults?.status, [Validators.required]),

        });

    }

    beforeCreateItem() {

    }

    afterCreateItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
        }
    }

    beforeUpdateItem() {

    }

    afterUpdateItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
        }
    }

    deleteItem(): void {
        this.loading = true;
        this.beforeDeleteItem();
        const sub = this.service.deleteNotifications(this.defaults._id).subscribe(
            (result: any) => {
                this.afterDeleteItem(result, null);
                this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Delete, result.message);
            }, error => {
                this.afterDeleteItem(null, error);
                this.setErrorsMessages(error.error.errors);
                this.loading = false;
            }
        );
        this.subscription.add(sub);
    }
}
