<div class="card p-4">
    <span class="font-semibold text-lg text-gray-700">Catalogue</span>


    <base-data-table
            [count]="count"
            [pageSize]='pageSize'
            (onPageEvent)='onPageEvent.emit($event)'
            (onSortEvent)='onSortEvent.emit($event)'
            [feisColumns]="feisColumns"
            [dataSource]="dataSource"
            [displayedColumns]="displayedColumnsCatalog"
            [customColumnTemplate]="templateRef"
            [showSort]="true"
            [showPaginator]="true"
    >
    </base-data-table>
</div>


<ng-template #templateRef let-column let-row="element" let-status="status">
    <catalog-quantity-column-base
            [column]="column"
            [row]="row"
            (addItem)="onAddToBasketEvent.emit($event)"
            (updateQuantityItem)="updateCatalogueQuantity($event.item, $event.action)"
    >
    </catalog-quantity-column-base>

    <div *ngIf="column === 'delete' && canDelete(row)">
        <button (click)="deleteReference(row)" class="text-red" mat-icon-button>
            <mat-icon [icIcon]="icDelete" ></mat-icon>
        </button>
    </div>


    <div *ngIf="column === 'isProduct'">
        <span *ngIf="row.isProduct">Article</span>
        <span *ngIf="!row.isProduct">Prestation</span>

    </div>

    <div *ngIf="column === 'label'" class="relative flex items-center" (mouseenter)="showComment = row._id" (mouseleave)="showComment = null">
        <span >{{ row.label }}</span>
            <div [hidden]="showComment !== row._id" class="tooltip-box__content" >
                <div *ngIf="row.comment" class="p-2 bg-gray-100 border rounded shadow-lg max-w-3xl overflow-auto"  [innerHTML]="row.comment"></div>
                <div *ngIf="!row.comment" class="p-2 bg-gray-100 border rounded shadow-lg"> Aucune description</div>
            </div>
    </div>

    <div *ngIf="column === 'reference'" class="relative flex items-center" (mouseenter)="showPreviousReference = row._id" (mouseleave)="showPreviousReference = null">
        <span >{{ row.reference }}</span>
        <div [hidden]="showPreviousReference !== row._id" class="tooltip-box__content" >
            <div *ngIf="row.previousReference" class="p-2 bg-gray-100 border rounded shadow-lg max-w-3xl overflow-auto"  [innerHTML]="row.previousReference"></div>
        </div>
    </div>

    <div *ngIf="column === 'moreInfo'">
        <button (click)="openMoreInfoModal(row)" mat-icon-button color="primary">
            <mat-icon>file_open</mat-icon>
        </button>
    </div>

</ng-template>

