import {Pipe, PipeTransform} from "@angular/core";

export enum BasketRowStatusEnum {
    DRAFT = 'DRAFT',
    WAITING = 'WAITING',
    EXPIRED = 'EXPIRED',
    WAITING_APPRO = 'WAITING_APPRO',
    WAITING_REFERENT = 'WAITING_REFERENT',
    WAITING_CUSTOMER = 'WAITING_CUSTOMER',
    WAITING_HC = 'WAITING_HC',
    HC_RETURN = 'HC_RETURN',
    CLOSED = 'CLOSED',
    CLOSED_BY_APPROVISIONER = 'CLOSED_BY_APPROVISIONER',
    ORDERED = 'ORDERED',
    RECEIVED = 'RECEIVED',
    RECEIVED_ERROR = 'RECEIVED_ERROR',
    VALIDATED = 'VALIDATED',
    REPLACED = 'REPLACED',
    CANCELED = 'CANCELED',
}


@Pipe({name: 'statusToText', pure: false})
export class StatusToTextPipe implements PipeTransform {
    transform(status: BasketRowStatusEnum): string {
        switch (status) {
            case BasketRowStatusEnum.DRAFT:
                return 'Brouillon';
            case BasketRowStatusEnum.WAITING:
                return 'En attente';
                case BasketRowStatusEnum.WAITING_APPRO:
                return 'En attente approvisonneur';
            case BasketRowStatusEnum.WAITING_REFERENT:
                return 'En attente référent métier';
            case BasketRowStatusEnum.WAITING_HC:
                return 'En attente hors catalogue';
                case BasketRowStatusEnum.HC_RETURN:
                return 'Retour hors catalogue';
            case BasketRowStatusEnum.WAITING_CUSTOMER:
                return 'En attente déclarant';
            case BasketRowStatusEnum.CLOSED:
                return 'Clôturé';
            case BasketRowStatusEnum.CLOSED_BY_APPROVISIONER:
                return 'Annulé par l’approvisionneur';
            case BasketRowStatusEnum.ORDERED:
                return 'Commandé';
            case BasketRowStatusEnum.RECEIVED:
                return 'Reçu';
            case BasketRowStatusEnum.RECEIVED_ERROR:
                return 'Réception non conforme';
            case BasketRowStatusEnum.VALIDATED:
                return 'Validé';
            case BasketRowStatusEnum.EXPIRED:
                return 'Expiré';
            case BasketRowStatusEnum.REPLACED:
                return 'Remplacé';
                case BasketRowStatusEnum.CANCELED:
                return 'Annulé';
            default:
                return 'Inconnu';
        }
    }
}
