import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
   FormModalBaseComponent,
} from '../../../../../../../core/base/components/form-modal-base/form-modal-base.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BuildingService } from '../../../building.service';
import { SnackbarService } from '../../../../../../../core/services/snackbar.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BuildingModel } from '../../../../../../../core/models/building.model';
import {
   FormModalConfirmBaseComponent,
} from '../../../../../../../core/base/components/form-modal-confirme-base/form-modal-confirm-base.component';

@Component({
   selector: 'vex-building-metrik-status',
   templateUrl: './building-metrik-status.component.html',
   styleUrls: ['./building-metrik-status.component.scss',
      '../../../../../../../../../node_modules/quill/dist/quill.snow.css',
      '../../../../../../../../@vex/styles/partials/plugins/_quill.scss'
   ],
   encapsulation: ViewEncapsulation.None,
})
export class BuildingMetrikStatusComponent extends FormModalBaseComponent<BuildingModel> implements OnInit {

   customModule = {
      toolbar: [
         ['bold', 'italic', 'underline', 'strike'],
         [{ header: 1 }, { header: 2 }],
         [{ list: 'ordered' }, { list: 'bullet' }],
         [{ indent: '-1' }, { indent: '+1' }],
         [{ align: [] }],
         ['clean'],
         ['link']
      ]
   };

   constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialogRef: MatDialogRef<FormModalBaseComponent<BuildingModel>>,
      public dialog: MatDialog,
      public service: BuildingService,
      public snackbarService: SnackbarService,
   ) {
      super(data, dialogRef, service);
   }

   ngOnInit(): void {
      this.defaults = this.data?.building;
      this.initForm();
   }

   initForm() {
      this.form = new FormGroup({
         _id: new FormControl(this.defaults?._id),
         startDate: new FormControl(null, [Validators.required]),
         comment: new FormControl(null, [Validators.required]),
      });
   }

   setEndServiceDate(): void {
      // 🔹 **Affichage de la modale de confirmation avant d'exécuter la requête**
      this.dialog.open(FormModalConfirmBaseComponent, {
         data: {
            title: 'Confirmation',
            message: `Voulez-vous vraiment rendre ce bâtiment définitivement inactif ? Cette action est irréversible.`,
         }
      }).afterClosed().subscribe((confirmed: boolean) => {
         if (!confirmed) { return; }
         this.processEndServiceDate();
      });
   }

   /**
    * Exécute la requête de mise à jour après confirmation.
    */
   private processEndServiceDate(): void {
      this.form.controls.startDate.setValidators([Validators.required]);
      this.form.controls.comment.setValidators([Validators.required]);

      if (this.form.valid) {
         this.loading = true;

         const sub = this.service.setEndServiceDate(this.defaults._id, this.form.getRawValue()).subscribe(
            result => {
               this.close(result.data);
               this.snackbarService.success('L\'état du bâtiment a été mis à jour avec succès.');
               this.afterCreateItem(result, null);
            },
            error => {
               this.afterCreateItem(null, error);
               this.setErrorsMessages(error.error.errors);
               this.loading = false;
            }
         );

         this.subscription.add(sub);
      } else {
         this.logFormErrors(this.form);
         this.form.markAllAsTouched();
         this.loading = false;
      }
   }
}
