<mat-form-field [appearance]="appearance" [ngClass]="{'without-padding': withoutPadding}"
                [ngStyle]="inputTextWidthAuto ? {'width.ch': inputText.value.length, 'min-width.ch': 10} : false"
                color='primary' class="flex-auto w-full"
                [formGroup]='formGroup'>
    <mat-label>{{ label }}</mat-label>
    <input [type]='type' [formControlName]='controlName' [placeholder]="placeholder" (blur)="onLostFocus.emit()"
           #inputText matInput>
    <button *ngIf='obscure' (click)="toggleInput()" mat-icon-button matSuffix type="button">
        <mat-icon *ngIf="visible" [icIcon]="icVisibility"></mat-icon>
        <mat-icon *ngIf="!visible" [icIcon]="icVisibilityOff"></mat-icon>
    </button>
    <mat-icon *ngIf='icon' [icIcon]="icon" class="mr-3" matPrefix></mat-icon>
    <mat-hint>{{ hint }}</mat-hint>

    <base-form-input-errors
        [control]="formGroup.controls[controlName]"
        [controlName]="controlName"
        [errorMessages]="defaultErrorMessage"
        [invalidPatternMessage]="invalidPatternMessage"
        [customErrorMessage]="customErrorMessage">
    </base-form-input-errors>
</mat-form-field>
