<button #originRef [class.active]='dropdownOpen' class='button' mat-button type='button' fxFill
        (click)='showPopover(); $event.stopPropagation()'
        [disableRipple]='true' [ngClass]="{'active-filter': count > 0}">
    <mat-icon class='cursor-pointer' *ngIf='count === 0;' [icIcon]='icFilterList'></mat-icon>
    <ng-container *ngIf='count > 0'>
        <div fxLayout='row' fxLayoutGap='20px' fxLayoutAlign='start center'>
            <div fxLayout='row' fxLayoutGap='10px' fxLayoutAlign='start center' style='margin-left: 20px' (click)='clear(); $event.stopPropagation();'>
                <span>EFFACER FILTRES</span>
            </div>
            <mat-icon class='cursor-pointer' [matBadge]='count'>
                filter_list
            </mat-icon>
        </div>
    </ng-container>
</button>

<!--    <button-->
<!--        class='ml-4'-->
<!--        fxFlex='none'-->
<!--        mat-icon-button-->
<!--        matTooltip='Filter Columns'-->
<!--        type='button'>-->
<!--        <mat-icon [icIcon]='icFilterList'></mat-icon>-->
<!--    </button>-->
