import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable} from 'rxjs';
import {PrestationModel} from '../../../../../core/models/prestation.model';
import {TvaModel} from '../../../../../core/models/tva.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormModalBaseComponent} from '../../../../../core/base/components/form-modal-base/form-modal-base.component';
import {PrestationService} from '../prestation.service';
import {TvaService} from '../../../settings/tva/tva.service';
import {map} from 'rxjs/operators';
import {SupplierModel} from '../../../../../core/models/supplier.model';
import {GenericPrestationModel} from '../../../../../core/models/generic-prestation.model';
import {CategoryModel} from '../../../../../core/models/category.model';
import {CustomerGroupComptableLine} from '../../../../../core/models/customer-group.model';
import {GenericPrestationService} from '../../../settings/generic-prestation/generic-prestation.service';
import {SupplierService} from '../../supplier/supplier.service';
import {CategoryService} from '../../../settings/category/category.service';
import {AuthService} from '../../../../../core/services/auth.service';
import {ENUM_PERMISSIONS} from '../../../../../core/enums/permission.enum';
import {Location} from '@angular/common';
import {ContractService} from '../../contract/contract.service';
import {b64ToBlob} from '../../../../../core/helpers/b64ToBlob.helper';
import {ContentTypeEnum} from '../../../../../core/base/enum/content-type.enum';
import {FileSaverService} from 'ngx-filesaver';
import {ResponseTypeEnum} from '../../../../../core/base/enum/response-type.enum';
import {CrudModeEnum} from '../../../../../core/base/enum/crud-mode.enum';
import {CustomerGroupService} from '../../../settings/customer-group/customer-group.service';
import {UserProfileModel} from '../../../../../core/models/user-profile.model';
import {ContractModel, ContractPerimeterModel} from '../../../../../core/models/contract.model';
import {node} from '../../../../../core/ui/tree-checkbox/tree-checkbox.component';
import {EstablishmentModel} from '../../../../../core/models/establishment.model';
import {EstablishmentService} from '../../establishment/establishment.service';
import {ContractTypeOfRevisionEnum} from '../../../../../core/enums/contract.enum';
import {quillConfig} from '../../../../../core/config/quill.config';
import {SnackbarService} from '../../../../../core/services/snackbar.service';
import {InterventionTypeEnum} from "../../../../../core/enums/intervention.enum";

@Component({
    selector: 'vex-prestation-contract-create-update-delete',
    templateUrl: './prestation-contract-create-update-delete.component.html',
    styleUrls: ['./prestation-contract-create-update-delete.component.scss',
        '../../../../../../../node_modules/quill/dist/quill.snow.css',
        '../../../../../../@vex/styles/partials/plugins/_quill.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PrestationContractCreateUpdateDeleteComponent extends FormModalBaseComponent<PrestationModel> implements OnInit {

    public quillConfig = quillConfig;

    suppliers$: Observable<SupplierModel[]>;
    genericPrestations$: Observable<GenericPrestationModel[]>;
    tva$: Observable<TvaModel[]>;
    tva: TvaModel[];
    categories$: Observable<CategoryModel[]>;
    establishments$: Observable<EstablishmentModel[]>;
    purchaseValueLabel = 'Nombre d\'interventions par prestation';
    tvaAccountingAccounts: CustomerGroupComptableLine[];
    canUpdateAvailableCatalogPermission = false;
    currentUser: UserProfileModel;
    accountingAccounts: CustomerGroupComptableLine[];

    disabledTva = false;

    purchaseUnit = ['Intervention', 'Heure'];

    canDownloadTemplateModel = false;

    contract: ContractModel;

    isRevisionPrestationAmount = false;

    urlPattern: RegExp = /^(https?:\/\/[^\s/$.?#].[^\s]*|www\.[a-zA-Z0-9-]+\.[a-z]{2,})$/i;

    typesOfIntervention = [
        {name: 'Fixe'},
        {name: 'Recurrence'},
    ];


    // perimeter
    public node: node[];
    public isLoading = false;
    perimeter: ContractPerimeterModel[] = [];

    isCheckboxDisabled = false;


    // new fields
    isInterventionRequired = false;
    isPackageAvailable = false;
    isWithPackage = false;
    priceHTPerUnitLabel = 'Prix par intervention HT';
    priceTTCPerUnitLabel = 'Prix par intervention TTC';

    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public dialogRef: MatDialogRef<FormModalBaseComponent<PrestationModel>>,
                public service: PrestationService,
                private genericPrestationService: GenericPrestationService,
                private supplierService: SupplierService,
                private tvaService: TvaService,
                public categoryService: CategoryService,
                private authService: AuthService,
                private location: Location,
                private contractService: ContractService,
                private fileSaver: FileSaverService,
                private customerGroupService: CustomerGroupService,
                private establishmentService: EstablishmentService,
                public snackbarService: SnackbarService,
    ) {
        super(data, dialogRef, service);
    }

    ngOnInit() {
        super.ngOnInit();
        if (this.isUpdateMode()) {
            this.perimeter = this.defaults.perimeter;
        }

        this.contract = this.data.contract;
        if (this.contract.sustainabilityAndPriceTrans.typeOfRevision === ContractTypeOfRevisionEnum.FIXED &&
            this.contract.sustainabilityAndPriceTrans.globalRevision === false) {
            // La condition est vraie si typeOfRevision est 'Fixe' et globalRevision est explicitement faux.
            this.isRevisionPrestationAmount = true;
        }

        this.disabledTva = true;

        this.genericPrestationAutocompleteChange(this.defaults?.genericPrestation?.label);
        this.supplierAutocompleteChange(this.defaults?.supplier?.label);
        this.accountantLineAutocompleteChange();
        this.tvaAutocompleteChange(this.defaults?.tva?.genericTva?.label);

        if (this.defaults.tva) {
            this.tvaAccountingAccounts = this.defaults.tva.accountingAccounts;
            if (this.tvaAccountingAccounts) {
                this.tvaAccountingAccounts.map(x => x.display = x.label + ' - ' + x.accountNumber);
                this.defaults.tvaAccountingAccount = this.tvaAccountingAccounts
                    .find(x => x.accountNumber === this.defaults.tvaAccountingAccount?.accountNumber);
            }
        }

        this.initForm();
        this.initPermissions();

    }

    getTvaRate($event: TvaModel){
        return `${this.tvaService.getRateByDate($event).rate.toFixed(2)}%`;
    }

    initForm() {
        this.form = new FormGroup({
            _id: new FormControl(this.defaults?._id),
            //  technicalIdentifier: new FormControl(this.defaults?.technicalIdentifier),
            reference: new FormControl(this.defaults?.reference, []),
            previousReference: new FormControl(this.defaults?.previousReference),
            label: new FormControl(this.defaults?.label, [Validators.required]),
            genericPrestation: new FormControl(this.defaults?.genericPrestation),
            priceHT: new FormControl(this.defaults?.priceHT?.toFixed(5), [Validators.required]),
            priceTTC: new FormControl((Math.round(this.defaults?.priceTTC * 100) / 100)?.toFixed(5)),
            priceHTPerUnit: new FormControl(this.defaults?.priceHTPerUnit?.toFixed(5)),
            priceTTCPerUnit: new FormControl((Math.round(this.defaults?.priceTTCPerUnit * 100) / 100)?.toFixed(5)),
            purchaseUnit: new FormControl({value: this.defaults?.purchaseUnit}),
            purchaseValue: new FormControl(this.defaults?.purchaseValue),
            tva: new FormControl(this.defaults?.tva, [Validators.required]),
            tvaAccountingAccount: new FormControl({
                value: this.defaults?.tvaAccountingAccount,
            }),
            quoteId: new FormControl(this.data?.id ? this.data?.id : this.defaults.quoteId ? this.defaults.quoteId : null),
            isAvailable: new FormControl(this.defaults?.isAvailable),
            isFullPerimeter: new FormControl(this.defaults?.isFullPerimeter ?? true),
            interventionRequired: new FormControl(this.defaults?.interventionRequired),
            isPackageService: new FormControl(this.defaults?.isPackageService ? this.defaults?.isPackageService : false),
            // mainEstablishment: new FormControl(this.defaults?.mainEstablishment),
            interventionType: new FormControl(null),
            minQuantity: new FormControl(1),
            accountingAccount: new FormControl({
                value: this.defaults?.accountingAccount,
            }),
            revisionPrestationAmount: new FormControl(this.defaults?.revisionPrestationAmount),
            comment: new FormControl(this.defaults ? this.defaults.comment : null),
            packagePriceHT: new FormControl(this.defaults?.packagePriceHT ? this.defaults?.packagePriceHT : null),
            packagePriceTTC: new FormControl(this.defaults?.packagePriceTTC ? this.defaults?.packagePriceTTC : null),
            purchasePackageValue: new FormControl(this.defaults?.purchasePackageValue),
            generalSupplierCatalog: new FormControl(this.defaults ? this.defaults.generalSupplierCatalog : null, [Validators.pattern(this.urlPattern)]),
            technicalSheet: new FormControl(this.defaults ? this.defaults.technicalSheet : null, [Validators.pattern(this.urlPattern)]),
            safetyUsageProtocol: new FormControl(this.defaults ? this.defaults.safetyUsageProtocol : null, [Validators.pattern(this.urlPattern)]),
        });


        if (this.defaults.isPlanned) {
            // disable all interventions fields
            this.form.controls.interventionRequired.disable();
            this.form.controls.interventionType.disable();
            this.form.controls.isPackageService.disable();
            this.form.controls.purchaseUnit.disable();

        }

        if (this.defaults.interventionType) {

            this.selectInterventionType({name: this.defaults.interventionType});
        }


        if (this.isCreateMode()) {
            this.form.get('isAvailable').setValue(true);
            this.form.get('interventionRequired').setValue(false);
        }

        if (this.isUpdateMode()) {
            this.form.controls.purchaseUnit.setValue(this.defaults.purchaseUnit);
            this.form.get('interventionRequired').setValue(this.defaults.interventionRequired);

            if (this.defaults.interventionRequired && this.defaults.genericPrestation?.interventionRequired && this.defaults.interventionType === InterventionTypeEnum.RECURRENCE) {
                this.isCheckboxDisabled = true;
            }
        }

        this.form.controls.genericPrestation.valueChanges.subscribe(value => {

            if (value?.interventionRequired) {
                this.form.controls.interventionRequired.setValue(true);
                this.form.controls.interventionRequired.disable();
            } else {
                this.form.controls.interventionRequired.setValue(false);
                this.form.controls.interventionRequired.disable();
            }
        });

        this.form.controls.tva.valueChanges.subscribe((tva) => {
            if (tva) {
                this.calculatePriceTTC();
                this.calculatePricePerUnitTTC();
                this.calculatePricePackageTTC();
            }
        });

        this.form.controls.interventionRequired.valueChanges.subscribe(value => {

            this.isInterventionRequired = value;
            if (this.form.controls.purchaseUnit.value !== 'Heure' && this.defaults.purchaseUnit !== 'Heure') {
                this.form.controls.purchaseUnit.setValue(this.purchaseUnit[0]);
            } else {
                this.form.controls.purchaseUnit.setValue(this.purchaseUnit[1]);
            }
            if (this.isInterventionRequired) {
                if (!this.form.controls.interventionType.value) {
                    this.selectInterventionType({name: 'Fixe'});
                }

                this.form.controls.purchaseUnit.setValidators([Validators.required]);
                this.form.controls.purchaseValue.setValidators([Validators.required]);
                this.form.controls.priceHTPerUnit.setValidators([Validators.required]);
                this.form.controls.priceTTCPerUnit.setValidators([Validators.required]);
            } else {
                this.form.controls.purchaseUnit.clearValidators();
                this.form.controls.purchaseValue.clearValidators();
                this.form.controls.priceHTPerUnit.clearValidators();
                this.form.controls.priceTTCPerUnit.clearValidators();
                this.form.controls.purchaseUnit.setValue(null);
                this.form.controls.purchaseValue.setValue(1);
                this.form.controls.priceHTPerUnit.setValue(null);
                this.form.controls.priceTTCPerUnit.setValue(null);
                if (this.isUpdateMode()) {
                    this.form.controls.isPackageService.setValue(this.defaults.isPackageService);
                } else {
                    this.form.controls.isPackageService.setValue(false);
                }

            }
            this.isPackageAvailable =
                (this.form.controls.interventionType.value === 'Fixe' && value) ||
                !this.form.controls.isPackageService.value ||
                (this.isUpdateMode() && this.form.controls.isPackageService.value);
        });

        this.form.controls.interventionType.valueChanges.subscribe(value => {
            if (value === 'Fixe' && this.form.controls.interventionRequired.value) {
                this.isPackageAvailable = true;

            } else {
                this.isPackageAvailable = false;
                this.form.controls.isPackageService.setValue(false);
            }

        });


        this.form.controls.priceHT.valueChanges.subscribe(e => {

            this.calculatePriceTTC();

            if (this.form.controls.interventionRequired.value) {
                this.calculatePricePerUnitHT();
                this.calculatePricePerUnitTTC();
            }

            if (this.isWithPackage) {
                this.calculatePricePackageHT();
                this.calculatePricePackageTTC();
            }

        });


        // UNIT OPTIONS

        this.form.controls.purchaseUnit.valueChanges.subscribe(e => {
            if (e === 'Heure') {
                this.purchaseValueLabel = 'Nombre d\'heures par prestation';
                this.priceHTPerUnitLabel = 'Prix par heure HT';
                this.priceTTCPerUnitLabel = 'Prix par heure TTC';
            }
            if (e === 'Intervention') {
                this.purchaseValueLabel = 'Nombre d\'interventions par prestation';
                this.priceHTPerUnitLabel = 'Prix par intervention HT';
                this.priceTTCPerUnitLabel = 'Prix par intervention TTC';
            }
        });

        this.form.controls.purchaseValue.valueChanges.subscribe(e => {
            this.calculatePricePerUnitHT();
            this.calculatePricePerUnitTTC();
        });


        this.form.controls.priceHTPerUnit.valueChanges.subscribe(value => {
            this.calculatePriceHT('priceHTPerUnit');
            this.calculatePriceTTC();
            this.calculatePricePerUnitTTC();

            if (this.isWithPackage) {
                this.calculatePricePackageHT();
                this.calculatePricePackageTTC();
            }
        });


        // PACKAGE OPTION

        this.form.controls.isPackageService.valueChanges.subscribe(value => {

            if (value) {

                this.isWithPackage = true;
                // Ajouter required à packagePriceHT et packagePriceTTC
                this.form.controls.purchasePackageValue.setValidators([Validators.required]);
                this.form.controls.packagePriceHT.setValidators([Validators.required]);
                this.form.controls.packagePriceTTC.setValidators([Validators.required]);
            } else {

                this.isWithPackage = false;

                // this.form.controls.purchasePackageValue.setValue(1);
                this.form.controls.packagePriceHT.setValue(null);
                this.form.controls.packagePriceTTC.setValue(null);

                // Supprimer required de packagePriceHT et packagePriceTTC
                this.form.controls.purchasePackageValue.clearValidators();
                this.form.controls.packagePriceHT.clearValidators();
                this.form.controls.packagePriceTTC.clearValidators();
            }

            this.form.controls.purchasePackageValue.updateValueAndValidity();
            this.form.controls.packagePriceHT.updateValueAndValidity();
            this.form.controls.packagePriceTTC.updateValueAndValidity();
        });


        this.form.controls.purchasePackageValue.valueChanges.subscribe(value => {
            this.calculatePriceHT('packagePriceHT');
            this.calculatePriceTTC();
            this.calculatePricePerUnitHT();
            this.calculatePricePerUnitTTC();
        });

        this.form.controls.packagePriceHT.valueChanges.subscribe(value => {
            this.calculatePriceHT('packagePriceHT');
            this.calculatePriceTTC();
            this.calculatePricePerUnitHT();
            this.calculatePricePerUnitTTC();
            this.calculatePricePackageTTC();
        });


        // OTHER OPTIONS
        this.form.controls.isFullPerimeter.valueChanges.subscribe(value => {

            if (this.form.controls.isPackageService.value) {
                this.establishmentAutocompleteChange(this.defaults?.mainEstablishment?.label);
            }
        });
    }

    initPermissions() {
        this.authService.getCurrentUserPermissions$().subscribe(permissions => {
            this.canDownloadTemplateModel = permissions.includes(ENUM_PERMISSIONS.DOWNLOAD_FM_REF_PURCHASE_SERVICE);
            if (!permissions.includes(ENUM_PERMISSIONS.INPUT_FM_REF_PURCHASE_SERVICE_INFO_PRESTATION)) {
                this.canUpdateAvailableCatalogPermission = true;
            }
            if (!permissions.includes(ENUM_PERMISSIONS.INPUT_FM_REF_PURCHASE_SERVICE_INFO)) {
                this.canUpdateAvailableCatalogPermission = true;
            }
        });

        this.currentUser = this.authService.currentUserValue();
    }

    calculatePriceHT(origin) {
        if (origin === 'priceHTPerUnit') {
            const priceHTPerUnit = this.form.getRawValue()?.priceHTPerUnit;
            const purchaseValue = this.form.getRawValue()?.purchaseValue;
            if (priceHTPerUnit && purchaseValue) {
                const number = priceHTPerUnit * purchaseValue;
                this.form.controls.priceHT.setValue(Number(number.toFixed(5)), {emitEvent: false});
            }
        }
        if (origin === 'packagePriceHT') {
            const packagePriceHT = this.form.getRawValue()?.packagePriceHT;
            const purchasePackageValue = this.form.getRawValue()?.purchasePackageValue;
            if (packagePriceHT && purchasePackageValue && purchasePackageValue > 0) {
                const number = packagePriceHT / purchasePackageValue;
                this.form.controls.priceHT.setValue(Number(number.toFixed(5)), {emitEvent: false});
            }
        }
    }

    calculatePriceTTC() {
        const tva = this.tvaService.getRateByDate(this.form.getRawValue()?.tva).rate;
        const priceHT = this.form.getRawValue()?.priceHT;

        if (priceHT && (tva || tva === 0)) {
            const number = priceHT * (1 + (tva / 100))
            this.form.controls.priceTTC.setValue(Number(number.toFixed(5)), {emitEvent: false});
        }
    }

    calculatePricePerUnitHT() {
        const priceHT = this.form.getRawValue()?.priceHT;
        const purchaseValue = this.form.getRawValue()?.purchaseValue;
        if (priceHT && purchaseValue) {
            const number = priceHT / purchaseValue;
            this.form.controls.priceHTPerUnit.setValue(Number(number.toFixed(5)), {emitEvent: false});

        }

    }

    calculatePricePerUnitTTC() {
        const tva = this.tvaService.getRateByDate(this.form.getRawValue()?.tva).rate;
        const priceHTPerUnit = this.form.getRawValue()?.priceHTPerUnit;
        if (priceHTPerUnit && tva) {
            const number = priceHTPerUnit * (1 + (tva / 100));
            this.form.controls.priceTTCPerUnit.setValue(Number(number.toFixed(5)), {emitEvent: false});

        }
    }

    calculatePricePackageHT() {
        const priceHT = this.form.getRawValue()?.priceHT;
        const purchasePackageValue = this.form.getRawValue()?.purchasePackageValue;
        if (priceHT && purchasePackageValue && purchasePackageValue > 0) {
            const number = priceHT * purchasePackageValue;
            this.form.controls.packagePriceHT.setValue(Number(number.toFixed(5)), {emitEvent: false});
        }
    }

    calculatePricePackageTTC() {
        const rate = this.tvaService.getRateByDate(this.form.getRawValue()?.tva).rate;
        const packagePriceHT = this.form.getRawValue()?.packagePriceHT;
        if (packagePriceHT && rate) {
            const number = packagePriceHT * (1 + (rate / 100));
            this.form.controls.packagePriceTTC.setValue(Number(number.toFixed(5)), {emitEvent: false});
        }
    }


    isSelected(typeOfIntervention: { name: string }): boolean {

        return this.form.controls.interventionType.value === typeOfIntervention.name;
    }

    selectInterventionType(typeOfIntervention: { name: string }): void {
        if (this.form.controls.interventionType.disabled && this.form.controls.interventionType.value) {
            return;
        }

        this.form.get('interventionType').setValue(typeOfIntervention.name);
    }

    beforeCreateItem() {

        // if (this.form.controls.isPackageService.value && !this.form.controls.interventionRequired.value) {
        // //      this.defaults.interventionType = null;
        // }

        this.defaults.supplier = this.contract.supplier;
        this.defaults.contractId = this.contract._id;
        this.defaults.perimeter = this.perimeter;

        // si la prestation dispose d'une recurrence automatique et que la prestation générique dispose d'une intervention nécessaire
        if (this.form.controls.isFullPerimeter.value && this.defaults.genericPrestation?.interventionRequired && this.defaults.interventionRequired && this.defaults.interventionType === InterventionTypeEnum.RECURRENCE) {
            this.defaults.perimeter = this.contract.perimeter;
        }

    }

    afterCreateItem(result?: any, error?: any) {
        if (result) {
            this.contractService.findById(result.data._id).subscribe();
            this.close(result.data.prestation);
        }
    }

    afterDeleteItem(result?: any, error?: any) {
        if (result) {
            this.contractService.findById(this.defaults.contractId).subscribe();
            this.close(result);
        }
    }

    afterImportItem(result?: any, error?: any) {
        if (result) {
            this.close(result.data);
        }
    }

    beforeUpdateItem() {
        // @ts-ignore
        // this.defaults.category = this.defaults.category?._id;
        if (this.form.get('priceHT') !== null) {
            this.defaults.priceHT = +this.form.get('priceHT').value;
        }
        this.defaults.perimeter = this.perimeter;
        this.defaults.supplier = this.contract.supplier;
        this.defaults.contractId = this.contract._id;

        // this.defaults.mainEstablishment = this.defaults.isPackageService ? this.form.getRawValue().mainEstablishment?._id : null;
    }

    afterUpdateItem(result?: any, error?: any) {
        if (result) {
            this.contractService.findById(result.data._id).subscribe();
            this.close(result.data.prestations);
        }
    }

    genericPrestationAutocompleteChange(state: any = '') {
        this.genericPrestations$ = this.genericPrestationService.findAllGenItemByGenEngagement([this.contract._id], state);
    }

    establishmentAutocompleteChange(state: any = '') {

        if (this.form.get('isFullPerimeter').value) {
            const establishmentIds = this.contract.perimeter?.map(e => e.establishmentId);
            this.establishments$ = this.establishmentService.findAll(null, null, null, null, state).pipe(map(d => {
                return d.data.filter(e => establishmentIds.includes(e._id));
            }));
        }

        if (!this.form.get('isFullPerimeter').value) {
            const establishmentIds = this.perimeter.map(e => e.establishmentId);
            this.establishments$ = this.establishmentService.findAll(null, null, null, null, state).pipe(map(d => {
                return d.data.filter(e => establishmentIds.includes(e._id));
            }));
        }
    }

    supplierAutocompleteChange(state: any = '') {
        this.suppliers$ = this.supplierService.findAll(null, null, 'label', 'asc', state).pipe(map(d => d.data));
    }

    tvaAutocompleteChange(state: any = '') {
        this.tva$ = this.tvaService.findAll(null, null, 'label', 'asc', state).pipe(map(d => d.data));
        console.log(this.tva$.subscribe());
    }


    exportPrestationContractTemplate() {
        this.service.downloadExcelTemplatePrestationContract(this.contract._id).subscribe((x: any) => {
            const b = b64ToBlob(x.data.b64, ContentTypeEnum.excel);
            this.fileSaver.save(b, x.data.fileName);
        });
    }

    importItems() {
        this.importDefaults = this.importForm.value;

        const isImporting = true;
        const importStartTime = Date.now();

        this.beforeImportItem();
        if (this.importForm.valid) {
            this.loading = true;
            const sub = this.service.importPrestationContractItems(this.importDefaults, this.contract._id).subscribe(
                result => {
                    this.afterImportItem(result, null);
                    this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Import, result.message);
                }, error => {
                    this.afterImportItem(null, error);
                    this.setImportErrorsMessages(error.error.errors);
                    this.loading = false;
                });

            setTimeout(() => {
                if (isImporting && !sub.closed) {
                    const importDuration = Date.now() - importStartTime;
                    if (importDuration >= 2000) {
                        this.snackbarService.warning('L\'importation peut prendre quelques secondes. Veuillez patienter.', 15000);
                    }
                }
            }, 2000);

            this.subscription.add(sub);
        } else {
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    accountantLineAutocompleteChange(state = '') {
        this.customerGroupService.findFilteredComptableLines(state, this.defaults).subscribe(res => {
            this.accountingAccounts = res.data.map(x => {
                x.display = x.label + ' - ' + x.accountNumber;
                return x;
            });

            if (this.defaults?.accountingAccount && this.form.controls.accountingAccount) {
                const accountingAccount = this.accountingAccounts
                    .find(x => x.accountNumber === this.defaults?.accountingAccount?.accountNumber);
                this.form.controls.accountingAccount.setValue(accountingAccount);
            }


        });
    }

    createItem(): void {

        this.loading = true;
        this.defaults = this.form.getRawValue();
        this.beforeCreateItem();
        if (this.form.valid) {

            // this.defaults.mainEstablishment = this.form.getRawValue().mainEstablishment?._id;

            const sub = this.service.create(this.defaults).subscribe(
                result => {
                    this.afterCreateItem(result, null);
                    this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Create, this.data.messageResult);
                }, error => {
                    this.showErrorUniquenessReference(error);
                    console.log(error);
                    this.afterCreateItem(null, error);
                    this.setErrorsMessages(error.error.errors);
                    this.loading = false;
                },
            );
            this.subscription.add(sub);
        } else {
            this.onFormInvalid();
            this.logFormErrors(this.form);
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    archiveItem(): void {
        this.loading = true;
        this.beforeArchiveItem();
        // @ts-ignore
        const call$ = this.defaults?.isArchive ? this.service.unarchive(this.defaults) : this.service.archive(this.defaults);
        const sub = call$.subscribe(
            result => {
                this.afterArchiveItem(result, null);
                this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Delete, result.message);
            }, error => {
                this.afterArchiveItem(null, error);
                this.setErrorsMessages(error.error.errors);
                this.loading = false;
            },
        );
        this.subscription.add(sub);
    }

    onSelectedItemsChange(selectedItems: []) {
        this.perimeter = selectedItems;

        if (this.form.controls.isPackageService.value) {
            this.establishmentAutocompleteChange();
        }
    }

    updateItem(): void {
        this.loading = true;
        this.defaults = this.form.getRawValue();
        this.beforeUpdateItem();

        if (this.form.valid) {
            const sub = this.service.update(this.defaults).subscribe(
                result => {
                    this.afterUpdateItem(result, null);
                    this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Update, this.data.messageResultUpdate);
                }, error => {
                    this.showErrorUniquenessReference(error);
                    this.afterUpdateItem(null, error);
                    this.setErrorsMessages(error.error.errors);
                    this.loading = false;
                });
            this.subscription.add(sub);
        } else {
            this.logFormErrors(this.form);
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    showErrorUniquenessReference(error: any) {
        const errors = error.error.errors.filter(x => x.statusCode === 5503);
        if (errors) {
            this.snackbarService.warning('Enregistrement impossible : cette référence existe déjà au contrat au même prix et sur le même périmètre.');
        }
    }
}
