import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormModalBaseComponent } from '../form-modal-base/form-modal-base.component';
import { FormGroup } from '@angular/forms';
import { CrudModeEnum } from '../../enum/crud-mode.enum';
import { b64ToBlob } from '../../../helpers/b64ToBlob.helper';
import { ContentTypeEnum } from '../../enum/content-type.enum';
import { FileSaverService } from 'ngx-filesaver';

@Component({
  selector: 'vex-form-import-base',
  templateUrl: './form-import-base.component.html',
  styleUrls: ['./form-import-base.component.scss']
})
export class FormImportBaseComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() mode: CrudModeEnum;
  @Input() entityLabel: string;
  @Input() service: any;
  @Input() modalTitle: string;
  @Input() loading: boolean = false;
  @Input() downloadModel = true;
  @Input() customExport = false;

  @Input() canDownloadTemplateModel = false;

  @Output() saveForm = new EventEmitter();
  @Output() importItems = new EventEmitter();
  @Output() customExportItems = new EventEmitter();

  constructor(
      private fileSaver: FileSaverService
  ) { }

  ngOnInit(): void {
  }

  get label() {
    return this.entityLabel.toLowerCase();
  }

  /**
   * Method for download template excel
   */
  exportTemplate() {
    if (this.customExport) {
      this.customExportItems.emit();
    } else {
      this.service.downloadExcelTemplate().subscribe((x: any) => {
        const b = b64ToBlob(x.data.b64, ContentTypeEnum.excel)
        this.fileSaver.save(b, x.data.fileName);
      })
    }
  }

  save () {
    this.saveForm.emit();
  }

  import () {
    this.importItems.emit();
  }

}
