<div [ngClass]="{'w-full border p-4 radius-drop cursor-pointer rounded h-40': true, 'border-gray-light': !focusDropzone, 'border-primary': focusDropzone}"
     fxLayout="column" fxLayoutAlign="center center"
     fxLayoutGap="10px" (click)="fileUpload.click()"
     drag-n-drop (fileDropped)="fileDropped($event)" (fileDragover)="focusDropzone = $event">
    <mat-icon class="text-primary">cloud_upload</mat-icon>
    <p><span class="text-primary">Rechercher des fichiers</span> ou glisser déposer</p>
</div>
<!--{{files}}-->
<div *ngFor="let f of files; let i = index" class="file-item" fxLayout="column" fxLayoutAlign="start start"
     fxLayoutGap="10px"
     [ngClass]="{'file-item': true, 'text-primary': f.progress.value > 100, 'text-gray': f.progress.value < 100, 'text-red': f.status === 'ERROR'}">
    <div fxFlex="40" class="truncate w-full cursor-pointer" fxLayout="row" fxLayoutAlign="space-between center" (click)="downloadDocument(f.DCWDOCID)">
        <span>{{ f.originalFileName }}</span>
        <div fxLayout="column" fxLayoutAlign="center center">
            <mat-icon class="material-icons-outlined text-base cursor-pointer" (click)="deleteFileInList(i)"
                      *ngIf="f.progress.value < 100 && f.status !== 'ON_LOAD'">close</mat-icon>
            <mat-icon class="text-base text-primary" *ngIf="f.progress.value > 100 && f.progress.value < 500">done</mat-icon>
            <mat-icon class="text-base" *ngIf="f.status === 'ERROR'" matTooltip="Une erreur est survenue lors de l'enregistrement">warning</mat-icon>
            <mat-spinner [diameter]="16" *ngIf="f.progress.value <= 100 && f.status === 'ON_LOAD'"></mat-spinner>
        </div>
    </div>
<!--    <mat-progress-bar mode="determinate" *ngIf="f.status === 'ON_LOAD'" [value]="f.progress.value"></mat-progress-bar>-->
</div>
<input type="file" style="display: none" multiple
       (change)="onFileSelected($event)" #fileUpload>
