import { Component, Input, OnInit } from '@angular/core';

export interface ICatalogAction {
  label: string;
  click: () => void;
  displayBtn: boolean;
  disabled?: boolean;
}
@Component({
  selector: 'catalog-actions-base',
  templateUrl: './catalog-actions-base.component.html',
  styleUrls: ['./catalog-actions-base.component.scss']
})
export class CatalogActionsBaseComponent implements OnInit {
  @Input() actions: Array<ICatalogAction>;
  constructor() { }

  ngOnInit(): void {
  }

}
