export enum NotificationTypeEnum {
    OVER_BUDGET_OPEN_ORDER_INVESTMENT = 'Dépassement de budget commande ouverte investissement',
    ACCEPTANCE_OF_INVESTMENT_OPEN_ORDER_BUDGET_OVERRUN = 'Acceptation du dépassement de budget commande ouverte investissement',
    REFUSAL_OF_INVESTMENT_OPEN_ORDER_BUDGET_OVERRUN = 'Refus du dépassement de budget commande ouverte investissement',
    REQUEST_FOR_RECOMMENDATIONS_PENDING = 'Demande de préconisations en attente',
    RETURN_ON_REQUEST_PURCHASE_RECOMMENDATIONS = 'Retour sur demande de préconisations d\'achat',
    RETURN_ON_REQUEST_PURCHASE_RECOMMENDATIONS_REFUSED = 'Retour sur demande de préconisations d\'achat refusée',
    PRODUCTS_TO_BE_TAKEN_FROM_INTERNAL_STOCKS = 'Article(s) à prélever dans les stocks internes',
    QUOTATION_ARCHIVED_FOLLOWING_EXPRESSION_OF_NEED = 'Devis archivé suite à expression de besoin',
    // Planification d'intervention en interne
    INTERNAL_RESPONSE_PLANNING = 'Planification d\'intervention en interne',
    // Planification d'intervention en interne confirmée
    INTERNAL_RESPONSE_PLANNING_CONFIRMED = 'Planification d\'intervention en interne confirmée',
    // Commande en attente de validation
    ORDER_PENDING_VALIDATION = 'Commande en attente de validation',
    // Commande validée
    ORDER_VALIDATED = 'Commande validée',
    // Commande rejetée
    ORDER_REJECTED = 'Commande rejetée',
    // Réception conforme
    RECEPTION_CONFORM = 'Réception conforme',
    // Réception non conforme
    RECEPTION_NON_CONFORM = 'Réception non conforme',
    // Nouveau litige sur une commande
    NEW_LITIGE_ON_ORDER = 'Nouveau litige sur une commande',
    // Litige sur commande résolu
    LITIGE_ON_ORDER_RESOLVED = 'Litige sur commande résolu',
    // Création de litige sur une facture
    NEW_LITIGE_ON_INVOICE = 'Création de litige sur une facture',
    // Litige sur facture résolu
    LITIGE_ON_INVOICE_RESOLVED = 'Litige sur facture résolu',
    // Quantité en stock basse
    LOW_STOCK = 'Quantité en stock basse',
    // Mise en paiement de facture
    INVOICE_PAYMENT = 'Mise en paiement de facture',
    // Accusé de lecture
    READING_NOTIFICATION = 'Accusé de lecture',
    // Facture bloquée
    BLOCKED_INVOICE = 'Facture bloquée',
    // Demande approbation panier hors contrat
    APPROVAL_BASKET_OUF_OF_CONTRACT = 'Demande approbation panier hors contrat',
    // Approbation panier hors contrat
    BASKET_OUT_OF_CONTRACT_APPROVED = 'Approbation panier hors contrat',
    RETURN_REQUEST_IN_PROGRESS_TICKET= 'Le statut du ticket est passé à "traitement en cours"',
    SEND_BILL_WAITING_APPROVER = "Une facture autorisée sans commande est en attente d'approbation",
}
