import { EquipementMetrikStatusModel } from '../models/equipement-metrik-status.model';
import { HistoriqueEvent } from '../base/components/history/history.component';
import { MetrikStatusModel } from '../models/metrik-status.model';


export const convertToHistoriqueEvents = (metrikStatusList: MetrikStatusModel[]): HistoriqueEvent[] => {
   const formatDate = (date: Date | null): string => {
      if (!date) { return ''; }
      return date.toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' });
   };

   const showDate = (sd: Date, ed: Date, isActive: boolean): string => {
      const startDate = formatDate(sd);
      const endDate = ed ? formatDate(ed) : isActive ? 'Aujourd\'hui' : '';
      return `${startDate} - ${endDate}`;
   };

   /**
    * Vérifie si le statut est actif en utilisant `getCurrentAndNextStatus`
    */
   const isStatusActive = (metrics: MetrikStatusModel[], status: MetrikStatusModel): boolean => {
      if (!metrics || metrics.length === 0) {
         return false;
      }
      const currentStatus = metrics.length > 0 ? metrics[0] : null;
      return currentStatus ? status.addedIndex === currentStatus.addedIndex : false;
   };


   return metrikStatusList.map(status => {
      const startDate = new Date(status.startDate);
      const endDate = status.endDate ? new Date(status.endDate) : null;
      const isActive = isStatusActive(metrikStatusList, status);
      return {
         date: showDate(startDate, endDate, isActive),
         titre: status.label,
         description: status.comment || 'Aucune description',
         active: isActive
      };
   });
};


export const getCurrentAndNextStatus = (metrikStatuses: MetrikStatusModel[]): MetrikStatusModel[]  => {
   if (!metrikStatuses || metrikStatuses.length === 0) {
      return [];
   }

   const now = new Date();
   const nowZero = new Date();
   nowZero.setHours(0, 0, 0, 0);
   now.setHours(23, 59, 59, 999);

   // 1️⃣ Trouver la métrik actuelle (couvre `now` et sélectionne la dernière ajoutée en cas d'égalité)
   const currentMetrics = metrikStatuses
      .filter(m => new Date(m.startDate) <= now && (!m.endDate || new Date(m.endDate) >= nowZero))
      .sort((a, b) => b.addedIndex - a.addedIndex); // Trie par `addedIndex`

   const current = currentMetrics.length > 0 ? currentMetrics[0] : null;

   // 2️⃣ Trouver la métrik suivante :
   let next: EquipementMetrikStatusModel | null = null;

   // Priorité 1 : Trouver la prochaine métrik dans le futur
   const futureMetrics = metrikStatuses
      .filter(m => new Date(m.startDate) > now)
      .sort((a, b) => {
         const startDiff = new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
         return startDiff !== 0 ? startDiff : b.addedIndex - a.addedIndex;
      });

   if (futureMetrics.length > 0) {
      next = futureMetrics[0]; // Prendre la plus proche dans le futur
   } else {
      // Priorité 2 : Trouver la métrik passée la plus récente
      const pastMetrics = metrikStatuses
         .filter(m => m.endDate && new Date(m.endDate) < now)
         .sort((a, b) => {
            const endDiff = new Date(b.endDate).getTime() - new Date(a.endDate).getTime();
            return endDiff !== 0 ? endDiff : b.addedIndex - a.addedIndex;
         });

      if (pastMetrics.length > 0) {
         next = pastMetrics[0]; // Prendre la plus proche dans le passé
      }
   }

   // 3️⃣ Construire la liste de sortie
   const result: EquipementMetrikStatusModel[] = [];
   if (current) {
      result.push(current);
   }
   if (next) {
      result.push(next);
   }
   return result;
}