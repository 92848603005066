import {ErrorHandler, Injectable} from "@angular/core";
@Injectable()
export class ChunckLoadInterceptor implements ErrorHandler {
    handleError(err: any): void {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if(chunkFailedMessage.test(err.message)) {
            if(confirm("Nouvelle version disponible. Charger la nouvelle version ?")) {
                window.location.reload();
            }
        } else {
            console.error(err)
        }
    }
}
