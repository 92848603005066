import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
    selector: 'form-select-input',
    templateUrl: './form-select-input.component.html',
    styleUrls: ['./form-select-input.component.scss']
})
export class SelectInputComponent {
    @Input() formGroup: FormGroup;
    @Input() controlName: string;
    @Input() label: string;
    @Input() appearance: MatFormFieldAppearance = 'outline';
    @Input() items: any[] = [];
    @Input() displayProperty: string;
    @Input() readonly = false;
    @Input() loading = false;
    @Input() hint = '';
    @Input() icon: any;
    @Input() defaultErrorMessage: any;
    @Input() invalidPatternMessage: string;
    @Input() customErrorMessage: string;
    @Input() withoutPadding = false;
    @Input() getProperties: (value: any) => string;
}
