import { Injectable } from '@angular/core';
import { CpomModel } from 'src/app/core/models/cpom.model';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/core/base/base.service';

@Injectable({
    providedIn: 'root'
})
export class CpomService extends BaseService<CpomModel>{
    defaultSort = 'createdAt';

    constructor(public http: HttpClient) {
        super(http, 'cpom');
    }
}


