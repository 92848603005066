import { Component, Inject, Input, OnInit } from '@angular/core';
import { BillService } from '../../bill.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import icClose from '@iconify/icons-ic/twotone-close';
import {ENUM_PERMISSIONS} from "../../../../../../core/enums/permission.enum";

@Component({
  selector: 'vex-bill-preview-modal',
  templateUrl: './bill-preview-modal.component.html',
  styleUrls: ['./bill-preview-modal.component.scss']
})
export class BillPreviewModalComponent implements OnInit {

  url = null;
  ENUM_PERMISSION = ENUM_PERMISSIONS;
  loading = false;
  icClose = icClose;
  errorMessage;

  @Input() showToPayButton: boolean = false;

  constructor(
      @Inject(MAT_DIALOG_DATA) public data,
      public service: BillService,
      public dialogRef: MatDialogRef<BillPreviewModalComponent>,
      private sanitizer : DomSanitizer
  ) { }

  ngOnInit(): void {
    if (this.data?.showToPayButton) this.showToPayButton = this.data?.showToPayButton;
    this.loading = true;
    this.service.gedGetDocumentPdfViewerUrl(this.data.docuwareId).subscribe((data: any) => {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(data);
      this.loading = false;
    }, error => {
      console.log(error)
      this.errorMessage = error.message;
      this.loading = false;
    });
  }

  close(result: any = null) {
    this.dialogRef.close(result);
  }

}
