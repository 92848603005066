import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import {
    AbstractControl,
    AsyncValidatorFn,
    FormArray,
    FormControl,
    FormGroup,
    ValidationErrors,
    Validators
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SupplierModel } from 'src/app/core/models/supplier.model';
import { SupplierService } from '../../supplier.service';
import { FormModalBaseComponent } from 'src/app/core/base/components/form-modal-base/form-modal-base.component';
import { SupplierType } from '../../../../../../core/enums/supplier-type.enum';
import { Country } from '../../../../../../core/enums/countries.enum';
import { FrenchTerritoryHelper } from '../../../../../../core/helpers/frenchTerritory.helper';
import { FormSwitchValidatorHelper } from '../../../../../../core/helpers/formSwitchValidator.helper';
import { FormResetManyHelper } from '../../../../../../core/helpers/formResetMany.helper';
import { Observable, of } from 'rxjs';
import { CategoryService } from '../../../../settings/category/category.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AuthService } from '../../../../../../core/services/auth.service';
import { ENUM_PERMISSIONS } from '../../../../../../core/enums/permission.enum';
import { ResponseTypeEnum } from '../../../../../../core/base/enum/response-type.enum';
import { CrudModeEnum } from '../../../../../../core/base/enum/crud-mode.enum';
import { DialogConfirmComponent } from '../../../../../../../@vex/components/dialog-confirm/dialog-confirm.component';
import { SnackbarService } from '../../../../../../core/services/snackbar.service';
import { CategoryModel } from '../../../../../../core/models/category.model';


export function tvaNumberValidator(sirenControl: AbstractControl, countryControl: AbstractControl): AsyncValidatorFn {
    return (tvaControl: AbstractControl): Observable<ValidationErrors | null> => {
        const siren = sirenControl.value;
        const tvaNumber = tvaControl.value;
        const country = countryControl.value;

        if (country !== Country.FR) {
            return of(null);
        }

        // Validation réussie si le champ est vide ou que le numéro de TVA commence par le code pays

        // Validation échoue si le numéro de TVA ne contient pas le SIREN correspondant
        if (tvaNumber.substring(4, 13) !== siren) {
            return of({customError: true});
        }

        // Validation réussie
        return of(null);
    };
}

@Component({
    selector: 'vex-supplier-create-update',
    templateUrl: './supplier-create-update-delete.component.html',
    styleUrls: ['./supplier-create-update-delete.component.scss']
})
export class SupplierCreateUpdateDeleteComponent extends FormModalBaseComponent<SupplierModel> implements OnInit {

    supplierType: Array<string>;
    addressForm: FormGroup;
    countries: Array<any>;
    isFrench = true;
    isIntern = false;
    submittedToTva = false;
    origin: any;
    families: Array<any> = [];

    canCreateMode = false;
    canUpdateMode = false;
    canDeleteMode = false;
    canReadMode = false;
    canImportMode = false;
    canArchiveMode = false;

    separatorKeysCodes: number[] = [ENTER, COMMA];
    itemCtrl = new FormControl();
    filteredFamilies: Observable<string[]>;
    familiesSupplier: Array<any> = [];

    @ViewChild('itemInput') itemInput: ElementRef<HTMLInputElement>;

    isDuplicate = false;

    ENUM_PERMISSIONS = ENUM_PERMISSIONS;

    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public dialogRef: MatDialogRef<FormModalBaseComponent<SupplierModel>>,
                public service: SupplierService,
                private categoryService: CategoryService,
                public authService: AuthService,
                private dialog: MatDialog,
                private supplierService: SupplierService,
                public snackbarService: SnackbarService) {
        super(data, dialogRef, service);
    }

    async ngOnInit() {


        super.ngOnInit();


        this.canCreateMode = this.isCreateMode();
        this.canUpdateMode = this.isUpdateMode();
        this.canDeleteMode = this.isDeleteMode();
        this.canReadMode = this.isReadMode();
        this.canImportMode = this.isImportMode();
        this.canArchiveMode = this.isArchiveMode();

        this.isIntern = this.defaults?.type === SupplierType.INTERNE;
        this.origin = this.defaults;
        this.isFrench = this.isUpdateMode() ? this.defaults?.headquarterAddress?.country === Country.FR : true;
        this.supplierType = Object.values(SupplierType);
        this.countries = Object.values(Country);


        const countryControl = new FormControl(this.isUpdateMode() ? this.defaults?.headquarterAddress?.country : Country.FR);
        const sirenControl = new FormControl(this.defaults?.sirenNumber, [Validators.required, Validators.pattern(/^[0-9]{9}$/)]);
        const tvaControl = new FormControl(this.defaults?.tva, [
            Validators.required,
            Validators.pattern(/^(AT|BE|BG|CY|CZ|DE|DK|EE|EL|ES|FI|FR|GB|HR|HU|IE|IT|LT|LU|LV|MT|NL|PL|PT|RO|SE|SI|SK)[0-9A-Z]{9,11}$/),
        ], [tvaNumberValidator(sirenControl, countryControl)]);

        this.addressForm = new FormGroup({
            street: new FormControl(this.defaults?.headquarterAddress?.street, [Validators.required]),
            additionalAddress: new FormControl(this.defaults?.headquarterAddress?.additionalAddress),
            postalCode: new FormControl(this.defaults?.headquarterAddress?.postalCode, [Validators.pattern(/^[0-9]{5}$/)]),
            city: new FormControl(this.defaults?.headquarterAddress?.city, [Validators.required]),
            country: countryControl
        });

        this.form = new FormGroup({
            _id: new FormControl(this.defaults?._id),
            type: new FormControl(this.defaults?.type, [Validators.required]),
            label: new FormControl(this.defaults?.label, [Validators.required]),
            siretNumber: new FormControl(this.defaults?.siretNumber, [Validators.required]),
            sirenNumber: sirenControl,
            codeApe: new FormControl(this.defaults?.codeApe, [
                Validators.required,
                Validators.pattern(/^\d{4}[A-Z]$/)
            ]),
            dunsNumber: new FormControl(this.defaults?.dunsNumber, null),
            tva: tvaControl,
            headquarterAddress: this.addressForm,
            subsidiarAccount: new FormControl(this.defaults?.subsidiarAccount, [Validators.maxLength(13)]),
            submittedToTva: new FormControl(false),
            famillies: new FormArray((this.data?.defaults?.famillies || []).map(x => new FormControl(x)))
        });


        this.categoryService.searchByLevel(1).subscribe(res => {
            res.sort((a, b) => a.link.localeCompare(b.link));
            this.families = res;

            if (this.isUpdateMode() && this.defaults?.famillies?.length > 0) {
                this.families.filter(family => this.defaults?.famillies?.some((result: CategoryModel) => {
                    return result._id === family._id;
                }));
            }

            this.filteredFamilies = this.itemCtrl.valueChanges.pipe(
                startWith(null),
                map((item: string | null) => (item ? this.filterNormalized(this.families, item) : this.families.slice())),
            );

        });

        if (this.isUpdateMode()) {
            if (this.defaults?.tva !== null) {
                this.submittedToTva = true;
                this.form.controls.submittedToTva.setValue(true);
                this.form.controls.tva.addValidators(Validators.required);
            }
        }

        this.addressForm.controls.country.valueChanges.subscribe(value => {
            this.isFrench = value ? FrenchTerritoryHelper(value) : true;
            if (!this.isIntern) {
                if (this.isFrench) {
                    FormSwitchValidatorHelper(this.form, ['sirenNumber'], [Validators.required, Validators.pattern(/^[0-9]{9}$/)]);
                    FormSwitchValidatorHelper(this.addressForm, ['postalCode'], [Validators.pattern(/^[0-9]{5}$/)]);
                    FormSwitchValidatorHelper(this.form, ['codeApe'], [
                        Validators.required,
                        Validators.pattern(/^\d{4}[A-Z]$/)
                    ]);
                    FormSwitchValidatorHelper(this.form, ['siretNumber', ], [Validators.required]);
                    FormSwitchValidatorHelper(this.form, ['dunsNumber'], null);
                } else {
                    FormSwitchValidatorHelper(this.form, ['siretNumber', 'sirenNumber', 'codeApe'], null);
                    FormSwitchValidatorHelper(this.form, ['dunsNumber'], [Validators.required]);
                    FormSwitchValidatorHelper(this.addressForm, ['postalCode']);

                    // this.form.get('sirenNumber').setValue(null);
                    // this.form.get('siretNumber').setValue(null);
                    // this.form.get('codeApe').setValue(null);
                }
            }
        });

        this.form.controls.type.valueChanges.subscribe(value => {
            if (value === SupplierType.INTERNE) {
                this.isIntern = true;
                this.form.controls.tva.setAsyncValidators(null);
                this.switchValidators(null);

                // this.resetForm();
            } else {
                this.isIntern = false;
                FormSwitchValidatorHelper(this.form, ['sirenNumber'], [Validators.required, Validators.pattern(/^[0-9]{9}$/)]);
                FormSwitchValidatorHelper(this.form, ['codeApe'], [
                    Validators.required,
                    Validators.pattern(/^\d{4}[A-Z]$/)
                ]);
                FormSwitchValidatorHelper(this.form, ['tva'], [
                    Validators.required,
                    Validators.pattern(/^(AT|BE|BG|CY|CZ|DE|DK|EE|EL|ES|FI|FR|GB|HR|HU|IE|IT|LT|LU|LV|MT|NL|PL|PT|RO|SE|SI|SK)[0-9A-Z]{9,11}$/),
                ]);
                FormSwitchValidatorHelper(this.form, ['siretNumber', ], [Validators.required]);
                FormSwitchValidatorHelper(this.form, ['dunsNumber'], null);
            }
        });


        this.form.controls.submittedToTva.valueChanges.subscribe(value => {
            this.submittedToTva = value;
            if (!value) {
                this.form.controls.tva.removeValidators(Validators.required);
            } else {
                this.form.controls.tva.addValidators(Validators.required);
            }
        });


    }


    resetForm() {
        FormResetManyHelper(this.addressForm, ['street', 'postalCode', 'city', 'country', 'additionalAddress']);
        FormResetManyHelper(this.form, ['siretNumber', 'sirenNumber', 'dunsNumber', 'codeApe', 'tva', 'subsidiarAccount']);
    }

    switchValidators(validator: any) {
        FormSwitchValidatorHelper(this.form, ['siretNumber', 'sirenNumber', 'dunsNumber', 'codeApe', 'tva', 'famillies'], validator);
        FormSwitchValidatorHelper(this.addressForm, ['street', 'postalCode', 'city', 'country'], validator);
    }

    beforeCreateItem() {

        if (!this.countries.includes(this.addressForm.controls.country.value)) {
            this.addressForm.controls.country.reset();
        }
        if (this.form.get('type').value === SupplierType.INTERNE) {
            this.defaults = {
                _id: this.defaults._id,
                type: this.defaults.type,
                label: this.defaults.label,
                sirenNumber: null,
                siretNumber: null,
                dunsNumber: null,
                tva: null,
                codeApe: null,
                headquarterAddress: null,
                famillies: this.form.get('famillies').value
            };
        }
        // cas ou c'est externe :
        this.defaults.famillies = this.form.get('famillies').value;

        if (this.addressForm.get('country').value !== Country.FR) {
            this.addressForm.controls.country.clearValidators();
        } else {
            this.addressForm.controls.country.clearValidators();
            this.addressForm.controls.country.addValidators([Validators.required]);
        }

    }

    afterCreateItem(result?: any, error?: any) {
        if (error) {
        } else {
            this.close(result);
        }
    }

    beforeUpdateItem() {

        if (!this.countries.includes(this.addressForm.controls.country.value)) {
            this.addressForm.controls.country.reset();
        }

        if (this.isIntern) {
            this.defaults = {
                _id: this.defaults._id,
                type: this.defaults.type,
                label: this.defaults.label,
                sirenNumber: null,
                siretNumber: null,
                dunsNumber: null,
                tva: null,
                codeApe: null,
                headquarterAddress: null,
                famillies: this.form.get('famillies').value
            };
            FormSwitchValidatorHelper(this.addressForm, ['city', 'country', 'postalCode', 'street'], null);
            FormSwitchValidatorHelper(this.form, ['siretNumber', 'sirenNumber', 'codeApe', 'dunsNumber', 'tva', 'headquarterAddress', 'subsidiarAccount'], null);
        }

        if (!this.isIntern && !this.isFrench) {
            this.defaults.sirenNumber = null;
            this.defaults.siretNumber = null;
            this.defaults.codeApe = null;
            FormSwitchValidatorHelper(this.form, ['siretNumber', 'sirenNumber', 'codeApe'], null);
        }

        if (!this.isIntern && this.isFrench) {
            this.defaults.dunsNumber = null;
            FormSwitchValidatorHelper(this.form, ['dunsNumber'], null);
        }

        if (this.form.controls.submittedToTva.value === false) {
            this.form.controls.tva.setValue('');
            this.defaults.tva = null;
        }

        if (this.defaults.sirenNumber === this.origin.sirenNumber) {
            this.form.controls.sirenNumber.removeValidators(Validators.required);
            this.form.getRawValue().sirenNumber = null;
        }

        if (this.defaults.siretNumber === this.origin.siretNumber) {
            this.form.controls.siretNumber.removeValidators(Validators.required);
            this.form.getRawValue().siretNumber = null;
        }
        this.defaults.famillies = this.form.get('famillies').value;
    }

    afterUpdateItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
        }
    }

    afterImportItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
        }
    }


    // ajout des méthodes pour le champs chips de famille d'achat ou poste de dépense
    // add(event: MatChipInputEvent): void {
    //     console.log(event)
    //     const value = (event.value || '').trim();
    //
    //     if (value) {
    //         this.familiesSupplier.push(value);
    //     }
    //
    //     event.chipInput!.clear();
    //
    //     this.itemCtrl.setValue(null);
    //     console.log(event, "-----event")
    // }

    remove(item: string): void {
        const index = this.form.get('famillies').value.indexOf(item);
        if (index >= 0) {
            this.form.get('famillies').value.splice(index, 1);
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        // le boolean permet de générer un message d'erreur dans le chips si l'utilisateur a voulu mettre un doublon
        this.isDuplicate = false;
        const selectedFamily = event.option.value;
        const familiesArray = this.form.get('famillies').value;

        // Rechercher l'index de la famille en double dans le tableau
        const duplicateIndex = familiesArray.findIndex(family => family._id === selectedFamily._id);

        // Si l'élément est en double, le remplacer, sinon l'ajouter à la liste des chips
        if (duplicateIndex !== -1) {
            familiesArray[duplicateIndex] = selectedFamily;
            // Le message d'erreur se lancera en cas de tentative d'insertion de doublon.
            this.isDuplicate = true;
        } else {
            familiesArray.push(selectedFamily);
        }
        this.itemInput.nativeElement.value = '';
        this.itemCtrl.setValue(null);
    }

    onKeyDown(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    async createItem() {
        this.loading = true;
        this.defaults = this.form.getRawValue();
        this.beforeCreateItem();
        if (this.form.valid) {
            const sub = this.service.create(this.defaults).subscribe(
                result => {

                    this.afterCreateItem(result, null);
                    this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Create, result.message);

                }, async error => {

                    if (error.error.errors.map(x => x.statusCode === 5554)) {
                        const foundSupplierIsArchive = error.error.errors.map(x => x.message).toString();
                        this.supplierService.findById(foundSupplierIsArchive).subscribe(
                            result => {

                                result.data.isArchive = false;
                                this.dialog.open(DialogConfirmComponent, {
                                    data: {
                                        title: `Ce SIRET existe déjà pour le fournisseur ${result.data.label}`,
                                        description: `Merci de confirmer sa remise en service`,
                                        cancelText: 'Annuler',
                                        validText: 'Confirmer',
                                        call$: this.supplierService.update(result.data)
                                    },
                                }).afterClosed().subscribe((finalResult) => {
                                    if (finalResult) {
                                        this.dialogRef.close();
                                        this.snackbarService.success('Le fournisseur a été remis en service avec succès');
                                    }
                                });

                            }
                        );

                    }

                    this.afterCreateItem(null, error);
                    this.setErrorsMessages(error.error.errors);
                    this.loading = false;
                }
            );
            this.subscription.add(sub);
        } else {
            this.onFormInvalid();
            this.logFormErrors(this.form);
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }
}



