<div class='py-3' fxLayout='row' fxLayoutAlign='start center'>
    <div @scaleIn
         [ngClass]='{"bg-primary-light text-primary" : isActive, "bg-gray-light text-gray": !isActive}'
         class='w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center'>
        <ic-icon [icon]='icon' size='20px'></ic-icon>
    </div>

    <div @fadeInRight>
        <p class='m-0 caption text-hint'>{{ label }}</p>
    </div>
</div>
