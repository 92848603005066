import {Component, OnInit} from '@angular/core';
import {AnimationOptions} from 'ngx-lottie';
import {AnimationItem} from 'lottie-web';
import {Router} from "@angular/router";

@Component({
    selector: 'vex-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent implements OnInit {

    options: AnimationOptions = {
        path: '/assets/lottie/unauthorized-lottie.json',
    };
    loading = true;
    pourcentsLeft: number = 100;
    loadingCooldown = 4;

    constructor(
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.loading = true;
    }

    onAnimate(animationItem: AnimationItem): void {
        this.loading = false;

        const interval = setInterval(() => {
            this.pourcentsLeft -= 0.1;
            if (this.pourcentsLeft === 0) {
                clearInterval(interval);
            }
        }, this.loadingCooldown);
    }

    // redirectToHome() {
    //     this.router.navigate(['/']);
    // }
}
