import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TicketService} from '../../../ticket.service';
import {
    FormModalBaseComponent
} from '../../../../../../../core/base/components/form-modal-base/form-modal-base.component';
import {TicketModel} from '../../../../../../../core/models/ticket.model';
import {SnackbarService} from '../../../../../../../core/services/snackbar.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TicketTypeEnum} from '../../../../../../../core/enums/ticket-type.enum';
import {TicketStatusEnum} from '../../../../../../../core/enums/ticketStatusEnum';
import {finalize, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {EstablishmentService} from '../../../../../management/establishment/establishment.service';
import {AuthService} from '../../../../../../../core/services/auth.service';

@Component({
    selector: 'ticket-requirement-create-update-delete-component',
    templateUrl: 'ticket-requirement-create-update-delete.component.html'
})

export class TicketRequirementCreateUpdateDeleteComponent extends FormModalBaseComponent<TicketModel> implements OnInit {
    establishments$: Observable<any>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        public service: TicketService,
        public dialogRef: MatDialogRef<FormModalBaseComponent<TicketModel>>,
        public snackbarService: SnackbarService,
        private establishmentService: EstablishmentService,
        private authService: AuthService,
    ) {
        super(data, dialogRef, service);
    }

    ngOnInit() {
        super.ngOnInit();
        this.initForm();
        this.autocompleteEstablishment('').then();
    }

    initForm() {
        this.form = new FormGroup({
            establishment: new FormControl(null, [Validators.required]),
        });
    }

    createItem(toDraft?: boolean, status?: TicketStatusEnum) {
        const {_id, label, technicalIdentifier, ...rest} = this.form.getRawValue().establishment;
        this.form.getRawValue().establishment = {_id, label, technicalIdentifier};
        const data: TicketModel = {
            establishment: _id,
            type: TicketTypeEnum.REQUIREMENT,
        };
        if (this.form.valid) {
            this.loading = true;
            this.service.create(data)
                .pipe(finalize(() => this.loading = false))
                .subscribe((response) => {
                    this.snackbarService.success('Ticket créé avec succès');
                    this.dialogRef.close(response);
                });
        }
    }

    async autocompleteEstablishment(search = '') {
        const user = await this.authService.currentUserValue();
        const establishmentIds = user.affectations.map(x => x.establishment._id);
        this.establishments$ = this.establishmentService
            .findAllAsSelect(true, 'label', 'desc', search, {includeIds: establishmentIds, setLimit: true})
            .pipe(map(res => res.data));
    }
}
