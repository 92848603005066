import { Component, OnInit } from '@angular/core';
import { DetailsBaseComponent } from '../../../../../core/base/components/details-base/details-base.component';
import { EquipmentModel } from '../../../../../core/models/equipment.model';
import { EquipmentService } from '../equipment.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../../../core/services/auth.service';
import { ENUM_PERMISSIONS } from '../../../../../core/enums/permission.enum';

@Component({
    selector: 'vex-equipment-details',
    templateUrl: './equipment-details.component.html',
    styleUrls: ['./equipment-details.component.scss']
})
export class EquipmentDetailsComponent extends DetailsBaseComponent<EquipmentModel> {

    constructor(
        public service: EquipmentService,
        public activatedRoute: ActivatedRoute,
        public authService: AuthService
    ) {
        super(service, activatedRoute, authService);
    }

    defaultTabs = [
        {
            label: 'INFORMATIONS',
            route: './',
            routerLinkActiveOptions: {exact: true},
            permission : ENUM_PERMISSIONS.READ_EQUIPMENT
        },
        {
            label: 'CARNET D\'ENTRETIEN',
            route: './service-book',
            permission: ENUM_PERMISSIONS.READ_EQUIPMENT
        },
        // {
        //     label: 'CONTRATS',
        //     route: './contract',
        //     permission: ENUM_PERMISSIONS.READ_EQUIPMENT_INFO_EQUIPMENT
        // },
        {
            label: 'DOCUMENTS',
            route: './documents',
            permission : ENUM_PERMISSIONS.READ_EQUIPMENT
        },
    ];
}
