<ng-container *ngIf="isCreateMode() || isUpdateMode()">
    <form (ngSubmit)="save()" [formGroup]="form">
        <base-form-header [mode]='mode' createLabel='Ajouter un équipement'
                          [updateLabel]='form.get("reference").value'></base-form-header>

        <mat-dialog-content fxLayout="column">


            <div class="flex flex-col sm:flex-row">
                <base-form-input
                        class='sm:mr-3 flex-auto'
                        label='Référence équipement'
                        controlName='reference'>
                </base-form-input>
                <base-form-input
                        class='sm:ml-3 flex-auto'
                        label='numéro de série (info. constructeur)'
                        controlName='serialNumber'>
                </base-form-input>
            </div>

            <base-form-input
                    label='Marque'
                    controlName='mark'>
            </base-form-input>

            <base-form-input
                    label='Modèle'
                    controlName='model'>
            </base-form-input>

            <!--            <base-form-input-->
            <!--                    label="Type d'équipement"-->
            <!--                    type='autocomplete'-->
            <!--                    [items$]='equipmentType$'-->
            <!--                    (autocompleteChange)='genericEquipmentAutoCompleteChange($event)'-->
            <!--                    displayProperty='label'-->
            <!--                    controlName='equipmentType'>-->
            <!--            </base-form-input>-->

            <base-form-input
                    label='Équipement générique'
                    type='autocomplete'
                    [items$]='genericEquipment$'
                    (autocompleteChange)='genericEquipmentAutoCompleteChange($event)'
                    displayProperty='label'
                    controlName='genericEquipment'>
            </base-form-input>

            <base-form-input
                    *ngIf='isUpdateMode()'
                    [disabled]='true'
                    label='Etablissement'
                    controlName='establishmentDisable'>
            </base-form-input>

            <base-form-input
                    *ngIf='!isUpdateMode()'
                    label='Etablissement'
                    type='autocomplete'
                    [items$]='establisment$'
                    (autocompleteChange)='establishmentAutoCompleteChange($event)'
                    displayProperty='label'
                    controlName='establishment'>
            </base-form-input>

            <base-form-input
                    label="Référent de l'équipement"
                    type='autocomplete'
                    [items$]='contacts$'
                    displayProperty='fullName'
                    controlName='contactIntern'>
            </base-form-input>

            <div class="flex flex-col sm:flex-row">
                <base-form-input
                        class='sm:mr-3 flex-auto'
                        label="Date d’achat de l'équipement"
                        type='date'
                        controlName='purchaseDate'>
                </base-form-input>
                <base-form-input
                        class='sm:ml-3 flex-auto'
                        type='date'
                        label='Date de mise en service'
                        controlName='commissioningDate'
                        [dateFilter]='filter'
                >
                </base-form-input>
            </div>


            <base-form-input
                    label='Fin de garantie constructeur'
                    type='date'
                    controlName='endManufacturerDate'
                    [dateFilter]='filter'
            >
            </base-form-input>

            <div class="flex flex-col sm:flex-row">

                <base-form-input
                        class='sm:mr-3 flex-auto'
                        label="Coût acquisition HT"
                        type='number'
                        controlName='valueAmortization'>
                </base-form-input>
                <!--                <base-form-input-->
                <!--                    class='sm:mr-3 flex-auto'-->
                <!--                    label="Date début d'amortissement"-->
                <!--                    type='date'-->
                <!--                    controlName='startAmortizationDate'>-->
                <!--                </base-form-input>-->
                <base-form-input
                        *ngIf='form.get("valueAmortization").value >= 500'
                        class='sm:ml-3 flex-auto'
                        type='number'
                        label="Durée amortissement"
                        controlName='depreciationPeriod'>
                </base-form-input>
            </div>

            <base-form-input
                    *ngIf='form.get("valueAmortization").value >= 500'
                    [disabled]="true"
                    label="Date début d'amortissement"
                    type='date'
                    controlName='startAmortizationDate'>
            </base-form-input>

            <base-form-input
                    *ngIf='form.get("valueAmortization").value >= 500'
                    [disabled]='true'
                    label="Date fin d'amortissement"
                    type='date'
                    controlName='endAmortizationDate'>
            </base-form-input>

        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <base-form-input
                    type='submit'
                    [mode]='mode'
                    entityLabel='EQUIPMENT'
                    (onCreate)='createItem()'
                    (onUpdate)='updateItem()'
                    [loading]="loading"
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>

<ng-container *ngIf="isDeleteMode()">
    <form-modal-delete-base
            [label]="data.name"
            (onClose)="close()"
            (onDelete)="deleteItem()"
    ></form-modal-delete-base>
</ng-container>


<ng-container *ngIf="isImportMode()">
    <vex-form-import-base
            [modalTitle]='"Importer des équipements"'
            [entityLabel]='"Equipement"'
            [form]='importForm'
            [loading]='loading'
            [service]='service'
            [mode]='mode'
            (saveForm)='save()'
            (importItems)='importItems()'
            [canDownloadTemplateModel]="canDownloadTemplateModel"
    ></vex-form-import-base>
</ng-container>

<ng-container *ngIf='isArchiveMode()'>
    <form-modal-delete-base
            (onClose)='close()'
            (onDelete)='archiveItem()'
            title="Archiver l'équipement"
            defaultMessage='Êtes-vous sûr de vouloir archiver cet équipement ?'
    >
    </form-modal-delete-base>
</ng-container>
