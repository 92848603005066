import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BuildingService} from '../building.service';
import {DetailsBaseComponent} from '../../../../../core/base/components/details-base/details-base.component';
import {BuildingModel} from '../../../../../core/models/building.model';
import {scaleIn400ms} from '../../../../../../@vex/animations/scale-in.animation';
import {fadeInRight400ms} from '../../../../../../@vex/animations/fade-in-right.animation';
import {stagger40ms} from '../../../../../../@vex/animations/stagger.animation';
import {fadeInUp400ms} from '../../../../../../@vex/animations/fade-in-up.animation';
import {scaleFadeIn400ms} from '../../../../../../@vex/animations/scale-fade-in.animation';
import {AuthService} from "../../../../../core/services/auth.service";
import {ENUM_PERMISSIONS} from "../../../../../core/enums/permission.enum";

@Component({
    selector: 'vex-building-details',
    templateUrl: './building-details.component.html',
    styleUrls: ['./building-details.component.scss'],
    animations: [
        scaleIn400ms,
        fadeInRight400ms,
        stagger40ms,
        fadeInUp400ms,
        scaleFadeIn400ms
    ]
})
export class BuildingDetailsComponent extends DetailsBaseComponent<BuildingModel> {

    defaultTabs = [
        {
            label: 'INFORMATIONS',
            route: './',
            routerLinkActiveOptions: {exact: true},
            permission : ENUM_PERMISSIONS.READ_BUILDING_INFO
        },
        // {
        //   label: 'FRIENDS',
        //   route: '',
        //   disabled: true
        // },
        // {
        //   label: 'PHOTOS',
        //   route: '',
        //   disabled: true
        // }
        {
            label: 'SALLES',
            route: './room',
            permission: ENUM_PERMISSIONS.READ_BUILDING_ROOM
        },
        {
            label: 'DOCUMENTS',
            route: './document',
        }
    ];

    constructor(public service: BuildingService, public activatedRoute: ActivatedRoute, public authService: AuthService) {
        super(service, activatedRoute, authService);
    }
}
