

<div class="flex items-center justify-between w-full">
   <!-- Bloc d'informations de version (à gauche) -->


   <!-- Éléments de la toolbar alignés à droite -->
   <div class="flex items-center">
      <ng-container *ngIf="isContactTypePresent && !isProductionEnvironment">
         <a href="https://support-predik360.atlassian.net/servicedesk/customer/"
            class="no-underline text-primary flex items-center py-2 px-3"
            target="_blank" rel="noopener noreferrer">
            <img src="assets/img/supportClient.png" alt="Support client">
            <span class="body-1 font-medium leading-snug ltr:ml-3 rtl:ml-3">Support client</span>
         </a>
      </ng-container>
      <a href="https://predik360.sharepoint.com/sites/OHS-Missionsencours/SitePages/Vid%C3%A9os---PREDIK360.aspx"
         class="no-underline text-primary flex items-center py-2 px-3"
         target="_blank" rel="noopener noreferrer">
         <mat-icon>menu_book</mat-icon>
         <span class="body-1 font-medium leading-snug ltr:ml-3 rtl:ml-3">Guide utilisateur</span>
      </a>
      <div #originRef
           (click)="showPopover(originRef)"
           [class.bg-hover]="dropdownOpen"
           class="flex items-center rounded cursor-pointer relative trans-ease-out select-none py-1 pr-1 pl-3 hover:bg-hover"
           matRipple>
         <div *ngIf='user$ | async as user' class="body-1 font-medium leading-snug ltr:mr-3 rtl:ml-3"
              fxHide.xs>{{ user.firstName | titlecase }} {{ user.lastName | titlecase }}
         </div>
         <div class="rounded-full h-9 w-9 flex items-center justify-center text-primary bg-primary-light">
            <mat-icon [icIcon]="icPerson"></mat-icon>
         </div>
      </div>
   </div>
</div>
