<ng-container *ngIf="isCreateMode() || isUpdateMode()">
    <form (ngSubmit)="save()" [formGroup]="form">
        <base-form-header [mode]='mode' createLabel='Ajouter un emplacement' [updateLabel]="'Modifier l\'emplacement'"></base-form-header>

        <mat-dialog-content fxLayout="column">
            <base-form-input
                    label='Bâtiment'
                    type='autocomplete'
                    [items$]='buildings$'
                    displayProperty='label'
                    (autocompleteChange)='autocompleteBuilding($event)'
                    controlName='building'>
            </base-form-input>

            <base-form-input
                    *ngIf="displayRoom"
                    [disabled]='isDisable'
                    type='autocomplete'
                    displayProperty='label'
                    [items]='rooms'
                    label='Salle'
                    controlName='room'>
            </base-form-input>

        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <base-form-input
                    type='submit'
                    [mode]='mode'
                    entityLabel='EQUIPMENT'
                    (onCreate)='createItem()'
                    (onUpdate)='updateItem()'
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>

<ng-container *ngIf="isDeleteMode()">
    <form-modal-delete-base
            [label]="data.name"
            (onClose)="close()"
            (onDelete)="deleteItem()"
    ></form-modal-delete-base>
</ng-container>
