<ng-container *ngIf="(contractService.entity$ | async) as contract">
    <div class=' md:block card mt-6 h-full'>
        <catalog-search-bar-base
                (fillterEvent)="onFilterEvent($event)"
                [popupFilter]="true"
                [contentPopupTemplate]='contentPopupTemplate'
                [popupForm]="popupForm"

        ></catalog-search-bar-base>
    </div>
    <div class=' md:block card mt-6 h-full tooltip-container'>
        <mat-tab-group #tabGroup (selectedIndexChange)="tabChanges($event)">
            <mat-tab *ngIf="hasArticle && !articleEmpty" label='Articles'>
                <ng-container>
                    <base-data-table
                            [count]="countProduct"
                            [pageSize]='pageSize'
                            [sortField]="sortField"
                            [sortOrder]='sortOrder'
                            (onPageEvent)='pageEvent($event)'
                            (onSortEvent)='sortEvent($event)'
                            [feisColumns]="feisColumnsArticle"
                            [customColumnTemplate]="templateRef"
                            [dataSource]="dataSourceArticle"
                            [displayedColumns]="displayedColumnsPrestation"
                            [showSort]="true"
                            [showPaginator]="true"
                            (onUpdateItem)='updateItem($event)'
                            (onArchiveItem)="archiveItem($event)"
                            (onDeleteItem)= "deleteItem($event)"
                    >
                    </base-data-table>

                    <button *ngIf="isEditableCatalog" color='primary' mat-raised-button type='button'
                            (click)="createContractItem()">
                        <mat-icon>add</mat-icon>
                        Ajouter un article
                    </button>
                    <button *ngIf="isEditableCatalog" color='primary' mat-raised-button type='button'
                            (click)="importContractItems()">
                        <mat-icon>upload_file</mat-icon>
                        Importer des articles
                    </button>
                </ng-container>
            </mat-tab>


            <mat-tab *ngIf="hasPrestation && !prestationEmpty" label='Prestations'>
                <ng-container>
                    <base-data-table
                            [count]="countPrestation"
                            [pageSize]='pageSize'
                            [sortField]="sortField"
                            [sortOrder]='sortOrder'
                            (onPageEvent)='pageEvent($event)'
                            (onSortEvent)='sortEvent($event)'
                            [feisColumns]="feisColumnsPrestation"
                            [dataSource]="dataSourcePrestation"
                            [customColumnTemplate]="templateRef"
                            [displayedColumns]="displayedColumnsPrestation"
                            [showSort]="true"
                            [showPaginator]="true"
                            (onUpdateItem)='updateItem($event)'
                            (onArchiveItem)="archiveItem($event)"
                            (onDeleteItem)= "deleteItem($event)"
                    >
                    </base-data-table>

                    <button *ngIf="isEditableCatalog" color='primary' mat-raised-button type='button'
                            (click)="createContractItem()">
                        <mat-icon>add</mat-icon>
                        Ajouter une prestation
                    </button>
                    <button *ngIf="isEditableCatalog" color='primary' mat-raised-button type='button'
                            (click)="importContractItems()">
                        <mat-icon>upload_file</mat-icon>
                        Importer des prestations
                    </button>
                </ng-container>
            </mat-tab>
        </mat-tab-group>
    </div>
</ng-container>


<ng-template #contentPopupTemplate>
    <form [formGroup]='popupForm' class="grid">
        <base-form-input
                label='Stockable'
                type='checkbox'
                controlName='stockable'>
            >
        </base-form-input>
        <base-form-input
                label='Visibilité eshop'
                type='checkbox'
                controlName='isAvailable'>
            >
        </base-form-input>
    </form>
</ng-template>

<ng-template #templateRef let-column let-row="element" let-status="status">

    <!--    <ng-container *ngIf="!row.isProduct">-->
    <!--    <div *ngIf="column === 'isFullPerimeterReturn'" class="flex items-center">-->
    <!--        <div (mouseenter)="onMouseEnter(row)" (mouseleave)="onMouseLeave()">-->
    <!--            <a class="w-8 h-8 p-2 leading-none flex items-center justify-center hover:bg-hover text-primary bg-primary-light"-->
    <!--               mat-icon-button-->
    <!--               *ngIf="row.isFullPerimeter">-->
    <!--                <mat-icon [icIcon]="icDone" size="18px"></mat-icon>-->
    <!--            </a>-->

    <!--            <a class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-accent bg-accent-light"-->
    <!--               mat-icon-button-->
    <!--               *ngIf="!row.isFullPerimeter">-->
    <!--                <mat-icon [icIcon]="icClose" size="18px"></mat-icon>-->
    <!--            </a>-->
    <!--        </div>-->
    <!--    </div>-->
    <!--    </ng-container>-->

    <ng-container *ngIf="!row.isProduct">
        <div *ngIf="column === 'isFullPerimeter'" class="flex items-center">
            <div (mouseenter)="onMouseEnter(row)" (mouseleave)="onMouseLeave()">
                <!-- Affichage du texte en fonction de la condition -->
                <span *ngIf="row.isFullPerimeter">complet</span>
                <span *ngIf="!row.isFullPerimeter">ciblé</span>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="row.isProduct">
        <div *ngIf="column === 'isFullPerimeter'" class="flex items-center">
            <div (mouseenter)="onMouseEnter(row)" (mouseleave)="onMouseLeave()">
                <a class="w-8 h-8 p-2 leading-none flex items-center justify-center hover:bg-hover text-primary bg-primary-light"
                   mat-icon-button
                   *ngIf="row.isFullPerimeter">
                    <mat-icon [icIcon]="icDone" size="18px"></mat-icon>
                </a>

                <a class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-accent bg-accent-light"
                   mat-icon-button
                   *ngIf="!row.isFullPerimeter">
                    <mat-icon [icIcon]="icClose" size="18px"></mat-icon>
                </a>
            </div>
        </div>
    </ng-container>

    <div *ngIf="column === 'reference'" class="relative flex items-center"
         (mouseenter)="showPreviousReference = row._id" (mouseleave)="showPreviousReference = null">
        <span>{{ row.reference }}</span>
        <div [hidden]="showPreviousReference !== row._id" class="tooltip-box__content">
            <div *ngIf="row.previousReference" class="p-2 bg-gray-100 border rounded shadow-lg max-w-3xl overflow-auto"
                 [innerHTML]="row.previousReference"></div>
        </div>
    </div>
    <div *ngIf="column === 'tva'" class="relative flex items-center">
        <span >{{ getTvaRate(row.tva) }}</span>
    </div>
    <div *ngIf='column === "interventionRequired"' class="flex items-center">
        <!--                <button (click)="createPrestIntervention(row)"-->
        <!--                        *ngIf="isToPlan(row)"-->
        <!--                        color='primary' mat-raised-button type='button'-->
        <!--                        class="m-0"-->
        <!--                        [disabled]="isExistingIntervention(row)">-->
        <!--                    Planifier-->
        <!--                </button>-->

        <button (click)="createPrestIntervention(row)"
                *ngIf="isToPlan(row)"
                color='primary' mat-raised-button type='button'
                class="m-0"
                [disabled]="this.contract.status === ContractStatusEnum.VALIDATED"
        >
            Planifier
        </button>

        <a (click)="$event.stopPropagation();"
           *ngIf="!isToPlan(row)"
           class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-accent bg-accent-light"
           mat-icon-button>
            <mat-icon
                    *ngIf="showTooltipText(row)"
                    [icIcon]="icClose" size="18px"
                    [matTooltipClass]=" {'text-base': true, 'material-tooltip': true}"
                    [matTooltip]="getTooltipText(row)"
            >
            </mat-icon>

            <mat-icon
                    *ngIf="!showTooltipText(row)"
                    [icIcon]="icClose" size="18px"
            >
            </mat-icon>
        </a>
    </div>
</ng-template>


<div class="tooltip-box card p-4 ml-10"
     *ngIf="tooltip"
     (mouseenter)="onMouseEnterTooltip()"
     (mouseleave)="onMouseLeaveTooltip()">
    <vex-contract-details-user-perimeter
            [entity]="contract"
            [parentItems]="perimeter"
            [isCheckboxDisabled]="true"
            [isCompressed]="true"
            [noReloadData]="true"
            [stockedData]="stockedData"
            [readMode]="isFullPerimter"
            (stockData)="onStockData($event)">
    </vex-contract-details-user-perimeter>
</div>

