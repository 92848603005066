import {RandomUtils} from '../utils/random-utils';

export enum OrderStatus {
    DRAFT = 'Précommande',
    PENDING = 'En validation',
    ACCEPTED = 'Validée',
    DECLINED = 'Rejetée',
    COMPLIANT = 'Réception conforme',
    PARTIAL = 'Réception partielle',
    NOCOMPLIANT = 'Réception non conforme',
    REGULARIZATION = 'Régularisation',
    EXPIRED = 'Expirée',
    CANCELED = 'Annulée',
    LITIGATION = 'Litige',
    SENT = 'Envoyée',
}

export enum OrderType {
    ORDER = 'Commande',
    BCR = 'BCR'
}

export const orderTypeTranslation = (type: OrderType): string => {
    switch (type) {
        case OrderType.ORDER:
            return 'Commande';
        case OrderType.BCR:
            return 'BCR';
        default:
            return null;
    }
};

export const orderTypeTranslationObject = (type: OrderType) => {
    return {
        _id: RandomUtils.generateId(),
        value: type,
        label: orderTypeTranslation(type)
    };
};

export const orderStatusTranslation = (status: OrderStatus): string => {
    switch (status) {
        case OrderStatus.DRAFT:
            return 'Précommande';
        case OrderStatus.PENDING:
            return 'En validation';
        case OrderStatus.ACCEPTED:
            return 'Validée';
        case OrderStatus.DECLINED:
            return 'Rejetée';
        case OrderStatus.COMPLIANT:
            return 'Réception conforme';
        case OrderStatus.PARTIAL:
            return 'Réception partielle';
        case OrderStatus.NOCOMPLIANT:
            return 'Réception non conforme';
        case OrderStatus.REGULARIZATION:
            return 'Régularisation';
        case OrderStatus.EXPIRED:
            return 'Expirée';
        case OrderStatus.LITIGATION:
            return 'Litige';
        default:
            return 'Annulée';
    }
};

export const orderStatusTranslationObject = (status: OrderStatus) => {
    return {
        _id: RandomUtils.generateId(),
        value: status,
        label: orderStatusTranslation(status)
    };
};

export enum OrderStatusHistoryEnum {
    CREATED = 'CREATED',
    ACCEPTED = 'ACCEPTED',
    PENDING = 'PENDING',
    VALIDATED = 'VALIDATED',
    CANCELED = 'CANCELED',
    DECLINED = 'DECLINED',
    SENT = 'SENT',
}

export const orderStatusHistoryTranslation = (status: OrderStatusHistoryEnum) => {
    switch (status) {
        case OrderStatusHistoryEnum.CREATED.toString():
            return 'Créée';
        case OrderStatusHistoryEnum.ACCEPTED.toString():
            return 'Acceptée';
        case OrderStatusHistoryEnum.PENDING.toString():
            return 'En attente de validation';
        case OrderStatusHistoryEnum.VALIDATED.toString():
            return 'Validée';
        case OrderStatusHistoryEnum.CANCELED.toString():
            return 'Annulée';
        case OrderStatusHistoryEnum.DECLINED.toString():
            return 'Rejetée';
        case OrderStatusHistoryEnum.SENT.toString():
            return 'Envoyée';
        default:
            return null;
    }
};


export enum OrderEmailStatusEnum {
    IN_PROGRESS = 'IN_PROGRESS',
    DELIVERED = 'DELIVERED',
    OPENED = 'OPENED',
    FAILED = 'FAILED',
    UNKNOWN = 'UNKNOWN',
}
