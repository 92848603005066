
export enum EstablishmentStatusEnum {
    UNCOMPLETED = 'UNCOMPLETED',
    COMPLETED = 'COMPLETED',
}

export const establishmentTranslation = (status: EstablishmentStatusEnum) => {
    switch (status) {
        case EstablishmentStatusEnum.UNCOMPLETED:
            return 'Incomplet';
        case EstablishmentStatusEnum.COMPLETED:
            return 'Complet';
        default:
            return 'Statut inconnu';
    }
};

export class EstablishmentStatusTranslation {
    static translate(status: EstablishmentStatusEnum): string {
        switch (status) {
            case EstablishmentStatusEnum.UNCOMPLETED:
                return 'Incomplet';
            case EstablishmentStatusEnum.COMPLETED:
                return 'Complet';
            default:
                return 'Statut inconnu';
        }
    }
}
