import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {MatFormFieldAppearance} from '@angular/material/form-field';

@Component({
    selector: 'form-select-between',
    templateUrl: './form-select-between.component.html',
    styleUrls: ['./form-select-between.component.scss']
})
export class FormSelectBetweenComponent implements OnInit {
    @Input() formGroup: FormGroup;
    @Input() controlName!: string;
    @Input() label = '';
    @Input() appearance: MatFormFieldAppearance = 'outline';
    @Input() hint = '';
    @Input() icon = '';
    @Input() withoutPadding = false;
    @Input() defaultErrorMessage: any;
    @Input() invalidPatternMessage = 'Le modèle est invalide';
    @Input() customErrorMessage = '';
    @Input() minMaxField = ['min', 'max'];
    @Input() typeSelection: 'number' | 'date';
    @Input() selection: 'between' | 'fixed' | 'greaterThen' | 'lessThen' = 'between';
    @Output() onLostFocus = new EventEmitter<void>();

    ngOnInit(): void {
        this.adjustFieldState();
        this.formGroup.get(this.controlName)?.setValue(this.selection);
    }

    onSelectionChange(event: any): void {
        this.selection = event.value;
        this.adjustFieldState();
    }

    adjustFieldState(): void {
        const minControl = this.formGroup.get(this.minMaxField[0]);
        const maxControl = this.formGroup.get(this.minMaxField[1]);

        if (this.selection === 'between') {
            minControl?.enable();
            maxControl?.enable();
        } else {
            minControl?.reset();
            minControl?.disable({ emitEvent: false });
            maxControl?.enable();
        }
    }


}
