import { Injectable } from '@angular/core';
import { TvaModel } from 'src/app/core/models/tva.model';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/core/base/base.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ObjectApiInterface } from '../../../../core/base/interfaces/object-api.interface';
import { map } from 'rxjs/operators';
import { OrderAdditionalCostModel } from '../../../../core/models/order.model';
import { RateList } from '../../../../core/models/generic-tva.model';
import { GenericTvaService } from '../generic-tva/generic-tva.service';

@Injectable({
    providedIn: 'root'
})
export class TvaService extends BaseService<TvaModel>{
    defaultSort = 'createdAt';
    constructor(
        public http: HttpClient,
        private genericTvaService: GenericTvaService,
    ) {
        super(http, 'tva');
    }

    getRateByDate(tva: TvaModel, date: Date = new Date()): RateList {
        return this.genericTvaService.getCurrentRate(tva.genericTva.rateList, date);
    }

    addAccountingAccount(idTva: string, data: any): Observable<ObjectApiInterface<TvaModel>> {
        return this.http.post<ObjectApiInterface<TvaModel>>(`${this.baseUrlApi}/${idTva}/addAccountingAccount`, data)
            .pipe(map(x => {
                this._entity.next(x.data);
                return x;
            }));
    }

    removeAccountingAccount(idTva: string, data: any): Observable<ObjectApiInterface<TvaModel>> {
        return this.http.post<ObjectApiInterface<TvaModel>>(`${this.baseUrlApi}/${idTva}/removeAccountingAccount`, data)
            .pipe(map(x => {
                this._entity.next(x.data);
                return x;
            }));
    }

}


