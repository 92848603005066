import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { PopoverService } from '../../../../../@vex/components/popover/popover.service';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';

@Component({
  selector: 'base-data-table-dropdown',
  templateUrl: './data-table-dropdown-icon.component.html',
  styleUrls: ['./data-table-dropdown-icon.component.scss']
})
export class DataTableDropdownIconComponent implements OnInit {

  dropdownOpen: boolean;
  @Input() count = 0;
  @Input() content: TemplateRef<DataTableDropdownIconComponent>;
  @ViewChild('originRef', { static: true, read: ElementRef }) originRef: ElementRef;
  @Output() eventClear = new EventEmitter<any>();
  popoverRef;
  icFilterList = icFilterList;
  constructor(private popover: PopoverService,
              private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  closePopover() {
    this.popoverRef.close();
    this.cd.markForCheck();
  }

  showPopover(): void {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    this.popoverRef = this.popover.open({
      content: this.content,
      origin: this.originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });



    this.popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }

  clear() {
    this.eventClear.emit();
  }

}
