<div class="mt-6 flex flex-col md:flex-row md:items-start">
    <div class="flex-auto">
        <div id="establishment-resume-details-card" class="card" *ngIf='(establishmentService.entity$ | async) as establishment'>
            <div class='px-gutter py-4 border-b' fxLayout='row' fxLayoutAlign='space-between center'>
                <h2 class='title m-0'>Détails</h2>
                <!-- TODO PERMISSION <button color='primary' mat-raised-button type='button' *hasPermission="[ENUM_PERMISSIONS.UPDATE_CP_ESTABLISHMENT_INFO]" (click)='updateItem()'>MODIFIER</button>-->
                <button color='primary' mat-raised-button type='button' (click)='updateItem()' *ngIf="canUpdatePermission">MODIFIER</button>
            </div>
            <div *ngIf='establishment._id; else loading' class="px-gutter py-4" gdColumns="1fr 1fr" gdColumns.xs="1fr" gdGap="16px">
                <div class="py-3" fxLayout="row" fxLayoutAlign="start center">

                    <div @fadeInRight>
                        <p class="m-0 body-1">{{ establishment.technicalIdentifier }}</p>
                        <p class="m-0 caption text-hint">Identifiant Technique</p>
                    </div>
                </div>

                <div class="py-3" fxLayout="row" fxLayoutAlign="start center">

                    <div @fadeInRight>
                        <p class="m-0 body-1">{{ establishment.label | titlecase }}</p>
                        <p class="m-0 caption text-hint">Libellé</p>
                    </div>
                </div>

                <div *ngIf='establishment.sirenNumber' class="py-3" fxLayout="row" fxLayoutAlign="start center">
                    <div @fadeInRight>
                        <p class="m-0 body-1">{{ establishment.sirenNumber }}</p>
                        <p class="m-0 caption text-hint">N° Siren</p>
                    </div>
                </div>
                <div *ngIf='establishment.siretNumber' class="py-3" fxLayout="row" fxLayoutAlign="start center">
                    <div @fadeInRight>
                        <p class="m-0 body-1">{{ establishment.siretNumber }}</p>
                        <p class="m-0 caption text-hint">N° Siret</p>
                    </div>
                </div>
                <div *ngIf='establishment.dunsNumber' class="py-3" fxLayout="row" fxLayoutAlign="start center">
                    <div @fadeInRight>
                        <p class="m-0 body-1">{{ establishment.dunsNumber }}</p>
                        <p class="m-0 caption text-hint">Identifiant entreprise</p>
                    </div>
                </div>
                <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                    <div @fadeInRight>
                        <p class="m-0 body-1">{{ establishment.tva }}</p>
                        <p class="m-0 caption text-hint">N° TVA</p>
                    </div>
                </div>
                <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                    <div @fadeInRight>
                        <p class="m-0 body-1">{{ establishment.ape }}</p>
                        <p class="m-0 caption text-hint">Code APE</p>
                    </div>
                </div>
                <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                    <div @fadeInRight>
                        <p class="m-0 body-1">{{ establishment.attachedAccountant ? establishment.attachedAccountant.firstName + ' ' + establishment.attachedAccountant.lastName : 'N/A' }}</p>
                        <p class="m-0 caption text-hint">Comptable de rattachement</p>
                    </div>
                </div>
                <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                    <div @fadeInRight>
                        <p class="m-0 body-1">{{ establishment.accountingFile ? establishment.accountingFile : 'N/A' }}</p>
                        <p class="m-0 caption text-hint">Dossier comptable</p>
                    </div>
                </div>
                <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                    <div @fadeInRight>
                        <p class="m-0 body-1">{{ translateValidatorMatriceLevel }}</p>
                        <p class="m-0 caption text-hint">Capacité budgétaire</p>
                    </div>
                </div>
                <details-line-base
                    [label]='establishment.vatStatus ? "Soumis" : "Non soumis"'
                    hint='Statut TVA'
                >
                </details-line-base>
<!--                <div class="py-3" fxLayout="row" fxLayoutAlign="start center">-->
<!--                    <div @fadeInRight>-->
<!--                        <p class="m-0 body-1">{{ establishment?.cpom?.label }}</p>-->
<!--                        <p class="m-0 caption text-hint">Cpom</p>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
                <div class='px-gutter py-4 border-b' fxLayout='row' fxLayoutAlign='space-between center'>
                    <h2 class='title m-0'>Adresse</h2>
                </div>
                <div class="px-gutter py-4" gdColumns="1fr 1fr" gdColumns.xs="1fr" gdGap="16px">
                    <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                        <div @fadeInRight>
                            <p class="m-0 body-1">{{ establishment.headquarterAddress?.street }}</p>
                            <p class="m-0 caption text-hint">Adresse</p>
                        </div>
                    </div>
                    <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                        <div @fadeInRight>
                            <p class="m-0 body-1">{{ establishment.headquarterAddress?.additionalAddress }}</p>
                            <p class="m-0 caption text-hint">Complément d'adresse</p>
                        </div>
                    </div>
                    <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                        <div @fadeInRight>
                            <p class="m-0 body-1">{{ establishment.headquarterAddress?.postalCode }}</p>
                            <p class="m-0 caption text-hint">Code postal</p>
                        </div>
                    </div>
                    <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                        <div @fadeInRight>
                            <p class="m-0 body-1">{{ establishment.headquarterAddress?.city }}</p>
                            <p class="m-0 caption text-hint">Ville</p>
                        </div>
                    </div>
                    <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                        <div @fadeInRight>
                            <p class="m-0 body-1">{{ establishment.headquarterAddress?.country }}</p>
                            <p class="m-0 caption text-hint">Pays</p>
                        </div>
                    </div>
                </div>
        </div>
        <div id="establishment-resume-adresses-card" class="card mt-6">
            <div class='px-gutter py-4 border-b' fxLayout='row' fxLayoutAlign='space-between center'>
                <h2 class='title m-0'>Adresses</h2>
                <div>
                    <!-- TODO PERMISSION
                    <button color='primary' mat-raised-button type='button' *hasPermission="[ENUM_PERMISSIONS.CREATE_CP_ESTABLISHMENT_INFO]" (click)='createAddress()'>AJOUTER</button>
                    <button color='primary' mat-raised-button type='button' class='ml-4' *hasPermission="[ENUM_PERMISSIONS.IMPORT_CP_ESTABLISHMENT_INFO_ADDRESS]" (click)='importAddress()'>IMPORTER</button>
                    -->
                    <button color='primary' mat-raised-button type='button' (click)='createAddress()' *ngIf="canAddAddressPermission">AJOUTER</button>
                    <button color='primary' mat-raised-button type='button' class='ml-4' (click)='importAddress()' *ngIf="canImportPermissions">IMPORTER</button>
                </div>
            </div>

            <!-- TODO PERMISSION [updatePermission]="[ENUM_PERMISSIONS.UPDATE_CP_ESTABLISHMENT_INFO_ADDRESS]"
            [deletePermission]="[ENUM_PERMISSIONS.DELETE_CP_ESTABLISHMENT_INFO_ADDRESS]"-->
            <div @stagger
                 class='px-gutter py-4'
                 gdGap='24px'>
                <base-data-table
                    [count]='countAddress'
                    [pageSize]='pageSizeAddress'
                    [sortField]='sortFieldAddress'
                    [sortOrder]='sortOrderAddress'
                    [dataSource]='dataSourceAddress'
                    [displayedColumns]='displayedColumnsAddress'
                    [feisColumns]='feisColumnsAddress'
                    [updatePermission]="[]"
                    [deletePermission]="[]"
                    (onPageEvent)='pageEventAddress($event)'
                    (onSortEvent)='sortEventAddress($event)'
                    (onUpdateItem)='updateAddress($event)'
                    (onDeleteItem)='deleteAddress($event)'
                ></base-data-table>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="px-gutter py-4" gdColumns="1fr 1fr" gdColumns.xs="1fr" gdGap="16px">
        <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
            <vex-skeleton class='w-14 mr-2' heigthTailwind='h-12' [circle]='true'></vex-skeleton>
            <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
        </div>

        <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
            <vex-skeleton class='w-14 mr-2' heigthTailwind='h-12' [circle]='true'></vex-skeleton>
            <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
        </div>

        <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
            <vex-skeleton class='w-14 mr-2' heigthTailwind='h-12' [circle]='true'></vex-skeleton>
            <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
        </div>
    </div>
</ng-template>
