import { Injectable } from '@angular/core';
import { ContactTypeModel } from 'src/app/core/models/contact-type.model';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/core/base/base.service';

@Injectable({
    providedIn: 'root'
})
export class ContactTypeService extends BaseService<ContactTypeModel>{
    defaultSort = 'createdAt';

    constructor(public http: HttpClient) {
        super(http, 'contact-type');
    }
}


