<!-- list-card.component.html -->
<div class='card'>
  <div class='px-gutter py-4 border-b' fxLayout='row' fxLayoutAlign='space-between center'>
    <h2 class='title m-0'>{{ title }}</h2>
    <button *ngIf="showRefresh"
            mat-icon-button
            class="text-primary hover:text-gray-800"
            (click)="onRefresh()">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
  <div class='px-6 py-4 flex flex-col gap-6'>
    <div class="scrollable-container">
      <div *ngFor="let item of items; let i = index"
           class="bg-white mb-4 p-2 border-b">
        <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item, index: i }">
        </ng-container>
      </div>
    </div>
  </div>
</div>
