import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vex-supplier-details-other',
  templateUrl: './supplier-details-other.component.html',
  styleUrls: ['./supplier-details-other.component.scss']
})
export class SupplierDetailsOtherComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
