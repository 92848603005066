import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
    GenericMetrikStatusService
} from '../../../../../settings/generic-metrik-status/generic-metrik-status.service';
import {
    FormModalBaseComponent
} from '../../../../../../../core/base/components/form-modal-base/form-modal-base.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BuildingService } from '../../../../building/building.service';
import { EquipementMetrikStatusModel } from '../../../../../../../core/models/equipement-metrik-status.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MetrikStatusModuleEnum } from '../../../../../../../core/enums/metrik-status.enum';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseTypeEnum } from '../../../../../../../core/base/enum/response-type.enum';
import { CrudModeEnum } from '../../../../../../../core/base/enum/crud-mode.enum';
import { EquipmentService } from '../../../equipment.service';
import { EquipmentModel } from '../../../../../../../core/models/equipment.model';
import { BasketTypeModel } from '../../../../../../../core/models/basket-type.model';
import {
    FormModalConfirmBaseComponent
} from '../../../../../../../core/base/components/form-modal-confirme-base/form-modal-confirm-base.component';
import { SnackbarService } from '../../../../../../../core/services/snackbar.service';


@Component({
    selector: 'vex-equipment-metrik-status',
    templateUrl: './equipment-metrik-status.component.html',
    styleUrls: ['./equipment-metrik-status.component.scss',
        '../../../../../../../../../node_modules/quill/dist/quill.snow.css',
        '../../../../../../../../@vex/styles/partials/plugins/_quill.scss'
    ],
    encapsulation: ViewEncapsulation.None,
})
export class EquipmentMetrikStatusComponent extends FormModalBaseComponent<EquipmentModel> implements OnInit {

    readonly MetrikStatusModuleEnum = MetrikStatusModuleEnum;
    equipmentMetrikStatus$: Observable<EquipementMetrikStatusModel[]>;
    metrik: EquipementMetrikStatusModel;

    customModule = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ align: [] }],
            ['clean'],
            ['link']
        ]
    };

    constructor(
        public readonly genericMetrikStatusService: GenericMetrikStatusService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<FormModalBaseComponent<EquipmentModel>>,
        public dialog: MatDialog,
        public service: EquipmentService,
        public buildingService: BuildingService,
        public snackbarService: SnackbarService,
    ) {
        super(data, dialogRef, service);
    }

    ngOnInit(): void {
        this.defaults = this.data?.equipment;
        this.metrik = this.data?.metrik;
        this.getEquipmentMetrikStatus();
        this.initForm();
    }

    initForm() {
        if (this.metrik) {
            this.form = new FormGroup({
                _id: new FormControl(this.metrik?._id),
                genericMetrikStatus: new FormControl(this.metrik?.genericMetrikStatusId, [Validators.required]),
                startDate: new FormControl(this.metrik?.startDate, [Validators.required]),
                endDate: new FormControl(this.metrik?.endDate),
                comment: new FormControl(this.metrik?.comment, [Validators.required]),
            });
        } else {
            this.form = new FormGroup({
                _id: new FormControl(this.defaults?._id),
                genericMetrikStatus: new FormControl(null, [Validators.required]),
                startDate: new FormControl(null, [Validators.required]),
                endDate: new FormControl(null),
                comment: new FormControl(null, [Validators.required]),
            });
        }
    }

    getEquipmentMetrikStatus() {
        this.equipmentMetrikStatus$ = this.service.findAll(null, null, 'startDate', 'desc').pipe(
            map((x: any) => x.data)
        );
    }

    createItem(): void {
        this.loading = true;
        const { genericMetrikStatus, ...rest } = this.form.getRawValue();
        const equipmentMetrik: EquipementMetrikStatusModel = {
            genericMetrikStatusId: genericMetrikStatus._id,
            ...rest
        };
        this.beforeCreateItem();
        if (this.form.valid) {
            const sub = this.service.createMetrik(this.defaults._id, equipmentMetrik).subscribe(
                result => {
                    this.close(result.data);
                    this.snackbarService.success('L\'état de l\'équipement a été mis à jour avec succès.');
                    this.afterCreateItem(result, null);
                }, error => {
                    this.afterCreateItem(null, error);
                    this.setErrorsMessages(error.error.errors);
                    this.loading = false;
                }
            );
            this.subscription.add(sub);
        } else {
            this.onFormInvalid();
            this.logFormErrors(this.form);
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    setEndServiceDate(): void {
        // 🔹 **Affichage de la modale de confirmation avant d'exécuter la requête**
        this.dialog.open(FormModalConfirmBaseComponent, {
            data: {
                title: 'Confirmation',
                message: `Voulez-vous vraiment rendre cet équipement définitivement inactif ? Cette action est irréversible.`,
            }
        }).afterClosed().subscribe((confirmed: boolean) => {
            if (!confirmed) { return; }
            this.processEndServiceDate();
        });
    }

    /**
     * Exécute la requête de mise à jour après confirmation.
     */
    private processEndServiceDate(): void {
        // 🔹 **Supprimer le validateur et mettre à jour la validation**
        this.form.controls.genericMetrikStatus.clearValidators();
        this.form.controls.genericMetrikStatus.updateValueAndValidity();

        // 🔹 **Ajouter les validations requises sur d'autres champs**
        this.form.controls.startDate.setValidators([Validators.required]);
        this.form.controls.comment.setValidators([Validators.required]);

        if (this.form.valid) {
            this.loading = true;

            const sub = this.service.setEndServiceDate(this.defaults._id, this.form.getRawValue()).subscribe(
                result => {
                    this.close(result.data);
                    this.snackbarService.success('L\'état de l\'équipement a été mis à jour avec succès.');
                    this.afterCreateItem(result, null);
                },
                error => {
                    this.afterCreateItem(null, error);
                    this.setErrorsMessages(error.error.errors);
                    this.loading = false;
                }
            );

            this.subscription.add(sub);
        } else {
            this.logFormErrors(this.form);
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }
}
