<ng-container *ngIf="isCreateMode() || isUpdateMode()">
    <form (ngSubmit)="save()" [formGroup]="form">
        <base-form-header [mode]='mode' createLabel='Ajouter un établissement'
                          [updateLabel]='form.get("label").value'></base-form-header>

        <mat-dialog-content fxLayout="column">

            <base-form-input
                    label='Libellé'
                    controlName='label'>
            </base-form-input>

            <!--             <base-form-input-->
            <!--                label='headquarterAddress'-->
            <!--                controlName='headquarterAddress'>-->
            <!--            </base-form-input>-->


            <!--            <base-form-input-->
            <!--                label='CPOM'-->
            <!--                type='autocomplete'-->
            <!--                [items$]='cpom$'-->
            <!--                (autocompleteChange)='cpomAutoCompleteChange($event)'-->
            <!--                displayProperty='label'-->
            <!--                controlName='cpom'>-->
            <!--            </base-form-input>-->

            <base-form-input
                    label='N° TVA'
                    controlName='tva'>
            </base-form-input>

            <base-form-input
                    *ngIf='isFrench'
                    label='Code APE'
                    controlName='ape'>
            </base-form-input>

            <base-form-input
                    *ngIf='isFrench'
                    label='N° Siren'
                    type='number'
                    controlName='sirenNumber'>
            </base-form-input>

            <base-form-input
                    *ngIf='isFrench'
                    label='N° Siret'
                    type='number'
                    controlName='siretNumber'>
            </base-form-input>

            <base-form-input
                    *ngIf='!isFrench'
                    label='Identifiant entreprise'
                    controlName='dunsNumber'>
            </base-form-input>

            <base-form-input
                    label='Statut TVA'
                    [items]='statusTva'
                    type='select'
                    controlName='vatStatus'>
            </base-form-input>

            <base-form-input
                    label='Comptable de rattachement'
                    [items$]='userProfiles$'
                    (autocompleteChange)='customerReferentAutocompleteChange($event)'
                    type='autocomplete'
                    controlName='attachedAccountant'
                    displayProperty='fullName'
            >
            </base-form-input>

            <base-form-input
                    *ngIf='isFrench'
                    label='Dossier comptable'
                    controlName='accountingFile'>
            </base-form-input>


            <p class='mb-3'>Adresse</p>
            <mat-divider class="mb-6"></mat-divider>
            <ng-container [formGroup]='addressForm'>
                <div class="flex flex-col sm:flex-row">
                    <base-form-input
                            class='sm:mr-3'
                            label='Adresse'
                            controlName='street'>
                    </base-form-input>
                    <base-form-input
                            class='sm:ml-3'
                            label="Complément d'adresse"
                            controlName='additionalAddress'>
                    </base-form-input>
                </div>

                <div class="flex flex-col sm:flex-row">
                    <base-form-input
                            class='sm:mr-3'
                            label='Code postal'
                            controlName='postalCode'>
                    </base-form-input>
                    <base-form-input
                            class='sm:ml-3'
                            label='Ville'
                            controlName='city'>
                    </base-form-input>
                </div>
                <base-form-input
                        label='Pays'
                        controlName='country'
                        type='autocomplete'
                        [items]='countries'
                >
                </base-form-input>
            </ng-container>
            <mat-form-field class="flex-auto w-full">
                <mat-label>Capacité budgétaire</mat-label>
                <mat-select formControlName="validatorMatriceLevel">
                    <mat-option *ngFor='let level of levels' [value]="level">{{ levelTranslation(level) }}</mat-option>
                </mat-select>
                <mat-error *ngIf="form.controls['validatorMatriceLevel'].errors?.required">Le champ Niveau de validation
                    est obligatoire
                </mat-error>
            </mat-form-field>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <base-form-input
                    type='submit'
                    [mode]='mode'
                    [loading]='loading'
                    entityLabel='ESTABLISHMENT'
                    (onCreate)='createItem()'
                    (onUpdate)='updateItem()'
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>

<ng-container *ngIf="isDeleteMode()">
    <form-modal-delete-base
            [label]='data.name'
            (onClose)='close()'
            [loading]='loading'
            (onDelete)='deleteItem()'
    >
    </form-modal-delete-base>
</ng-container>


<ng-container *ngIf="isImportMode()">
    <vex-form-import-base
            [modalTitle]='"Importer des établissements"'
            [entityLabel]='"ESTABLISHMENT"'
            [form]='importForm'
            [service]='service'
            [mode]='mode'
            [loading]='loading'
            (saveForm)='save()'
            (importItems)='importItems()'
            [downloadModel]="canDownloadModelPermissions"
            [canDownloadTemplateModel]="true"
    ></vex-form-import-base>
</ng-container>

<ng-container *ngIf='isArchiveMode()'>
    <form-modal-delete-base
            (onClose)='close()'
            (onDelete)='archiveItem()'
            title="Archiver l'établissement"
            defaultMessage='Êtes-vous sûr de vouloir archiver cet établissement ?'
    >
    </form-modal-delete-base>
</ng-container>
