import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {SnackbarService} from "../services/snackbar.service";

@Injectable({
    providedIn: 'root'
})
export class SnackbarMessageInterceptor implements HttpInterceptor {

    constructor(private snackbarService: SnackbarService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(tap((event) => {
                // if(event instanceof HttpResponse) {
                //     let data : any = event?.body;
                //     if(data.message !== undefined && data.message !== null && data.message !== ''){
                //         // this.snackbar.open(data.message.description, 'CLOSE', {
                //         //     duration: 5000,
                //         //     horizontalPosition: 'center',
                //         //     panelClass: data.message.level
                //         // });
                //     }
                // }
            }, (error) => {
                console.log('error', error)
                if (error instanceof HttpErrorResponse) {
                    let data: any = error?.error;
                    if (data?.message && [5995, 409].includes(data?.statusCode)) {
                        this.snackbarService.danger(data.message);
                    }
                }
            }));
    }
}
