<div
        @fadeInRight
        [ngClass]="getBorderClasses()"
        class="card border-l-4 p-4 mt-4"
>
    <div class="flex flex-row w-full justify-between">
        <p class="font-bold">
            {{ text }}
        </p>
        <div class="flex flex-row gap-3" *ngIf="showButton">
            <button
                    (click)="callButton()"
                    class="w-full"
                    matSuffix
                    mat-raised-button
                    [color]="colorButton"
            >
                {{ textButton }}
            </button>
        </div>
    </div>
</div>
