import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition
} from '@angular/material/snack-bar/snack-bar-config';
import {
    TicketEquipmentCreateUpdateDeleteComponent
} from '../../pages/application/administrative/ticket/modals/equipment/ticket-equipment-create-update-delete/ticket-equipment-create-update-delete.component';

@Injectable({
    providedIn: 'root'
})
export class SnackbarService {

    _duration = 5000;

    constructor(public snackBar: MatSnackBar) {
    }

    success(message: string) {
        this.snackBar.open(message, '', {
            duration: 5000,
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            panelClass: ['snackbar-success']
        });
    }

    warning(message: string, duration: number = 5000) {
        this.snackBar.open(message, '', {
            duration: duration,
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            panelClass: ['snackbar-warning'],
        });
    }

    danger(message: string) {
        this.snackBar.open(message, '', {
            duration: 5000,
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            panelClass: ['snackbar-danger']
        });
    }

    copyToClipboard(message: string) {
        this.snackBar.open(message + ' copié !', '', {
            duration: 500,
            horizontalPosition: 'center',
            panelClass: ['snackbar-white']
        });
    }
}


