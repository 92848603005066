import {Injectable} from '@angular/core';
import {UserProfileModel} from 'src/app/core/models/user-profile.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BaseService} from 'src/app/core/base/base.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {ObjectApiInterface} from '../../../../core/base/interfaces/object-api.interface';
import {AffectationModel} from '../../../../core/models/affectation.model';
import {map} from 'rxjs/operators';
import {EmployeePositionModel} from '../../../../core/models/employee-position.model';
import {
    UserProfileValidatorLevel,
    UserProfileValidatorSeuil
} from '../../../../core/enums/user-profile-validator-seuil.enum';
import {CategoryModel} from '../../../../core/models/category.model';
import {RoleModel, UserProfileRoleModel} from '../../../../core/models/role.model';
import {PrestationModel} from "../../../../core/models/prestation.model";

@Injectable({
    providedIn: 'root'
})
export class UserProfileService extends BaseService<UserProfileModel> {
    defaultSort = 'createdAt';

    protected _validators = new BehaviorSubject<CategoryModel[]>([] as CategoryModel[]);
    readonly validators$ = this._validators.asObservable();


    constructor(public http: HttpClient) {
        super(http, 'userProfile');
    }

    deletingValidatorsTechnicalCategories() {
        return  this.http.delete<ObjectApiInterface<UserProfileModel>>(`${this.baseUrlApi}/deleteEmptyValidatorsTechnicalCategories`);
    }

    findAllForAffectation(page?: number,
                          perPage?: number,
                          sortField: string = this.defaultSort,
                          sortOrder: string = 'desc',
                          search?: string,
                          filters?: UserProfileModel | any): Observable<ObjectApiInterface<UserProfileModel[]>>
    {
        return this.http.get<ObjectApiInterface<UserProfileModel[]>>(`${this.baseUrlApi}/affectation/list`, {
                params: new HttpParams()
                    .set('page', page?.toString() ?? '1')
                    .set('perPage', perPage?.toString() ?? '30')
                    .set('sort', `${sortField}@${sortOrder}`)
                    .set('search', search ?? '')
                    .set('filters', JSON.stringify(filters) ?? '')
            }
        );
    }

    findUserAffectation()
    {
        return this.http.get<ObjectApiInterface<any[]>>(`${this.baseUrlApi}/user/affectation/list`);
    }

    getResume(id: string): Observable<ObjectApiInterface<UserProfileModel>> {
        return this.http.get<ObjectApiInterface<UserProfileModel>>(`${this.baseUrlApi}/resume/${id}`);
    }

    getEmployeePositionRelation(_id): Observable<ObjectApiInterface<UserProfileModel[]>> {
        return this.http.get<ObjectApiInterface<UserProfileModel[]>>(`${this.baseUrlApi}/getEmployeePositionRelation/${_id}`);
    }

    getContactTypeRelation(_id): Observable<ObjectApiInterface<UserProfileModel[]>> {
        return this.http.get<ObjectApiInterface<UserProfileModel[]>>(`${this.baseUrlApi}/getContactTypeRelation/${_id}`);
    }

    getGedCredential() {
        return this.http.get<ObjectApiInterface<any>>(`${this.baseUrlApi}/get/gedCredential`);
    }

    addAffectation(id: string, affectation: AffectationModel) {

        // remove useless data in affectations.establishment
        if (affectation.establishment) {
            affectation.establishment = {
                _id: affectation.establishment._id,
                label: affectation.establishment.label,
                technicalIdentifier: affectation.establishment.technicalIdentifier,
            };
        }


        if (affectation.responsable) {
            affectation.responsable = {
                _id: affectation.responsable._id,
                firstName: affectation.responsable.firstName,
                lastName: affectation.responsable.lastName,
                fullName: affectation.responsable.fullName,
                mcEmail: affectation.responsable.mcEmail,
            };

        }

        return this.http.post<ObjectApiInterface<UserProfileModel>>(`${this.baseUrlApi}/${id}/addAffectation`, affectation)
            .pipe(map(x => {
                this._entity.next(x.data);
            }));
    }

    updateAffectation(id: string, affectation: AffectationModel) {
        return this.http.put<ObjectApiInterface<AffectationModel>>(`${this.baseUrlApi}/${id}/updateAffectation`, affectation)
            .pipe(map(x => {
                const index = this._entity.value.affectations.findIndex(y => y._id === x.data._id);
                this._entity.value.affectations[index] = x.data;
                return x;
            }));
    }

    deleteAffectation(id: string, affectationId: string) {
        return this.http.delete<ObjectApiInterface<UserProfileModel>>(`${this.baseUrlApi}/${id}/deleteAffectation/${affectationId}`)
            .pipe(map((x: ObjectApiInterface<UserProfileModel>) => {
                this._entity.next(x.data);
                return x;
            }));
    }

    addEmployeePosition(id: string, employeePosition: EmployeePositionModel) {
        return this.http.post<ObjectApiInterface<EmployeePositionModel>>(`${this.baseUrlApi}/${id}/addEmployeePosition`, employeePosition)
            .pipe(map(x => {
                this._entity.value.employeePositions.push(x.data);
                return x;
            }));
    }

    updateEmployeePosition(id: string, _oldId: string, employeePosition: EmployeePositionModel) {
        return this.http
            .put<ObjectApiInterface<EmployeePositionModel>>(`${this.baseUrlApi}/${id}/updateEmployeePosition/${_oldId}`, employeePosition)
            .pipe(map(x => {
                this._entity.value.employeePositions = this._entity.value.employeePositions.filter(y => y._id !== _oldId);
                this._entity.value.employeePositions.push(employeePosition);
                return x;
            }));
    }

    deleteEmployeePosition(id: string, employeePositionId: string) {
        return this.http.delete<ObjectApiInterface<EmployeePositionModel>>(`${this.baseUrlApi}/${id}/deleteEmployeePosition/${employeePositionId}`)
            .pipe(map(x => {
                this._entity.value.employeePositions = this._entity.value.employeePositions.filter(y => y._id !== x.data._id);
                return x;
            }));
    }

    getValidator(id: string, matriceLevel: UserProfileValidatorLevel) {
        return this.http.get<ObjectApiInterface<CategoryModel[]>>(`${this.baseUrlApi}/${id}/getValidator?matriceLevel=${matriceLevel}`)
            .pipe(map(x => {

                this._validators.next(x.data);
                return x;
            }));
    }

    setValidator(id: string, categoryId: string, seuil: UserProfileValidatorSeuil, matriceLevel: UserProfileValidatorLevel) {
        return this.http.post<ObjectApiInterface<CategoryModel>>(`${this.baseUrlApi}/${id}/setValidator`, {
            seuil,
            category: categoryId,
            matriceLevel
        })
            .pipe(map(x => {
                const exist = this._validators.value.find(y => y._id === x.data.category);

                if (exist) {
                    this._validators.value.map(y => {
                        if (y._id === x.data.category) {

                            y.seuil = x.data.seuil;

                            // map same seuil to all category.children
                            y.children.map(z => {
                                z.seuil = x.data.seuil;
                            });

                        }
                        return y;
                    });
                } else {
                    this._validators.value.push(x.data);
                }
                return x;
            }));
    }


    setAllValidator(id: string, seuil: UserProfileValidatorSeuil, matriceLevel: UserProfileValidatorLevel) {
        return this.http.post<ObjectApiInterface<CategoryModel[]>>(`${this.baseUrlApi}/${id}/setAllValidator/${seuil}/${matriceLevel}`, {})
            .pipe(map(x => {

                this._validators.value.map(y => {

                    y.seuil = seuil;

                    // map same seuil to all category.children
                    y.children.map(z => {
                        z.seuil = seuil;
                    });

                    return y;
                });
                // this._validators.next(x.data);
                return x;
            }));
    }

    setCredentialGed(id, credential) {
        return this.http
            .put<ObjectApiInterface<any>>(`${this.baseUrlApi}/setCredentialGed/${id}`, credential)
            .pipe(map(x => {
            this._entity.value.gedCredential = credential;
            return x;
        }));
    }


    updateUserProfileRoles(id: string, data: Array<RoleModel>): Observable<any> {
        return this.http.put<ObjectApiInterface<UserProfileModel>>(`${this.baseUrlApi}/${id}/update-user-profile-roles`, data)
            .pipe(map((x: any) => {
                // this._entity.value.employeePositions = this._entity.value.employeePositions.ticket-filter(y => y._id !== _oldId);
                // this._entity.value.employeePositions.push(employeePosition);
                this._entity.next(x.data);
                return x;
            }));
    }


    assignCategories(id: string, data: CategoryModel, toogleValue: boolean): Observable<any> {
        return this.http.post<ObjectApiInterface<UserProfileModel>>(`${this.baseUrlApi}/${id}/assign-categories/${toogleValue}`, data)
            .pipe(map(x => {

                this._entity.next(x.data);

                return x;
            }));
    }

    getEstablishmentRelation(_id: string) {
        return this.http
            .get<ObjectApiInterface<UserProfileModel>>(`${this.baseUrlApi}/${_id}/getEstablishmentRelation`)
            .pipe(map(x => x.data));
    }

    exportUserProfiles(): Observable<ObjectApiInterface<any>> {
        return this.http.get<ObjectApiInterface<any>>(`${this.baseUrlApi}/export-user-profiles`);
    }

    downloadUsersTestTemplate(): Observable<ObjectApiInterface<UserProfileModel>>{
        return this.http.get<ObjectApiInterface<UserProfileModel>>(`${this.baseUrlApi}/downloadUsersTestTemplate/`);
    }

    importUsersTest(data: any): Observable<ObjectApiInterface<UserProfileModel>> {
        return this.http.post<ObjectApiInterface<UserProfileModel>>(`${this.baseUrlApi}/importUsersTest`, data, {
            headers: {'Error-CRUD': 'IMPORT'}
        });
    }
}


