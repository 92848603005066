<ng-container *ngIf="isCreateMode() || isUpdateMode()">
    <form (ngSubmit)="save()" [formGroup]="form">
        <base-form-header [mode]='mode' createLabel='Ajouter une activité' [updateLabel]='form.get("activity").value?.label'></base-form-header>

        <mat-dialog-content fxLayout="column">

            <base-form-input
                label='Bâtiment'
                type='autocomplete'
                controlName='building'
                [items]='buildings'
                displayProperty='label'
            >
            </base-form-input>

            <base-form-input
                label='Activité'
                type='autocomplete'
                controlName='activity'
                [items]='activity'
                displayProperty='label'
            >
            </base-form-input>

            <base-form-input
                label='Pourcentage'
                type='number'
                controlName='percent'>
            </base-form-input>

        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <base-form-input
                type='submit'
                [mode]='mode'
                entityLabel='ACTIVITY'
                (onCreate)='createItem()'
                (onUpdate)='updateItem()'
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>

<ng-container *ngIf="isDeleteMode()">
    <form-modal-delete-base
        [label]='data.name'
        [loading]='loading'
        (onClose)='close()'
        (onDelete)='deleteItem()'
    >
    </form-modal-delete-base>
</ng-container>
