export enum GedTypeEnum {
    QUOTE = 'QUOTE',
    ORDER = 'ORDER',
    SUPPLIER = 'SUPPLIER',
    CONTRACT = 'CONTRACT',
    SECURITY_COMMISSION = 'SECURITY_COMMISSION',
    EQUIPMENT = 'EQUIPMENT',
    BUILDING = 'BUILDING',
    RECEPTION = 'RECEPTION',
    LITIGATION = 'LITIGATION',
    INTERVENTION = 'INTERVENTION',
    BILL = 'BILL',
    TICKET_EQUIPMENT = 'TICKET_EQUIPMENT',
}

export class GedConfig {
    gedType: GedTypeEnum;
    apiUrl: string;
}

export const GED_CONFIG: { [key: string]: GedConfig } =
    {
        quote: {
            gedType: GedTypeEnum.QUOTE,
            apiUrl: '/api/quote/ged'
        },
        order: {
            gedType: GedTypeEnum.ORDER,
            apiUrl: '/api/order/ged'
        },
        supplier: {
            gedType: GedTypeEnum.SUPPLIER,
            apiUrl: '/api/supplier/ged'
        },
        contract: {
            gedType: GedTypeEnum.CONTRACT,
            apiUrl: '/api/contract/ged'
        },
        securityCommission: {
            gedType: GedTypeEnum.SECURITY_COMMISSION,
            apiUrl: '/api/security-commission/ged'
        },
        equipment: {
            gedType: GedTypeEnum.EQUIPMENT,
            apiUrl: '/api/equipment/ged'
        },
        building: {
            gedType: GedTypeEnum.BUILDING,
            apiUrl: '/api/building/ged'
        },
        bill: {
            gedType: GedTypeEnum.BILL,
            apiUrl: '/api/bill/ged'
        },
        reception: {
            gedType: GedTypeEnum.RECEPTION,
            apiUrl: '/api/reception/ged'
        },
        litigation: {
            gedType: GedTypeEnum.LITIGATION,
            apiUrl: '/api/litigation/ged'
        },
        intervention: {
            gedType: GedTypeEnum.INTERVENTION,
            apiUrl: '/api/intervention/ged'
        },
        ticket: {
            gedType: GedTypeEnum.TICKET_EQUIPMENT,
            apiUrl: '/api/ticket/ged'
        }
    };


