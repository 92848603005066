<div class="timeline m-3">
    <div *ngFor="let event of events; let i = index" class="timeline-item" fxLayout="row" fxLayoutAlign="start center">

        <div class="timeline-marker" fxLayout="column" fxLayoutAlign="center center">
            <div [class]="event?.active ? 'blue-dot' : 'black-dot'"></div>
            <div class="line" *ngIf="i < events.length - 1"></div>
        </div>

        <div fxLayout="column">

            <details-line-base
                class="timeline-date"
                [label]='event.date'
            >
            </details-line-base>

            <div class="timeline-content">
                <p><strong>{{ event.titre }}</strong></p>
               <quill-editor
                  class="h-200"
                  [modules]="quillConfig"
                  [ngModel]="event.description"
                  >
               </quill-editor>
            </div>
        </div>
    </div>
</div>
