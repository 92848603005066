import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'button-filter',
    templateUrl: './button-filter.component.html',
    styleUrls: ['./button-filter.component.scss']
})
export class ButtonFilterComponent {

    @Input() nbFilters = 0;

    @Input() label = 'Filtres avancés';

    @Output() clickButton = new EventEmitter<void>();

    constructor() { }

    onClick() {
        this.clickButton.emit();
    }
}
