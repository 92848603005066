<ng-container *ngIf="isImportMode()">
    <vex-form-import-base
            [modalTitle]='"Importer des utilisateurs test"'
            [entityLabel]='"Utilisateurs test"'
            [form]='importForm'
            [loading]='loading'
            [service]='service'
            [mode]='mode'
            (saveForm)='save()'
            (importItems)='importItems()'
            customExport='true'
            (customExportItems)="downloadUsersTestTemplate()"
            [canDownloadTemplateModel]='true'
    ></vex-form-import-base>
    <p *ngIf="loadingMessage" style="color: red; font-weight: bold;">{{ loadingMessage }}</p>
</ng-container>

