<div fxLayout="row" fxLayoutGap="20px" *ngIf="!errorMessage" class="mt-4">
    <div [fxFlex]="canAddDoc ? 75 : 100" class="card ged-documents-card">
<!--        <div class='px-gutter py-4 border-b' fxLayout='row' fxLayoutAlign='space-between center'>-->
<!--            <h2 class='title m-0'>Liste des documents</h2>-->
<!--        </div>-->
        <base-data-table-header
                [backgroundColorWhite]="true"
                [canCreate]="false"
                label='{{listTitle}}'
                [loading]='loading'
                [showSearch]="false"
        ></base-data-table-header>
        <div *ngIf="!loading">
            <ng-content></ng-content>
        </div>
    </div>
    <div [fxFlex]="canAddDoc ? 25 : 25" class="card ged-add-documents-card" *ngIf="canAddDoc">
        <div class='px-gutter py-4 border-b' fxLayout='row' fxLayoutAlign='space-between center'>
            <h2 class='title m-0'>{{addDocumentTitle}}</h2>
        </div>
        <div class="p-4" *ngIf="!loading">
            <ng-content select="[dragFormZone]"></ng-content>
        </div>
    </div>
</div>
<div *ngIf="errorMessage" class="card p-4 mt-6" fxLayout="column" fxLayoutAlign="center center">
    <span class="·text-red text-lg">{{errorMessage}}</span>
    <img src="assets/img/illustrations/it_support.svg" style="height: 168px; width: 152px">
</div>
