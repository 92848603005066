import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SupplierContactModel} from '../../../../../../core/models/supplier-contact.model';
import {CrudModeEnum} from '../../../../../../core/base/enum/crud-mode.enum';
import {Observable, Subscription} from 'rxjs';
import {ContactTypeModel} from '../../../../../../core/models/contact-type.model';
import icClose from '@iconify/icons-ic/twotone-close';
import {ContactTypeService} from '../../../../settings/contact-type/contact-type.service';
import {SupplierService} from '../../supplier.service';
import {ActivatedRoute, Router} from '@angular/router';
import {setErrorsMessages} from '../../../../../../core/helpers/form.error.helper';
import {ErrorApi} from '../../../../../../core/models/api/error-api';
import {SupplierContactService} from '../../supplier-contact.service';
import {BaseContactType} from '../../../../../../core/enums/contact-type.enum';
import {GenderWithEntity} from '../../../../../../core/enums/gender-with-entity.enum';
import {Typology} from '../../../../../../core/enums/typology.enum';
import {SnackbarService} from '../../../../../../core/services/snackbar.service';
import {ResponseTypeEnum} from '../../../../../../core/base/enum/response-type.enum';
import {FormModalBaseComponent} from '../../../../../../core/base/components/form-modal-base/form-modal-base.component';
import {EstablishmentModel} from '../../../../../../core/models/establishment.model';
import {map} from 'rxjs/operators';
import {EstablishmentService} from '../../../establishment/establishment.service';
import {UserProfileService} from '../../../user-profile/user-profile.service';
import {UserProfileModel} from '../../../../../../core/models/user-profile.model';
import {AffectationModel} from '../../../../../../core/models/affectation.model';

@Component({
    selector: 'vex-supplier-contact-create-update',
    templateUrl: './supplier-contact-create-update-delete.component.html',
    styleUrls: ['./supplier-contact-create-update-delete.component.scss']
})
export class SupplierContactCreateUpdateDeleteComponent extends FormModalBaseComponent<SupplierContactModel> implements OnInit, OnDestroy {

    protected subscription = new Subscription();

    icClose = icClose;

    form: FormGroup;
    defaults: SupplierContactModel;
    isQuote = false;
    genderItem: string[];
    contactTypes: ContactTypeModel[] = [];
    supplierId: string;

    importDefaults: any;
    canCreateMode = false;
    canUpdateMode = false;
    canDeleteMode = false;
    canReadMode = false;
    canImportMode = false;

    successCreateMessage: string;
    successUpdateMessage: string;
    successDeleteMessage: string;
    errorCreateMessage: string;
    errorUpdateMessage: string;
    errorDeleteMessage: string;

    establishments$: Observable<any[]>;  // Liste complète des établissements
    responsableFilters: any = {};
    selectedAffectations: AffectationModel[] = [];
    filteredEstablishments: { [key: number]: EstablishmentModel[] } = {};
    filteredResponsables: { [key: number]: UserProfileModel[] } = {};
    isCheckedBoxSelectAllEstablishments: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public dialogRef: MatDialogRef<FormModalBaseComponent<SupplierContactModel>>,
                private contactTypeService: ContactTypeService,
                private supplierService: SupplierService,
                public activatedRoute: ActivatedRoute,
                private router: Router,
                public snackbarService: SnackbarService,
                public service: SupplierContactService,
                private establishmentService: EstablishmentService,
                public userProfileService: UserProfileService,
                private fb: FormBuilder
    ) {
        super(data, dialogRef, service);
        if (this.data) {
            this.defaults = this.data?.defaults ?? {} as SupplierContactModel;
            this.mode = this.data.mode;
            this.isQuote = this.data.isQuote ?? false;
        }
    }

    ngOnInit() {
        super.ngOnInit();

        // if (this.data?.defaults?.affectations && this.data.defaults.affectations.length > 0) {
        //     this.selectedAffectations = this.data.defaults.affectations.map(affectation => ({
        //         establishment: affectation.establishment ? {...affectation.establishment} : {_id: '', label: ''},
        //         responsable: affectation.responsable ? {...affectation.responsable} : {_id: '', fullName: ''}
        //     }));
        // } else {
        //     this.selectedAffectations = [];
        // }

        if (this.data?.defaults?.affectations && this.data.defaults.affectations.length > 0) {
            this.selectedAffectations = this.data.defaults.affectations.map(affectation => ({
                establishment: affectation.establishment ? {...affectation.establishment} : {_id: '', label: ''},
                responsable: affectation.responsable ? {...affectation.responsable} : {_id: '', fullName: ''}
            }));

            // Charger immédiatement les responsables pour chaque établissement sélectionné
            this.selectedAffectations.forEach((affectation, index) => {
                if (affectation.establishment._id) {
                    //    this.loadResponsablesForEstablishment(affectation.establishment._id, index);
                }
            });
        }

        // Charger la liste des établissements dès l'initialisation
        this.establishmentService.findAllAsSelect(null, null, 'asc', '', null)
            .pipe(map(x => x.data))
            .subscribe(establishments => {
                this.selectedAffectations.forEach((_, index) => {
                    this.filteredEstablishments[index] = establishments;
                });
            });

        this.canCreateMode = this.isCreateMode();
        this.canUpdateMode = this.isUpdateMode();
        this.canDeleteMode = this.isDeleteMode();
        this.canReadMode = this.isReadMode();
        this.canImportMode = this.isImportMode();

        // this.contactType$ = this.contactTypeService.findAll().pipe(map(d => [...d.data, ...BaseContactType]));
        this.genderItem = Object.values(GenderWithEntity);
        this.supplierId = this.data.id;
        this.contactTypeService.findAll().subscribe(x => {

            this.contactTypes = [...x.data, ...BaseContactType].filter(x => x.typology === Typology.supplier);

            if (this.isUpdateMode()) {
                const a = [];
                this.isCheckedBoxSelectAllEstablishments = this.defaults?.isCheckedBoxSelectAllEstablishments;
                this.defaults.contactTypes.forEach(x => {
                    const ct = this.contactTypes.findIndex(y => y._id === x._id);
                    if (ct !== -1) {
                        a.push(this.contactTypes[ct]);
                    }
                });
                this.form.controls.contactTypes.setValue(a);


            }

            if (this.isQuote) {
                const a = this.contactTypes.filter(x => x.label === 'Devis');
                this.form.controls.contactTypes.setValue(a);
            }
        });

        this.initForm();

        if (this.isCreateMode()) {
            this.form.controls.isCheckedBoxSelectAllEstablishments.setValue(true);
            this.selectAllEstablishments(true);
        }

        this.form.controls.establishment.valueChanges.subscribe(value => {
            this.responsableFilters.establishment = value._id;
        });
    }

    initForm() {
        this.form = new FormGroup({
            _id: new FormControl(this.defaults?._id),
            firstName: new FormControl(this.defaults?.firstName, [Validators.required]),
            lastName: new FormControl(this.defaults?.lastName, [Validators.required]),
            mobileNumber: new FormControl(this.defaults?.mobileNumber, [Validators.required, Validators.maxLength(10)]),
            email: new FormControl(this.defaults?.email, [Validators.required, Validators.email]),
            contactTypes: new FormControl(this.defaults?.contactTypes, [Validators.required]),
            gender: new FormControl(this.defaults?.gender),
            affectations: new FormControl(this.defaults?.affectations),
            isCheckedBoxSelectAllEstablishments: new FormControl(this.defaults?.isCheckedBoxSelectAllEstablishments),
        });
    }


    afterCreateItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
        }
    }

    afterImportItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
            super.ngOnInit();
        }
    }

    afterUpdateItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
        }
    }

    close(data?: any): void {
        this.dialogRef.close(data);
    }

    deleteItem() {
        this.loading = true;
        const sub = this.supplierService.deleteContact(this.supplierId, this.defaults?._id).subscribe(
            result => {
                this.close(result);
                this.loading = false;
            }, error => {
                console.log(error);
                this.snackbarService.danger(error.error.message);
                this.loading = false;
                this.close(false);
            }
        );
        this.subscription.add(sub);
    }

    setErrorsMessages(errors: Array<ErrorApi>) {
        errors?.forEach(error => {
            let err = this.form.controls[error.property].errors;
            if (err === null) {
                err = {messages: []};
            }
            err.messages.push(error.message);
            this.form.controls[error.property].setErrors(err);
        });
        this.form.markAllAsTouched();
    }

    public beforeCreateItem() {
        // Delete gender from form if null so the backend can update it to null
        if (this.defaults?.gender === null) {
            delete this.defaults?.gender;
        }

        if (this.data?.isCreateInContract) {
            this.defaults.isCreateInContract = true;
        }

        if (this.selectedAffectations.length > 0) {
            this.defaults.affectations = this.selectedAffectations;
        }
    }

    createItem() {
        this.loading = true;
        this.defaults = this.form.value;
        this.beforeCreateItem();
        if (this.form.valid) {
            const sub = this.supplierService.createContact(this.supplierId, this.defaults).subscribe(
                res => {
                    this.close(res);
                    this.loading = false;
                }, err => {
                    setErrorsMessages(err?.error?.errors, this.form);
                    this.loading = false;
                });
            this.subscription.add(sub);
        } else {
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    updateItem() {
        this.loading = true;
        this.defaults = this.form.value;
        this.beforeUpdateItem();
        if (this.form.valid) {
            const sub = this.supplierService.updateContact(this.supplierId, this.defaults).subscribe(
                result => {
                    this.close(result);
                    this.loading = false;
                }, error => {
                    this.setErrorsMessages(error.error.errors);
                    this.loading = false;
                });
            this.subscription.add(sub);
        } else {
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    async importItems() {
        const supplier = await this.supplierService.entity;
        this.importDefaults = this.importForm.value;
        this.beforeImportItem();
        if (this.importForm.valid) {
            this.loading = true;
            const sub = this.supplierService.importContact(this.importDefaults, supplier._id).subscribe(
                result => {
                    this.afterImportItem(result, null);
                    this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Import, result.message);
                }, error => {
                    this.afterImportItem(null, error);
                    this.setImportErrorsMessages(error.error.errors);
                    this.loading = false;
                });
            this.subscription.add(sub);
        } else {
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    public beforeUpdateItem() {
        // Delete gender from form if null so the backend can update it to null
        if (this.defaults?.gender === null) {
            delete this.defaults?.gender;
        }

        if (this.selectedAffectations.length > 0) {
            this.defaults.affectations = this.selectedAffectations;
        } else {
            this.defaults.affectations = [];
        }
    }

    establishmentAutocompleteChange(state: string, index: number) {
        if (!state) {
            state = '';
        }

        this.establishmentService.findAllAsSelect(null, null, 'asc', state, null)
            .pipe(map(x => x.data))
            .subscribe(establishments => {
                const selectedIds = new Set(this.selectedAffectations.map(aff => aff.establishment._id));

                // Exclure les établissements déjà sélectionnés
                this.filteredEstablishments[index] = establishments.filter(est => !selectedIds.has(est._id));
            });
    }

    removeAffectation(index: number) {
        this.selectedAffectations.splice(index, 1);
    }

    // onResponsableSelect(event: any, index: number) {
    //     const selectedName = event.option.value;
    //     const selectResponsable = this.filteredResponsables[index].find(resp => resp.fullName === selectedName);
    //
    //     if (selectResponsable) {
    //         this.selectedAffectations[index].responsable = {
    //             _id: selectResponsable._id,
    //             fullName: selectResponsable.fullName,
    //             email: selectResponsable.email
    //         } as Partial<UserProfileModel>;
    //     }
    // }
    //
    // filterResponsables(search: string, index: number) {
    //     const establishmentId = this.selectedAffectations[index]?.establishment?._id;
    //
    //     if (!establishmentId) {
    //         this.filteredResponsables[index] = [];
    //         return;
    //     }
    //
    //     this.userProfileService
    //         .findAllForAffectation(null, null, null, null, search, { establishment: establishmentId })
    //         .pipe(map(x => x.data))
    //         .subscribe(responsables => {
    //             this.filteredResponsables[index] = responsables;
    //         });
    // }

    selectAllEstablishments(isChecked: boolean) {

        this.isCheckedBoxSelectAllEstablishments = isChecked;

        if (isChecked) {
            this.establishmentService.findAllAsSelect(null, null, 'asc', '', null)
                .pipe(map(x => x.data))
                .subscribe(establishments => {
                    this.selectedAffectations = [];
                    this.filteredResponsables = {};

                    this.selectedAffectations = establishments.map((est, index) => {
                        //      this.loadResponsablesForEstablishment(est._id, index);
                        return {
                            establishment: {_id: est._id, label: est.label},
                            responsable: {_id: '', fullName: ''}
                        };
                    });

                    this.updateFilteredEstablishments();
                });
        } else {
            this.filteredResponsables = {};
            this.selectedAffectations = [];
        }
    }

    addAffectation() {
        const newAffectation = {
            establishment: {_id: '', label: ''},
            responsable: {_id: '', fullName: ''}
        };

        this.selectedAffectations.push(newAffectation);

        const newIndex = this.selectedAffectations.length - 1;

        // Charger immédiatement la liste des établissements disponibles en excluant ceux déjà sélectionnés
        this.updateFilteredEstablishments();
    }


    updateFilteredEstablishments() {
        const selectedIds = new Set(this.selectedAffectations.map(aff => aff.establishment._id));

        this.establishmentService.findAllAsSelect(null, null, 'asc', '', null)
            .pipe(map(x => x.data))
            .subscribe(establishments => {
                this.selectedAffectations.forEach((_, index) => {
                    this.filteredEstablishments[index] = establishments.filter(est => !selectedIds.has(est._id));
                });
            });
    }

    onEstablishmentSelect(event: any, index: number) {
        const selectedLabel = event.option.value;
        const selectedId = event.option._element.nativeElement.getAttribute('data-id');

        // Vérifier si l'établissement est déjà sélectionné
        const isDuplicate = this.selectedAffectations.some((aff, i) => aff.establishment._id === selectedId && i !== index);

        if (isDuplicate) {
            this.snackbarService.danger('Cet établissement est déjà sélectionné.');
            return;
        }

        this.selectedAffectations[index].establishment = {_id: selectedId, label: selectedLabel};

        // Mettre à jour les établissements disponibles après la sélection
        this.updateFilteredEstablishments();

        // Charger les responsables pour cet établissement
        //   this.loadResponsablesForEstablishment(selectedId, index);
    }

    // loadResponsablesForEstablishment(establishmentId: string, index: number) {
    //     if (!establishmentId) {
    //         this.filteredResponsables[index] = []; // Réinitialiser si pas d'établissement
    //         return;
    //     }
    //
    //     this.userProfileService
    //         .findAllForAffectation(null, null, null, null, '', { establishment: establishmentId })
    //         .pipe(map(x => x.data))
    //         .subscribe(responsables => {
    //             this.filteredResponsables[index] = responsables; // Mettre à jour la liste
    //         });
    // }

    ensureResponsablesLoaded(index: number) {
        const establishmentId = this.selectedAffectations[index]?.establishment?._id;

        if (establishmentId && (!this.filteredResponsables[index] || this.filteredResponsables[index].length === 0)) {
            //   this.loadResponsablesForEstablishment(establishmentId, index);
        }
    }

    isDeleteMode() {
        return this.mode === CrudModeEnum.Delete;
    }

    isCreateMode() {
        return this.mode === CrudModeEnum.Create;
    }

    isUpdateMode() {
        return this.mode === CrudModeEnum.Update;
    }

    isReadMode() {
        return this.mode === CrudModeEnum.Read;
    }

    isImportMode(): boolean {
        return this.mode === CrudModeEnum.Import;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
