import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CrudModeEnum } from '../../enum/crud-mode.enum';
import icClose from '@iconify/icons-ic/twotone-close';

@Component({
    selector: 'base-form-header',
    templateUrl: 'form-header-base.component.html'
})

export class FormHeaderBaseComponent implements OnInit {

    @Input() createLabel: string;
    @Input() updateLabel: string;
    @Input() readLabel: string;
    @Input() importLabel: string;
    @Input() filterLabel: string;
    @Input() mode: CrudModeEnum;
    @Input() noDivider = false;
    @Input() loading = false;

    @Input() iconCustom: string;

    @Output() onClose = new EventEmitter();

    CrudModeEnum = CrudModeEnum;
    icClose = icClose;

    fixUpdateLabel: string;

    constructor() {
    }

    ngOnInit() {

        if (!this.mode) {
            this.mode = CrudModeEnum.Create;
        }

        this.fixUpdateLabel = this.updateLabel;
        if (!this.readLabel) {
            this.readLabel = this.updateLabel;
        }
    }
}
