import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CrudModeEnum } from '../../../../../core/base/enum/crud-mode.enum';
import icClose from '@iconify/icons-ic/twotone-close';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormModalBaseComponent } from '../../../../../core/base/components/form-modal-base/form-modal-base.component';
import { BuildingModel } from '../../../../../core/models/building.model';
import { BuildingService } from '../../building/building.service';
import { ProductCompatibleEquipmentModel, ProductModel } from '../../../../../core/models/product.model';
import { ErrorApi } from '../../../../../core/models/api/error-api';
import { Observable } from 'rxjs';
import { EstablishmentModel } from '../../../../../core/models/establishment.model';
import { EstablishmentService } from '../../establishment/establishment.service';
import { map } from 'rxjs/operators';
import { EquipmentModel } from '../../../../../core/models/equipment.model';
import { EquipmentService } from '../../equipment/equipment.service';
import { ProductService } from '../product.service';

@Component({
  selector: 'vex-product-compatible-equipment-create-update-delete',
  templateUrl: './product-compatible-equipment-create-update-delete.component.html',
  styleUrls: ['./product-compatible-equipment-create-update-delete.component.scss']
})
export class ProductCompatibleEquipmentCreateUpdateDeleteComponent  implements OnInit {
  form: FormGroup;
  mode: CrudModeEnum;

  icClose = icClose;

  loading = false;
  defaults: any;
  successCreateMessage: string;
  successUpdateMessage: string;
  successDeleteMessage: string;
  errorCreateMessage: string;
  errorUpdateMessage: string;
  errorDeleteMessage: string;

  CrudModeEnum = CrudModeEnum;

  establishment: EstablishmentModel[]
  equipment$: Observable<EquipmentModel[]>

  constructor(
      @Inject(MAT_DIALOG_DATA) public data,
      public dialogRef: MatDialogRef<FormModalBaseComponent<ProductModel>>,
      public service: ProductService,
      public establishmentService: EstablishmentService,
      public equipmentService: EquipmentService
  ) { }

  ngOnInit(): void {
    this.mode = this.data.mode;
    this.defaults = this.data.defaults;

    if (this.isCreateMode()) {
      this.establishmentService.findAll().subscribe(res => {
        this.establishment = res.data;
      });
    }

    this.form = new FormGroup({
      establishment: new FormControl(null, [Validators.required]),
      equipment: new FormControl(null, [Validators.required]),
    })

    this.form.valueChanges.subscribe(controls => {
      if (controls.establishment) {
        const compatibleEquipmentIds: string[] = this.data?.product?.compatibleEquipment.map(x => x._id);
        this.equipment$ = this.equipmentService.findAll(null, null, null, 'desc', null, {establishment: controls.establishment?._id, compatibleEquipmentIds: compatibleEquipmentIds}).pipe(map(res => res.data));
      } else {
        this.form.controls.equipment.setValue(null, {emitEvent: false});
        this.equipment$ = new Observable<EquipmentModel[]>(null);
      }
    });
  }

  isCreateMode() {
    return this.mode === CrudModeEnum.Create;
  }

  isUpdateMode() {
    return this.mode === CrudModeEnum.Update;
  }

  isDeleteMode() {
    return this.mode === CrudModeEnum.Delete;
  }

  save() {
    if (this.isCreateMode()) {
      this.createItem();
    }
  }

  createItem() {
    if (this.form.valid) {
      const data = this.form.getRawValue();
      this.service.addComplatibleEquipment(this.data?.product?._id, data.equipment).subscribe(
          res => {
            this.close(res.data);
          }, err => {
            this.setErrorsMessages(err.error.errors);
          }
      );
    } else {
      this.form.markAllAsTouched();
    }
  }

  deleteItem() {
    if (this.defaults) {
      this.service.deleteCompatibleEquipment(this.data.product._id, this.defaults._id).subscribe(
          res => {
            this.close(res);
          }, err => {
            this.setErrorsMessages(err.error.errors);
          }
      );
    }
  }

  close(res?) {
    this.dialogRef.close(res);
  }

  setErrorsMessages(errors: Array<ErrorApi>) {
    errors?.forEach(error => {
      let err = this.form.controls[error.property].errors;
      if (err === null) {
        err = {messages: []};
      }
      err.messages.push(error.message);
      this.form.controls[error.property].setErrors(err);
    });
    this.form.markAllAsTouched();
  }

  equipmentAutocompleteChange(state: any) {
    this.equipment$ = this.equipmentService.findAll(null, null, null, 'desc', state, {establishment: this.form.controls.establishment.value?._id, compatibleEquipmentIds: this.data?.product?.compatibleEquipment.map(x => x._id)}).pipe(map(res => res.data));
  }
}
