<ng-container *ngIf="isCreateMode() || isUpdateMode()">
    <form (ngSubmit)="save()" *ngIf="!loading">
        <base-form-header [mode]='mode' createLabel='Edition des rôles'></base-form-header>
        <mat-dialog-content fxLayout="column" class="overflow-hidden">
            <div fxLayout="column" [formGroup]="formUser">
                <base-form-input
                        class="w-full"
                        label='Copier les droits de'
                        controlName='user'
                        type='autocomplete'
                        [items]='users'
                        displayProperty='fullName'
                        [disableAutoSelect]="true"
                ></base-form-input>
                <mat-form-field
                        class="w-full">
                    <mat-label>Intervalle de validité</mat-label>
                    <mat-date-range-input formGroupName='range' [rangePicker]="picker">
                        <input formControlName='start' matStartDate placeholder="Date de début">
                        <input formControlName='end' matEndDate placeholder="Date de fin">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>

                </mat-form-field>
                <button mat-raised-button color='primary' type="button" class="w-full" (click)=applyCopy() [disabled]="disabledApplyButton">APPLIQUER LES CHANGEMENTS</button>

            </div>
            <mat-divider class="mb-6"></mat-divider>
            <div fxLayout="column">
                <div fxLayout="row" fxLayoutGap="20px" *ngFor="let assignedRole of assignedRoles;"
                     [formGroup]="assignedRole.form">
                    <base-form-input
                            class="w-full"
                            label='Rôles'
                            controlName='role'
                            type='autocomplete'
                            disabled="true"
                            [items]='availableRoles'
                            displayProperty='label'
                            [disableAutoSelect]="true"
                    ></base-form-input>
                    <mat-form-field class="w-full">
                        <mat-label>Intervalle de validité</mat-label>
                        <mat-date-range-input formGroupName='range' [rangePicker]="picker">
                            <input formControlName='start' matStartDate placeholder="Date de début">
                            <input formControlName='end' matEndDate placeholder="Date de fin">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                        <mat-hint *ngIf="assignedRole.hint" align="end" class="text-amber">{{ assignedRole.hint }}</mat-hint>


                    </mat-form-field>
                    <button class="mt-2" mat-icon-button type="button" *ngIf="canRemovePermission">
                        <mat-icon color="warn" (click)='removeRole(assignedRole)'>delete</mat-icon>
                    </button>
                </div>

                <div [formGroup]="formAdd" fxLayout="row" fxLayoutGap="20px" class="w-full"
                     *ngIf="displayAddForm && canAddRolePermission">
                    <base-form-input
                            class="w-full"
                            label='Rôles'
                            controlName='role'
                            type='autocomplete'
                            [items]='availableRoles'
                            displayProperty='label'
                            [disableAutoSelect]="true"
                    ></base-form-input>
                    <mat-form-field
                            class="w-full">
                        <mat-label>Intervalle de validité</mat-label>
                        <mat-date-range-input formGroupName='range' [rangePicker]="picker">
                            <input formControlName='start' matStartDate placeholder="Date de début">
                            <input formControlName='end' matEndDate placeholder="Date de fin">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>

                        <!--                                <mat- *ngIf="popupRange.controls.end.errors?.required">Date de fin obligatoire</mat-error>-->
                    </mat-form-field>
                    <button mat-icon-button type="button" class="mt-2">
                        <mat-icon></mat-icon>
                    </button>
                </div>
            </div>

        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close type="button">ANNULER</button>
            <button mat-button type="button" color="primary" (click)='updateUserProfileRoles()'>ENREGISTRER</button>
        </mat-dialog-actions>
    </form>
</ng-container>

