import {RandomUtils} from '../utils/random-utils';

export enum QuoteStatus {
    DRAFT = 'Brouillon',
    SENT = 'Envoyé',
    SELECTED = 'Retenu',
    UNSELECTED = 'Non retenu',
    ENGAGED = 'Engagé',
    SAVED = 'Enregistré',
    REJECTED = 'Rejeté',
}

export enum QuoteStatusHistoryEnum {
    DRAFT = 'DRAFT',
    SENT = 'SENT',
    SELECTED = 'SELECTED',
    UNSELECTED = 'UNSELECTED',
    ENGAGED = 'ENGAGED',
    SAVED = 'SAVED',
    REJECTED = 'REJECTED',
}

export const quoteTypeTranslation = (status: QuoteStatus) => {
    switch (status) {
        case QuoteStatus.DRAFT.toString():
            return 'Brouillon';
        case QuoteStatus.SENT.toString():
            return 'Envoyé';
        case QuoteStatus.SELECTED.toString():
            return 'Retenu';
        case QuoteStatus.UNSELECTED.toString():
            return 'Non retenu';
        case QuoteStatus.ENGAGED.toString():
            return 'Engagé';
        case QuoteStatus.SAVED.toString():
            return 'Enregistré';
        default:
            return null;
    }
};

export const quoteTypeStatusHistoryTranslation = (status: QuoteStatusHistoryEnum) => {
    switch (status) {
        case QuoteStatusHistoryEnum.DRAFT.toString():
            return 'Brouillon';
        case QuoteStatusHistoryEnum.SENT.toString():
            return 'Envoyé';
        case QuoteStatusHistoryEnum.SELECTED.toString():
            return 'Devis retenu';
        case QuoteStatusHistoryEnum.UNSELECTED.toString():
            return 'Non retenu';
        case QuoteStatusHistoryEnum.ENGAGED.toString():
            return 'Devis validé';
        case QuoteStatusHistoryEnum.SAVED.toString():
            return 'Enregistré';
        case QuoteStatusHistoryEnum.REJECTED.toString():
            return 'Devis rejeté';
        default:
            return null;
    }
};

export const quoteTypeEnumObject = (type: QuoteStatus) => {
    return {
        _id: RandomUtils.generateId(),
        value: type,
        label: quoteTypeTranslation(type)
    };
};
