<ng-container *ngIf="canCreateMode || canUpdateMode">
    <form (ngSubmit)="save()" [formGroup]="form" style="min-width: 750px; width: 750px">
        <base-form-header [mode]='mode' createLabel='Ajouter une famille'
                          [updateLabel]='"Modifier une famille"'></base-form-header>

        <mat-dialog-content fxLayout="column">

            <base-form-input
                    label="Famille"
                    type="autocomplete"
                    controlName="category"
                    displayProperty='link'
                    [items]="categories"
            ></base-form-input>

<!--            <base-form-input-->
<!--                    label="Pourcentage"-->
<!--                    type="number"-->
<!--                    controlName="percent"-->
<!--            ></base-form-input>-->
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <base-form-input
                    [loading]="loading"
                    type='submit'
                    [mode]='mode'
                    entityLabel='SUPPLIER'
                    (onCreate)='createItem()'
                    (onUpdate)='updateItem()'
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>

<ng-container *ngIf="canDeleteMode">
    <form-modal-delete-base
            label="famille d'achat"
            defaultMessage="Etes-vous sûr de vouloir supprimer cette famille ?"
            (onClose)="close()"
            (onDelete)="deleteItem()"
    ></form-modal-delete-base>
</ng-container>
