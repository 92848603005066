<div class="flex flex-col">
    <div class='bg-card flex flew-row items-center rounded-full border px-4 ltr:pr-4 rtl:pl-4 ltr:border-r rtl:border-l'>
        <ic-icon [icIcon]='icSearch' size='20px'></ic-icon>
        <input
                class='px-4 py-3 border-0 outline-none w-full bg-transparent'
                placeholder='Recherche...'
                [formControl]="searchControl"
                #search
        >
    </div>

    <!--Select and unselect all-->
    <button mat-raised-button color="primary"
            (click)="toggleAll()"
            *ngIf="!readMode"
            class="my-2">
        {{ selectAllButtonLabel }}
    </button>

    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
            <mat-checkbox
                    *ngIf="!readMode"
                    [checked]="node.selected"
                    (change)="itemToggle($event.checked,node)">{{ node.name }}
            </mat-checkbox>
            <span *ngIf="readMode">{{ node.name }}</span>

        </mat-tree-node>

        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
            <li>
                <div class="mat-tree-node">
                    <button mat-icon-button matTreeNodeToggle
                            [attr.aria-label]="'toggle ' + node.name">
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                        </mat-icon>
                    </button>
                    <mat-checkbox
                            *ngIf="!readMode"
                            [checked]="node.selected"
                            [indeterminate]="node.indeterminate && !node.selected"
                            (change)="itemToggle($event.checked,node)">{{ node.name }}
                    </mat-checkbox>
                    <span *ngIf="readMode">{{ node.name }}</span>

                </div>
                <ul class='checklist-leaf-node' [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                    <ng-container matTreeNodeOutlet></ng-container>
                </ul>
            </li>
        </mat-nested-tree-node>
    </mat-tree>

</div>
