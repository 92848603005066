import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from "rxjs";
import icClose from "@iconify/icons-ic/twotone-close";
import icMinus from "@iconify/icons-ic/twotone-minus";
import icPlus from "@iconify/icons-ic/twotone-plus";
import {scaleIn400ms} from "../../../../../../@vex/animations/scale-in.animation";
import {fadeInRight400ms} from "../../../../../../@vex/animations/fade-in-right.animation";
import {stagger40ms} from "../../../../../../@vex/animations/stagger.animation";
import {fadeInUp400ms} from "../../../../../../@vex/animations/fade-in-up.animation";
import {scaleFadeIn400ms} from "../../../../../../@vex/animations/scale-fade-in.animation";

@Component({
    selector: 'catalog-basket-component',
    templateUrl: 'catalog-basket.component.html',
    animations: [
        scaleIn400ms,
        fadeInRight400ms,
        stagger40ms,
        fadeInUp400ms,
        scaleFadeIn400ms
    ],
    styles: [`
        .number-no-arrows::-webkit-outer-spin-button,
        .number-no-arrows::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
            text-align: center;
        }
    `]
})

export class CatalogBasketComponent implements OnInit {

    @Input() items$: Observable<any> = new Observable<any>();
    @Input() canChangeQuantity: boolean = false;
    @Input() canDelete: boolean = false;
    @Input() displayTitle: boolean = true;
    @Input() showExpired: boolean = false;
    @Input() isBasketType: boolean = false;
    @Input() isBasketTypeFromTicket: boolean = false;
    @Output() removeItem = new EventEmitter<any>();
    @Output() updateQuantityItem = new EventEmitter<any>();
    @Output() updateEmergencyItem = new EventEmitter<any>();

    icPlus = icPlus;
    icMinus = icMinus;
    icClose = icClose;

    items: any;
    totalPrice: number = 0;

    constructor() {
    }

    ngOnInit() {
        this.items$.subscribe(items => {
            this.items = items;
            if (this.isBasketType) {
                this.items.map(item => {
                    item.isAllowed = true;
                    return item;
                });
            }
            console.log(items, 'voici les items')
            this.totalPrice = this.items.reduce((acc, item) => {
                const priceHT = item?.priceHT ? item?.priceHT : item?.item.priceHT;
                return acc + priceHT * item?.quantity;
            }, 0);
        });
    }

    remove(item: any) {
        this.removeItem.emit(item);
    }

    updateQuantity(item: any, action: 'minus' | 'plus' | 'change') {
        this.updateQuantityItem.emit({item, action});
    }

    _checkNumberInput($event: Event, item: any) {
        const inputElement = event.target as HTMLInputElement;
        const inputValue = inputElement.valueAsNumber;
        if (inputValue < 1) {
            this.remove(item);
        } else {
            this.updateQuantity(item, 'change');
        }
    }
}
