<ng-template #equipmentTemplate>
    <vex-page-layout>

            <vex-page-layout-header class='pb-4' fxLayout='column' fxLayoutAlign='center end'>
                <app-legend [legendItems]="legendData" class="-mt-6"></app-legend>
            </vex-page-layout-header>


        <vex-page-layout-content class='-mt-6'>
            <div class='card overflow-auto -mt-16'>
                <base-data-table-header
                    [label]="entityName"
                    [loading]='loading'
                    [showArchives]="canShowArchivePermission"
                    [templates]="[{ template: advancedFilters, position: 2, place: 'before' }]"
                    (onShowArchived)="onShowArchived($event)"
                    (onSearchChange)='onSearchChange($event)'
                    (onCreate)='createItem()'
                    canImport='true'
                    (onImport)='importItems()'
                    [createPermission]="[ENUM_PERMISSION.CREATE_EQUIPMENT]"
                    [importPermission]="[ENUM_PERMISSION.CREATE_EQUIPMENT]"
                ></base-data-table-header>

                <base-data-table
                    [count]='count'
                    [pageSize]='pageSize'
                    [sortField]='sortField'
                    [sortOrder]='sortOrder'
                    [dataSource]='dataSource'
                    [displayedColumns]='displayedColumns'
                    [customColumnTemplate]="customColumn"
                    [feisColumns]='feisColumns'
                    [routerLinkDetailRow]='routerLinkDetailRow'
                    [stickyTop]="true"
                    (onPageEvent)='pageEvent($event)'
                    (onSortEvent)='sortEvent($event)'
                    (onUpdateItem)='updateItem($event)'
                    (onDeleteItem)='deleteItem($event)'
                    (onArchiveItem)='archiveItem($event)'
                ></base-data-table>
            </div>
        </vex-page-layout-content>
    </vex-page-layout>
    <ng-template #advancedFilters>
        <button-filter
            [nbFilters]="activeFiltersCount"
            (clickButton)="toggleSidenav()">
        </button-filter>
    </ng-template>
</ng-template>

<ng-template #filters>
    <ng-container *ngIf="isFilterOpen">
        <equipment-filter [sidenav]='sideNav' (onClear)="clearTextFilter()" (onFilter)="applyAdvancedFilter($event)">
        </equipment-filter>
    </ng-container>
</ng-template>

<app-sidenav
    #sidenav
    [content]="equipmentTemplate"
    [contentSidenav]="filters">
</app-sidenav>

<ng-template #customColumn let-column let-row="element" let-status="status">
  <div  *ngIf="column === 'equipmentStatus'">
  <mat-icon  *ngIf="row.equipmentStatus === equipmentStatusEnum.TO_COMPLETE" class="legend-icon" [ngStyle]="{'color':' #FFC0CB'}">circle</mat-icon>
  </div>


<!--  <div *ngIf="column === 'functionalEquipment'">-->
<!--    <a *ngIf="isEquipmentActive(row.metrikStatus); else notActive"-->
<!--       class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-primary bg-primary-light"-->
<!--       mat-icon-button>-->
<!--      <mat-icon [icIcon]="icDone" size="18px"></mat-icon>-->
<!--    </a>-->
<!--    <ng-template #notActive>-->
<!--      <a class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-accent bg-accent-light"-->
<!--         mat-icon-button>-->
<!--        <mat-icon [icIcon]="icClose" size="18px"></mat-icon>-->
<!--      </a>-->
<!--    </ng-template>-->
<!--  </div>-->

</ng-template>

