import {Component, Inject, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {StageModel} from "../../../../../../../core/models/stage.model";
import {CrudModeEnum} from "../../../../../../../core/base/enum/crud-mode.enum";
import icClose from "@iconify/icons-ic/twotone-close";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
    FormModalBaseComponent
} from "../../../../../../../core/base/components/form-modal-base/form-modal-base.component";
import {BuildingModel} from "../../../../../../../core/models/building.model";
import {BuildingService} from "../../../building.service";
import {BuildingRoomModel} from "../../../../../../../core/models/building-room.model";
import {RoomCategoryService} from "../../../../../settings/room-category/room-category.service";
import {Observable, Subscription} from "rxjs";
import {RoomCategoryModel} from "../../../../../../../core/models/room-category.model";
import {map} from "rxjs/operators";
import {ErrorApi} from "../../../../../../../core/models/api/error-api";
import {b64ToBlob} from "../../../../../../../core/helpers/b64ToBlob.helper";
import {ContentTypeEnum} from "../../../../../../../core/base/enum/content-type.enum";
import {FileSaverService} from "ngx-filesaver";
import {GenericRoomService} from "../../../../../settings/generic-room/generic-room.service";
import {GenericRoomModel} from "../../../../../../../core/models/generic-room.model";
import {EstablishmentModel} from "../../../../../../../core/models/establishment.model";
import {EstablishmentService} from "../../../../establishment/establishment.service";
import {AuthService} from "../../../../../../../core/services/auth.service";
import {ENUM_PERMISSIONS} from "../../../../../../../core/enums/permission.enum";


@Component({
    selector: "vex-building-details-room-create-update-delete",
    templateUrl: "./building-details-room-create-update-delete.component.html",
    styleUrls: ["./building-details-room-create-update-delete.component.scss"]
})
export class BuildingDetailsRoomCreateUpdateDeleteComponent implements OnInit {
    form: FormGroup;
    mode: CrudModeEnum;

    importForm: FormGroup;

    icClose = icClose;

    loading = false;
    defaults: BuildingRoomModel;
    successCreateMessage: string;
    successUpdateMessage: string;
    successDeleteMessage: string;
    errorCreateMessage: string;
    errorUpdateMessage: string;
    errorDeleteMessage: string;

    CrudModeEnum = CrudModeEnum;

    roomCategory$: Observable<RoomCategoryModel[]>;
    genericRoom$: Observable<GenericRoomModel[]>;
    stage$: Observable<StageModel[]>;
    buildingEstablishment$: Observable<EstablishmentModel[]>;
    importDefaults: any;

    subscription = new Subscription();

    displayNbOfBed = false;

    canDownloadTemplateModel: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        public dialogRef: MatDialogRef<FormModalBaseComponent<BuildingModel>>,
        public service: BuildingService,
        public roomCatergoryService: RoomCategoryService,
        public genericRoomService: GenericRoomService,
        public establishmentService: EstablishmentService,
        private fileSaver: FileSaverService,
        private authService: AuthService
    ) {
    }

    ngOnInit(): void {
        this.mode = this.data.mode;
        this.defaults = this.data.defaults;

        this.stage$ = this.service.entity$.pipe(map(res => res.stages));
        this.buildingEstablishment$ = this.service.entity$.pipe(map(res => res.establishments));
        this.roomCategory$ = this.roomCatergoryService.findAll().pipe(map(res => res.data));

        this.genericRoomAutocomplete();

        this.form = new FormGroup({
            _id: new FormControl(this.defaults?._id),
            label: new FormControl(this.defaults?.label, [Validators.required]),
            stage: new FormControl(this.defaults?.stage, [Validators.required]),
            // roomCategory: new FormControl(this.defaults?.roomCategory, [Validators.required]),
            genericRoom: new FormControl(this.defaults?.genericRoom, [Validators.required]),
            surface: new FormControl(this.defaults?.surface, [Validators.required]),
            nbOfBed: new FormControl(this.defaults?.nbOfBed, [Validators.required]),
            establishment: new FormControl(this.defaults?.establishment, [Validators.required])
        });

        this.form.controls.genericRoom.valueChanges.subscribe(value => {
            this.nbOfBedFormSetup(value);
        });

        if (this.isImportMode()) {
            this.importForm = new FormGroup({
                file: new FormControl("", [Validators.required])
            });
        }

        this.nbOfBedFormSetup(this.defaults?.genericRoom);

        this.authService.getCurrentUserPermissions$().subscribe(permissions => {
            this.canDownloadTemplateModel = permissions.includes(ENUM_PERMISSIONS.DOWNLOAD_BUILDING_ROOM)
        })
    }

    isCreateMode() {
        return this.mode === CrudModeEnum.Create;
    }

    isUpdateMode() {
        return this.mode === CrudModeEnum.Update;
    }

    isDeleteMode() {
        return this.mode === CrudModeEnum.Delete;
    }

    isImportMode(): boolean {
        return this.mode === CrudModeEnum.Import;
    }

    save() {
        if (this.isCreateMode()) {
            this.createItem();
        } else if (this.isUpdateMode()) {
            this.updateItem();
        } else if (this.isImportMode()) {
            this.importItems();
        }
    }

    createItem() {
        if (this.form.valid) {
            this.loading = true;
            this.service.createRoom(this.data.building._id, this.form.getRawValue()).subscribe(
                res => {
                    this.close(res.data);
                }, err => {
                    this.loading = false;
                    this.setErrorsMessages(err.error.errors);
                }
            );
        } else {
            this.form.markAllAsTouched();
        }
    }

    updateItem() {
        if (this.form.valid) {
            this.loading = true;
            this.service.updateRoom(this.data.building._id, this.form.getRawValue()).subscribe(
                res => {
                    this.close(res.data);
                }, err => {
                    this.loading = false;
                    this.setErrorsMessages(err.error.errors);
                }
            );
        } else {
            this.form.markAllAsTouched();
        }
    }

    deleteItem() {
        if (this.defaults) {
            this.loading = true;
            this.service.deleteRoom(this.data.building._id, this.defaults._id).subscribe(
                res => {
                    this.close(res);
                }, err => {
                    this.loading = false;
                    this.setErrorsMessages(err.error.errors);
                }
            );
        }
    }

    close(res?) {
        this.dialogRef.close(res);
    }


    setErrorsMessages(errors: Array<ErrorApi>) {
        errors?.forEach(error => {
            let err = this.form.controls[error.property].errors;
            if (err === null) {
                err = {messages: []};
            }
            err.messages.push(error.message);
            this.form.controls[error.property].setErrors(err);
        });
        this.form.markAllAsTouched();
    }

    importItems() {
        this.importDefaults = this.importForm.value;
        this.beforeImportItem();
        if (this.importForm.valid) {
            this.loading = true;
            const sub = this.service.importRoom(this.importDefaults, this.data?.building?._id).subscribe(
                result => {
                    this.close(result);
                }, error => {
                    this.setImportErrorsMessages(error.error.errors);
                    this.loading = false;
                });
            this.subscription.add(sub);
        } else {
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    beforeImportItem() {
        let formData = new FormData();

        if (!this.importDefaults?.file?._files?.length) {
            this.importForm.controls["file"].setErrors({required: true});
            this.importForm.markAllAsTouched();
            return;
        }

        formData.append("file-excel", this.importDefaults.file._files[0]);
        this.importDefaults = formData;
    }

    setImportErrorsMessages(errors: Array<ErrorApi>) {
        errors?.forEach(error => {
            let err = this.importForm.controls[error.property].errors;
            if (err === null) {
                err = {messages: []};
            }
            err.messages.push(error.message);
            this.importForm.controls[error.property].setErrors(err);
        });
        this.importForm.markAllAsTouched();
    }

    exportRoomModel() {
        this.service.downloadExcelRoomTemplate().subscribe((x: any) => {
            const b = b64ToBlob(x.data.b64, ContentTypeEnum.excel);
            this.fileSaver.save(b, x.data.fileName);
        });
    }

    roomCategoryAutocompleteChange(state: any) {
        this.roomCategory$ = this.roomCatergoryService.findAll(null, null, "label", "asc", state).pipe(map(res => res.data));
    }

    genericRoomAutocomplete(search = "") {
        this.genericRoom$ = this.genericRoomService.findAll(null, null, "label", "asc", search).pipe(map(res => res.data));
    }

    private nbOfBedFormSetup(value) {
        if (value?.nbBed) {
            this.displayNbOfBed = true;
            this.form.controls.nbOfBed.setValidators([Validators.required]);
            this.form.controls.nbOfBed.updateValueAndValidity();
        } else {
            this.displayNbOfBed = false;
            this.form.controls.nbOfBed.setValue(null);
            this.form.controls.nbOfBed.clearValidators();
        }
    }
}
