export enum GenericEnergyIdTypeEnergyEnum {
    GAS = 'GAS',
    ELECTRICITY = 'ELECTRICITY',
    WOOD = 'WOOD',
    FUEL = 'FUEL',
}

export enum GenericEnergyIdFormatEnum {
    ALPHANUMERIC = 'ALPHANUMERIC',
    NUMERIC = 'NUMERIC',
    NO_RESTRICTION = 'NO_RESTRICTION'
}

export enum GenericEnergyIdRestrictEnum {
    FIXED = 'FIXED',
    MAX = 'MAX',
}

export class GenericEnergyIdEnumTranslate {
    static translateGenericEnergyIdTypeEnergyToFrench(value: GenericEnergyIdTypeEnergyEnum): string {
        switch (value) {
            case GenericEnergyIdTypeEnergyEnum.GAS:
                return 'Gaz';
            case GenericEnergyIdTypeEnergyEnum.ELECTRICITY:
                return 'Électricité';
            case GenericEnergyIdTypeEnergyEnum.WOOD:
                return 'Bois';
            case GenericEnergyIdTypeEnergyEnum.FUEL:
                return 'Fioul';
            default:
                return value;
        }
    }

    static translateGenericEnergyIdFormatToFrench(value: GenericEnergyIdFormatEnum): string {
        switch (value) {
            case GenericEnergyIdFormatEnum.ALPHANUMERIC:
                return 'Alphanumérique';
            case GenericEnergyIdFormatEnum.NUMERIC:
                return 'Numérique';
            case GenericEnergyIdFormatEnum.NO_RESTRICTION:
                return 'Pas de restriction';
            default:
                return value;
        }
    }

    static translateGenericEnergyIdRestrictToFrench(value: GenericEnergyIdRestrictEnum): string {
        switch (value) {
            case GenericEnergyIdRestrictEnum.FIXED:
                return 'Fixe';
            case GenericEnergyIdRestrictEnum.MAX:
                return 'Max';
            default:
                return value;
        }

    }


}

export const genericEnergyIdTypeEnergyEnumTranslate = (genericEnergyIdTypeEnergyEnum: GenericEnergyIdTypeEnergyEnum) => {
    return {
        value: genericEnergyIdTypeEnergyEnum,
        label: GenericEnergyIdEnumTranslate.translateGenericEnergyIdTypeEnergyToFrench(genericEnergyIdTypeEnergyEnum)
    };
}

export const genericEnergyIdFormatEnumTranslate = (genericEnergyIdFormatEnum: GenericEnergyIdFormatEnum) => {
    return {
        value: genericEnergyIdFormatEnum,
        label: GenericEnergyIdEnumTranslate.translateGenericEnergyIdFormatToFrench(genericEnergyIdFormatEnum)
    };
}

export const genericEnergyIdRestrictEnumTranslate = (genericEnergyIdRestrictEnum: GenericEnergyIdRestrictEnum) => {
    return {
        value: genericEnergyIdRestrictEnum,
        label: GenericEnergyIdEnumTranslate.translateGenericEnergyIdRestrictToFrench(genericEnergyIdRestrictEnum)
    };
}
