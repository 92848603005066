<vex-ged-layout [canAddDoc]="true"
                [loading]="loading"
                [addDocumentTitle]="addDocumentTitle"
                [listTitle]="listTitle">
    <base-data-table
            [dataSource]='dataSource'
            [actionsTemplate]="customAction"
            [displayedColumns]='displayedColumns'
            [feisColumns]='feisColumns'
            [showPaginator]="false"
            (onDeleteItem)="openDelete($event)"
            [showSort]="false"
    ></base-data-table>
    <ng-template #customAction let-row="value">
        <button class="mat-menu-item" (click)="downloadDocument(row.DWDOCID)">
            <mat-icon>file_download</mat-icon>
            Télécharger
        </button>
    </ng-template>
    <div dragFormZone>
        <div fxLayout="column" *ngIf="!loading">
            <vex-drop-zone [files]="files"></vex-drop-zone>
            <button mat-raised-button color="primary" class="mt-4" fxFlexAlign="end" (click)="validForm()" [loading]="loadingSave"
                    [disabled]="form.invalid || files.length === 0">ENREGISTRER
            </button>
        </div>
    </div>
</vex-ged-layout>
