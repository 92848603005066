import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { scaleIn400ms } from '../../../../../../../@vex/animations/scale-in.animation';
import { fadeInRight400ms } from '../../../../../../../@vex/animations/fade-in-right.animation';
import { stagger40ms } from '../../../../../../../@vex/animations/stagger.animation';
import { fadeInUp400ms } from '../../../../../../../@vex/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from '../../../../../../../@vex/animations/scale-fade-in.animation';
import {
    ContractDeliveryCostEnum,
    ContractDeliveryTimeEnum,
    ContractTypeOfRevisionEnum,
} from '../../../../../../core/enums/contract.enum';
import { Observable, Subscription, switchMap, tap } from 'rxjs';
import { ContractService } from '../../contract.service';
import {
    RecurrenceEnum,
    recurrenceEnumTranslation,
    recurrenceEnumTranslationOccurence,
} from '../../../../../../core/enums/recurrence.enum';
import { MonthEnum, monthEnumTranslation } from '../../../../../../core/enums/month.enum';
import { DayEnum, dayEnumTranslation } from '../../../../../../core/enums/day.enum';
import moment from 'moment';
import { DialogConfirmComponent } from '../../../../../../../@vex/components/dialog-confirm/dialog-confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { InputTypeInterface } from '../../../../../../core/base/interfaces/input-type.interface';
import { ContractStatusEnum } from '../../../../../../core/enums/contract-status.enum';
import { GenericTvaService } from '../../../../settings/generic-tva/generic-tva.service';
import { GenericTVAModel } from '../../../../../../core/models/generic-tva.model';
import { CustomerGroupService } from '../../../../settings/customer-group/customer-group.service';
import { GenericTypeAdditionalCostModel } from '../../../../../../core/models/generic-type-additional-cost.model';
import { GenericEngagementFormatEnum } from '../../../../../../core/enums/generic-engagement-format.enum';
import {
    GenericAdditionalCostService,
} from '../../../../settings/generic-additional-cost/generic-additional-cost.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericAdditionalCostModel } from '../../../../../../core/models/generic-additional-cost.model';
import { ContractSustainabilityPriceEnum } from '../../../../../../core/enums/contract-sustainability-price.enum';
import {
    ContractAdditionalCostsModel,
    ContractDeliveryCostModel,
    ContractDeliveryTimeModel,
    ContractModel,
    ContractSustainabilityAndPriceTransModel,
    RecurringBasketModel,
} from '../../../../../../core/models/contract.model';
import { debounceTime, startWith, take } from 'rxjs/operators';
import { quillConfig } from '../../../../../../core/config/quill.config';
import { GenericAdditionalCostTypeEnum } from '../../../../../../core/enums/generic-additional-cost-type.enum';
import {
    convertMinutesToUnit,
    TimeDelay,
    TimeUnit, translateTimeDelay,
    translateTimeUnitObject,
} from '../../../../../../core/utils/time-utils';


@Component({
    selector: 'vex-contract-details-cga',
    templateUrl: './contract-details-cga.component.html',
    styleUrls: [
        './contract-details-cga.component.scss',
        '../../../../../../../../node_modules/quill/dist/quill.snow.css',
        '../../../../../../../@vex/styles/partials/plugins/_quill.scss'
    ],
    animations: [
        scaleIn400ms,
        fadeInRight400ms,
        stagger40ms,
        fadeInUp400ms,
        scaleFadeIn400ms
    ],
    encapsulation: ViewEncapsulation.None,
})
export class ContractDetailsCgaComponent implements OnInit {

    constructor(
        public contractService: ContractService,
        public customerGroupService: CustomerGroupService,
        private genericAdditionalCostService: GenericAdditionalCostService,
        private dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private genericTvaService: GenericTvaService,
    ) {

    }

    private id: string;
    private defaultOtherExpenses: GenericTypeAdditionalCostModel;
    public genericAdditionalCost$: Observable<GenericAdditionalCostModel[]>;

    public contractSustainabilityPrice = Object.values(ContractSustainabilityPriceEnum);
    public contractTypeOfRevision = Object.values(ContractTypeOfRevisionEnum);

    public form: FormGroup;
    public sustainabilityAndPriceTransForm: FormGroup;
    public priceRevisionForm: FormGroup;
    public additionalCostsForm: FormArray;
    public additionalCostAddForm: FormGroup;
    public deliveryCostForm: FormGroup;
    public deliveryTimeForm: FormGroup;

    public contractSustainabilityPriceEnum = ContractSustainabilityPriceEnum;
    public contractTypeOfRevisionEnum = ContractTypeOfRevisionEnum;
    public deliveryCostsEnum = ContractDeliveryCostEnum;
    public deliveryTimesEnum = ContractDeliveryTimeEnum;
    monthEnum = Object.values(MonthEnum).map(month => monthEnumTranslation(month));
    dayEnum = Object.values(DayEnum).map(day => dayEnumTranslation(day));

    public deliveryCostEnum = Object.values(ContractDeliveryCostEnum);
    public deliveryTimeEnum = Object.values(ContractDeliveryTimeEnum);
    public genericAdditionalCosts$: Observable<GenericAdditionalCostModel[]>;
    public availableGenericAdditionalCosts: GenericAdditionalCostModel[];
    defaultTimeDelay = { quantity: 0, displayedUnit: TimeUnit.HOUR }; // Par défaut : 0 et une unité par défaut

    public displayRepeat = true;
    public repeatLabel: string;
    autoCompleteDisplayProperty: string = null;

    public anniversaryCheckboxActive: boolean;

    recurrenceEnum = Object.values(RecurrenceEnum).map(recurrence => recurrenceEnumTranslation(recurrence));

    public autoCompleteValue: Array<any>;
    autoCompleteType: typeof InputTypeInterface;
    public autoCompleteIsActive = false;
    public autocompleteMonth = false;

    public quillConfig = quillConfig;
    private subscription = new Subscription();
    public contract: ContractModel;
    public ContractStatusEnum = ContractStatusEnum;
    readonly RecurrenceEnum = RecurrenceEnum;

    ContractDeliveryTimeEnum = ContractDeliveryTimeEnum;

    readonly TimeUnit = TimeUnit;

    ngOnInit(): void {

        this.initData();

        this.contractService.entity$.pipe(take(1)).subscribe((contract) => {
            if (contract) {
                this.id = contract._id;
                this.contract = contract;

                // Initialisation des formulaires avec les données de `contract`
                this.initSustainabilityAndPriceTransForm(this.contract.sustainabilityAndPriceTrans);
                this.initPriceRevisionForm(this.contract.priceRevision);
                this.initAdditionalCostForms(this.contract.additionalCosts);
                this.initDeliveryCostForm(this.contract.deliveryCost);
                this.initDeliveryTimeForm(this.contract.deliveryTime);

                this.genericAdditionalCostService.findAll().pipe(take(1)).subscribe(x => {
                    // this.availableGenericAdditionalCosts = x.data.filter(
                    //     (item: GenericAdditionalCostModel) => !item.isInactive
                    // );
                    // this.availableGenericAdditionalCosts = this.availableGenericAdditionalCosts.filter((x: GenericAdditionalCostModel) => {
                    //     // et je veux filtrer sur les additonnalcost qui ont pour propriete isInactive à false ou inexistant
                    //     return !this.contract.additionalCosts.some(y => y.additionalCost._id === x._id);
                    // });

                    this.availableGenericAdditionalCosts = x.data.filter((item: GenericAdditionalCostModel) => {

                        return (
                            !item.isInactive &&
                            !this.contract.additionalCosts.some(
                                (z: ContractAdditionalCostsModel) => z.additionalCost._id === item._id
                            )
                        );
                    });

                });

                const defaultDeliveryCost = this.contract?.deliveryCost?.deliveryCost || ContractDeliveryCostEnum.FREE_SHIPPING;
                this.deliveryCostForm.get('deliveryCost').setValue(defaultDeliveryCost);

                // const defaultDeliveryTime =
                //     this.contract?.deliveryTime?.deliveryTime ||
                // ContractDeliveryTimeEnum.URGENT_THEORETICAL_DELIVERY_TIME;

                // this.deliveryCostForm.get('deliveryTime').setValue(defaultDeliveryTime);

                // Création du formulaire principal
                this.initMainForm();

            }
        });

        this.contractService.entity$.subscribe((contract) => {
            this.contract = contract;
        });
    }

    getUrgentTheoreticalDeliveryValueLabel(): string {
        if (this.form.controls.deliveryTime.get('urgentTheoreticalDeliveryValue').value) {
            return 'Délai de livraison théorique urgent ( en ' +
               translateTimeDelay(this.form.controls.deliveryTime.get('urgentTheoreticalDeliveryValue').value).label + ')';
        }
        return 'Délai de livraison théorique urgent';
    }

    getStandardTheoreticalDeliveryValueLabel(): string {
        if (this.form.controls.deliveryTime.get('standardTheoreticalDeliveryValue').value) {
            return 'Délai de livraison théorique standard (en ' +
               translateTimeDelay(this.form.controls.deliveryTime.get('standardTheoreticalDeliveryValue').value).label + ')';
        }
        return 'Délai de livraison théorique standard';
    }

    getUrgentTheoreticalDeliveryValueQuantity(): number {
        const standard: TimeDelay = this.deliveryTimeForm.get('standardTheoreticalDeliveryValue')?.value;
        const urgent: TimeDelay = this.deliveryTimeForm.get('urgentTheoreticalDeliveryValue')?.value;

        if (standard && standard.quantity != null) {
            const standardMinutes = standard.quantity;
            const urgentUnit = urgent && urgent.displayedUnit ? urgent.displayedUnit : TimeUnit.HOUR;
            return convertMinutesToUnit(standardMinutes, urgentUnit);
        }
        return null;
    }

    // Exemple de méthode dans le composant parent
    getDefaultValue(controlName: string): TimeDelay {
        const controlValue = this.deliveryTimeForm.get(controlName)?.value;
        return controlValue ? controlValue : this.defaultTimeDelay;
    }


    async initData() {
        this.getDefaultOtherExpensesId();
    }

    showCustomAdditionalCosts(additionalCost: GenericAdditionalCostModel) {
        if (additionalCost.typeAdditionalCosts) {
            return `[${additionalCost.typeAdditionalCosts.label} - ${additionalCost.type}]`;
        }
    }

    computeTva(value: number, tva: GenericTVAModel) {
        let rate: number;

        if (tva && tva.rateList && tva.rateList.length > 0)
        {
            rate = this.genericTvaService.getCurrentRate(tva.rateList).rate;
            return value * (1 + (rate / 100));
        }else {
            return value;
        }
    }

    initMainForm() {
        this.form = new FormGroup({
            sustainabilityAndPriceTrans: this.sustainabilityAndPriceTransForm,
            priceRevision: this.priceRevisionForm,
            additionalCosts: this.additionalCostsForm,
            deliveryCost: this.deliveryCostForm,
            specialConditions: new FormControl(this.contract ? this.contract.specialConditions : null),
            deliveryTime: this.deliveryTimeForm
        });

        this.subscription.add(
            this.form.valueChanges
                .pipe(
                    debounceTime(500),
                    switchMap(value => {
                        const rawValue = this.form.getRawValue();
                        return this.contractService.updateCga(this.id, rawValue);
                    })
                ).subscribe((res) => {
            })
        );
    }

    initSustainabilityAndPriceTransForm(data: ContractSustainabilityAndPriceTransModel = null) {
        this.sustainabilityAndPriceTransForm = new FormGroup({
            sustainabilityPrice: new FormControl(data ? data.sustainabilityPrice : null, Validators.required),
            typeOfRevision: new FormControl(data ? data.typeOfRevision : null),
            globalRevision: new FormControl(data ? data.globalRevision : null),
            revisionAmount: new FormControl(data ? data.revisionAmount : null),
            revisionForm: new FormControl(data ? data.revisionForm : null),
        });

        const isQuote = this.contract.genericEngagement.format === GenericEngagementFormatEnum.QUOTE;
        const isReviewable = this.sustainabilityAndPriceTransForm.get('sustainabilityPrice').value === ContractSustainabilityPriceEnum.REVIEWABLE;


        if (isQuote && isReviewable) {
            this.sustainabilityAndPriceTransForm.get('typeOfRevision').setValue(ContractTypeOfRevisionEnum.FIXED, {emitEvent: false});
            this.sustainabilityAndPriceTransForm.get('typeOfRevision').disable({emitEvent: false});
        }

        if (!isQuote && isReviewable && !data.typeOfRevision) {
            this.sustainabilityAndPriceTransForm.get('typeOfRevision').setValue(ContractTypeOfRevisionEnum.FIXED);
        }


        this.sustainabilityAndPriceTransFormValueChanges();
    }

    initPriceRevisionForm(data: RecurringBasketModel = null) {

        this.priceRevisionForm = new FormGroup({
            endDate: new FormControl(),
            startDate: new FormControl(),
            recurrence: new FormControl(this.recurrenceEnum.find(x => x.value === data?.recurrence) || null, [Validators.required]),
            repeat: new FormControl(data ? data.repeat : null),
            activated: new FormControl(data ? data.activated : null),
            month: new FormControl(data ? this.monthEnum.find(x => x.value === data?.month) || null : null),
            isAnniversaryDate: new FormControl(data ? data.isAnniversaryDate : true)
        });

        this.priceRevisionForm.patchValue({
            startDate: this.contract.startValidityDate,
            endDate: this.contract.endValidityDate,
        });

        if (data && data.recurrence) {
            this.setRepeat(data.recurrence);
        }

        // disable all fields if contract.sustainabilityAndPriceTrans.sustainabilityPrice === ContractSustainabilityPriceEnum.FIRM
        if (this.sustainabilityAndPriceTransForm.get('sustainabilityPrice').value === ContractSustainabilityPriceEnum.FIRM) {
            this.priceRevisionForm.disable();
        } else {
            this.priceRevisionForm.enable();
        }
        this.priceRevisionFormValueChanges();
    }

    initDeliveryCostForm(data: ContractDeliveryCostModel) {
        this.deliveryCostForm = new FormGroup({
            deliveryCost: new FormControl(data ? data.deliveryCost : null, Validators.required),
            fromValue: new FormControl(data ? data.fromValue : null, Validators.required),
            packageValue: new FormControl(data ? data.packageValue : null, Validators.required),
        });

        if (data && data.deliveryCost) {

            if (data.deliveryCost === ContractDeliveryCostEnum.FREE_SHIPPING) {
                this.deliveryCostForm.get('fromValue').disable();
                this.deliveryCostForm.get('packageValue').disable();
            }

            if (data.deliveryCost === ContractDeliveryCostEnum.FREE_SHIPPING_FROM) {
                this.deliveryCostForm.get('fromValue').enable();
                this.deliveryCostForm.get('packageValue').enable();
            }

            if (data.deliveryCost === ContractDeliveryCostEnum.PACKAGE) {
                this.deliveryCostForm.get('fromValue').disable();
                this.deliveryCostForm.get('packageValue').enable();
            }

            if (data.deliveryCost === ContractDeliveryCostEnum.NOT_DEFINED) {
                this.deliveryCostForm.get('fromValue').disable();
                this.deliveryCostForm.get('packageValue').disable();
            }
        }
        this.deliveryCostFormValueChanges();
    }

    initDeliveryTimeForm(data: ContractDeliveryTimeModel) {
        this.deliveryTimeForm = new FormGroup({
            standardTheoreticalDeliveryValue: new FormControl(
               data && data.standardTheoreticalDeliveryValue
                  ? data.standardTheoreticalDeliveryValue
                  : this.defaultTimeDelay
            ),
            urgentTheoreticalDeliveryValue: new FormControl(
               data && data.urgentTheoreticalDeliveryValue
                  ? data.urgentTheoreticalDeliveryValue
                  : this.defaultTimeDelay
            ),
        });

        this.deliveryTimeForm.updateValueAndValidity();
    }


    initAdditionalCostForms(data: ContractAdditionalCostsModel[] = []) {
        console.log('data', data);
        this.additionalCostsForm = new FormArray([]);
        this.additionalCostAddForm = this.createAdditionalCostControl();
        data.forEach((x: ContractAdditionalCostsModel, index: number) => {
            const formGroup = this.createAdditionalCostFormGroup(x);
            this.additionalCostsForm.insert(index, formGroup);
        });
    }

    createAdditionalCostFormGroup(costsModel: ContractAdditionalCostsModel): FormGroup {

        const isFieldRequired = costsModel.additionalCost.type !== GenericAdditionalCostTypeEnum.TO_DEFINED;

        const formGroup = new FormGroup({
            additionalCost: new FormControl(costsModel.additionalCost, Validators.required),
            numValueHT: new FormControl(costsModel.numValueHT, isFieldRequired ? Validators.required : null),
            numValueTTC: new FormControl(costsModel.numValueTTC, isFieldRequired ? Validators.required : null),
            additionalInformation: new FormControl(costsModel.additionalInformation),
            type: new FormControl({value: costsModel.type, disabled: true}),
        });

        formGroup.controls.numValueHT.valueChanges.subscribe((value) => {
            formGroup.controls.numValueTTC.setValue(this.computeTva(value, costsModel.additionalCost.tva));
        });

        return formGroup;
    }

// Par exemple, vous pouvez créer une méthode privée pour mettre à jour les validateurs
    private updateValidatorsForNumValues(
        additionalCostModel: GenericAdditionalCostModel,
        numValueHTControl: AbstractControl,
        numValueTTCControl: AbstractControl
    ): void {
        // Si le type est TO_DEFINED, les champs ne sont pas requis
        if (additionalCostModel.type === GenericAdditionalCostTypeEnum.TO_DEFINED) {
            numValueHTControl.clearValidators();
            numValueTTCControl.clearValidators();
        } else {
            numValueHTControl.setValidators(Validators.required);
            numValueTTCControl.setValidators(Validators.required);
        }
        numValueHTControl.updateValueAndValidity({ emitEvent: false });
        numValueTTCControl.updateValueAndValidity({ emitEvent: false });
    }

    createAdditionalCostControl(): FormGroup {
        const formGroup = new FormGroup({
            additionalCost: new FormControl(null),
            numValueHT: new FormControl({ value: null, disabled: true }, Validators.required),
            numValueTTC: new FormControl({ value: null, disabled: true }, Validators.required),
            additionalInformation: new FormControl({ value: null, disabled: true })
        });

        // Récupération des contrôles
        const additionalCostControl = formGroup.get('additionalCost');
        const numValueHTControl = formGroup.get('numValueHT');
        const numValueTTCControl = formGroup.get('numValueTTC');
        const additionalInformationControl = formGroup.get('additionalInformation');

        // Abonnement aux changements de la valeur de "additionalCost"
        const additionalCostSubscription = additionalCostControl.valueChanges.subscribe(
            (value: GenericAdditionalCostModel) => {
                if (!value) {
                    return;
                }

                console.log('Nouvelle valeur sélectionnée pour additionalCost', value);

                // Active les contrôles désactivés
                numValueHTControl.enable({ emitEvent: false });
                numValueTTCControl.enable({ emitEvent: false });
                additionalInformationControl.enable({ emitEvent: false });

                // Met à jour les validateurs en fonction du type
                this.updateValidatorsForNumValues(value, numValueHTControl, numValueTTCControl);

                // Abonnement pour mettre à jour numValueTTC lorsque numValueHT change
                const numValueHTSubscription = numValueHTControl.valueChanges.subscribe((htValue: number) => {
                    // On calcule la valeur TTC en fonction du HT et du taux de TVA du modèle
                    const ttcValue = htValue != null ? this.computeTva(htValue, value.tva) : null;
                    numValueTTCControl.setValue(ttcValue, { emitEvent: false });
                });

                // Ajoute le formGroup créé à la collection/formArray de coûts additionnels
                this.additionalCostsForm.insert(0, formGroup);

                // Pour éviter de multiples abonnements, on se désabonne du contrôle "additionalCost"
                additionalCostSubscription.unsubscribe();

                // Crée un nouveau formGroup pour l'ajout d'un autre coût additionnel
                this.additionalCostAddForm = this.createAdditionalCostControl();
                this.additionalCostAddForm.get('numValueHT').disable({ emitEvent: false });
                this.additionalCostAddForm.get('numValueTTC').disable({ emitEvent: false });
                this.additionalCostAddForm.get('additionalInformation').disable({ emitEvent: false });

                // Met à jour la liste des coûts disponibles en retirant celui déjà sélectionné
                this.availableGenericAdditionalCosts = this.availableGenericAdditionalCosts.filter(
                    x => x._id !== value._id
                );
            }
        );

        return formGroup;
    }


    removeAdditionalCostControl(index: number) {
        const additionalCost = this.additionalCostsForm.at(index).get('additionalCost').value;

        this.additionalCostsForm.removeAt(index);
        // get the additionalCost from the formArray at index and push it to availableGenericAdditionalCosts

        this.availableGenericAdditionalCosts.push(additionalCost);
    }


    deliveryCostFormValueChanges() {
        this.subscription.add(
            this.deliveryCostForm.get('deliveryCost').valueChanges.subscribe(value => {
                this.deliveryCostForm.get('fromValue').setValue(null);
                this.deliveryCostForm.get('packageValue').setValue(null);

                if (value === ContractDeliveryCostEnum.FREE_SHIPPING) {
                    this.deliveryCostForm.get('fromValue').disable();
                    this.deliveryCostForm.get('packageValue').disable();
                }

                if (value === ContractDeliveryCostEnum.FREE_SHIPPING_FROM) {
                    this.deliveryCostForm.get('fromValue').enable();
                    this.deliveryCostForm.get('packageValue').enable();
                }

                if (value === ContractDeliveryCostEnum.PACKAGE) {
                    this.deliveryCostForm.get('fromValue').disable();
                    this.deliveryCostForm.get('packageValue').enable();
                }

                if (value === ContractDeliveryCostEnum.NOT_DEFINED) {
                    this.deliveryCostForm.get('fromValue').disable();
                    this.deliveryCostForm.get('packageValue').disable();
                }

            })
        );
        this.subscription.add(
            this.deliveryCostForm.get('fromValue').valueChanges.subscribe(value => {
                if (value === 0) {
                    this.deliveryCostForm.get('packageValue').disable();
                    this.deliveryCostForm.get('packageValue').setValue(null);
                }
            })
        );
    }

    priceRevisionFormValueChanges() {
        this.subscription.add(
            this.priceRevisionForm.get('recurrence').valueChanges.subscribe(value => {
                if (value) {
                    this.setForm(value);
                }
            })
        );
    }

    private toggleFormField(fieldName: string, enable: boolean, validators = []) {
        const field = this.sustainabilityAndPriceTransForm.get(fieldName);
        if (enable) {
            field.enable();
            field.setValidators(validators);
        } else {
            field.disable();
            field.setValue(null);
            field.setValidators(null);
        }
        field.updateValueAndValidity();
    }

    sustainabilityAndPriceTransFormValueChanges() {
        this.subscription.add(
            this.sustainabilityAndPriceTransForm.get('sustainabilityPrice').valueChanges
                .pipe(
                    tap(value => {
                        const isQuote = this.contract.genericEngagement.format === GenericEngagementFormatEnum.QUOTE;
                        const isReviewable = value === ContractSustainabilityPriceEnum.REVIEWABLE;
                        const shouldEnable = isReviewable;

                        if (!isReviewable) {
                            // clear all priceRevisionForm fields
                            this.priceRevisionForm.reset();
                        }

                        this.priceRevisionForm[shouldEnable ? 'enable' : 'disable']();
                        this.toggleFormField('typeOfRevision', shouldEnable, [Validators.required]);

                        if (isReviewable && isQuote) {
                            this.sustainabilityAndPriceTransForm.get('typeOfRevision').disable();
                            this.sustainabilityAndPriceTransForm.get('typeOfRevision').setValue(ContractTypeOfRevisionEnum.FIXED);
                        }
                    })
                ).subscribe()
        );

        this.subscription.add(
            this.sustainabilityAndPriceTransForm.get('typeOfRevision').valueChanges
                .pipe(
                    startWith(this.sustainabilityAndPriceTransForm.get('typeOfRevision').value),
                    tap(value => {

                        // By default, globalRevision is true if typeOfRevision is FIXED and creationStep is 3
                        if (this.sustainabilityAndPriceTransForm
                            .get('typeOfRevision').value === ContractTypeOfRevisionEnum.FIXED && this.contract.creationStep === 3) {
                            this.sustainabilityAndPriceTransForm.get('globalRevision').setValue(true);
                        }

                        switch (value) {
                            case ContractTypeOfRevisionEnum.FIXED:
                                this.toggleFormField('globalRevision', true);
                                this.toggleFormField('revisionForm', false);
                                break;
                            case ContractTypeOfRevisionEnum.VARIABLE:
                                this.toggleFormField('revisionForm', true, [Validators.required]);
                                this.toggleFormField('globalRevision', false);
                                break;
                            default:
                                this.toggleFormField('globalRevision', false);
                                this.toggleFormField('revisionForm', false);
                                break;
                        }
                    })
                )
                .subscribe()
        );

        this.subscription.add(
            this.sustainabilityAndPriceTransForm.get('globalRevision').valueChanges
                .pipe(
                    startWith(this.sustainabilityAndPriceTransForm.get('globalRevision').value),
                    tap(value => {
                        const shouldEnable = !!value;
                        this.toggleFormField('revisionAmount', shouldEnable, [Validators.required]);
                    })
                )
                .subscribe()
        );
    }

    setRepeat(value: RecurrenceEnum) {
        const startDate = moment(this.contract.startValidityDate);
        const dayOfMonth: number = startDate.date();
        const monthNumber: number = startDate.month();

        switch (value) {
            case RecurrenceEnum.Weekly:
                this.repeatLabel = 'Semaine(s)';
                this.autoCompleteValue = this.dayEnum;
                this.autoCompleteType = 'multiple';
                this.autoCompleteDisplayProperty = 'label';
                this.autoCompleteIsActive = true;
                this.anniversaryCheckboxActive = false;

                // get index of day enum

                if (this.contract.priceRevision?.activated) {
                    this.priceRevisionForm.controls.activated
                        .setValue(this.contract.priceRevision?.activated?.map(x => this.dayEnum[x])?.filter(x => x !== undefined) || null);
                }


                break;
            case RecurrenceEnum.Monthly:
                this.repeatLabel = 'Mois';
                this.autoCompleteValue = Array.from({length: 31}, (_, index) => index + 1);
                this.autoCompleteDisplayProperty = null;
                this.autoCompleteType = 'autocomplete';
                this.autoCompleteIsActive = true;

                this.anniversaryCheckboxActive = true;
                this.priceRevisionForm.controls.isAnniversaryDate.setValue(true);

                this.autoCompleteAnniversaryDate();


                break;
            case RecurrenceEnum.Yearly:
                this.repeatLabel = 'Année(s)';
                this.autoCompleteValue = Array.from({length: 31}, (_, index) => index + 1);
                this.autoCompleteDisplayProperty = null;
                this.autoCompleteType = 'autocomplete';
                this.autoCompleteIsActive = true;
                this.autocompleteMonth = true;

                if (this.contract.priceRevision?.activated) {
                    this.priceRevisionForm.controls.activated.setValue(this.contract?.priceRevision?.activated);
                }

                this.anniversaryCheckboxActive = true;
                this.priceRevisionForm.controls.isAnniversaryDate.setValue(true);

                this.autoCompleteAnniversaryDate();
                break;
            default:
                this.repeatLabel = 'Jour(s)';
                this.anniversaryCheckboxActive = false;
        }
    }

    setForm(x) {
        if (x.value === RecurrenceEnum.Daily) {
            this.priceRevisionForm.get('activated').disable();
            this.autoCompleteIsActive = false;
            this.autocompleteMonth = false;
            this.displayRepeat = false;
        } else if (x.value === RecurrenceEnum.Yearly) {
            this.autocompleteMonth = true;
            this.priceRevisionForm.get('activated').enable();
            this.displayRepeat = true;
        } else {
            this.priceRevisionForm.get('activated').enable();
            this.displayRepeat = true;
            this.autocompleteMonth = false;
        }

        this.priceRevisionForm.get('repeat').setValue(1);
        this.priceRevisionForm.get('activated').setValue(null);
        this.priceRevisionForm.get('activated').setValue(null);
        if (x) {
            this.setRepeat(x.value);
        }
    }


    // Date Filter for the datepicker
    revisionStartDateFilter = (d: Date | null): boolean => {
        const contractStartDate = this.contract.startValidityDate;
        const contractEndDate = this.contract.endValidityDate;

        return moment(d).isBetween(contractStartDate, contractEndDate);


    }

    revisionEndDateFilter = (d: Date | null): boolean => {
        // end Date is between contract start date and contract end date and is after start date
        const contractStartDate = this.contract.startValidityDate;
        const contractEndDate = this.contract.endValidityDate;
        const startDate = this.priceRevisionForm.get('startDate').value;

        return moment(d).isBetween(contractStartDate, contractEndDate) && moment(d).isAfter(startDate);

    }

    isCgaNotValidated() {
        if (this.sustainabilityAndPriceTransForm.invalid) {
            return true;
        }

        if (this.priceRevisionForm.invalid) {
            return true;
        }

        if (this.deliveryCostForm.invalid) {
            return true;
        }

        if (this.additionalCostsForm.invalid) {
            console.log('this.additionalCostsForm', this.additionalCostsForm);
            return true;
        }

        return false;

    }

    async switchToUpdateMode() {

        this.dialog.open(DialogConfirmComponent, {
            data: {
                description: 'Êtes-vous sûr de vouloir modifier les CGA ? Attention cela peut avoir des conséquences sur les autres étapes.',
                cancelText: 'ANNULER',
                title: 'Modifier les CGA',
                validText: 'CONFIRMER',
                call$: this.contractService.updateStep(this.id, {creationStep: 3})
            }
        });

    }


    async updateStep(step: number) {
        const entity = await this.contractService.entity;

        this.contractService.updateStep(entity._id, {creationStep: step}).subscribe(res => {
            if (step === 4) {
                this.router.navigate(['/management/engagement/', entity._id, 'catalog']).then();
            }
        });
    }

    getOccurrenceText(recurringBasket: RecurringBasketModel) {
        return recurrenceEnumTranslationOccurence(recurringBasket);
    }

    setAnniversaryDate() {
        if (this.priceRevisionForm.get('isAnniversaryDate').value) {
            this.priceRevisionForm.get('activated').setValue(null);
            this.priceRevisionForm.get('month').setValue(null);
        } else {
            this.autoCompleteAnniversaryDate();
        }
    }

    autoCompleteAnniversaryDate() {
        const startDate = moment(this.contract.startValidityDate);
        const dayOfMonth: number = startDate.date();
        const monthNumber: number = startDate.month();

        // Si 29 février, changer pour le jour précédent
        if (dayOfMonth === 29 && monthNumber === 1) {
            this.priceRevisionForm.get('activated').setValue(28);
        } else {
            this.priceRevisionForm.get('activated').setValue(dayOfMonth);
        }

        this.priceRevisionForm.get('month').setValue(this.monthEnum[monthNumber]);
    }

    getDefaultOtherExpensesId() {
        this.customerGroupService.getCurrent().subscribe((x: any) => {
            this.defaultOtherExpenses = x.data.comptable.otherExpensesType;
        });
    }

    isOtherExpensesType(additionalCost: GenericAdditionalCostModel): boolean {

        console.log('additionalCost', additionalCost);
        console.log('this.defaultOtherExpenses', this.defaultOtherExpenses);
        return additionalCost?.typeAdditionalCosts?._id === this.defaultOtherExpenses?._id;
    }
}
