import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import icClose from '@iconify/icons-ic/twotone-close';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'form-modal-delete-cofirm-base',
    template: `
        <ng-container>
            <div mat-dialog-title fxLayout='row' fxLayoutAlign='space-between center'>
                <div>{{ title }}</div>
                <button type='button' mat-icon-button (click)='close(false)' tabindex='-1'>
                    <mat-icon [icIcon]='icClose'></mat-icon>
                </button>
            </div>

            <mat-dialog-content>
                <p>{{ message }}</p>
            </mat-dialog-content>

            <mat-dialog-actions align='end'>
                <button mat-button (click)='close(false)'>NON</button>
                <button mat-button color='primary' [loading]='loading' (click)='close(true)'>OUI</button>
            </mat-dialog-actions>
        </ng-container>
    `
})

export class FormModalConfirmBaseComponent implements OnInit {

    title: string;
    message: string;
    loading = false;


    icClose = icClose;


    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {
            title: string, message: string},
        private dialog: MatDialogRef<FormModalConfirmBaseComponent>
    ) {
        if (data) {
            this.title = data.title;
            this.message = data.message;
        }
    }

    ngOnInit() {
    }

    close(bool: boolean) {
        this.dialog.close(bool);
    }
}
