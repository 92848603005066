import { Pipe, PipeTransform } from "@angular/core";

export enum DayEnum {
    Monday = 'monday',
    Tuesday = 'tuesday',
    Wednesday = 'wednesday',
    Thursday = 'thursday',
    Friday = 'friday',
    Saturday = 'saturday',
    Sunday = 'sunday',
}

export class DayTranslation {
    static translate(day: DayEnum): string {
        switch (day) {
            case DayEnum.Monday:
                return 'Lundi';

            case DayEnum.Tuesday:
                return 'Mardi';

            case DayEnum.Wednesday:
                return 'Mercredi';

            case DayEnum.Thursday:
                return 'Jeudi';

            case DayEnum.Friday:
                return 'Vendredi';

            case DayEnum.Saturday:
                return 'Samedi';

            case DayEnum.Sunday:
                return 'Dimanche';

            default:
                return null;
        }
    }
}

@Pipe({ name: 'dayTranslation', pure: false })
export class DayTranslationPipe implements PipeTransform {
    transform(day: DayEnum): string {
        return DayTranslation.translate(day);
    }
}

export const dayEnumTranslation = (day: DayEnum) => {
    return {
        value: day,
        label: DayTranslation.translate(day)
    };
}


