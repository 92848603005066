import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
    FormModalBaseComponent,
} from '../../../../../../../core/base/components/form-modal-base/form-modal-base.component';
import { TicketModel } from '../../../../../../../core/models/ticket.model';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TicketService } from '../../../ticket.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { concatMap, debounce, delay, from, interval, Observable, of } from 'rxjs';
import { EstablishmentModel } from '../../../../../../../core/models/establishment.model';
import { EstablishmentService } from '../../../../../management/establishment/establishment.service';
import { map } from 'rxjs/operators';
import { BuildingModel } from '../../../../../../../core/models/building.model';
import { BuildingService } from '../../../../../management/building/building.service';
import { BuildingRoomModel } from '../../../../../../../core/models/building-room.model';
import { EquipmentModel } from '../../../../../../../core/models/equipment.model';
import { EquipmentService } from '../../../../../management/equipment/equipment.service';
import { TicketStatusEnum } from '../../../../../../../core/enums/ticketStatusEnum';
import { SnackbarService } from '../../../../../../../core/services/snackbar.service';
import moment from 'moment';
import { scaleIn400ms } from '../../../../../../../../@vex/animations/scale-in.animation';
import { fadeInRight400ms } from '../../../../../../../../@vex/animations/fade-in-right.animation';
import { stagger40ms } from '../../../../../../../../@vex/animations/stagger.animation';
import { fadeInUp400ms } from '../../../../../../../../@vex/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from '../../../../../../../../@vex/animations/scale-fade-in.animation';
import { DocumentElement, GedService } from '../../../../../admin/playground/docuware-playground/ged.service';
import { GED_CONFIG } from '../../../../../../../core/enums/ged-type.enum';
import { EquipmentTypeService } from '../../../../../settings/equipment-type/equipment-type.service';
import { EquipmentTypeModel } from '../../../../../../../core/models/equipment-type.model';
import { TicketTypeEnum } from '../../../../../../../core/enums/ticket-type.enum';
import { GenericEquipmentModel } from '../../../../../../../core/models/generic-equipment.model';
import { GenericEquipmentService } from '../../../../../settings/generic-equipment/generic-equipment.service';
import {
    EquipmentCreateUpdateDeleteComponent,
} from '../../../../../management/equipment/equipment-create-update-delete/equipment-create-update-delete.component';
import { TicketIncidentSubStatusEnum } from '../../../../../../../core/enums/ticketIncidentSubStatus';
import {
    NotificationService,
} from '../../../../../../../core/layout/application-layout/notification/notification.service';

@Component({
    selector: 'vex-ticket-equipment-create-update-delete',
    templateUrl: './ticket-equipment-create-update-delete.component.html',
    styleUrls: [
        './ticket-equipment-create-update-delete.component.scss',
        '../../../../../../../../../node_modules/quill/dist/quill.snow.css',
        '../../../../../../../../@vex/styles/partials/plugins/_quill.scss',
    ],
    encapsulation: ViewEncapsulation.None,
    animations: [
        scaleIn400ms,
        fadeInRight400ms,
        stagger40ms,
        fadeInUp400ms,
        scaleFadeIn400ms
    ]
})
export class TicketEquipmentCreateUpdateDeleteComponent extends FormModalBaseComponent<TicketModel> implements OnInit {

    establishments$: Observable<EstablishmentModel[]>;
    buildings$: Observable<BuildingModel[]>;
    rooms$: Observable<BuildingRoomModel[]>;
    equipments$: Observable<EquipmentModel[]>;
    genericEquipment$: Observable<GenericEquipmentModel[]>;
    equipmentTypes$: Observable<EquipmentTypeModel[]>;
    private equipmentFilter: any = {};
    isLinear = true;
    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    thirdFormGroup: FormGroup;
    ticketStatusEnum = TicketStatusEnum;
    isEnterKeyPressed = false;


    files: DocumentElement[] = [];
    canUpload = false;
    loadingSave = false;
    message = '';
    customModule = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            [{header: 1}, {header: 2}],               // custom button values
            [{list: 'ordered'}, {list: 'bullet'}],
            [{indent: '-1'}, {indent: '+1'}],          // outdent/indent
            [{align: []}],
            ['clean'],                                         // remove formatting button
            ['link']                         // link and image, video
        ]
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        public dialog: MatDialog,
        public service: TicketService,
        public dialogRef: MatDialogRef<FormModalBaseComponent<TicketModel>>,
        private establishmentService: EstablishmentService,
        private buildingService: BuildingService,
        private equipmentService: EquipmentService,
        private genericEquipmentService: GenericEquipmentService,
        public snackbarService: SnackbarService,
        private gedService: GedService,
        private equipmentTypeService: EquipmentTypeService,
        private notificationService: NotificationService,
    ) {
        super(data, dialogRef, service);
    }

    ngOnInit(): void {
        console.log('this.data', this.data);
        this.defaults = this.data?.defaults;

        super.ngOnInit();
        this.gedService.setUrl(GED_CONFIG.ticket.apiUrl);
        this.gedService.setType(GED_CONFIG.ticket.gedType);
        this.initData();
        this.initForm();
    }

    initData(): void {
        this.autocompleteEstablishment();
        this.autocompleteEquipment();
        this.autocompleteEquipmentType();
        this.autocompleteGenericEquipment();
        this.gedService.testConnection().subscribe((res) => {
            this.canUpload = true;
        }, error => {
            this.canUpload = false;
        });
    }

    initForm(): void {
        // Initialize form controls for both creation and update mode
        this.firstFormGroup = new FormGroup({
            genericEquipment: new FormControl(this.defaults?.genericEquipment),
            equipment: new FormControl(this.defaults?.equipment, Validators.nullValidator),
        });

        this.secondFormGroup = new FormGroup({
            establishment: new FormControl(this.defaults?.establishment),
            building: new FormControl(this.defaults?.location?.building),
            room: new FormControl(this.defaults?.location?.room),
            locationDescription: new FormControl(this.defaults?.locationDescription),
        });

        this.thirdFormGroup = new FormGroup({
            description: new FormControl(this.defaults?.description ?? '', Validators.required),
            files: new FormControl()
        });


        this.secondFormGroup.controls.building.disable();
        this.secondFormGroup.controls.room.disable();


        this.firstFormGroup.controls.genericEquipment.valueChanges.subscribe((value) => {

            // ticket-filter equipment
            delete this.equipmentFilter.genericEquipment;
            delete this.equipmentFilter.establishment;
            this.autocompleteEquipment();
            if (value) {
                // ticket-filter equipment

                this.equipmentFilter.genericEquipment = value._id;
                this.autocompleteEquipment();

                // clear equipment
                if (this.firstFormGroup.controls.equipment.value && this.firstFormGroup.controls.equipment.value.genericEquipment._id !== value._id) {
                    this.firstFormGroup.controls.equipment.setValue(null);
                }

            }
        });


        this.firstFormGroup.controls.equipment.valueChanges.subscribe((value) => {

            // ticket-filter equipment
            if (value) {
                // ticket-filter equipment
                if (value?.establishment) {
                    this.secondFormGroup.controls.establishment.setValue(value?.establishment);
                }

                if (value?.location?.length > 0) {
                    this.secondFormGroup.controls.building.setValue(value?.location[0]?.building);
                    this.secondFormGroup.controls.room.setValue(value?.location[0]?.room);
                    this.autocompleteBuilding();
                    this.autocompleteRoom();
                }
            }
        });

        this.secondFormGroup.controls.establishment.valueChanges.subscribe((value) => {
            // disable building field and reset value
            this.secondFormGroup.controls.building.setValue(null);
            this.secondFormGroup.controls.building.disable();

            // disable room field and reset value
            this.secondFormGroup.controls.room.setValue(null);
            this.secondFormGroup.controls.room.disable();

            // ticket-filter equipment
            delete this.equipmentFilter.establishment;
            if (value) {
                // ticket-filter equipment
                this.equipmentFilter.establishment = value?._id;

                // ticket-filter building
                this.autocompleteBuilding('');

                // enable building field
                this.secondFormGroup.controls.building.enable();
            }
        });

        this.secondFormGroup.controls.building.valueChanges.subscribe((value) => {
            // disable room field and reset value
            this.secondFormGroup.controls.room.setValue(null);
            this.secondFormGroup.controls.room.disable();


            // ticket-filter equipment
            if (value) {
                // ticket-filter room
                this.autocompleteRoom('');

                this.secondFormGroup.controls.room.enable();
            }
        });


        if (this.isUpdateMode()) {
            if (this.defaults?.location?.establishment) {
                this.equipmentFilter.establishment = this.defaults?.location?.establishment?._id;
                this.secondFormGroup.controls.building.enable();
                this.autocompleteBuilding('');
            }
            if (this.defaults?.location?.building) {
                this.equipmentFilter.building = this.defaults?.location?.building?._id;
                this.secondFormGroup.controls.room.enable();
                this.autocompleteRoom('');
            }
            if (this.defaults?.location?.room) {
                this.secondFormGroup.controls.room.setValue(this.defaults?.location?.room);
            }

            if (this.defaults?.equipment) {
                this.defaults.equipment.customDisplay = `${this.defaults?.equipment.reference} - ${this.defaults?.equipment.genericEquipment?.label}`;
                this.secondFormGroup.controls.equipment.setValue(this.defaults?.equipment);
            }
        }
    }

    autocompleteEstablishment(search: string = '') {
        this.establishments$ = this.establishmentService.findAll(null, null, 'label', 'asc', search, {allEstablishments: true}).pipe(map(x => x.data));
    }

    autocompleteGenericEquipment(search: any = '') {
        this.genericEquipment$ = this.genericEquipmentService.findAll(null, null, 'label', 'asc', search).pipe(map(x => x.data));
    }

    autocompleteEquipmentType(search: string = '') {
        this.equipmentTypes$ = this.equipmentTypeService.findAll(null, null, 'label', 'asc', search).pipe(map(x => x.data));
    }

    autocompleteEquipment(search: string = '') {
        this.equipments$ = this.equipmentService.findAll(null, null, 'label', 'asc', search, {
            ...this.equipmentFilter,
            allEquipment: true
        }).pipe(map(res => {
            return res.data.map(x => {
                if (x.genericEquipment) {
                    x.customDisplay = `${x.reference} - ${x.genericEquipment?.label}`;
                } else {
                    x.customDisplay = x.reference;
                }
                return x;
            });
        }));
    }

    autocompleteBuilding(search: string = '') {
        this.buildings$ = this.buildingService.findAll(null, null, 'label', 'asc', search, {
            establishmentId: this.equipmentFilter.establishment,
            allBuildings: true,
            isStatusNeeded: true
        }).pipe(map(x => x.data));
    }

    autocompleteRoom(search: any = '') {
        this.rooms$ = this.buildingService.findAllRoom(this.secondFormGroup.controls.building.value._id, null, null, 'label', 'asc', search).pipe(map(res => res.data));
    }

    equipmentSelected(event: any) {
        // L'événement contient une propriété `option` qui est l'option sélectionnée
        const equipment = event?.option?.value;

        if (equipment && equipment.genericEquipment) {
            // Met à jour la valeur du contrôle 'equipmentType'
            this.firstFormGroup.get('genericEquipment')?.setValue(equipment.genericEquipment);
            // lock genericEquipment
        }
    }


    hasValue() {
        return [this.firstFormGroup, this.secondFormGroup, this.thirdFormGroup].some(form =>
            Object.values(form.controls).some(control => control.value)
        );
    }

    mapData(status: TicketStatusEnum) {
        const firstForm = this.firstFormGroup.getRawValue();
        const secondForm = this.secondFormGroup.getRawValue();
        const thirdForm = this.thirdFormGroup.getRawValue();
        let building;
        let establishment;
        let room;

        console.log('secondForm.building', firstForm?.equipment);

        if (secondForm?.building) { building = {
            _id: secondForm?.building?._id,
            technicalIdentifier: secondForm?.building?.technicalIdentifier,
            label: secondForm?.building?.label
        };
        }
        if (secondForm?.establishment) { establishment = {
            _id: secondForm?.establishment?._id,
            technicalIdentifier: secondForm?.establishment?.technicalIdentifier,
            label: secondForm?.establishment?.label
        };
        }
        if (secondForm?.room) { room = {
            _id: secondForm?.room?._id,
            label: secondForm?.room?.label,
            nbOfBed: secondForm?.room?.nbOfBed,
            surface: secondForm?.room?.surface,
            stage: secondForm?.room?.stage
        };
        }

        this.defaults.location = {establishment, building, room: secondForm?.room};
        this.defaults.equipment = firstForm?.equipment;
        this.defaults.equipmentType = firstForm?.equipmentType;
        this.defaults.establishment = establishment;
        this.defaults.genericEquipment = firstForm?.genericEquipment;

        this.defaults.description = thirdForm?.description;
        this.defaults.locationDescription = secondForm?.locationDescription;
        if (status === TicketStatusEnum.WAITING) { this.defaults.dateSend = moment().toDate(); }
        this.defaults.status = status;
        this.defaults.type = TicketTypeEnum.INCIDENT;
    }

    toDraft() {
        if (!this.hasValue()) {
            this.snackbarService.danger('Pour enregistrer le brouillon, veuillez renseigner au moins une valeur.');
            return;
        }
        this.createItem(true, TicketStatusEnum.DRAFT);
    }

    afterCreateItem(result?: any, error?: any) {
        this.close(result);
    }

    createItem(toDraft?: boolean, status?: TicketStatusEnum): void {
        this.loading = true;

        status ? this.mapData(status) : this.mapData(TicketStatusEnum.WAITING);

        if (!toDraft) {
            if (!this.thirdFormGroup.valid) {
                if (this.thirdFormGroup.controls.description.invalid) { this.snackbarService.danger('' +
                    'Veuillez renseigner une description.');
                }
                this.thirdFormGroup.markAllAsTouched();
                this.loading = false;
                return;
            }
            this.defaults.incidentSubStatus = TicketIncidentSubStatusEnum.WAITING_VALIDATION;
        }

        if (this.service.checkInterventionConfirmation(this.defaults) || toDraft) {
            const sub = this.service.create(this.defaults)
                .subscribe(
                    result => {
                        this.defaults._id = result?.data?._id;
                        this.message = result.message;
                        this.uploadFile();
                        // this.notificationService.sendConfirmIntervention(result?.data?._id).subscribe();
                        this.afterCreateItem(result, null);
                        this.snackbarService.success('Ticket créé avec succès');
                    }, error => {
                        this.afterCreateItem(null, error);
                        this.firstFormGroup.markAllAsTouched();
                        this.secondFormGroup.markAllAsTouched();
                        this.thirdFormGroup.markAllAsTouched();
                        this.loading = false;
                    }
                );
            this.subscription.add(sub);

        } else {
            this.snackbarService.danger('Il manque certaines informations pour confirmer l\'intervention. Assurez-vous d\'avoir bien renseigné l\'équipement générique, l\'établissement, l\'équipement spécifique et la description de l\'emplacement. Merci de votre compréhension.');

        }


    }

    updateItem(): void {
        this.loading = true;

        this.mapData(this.defaults.status);

        const sub = this.service.update(this.defaults).subscribe(
            result => {
                this.uploadFile();
                this.close(true);
                this.loading = false;
            }, error => {
                this.close(false);
                this.setErrorsMessages(error.error.errors);
                this.loading = false;
            });
        this.subscription.add(sub);
        // } else {
        //     this.form.markAllAsTouched();
        //     this.loading = false;
        // }
    }

    uploadFile() {
        this.loadingSave = true;
        from(this.files).pipe(
            concatMap(item => of(item).pipe(delay(500)))
        ).subscribe(document => {
                document.status = 'ON_LOAD';
                const documentElement = this.gedService.uploadDocument(document, {predikId: this.defaults?._id}, this.loadingSave);

                documentElement.progress.pipe(debounce(() => interval(100)))
                    .subscribe(value => {
                        if (value === 500) {
                            this.loadingSave = false;
                        }
                        if (this.files[this.files.length - 1].id === document.id && value === 101) {
                            this.afterCreateItem(this.defaults, null);
                            // this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Create, this.message);
                        }
                    });
            },
            error => console.error('error', error),
        );
    }

    async createEquipment() {
        this.dialog.open(EquipmentCreateUpdateDeleteComponent, {
            data: {
                mode: this.CrudModeEnum.Create,
            }
        }).afterClosed().subscribe((equipment: EquipmentModel) => {
            if (equipment) {
                if (equipment._id) {
                    equipment.customDisplay = `${equipment.reference} - ${equipment.genericEquipment?.label}`;
                    this.firstFormGroup.get('equipment')?.setValue(equipment);
                }
            }
        });
    }

    isReferent() {
        return this.service.isReferentEngagement();
    }
    onKeyDown(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.isEnterKeyPressed = true;
            event.preventDefault();
        }
    }

    onKeyUp(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.isEnterKeyPressed = false;
        }
    }
}
