import {Component, Input, OnInit} from '@angular/core';
import {scaleIn400ms} from '../../../../../@vex/animations/scale-in.animation';
import {fadeInRight400ms} from '../../../../../@vex/animations/fade-in-right.animation';
import {stagger40ms} from '../../../../../@vex/animations/stagger.animation';
import {fadeInUp400ms} from '../../../../../@vex/animations/fade-in-up.animation';
import {scaleFadeIn400ms} from '../../../../../@vex/animations/scale-fade-in.animation';
import {Observable} from 'rxjs';
import {DetailsLineTypeInterface} from '../../interfaces/details-line-type.interface';
import icDone from '@iconify/icons-ic/twotone-done';
import icClose from '@iconify/icons-ic/twotone-close';
import {DateFormat} from '../../../helpers/date.helper';
import {StringHelper} from '../../../helpers/string.helper';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {ENUM_PERMISSIONS} from '../../../enums/permission.enum';

@Component({
    selector: 'details-line-base',
    templateUrl: 'details-line-base.component.html',
    animations: [
        scaleIn400ms,
        fadeInRight400ms,
        stagger40ms,
        fadeInUp400ms,
        scaleFadeIn400ms
    ],
    styleUrls: ['details-line-base.component.scss']
})

export class DetailsLineBaseComponent implements OnInit {

    icDone = icDone;
    icClose = icClose;

    DateFormat = DateFormat;

    @Input() entity$: Observable<any>;
    @Input() label;
    @Input() extraHtml;
    @Input() hint;
    @Input() additionalDisplay;
    @Input() loading = false;
    @Input() type: typeof DetailsLineTypeInterface = 'text';

    @Input() titleCase = false;
    @Input() link;
    @Input() linkPermission: ENUM_PERMISSIONS[] | ENUM_PERMISSIONS;

    constructor(private router: Router, private authService: AuthService) {
    }

    ngOnInit() {
        this.toTitleCase();

        if (Array.isArray(this.label)) {
            this.label = this.label.join(', ');
        }

        if (this.linkPermission) {
            this.authService.getCurrentUserPermissions$().subscribe(permissions => {
                // if (!permissions.some()) {
                //     this.link = null;
                // }
                if (this.linkPermission instanceof Array) {
                    if (!permissions.some((x: ENUM_PERMISSIONS) => this.linkPermission.includes(x))) {
                        this.link = null;
                    }
                } else {
                    if (!permissions.includes(this.linkPermission)) {
                        this.link = null;
                    }
                }
            });
        }
    }

    toTitleCase() {
        if (this.titleCase) {
            this.label = StringHelper.toTitleCase(this.label);
        }
    }

    round(element) {
        return Math.round(element * 100) / 100;
    }

    async navigateTo() {
        if (this.link && !this.link.includes(null)) {

            // external links
            if (this.isExternalLink(this.link)) {
                window.open(this.link, '_blank');
            } else {
                // internal links
                await this.router.navigateByUrl(this.link);
            }
        }
    }

    private isExternalLink(link: string): boolean {
        return link.startsWith('http') || link.startsWith('https') || link.startsWith('www');
    }
}
