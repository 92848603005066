<div *ngIf='(service.entity$ | async) as equipment; else loading' class="mt-6 flex flex-col md:flex-row md:items-start">
    <div class='flex-auto'>
        <div id="equipment-resume-details-card" class='card'>
            <div class='px-gutter py-4 border-b' fxLayout='row' fxLayoutAlign='space-between center'>
                <h2 class='title m-0'>Détails</h2>

                <div>
                    <button color='primary' mat-raised-button type='button'  (click)='updateItem()' *hasPermission="[ENUM_PERMISSIONS.UPDATE_EQUIPMENT_INFO]">MODIFIER</button>
                </div>
            </div>
            <div class="px-gutter py-4" gdColumns="1fr 1fr" gdColumns.xs="1fr" gdGap="16px">
                <details-line-base
                    hint='Identifiant technique'
                    [label]='equipment.technicalIdentifier'
                >
                </details-line-base>

                <details-line-base
                    *ngIf='equipment?.reference'
                    hint='Référence équipement'
                    [label]='equipment.reference'
                >
                </details-line-base>


                <details-line-base
                    hint='Etablissement'
                    [label]='equipment.establishment?.label'
                    [link]="'/management/establishment/' + equipment.establishment?._id"
                    [linkPermission]="ENUM_PERMISSIONS.ACCESS_EQUIPMENT_INFO_ESTABLISHMENT"
                >
                </details-line-base>

                <details-line-base
                    hint="Référent de l'équipement"
                    [label]='equipment.contactIntern?.fullName'
                    [link]="'/management/user-profile/' + equipment.contactIntern?._id"
                    [linkPermission]="ENUM_PERMISSIONS.ACCESS_EQUIPMENT_INFO_REFERENT"
                >
                </details-line-base>

                <details-line-base
                    hint='Numéro de série'
                    [label]='equipment.serialNumber'
                >
                </details-line-base>

                <details-line-base
                    hint='Marque'
                    [label]='equipment.mark'
                >
                </details-line-base>

                <details-line-base
                        *ngIf='equipment.model'
                        hint='Modèle'
                        [label]='equipment?.model'
                >
                </details-line-base>

                <details-line-base
                    *ngIf='equipment.genericEquipment'
                    hint='Equipement générique'
                    [label]='equipment.genericEquipment?.label'
                >
                </details-line-base>

                <details-line-base
                    hint='Mise en service'
                    [label]='equipment.commissioningDate'
                    type='date'
                >
                </details-line-base>

                <details-line-base
                    hint='Fin de garantie constructeur'
                    [label]='equipment.endManufacturerDate'
                    type='date'
                >
                </details-line-base>

                <details-line-base
                    hint="Achat"
                    [label]='equipment.purchaseDate'
                    type='date'
                >
                </details-line-base>

                <details-line-base
                    *ngIf='equipment?.valueAmortization >= 500'
                    hint="Début d'amortissement"
                    [label]='equipment.startAmortizationDate'
                    type='date'
                >
                </details-line-base>
                <details-line-base
                    *ngIf='equipment?.valueAmortization >= 500'
                    hint="Fin d'amortissement"
                    [label]='equipment.endAmortizationDate'
                    type='date'
                >
                </details-line-base>

                <details-line-base
                    hint="Prix acquisition HT"
                    [label]="equipment.valueAmortization | number: '1.5-5'"
                >
                </details-line-base>

                <details-line-base
                    *ngIf='equipment?.valueAmortization >= 500'
                    hint='Durée amortissement'
                    [label]='equipment.depreciationPeriod?.value'
                >
                </details-line-base>

<!--                <details-line-base-->
<!--                    hint="Type d'équipement"-->
<!--                    [label]='equipment.equipmentType?.label'-->
<!--                >-->
<!--                </details-line-base>-->

                <!--                <details-line-base-->
                <!--                    [label]='(service.entity$ | async).category?.label'-->
                <!--                    hint='Catégorie'-->
                <!--                >-->
                <!--                </details-line-base>-->
            </div>
        </div>

        <div id="equipment-resume-locations-card" class="card mt-6" *hasPermission="[ENUM_PERMISSIONS.READ_EQUIPMENT_INFO_LOCATION]">
            <div class="px-gutter py-4 border-b" fxLayout='row' fxLayoutAlign='space-between center' >
                <h2 class="title m-0">Emplacements actuels</h2>
                <div>

                    <button *hasPermission="[ENUM_PERMISSIONS.ADD_EQUIPMENT_INFO_LOCATION]"
                            (click)='addLocation()'
                            class='ml-4'
                            color='primary'
                            fxFlex='none'
                            mat-mini-fab
                            matTooltip='Ajouter un emplacement'
                            type='button'>
                        <mat-icon [icIcon]='icAdd'></mat-icon>
                    </button>
                </div>
            </div>

            <!-- TODO PERMISSION [deletePermission]="[ENUM_PERMISSIONS.DELETE_HM_EQUIPMENT_INFO_LOCATION]"-->
            <base-data-table
                [showPaginator]='false'
                [showSort]='false'
                [dataSource]='dataSourceLocation'
                [displayedColumns]='displayedColumnsLocation'
                [feisColumns]='feisColumnsLocation'
                (onUpdateItem)='updateItemLocation($event)'
                (onDeleteItem)='deleteItem($event)'
                [customColumnTemplate]="templateRef"
            ></base-data-table>
            <ng-template #templateRef let-column let-row="element">
                <div *ngIf="column === 'surface'">
                    <div *ngIf='row?.room; else noRoom'>
                        {{row?.room?.surface}} m²
                    </div>
                    <ng-template #noRoom>
                        {{row?.building?.surface}} m²
                    </ng-template>
                </div>
            </ng-template>
        </div>

        <div class="card mt-6"  *hasPermission="[ENUM_PERMISSIONS.READ_EQUIPMENT_INFO_HISTORY]">
            <div class="px-gutter py-4 border-b" fxLayout='row' fxLayoutAlign='space-between center'>
                <h2 class="title m-0">Historique des emplacements</h2>
            </div>
            <base-data-table
                [showPaginator]='false'
                [showSort]='false'
                [dataSource]='dataSourceLocationHistory'
                [displayedColumns]='displayedColumnsLocationHistory'
                [feisColumns]='feisColumnsLocationHistory'
                [customColumnTemplate]="templateRef"
            ></base-data-table>
            <ng-template #templateRef let-column let-row="element">
                <div *ngIf="column === 'surface'">
                    <div *ngIf='row?.room; else noRoom'>
                        {{row?.room?.surface}} m²
                    </div>
                    <ng-template #noRoom>
                        {{row?.building?.surface}} m²
                    </ng-template>
                </div>
            </ng-template>
        </div>

        <div *hasPermission="[ENUM_PERMISSIONS.READ_EQUIPMENT_INFO_TABLE]" >
            <div class="card mt-6" *ngIf='equipment.valueAmortization >= 500'>
                <div class="px-gutter py-4 border-b" fxLayout='row' fxLayoutAlign='space-between center'>
                    <h2 class="title m-0">Amortissement</h2>
                    <!--                <button color='primary' mat-raised-button type='button' (click)='addCharacteristic()'>AJOUTER</button>-->
                </div>

                <base-data-table
                        [showPaginator]='false'
                        [showSort]='false'
                        [dataSource]='dataSourceAmortization'
                        [displayedColumns]='displayedColumnsAmortization'
                        [feisColumns]='feisColumnsAmortization'
                ></base-data-table>

                <!--            <ng-template #noAmortization>-->
                <!--                <div class='text-center p-4'>-->
                <!--                    <p>L'amortissement n'est pas disponible pour les équipements dont la valeur d'achat est inférieure à 500€</p>-->
                <!--                </div>-->
                <!--            </ng-template>-->
            </div>
        </div>
        
        <div id="equipment-resume-activities-card" class="card mt-6" *hasPermission="[ENUM_PERMISSIONS.READ_EQUIPMENT_INFO_ACTIVITY]">
            <div class="px-gutter py-4 border-b"  fxLayout='row' fxLayoutAlign='space-between center'>
                <h2 class="title m-0">Activités
                    <icon-warning
                        class='ml-2'
                        [show]='displayPercentWarning || displayLocation'
                        [toolTip]='displayMessage()'
                    >
                    </icon-warning>
                </h2>

                <button *hasPermission="[ENUM_PERMISSIONS.ADD_EQUIPMENT_INFO]"
                    (click)='createActivity()'
                    [disabled]='displayLocation'
                    class='ml-4'
                    color='primary'
                    fxFlex='none'
                    mat-mini-fab
                    matTooltip='Ajouter une activité'
                    type='button'>
                    <mat-icon [icIcon]='icAdd'></mat-icon>
                </button>
            </div>

            <!-- TODO PERMISSION
            [updatePermission]="[ENUM_PERMISSIONS.UPDATE_HM_EQUIPMENT_INFO_ACTIVITY]"
                [deletePermission]="[ENUM_PERMISSIONS.DELETE_HM_EQUIPMENT_INFO]"
            -->
            <base-data-table
                [dataSource]='dataSourceActivity'
                [displayedColumns]='displayedColumnsActivity'
                [feisColumns]='feisColumnsActivity'
                [showPaginator]='false'
                (onUpdateItem)='updateItemActivity($event)'
                (onDeleteItem)='deleteItemActivity($event)'
            ></base-data-table>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="px-gutter py-4" gdColumns="1fr 1fr" gdColumns.xs="1fr" gdGap="16px">
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
        <details-line-base [loading]='true'></details-line-base>
    </div>
</ng-template>
