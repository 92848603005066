import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { GenericMotifModel } from '../../../models/generic-motif.model';
import { GenericMotifService } from '../../../../pages/application/settings/generic-motif/generic-motif.service';
import { GenericMotifModuleEnum, GenericMotifTypesEnum } from '../../../enums/generic-motif-types.enum';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'generic-reason',
    templateUrl: './generic-reason.component.html',
    styleUrls: ['./generic-reason.component.scss',
        '../../../../../../node_modules/quill/dist/quill.snow.css',
        '../../../../../@vex/styles/partials/plugins/_quill.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class GenericReasonComponent implements OnInit {

    public genericMotifs$: Observable<GenericMotifModel[]>;
    public motif = null;
    public showComment: boolean;
    public quillEditorInstance: any;
    public documentTypes: string[];
    public loadingSave = false;
    protected subscription = new Subscription();
    @Input() form: FormGroup;
    @Input() type: GenericMotifTypesEnum;
    @Input() module: GenericMotifModuleEnum;

    @Input() customTextBeforeDescription = '';
    @Input() commentTitle = 'Commentaire :';
    @Input() subCommentTitle = '';

    customModule = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{header: 1}, {header: 2}],
            [{list: 'ordered'}, {list: 'bullet'}],
            [{indent: '-1'}, {indent: '+1'}],
            [{align: []}],
            ['clean'],
            ['link']
        ]
    };

    constructor(
        private genericMotifService: GenericMotifService,
    ) {
    }

    ngOnInit() {
        this.initData();
        this.initForm();
    }

    initData() {
        this.genericReasonAutocompleteChange();
    }

    initForm() {
        this.form.addControl('motif', new FormControl(null, [Validators.required]));
        this.form.addControl('comment', new FormControl(null, []));

        const commentControl = this.form.get('comment');

        this.subscription.add(
            this.form.controls?.motif?.valueChanges.subscribe((motif: GenericMotifModel) => {

                if (motif.isCommentNeeded) {
                    this.showComment = true;
                    commentControl?.setValidators([Validators.required]);
                } else {
                    this.showComment = false;
                    commentControl.setValue(null);
                    commentControl?.setValidators([]);
                }
                commentControl?.updateValueAndValidity();
            })
        );
    }

    genericReasonAutocompleteChange(search: any = '') {
        this.genericMotifs$ =
            this.genericMotifService
                .findAll(null, null, 'label', 'asc', search, {
                    type: this.type,
                    module: this.module,
                })
                .pipe(map(x => x.data));
    }

    onEditorCreated(quill: any) {
        this.quillEditorInstance = quill;
        this.updateEditorContent();
    }

    updateEditorContent() {
        const editor = this.quillEditorInstance.container;

        // Check if the element already exists and remove it
        const existingHeadPrefix = document.getElementById('headPrefix');
        if (existingHeadPrefix) {
            editor.removeChild(existingHeadPrefix);
        }

        // Create and insert the new element
        const noEditableHeadPrefix = document.createElement('div');
        noEditableHeadPrefix.setAttribute('contenteditable', 'false');
        noEditableHeadPrefix.setAttribute('id', 'headPrefix');
        noEditableHeadPrefix.style.userSelect = 'none';
        noEditableHeadPrefix.style.pointerEvents = 'none';
        noEditableHeadPrefix.style.color = 'grey';
        noEditableHeadPrefix.style.fontSize = '15px';
        noEditableHeadPrefix.style.margin = '15px';
        noEditableHeadPrefix.innerHTML = this.customTextBeforeDescription;

        editor.insertBefore(noEditableHeadPrefix, editor.firstChild);
    }

}
