import {Component, Input} from '@angular/core';
import icClose from "@iconify/icons-ic/twotone-close";
import {fadeInRight400ms} from "../../../../@vex/animations/fade-in-right.animation";


@Component({
  selector: 'error-notification',
  templateUrl: './error-notification.component.html',
  styleUrls: ['./error-notification.component.scss'],
  animations: [
    fadeInRight400ms,
  ],

})
export class ErrorNotificationComponent {

  @Input() exportErrors?: string[] = [];
  @Input() label: string;
  @Input() color?: 'primary' | 'accent' | 'warm';
  @Input() disableClose?:boolean = false;
  readonly icClose = icClose;

  constructor() { }

  closeExportError() {
    this.exportErrors = [];
  }

}
