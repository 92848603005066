
<!DOCTYPE html>
<html lang="fr">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Site en Maintenance</title>
  <style>
      body {
          font-family: Arial, sans-serif;
          text-align: center;
          background-color: #f4f4f4;
          margin: 0;
          padding: 0;
      }
      .container {
          max-width: 600px;
          margin: 100px auto;
          background-color: white;
          padding: 20px;
          border-radius: 10px;
          box-shadow: 0 0 10px rgba(0,0,0,0.1);
      }
      h1 {
          color: #333;
      }
      p {
          color: #666;
          font-size: 1.3em;
      }
      .links {
          margin-top: 20px;
      }
      .links a {
          display: inline-block;
          margin: 10px;
          text-decoration: none;
          color: #007BFF;
      }
      .footer {
          margin-top: 30px;
          color: #999;
      }
  </style>
</head>
<body>
<div class="container">
  <ng-lottie
    (animationCreated)="onAnimate($event)"
    [options]="options"
    class='w-1/4 mb--10'>
  </ng-lottie>
  <p>Notre site est actuellement en maintenance jusqu'au 8 juillet 2024.</p>
  <p> Pendant cette période, la plateforme ne sera pas accessible.</p>
  <p>Nous vous prions de nous excuser pour la gêne occasionnée. </p>
  <div class="links">
  </div>
  <div class="footer">
    <p><strong>L'équipe Predik360</strong></p>
  </div>
</div>
</body>
</html>
