import { Pipe, PipeTransform } from "@angular/core";

export enum MonthEnum {
    JANUARY = 'january',
    FEBRUARY = 'february',
    MARCH = 'march',
    APRIL = 'april',
    MAY = 'may',
    JUNE = 'june',
    JULY = 'july',
    AUGUST = 'august',
    SEPTEMBER = 'september',
    OCTOBER = 'october',
    NOVEMBER = 'november',
    DECEMBER = 'december',
}

export class MonthTranslation {
    static translate(month: MonthEnum): string {
        switch (month) {
            case MonthEnum.JANUARY:
                return 'Janvier';

            case MonthEnum.FEBRUARY:
                return 'Février';

            case MonthEnum.MARCH:
                return 'Mars';

            case MonthEnum.APRIL:
                return 'Avril';

            case MonthEnum.MAY:
                return 'Mai';

            case MonthEnum.JUNE:
                return 'Juin';

            case MonthEnum.JULY:
                return 'Juillet';

            case MonthEnum.AUGUST:
                return 'Août';

            case MonthEnum.SEPTEMBER:
                return 'Septembre';

            case MonthEnum.OCTOBER:
                return 'Octobre';

            case MonthEnum.NOVEMBER:
                return 'Novembre';

            case MonthEnum.DECEMBER:
                return 'Décembre';

            default:
                return null;
        }
    }
}


@Pipe({ name: 'monthTranslation', pure: false })
export class MonthTranslationPipe implements PipeTransform {
    transform(month: MonthEnum): string {
        return MonthTranslation.translate(month);
    }
}

export const monthEnumTranslation = (month: MonthEnum) => {
        return {
            value: month,
            label: MonthTranslation.translate(month)
        }
}
