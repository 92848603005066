import { Country } from '../enums/countries.enum';

export const FrenchTerritoryHelper = (label: Country) => {

    const territory = [
        Country.GF,
        Country.GP,
        Country.FR,
        Country.RE,
        Country.PM,
        Country.YT,
        Country.BL,
        Country.MF,
        Country.WF,
        Country.PF,
        Country.NC,
        Country.TF,
        Country.CP,
        Country.MQ
    ];

    const findedIndex = territory.findIndex(x => x === label);

    if (findedIndex !== -1) {
        return true;
    }

    return false;

}