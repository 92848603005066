import { Component, Input, OnInit } from '@angular/core';
import { scaleIn400ms } from '../../../../@vex/animations/scale-in.animation';
import { fadeInRight400ms } from '../../../../@vex/animations/fade-in-right.animation';
import { stagger40ms } from '../../../../@vex/animations/stagger.animation';
import { fadeInUp400ms } from '../../../../@vex/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from '../../../../@vex/animations/scale-fade-in.animation';

@Component({
    selector: 'status-bar-item',
    templateUrl: 'status-bar-item.component.html',
    animations: [
        scaleIn400ms,
        fadeInRight400ms,
        stagger40ms,
        fadeInUp400ms,
        scaleFadeIn400ms
    ]
})

export class StatusBarItemComponent implements OnInit {

    @Input() icon: any;
    @Input() label: string;
    @Input() isActive: boolean;

    constructor() {
    }

    ngOnInit() {
    }
}
