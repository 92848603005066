import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { InputTypeInterface } from '../../../interfaces/input-type.interface';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
@Component({
    selector: 'form-number-input',
    templateUrl: './form-number-input.component.html',
})
export class NumberInputComponent {
    @Input() formGroup: FormGroup;
    @Input() controlName: string;
    @Input() label: string;
    @Input() appearance: MatFormFieldAppearance = 'outline';
    @Input() type: typeof InputTypeInterface = 'text';
    @Input() minNumber: number;
    @Input() maxNumber: number;
    @Input() hint = '';
    @Input() icon: any;
    @Input() obscure = false;
    @Input() defaultErrorMessage: any;
    @Input() invalidPatternMessage: string;
    @Input() customErrorMessage: string;
    @Input() withoutPadding = false;
    @Output() onLostFocus = new EventEmitter<void>();


    visible = false;
    icVisibility = icVisibility;
    icVisibilityOff = icVisibilityOff;

    toggleInput() {
        this.visible = !this.visible;
    }
}
