import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseService } from '../../base.service';
import icClose from '@iconify/icons-ic/twotone-close';
import icPrint from '@iconify/icons-ic/twotone-print';
import icDownload from '@iconify/icons-ic/twotone-download';
import icPerson from '@iconify/icons-ic/twotone-person';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icEmail from '@iconify/icons-ic/twotone-email';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import { ErrorApi } from '../../../models/api/error-api';
import { FormBaseInterface } from './form-base.interface';
import { SnackbarService } from '../../../services/snackbar.service';
import { ResponseTypeEnum } from '../../enum/response-type.enum';
import { CrudModeEnum } from '../../enum/crud-mode.enum';

@Component({
    template: ''
})

export class FormBaseComponent<T> implements OnInit, OnDestroy, FormBaseInterface {

    protected subscription = new Subscription();

    cd: ChangeDetectorRef;
    form: FormGroup;
    importForm: FormGroup;

    mode: CrudModeEnum = CrudModeEnum.Create;
    // service: BaseService<T>;
    snackbarService: SnackbarService;

    icMoreVert = icMoreVert;
    icClose = icClose;
    icPrint = icPrint;
    icDownload = icDownload;
    icDelete = icDelete;
    icPerson = icPerson;
    icPhone = icPhone;
    icEmail = icEmail;
    icVisibility = icVisibility;
    icVisibilityOff = icVisibilityOff;

    loading = false;
    defaults: T;
    importDefaults: any;
    successCreateMessage: string;
    successUpdateMessage: string;
    successDeleteMessage: string;
    errorCreateMessage: string;
    errorUpdateMessage: string;
    errorDeleteMessage: string;

    CrudModeEnum = CrudModeEnum;

    constructor(public service: BaseService<T>) {
    }


    ngOnInit() {
        if (this.isArchiveMode()) {
            this.mode = CrudModeEnum.Archive;
        } else if (this.mode === CrudModeEnum.Custom) {
            this.mode = CrudModeEnum.Custom;
        } else if (this.mode !== CrudModeEnum.Delete && this.mode !== CrudModeEnum.Read) {
            if (this.defaults) {
                if (this.mode === null || this.mode === undefined) {
                    this.mode = CrudModeEnum.Update;
                }
            } else {
                this.defaults = {} as T;
            }
        }

        if (this.isImportMode()) {
            this.importForm = new FormGroup({
                file: new FormControl('', [Validators.required])
            });
        }
    }

    private normalizeString(str: string): string {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
    }

    filterNormalized(list: any[], value: string, property: string = 'label'): any[] {
        if (Array.isArray(list) && typeof value === 'string') {
            const normalizedValue = this.normalizeString(value).split(' ').filter(Boolean);
            return list.filter(item => {
                const normalizedProperty = this.normalizeString(item?.[property]);
                return normalizedValue.every(segment => normalizedProperty.includes(segment));
            });
        }
        return [];
    }

    isReadMode(): boolean {
        return this.mode === CrudModeEnum.Read;
    }

    isCreateMode(): boolean {
        return this.mode === CrudModeEnum.Create;
    }

    isUpdateMode(): boolean {
        return this.mode === CrudModeEnum.Update;
    }

    isDeleteMode(): boolean {
        return this.mode === CrudModeEnum.Delete;
    }

    isImportMode(): boolean {
        return this.mode === CrudModeEnum.Import;
    }

    isDuplicateMode(): boolean {
        return this.mode === CrudModeEnum.Duplicate;
    }

    isCustomMode(): boolean {
        return this.mode === CrudModeEnum.Custom;
    }

    isArchiveMode(): boolean {
        return this.mode === CrudModeEnum.Archive;
    }

    isFilterMode(): boolean {
        return this.mode === CrudModeEnum.Filter;
    }

    save(): void {
        if (this.isCreateMode()) {
            this.createItem();
        } else if (this.isUpdateMode()) {
            this.updateItem();
        } else if (this.isImportMode()) {
            this.importItems();
        } else if (this.isFilterMode()) {
            this.filterItem();
        }
    }

    onFormInvalid(): void {
    }

    beforeCreateItem(): void {
    }

    afterCreateItem(result?: any, error?: any): void {
    }

    createItem(): void {
        this.loading = true;
        this.defaults = this.form.getRawValue();
        this.beforeCreateItem();
        if (this.form.valid) {
            const sub = this.service.create(this.defaults).subscribe(
                result => {
                    this.afterCreateItem(result, null);
                    this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Create, result.message);
                }, error => {
                    this.afterCreateItem(null, error);
                    this.setErrorsMessages(error.error.errors);
                    this.loading = false;
                }
            );
            this.subscription.add(sub);
        } else {
            this.onFormInvalid();
            this.logFormErrors(this.form);
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    filterItem(): void {
    }

    beforeUpdateItem(): void {
    }

    afterUpdateItem(result?: any, error?: any): void {
    }

    updateItem(): void {
        this.loading = true;
        this.defaults = this.form.getRawValue();
        this.beforeUpdateItem();
        if (this.form.valid) {
            const sub = this.service.update(this.defaults).subscribe(
                result => {
                    this.afterUpdateItem(result, null);
                    this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Update, result.message);
                }, error => {
                    this.afterUpdateItem(null, error);
                    this.setErrorsMessages(error.error.errors);
                    this.loading = false;
                });
            this.subscription.add(sub);
        } else {
            this.logFormErrors(this.form); // Log des erreurs du formulaire
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    beforeDeleteItem(): void {
    }

    afterDeleteItem(result?: any, error?: any): void {
    }

    deleteItem(): void {
        this.loading = true;
        this.beforeDeleteItem();
        const sub = this.service.delete(this.defaults).subscribe(
            result => {
                this.afterDeleteItem(result, null);
                this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Delete, result.message);
            }, error => {
                this.afterDeleteItem(null, error);
                this.setErrorsMessages(error.error.errors);
                this.loading = false;
            }
        );
        this.subscription.add(sub);
    }

    archiveItem(): void {
        this.loading = true;
        this.beforeArchiveItem();
        // @ts-ignore
        const call$ = this.defaults?.isArchive ? this.service.unarchive(this.defaults) : this.service.archive(this.defaults);
        const sub = call$.subscribe(
            result => {
                this.afterArchiveItem(result, null);
                this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Delete, result.message);
            }, error => {
                this.afterArchiveItem(null, error);
                this.setErrorsMessages(error.error.errors);
                this.loading = false;
            }
        );
        this.subscription.add(sub);
    }

    setErrorsMessages(errors: Array<ErrorApi>) {
        errors?.forEach(error => {
            let err = this.form.controls[error.property].errors;
            if (err === null) {
                err = {messages: []};
            }
            err.messages.push(error.message);
            this.form.controls[error.property].setErrors(err);
        });
        this.form.markAllAsTouched();
    }

    setImportErrorsMessages(errors: Array<ErrorApi>) {
        errors?.forEach(error => {
            let err = this.importForm.controls[error.property].errors;
            if (err === null) {
                err = {messages: []};
            }
            err.messages.push(error.message);
            this.importForm.controls[error.property].setErrors(err);
        });
        this.importForm.markAllAsTouched();
    }

    setSnackbar(type: ResponseTypeEnum, mode: CrudModeEnum, message: string) {
        if (this.snackbarService) {
            switch (mode) {
                case CrudModeEnum.Create:
                    if (type === ResponseTypeEnum.Success) {
                        this.snackbarService.success(this.successCreateMessage ?? message);
                    }
                    if (type === ResponseTypeEnum.Error) {
                        this.snackbarService.danger(this.errorCreateMessage ?? message);
                    }
                    break;
                case CrudModeEnum.Update:
                    if (type === ResponseTypeEnum.Success) {
                        this.snackbarService.success(this.successUpdateMessage ?? message);
                    }
                    if (type === ResponseTypeEnum.Error) {
                        this.snackbarService.danger(this.errorUpdateMessage ?? message);
                    }
                    break;
                case CrudModeEnum.Delete:
                    if (type === ResponseTypeEnum.Success) {
                        this.snackbarService.success(this.successDeleteMessage ?? message);
                    }
                    if (type === ResponseTypeEnum.Error) {
                        this.snackbarService.danger(this.errorDeleteMessage ?? message);
                    }
                    break;
                case CrudModeEnum.Import:
                    if (type === ResponseTypeEnum.Success) {
                        this.snackbarService.success(this.successDeleteMessage ?? message);
                    }
                    if (type === ResponseTypeEnum.Error) {
                        this.snackbarService.danger(this.errorDeleteMessage ?? message);
                    }
                    break;
                default:
                    break;
            }
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    importItems() {
        this.importDefaults = this.importForm.value;
        this.beforeImportItem();
        if (this.importForm.valid) {
            this.loading = true;
            const sub = this.service.import(this.importDefaults).subscribe(
                result => {
                    this.afterImportItem(result, null);
                    this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Import, result.message);
                }, error => {
                    this.afterImportItem(null, error);
                    this.setImportErrorsMessages(error.error.errors);
                    this.loading = false;
                });
            this.subscription.add(sub);
        } else {
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    importContactSupplierItems() {
        this.importDefaults = this.importForm.value;
        this.beforeImportItem();
        if (this.importForm.valid) {
            this.loading = true;
            const sub = this.service.import(this.importDefaults).subscribe(
                result => {
                    this.afterImportItem(result, null);
                    this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Import, result.message);
                }, error => {
                    this.afterImportItem(null, error);
                    this.setImportErrorsMessages(error.error.errors);
                    this.loading = false;
                });
            this.subscription.add(sub);
        } else {
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    beforeImportItem() {
        const formData = new FormData();

        if (!this.importDefaults?.file?._files?.length) {
            this.importForm.controls.file.setErrors({required: true});
            this.importForm.markAllAsTouched();
            return;
        }

        formData.append('file-excel', this.importDefaults.file._files[0]);
        this.importDefaults = formData;
    }

    afterImportItem(result?: any, error?: any) {

    }

    beforeArchiveItem() {

    }

    afterArchiveItem(result: any, error: any) {

    }

    public logFormErrors(formGroup: FormGroup): void {
        Object.keys(formGroup.controls).forEach(key => {
            const control = formGroup.get(key);
            if (control instanceof FormGroup) {
                this.logFormErrors(control);
            } else {
                if (control && control.invalid) {
                    console.log(`Control ${key} is invalid. Errors:`, control.errors);
                }
            }
        });
    }
}
