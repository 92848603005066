<ng-container>
    <form (ngSubmit)="save()" [formGroup]="form">
        <base-form-header [mode]='mode' [importLabel]='modalTitle'></base-form-header>

        <mat-dialog-content fxLayout="column">

            <base-form-input
                type="file"
                controlName="file"
                label="File"
            ></base-form-input>

        </mat-dialog-content>

        <a *ngIf='downloadModel && canDownloadTemplateModel' style="cursor: pointer; text-decoration: underline;" class="text-accent" (click)="exportTemplate()">
            Télécharger le modèle
        </a>

        <mat-dialog-actions align="end">
            <base-form-input
                type='submit'
                [mode]='mode'
                [loading]='loading'
                [entityLabel]='entityLabel'
                (onImport)="import()"
            >
            </base-form-input>
        </mat-dialog-actions>

    </form>
</ng-container>
