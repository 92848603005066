<div class='mt-6 flex flex-col md:flex-row md:items-start' *ngIf='(supplierService.entity$ | async) as entity'>
    <div class='flex-auto'>
        <div class='card'>
            <div class='px-gutter py-4 border-b' fxLayout='row' fxLayoutAlign='space-between center'>
                <h2 class='title m-0'>Détails</h2>
                <button color='primary' mat-raised-button type='button' (click)='updateItem()' *hasPermission="[ENUM_PERMISSION.UPDATE_FM_REF_PURCHASE_SUPPLIER_INFO]">MODIFIER</button>
                <!--                <button color='accent' mat-raised-button type='button'>MODIFIER</button>-->
                <!--                <button color='warn' mat-raised-button type='button' (click)='updateItem()'><mat-icon><mat-progress-spinner diameter="20" mode="indeterminate" strokeWidth="11%">-->
                <!--                </mat-progress-spinner></mat-icon></button>-->
            </div>
            <div *ngIf='entity._id; else loading' class='px-gutter py-4' gdColumns='1fr 1fr' gdColumns.xs='1fr'
                 gdGap='16px'>
                <div class='py-3' fxLayout='row' fxLayoutAlign='start center'>
                    <!--                    <div @scaleIn-->
                    <!--                         class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">-->
                    <!--                        <ic-icon [icon]="icPhone" size="20px"></ic-icon>-->
                    <!--                    </div>-->

                    <div @fadeInRight>
                        <p class='m-0 body-1'>{{ entity.label }}</p>
                        <p class='m-0 caption text-hint'>Dénomination</p>
                    </div>
                </div>

                <div class='py-3' fxLayout='row' fxLayoutAlign='start center'>
                    <!--                    <div @scaleIn-->
                    <!--                         class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">-->
                    <!--                        <ic-icon [icon]="icWork" size="20px"></ic-icon>-->
                    <!--                    </div>-->

                    <div @fadeInRight>
                        <p class='m-0 body-1'>{{ entity.type | titlecase }}</p>
                        <p class='m-0 caption text-hint'>Type</p>
                    </div>
                </div>

                <ng-container *ngIf='entity.type === SupplierType.EXTERNE'>
                    <div class='py-3' fxLayout='row' fxLayoutAlign='start center'>
                        <!--                    <div @scaleIn-->
                        <!--                         class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">-->
                        <!--                        <ic-icon [icon]="icWork" size="20px"></ic-icon>-->
                        <!--                    </div>-->

                        <div @fadeInRight>
                            <p class='m-0 body-1'>{{ entity.tva }}</p>
                            <p class='m-0 caption text-hint'>Numéro de TVA</p>
                        </div>
                    </div>
                    <details-line-base
                        [label]='entity.subsidiarAccount'
                        hint='Compte auxiliaire comptable'
                    >
                    </details-line-base>
                </ng-container>

                <ng-container *ngIf='entity.type === SupplierType.EXTERNE && entity.headquarterAddress.country !== "France"'>
                    <div class='py-3' fxLayout='row' fxLayoutAlign='start center'>
                        <!--                    <div @scaleIn-->
                        <!--                         class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">-->
                        <!--                        <ic-icon [icon]="icWork" size="20px"></ic-icon>-->
                        <!--                    </div>-->

                        <div @fadeInRight>
                            <p class='m-0 body-1'>{{ entity.dunsNumber }}</p>
                            <p class='m-0 caption text-hint'>Identifiant entreprise</p>
                        </div>
                    </div>
                </ng-container>

                <ng-container
                    *ngIf='entity.type === SupplierType.EXTERNE && entity.headquarterAddress.country === "France"'>
                    <div class='py-3' fxLayout='row' fxLayoutAlign='start center'>
                        <!--                    <div @scaleIn-->
                        <!--                         class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">-->
                        <!--                        <ic-icon [icon]="icMail" size="20px"></ic-icon>-->
                        <!--                    </div>-->

                        <div @fadeInRight>
                            <p class='m-0 body-1'>{{ entity.siretNumber }}</p>
                            <p class='m-0 caption text-hint'>Numéro de siret</p>
                        </div>
                    </div>

                    <div class='py-3' fxLayout='row' fxLayoutAlign='start center'>
                        <!--                    <div @scaleIn-->
                        <!--                         class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">-->
                        <!--                        <ic-icon [icon]="icAccessTime" size="20px"></ic-icon>-->
                        <!--                    </div>-->

                        <div @fadeInRight>
                            <p class='m-0 body-1'>{{ entity.sirenNumber }}</p>
                            <p class='m-0 caption text-hint'>Numéro de siren</p>
                        </div>
                    </div>

                    <div class='py-3' fxLayout='row' fxLayoutAlign='start center'>
                        <!--                    <div @scaleIn-->
                        <!--                         class="w-10 h-10 rounded-full bg-gray-light text-dark ltr:mr-3 rtl:ml-3 cursor-pointer flex items-center justify-center">-->
                        <!--                        <ic-icon [icon]="icAdd" size="20px"></ic-icon>-->
                        <!--                    </div>-->

                        <div @fadeInRight>
                            <p class='m-0 body-1'>{{ entity.codeApe }}</p>
                            <p class='m-0 caption text-hint'>Code APE</p>
                        </div>
                    </div>
                </ng-container>
            </div>

            <ng-container *ngIf='entity.type === SupplierType.EXTERNE'>
                <div class='px-gutter py-4 border-b' fxLayout='row' fxLayoutAlign='space-between center'>
                    <h2 class='title m-0'>Adresse</h2>
                </div>
                <div *ngIf='entity._id; else loading' class='px-gutter py-4' gdColumns='1fr 1fr' gdColumns.xs='1fr'
                     gdGap='16px'>
                    <div class='py-3' fxLayout='row' fxLayoutAlign='start center'>
                        <div @fadeInRight>
                            <p class='m-0 body-1'>{{ entity.headquarterAddress.street }}</p>
                            <p class='m-0 caption text-hint'>Adresse</p>
                        </div>
                    </div>
                    <div class='py-3' fxLayout='row' fxLayoutAlign='start center'>
                        <div @fadeInRight>
                            <p class='m-0 body-1'>{{ entity.headquarterAddress.additionalAddress }}</p>
                            <p class='m-0 caption text-hint'>Complément d'adresse</p>
                        </div>
                    </div>
                    <div class='py-3' fxLayout='row' fxLayoutAlign='start center'>
                        <div @fadeInRight>
                            <p class='m-0 body-1'>{{ entity.headquarterAddress.postalCode }}</p>
                            <p class='m-0 caption text-hint'>Code postal</p>
                        </div>
                    </div>
                    <div class='py-3' fxLayout='row' fxLayoutAlign='start center'>
                        <div @fadeInRight>
                            <p class='m-0 body-1'>{{ entity.headquarterAddress.city }}</p>
                            <p class='m-0 caption text-hint'>Ville</p>
                        </div>
                    </div>
                    <div class='py-3' fxLayout='row' fxLayoutAlign='start center'>
                        <div @fadeInRight>
                            <p class='m-0 body-1'>{{ entity.headquarterAddress.country }}</p>
                            <p class='m-0 caption text-hint'>Pays</p>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>


        <div class='card mt-6'>
            <div class='px-gutter py-4 border-b' fxLayout='row' fxLayoutAlign='space-between center'>
                <h2 class='title m-0'>Adresses</h2>
                <button color='primary' mat-raised-button type='button' (click)='createAddress()' *hasPermission="[ENUM_PERMISSION.ADD_FM_REF_PURCHASE_SUPPLIER_INFO]">AJOUTER</button>
                <!--                <button color='accent' mat-raised-button type='button'>MODIFIER</button>-->
                <!--                <button color='warn' mat-raised-button type='button' (click)='updateItem()'><mat-icon><mat-progress-spinner diameter="20" mode="indeterminate" strokeWidth="11%">-->
                <!--                </mat-progress-spinner></mat-icon></button>-->
            </div>

            <div @stagger
                 class='px-gutter py-4'
                 gdGap='24px'>
                <!-- TODO PERMISSION [updatePermission]="[ENUM_PERMISSION.UPDATE_FM_BF_SUPPLIER_INFO]"
                    [deletePermission]="[ENUM_PERMISSION.DELETE_FM_BF_SUPPLIER_INFO_ADDRESS]"-->
                <base-data-table
                    [showPaginator]='false'
                    [sortField]='sortFieldAddress'
                    [sortOrder]='sortOrderAddress'
                    [dataSource]='dataSourceAddress'
                    [displayedColumns]='displayedColumnsAddress'
                    [feisColumns]='feisColumnsAddress'
                    (onPageEvent)='pageEventAddress($event)'
                    (onSortEvent)='sortEventAddress($event)'
                    (onUpdateItem)='updateAddress($event)'
                    (onDeleteItem)='deleteAddress($event)'
                ></base-data-table>
            </div>
        </div>
    </div>

    <div class='flex-none max-w-unset md:max-w-xs w-full md:ltr:ml-6 md:rtl:mr-6 mt-6 md:mt-0'>
        <div class='card'>
            <div class='px-6 py-4 border-b flex flex-row justify-between'>
                <div fxLayout='row' fxLayoutAlign='space-between center'>
                    <h2 class='title m-0 mr-2'>Poste de dépense</h2>
<!--                    <icon-warning-->
<!--                        class='ml-2'-->
<!--                        [show]='displayPostePercentWarning'-->
<!--                        [toolTip]="'Le pourcentage doit être égal à 100%'"-->
<!--                    >-->
<!--                    </icon-warning>-->
                </div>
                <!-- TODO PERMISSION *hasPermission="[ENUM_PERMISSION.ADD_FM_BF_SUPPLIER_INFO]"-->
<!--                <button-->
<!--                    (click)='addItemFamille()'-->
<!--                    class='ml-4'-->
<!--                    color='primary'-->
<!--                    fxFlex='none'-->
<!--                    mat-mini-fab-->
<!--                    type='button'>-->
<!--                    <mat-icon [icIcon]='icAdd'></mat-icon>-->
<!--                </button>-->
            </div>

            <!-- TODO PERMISSION [updatePermission]="[ENUM_PERMISSION.UPDATE_FM_BF_SUPPLIER_INFO_BUYING_FAMILY]"
                [deletePermission]="[ENUM_PERMISSION.DELETE_FM_BF_SUPPLIER_INFO]"-->
            <base-data-table
                [dataSource]='dataSourceFamille'
                [displayedColumns]='displayedColumnsFamille'
                [feisColumns]='feisColumnsFamille'
                [showPaginator]='false'
                [showSort]='false'
                (onUpdateItem)='updateItemFamille($event)'
                (onDeleteItem)='deleteItemFamille($event)'
            ></base-data-table>
        </div>
    </div>

    <ng-template #loading>
        <div class='px-gutter py-4' gdColumns='1fr 1fr' gdColumns.xs='1fr' gdGap='16px'>
            <div class='py-3' fxLayout='row' fxLayoutAlign='start center'>
                <vex-skeleton class='w-14 mr-2' heigthTailwind='h-12' [circle]='true'></vex-skeleton>
                <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
            </div>

            <div class='py-3' fxLayout='row' fxLayoutAlign='start center'>
                <vex-skeleton class='w-14 mr-2' heigthTailwind='h-12' [circle]='true'></vex-skeleton>
                <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
            </div>

            <div class='py-3' fxLayout='row' fxLayoutAlign='start center'>
                <vex-skeleton class='w-14 mr-2' heigthTailwind='h-12' [circle]='true'></vex-skeleton>
                <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
            </div>

            <div class='py-3' fxLayout='row' fxLayoutAlign='start center'>
                <vex-skeleton class='w-14 mr-2' heigthTailwind='h-12' [circle]='true'></vex-skeleton>
                <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
            </div>

            <div class='py-3' fxLayout='row' fxLayoutAlign='start center'>
                <vex-skeleton class='w-14 mr-2' heigthTailwind='h-12' [circle]='true'></vex-skeleton>
                <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
            </div>

            <div class='py-3' fxLayout='row' fxLayoutAlign='start center'>
                <vex-skeleton class='w-14 mr-2' heigthTailwind='h-12' [circle]='true'></vex-skeleton>
                <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
            </div>
        </div>
    </ng-template>
</div>
