import {Injectable} from '@angular/core';
import {BaseService} from '../../../../core/base/base.service';
import {GenericEnergyIdModelWithStrEnum} from '../../../../core/models/generic-energy-id.model';
import {HttpClient} from '@angular/common/http';
import {ObjectApiInterface} from '../../../../core/base/interfaces/object-api.interface';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {EnergyIdModel} from "../../../../core/models/energy-id.model";

@Injectable({
    providedIn: 'root'
})
export class GenericEnergyIdService extends BaseService<GenericEnergyIdModelWithStrEnum> {

    constructor(public http: HttpClient) {
        super(http, 'generic-energy-id');
    }

    findAllShopGenerics() {
        return this.http.get<ObjectApiInterface<any>>(`${this.baseUrlApi}/list/generics`)
            .pipe(map(x => {
                this._entity.next(x.data);
                return x.data;
            }));
    }

    addCharacteristic(genericProductId: string, data: any): Observable<ObjectApiInterface<GenericEnergyIdModelWithStrEnum>> {
        return this.http.post<ObjectApiInterface<GenericEnergyIdModelWithStrEnum>>(`${this.baseUrlApi}/${genericProductId}/addCharacteristic`, data)
            .pipe(map(x => {
                this._entity.next(x.data);
                return x;
            }));
    }

    updateCharacteristic(genericProductId: string, data: any): Observable<ObjectApiInterface<GenericEnergyIdModelWithStrEnum>> {
        return this.http.put<ObjectApiInterface<GenericEnergyIdModelWithStrEnum>>(`${this.baseUrlApi}/${genericProductId}/updateCharacteristic/${data._id}`, data)
            .pipe(map(x => {
                this._entity.next(x.data);
                return x;
            }));
    }

    deleteCharacteristic(genericProductId: string, dataId: any): Observable<ObjectApiInterface<GenericEnergyIdModelWithStrEnum>> {
        return this.http.delete<ObjectApiInterface<GenericEnergyIdModelWithStrEnum>>(`${this.baseUrlApi}/${genericProductId}/deleteCharacteristic/${dataId}`)
            .pipe(map(x => {
                return x;
            }));
    }

    create(entity: GenericEnergyIdModelWithStrEnum): Observable<ObjectApiInterface<GenericEnergyIdModelWithStrEnum>> {
        return this.http.post<ObjectApiInterface<GenericEnergyIdModelWithStrEnum>>(`${this.baseUrlApi}/create`, entity);
    }

    update(entity: GenericEnergyIdModelWithStrEnum): Observable<ObjectApiInterface<GenericEnergyIdModelWithStrEnum>> {
        return this.http.put<ObjectApiInterface<GenericEnergyIdModelWithStrEnum>>(`${this.baseUrlApi}/update/${entity._id}`, entity);
    }

    fetchActiveIDEnergyByCondition(condition: any): any {
        return this.http.post<ObjectApiInterface<EnergyIdModel>>(`${this.baseUrlApi}/fetchActiveIDEnergyByCondition`, condition).pipe(map(x => {
            return x.data;
        }));
    }

}
