import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'base-data-table-dropdown-template',
    templateUrl: 'data-table-dropdown-template.component.html'
})

export class DataTableDropdownTemplateComponent implements OnInit {

    @Input() header = true;
    @Input() footer = true;
    @Input() width = 400;


    constructor() {
    }

    ngOnInit() {
    }

}
