import {AfterContentInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Route} from '@angular/router';
import {BaseService} from '../../base.service';
import {Subscription} from 'rxjs';
import {scaleIn400ms} from '../../../../../@vex/animations/scale-in.animation';
import {fadeInRight400ms} from '../../../../../@vex/animations/fade-in-right.animation';
import {stagger40ms} from '../../../../../@vex/animations/stagger.animation';
import {fadeInUp400ms} from '../../../../../@vex/animations/fade-in-up.animation';
import {scaleFadeIn400ms} from '../../../../../@vex/animations/scale-fade-in.animation';
import {ENUM_PERMISSIONS} from '../../../enums/permission.enum';
import {Link} from '../../../../../@vex/interfaces/link.interface';
import {TabsManagementInterface} from '../../interfaces/tabs-management.interface';
import {AuthService} from '../../../services/auth.service';
import {PermissionGuard} from '../../../guards/permission.guard';

// @ts-ignore
// @ts-ignore
// @ts-ignore
@Component({
    selector: 'details-base',
    template: '',
    animations: [
        scaleIn400ms,
        fadeInRight400ms,
        stagger40ms,
        fadeInUp400ms,
        scaleFadeIn400ms
    ]
})

export class DetailsBaseComponent<T> implements OnInit, OnDestroy, AfterContentInit, TabsManagementInterface {

    ENUM_PERMISSION = ENUM_PERMISSIONS;
    id: string;
    object: T;
    loading = false;
    defaultTabs: Link[] = [];
    protected subscription = new Subscription();
    public tabs: Link[] = [];

    // DON'T FORGET TO INJECT SERVICE AND ACTIVEDROUTE IN YOUR PARENT CONTRUSCTOR
    constructor(
        public service: BaseService<T>,
        public activatedRoute: ActivatedRoute,
        public authService: AuthService) {
    }

    ngOnInit() {


        this.tabs = this.defaultTabs;
        this.initData();
    }

    ngAfterContentInit() {
        // this.subscription.unsubscribe();
    }

    initData() {

        this.loading = true;
        this.id = this.activatedRoute.snapshot.params?.id;
        const item = this.service.findById(this.id).subscribe(object => {
            this.object = object.data;
            this.loading = false;
        });
        this.subscription.add(item);
    }

    ngOnDestroy() {
        this.service.resetEntity();
        this.subscription.unsubscribe();
    }

    previousRoute() {
        window.history.back();
    }

    addTabs(...tabs: Link[]): void {
        this.tabs = [...this.tabs, ...tabs];
    }

    removeTabs(...tabs: Link[]): void {
        this.tabs = this.tabs.filter(x => !tabs.includes(x));

    }

    getDisplayableTabs(allowDuplicates: boolean = false): Link[] {
        let res: Link[] = [];
        // Filter tabs by permissions
        res = this.tabs?.filter(tab => {
            if (typeof tab.route === 'string') {
                // Get the route related to the tab from the router
                const route = this.findRoute(tab.route?.replace('./', '')); // Implement this function to find ActivatedRoute by path

                // If the route is found and has a permission, it takes priority over the tab permission
                if (route) {

                    // Only continue if the route has the PermissionGuard, otherwise we consider it accessible
                    if (route.canActivate?.some(x => x.name === PermissionGuard.name)) {

                        if (route && route.data && 'permission' in route.data) {

                            // Check if the associated route has a permission
                            return this.authService.hasPermission(route.data.permission);
                        } else {
                            return false; // If the route doesn't have a permission but has the PermissionGuard, it's not accessible
                        }
                    }
                }

                // If the route is not found or doesn't have a permission, check the tab permission
                if ('permission' in tab) {
                    // Check if the tab has a permission
                    return this.authService.hasPermission(tab.permission);
                }

                if ('predikTab' in tab) {
                    return this.authService.isPredik();
                }

                if ('hidden' in tab) {
                    return tab.hidden;
                }
            }

            return true; // Keep the tab if it doesn't have a permission or associated route permission
        });



        // if (res?.some(x => x.additionalCondition)) {
        res = res?.filter(x => !('additionalCondition' in x) || x.additionalCondition);
        // }

        if (!allowDuplicates) {
            res = res?.filter((x, i) => res.findIndex(y => y.label === x.label) === i);
        }

        const sorted = res?.filter(x => x.index).sort((a, b) => a.index - b.index);

        if (sorted?.length) {
            const unsorted = res?.filter(x => !x.index);
            res = [...sorted, ...unsorted];
        }

        return res;
    }

    // Function to find ActivatedRoute by path
    private findRoute(path: string): Route | undefined {
        const findRoute = (route: Route): Route | undefined => {
            if (route.path === path) {
                return route;
            }

            if (Array.isArray(route.children)) {
                for (const child of route.children) {
                    const found = findRoute(child);
                    if (found) {
                        return found;
                    }
                }
            }

            return undefined;
        };

        return findRoute(this.activatedRoute?.snapshot?.routeConfig);
    }
}
