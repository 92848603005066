import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormModalBaseComponent } from '../../../../../../core/base/components/form-modal-base/form-modal-base.component';
import { HotTopicService } from '../../hot-topic.service';
import { HotTopicModel } from '../../../../../../core/models/hot-topic.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CrudModeEnum } from '../../../../../../core/base/enum/crud-mode.enum';
import { quillConfig } from '../../../../../../core/config/quill.config';
import { GenericHotTopicService } from '../../../../settings/generic-hot-topic/generic-hot-topic.service';
import { Observable } from 'rxjs';
import { GenericHotTopicModel } from '../../../../../../core/models/generic-hot-topic.model';
import { map } from 'rxjs/operators';
import { ContractService } from '../../../../management/contract/contract.service';
import { ResponseTypeEnum } from '../../../../../../core/base/enum/response-type.enum';

@Component({
    selector: 'vex-tva-create-update',
    templateUrl: './contract-hot-topic-create-update-delete.component.html',
    styleUrls: ['./contract-topic-create-update-delete.component.scss']
})
export class ContractHotTopicCreateUpdateDeleteComponent extends FormModalBaseComponent<HotTopicModel> implements OnInit {


    public readonly quillConfig = quillConfig;
    genericHotTopic$: Observable<GenericHotTopicModel>;
    contract$: Observable<GenericHotTopicModel>;
    MAX_TEXT_DESCRIPTION = 255;
    contractId = null;
    isNotFromContract = true;

    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public dialogRef: MatDialogRef<FormModalBaseComponent<HotTopicModel>>,
                public service: HotTopicService,
                public genericHotTopicService: GenericHotTopicService,
                public contractService: ContractService,
    ) {
        super(data, dialogRef, service);
    }

    ngOnInit() {
        super.ngOnInit();
        this.defaults = this.data?.defaults;
        this.contractId = this.data?.contractId || null;
        this.isNotFromContract = this.contractId === null;
        this.genericHotTopicAutocomplete('');
        this.contractAutocomplete(this.defaults?.contract?.object ?? '');
        this.initForm();
    }

    generateModalHeader(action: 'delete'| 'edit', maxLength = 20): string {

        if (this.mode === CrudModeEnum.Create) { return; }

        if (!['edit', 'delete'].includes(action)) {
            throw new Error('L\'action doit être soit \'modifier\' soit \'supprimer\'.');
        }

        switch (action) {
            case 'edit':
                return `Modifier l'actualité`;
            case 'delete':
                return `l'actualité`;
        }
    }

    initForm() {
        this.form = new FormGroup({
            _id: new FormControl(this.defaults?._id),
            startDate: new FormControl(this.defaults?.startDate, [Validators.required]),
            endDate: new FormControl(this.defaults?.endDate, [Validators.required]),
            contract: new FormControl(this.contractId ?? this.defaults?.contract, [Validators.required]),
            genericHotTopic: new FormControl(this.defaults?.genericHotTopic, [Validators.required]),
        });

        if (this.contractId !== null) {
            this.form.controls.contract.clearValidators();
            this.form.controls.contract.setErrors(null);
        }
    }

    createItem(): void {
        this.loading = true;
        const {contract, ...rest} = this.form.getRawValue();

        this.defaults = {
            ...rest,
            contractId: this.isNotFromContract ? contract._id : this.contractId
        };
        this.beforeCreateItem();
        if (this.form.valid) {
            const sub = this.service.createContractTopic(this.defaults).subscribe(
                result => {
                    this.afterCreateItem(result, null);
                    this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Create, result.message);
                }, error => {
                    this.afterCreateItem(null, error);
                    this.setErrorsMessages(error.error.errors);
                    this.loading = false;
                }
            );
            this.subscription.add(sub);
        } else {
            this.onFormInvalid();
            this.logFormErrors(this.form);
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    updateItem(): void {
        this.loading = true;
        const {contract, ...rest} = this.form.getRawValue();

        console.log('rest', rest);

        this.defaults = {
            ...rest,
            contractId: contract._id
        };
        this.beforeUpdateItem();
        if (this.form.valid) {
            const sub = this.service.updateContractTopic(this.defaults).subscribe(
                result => {
                    this.afterUpdateItem(result, null);
                    this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Update, result.message);
                }, error => {
                    this.afterUpdateItem(null, error);
                    this.setErrorsMessages(error.error.errors);
                    this.loading = false;
                });
            this.subscription.add(sub);
        } else {
            this.logFormErrors(this.form); // Log des erreurs du formulaire
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }


    genericHotTopicAutocomplete(value: string) {
        this.genericHotTopic$ = this.genericHotTopicService.findAll(null, null, 'type', 'asc', value, {carrouselStyle: 'CONTRACT'}).pipe(
            map((x: any) => x.data)
        );
    }

    contractAutocomplete(value: string) {
        this.contract$ = this.contractService.findAll(null, null, 'type', 'asc', value, {status: ['VALIDATED']}).pipe(
            map((x: any) => x.data)
        );
    }

    afterCreateItem(result?: any, error?: any) {
        if (result) {
            this.close(result.data);
        }
    }

    afterUpdateItem(result?: any, error?: any) {
        if (result) {
            this.close(result.data);
        }
    }

    afterDeleteItem(result?: any, error?: any) {
        super.afterDeleteItem(result, error);
    }
}


