import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ENUM_PERMISSIONS } from '../../../enums/permission.enum';


export interface IDynamicTemplates {
    template: TemplateRef<any>;
    position: number;
    place?: 'after' | 'before';
    isShowing?: boolean;
    hasPermission?: ENUM_PERMISSIONS[];
}

@Component({
    selector: 'base-data-table-header-layout',
    templateUrl: 'data-table-header-layout-base.component.html'
})
export class DataTableHeaderLayoutBaseComponent implements OnInit, AfterViewInit {

    @Input() backgroundColorWhite = false;
    @Input() dynamicTemplates: Array<IDynamicTemplates>;
    @Input() additionalDynamicTemplates: Array<IDynamicTemplates>;

    dynamicTemplatesAfter: Array<IDynamicTemplates> = [];
    dynamicTemplatesBefore: Array<IDynamicTemplates> = [];

    constructor() {
    }

    ngOnInit() {

        const dynamicTemplatesBefore = this.dynamicTemplates
            .filter(dt => this.filteredDynamicTemplates(dt, 'before'));

        const dynamicTemplatesAfter = this.dynamicTemplates
            .filter(dt => this.filteredDynamicTemplates(dt, 'after'));

        this.additionalDynamicTemplates.forEach(template => {
            if (template.place === 'before') {
                dynamicTemplatesBefore.splice(template.position - 1, 0, template);
            } else if (template.place === 'after') {
                dynamicTemplatesAfter.splice(template.position - 1, 0, template);
            }
        });

        this.dynamicTemplatesBefore = dynamicTemplatesBefore.sort((a, b) => a.position - b.position);
        this.dynamicTemplatesAfter = dynamicTemplatesAfter.sort((a, b) => a.position - b.position);
    }
    filteredDynamicTemplates = (dt: IDynamicTemplates, place: string) => {
        return (!dt.isShowing || dt.isShowing === true) && dt.place === place;
    }

    ngAfterViewInit(): void {
    }

}
