<ng-container *ngIf="isCreateMode() || isUpdateMode()">
    <form (ngSubmit)="save()" [formGroup]="form">
        <base-form-header [mode]='mode' createLabel='Ajouter un étage' [updateLabel]='form.get("label").value'></base-form-header>

        <mat-dialog-content fxLayout="column">

            <base-form-input
                label='Libellé'
                controlName='label'>
            </base-form-input>

            <base-form-input
                label='Niveau'
                [minNumber]='null'
                type='number'
                controlName='level'>
            </base-form-input>

        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <base-form-input
                type='submit'
                [mode]='mode'
                entityLabel='BUILDING'
                (onCreate)='createItem()'
                (onUpdate)='updateItem()'
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>

<ng-container *ngIf="isDeleteMode()">
    <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
        <div>Supprimer {{ data.name }}</div>
        <button type="button" mat-icon-button (click)="close(false)" tabindex="-1">
            <mat-icon [icIcon]="icClose"></mat-icon>
        </button>
    </div>

    <mat-dialog-content>
        <p>Etes-vous sûr de vouloir supprimer {{ data.name }} ?</p>
    </mat-dialog-content>


    <mat-dialog-actions align="end">
        <button mat-button (click)="close(false)">NON</button>
        <button mat-button color="primary" (click)="deleteItem()">OUI</button>
    </mat-dialog-actions>
</ng-container>
