<mat-form-field [appearance]="appearance" [formGroup]="formGroup" class="flex-auto w-full" [ngClass]="{'without-padding': withoutPadding}">
    <mat-label>{{ label }}</mat-label>
    <mat-select [formControlName]="controlName" multiple>
        <mat-select-trigger *ngIf="customMultipleSelectedTemplate">
            <ng-container
                    *ngTemplateOutlet="customMultipleSelectedTemplate; context: {element: formGroup.controls[controlName].value}">
            </ng-container>
        </mat-select-trigger>
        <ng-container *ngIf="!loading">
            <mat-option *ngFor="let item of items" [value]="item" class="wrap-text">
                {{ displayProperty ? getProperties(item) : item }}
            </mat-option>
        </ng-container>
    </mat-select>
    <mat-icon *ngIf="icon" [icIcon]="icon" class="mr-3" matPrefix></mat-icon>
    <mat-hint>{{ hint }}</mat-hint>

    <base-form-input-errors
            [control]="formGroup.controls[controlName]"
            [controlName]="controlName"
            [errorMessages]="defaultErrorMessage"
            [invalidPatternMessage]="invalidPatternMessage"
            [customErrorMessage]="customErrorMessage">
    </base-form-input-errors>
</mat-form-field>