<ng-container *ngIf="type === 'text'">
  <form-text-input
          [formGroup]="fcd.form"
          [controlName]="controlName"
          [label]="label"
          [placeholder]="placeholder"
          [withoutPadding]="withoutPadding"
          [hint]="hint"
          [icon]="icon"
          [obscure]="obscure"
          [defaultErrorMessage]="defaultErrorMessage"
          [invalidPatternMessage]="invalidPatternMessage"
          [customErrorMessage]="customErrorMessage"
          (onLostFocus)="onLostFocus.emit()"
          [appearance]="appearance"
          [inputTextWidthAuto]="inputTextWidthAuto"
  ></form-text-input>
</ng-container>

<ng-container *ngIf="type === 'number'">
    <form-number-input
        [formGroup]="fcd.form"
        [controlName]="controlName"
        [label]="label"
        [withoutPadding]="withoutPadding"
        [hint]="hint"
        [icon]="icon"
        [defaultErrorMessage]="defaultErrorMessage"
        [invalidPatternMessage]="invalidPatternMessage"
        [customErrorMessage]="customErrorMessage"
        (onLostFocus)="onLostFocus.emit()"
        [appearance]="appearance"
    ></form-number-input>
</ng-container>

<ng-container *ngIf="type === 'select'">
    <form-select-input
        [formGroup]="fcd.form"
        [controlName]="controlName"
        [label]="label"
        [items]="items"
        [displayProperty]="displayProperty"
        [withoutPadding]="withoutPadding"
        [hint]="hint"
        [icon]="icon"
        [defaultErrorMessage]="defaultErrorMessage"
        [invalidPatternMessage]="invalidPatternMessage"
        [customErrorMessage]="customErrorMessage"
        [getProperties]="getProperties.bind(this)"
        [appearance]="appearance"

    >
    </form-select-input>
</ng-container>

 <ng-container *ngIf="type === 'multiple'">
     <form-multi-input
        [formGroup]="fcd.form"
        [controlName]="controlName"
        [label]="label"
        [items]="items"
        [displayProperty]="displayProperty"
        [withoutPadding]="withoutPadding"
        [hint]="hint"
        [icon]="icon"
        [defaultErrorMessage]="defaultErrorMessage"
        [invalidPatternMessage]="invalidPatternMessage"
        [customErrorMessage]="customErrorMessage"
        [getProperties]="getProperties.bind(this)"
        [appearance]="appearance"
     >
     </form-multi-input>
 </ng-container>

<ng-container *ngIf="type === 'select-between'">
    <form-select-between
            [formGroup]="fcd.form"
            [controlName]="controlName"
            [label]="label"
            [appearance]="appearance"
            [hint]="hint"
            [icon]="icon"
            [withoutPadding]="withoutPadding"
            [typeSelection]="typeSelection"
            [selection]="selection"
            [defaultErrorMessage]="defaultErrorMessage"
            [invalidPatternMessage]="invalidPatternMessage"
            [customErrorMessage]="customErrorMessage"
            (onLostFocus)="onLostFocus.emit()">
    </form-select-between>
</ng-container>

<ng-container *ngIf="type === 'search-multiple' && fcd.form">
    <form-multi-select-search
            [formGroup]="fcd.form"
            [selectedItems]="selectedItems"
            [controlName]="controlName"
            [label]="label"
            [appearance]="appearance"
            [placeholder]="placeholder"
            [filteredStates$]="filteredStates$"
            [displayProperty]="displayProperty"
            [displayFn]="_displayFn.bind(this)"
            [withoutPadding]="withoutPadding"
            [defaultErrorMessage]="defaultErrorMessage"
            [invalidPatternMessage]="invalidPatternMessage"
            [customErrorMessage]="customErrorMessage"
            (onSelectItems)="onSelectItems.emit($event)"
    >
    </form-multi-select-search>
</ng-container>

<ng-container *ngIf="type === 'search'">
    <form-search-input
            [formGroup]="fcd.form"
            [controlName]="controlName"
            [label]="label"
            [appearance]="appearance"
    >
    </form-search-input>
</ng-container>

<mat-form-field [appearance]="appearance" *ngIf='fcd.form && type === "autocomplete-multiple"' class="flex-auto w-full"
                [formGroup]='fcd.form'>
    <mat-label>{{ label }}</mat-label>
        <mat-chip-list #chipList>
            <mat-chip
                    *ngFor="let item of chipsValues"
                    (removed)="clearChip(item)">
                {{  isStringArray ? item : item.label }}
                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip>
    <input [matAutocompleteDisabled]='disabled || readonly' [readonly]="readonly" [formControlName]='controlName'
           [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           (keydown)='keydownAutocomplete($event)' (blur)="onLostFocus.emit()" matInput>
        </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)"
                      [displayWith]='_displayFn.bind(this)' [panelWidth]="autocompletePanelWidthAuto ? 'auto' : false">
        <mat-option *ngFor="let state of filteredStates$ | async" [value]="state" [disabled]="readonly">
            <span class="body-1">{{ displayProperty ? getProperties(state) : state }}</span>
        </mat-option>
    </mat-autocomplete>
    <!--    <div *ngIf="isDuplicate" style="color: #ff0000;">Ce fournisseur a déja été sélectionné</div>-->
    <!-- Boutons désactivés ou cachés en mode lecture seule -->
    <button *ngIf='fcd.form.controls[controlName].value && !(disabled || readonly) && !fcd.form.controls[controlName].disabled'
            class="text-secondary" mat-icon-button matSuffix
            type="button" (click)='resetAutoComplete()'>
        <mat-icon [icIcon]="icClose"></mat-icon>
    </button>
    <button *ngIf='!fcd.form.controls[controlName].value && !readonly' class="text-secondary" mat-icon-button matSuffix
            type="button">
        <mat-icon style='font-size: 25px; padding-left: 15px' [icIcon]="icArrowDropDown"></mat-icon>
    </button>

    <mat-hint>{{ hint }}</mat-hint>

    <base-form-input-errors
        [control]="fcd.form.controls[controlName]"
        [controlName]="controlName"
        [errorMessages]="defaultErrorMessage"
        [invalidPatternMessage]="invalidPatternMessage"
        [customErrorMessage]="customErrorMessage">
    </base-form-input-errors>

</mat-form-field>

<mat-form-field [appearance]="appearance" *ngIf='fcd.form && type === "autocomplete"' class="flex-auto w-full"
                [formGroup]='fcd.form'>
    <mat-label>{{ label }}</mat-label>
    <input [matAutocompleteDisabled]='disabled || readonly' [readonly]="readonly" [formControlName]='controlName'
           [matAutocomplete]="auto"
           (keydown)='keydownAutocomplete($event)' (blur)="onLostFocus.emit()" matInput>
    <mat-autocomplete (optionSelected)="optionSelected($event)" #auto="matAutocomplete"
                      [displayWith]='_displayFn.bind(this)' [panelWidth]="autocompletePanelWidthAuto ? 'auto' : false">
        <mat-option *ngFor="let state of filteredStates$ | async" [value]="state" [disabled]="readonly">
            <span class="body-1">{{ displayProperty ? getProperties(state) : state}}</span>
        </mat-option>
    </mat-autocomplete>

    <button *ngIf='fcd.form.controls[controlName].value && !(disabled || readonly) && !fcd.form.controls[controlName].disabled'
            class="text-secondary" mat-icon-button matSuffix
            type="button" (click)='resetAutoComplete()'>
        <mat-icon [icIcon]="icClose"></mat-icon>
    </button>
    <button *ngIf='!fcd.form.controls[controlName].value && !readonly' class="text-secondary" mat-icon-button matSuffix
            type="button">
        <mat-icon style='font-size: 25px; padding-left: 15px' [icIcon]="icArrowDropDown"></mat-icon>
    </button>

    <mat-hint>{{ hint }}</mat-hint>

    <base-form-input-errors
        [control]="fcd.form.controls[controlName]"
        [controlName]="controlName"
        [errorMessages]="defaultErrorMessage"
        [invalidPatternMessage]="invalidPatternMessage"
        [customErrorMessage]="customErrorMessage">
    </base-form-input-errors>

</mat-form-field>

<mat-form-field [appearance]="appearance" *ngIf='fcd.form && type === "date"' class="flex-auto w-full"
                [formGroup]='fcd.form'>
    <mat-label>{{ label }}</mat-label>
    <input [formControlName]='controlName' [matDatepicker]="datepickerRef" [matDatepickerFilter]="dateFilter"
           [min]="minDate" [max]="maxDate" (blur)="onLostFocus.emit()" matInput (dateChange)="signatureDateChanged($event)">
    <mat-datepicker-toggle [for]="datepickerRef" matSuffix></mat-datepicker-toggle>
    <mat-datepicker #datepickerRef></mat-datepicker>
    <mat-hint>{{ hint }}</mat-hint>

    <base-form-input-errors
        [control]="fcd.form.controls[controlName]"
        [controlName]="controlName"
        [errorMessages]="defaultErrorMessage"
        [invalidPatternMessage]="invalidPatternMessage"
        [customErrorMessage]="customErrorMessage">
    </base-form-input-errors>

</mat-form-field>

<mat-form-field [appearance]="appearance" *ngIf='fcd.form && type === "time"' class="flex-auto w-full"
                [formGroup]='fcd.form'>
    <mat-label>{{ label }}</mat-label>
    <input [formControlName]='controlName' (blur)="onLostFocus.emit()" format="24" [ngxMatTimepicker]="pickerB"
           matInput>
    <mat-icon matSuffix
              *ngIf="!fcd.form.disabled"
              (click)="pickerB.open()">
        watch_later
    </mat-icon>
    <ngx-mat-timepicker [confirmBtnTmpl]='okBtn' [cancelBtnTmpl]='cancelBtn' #pickerB></ngx-mat-timepicker>
    <ng-template #cancelBtn>
        <button mat-button type="button">ANNULER</button>
    </ng-template>
    <ng-template #okBtn>
        <button mat-raised-button color='primary' type="button">OK</button>
    </ng-template>
    <mat-hint>{{ hint }}</mat-hint>

    <base-form-input-errors
        [control]="fcd.form.controls[controlName]"
        [controlName]="controlName"
        [errorMessages]="defaultErrorMessage"
        [invalidPatternMessage]="invalidPatternMessage"
        [customErrorMessage]="customErrorMessage">
    </base-form-input-errors>

</mat-form-field>

<ng-container *ngIf='fcd.form && type === "checkbox"' class="flex-auto w-full" [formGroup]='fcd.form'>
    <mat-checkbox [formControlName]="controlName"> {{ label }}</mat-checkbox>
    <mat-icon *ngIf='icon' [icIcon]="icon" class="mr-3" matPrefix></mat-icon>
    <mat-hint>{{ hint }}</mat-hint>

    <base-form-input-errors
        [control]="fcd.form.controls[controlName]"
        [controlName]="controlName"
        [errorMessages]="defaultErrorMessage"
        [invalidPatternMessage]="invalidPatternMessage"
        [customErrorMessage]="customErrorMessage">
    </base-form-input-errors>

</ng-container>

<mat-form-field [appearance]="appearance" *ngIf='fcd.form && type === "file"' class="flex-auto w-full"
                [formGroup]='fcd.form'>
    <ngx-mat-file-input [formControlName]="controlName" placeholder="Required input"
                        valuePlaceholder="Sélectionner un fichier .xlsx" required></ngx-mat-file-input>
    <mat-icon class="mr-3" matSuffix>folder</mat-icon>
    <mat-error *ngIf="fcd.form.controls[controlName].errors?.required" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.required }}</mat-error>
    <mat-error *ngFor="let message of fcd.form.controls[controlName].errors?.messages">{{ message }}</mat-error>
</mat-form-field>


<ng-container *ngIf='fcd.form && type === "slide-toggle"' class="flex-auto w-full mb-4" [formGroup]='fcd.form'>
    <mat-slide-toggle [formControlName]='controlName'>{{ label }}</mat-slide-toggle>
    <mat-icon *ngIf='icon' [icIcon]="icon" class="mr-3" matPrefix></mat-icon>
</ng-container>

<ng-container *ngIf='fcd.form && type === "submit"'>
        <button *ngIf='showCancelButton' mat-button mat-dialog-close type="button">{{ cancelLabel }}</button>
        <button *ngIf="mode === CrudModeEnum.Create" (click)='onCreate.emit()' [loading]='loading' color="primary"
                mat-button type="button">{{ (customValidateButtonLabel ? customValidateButtonLabel : saveButtonLabel) }}
        </button>
        <button *ngIf="mode === CrudModeEnum.Update" (click)='onUpdate.emit()' [loading]='loading' color="primary"
                mat-button type="button">{{ (customValidateButtonLabel ? customValidateButtonLabel : updateButtonLabel) }}
        </button>
        <button *ngIf="mode === CrudModeEnum.Import" (click)='onImport.emit()' [loading]='loading' color="primary"
                mat-button type="button">IMPORTER
        </button>
</ng-container>

