import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { CrudModeEnum } from '../../../enum/crud-mode.enum';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { DEFAULT_SCROLL_SIZE } from '../../../../config/base-table.config';
import { map } from 'rxjs/operators';
import { Duplicate } from '../../../../utils/duplicate-utils';
import { ScrollManager } from '../../../../services/scroll-manager.service';
import { SnackbarService } from '../../../../services/snackbar.service';
import { AutocompleteService } from '../../../../services/scroll.service';

@Component({
    selector: 'base-sidenav',
    templateUrl: './sidenav-base-filter.component.html',
})
export  class SidenavBaseFilterComponent implements OnInit, OnDestroy {
    @Input() sidenav: MatSidenav;
    @Input() standardForms: TemplateRef<any>;
    @Output() onFilter = new EventEmitter<any>();
    @Output() onClear = new EventEmitter<any>();
    @Output() onValidate = new EventEmitter<any>();
    @Output() onClose = new EventEmitter<any>();
    subscription = new Subscription();
    mode: CrudModeEnum = CrudModeEnum.Filter;
    filters: any = {};
    forms: { [key: string]: FormGroup } = {};

    constructor(
        protected snackbarService: SnackbarService,
        protected autocompleteService: AutocompleteService,
    ) {
    }

    ngOnInit() {
        this.initialize();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    initialize() {}
    buildCustomFilters(){ return null; }

    validate() {
        const customFilters = this.buildCustomFilters();

        const queryString = new URLSearchParams(customFilters).toString();
        const url = `https://predik.eu.loclx.io/dossier/entite/numeroEntite?${queryString}`;
        const maxUrlLength = 2000;

        if (url.length > maxUrlLength) {
            this.snackbarService.danger(
                `Les filtres sont trop volumineux pour une requête GET (${url.length} caractères). Veuillez réduire les critères de recherche.`
            );
            return;
        }

        this.filters = this.validateCustomFilters(customFilters);

        this.onFilter.emit(this.filters);

        this.close();
    }

    validateCustomFilters(customFilters: any): any {
        return Object.keys(customFilters).reduce((acc, key) => {
            const value = customFilters[key];

            if (this.isValidFilter(value, key)) {
                acc[key] = value;
            }

            return acc;
        }, {});
    }

    /**
     * Vérifie qu'un objet "de comparaison" est valide.
     * @param filter L'objet qui contient { comparison, min, max }.
     * @returns true si l'objet est valide, false sinon.
     */
    private isComparisonValid(filter: any): boolean {
        if (!filter) {
            return false;
        }

        switch (filter.comparison) {
            case 'between':
                return this.isNotNullOrEmpty(filter.min) && this.isNotNullOrEmpty(filter.max);

            case 'fixed':
            case 'greaterThan':
            case 'lessThan':
                return this.isNotNullOrEmpty(filter.max);

            default:
                return false;
        }
    }

    private isNotNullOrEmpty(value: any): boolean {
        return value !== null && value !== undefined && value !== '';
    }


    isValidFilter(value: any, key: string): boolean {
        // 1) Cas value null/empty
        if (value === null || value === undefined || value === '') {
            return false;
        }

        // 2) Cas booléen
        if (typeof value === 'boolean') {
            return true;
        }

        // 3) Cas tableau
        if (Array.isArray(value)) {
            return value.length > 0;
        }

        // 4) Cas objet
        if (typeof value === 'object') {
            if ('comparison' in value) {
                return this.isComparisonValid(value);
            }
            else {
                return Object.keys(value).some(k => value[k]);
            }
        }
        return true;
    }


    clear() {
        Object.keys(this.forms).forEach(formKey => {
            const form = this.forms[formKey];
            if (form instanceof FormGroup) {
                Object.keys(form.controls).forEach(controlKey => {
                    const control = form.controls[controlKey];
                    control.setValue('');
                    control.markAsPristine();
                    control.markAsUntouched();
                });
            }
        });
        this.filters = {};
        this.onFilter.emit(this.filters);
        this.onClear.emit();
    }

    addForm(key: string, formGroup: FormGroup) {
        this.forms[key] = formGroup;
    }

    close() {
        this.sidenav.close();
    }

    getFormGroup(key: string) {
        return this.forms[key];
    }

    getItems$(key: string): Observable<any[]> {
        return this.autocompleteService.getItems$(key);
    }
}
