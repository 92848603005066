<ng-container *ngIf="canCreateMode || canUpdateMode">
    <form (ngSubmit)="save()" [formGroup]="form">
        <base-form-header [mode]='mode' createLabel='Ajouter une adresse au fournisseur'></base-form-header>

        <mat-dialog-content fxLayout="column">

            <base-form-input
                    label="Libellé"
                    controlName="label"
            ></base-form-input>

            <base-form-input
                    label='Adresse'
                    controlName='street'>
            </base-form-input>

            <base-form-input
                    label="Complément d'adresse"
                    controlName='additionalAddress'>
            </base-form-input>

            <base-form-input
                    label='Code Postal'
                    controlName='postalCode'>
            </base-form-input>

            <base-form-input
                    label='Ville'
                    controlName='city'>
            </base-form-input>

            <base-form-input
                    label='Pays'
                    type="autocomplete"
                    controlName='country'
                    [items]="countryItem"
            >
            </base-form-input>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <base-form-input
                    [loading]="loading"
                    type='submit'
                    [mode]='mode'
                    entityLabel='SUPPLIER'
                    (onCreate)='createItem()'
                    (onUpdate)='updateItem()'
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>

<ng-container *ngIf="canDeleteMode">
    <form-modal-delete-base
            [label]="data.name"
            defaultMessage="Etes-vous sûr de vouloir supprimer cette adresse ?"
            (onClose)="close()"
            (onDelete)="deleteItem()"
    ></form-modal-delete-base>
</ng-container>
