

<div class='card'>
    <div *ngIf="displayTitle || (items?.length && isBasketType)" class='p-4 border-b flex flex-row justify-between'>
        <h2 *ngIf="displayTitle" class='title m-0 mr-2'>Panier</h2>
        <h2 *ngIf="items?.length && isBasketType" class='title text-right'>Total : {{totalPrice | number: '1.2-2'}}€</h2>
    </div>
    <div class="overflow-auto h-full" style="max-height: 33.9rem">
        <div @fadeInRight *ngFor='let item of items ' class='card mt-1'>
            <div class='p-4 flex flex-col justify-between w-full h-full'
                 [ngStyle]="showExpired && item.item?.isExpired ? {'background-color': 'rgba(255, 0, 0, 0.2)'} : {}">
                <mat-icon *ngIf="canDelete && item.isEditable" (click)='remove(item)' class='flex self-end'
                          [icIcon]="icClose" size="18px"></mat-icon>
                <h4>{{ item?.item?.label || item?.label }}</h4>
                <span *ngIf="isBasketType" class='text-gray'>Prix H.T. : {{item?.item?.priceHT * item?.quantity || item?.priceHT * item?.quantity | number: '1.3-3' }}€</span>
                <div class='flex flex-row justify-between mt-2' *ngIf="!item?.item?.isExpired">
                    <p class='italic text-gray'>{{ item?.supplier?.label || item?.item?.supplier?.label }}</p>
                    <div class='flex flex-row justify-between w-20 p-0'
                         *ngIf="item.quantity && canChangeQuantity && item.isEditable"> <!-- Jordan a changé le isAllowed en isEditable ? il faut donc tou changer ici-->
                        <a (click)="updateQuantity(item, 'minus')"
                           class="w-5 h-5 leading-none flex items-center justify-center hover:bg-hover hover:text-primary text-white bg-primary"
                           mat-icon-button>
                            <mat-icon [icIcon]="icMinus" size="18px"></mat-icon>
                        </a>
                        <input (change)='_checkNumberInput($event, item)'
                               class='number-no-arrows w-1/3 min-w-10 text-center' type='number' min='1'
                               oninput="this.value = (this.value > 9999 ? 9999 : Math.max(1, this.value))" [(ngModel)]='this.isBasketTypeFromTicket ? item?.quantityBasketType : item.quantity'>
                        <a (click)="updateQuantity(item, 'plus')"
                           class="w-5 h-5 leading-none flex items-center justify-center hover:bg-hover hover:text-primary text-white bg-primary"
                           mat-icon-button>
                            <mat-icon [icIcon]="icPlus" size="18px"></mat-icon>
                        </a>
                    </div>
                    <div class='flex flex-row justify-center w-20 p-0' *ngIf="item.quantity && !canChangeQuantity">
                        {{ item.quantity }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
