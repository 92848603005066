export enum ENUM_PERMISSIONS {
    test = 'test',
    /*CREATE_TP_CUSTOMER_ACCOUNTING_DATA_LINE = 'CREATE_TP_CUSTOMER_ACCOUNTING_DATA_LINE',
    IMPORT_TP_CUSTOMER_ACCOUNTING_DATA_LINE = 'IMPORT_TP_CUSTOMER_ACCOUNTING_DATA_LINE',
    UPDATE_TP_CUSTOMER_ACCOUNTING_DATA_LINE = 'UPDATE_TP_CUSTOMER_ACCOUNTING_DATA_LINE',
    DELETE_CP_INTERNAL_CONTACT = 'DELETE_CP_INTERNAL_CONTACT',
    CREATE_CP_INTERNAL_CONTACT_INFO = 'CREATE_CP_INTERNAL_CONTACT_INFO',
    UPDATE_CP_INTERNAL_CONTACT_ASSIGNMENT = 'UPDATE_CP_INTERNAL_CONTACT_ASSIGNMENT',
    DELETE_CP_INTERNAL_CONTACT_ASSIGNMENT = 'DELETE_CP_INTERNAL_CONTACT_ASSIGNMENT',
    CREATE_CP_INTERNAL_CONTACT_POST = 'CREATE_CP_INTERNAL_CONTACT_POST',
    UPDATE_CP_INTERNAL_CONTACT_POST = 'UPDATE_CP_INTERNAL_CONTACT_POST',
    UPDATE_CP_INTERNAL_CONTACT_ORDER_VALIDATION = 'UPDATE_CP_INTERNAL_CONTACT_ORDER_VALIDATION',
    DELETE_CP_ESTABLISHMENT = 'DELETE_CP_ESTABLISHMENT',
    CREATE_CP_ESTABLISHMENT_INFO = 'CREATE_CP_ESTABLISHMENT_INFO',
    UPDATE_CP_ESTABLISHMENT_INFO_ADDRESS = 'UPDATE_CP_ESTABLISHMENT_INFO_ADDRESS',
    IMPORT_CP_ESTABLISHMENT_INFO_ADDRESS = 'IMPORT_CP_ESTABLISHMENT_INFO_ADDRESS',
    DELETE_CP_ESTABLISHMENT_INFO_ADDRESS = 'DELETE_CP_ESTABLISHMENT_INFO_ADDRESS',
    CREATE_CP_ESTABLISHMENT_ACTIVITY = 'CREATE_CP_ESTABLISHMENT_ACTIVITY',
    READ_CP_TYPE_OF_COMMITMENT = 'READ_CP_TYPE_OF_COMMITMENT',
    CREATE_CP_TYPE_OF_COMMITMENT = 'CREATE_CP_TYPE_OF_COMMITMENT',
    UPDATE_CP_TYPE_OF_COMMITMENT = 'UPDATE_CP_TYPE_OF_COMMITMENT',
    DELETE_CP_TYPE_OF_COMMITMENT = 'DELETE_CP_TYPE_OF_COMMITMENT',
    CREATE_CP_CPOM = 'CREATE_CP_CPOM',
    UPDATE_CP_CPOM = 'UPDATE_CP_CPOM',
    DELETE_CP_CPOM = 'DELETE_CP_CPOM',
    CREATE_CP_TVA = 'CREATE_CP_TVA',
    UPDATE_CP_TVA = 'UPDATE_CP_TVA',
    DELETE_CP_TVA = 'DELETE_CP_TVA',
    READ_HM = 'READ_HM',
    READ_HM_BUILDING = 'READ_HM_BUILDING',
    CREATE_HM_BUILDING = 'CREATE_HM_BUILDING',
    IMPORT_HM_BUILDING = 'IMPORT_HM_BUILDING',
    DELETE_HM_BUILDING = 'DELETE_HM_BUILDING',
    READ_HM_BUILDING_INFO = 'READ_HM_BUILDING_INFO',
    UPDATE_HM_BUILDING_INFO = 'UPDATE_HM_BUILDING_INFO',
    ADD_HM_BUILDING_INFO = 'ADD_HM_BUILDING_INFO',
    UPDATE_HM_BUILDING_INFO_ACTIVITY = 'UPDATE_HM_BUILDING_INFO_ACTIVITY',
    DELETE_HM_BUILDING_INFO_ACTIVITY = 'DELETE_HM_BUILDING_INFO_ACTIVITY',
    CREATE_HM_BUILDING_INFO_ESTABLISHMENT = 'CREATE_HM_BUILDING_INFO_ESTABLISHMENT',
    DELETE_HM_BUILDING_INFO_ESTABLISHMENT = 'DELETE_HM_BUILDING_INFO_ESTABLISHMENT',
    CREATE_HM_BUILDING_INFO_STAGE = 'CREATE_HM_BUILDING_INFO_STAGE',
    UPDATE_HM_BUILDING_INFO_STAGE = 'UPDATE_HM_BUILDING_INFO_STAGE',
    DELETE_HM_BUILDING_INFO_STAGE = 'DELETE_HM_BUILDING_INFO_STAGE',
    CREATE_HM_BUILDING_ROOM = 'CREATE_HM_BUILDING_ROOM',
    READ_HM_BUILDING_ROOM = 'READ_HM_BUILDING_ROOM',
    UPDATE_HM_BUILDING_ROOM = 'UPDATE_HM_BUILDING_ROOM',
    IMPORT_HM_BUILDING_ROOM = 'IMPORT_HM_BUILDING_ROOM',
    READ_HM_BUILDING_DOCUMENT = 'READ_HM_BUILDING_DOCUMENT',
    UPLOAD_HM_BUILDING_DOCUMENT = 'UPLOAD_HM_BUILDING_DOCUMENT',
    DOWNLOAD_HM_BUILDING_DOCUMENT = 'DOWNLOAD_HM_BUILDING_DOCUMENT',
    DELETE_HM_BUILDING_DOCUMENT = 'DELETE_HM_BUILDING_DOCUMENT',
    READ_HM_EQUIPMENT = 'READ_HM_EQUIPMENT',
    CREATE_HM_EQUIPMENT = 'CREATE_HM_EQUIPMENT',
    IMPORT_HM_EQUIPMENT = 'IMPORT_HM_EQUIPMENT',
    DELETE_HM_EQUIPMENT = 'DELETE_HM_EQUIPMENT',
    READ_HM_EQUIPMENT_INFO = 'READ_HM_EQUIPMENT_INFO',
    READ_HM_EQUIPMENT_INFO_LINK = 'READ_HM_EQUIPMENT_INFO_LINK',
    UPDATE_HM_EQUIPMENT_INFO = 'UPDATE_HM_EQUIPMENT_INFO',
    READ_HM_EQUIPMENT_INFO_LOCATION = 'READ_HM_EQUIPMENT_INFO_LOCATION',
    ADD_HM_EQUIPMENT_INFO_LOCATION = 'ADD_HM_EQUIPMENT_INFO_LOCATION',
    DELETE_HM_EQUIPMENT_INFO_LOCATION = 'DELETE_HM_EQUIPMENT_INFO_LOCATION',
    READ_HM_EQUIPMENT_INFO_ACTIVITY = 'READ_HM_EQUIPMENT_INFO_ACTIVITY',
    READ_HM_EQUIPMENT_INFO_AMORTIZATION = 'READ_HM_EQUIPMENT_INFO_AMORTIZATION',
    ADD_HM_EQUIPMENT_INFO_ACTIVITY = 'ADD_HM_EQUIPMENT_INFO_ACTIVITY',
    UPDATE_HM_EQUIPMENT_INFO_ACTIVITY = 'UPDATE_HM_EQUIPMENT_INFO_ACTIVITY',
    DELETE_HM_EQUIPMENT_INFO = 'DELETE_HM_EQUIPMENT_INFO',
    READ_HM_EQUIPMENT_MAINTENANCE_BOOK = 'READ_HM_EQUIPMENT_MAINTENANCE_BOOK',
    READ_HM_EQUIPMENT_MAINTENANCE_BOOK_LINK = 'READ_HM_EQUIPMENT_MAINTENANCE_BOOK_LINK',
    READ_HM_EQUIPMENT_CONTRACT = 'READ_HM_EQUIPMENT_CONTRACT',
    READ_HM_EQUIPMENT_LINK = 'READ_HM_EQUIPMENT_LINK',
    READ_HM_EQUIPMENT_DOCUMENT = 'READ_HM_EQUIPMENT_DOCUMENT',
    UPLOAD_HM_EQUIPMENT_DOCUMENT = 'UPLOAD_HM_EQUIPMENT_DOCUMENT',
    DOWNLOAD_HM_EQUIPMENT_DOCUMENT = 'DOWNLOAD_HM_EQUIPMENT_DOCUMENT',
    DELETE_HM_EQUIPMENT_DOCUMENT = 'DELETE_HM_EQUIPMENT_DOCUMENT',
    READ_FM = 'READ_FM',
    READ_FM_BF_SUPPLIER = 'READ_FM_BF_SUPPLIER',
    ADD_FM_BF_SUPPLIER = 'ADD_FM_BF_SUPPLIER',
    IMPORT_FM_BF_SUPPLIER = 'IMPORT_FM_BF_SUPPLIER',
    DELETE_FM_BF_SUPPLIER = 'DELETE_FM_BF_SUPPLIER',
    UPDATE_FM_BF_SUPPLIER_INFO = 'UPDATE_FM_BF_SUPPLIER_INFO',
    ADD_FM_BF_SUPPLIER_INFO_ADDRESS = 'ADD_FM_BF_SUPPLIER_INFO_ADDRESS',
    DELETE_FM_BF_SUPPLIER_INFO_ADDRESS = 'DELETE_FM_BF_SUPPLIER_INFO_ADDRESS',
    ADD_FM_BF_SUPPLIER_INFO = 'ADD_FM_BF_SUPPLIER_INFO',
    UPDATE_FM_BF_SUPPLIER_INFO_BUYING_FAMILY = 'UPDATE_FM_BF_SUPPLIER_INFO_BUYING_FAMILY',
    DELETE_FM_BF_SUPPLIER_INFO = 'DELETE_FM_BF_SUPPLIER_INFO',
    UPDATE_FM_BF_SUPPLIER_SUPPLIER_CONTACT = 'UPDATE_FM_BF_SUPPLIER_SUPPLIER_CONTACT',
    DELETE_FM_BF_SUPPLIER_SUPPLIER_CONTACT = 'DELETE_FM_BF_SUPPLIER_SUPPLIER_CONTACT',
    READ_FM_BF_SUPPLIER_DOCUMENT = 'READ_FM_BF_SUPPLIER_DOCUMENT',
    UPLOAD_FM_BF_SUPPLIER_DOCUMENT = 'UPLOAD_FM_BF_SUPPLIER_DOCUMENT',
    DOWNLOAD_FM_BF_SUPPLIER_DOCUMENT = 'DOWNLOAD_FM_BF_SUPPLIER_DOCUMENT',
    DELETE_FM_BF_SUPPLIER_DOCUMENT = 'DELETE_FM_BF_SUPPLIER_DOCUMENT',
    READ_FM_BF_SERVICE = 'READ_FM_BF_SERVICE',
    CREATE_FM_BF_SERVICE = 'CREATE_FM_BF_SERVICE',
    IMPORT_FM_BF_SERVICE = 'IMPORT_FM_BF_SERVICE',
    DELETE_FM_BF_SERVICE = 'DELETE_FM_BF_SERVICE',
    UPDATE_FM_BF_SERVICE_INFO = 'UPDATE_FM_BF_SERVICE_INFO',
    READ_FM_BF_PRODUCT = 'READ_FM_BF_PRODUCT',
    CREATE_FM_BF_PRODUCT = 'CREATE_FM_BF_PRODUCT',
    IMPORT_FM_BF_PRODUCT = 'IMPORT_FM_BF_PRODUCT',
    DELETE_FM_BF_PRODUCT = 'DELETE_FM_BF_PRODUCT',
    UPDATE_FM_BF_PRODUCT_INFO = 'UPDATE_FM_BF_PRODUCT_INFO',
    READ_FM_CONTRACT = 'READ_FM_CONTRACT',
    CREATE_FM_CONTRACT = 'CREATE_FM_CONTRACT',
    DELETE_FM_CONTRACT = 'DELETE_FM_CONTRACT',
    UPDATE_FM_CONTRACT_INFO = 'UPDATE_FM_CONTRACT_INFO',
    READ_FM_CONTRACT_INFO = 'READ_FM_CONTRACT_INFO',
    ASSIGN_FM_CONTRACT_INFO = 'ASSIGN_FM_CONTRACT_INFO',
    DELETE_FM_CONTRACT_INFO = 'DELETE_FM_CONTRACT_INFO',
    CREATE_FM_CONTRACT_INFO = 'CREATE_FM_CONTRACT_INFO',
    INPUT_FM_CONTRACT_INFO = 'INPUT_FM_CONTRACT_INFO',
    ADD_FM_CONTRACT_LINE = 'ADD_FM_CONTRACT_LINE',
    IMPORT_FM_CONTRACT_LINE = 'IMPORT_FM_CONTRACT_LINE',
    ADD_FM_CONTRACT_LINE_LOCATION = 'ADD_FM_CONTRACT_LINE_LOCATION',
    UPDATE_FM_CONTRACT_LINE_LOCATION = 'UPDATE_FM_CONTRACT_LINE_LOCATION',
    DELETE_FM_CONTRACT_LINE_LOCATION = 'DELETE_FM_CONTRACT_LINE_LOCATION',
    ADD_FM_CONTRACT_LINE_PRESTATION = 'ADD_FM_CONTRACT_LINE_PRESTATION',
    UPDATE_FM_CONTRACT_LINE_PRESTATION = 'UPDATE_FM_CONTRACT_LINE_PRESTATION',
    DELETE_FM_CONTRACT_LINE_PRESTATION = 'DELETE_FM_CONTRACT_LINE_PRESTATION',
    ADD_FM_CONTRACT_LINE_EQUIPMENT = 'ADD_FM_CONTRACT_LINE_EQUIPMENT',
    UPDATE_FM_CONTRACT_LINE_EQUIPMENT = 'UPDATE_FM_CONTRACT_LINE_EQUIPMENT',
    DELETE_FM_CONTRACT_LINE_EQUIPMENT = 'DELETE_FM_CONTRACT_LINE_EQUIPMENT',
    ADD_FM_CONTRACT_LINE_INTERVENTION = 'ADD_FM_CONTRACT_LINE_INTERVENTION',
    UPDATE_FM_CONTRACT_LINE_INTERVENTION = 'UPDATE_FM_CONTRACT_LINE_INTERVENTION',
    DELETE_FM_CONTRACT_LINE_INTERVENTION = 'DELETE_FM_CONTRACT_LINE_INTERVENTION',
    ADD_FM_CONTRACT_LINE_ENGAGMENT = 'ADD_FM_CONTRACT_LINE_ENGAGMENT',
    UPDATE_FM_CONTRACT_LINE_ENGAGMENT = 'UPDATE_FM_CONTRACT_LINE_ENGAGMENT',
    DELETE_FM_CONTRACT_LINE_ENGAGMENT = 'DELETE_FM_CONTRACT_LINE_ENGAGMENT',
    DELETE_FM_CONTRACT_LINE = 'DELETE_FM_CONTRACT_LINE',
    READ_FM_CONTRACT_DOCUMENT = 'READ_FM_CONTRACT_DOCUMENT',
    UPLOAD_FM_CONTRACT_DOCUMENT = 'UPLOAD_FM_CONTRACT_DOCUMENT',
    DOWNLOAD_FM_CONTRACT_DOCUMENT = 'DOWNLOAD_FM_CONTRACT_DOCUMENT',
    DELETE_FM_CONTRACT_DOCUMENT = 'DELETE_FM_CONTRACT_DOCUMENT',
    READ_FM_PURCHASE = 'READ_FM_PURCHASE',
    READ_FM_PURCHASE_REQUIEREMENT = 'READ_FM_PURCHASE_REQUIEREMENT',
    CREATE_FM_PURCHASE_REQUIEREMENT = 'CREATE_FM_PURCHASE_REQUIEREMENT',
    DELETE_FM_PURCHASE_REQUIEREMENT = 'DELETE_FM_PURCHASE_REQUIEREMENT',
    UPDATE_FM_PURCHASE_REQUIEREMENT_INFO = 'UPDATE_FM_PURCHASE_REQUIEREMENT_INFO',
    READ_FM_PURCHASE_REQUIEREMENT_INFO = 'READ_FM_PURCHASE_REQUIEREMENT_INFO',
    INPUT_FM_PURCHASE_REQUIEREMENT = 'INPUT_FM_PURCHASE_REQUIEREMENT',
    SAVE_FM_PURCHASE_REQUIEREMENT_BUSINESS_REFERENT = 'SAVE_FM_PURCHASE_REQUIEREMENT_BUSINESS_REFERENT',
    INPUT_FM_PURCHASE_REQUIEREMENT_PRECONISATION_PROVIDER = 'INPUT_FM_PURCHASE_REQUIEREMENT_PRECONISATION_PROVIDER',
    INPUT_FM_PURCHASE_REQUIEREMENT_PRECONISATION = 'INPUT_FM_PURCHASE_REQUIEREMENT_PRECONISATION',
    SAVE_FM_PURCHASE_REQUIEREMENT_PRECONISATION = 'SAVE_FM_PURCHASE_REQUIEREMENT_PRECONISATION',
    TRANSMIT_FM_PURCHASE_REQUIEREMENT_PRECONISATION = 'TRANSMIT_FM_PURCHASE_REQUIEREMENT_PRECONISATION',
    CREATE_FM_PURCHASE_REQUIEREMENT_PRECONISATION = 'CREATE_FM_PURCHASE_REQUIEREMENT_PRECONISATION',
    INPUT_FM_PURCHASE_REQUIEREMENT_BASKET = 'INPUT_FM_PURCHASE_REQUIEREMENT_BASKET',
    ADD_FM_PURCHASE_REQUIEREMENT_BASKET = 'ADD_FM_PURCHASE_REQUIEREMENT_BASKET',
    UPDATE_FM_PURCHASE_REQUIEREMENT_BASKET = 'UPDATE_FM_PURCHASE_REQUIEREMENT_BASKET',
    DELETE_FM_PURCHASE_REQUIEREMENT_BASKET = 'DELETE_FM_PURCHASE_REQUIEREMENT_BASKET',
    INPUT_FM_PURCHASE_REQUIEREMENT_SUMMARY = 'INPUT_FM_PURCHASE_REQUIEREMENT_SUMMARY',
    VALIDATE_FM_PURCHASE_REQUIEREMENT_SUMMARY = 'VALIDATE_FM_PURCHASE_REQUIEREMENT_SUMMARY',
    CLOSE_FM_PURCHASE_REQUIEREMENT_SUMMARY = 'CLOSE_FM_PURCHASE_REQUIEREMENT_SUMMARY',
    READ_FM_PURCHASE_QUOTE_REQUEST = 'READ_FM_PURCHASE_QUOTE_REQUEST',
    DELETE_FM_PURCHASE_QUOTE_REQUEST = 'DELETE_FM_PURCHASE_QUOTE_REQUEST',
    UPDATE_FM_PURCHASE_QUOTE_REQUEST = 'UPDATE_FM_PURCHASE_QUOTE_REQUEST',
    READ_FM_PURCHASE_QUOTE_REQUEST_ESTABLISHMENT_LINK = 'READ_FM_PURCHASE_QUOTE_REQUEST_ESTABLISHMENT_LINK',
    READ_FM_PURCHASE_QUOTE_REQUEST_BUSINESS_REFERENT_LINK = 'READ_FM_PURCHASE_QUOTE_REQUEST_BUSINESS_REFERENT_LINK',
    READ_FM_PURCHASE_QUOTE_REQUEST_REQUIREMENT_REFERENT = 'READ_FM_PURCHASE_QUOTE_REQUEST_REQUIREMENT_REFERENT',
    SAVE_FM_PURCHASE_QUOTE_REQUEST = 'SAVE_FM_PURCHASE_QUOTE_REQUEST',
    SEND_FM_PURCHASE_QUOTE_REQUEST = 'SEND_FM_PURCHASE_QUOTE_REQUEST',
    INPUT_FM_PURCHASE_QUOTE_REQUEST_RESTRAINED = 'INPUT_FM_PURCHASE_QUOTE_REQUEST_RESTRAINED',
    INPUT_FM_PURCHASE_QUOTE_REQUEST_NOT_RESTAINED = 'INPUT_FM_PURCHASE_QUOTE_REQUEST_NOT_RESTAINED',
    UPLOAD_FM_PURCHASE_QUOTE_REQUEST_DOCUMENT = 'UPLOAD_FM_PURCHASE_QUOTE_REQUEST_DOCUMENT',
    DOWNLOAD_FM_PURCHASE_QUOTE_REQUEST_DOCUMENT = 'DOWNLOAD_FM_PURCHASE_QUOTE_REQUEST_DOCUMENT',
    DELETE_FM_PURCHASE_QUOTE_REQUEST_DOCUMENT = 'DELETE_FM_PURCHASE_QUOTE_REQUEST_DOCUMENT',
    READ_FM_PURCHASE_ORDER = 'READ_FM_PURCHASE_ORDER',
    DELETE_FM_PURCHASE_ORDER = 'DELETE_FM_PURCHASE_ORDER',
    READ_FM_PURCHASE_ORDER_INFO = 'READ_FM_PURCHASE_ORDER_INFO',
    READ_FM_PURCHASE_ORDER_INFO_ESTABLISHMENT_LINK = 'READ_FM_PURCHASE_ORDER_INFO_ESTABLISHMENT_LINK',
    READ_FM_PURCHASE_ORDER_INFO_SUPPLIER_LINK = 'READ_FM_PURCHASE_ORDER_INFO_SUPPLIER_LINK',
    UPDATE_FM_PURCHASE_ORDER_INFO = 'UPDATE_FM_PURCHASE_ORDER_INFO',
    ADD_FM_PURCHASE_ORDER_BASKET = 'ADD_FM_PURCHASE_ORDER_BASKET',
    UPDATE_FM_PURCHASE_ORDER_BASKET_QUANTITY = 'UPDATE_FM_PURCHASE_ORDER_BASKET_QUANTITY',
    INPUT_FM_PURCHASE_ORDER_BASKET_ANALYTIQUE = 'INPUT_FM_PURCHASE_ORDER_BASKET_ANALYTIQUE',
    UPDATE_FM_PURCHASE_ORDER_BASKET = 'UPDATE_FM_PURCHASE_ORDER_BASKET',
    DELETE_FM_PURCHASE_ORDER_BASKET = 'DELETE_FM_PURCHASE_ORDER_BASKET',
    INPUT_FM_PURCHASE_ORDER_VENTILATION = 'INPUT_FM_PURCHASE_ORDER_VENTILATION',
    INPUT_FM_PURCHASE_ORDER_VENTILATION_PRODUCT = 'INPUT_FM_PURCHASE_ORDER_VENTILATION_PRODUCT',
    UPDATE_FM_PURCHASE_ORDER_VENTILATION = 'UPDATE_FM_PURCHASE_ORDER_VENTILATION',
    ADD_FM_PURCHASE_ORDER_ADDITIONAL_COST = 'ADD_FM_PURCHASE_ORDER_ADDITIONAL_COST',
    UPDATE_FM_PURCHASE_ORDER_ADDITIONAL_COST = 'UPDATE_FM_PURCHASE_ORDER_ADDITIONAL_COST',
    DELETE_FM_PURCHASE_ORDER_ADDITIONAL_COST = 'DELETE_FM_PURCHASE_ORDER_ADDITIONAL_COST',
    SEND_FM_PURCHASE_ORDER_SUMMARY = 'SEND_FM_PURCHASE_ORDER_SUMMARY',
    DOWNLOAD_FM_PURCHASE_ORDER_SUMMARY = 'DOWNLOAD_FM_PURCHASE_ORDER_SUMMARY',
    INPUT_FM_PURCHASE_ORDER_SUMMARY = 'INPUT_FM_PURCHASE_ORDER_SUMMARY',
    CREATE_FM_PURCHASE_ORDER_SUMMARY = 'CREATE_FM_PURCHASE_ORDER_SUMMARY',
    READ_FM_PURCHASE_ORDER_DOCUMENT = 'READ_FM_PURCHASE_ORDER_DOCUMENT',
    ADD_FM_PURCHASE_ORDER_DOCUMENT = 'ADD_FM_PURCHASE_ORDER_DOCUMENT',
    INPUT_FM_PURCHASE_ORDER_DOCUMENT = 'INPUT_FM_PURCHASE_ORDER_DOCUMENT',
    DELETE_FM_PURCHASE_ORDER_DOCUMENT = 'DELETE_FM_PURCHASE_ORDER_DOCUMENT',
    DOWNLOAD_FM_PURCHASE_ORDER_DOCUMENT = 'DOWNLOAD_FM_PURCHASE_ORDER_DOCUMENT',
    READ_FM_PURCHASEORDER_REG = 'READ_FM_PURCHASEORDER_REG',
    DELETE_FM_PURCHASEORDER_REG = 'DELETE_FM_PURCHASEORDER_REG',
    READ_FM_PURCHASEORDER_REG_INFO_ORDER = 'READ_FM_PURCHASEORDER_REG_INFO_ORDER',
    READ_FM_PURCHASEORDER_REG_INFO_ESTABLISHMENT_LINK = 'READ_FM_PURCHASEORDER_REG_INFO_ESTABLISHMENT_LINK',
    READ_FM_PURCHASEORDER_REG_INFO_SUPPLIER_LINK = 'READ_FM_PURCHASEORDER_REG_INFO_SUPPLIER_LINK',
    ADD_FM_PURCHASEORDER_REG_BASKET = 'ADD_FM_PURCHASEORDER_REG_BASKET',
    UPDATE_FM_PURCHASEORDER_REG_BASKET_QUANTITY = 'UPDATE_FM_PURCHASEORDER_REG_BASKET_QUANTITY',
    INPUT_FM_PURCHASEORDER_REG_BASKET_ANALYTIQUE = 'INPUT_FM_PURCHASEORDER_REG_BASKET_ANALYTIQUE',
    ASSIGN_FM_PURCHASEORDER_REG_BASKET_COMPTABLE = 'ASSIGN_FM_PURCHASEORDER_REG_BASKET_COMPTABLE',
    UPDATE_FM_PURCHASEORDER_REG_BASKET = 'UPDATE_FM_PURCHASEORDER_REG_BASKET',
    DELETE_FM_PURCHASEORDER_REG_BASKET = 'DELETE_FM_PURCHASEORDER_REG_BASKET',
    INPUT_FM_PURCHASEORDER_REG_VENTILATION = 'INPUT_FM_PURCHASEORDER_REG_VENTILATION',
    UPDATE_FM_PURCHASEORDER_REG_VENTILATION = 'UPDATE_FM_PURCHASEORDER_REG_VENTILATION',
    ADD_FM_PURCHASEORDER_REG_ADDITIONAL_COST = 'ADD_FM_PURCHASEORDER_REG_ADDITIONAL_COST',
    UPDATE_FM_PURCHASEORDER_REG_ADDITIONAL_COST = 'UPDATE_FM_PURCHASEORDER_REG_ADDITIONAL_COST',
    DELETE_FM_PURCHASEORDER_REG_ADDITIONAL_COST = 'DELETE_FM_PURCHASEORDER_REG_ADDITIONAL_COST',
    SAVE_FM_PURCHASEORDER_REG_SUMMARY = 'SAVE_FM_PURCHASEORDER_REG_SUMMARY',
    DOWNLOAD_FM_PURCHASEORDER_REG_DOCUMENT = 'DOWNLOAD_FM_PURCHASEORDER_REG_DOCUMENT',
    READ_FM_PURCHASE_RECEPTION = 'READ_FM_PURCHASE_RECEPTION',
    DELETE_FM_PURCHASE_RECEPTION = 'DELETE_FM_PURCHASE_RECEPTION',
    READ_FM_PURCHASE_RECEPTION_INFO = 'READ_FM_PURCHASE_RECEPTION_INFO',
    UPDATE_FM_PURCHASE_RECEPTION_INFO_DELIVERY_DATE = 'UPDATE_FM_PURCHASE_RECEPTION_INFO_DELIVERY_DATE',
    READ_FM_PURCHASE_RECEPTION_INFO_ORDER_LINK = 'READ_FM_PURCHASE_RECEPTION_INFO_ORDER_LINK',
    READ_FM_PURCHASE_RECEPTION_INFO_ESTABLISHMENT_LINK = 'READ_FM_PURCHASE_RECEPTION_INFO_ESTABLISHMENT_LINK',
    READ_FM_PURCHASE_RECEPTION_INFO_SUPPLIER_LINK = 'READ_FM_PURCHASE_RECEPTION_INFO_SUPPLIER_LINK',
    CREATE_FM_PURCHASE_RECEPTION_INFO_COMMENT = 'CREATE_FM_PURCHASE_RECEPTION_INFO_COMMENT',
    CREATE_FM_PURCHASE_RECEPTION_INFO = 'CREATE_FM_PURCHASE_RECEPTION_INFO',
    READ_FM_PURCHASE_RECEPTION_LINE = 'READ_FM_PURCHASE_RECEPTION_LINE',
    INPUT_FM_PURCHASE_RECEPTION_LINE_RECEIVED_QUANTITY = 'INPUT_FM_PURCHASE_RECEPTION_LINE_RECEIVED_QUANTITY',
    INPUT_FM_PURCHASE_RECEPTION_LINE_COMMENT = 'INPUT_FM_PURCHASE_RECEPTION_LINE_COMMENT',
    INPUT_FM_PURCHASE_RECEPTION_LINE_COMPLIANCE = 'INPUT_FM_PURCHASE_RECEPTION_LINE_COMPLIANCE',
    INPUT_FM_PURCHASE_RECEPTION_LINE_PARTIAL = 'INPUT_FM_PURCHASE_RECEPTION_LINE_PARTIAL',
    INPUT_FM_PURCHASE_RECEPTION_LINE_COMPLIANT = 'INPUT_FM_PURCHASE_RECEPTION_LINE_COMPLIANT',
    INPUT_FM_PURCHASE_RECEPTION_LINE_NOT_COMPLIANT = 'INPUT_FM_PURCHASE_RECEPTION_LINE_NOT_COMPLIANT',
    READ_FM_PURCHASE_RECEPTION_DOCUMENT = 'READ_FM_PURCHASE_RECEPTION_DOCUMENT',
    ADD_FM_PURCHASE_RECEPTION_DOCUMENT = 'ADD_FM_PURCHASE_RECEPTION_DOCUMENT',
    DELETE_FM_PURCHASE_RECEPTION_DOCUMENT = 'DELETE_FM_PURCHASE_RECEPTION_DOCUMENT',
    DOWNLOAD_FM_PURCHASE_RECEPTION_DOCUMENT = 'DOWNLOAD_FM_PURCHASE_RECEPTION_DOCUMENT',
    READ_FM_PURCHASE_INVOICE = 'READ_FM_PURCHASE_INVOICE',
    DELETE_FM_PURCHASE_INVOICE = 'DELETE_FM_PURCHASE_INVOICE',
    READ_FM_PURCHASE_INVOICE_INFO = 'READ_FM_PURCHASE_INVOICE_INFO',
    READ_FM_PURCHASE_INVOICE_INFO_ESTABLISHMENT_LINK = 'READ_FM_PURCHASE_INVOICE_INFO_ESTABLISHMENT_LINK',
    READ_FM_PURCHASE_INVOICE_INFO_SUPPLIER_LINK = 'READ_FM_PURCHASE_INVOICE_INFO_SUPPLIER_LINK',
    CREATE_FM_PURCHASE_INVOICE_INFO = 'CREATE_FM_PURCHASE_INVOICE_INFO',
    READ_FM_PURCHASE_INVOICE_SUMMARY = 'READ_FM_PURCHASE_INVOICE_SUMMARY',
    READ_FM_PURCHASE_INVOICE_INFO_DOCUMENT = 'READ_FM_PURCHASE_INVOICE_INFO_DOCUMENT',
    READ_FM_PURCHASE_GED_SUBMENU = 'READ_FM_PURCHASE_GED_SUBMENU',
    READ_MM = 'READ_MM',
    READ_MM_PLANNING = 'READ_MM_PLANNING',
    READ_MM_IC = 'READ_MM_IC',
    CREATE_MM_IC = 'CREATE_MM_IC',
    DELETE_MM_IC = 'DELETE_MM_IC',
    READ_MM_IC_INFO = 'READ_MM_IC_INFO',
    READ_MM_IC_INFO_SUPPLIER_LINK = 'READ_MM_IC_INFO_SUPPLIER_LINK',
    READ_MM_IC_INFO_RECEPTION_LINK = 'READ_MM_IC_INFO_RECEPTION_LINK',
    READ_MM_IC_CONTACT = 'READ_MM_IC_CONTACT',
    CALL_MM_IC = 'CALL_MM_IC',
    SEND_MM_IC = 'SEND_MM_IC',
    INPUT_MM_IC_PLANNING = 'INPUT_MM_IC_PLANNING',
    READ_MM_IC_PERIMETER = 'READ_MM_IC_PERIMETER',
    READ_MM_IC_SUMMARY = 'READ_MM_IC_SUMMARY',
    INPUT_MM_IC_SUMMARY = 'INPUT_MM_IC_SUMMARY',
    READ_MM_IC_SUMMARY_LINK = 'READ_MM_IC_SUMMARY_LINK',
    INPUT_MM_IC_SUMMARY_RECEPTION = 'INPUT_MM_IC_SUMMARY_RECEPTION',
    CLOSE_MM_IC_SUMMARY = 'CLOSE_MM_IC_SUMMARY',
    READ_MM_IC_DOCUMENT = 'READ_MM_IC_DOCUMENT',
    UPLOAD_MM_IC_DOCUMENT = 'UPLOAD_MM_IC_DOCUMENT',
    DOWNLOAD_MM_IC_DOCUMENT = 'DOWNLOAD_MM_IC_DOCUMENT',
    DELETE_MM_IC_DOCUMENT = 'DELETE_MM_IC_DOCUMENT',
    READ_MM_IP_PLANNING = 'READ_MM_IP_PLANNING',
    INPUT_MM_IP_PLANNING = 'INPUT_MM_IP_PLANNING',
    READ_MM_IP_INTERVENTION = 'READ_MM_IP_INTERVENTION',
    DELETE_MM_IP_INTERVENTION = 'DELETE_MM_IP_INTERVENTION',
    READ_MM_IP_INTERVENTION_INFO = 'READ_MM_IP_INTERVENTION_INFO',
    READ_MM_IP_INTERVENTION_INFO_LINK = 'READ_MM_IP_INTERVENTION_INFO_LINK',
    READ_MM_IP_INTERVENTION_CONTACT = 'READ_MM_IP_INTERVENTION_CONTACT',
    CALL_MM_IP_INTERVENTION = 'CALL_MM_IP_INTERVENTION',
    SEND_MM_IP_INTERVENTION = 'SEND_MM_IP_INTERVENTION',
    READ_MM_IP_INTERVENTION_PERIMETER = 'READ_MM_IP_INTERVENTION_PERIMETER',
    READ_MM_IP_INTERVENTION_SUMMARY_REPORT = 'READ_MM_IP_INTERVENTION_SUMMARY_REPORT',
    INPUT_MM_IP_INTERVENTION_SUMMARY_REPORT = 'INPUT_MM_IP_INTERVENTION_SUMMARY_REPORT',
    INPUT_MM_IP_INTERVENTION_SUMMARY = 'INPUT_MM_IP_INTERVENTION_SUMMARY',
    CLOSE_MM_IP_INTERVENTION_SUMMARY = 'CLOSE_MM_IP_INTERVENTION_SUMMARY',
    READ_MM_IP_INTERVENTION_DOCUMENT = 'READ_MM_IP_INTERVENTION_DOCUMENT',
    UPLOAD_MM_IP_INTERVENTION = 'UPLOAD_MM_IP_INTERVENTION',
    DOWNLOAD_MM_IP_INTERVENTION = 'DOWNLOAD_MM_IP_INTERVENTION',
    DELETE_MM_IP_INTERVENTION_DOCUMENT = 'DELETE_MM_IP_INTERVENTION_DOCUMENT',
    READ_MM_CS = 'READ_MM_CS',
    DELETE_MM_CS = 'DELETE_MM_CS',
    CREATE_MM_CS = 'CREATE_MM_CS',
    READ_MM_CS_INFO = 'READ_MM_CS_INFO',
    UPDATE_MM_CS_INFO = 'UPDATE_MM_CS_INFO',
    READ_MM_CS_INFO_ESTABLISHMENT_LINK = 'READ_MM_CS_INFO_ESTABLISHMENT_LINK',
    ADD_MM_CS_INFO_CONTACT = 'ADD_MM_CS_INFO_CONTACT',
    DELETE_MM_CS_INFO_CONTACT = 'DELETE_MM_CS_INFO_CONTACT',
    ADD_MM_CS_INFO = 'ADD_MM_CS_INFO',
    DELETE_MM_CS_INFO = 'DELETE_MM_CS_INFO',
    READ_MM_CS_ACTION = 'READ_MM_CS_ACTION',
    CREATE_MM_CS_ACTION = 'CREATE_MM_CS_ACTION',
    UPDATE_MM_CS_ACTION = 'UPDATE_MM_CS_ACTION',
    DELETE_MM_CS_ACTION = 'DELETE_MM_CS_ACTION',
    READ_MM_CS_DOCUMENT = 'READ_MM_CS_DOCUMENT',
    UPLOAD_MM_CS_DOCUMENT = 'UPLOAD_MM_CS_DOCUMENT',
    DOWNLOAD_MM_CS_DOCUMENT = 'DOWNLOAD_MM_CS_DOCUMENT',
    DELETE_MM_CS_DOCUMENT = 'DELETE_MM_CS_DOCUMENT',
    DELETE_QF_LITIGATION_LINE = 'DELETE_QF_LITIGATION_LINE',
    READ_QF_LITIGATION_INFO = 'READ_QF_LITIGATION_INFO',
    READ_QF_LITIGATION_SUPPLIER = 'READ_QF_LITIGATION_SUPPLIER',
    READ_QF_LITIGATION_ESTABLISHMENT = 'READ_QF_LITIGATION_ESTABLISHMENT',
    READ_QF_LITIGATION_ORDER = 'READ_QF_LITIGATION_ORDER',
    CREATE_QF_LITIGATION_ACTION = 'CREATE_QF_LITIGATION_ACTION',
    READ_QF_LITIGATION_DOCUMENT = 'READ_QF_LITIGATION_DOCUMENT',
    DELETE_QF_LITIGATION_DOCUMENT = 'DELETE_QF_LITIGATION_DOCUMENT',
    READ_TP_CUSTOMER_ROLE = "READ_TP_CUSTOMER_ROLE",
    CREATE_TP_CUSTOMER_ROLE = "CREATE_TP_CUSTOMER_ROLE",
    UPDATE_TP_CUSTOMER_ROLE = "UPDATE_TP_CUSTOMER_ROLE",
    UPDATE_CP_INTERNAL_ROLE = "UPDATE_CP_INTERNAL_ROLE",
    IMPORT_CP_ESTABLISHMENT_ANALYTIQUE = "IMPORT_CP_ESTABLISHMENT_ANALYTIQUE",
    ADD_CP_ESTABLISHMENT_ANALYTIQUE = "ADD_CP_ESTABLISHMENT_ANALYTIQUE",
    HIDE_CP_ESTABLISHMENT_ANALYTIQUE = "HIDE_CP_ESTABLISHMENT_ANALYTIQUE",
    DELETE_ROLE = 'DELETE_ROLE',
    ADD_CONTRACT_PRODUCT_LINE = 'ADD_CONTRACT_PRODUCT_LINE',
    DELETE_CONTRACT_PRODUCT_LINE = 'DELETE_CONTRACT_PRODUCT_LINE',
    READ_QUOTE_DOCUMENT = 'READ_QUOTE_DOCUMENT',
    READ_CORRECTIVE_INTERVENTION_PLANIFICATION = 'READ_CORRECTIVE_INTERVENTION_PLANIFICATION',
    ADD_CORRECTIVE_INTERVENTION_PERIMETER = 'ADD_CORRECTIVE_INTERVENTION_PERIMETER',
    UPDATE_CORRECTIVE_INTERVENTION_PERIMETER = 'UPDATE_CORRECTIVE_INTERVENTION_PERIMETER',
    DELETE_CORRECTIVE_INTERVENTION_PERMETER = 'DELETE_CORRECTIVE_INTERVENTION_PERMETER',
    ADD_CORRECTIVE_INTERVENTION_CONTACT = 'ADD_CORRECTIVE_INTERVENTION_CONTACT',
    DELETE_CORRECTIVE_INTERVENTION_CONTACT = 'DELETE_CORRECTIVE_INTERVENTION_CONTACT',
    SAVE_CORRECTIVE_INTERVENTION_TIME = 'SAVE_CORRECTIVE_INTERVENTION_TIME',
    ADD_PREVENTIVE_INTERVENTION_CONTACT = 'ADD_PREVENTIVE_INTERVENTION_CONTACT',
    DELETE_PREVENTIVE_INTERVENTION_CONTACT = 'DELETE_PREVENTIVE_INTERVENTION_CONTACT',
    ADD_PREVENTIVE_INTERVENTION_PERIMETER = 'ADD_PREVENTIVE_INTERVENTION_PERIMETER',
    UPDATE_PREVENTIVE_INTERVENTION_PERIMETER = 'UPDATE_PREVENTIVE_INTERVENTION_PERIMETER',
    DELETE_PREVENTIVE_INTERVENTION_PERIMETER = 'DELETE_PREVENTIVE_INTERVENTION_PERIMETER',
    ADD_FM_PURCHASE_REQUIEREMENT_OFFER = 'ADD_FM_PURCHASE_REQUIEREMENT_OFFER',
    ADD_FM_PURCHASE_REQUIEREMENT_SPOT_PRICE = 'ADD_FM_PURCHASE_REQUIEREMENT_SPOT_PRICE',
    UPDATE_FM_PURCHASE_REQUIEREMENT_BASKET_PRICE = 'UPDATE_FM_PURCHASE_REQUIEREMENT_BASKET_PRICE',
    READ_FM_PURCHASE_OFFER = 'READ_FM_PURCHASE_OFFER',
    UPDATE_FM_PURCHASE_OFFER = 'UPDATE_FM_PURCHASE_OFFER',
    ADD_FM_PURCHASE_OFFER_ESTABLISHMENT = 'ADD_FM_PURCHASE_OFFER_ESTABLISHMENT',
    ARCHIVE_FM_PURCHASE_OFFER = 'ARCHIVE_FM_PURCHASE_OFFER',
    VALIDATE_FM_PURCHASE_INVOICE_PAYMENT = 'VALIDATE_FM_PURCHASE_INVOICE_PAYMENT',
    READ_FM_PURCHASE_INVOICE_PAYMENT = 'READ_FM_PURCHASE_INVOICE_PAYMENT',
    DELETE_FM_PURCHASE_OFFER_ESTABLISHMENT = 'DELETE_FM_PURCHASE_OFFER_ESTABLISHMENT',
    DELETE_BILL_DOCUMENT = 'DELETE_BILL_DOCUMENT',
    UPLOAD_BILL_DOCUMENT = 'UPLOAD_BILL_DOCUMENT',
    DOWNLOAD_BILL_DOCUMENT = 'DOWNLOAD_BILL_DOCUMENT',
    DOWNLOAD_BRILL_PROUD_DOCUMENT = 'DOWNLOAD_BRILL_PROUD_DOCUMENT',
    ARCHIVE_TP_ACCOUNTING_DATA = 'ARCHIVE_TP_ACCOUNTING_DATA',
    ARCHIVE_CP_ESTABLISHMENT_ANALYTICS = 'ARCHIVE_CP_ESTABLISHMENT_ANALYTICS',
    UPDATE_CP_ESTABLISHMENT_ANALYTICS= 'UPDATE_CP_ESTABLISHMENT_ANALYTICS',
    ADD_PRODUCT_QUOTE = 'ADD_PRODUCT_QUOTE',
    ADD_SERVICE_QUOTE = 'ADD_SERVICE_QUOTE',
    RECEIVE_NOTIFICATION = 'RECEIVE_NOTIFICATION',*/


    READ_TP = 'READ_TP',
    READ_TP_CUSTOMER = 'READ_TP_CUSTOMER',
    READ_TP_CUSTOMER_INFO = 'READ_TP_CUSTOMER_INFO',
    UPDATE_TP_CUSTOMER_INFO = 'UPDATE_TP_CUSTOMER_INFO',
    READ_TP_CUSTOMER_ORDER_VALIDATION = 'READ_TP_CUSTOMER_ORDER_VALIDATION',
    INPUT_TP_CUSTOMER_ORDER_VALIDATION = 'INPUT_TP_CUSTOMER_ORDER_VALIDATION',
    UPDATE_TP_CUSTOMER_ORDER_VALIDATION = 'UPDATE_TP_CUSTOMER_ORDER_VALIDATION',
    VALIDATE_TP_CUSTOMER_ORDER_VALIDATION = 'VALIDATE_TP_CUSTOMER_ORDER_VALIDATION',
    READ_TP_CUSTOMER_ACCOUNTING_DATA = 'READ_TP_CUSTOMER_ACCOUNTING_DATA',
    UPDATE_TP_CUSTOMER_ACCOUNTING_DATA = 'UPDATE_TP_CUSTOMER_ACCOUNTING_DATA',
    SEARCH_TP_CUSTOMER_ACCOUNTING_DATA = 'SEARCH_TP_CUSTOMER_ACCOUNTING_DATA',
    CREATE_TP_CUSTOMER_ACCOUNTING_DATA = 'CREATE_TP_CUSTOMER_ACCOUNTING_DATA',
    DOWNLOAD_TP_CUSTOMER_ACCOUNTING_DATA = 'DOWNLOAD_TP_CUSTOMER_ACCOUNTING_DATA',
    IMPORT_TP_CUSTOMER_ACCOUNTING_DATA = 'IMPORT_TP_CUSTOMER_ACCOUNTING_DATA',
    UPDATE_TP_CUSTOMER_ACCOUNTING_DATA_ROW = 'UPDATE_TP_CUSTOMER_ACCOUNTING_DATA_ROW',
    SAVE_TP_CUSTOMER_GED = 'SAVE_TP_CUSTOMER_GED',
    READ_DASHBOARD = 'READ_DASHBOARD',
    INPUT_DASHBOARD = 'INPUT_DASHBOARD',
    DELETE_DASHBOARD = 'DELETE_DASHBOARD',
    CREATE_TICKET_REQUIREMENT = 'CREATE_TICKET_REQUIREMENT',
    READ_TICKET_REQUIREMENT_INFO = 'READ_TICKET_REQUIREMENT_INFO',
    ADD_TICKET_REQUIREMENT_INFO = 'ADD_TICKET_REQUIREMENT_INFO',
    VALIDATE_TICKET_REQUIREMENT_INFO = 'VALIDATE_TICKET_REQUIREMENT_INFO',
    INPUT_TICKET_REQUIREMENT_INFO_REFUSE = 'INPUT_TICKET_REQUIREMENT_INFO_REFUSE',
    INPUT_TICKET_REQUIREMENT_INFO = 'INPUT_TICKET_REQUIREMENT_INFO',
    CANCEL_TICKET_REQUIREMENT_INFO = 'CANCEL_TICKET_REQUIREMENT_INFO',
    READ_TICKET_REQUIREMENT = 'READ_TICKET_REQUIREMENT',
    ACCESS_TICKET_REQUIREMENT = 'ACCESS_TICKET_REQUIREMENT',
    READ_TICKET_REQUIREMENT_CATALOG = 'READ_TICKET_REQUIREMENT_CATALOG',
    SEARCH_TICKET_REQUIREMENT_CATALOG = 'SEARCH_TICKET_REQUIREMENT_CATALOG',
    INPUT_TICKET_REQUIREMENT_CATALOG_FILTER = 'INPUT_TICKET_REQUIREMENT_CATALOG_FILTER',
    ADD_TICKET_REQUIREMENT_CATALOG = 'ADD_TICKET_REQUIREMENT_CATALOG',
    UPDATE_TICKET_REQUIREMENT_CATALOG = 'UPDATE_TICKET_REQUIREMENT_CATALOG',
    DELETE_TICKET_REQUIREMENT_CATALOG = 'DELETE_TICKET_REQUIREMENT_CATALOG',
    INPUT_TICKET_REQUIREMENT_CATALOG = 'INPUT_TICKET_REQUIREMENT_CATALOG',
    READ_TICKET_REQUIREMENT_BASKET_TYPE = 'READ_TICKET_REQUIREMENT_BASKET_TYPE',
    ADD_TICKET_REQUIREMENT_BASKET_TYPE = 'ADD_TICKET_REQUIREMENT_BASKET_TYPE',
    READ_TICKET_REQUIREMENT_BASKET = 'READ_TICKET_REQUIREMENT_BASKET',
    UPDATE_TICKET_REQUIREMENT_BASKET = 'UPDATE_TICKET_REQUIREMENT_BASKET',
    DELETE_TICKET_REQUIREMENT_BASKET = 'DELETE_TICKET_REQUIREMENT_BASKET',
    INPUT_TICKET_REQUIREMENT_BASKET_FINALISE = 'INPUT_TICKET_REQUIREMENT_BASKET_FINALISE',
    VALIDATE_TICKET_REQUIREMENT_BASKET = 'VALIDATE_TICKET_REQUIREMENT_BASKET',
    INPUT_TICKET_REQUIREMENT_BASKET = 'INPUT_TICKET_REQUIREMENT_BASKET',
    READ_TICKET_REQUIREMENT_DOCUMENT = 'READ_TICKET_REQUIREMENT_DOCUMENT',
    UPLOAD_TICKET_REQUIREMENT_DOCUMENT = 'UPLOAD_TICKET_REQUIREMENT_DOCUMENT',
    DOWNLOAD_TICKET_REQUIREMENT_DOCUMENT = 'DOWNLOAD_TICKET_REQUIREMENT_DOCUMENT',
    DELETE_TICKET_REQUIREMENT_DOCUMENT = 'DELETE_TICKET_REQUIREMENT_DOCUMENT',
    CREATE_TICKET_INCIDENT = 'CREATE_TICKET_INCIDENT',
    READ_TICKET_INCIDENT_DESCRIPTION = 'READ_TICKET_INCIDENT_DESCRIPTION',
    INPUT_TICKET_INCIDENT_DESCRIPTION = 'INPUT_TICKET_INCIDENT_DESCRIPTION',
    UPDATE_TICKET_INCIDENT_DESCRIPTION = 'UPDATE_TICKET_INCIDENT_DESCRIPTION',
    SAVE_TICKET_INCIDENT_DESCRIPTION = 'SAVE_TICKET_INCIDENT_DESCRIPTION',
    INPUT_TICKET_INCIDENT_DESCRIPTION_TRANSMIT = 'INPUT_TICKET_INCIDENT_DESCRIPTION_TRANSMIT',
    READ_TICKET_INCIDENT_DOCUMENT = 'READ_TICKET_INCIDENT_DOCUMENT',
    UPLOAD_TICKET_INCIDENT_DOCUMENT = 'UPLOAD_TICKET_INCIDENT_DOCUMENT',
    DOWNLOAD_TICKET_INCIDENT_DOCUMENT = 'DOWNLOAD_TICKET_INCIDENT_DOCUMENT',
    DELETE_TICKET_INCIDENT_DOCUMENT = 'DELETE_TICKET_INCIDENT_DOCUMENT',
    READ_TICKET_TICKET = 'READ_TICKET_TICKET',
    INPUT_TICKET_TICKET = 'INPUT_TICKET_TICKET',
    ACCESS_TICKET_TICKET = 'ACCESS_TICKET_TICKET',
    INPUT_TICKET_TICKET_ARCHIVE = 'INPUT_TICKET_TICKET_ARCHIVE',
    READ_TICKET_TICKET_ARCHIVE = 'READ_TICKET_TICKET_ARCHIVE',
    READ_TICKET_TICKET_TICKET = 'READ_TICKET_TICKET_TICKET',
    INPUT_TICKET_TICKET_TICKET = 'INPUT_TICKET_TICKET_TICKET',
    ACCESS_TICKET_TICKET_TICKET = 'ACCESS_TICKET_TICKET_TICKET',
    INPUT_TICKET_TICKET_TICKET_ARCHIVE = 'INPUT_TICKET_TICKET_TICKET_ARCHIVE',
    READ_TICKET_TICKET_TICKET_ARCHIVE = 'READ_TICKET_TICKET_TICKET_ARCHIVE',
    READ_TICKET_TICKET_TICKET_IN_PROGRESS = 'READ_TICKET_TICKET_TICKET_IN_PROGRESS',
    INPUT_TICKET_TICKET_TICKET_IN_PROGRESS = 'INPUT_TICKET_TICKET_TICKET_IN_PROGRESS',
    ACCESS_TICKET_TICKET_TICKET_IN_PROGRESS = 'ACCESS_TICKET_TICKET_TICKET_IN_PROGRESS',
    INPUT_TICKET_TICKET_TICKET_IN_PROGRESS_ARCHIVE = 'INPUT_TICKET_TICKET_TICKET_IN_PROGRESS_ARCHIVE',
    READ_TICKET_TICKET_TICKET_IN_PROGRESS_ARCHIVE = 'READ_TICKET_TICKET_TICKET_IN_PROGRESS_ARCHIVE',
    READ_TICKET_TICKET_TICKET_IN_PROGRESS_FINALIZED = 'READ_TICKET_TICKET_TICKET_IN_PROGRESS_FINALIZED',
    INPUT_TICKET_TICKET_TICKET_IN_PROGRESS_FINALIZED = 'INPUT_TICKET_TICKET_TICKET_IN_PROGRESS_FINALIZED',
    ACCESS_TICKET_TICKET_TICKET_IN_PROGRESS_FINALIZED = 'ACCESS_TICKET_TICKET_TICKET_IN_PROGRESS_FINALIZED',
    INPUT_TICKET_TICKET_TICKET_IN_PROGRESS_DUPLICATE = 'INPUT_TICKET_TICKET_TICKET_IN_PROGRESS_DUPLICATE',
    INPUT_TICKET_TICKET_TICKET_IN_PROGRESS_FINALIZED_ARCHIVE = 'INPUT_TICKET_TICKET_TICKET_IN_PROGRESS_FINALIZED_ARCHIVE',
    READ_TICKET_TICKET_TICKET_IN_PROGRESS_FINALIZED_ARCHIVE = 'READ_TICKET_TICKET_TICKET_IN_PROGRESS_FINALIZED_ARCHIVE',
    READ_CP_INTERNAL_CONTACT = 'READ_CP_INTERNAL_CONTACT',
    CREATE_CP_INTERNAL_CONTACT = 'CREATE_CP_INTERNAL_CONTACT',
    INPUT_CP_INTERNAL_CONTACT_ARCHIVE = 'INPUT_CP_INTERNAL_CONTACT_ARCHIVE',
    READ_CP_INTERNAL_CONTACT_INFORMATION = 'READ_CP_INTERNAL_CONTACT_INFORMATION',
    READ_CP_INTERNAL_CONTACT_INFO = 'READ_CP_INTERNAL_CONTACT_INFO',
    UPDATE_CP_INTERNAL_CONTACT_INFO = 'UPDATE_CP_INTERNAL_CONTACT_INFO',
    ADD_CP_INTERNAL_CONTACT_INFO = 'ADD_CP_INTERNAL_CONTACT_INFO',
    UPDATE_CP_INTERNAL_CONTACT_INFO_ASSIGNMENT = 'UPDATE_CP_INTERNAL_CONTACT_INFO_ASSIGNMENT',
    DELETE_CP_INTERNAL_CONTACT_INFO = 'DELETE_CP_INTERNAL_CONTACT_INFO',
    ADD_CP_INTERNAL_CONTACT_INFO_JOB = 'ADD_CP_INTERNAL_CONTACT_INFO_JOB',
    UPDATE_CP_INTERNAL_CONTACT_INFO_JOB = 'UPDATE_CP_INTERNAL_CONTACT_INFO_JOB',
    DELETE_CP_INTERNAL_CONTACT_INFO_JOB = 'DELETE_CP_INTERNAL_CONTACT_INFO_JOB',
    READ_CP_INTERNAL_CONTACT_ORDER_VALIDATION = 'READ_CP_INTERNAL_CONTACT_ORDER_VALIDATION',
    INPUT_CP_INTERNAL_CONTACT_ORDER_VALIDATION = 'INPUT_CP_INTERNAL_CONTACT_ORDER_VALIDATION',
    READ_CP_INTERNAL_CONTACT_PERIMETER = 'READ_CP_INTERNAL_CONTACT_PERIMETER',
    INPUT_CP_INTERNAL_CONTACT_PERIMETER = 'INPUT_CP_INTERNAL_CONTACT_PERIMETER',
    READ_CP_INTERNAL_CONTACT_ROLE = 'READ_CP_INTERNAL_CONTACT_ROLE',
    INPUT_CP_INTERNAL_CONTACT_ROLE = 'INPUT_CP_INTERNAL_CONTACT_ROLE',
    ADD_CP_INTERNAL_CONTACT_ROLE = 'ADD_CP_INTERNAL_CONTACT_ROLE',
    DELETE_CP_INTERNAL_CONTACT_ROLE = 'DELETE_CP_INTERNAL_CONTACT_ROLE',
    READ_CP_ESTABLISHMENT = 'READ_CP_ESTABLISHMENT',
    CREATE_CP_ESTABLISHMENT = 'CREATE_CP_ESTABLISHMENT',
    DOWNLOAD_CP_ESTABLISHMENT = 'DOWNLOAD_CP_ESTABLISHMENT',
    IMPORT_CP_ESTABLISHMENT = 'IMPORT_CP_ESTABLISHMENT',
    INPUT_CP_ESTABLISHMENT = 'INPUT_CP_ESTABLISHMENT',
    READ_CP_ESTABLISHMENT_INFORMATION = 'READ_CP_ESTABLISHMENT_INFORMATION',
    READ_CP_ESTABLISHMENT_INFO = 'READ_CP_ESTABLISHMENT_INFO',
    UPDATE_CP_ESTABLISHMENT_INFO = 'UPDATE_CP_ESTABLISHMENT_INFO',
    ADD_CP_ESTABLISHMENT_INFO = 'ADD_CP_ESTABLISHMENT_INFO',
    UPDATE_CP_ESTABLISHMENT_ADDRESS = 'UPDATE_CP_ESTABLISHMENT_ADDRESS',
    DELETE_CP_ESTABLISHMENT_INFO = 'DELETE_CP_ESTABLISHMENT_INFO',
    DOWNLOAD_CP_ESTABLISHMENT_INFO = 'DOWNLOAD_CP_ESTABLISHMENT_INFO',
    IMPORT_CP_ESTABLISHMENT_INFO = 'IMPORT_CP_ESTABLISHMENT_INFO',
    READ_CP_ESTABLISHMENT_INTERNAL_CONTACT = 'READ_CP_ESTABLISHMENT_INTERNAL_CONTACT',
    ACCESS_CP_ESTABLISHMENT_INTERNAL_CONTACT = 'ACCESS_CP_ESTABLISHMENT_INTERNAL_CONTACT',
    CALL_CP_ESTABLISHMENT_INTERNAL_CONTACT = 'CALL_CP_ESTABLISHMENT_INTERNAL_CONTACT',
    SEND_CP_ESTABLISHMENT_INTERNAL_CONTACT = 'SEND_CP_ESTABLISHMENT_INTERNAL_CONTACT',
    READ_CP_ESTABLISHMENT_ACTIVITY = 'READ_CP_ESTABLISHMENT_ACTIVITY',
    ADD_CP_ESTABLISHMENT_ACTIVITY = 'ADD_CP_ESTABLISHMENT_ACTIVITY',
    UPDATE_CP_ESTABLISHMENT_ACTIVITY = 'UPDATE_CP_ESTABLISHMENT_ACTIVITY',
    DELETE_CP_ESTABLISHMENT_ACTIVITY = 'DELETE_CP_ESTABLISHMENT_ACTIVITY',
    READ_CP_ESTABLISHMENT_BUILDING = 'READ_CP_ESTABLISHMENT_BUILDING',
    READ_CP_ESTABLISHMENT_PURCHASE_ACTOR = 'READ_CP_ESTABLISHMENT_PURCHASE_ACTOR',
    ADD_CP_ESTABLISHMENT_PURCHASE_ACTOR = 'ADD_CP_ESTABLISHMENT_PURCHASE_ACTOR',
    UPDATE_CP_ESTABLISHMENT_PURCHASE_ACTOR = 'UPDATE_CP_ESTABLISHMENT_PURCHASE_ACTOR',
    DELETE_CP_ESTABLISHMENT_PURCHASE_ACTOR = 'DELETE_CP_ESTABLISHMENT_PURCHASE_ACTOR',
    INPUT_CP_ESTABLISHMENT_PURCHASE_ACTOR = 'INPUT_CP_ESTABLISHMENT_PURCHASE_ACTOR',
    INPUT_CP_ESTABLISHMENT_PURCHASE_ACTOR_AUTHORIZE = 'INPUT_CP_ESTABLISHMENT_PURCHASE_ACTOR_AUTHORIZE',
    READ_CP_ESTABLISHMENT_ANALYTIC_PLAN = 'READ_CP_ESTABLISHMENT_ANALYTIC_PLAN',
    ADD_CP_ESTABLISHMENT_ANALYTIC_PLAN = 'ADD_CP_ESTABLISHMENT_ANALYTIC_PLAN',
    DOWNLOAD_CP_ESTABLISHMENT_ANALYTIC_PLAN = 'DOWNLOAD_CP_ESTABLISHMENT_ANALYTIC_PLAN',
    IMPORT_CP_ESTABLISHMENT_ANALYTIC_PLAN = 'IMPORT_CP_ESTABLISHMENT_ANALYTIC_PLAN',
    UPDATE_CP_ESTABLISHMENT_ANALYTIC_PLAN = 'UPDATE_CP_ESTABLISHMENT_ANALYTIC_PLAN',
    INPUT_CP_ESTABLISHMENT_ANALYTIC_PLAN_ARCHIVE = 'INPUT_CP_ESTABLISHMENT_ANALYTIC_PLAN_ARCHIVE',
    INPUT_CP_ESTABLISHMENT_ANALYTIC_PLAN = 'INPUT_CP_ESTABLISHMENT_ANALYTIC_PLAN',
    READ_CP = 'READ_CP',
    ADD_CP = 'ADD_CP',
    INPUT_CP = 'INPUT_CP',
    READ_CP_ARCHIVE = 'READ_CP_ARCHIVE',
    READ_CP_INFO = 'READ_CP_INFO',
    UPDATE_CP_INFO = 'UPDATE_CP_INFO',
    UPDATE_CP_INFO_QUANTITY = 'UPDATE_CP_INFO_QUANTITY',
    DELETE_CP_INFO = 'DELETE_CP_INFO',
    READ_CP_CATALOG = 'READ_CP_CATALOG',
    UPDATE_CP_CATALOG = 'UPDATE_CP_CATALOG',
    INPUT_CP_INTERNAL_CONTACT = 'INPUT_CP_INTERNAL_CONTACT',
    READ_CP_TVA = 'READ_CP_TVA',
    ADD_CP_TVA = 'ADD_CP_TVA',
    INPUT_CP_TVA = 'INPUT_CP_TVA',
    READ_CP_TVA_ARCHIVE = 'READ_CP_TVA_ARCHIVE',
    READ_CP_TVA_INFO = 'READ_CP_TVA_INFO',
    UPDATE_CP_TVA_INFO = 'UPDATE_CP_TVA_INFO',
    ADD_CP_TVA_INFO = 'ADD_CP_TVA_INFO',
    READ_CP_ADMIN_GENERIC = 'READ_CP_ADMIN_GENERIC',
    INPUT_CP_ADMIN_GENERIC = 'INPUT_CP_ADMIN_GENERIC',
    ADD_CP_ADMIN_GENERIC = 'ADD_CP_ADMIN_GENERIC',
    READ_BUILDING = 'READ_BUILDING',
    ADD_BUILDING = 'ADD_BUILDING',
    DOWNLOAD_BUILDING = 'DOWNLOAD_BUILDING',
    IMPORT_BUILDING = 'IMPORT_BUILDING',
    INPUT_BUILDING = 'INPUT_BUILDING',
    READ_BUILDING_ARCHIVE = 'READ_BUILDING_ARCHIVE',
    READ_BUILDING_INFO = 'READ_BUILDING_INFO',
    UPDATE_BUILDING_INFO = 'UPDATE_BUILDING_INFO',
    ADD_BUILDING_INFO_ACTIVITY = 'ADD_BUILDING_INFO_ACTIVITY',
    UPDATE_BUILDING_INFO_ACTIVITY = 'UPDATE_BUILDING_INFO_ACTIVITY',
    DELETE_BUILDING_INFO_ACTIVITY = 'DELETE_BUILDING_INFO_ACTIVITY',
    ADD_BUILDING_INFO_ESTABLISHMENT = 'ADD_BUILDING_INFO_ESTABLISHMENT',
    UPDATE_BUILDING_INFO_ESTABLISHMENT = 'UPDATE_BUILDING_INFO_ESTABLISHMENT',
    DELETE_BUILDING_INFO_ESTABLISHMENT = 'DELETE_BUILDING_INFO_ESTABLISHMENT',
    ADD_BUILDING_INFO_ADDRESS = 'ADD_BUILDING_INFO_ADDRESS',
    UPDATE_BUILDING_INFO_ADDRESS = 'UPDATE_BUILDING_INFO_ADDRESS',
    DELETE_BUILDING_INFO_ADDRESS = 'DELETE_BUILDING_INFO_ADDRESS',
    ADD_BUILDING_INFO_FLOOR = 'ADD_BUILDING_INFO_FLOOR',
    UPDATE_BUILDING_INFO_FLOOR = 'UPDATE_BUILDING_INFO_FLOOR',
    DELETE_BUILDING_INFO_FLOOR = 'DELETE_BUILDING_INFO_FLOOR',
    READ_BUILDING_ROOM = 'READ_BUILDING_ROOM',
    ADD_BUILDING_ROOM = 'ADD_BUILDING_ROOM',
    UPDATE_BUILDING_ROOM = 'UPDATE_BUILDING_ROOM',
    DOWNLOAD_BUILDING_ROOM = 'DOWNLOAD_BUILDING_ROOM',
    IMPORT_BUILDING_ROOM = 'IMPORT_BUILDING_ROOM',
    DELETE_BUILDING_ROOM = 'DELETE_BUILDING_ROOM',
    UPLOAD_BUILDING_DOCUMENT = 'UPLOAD_BUILDING_DOCUMENT',
    DOWNLOAD_BUILDING_DOCUMENT = 'DOWNLOAD_BUILDING_DOCUMENT',
    DELETE_BUILDING_DOCUMENT = 'DELETE_BUILDING_DOCUMENT',
    READ_EQUIPMENT = 'READ_EQUIPMENT',
    ADD_EQUIPMENT = 'ADD_EQUIPMENT',
    DOWNLOAD_EQUIPMENT = 'DOWNLOAD_EQUIPMENT',
    IMPORT_EQUIPMENT = 'IMPORT_EQUIPMENT',
    INPUT_EQUIPMENT = 'INPUT_EQUIPMENT',
    READ_EQUIPMENT_ARCHIVE = 'READ_EQUIPMENT_ARCHIVE',
    READ_EQUIPMENT_INFO = 'READ_EQUIPMENT_INFO',
    UPDATE_EQUIPMENT_INFO = 'UPDATE_EQUIPMENT_INFO',
    ACCESS_EQUIPMENT_INFO_ESTABLISHMENT = 'ACCESS_EQUIPMENT_INFO_ESTABLISHMENT',
    ACCESS_EQUIPMENT_INFO_REFERENT = 'ACCESS_EQUIPMENT_INFO_REFERENT',
    READ_EQUIPMENT_INFO_LOCATION = 'READ_EQUIPMENT_INFO_LOCATION',
    ADD_EQUIPMENT_INFO_LOCATION = 'ADD_EQUIPMENT_INFO_LOCATION',
    DELETE_EQUIPMENT_INFO_LOCATION = 'DELETE_EQUIPMENT_INFO_LOCATION',
    READ_EQUIPMENT_INFO_HISTORY = 'READ_EQUIPMENT_INFO_HISTORY',
    READ_EQUIPMENT_INFO_TABLE = 'READ_EQUIPMENT_INFO_TABLE',
    READ_EQUIPMENT_INFO_ACTIVITY = 'READ_EQUIPMENT_INFO_ACTIVITY',
    ADD_EQUIPMENT_INFO = 'ADD_EQUIPMENT_INFO',
    UPDATE_EQUIPMENT_INFO_ACTIVITY = 'UPDATE_EQUIPMENT_INFO_ACTIVITY',
    DELETE_EQUIPMENT_INFO = 'DELETE_EQUIPMENT_INFO',
    READ_EQUIPMENT_MAINTENANCE_BOOK_INTERVENTION = 'READ_EQUIPMENT_MAINTENANCE_BOOK_INTERVENTION',
    ACCESS_EQUIPMENT_MAINTENANCE_BOOK_INTERVENTION = 'ACCESS_EQUIPMENT_MAINTENANCE_BOOK_INTERVENTION',
    READ_EQUIPMENT_MAINTENANCE_BOOK = 'READ_EQUIPMENT_MAINTENANCE_BOOK',
    ACCESS_EQUIPMENT_MAINTENANCE_BOOK = 'ACCESS_EQUIPMENT_MAINTENANCE_BOOK',
    READ_EQUIPMENT_INFO_EQUIPMENT = 'READ_EQUIPMENT_INFO_EQUIPMENT',
    ACCESS_EQUIPMENT_INFO = 'ACCESS_EQUIPMENT_INFO',
    ADD_EQUIPMENT_DOCUMENT = 'ADD_EQUIPMENT_DOCUMENT',
    DOWNLOAD_EQUIPMENT_DOCUMENT = 'DOWNLOAD_EQUIPMENT_DOCUMENT',
    DELETE_EQUIPMENT_DOCUMENT = 'DELETE_EQUIPMENT_DOCUMENT',
    STOCK = 'STOCK',
    RH = 'RH',
    RH_INTERNAL_SERVICE = 'RH_INTERNAL_SERVICE',
    RH_INTERNAL_RESOURCE = 'RH_INTERNAL_RESOURCE',
    READ_FM_PURCHASE_PROCESS_FINALIZE_ORDER = 'READ_FM_PURCHASE_PROCESS_FINALIZE_ORDER',
    INPUT_FM_PURCHASE_PROCESS_FINALIZE_ORDER_FILTER = 'INPUT_FM_PURCHASE_PROCESS_FINALIZE_ORDER_FILTER',
    INPUT_FM_PURCHASE_PROCESS_FINALIZE_ORDER = 'INPUT_FM_PURCHASE_PROCESS_FINALIZE_ORDER',
    INPUT_FM_PURCHASE_PROCESS_FINALIZE_ORDER_ALL = 'INPUT_FM_PURCHASE_PROCESS_FINALIZE_ORDER_ALL',
    INPUT_FM_PURCHASE_PROCESS_FINALIZE_ORDER_REFERENCE = 'INPUT_FM_PURCHASE_PROCESS_FINALIZE_ORDER_REFERENCE',
    ACCESS_FM_PURCHASE_PROCESS_FINALIZE_ORDER = 'ACCESS_FM_PURCHASE_PROCESS_FINALIZE_ORDER',
    INPUT_FM_PURCHASE_PROCESS_FINALIZE_ORDER_WAITING = 'INPUT_FM_PURCHASE_PROCESS_FINALIZE_ORDER_WAITING',
    ACCESS_FM_PURCHASE_PROCESS_FINALIZE_ORDER_WAITING = 'ACCESS_FM_PURCHASE_PROCESS_FINALIZE_ORDER_WAITING',
    SAVE_FM_PURCHASE_PROCESS_FINALIZE_ORDER = 'SAVE_FM_PURCHASE_PROCESS_FINALIZE_ORDER',
    UPDATE_FM_PURCHASE_PROCESS_FINALIZE_ORDER = 'UPDATE_FM_PURCHASE_PROCESS_FINALIZE_ORDER',
    ADD_FM_PURCHASE_PROCESS_FINALIZE_ORDER = 'ADD_FM_PURCHASE_PROCESS_FINALIZE_ORDER',
    DELETE_FM_PURCHASE_PROCESS_FINALIZE_ORDER = 'DELETE_FM_PURCHASE_PROCESS_FINALIZE_ORDER',
    UPDATE_FM_PURCHASE_PROCESS_FINALIZE_ORDER_AVAILABLE_STOCK = 'UPDATE_FM_PURCHASE_PROCESS_FINALIZE_ORDER_AVAILABLE_STOCK',
    UPDATE_FM_PURCHASE_PROCESS_FINALIZE_ORDER_MINIMUM_STOCK = 'UPDATE_FM_PURCHASE_PROCESS_FINALIZE_ORDER_MINIMUM_STOCK',
    UPDATE_FM_PURCHASE_PROCESS_FINALIZE_ORDER_ORDER_TRESHOLD = 'UPDATE_FM_PURCHASE_PROCESS_FINALIZE_ORDER_ORDER_TRESHOLD',
    UPDATE_FM_PURCHASE_PROCESS_FINALIZE_ORDER_QUANTITY_ORDER = 'UPDATE_FM_PURCHASE_PROCESS_FINALIZE_ORDER_QUANTITY_ORDER',
    INPUT_FM_PURCHASE_PROCESS_FINALIZE_ORDER_OPEN = 'INPUT_FM_PURCHASE_PROCESS_FINALIZE_ORDER_OPEN',
    ACCESS_FM_PURCHASE_PROCESS_FINALIZE_ORDER_CONTENT = 'ACCESS_FM_PURCHASE_PROCESS_FINALIZE_ORDER_CONTENT',
    INPUT_FM_PURCHASE_PROCESS_FINALIZE_ORDER_STOCK = 'INPUT_FM_PURCHASE_PROCESS_FINALIZE_ORDER_STOCK',
    SEND_FM_PURCHASE_PROCESS_FINALIZE_ORDER = 'SEND_FM_PURCHASE_PROCESS_FINALIZE_ORDER',
    INPUT_FM_PURCHASE_PROCESS_FINALIZE_ORDER_PLANNING = 'INPUT_FM_PURCHASE_PROCESS_FINALIZE_ORDER_PLANNING',
    INPUT_FM_PURCHASE_PROCESS_FINALIZE_ORDER_PREORDER = 'INPUT_FM_PURCHASE_PROCESS_FINALIZE_ORDER_PREORDER',
    READ_FM_PURCHASE_PROCESS_ORDER_RECAP = 'READ_FM_PURCHASE_PROCESS_ORDER_RECAP',
    INPUT_FM_PURCHASE_PROCESS_ORDER = 'INPUT_FM_PURCHASE_PROCESS_ORDER',
    READ_FM_PURCHASE_PROCESS_ORDER = 'READ_FM_PURCHASE_PROCESS_ORDER',
    READ_FM_PURCHASE_PROCESS_ORDER_INFO = 'READ_FM_PURCHASE_PROCESS_ORDER_INFO',
    ACCESS_FM_PURCHASE_PROCESS_ORDER_INFO_ESTABLISHMENT = 'ACCESS_FM_PURCHASE_PROCESS_ORDER_INFO_ESTABLISHMENT',
    ACCESS_FM_PURCHASE_PROCESS_ORDER_INFO = 'ACCESS_FM_PURCHASE_PROCESS_ORDER_INFO',
    ADD_FM_PURCHASE_PROCESS_ORDER_INFO = 'ADD_FM_PURCHASE_PROCESS_ORDER_INFO',
    UPDATE_FM_PURCHASE_PROCESS_ORDER_INFO = 'UPDATE_FM_PURCHASE_PROCESS_ORDER_INFO',
    ADD_FM_PURCHASE_PROCESS_ORDER_BASKET = 'ADD_FM_PURCHASE_PROCESS_ORDER_BASKET',
    UPDATE_FM_PURCHASE_PROCESS_ORDER_BASKET = 'UPDATE_FM_PURCHASE_PROCESS_ORDER_BASKET',
    INPUT_FM_PURCHASE_PROCESS_ORDER_BASKET = 'INPUT_FM_PURCHASE_PROCESS_ORDER_BASKET',
    INPUT_FM_PURCHASE_PROCESS_ORDER_BASKET_ANALYTIC = 'INPUT_FM_PURCHASE_PROCESS_ORDER_BASKET_ANALYTIC',
    ASSIGN_FM_PURCHASE_PROCESS_ORDER_BASKET = 'ASSIGN_FM_PURCHASE_PROCESS_ORDER_BASKET',
    ASSIGN_FM_PURCHASE_PROCESS_ORDER_BASKET_ANALYTIC = 'ASSIGN_FM_PURCHASE_PROCESS_ORDER_BASKET_ANALYTIC',
    UPDATE_FM_PURCHASE_PROCESS_ORDER_BASKET_ELEMENT = 'UPDATE_FM_PURCHASE_PROCESS_ORDER_BASKET_ELEMENT',
    DELETE_FM_PURCHASE_PROCESS_ORDER_BASKET = 'DELETE_FM_PURCHASE_PROCESS_ORDER_BASKET',
    ADD_FM_PURCHASE_PROCESS_ORDER_BASKET_ADDITIONAL_COSTS = 'ADD_FM_PURCHASE_PROCESS_ORDER_BASKET_ADDITIONAL_COSTS',
    UPDATE_FM_PURCHASE_PROCESS_ORDER_BASKET_ADDITIONAL_COSTS = 'UPDATE_FM_PURCHASE_PROCESS_ORDER_BASKET_ADDITIONAL_COSTS',
    DELETE_FM_PURCHASE_PROCESS_ORDER_BASKET_ADDITIONAL_COSTS = 'DELETE_FM_PURCHASE_PROCESS_ORDER_BASKET_ADDITIONAL_COSTS',
    READ_FM_PURCHASE_PROCESS_ORDER_ACTIVITY = 'READ_FM_PURCHASE_PROCESS_ORDER_ACTIVITY',
    INPUT_FM_PURCHASE_PROCESS_ORDER_ACTIVITY = 'INPUT_FM_PURCHASE_PROCESS_ORDER_ACTIVITY',
    UPDATE_FM_PURCHASE_PROCESS_ORDER_ACTIVITY = 'UPDATE_FM_PURCHASE_PROCESS_ORDER_ACTIVITY',
    ADD_FM_PURCHASE_PROCESS_ORDER_ANALYTIC_PLAN = 'ADD_FM_PURCHASE_PROCESS_ORDER_ANALYTIC_PLAN',
    UPDATE_FM_PURCHASE_PROCESS_ORDER_ANALYTIC_PLAN = 'UPDATE_FM_PURCHASE_PROCESS_ORDER_ANALYTIC_PLAN',
    DELETE_FM_PURCHASE_PROCESS_ORDER_ANALYTIC_PLAN = 'DELETE_FM_PURCHASE_PROCESS_ORDER_ANALYTIC_PLAN',
    VALIDATE_FM_PURCHASE_PROCESS_ORDER_ANALYTIC_PLAN = 'VALIDATE_FM_PURCHASE_PROCESS_ORDER_ANALYTIC_PLAN',
    SEND_FM_PURCHASE_PROCESS_ORDER_SUMMARY = 'SEND_FM_PURCHASE_PROCESS_ORDER_SUMMARY',
    DOWNLOAD_FM_PURCHASE_PROCESS_ORDER_SUMMARY = 'DOWNLOAD_FM_PURCHASE_PROCESS_ORDER_SUMMARY',
    INPUT_FM_PURCHASE_PROCESS_ORDER_SUMMARY = 'INPUT_FM_PURCHASE_PROCESS_ORDER_SUMMARY',
    ADD_FM_PURCHASE_PROCESS_ORDER_SUMMARY = 'ADD_FM_PURCHASE_PROCESS_ORDER_SUMMARY',
    ACCESS_FM_PURCHASE_PROCESS_ORDER_SUMMARY = 'ACCESS_FM_PURCHASE_PROCESS_ORDER_SUMMARY',
    UPLOAD_FM_PURCHASE_PROCESS_ORDER_DOCUMENT = 'UPLOAD_FM_PURCHASE_PROCESS_ORDER_DOCUMENT',
    INPUT_FM_PURCHASE_PROCESS_ORDER_DOCUMENT = 'INPUT_FM_PURCHASE_PROCESS_ORDER_DOCUMENT',
    DELETE_FM_PURCHASE_PROCESS_ORDER_DOCUMENT = 'DELETE_FM_PURCHASE_PROCESS_ORDER_DOCUMENT',
    DOWNLOAD_FM_PURCHASE_PROCESS_ORDER_DOCUMENT = 'DOWNLOAD_FM_PURCHASE_PROCESS_ORDER_DOCUMENT',
    READ_FM_PURCHASE_PROCESS_QUOTE = 'READ_FM_PURCHASE_PROCESS_QUOTE',
    INPUT_FM_PURCHASE_PROCESS_QUOTE = 'INPUT_FM_PURCHASE_PROCESS_QUOTE',
    READ_FM_PURCHASE_PROCESS_QUOTE_ARCHIVE = 'READ_FM_PURCHASE_PROCESS_QUOTE_ARCHIVE',
    READ_FM_PURCHASE_PROCESS_QUOTE_QUOTE = 'READ_FM_PURCHASE_PROCESS_QUOTE_QUOTE',
    ACCESS_FM_PURCHASE_PROCESS_QUOTE_QUOTE_ESTABLISHMENT = 'ACCESS_FM_PURCHASE_PROCESS_QUOTE_QUOTE_ESTABLISHMENT',
    ACCESS_FM_PURCHASE_PROCESS_QUOTE_QUOTE_SUPPLIER = 'ACCESS_FM_PURCHASE_PROCESS_QUOTE_QUOTE_SUPPLIER',
    ACCESS_FM_PURCHASE_PROCESS_QUOTE_QUOTE = 'ACCESS_FM_PURCHASE_PROCESS_QUOTE_QUOTE',
    INPUT_FM_PURCHASE_PROCESS_QUOTE_QUOTE = 'INPUT_FM_PURCHASE_PROCESS_QUOTE_QUOTE',
    INPUT_FM_PURCHASE_PROCESS_QUOTE_QUOTE_HOLD = 'INPUT_FM_PURCHASE_PROCESS_QUOTE_QUOTE_HOLD',
    INPUT_FM_PURCHASE_PROCESS_QUOTE_QUOTE_NOT_HOLD = 'INPUT_FM_PURCHASE_PROCESS_QUOTE_QUOTE_NOT_HOLD',
    UPLOAD_FM_PURCHASE_PROCESS_QUOTE_DOCUMENT = 'UPLOAD_FM_PURCHASE_PROCESS_QUOTE_DOCUMENT',
    DOWNLOAD_FM_PURCHASE_PROCESS_QUOTE_DOCUMENT = 'DOWNLOAD_FM_PURCHASE_PROCESS_QUOTE_DOCUMENT',
    DELETE_FM_PURCHASE_PROCESS_QUOTE_DOCUMENT = 'DELETE_FM_PURCHASE_PROCESS_QUOTE_DOCUMENT',
    READ_FM_PURCHASE_PROCESS_RECEPTION = 'READ_FM_PURCHASE_PROCESS_RECEPTION',
    INPUT_FM_PURCHASE_PROCESS_RECEPTION = 'INPUT_FM_PURCHASE_PROCESS_RECEPTION',
    READ_FM_PURCHASE_PROCESS_RECEPTION_ARCHIVE = 'READ_FM_PURCHASE_PROCESS_RECEPTION_ARCHIVE',
    READ_FM_PURCHASE_PROCESS_RECEPTION_INFO = 'READ_FM_PURCHASE_PROCESS_RECEPTION_INFO',
    UPDATE_FM_PURCHASE_PROCESS_RECEPTION_INFO = 'UPDATE_FM_PURCHASE_PROCESS_RECEPTION_INFO',
    ACCESS_FM_PURCHASE_PROCESS_RECEPTION_INFO = 'ACCESS_FM_PURCHASE_PROCESS_RECEPTION_INFO',
    ACCESS_FM_PURCHASE_PROCESS_RECEPTION_INFO_ESTABLISHMENT = 'ACCESS_FM_PURCHASE_PROCESS_RECEPTION_INFO_ESTABLISHMENT',
    ACCESS_FM_PURCHASE_PROCESS_RECEPTION_INFO_SUPPLIER = 'ACCESS_FM_PURCHASE_PROCESS_RECEPTION_INFO_SUPPLIER',
    SAVE_FM_PURCHASE_PROCESS_RECEPTION_INFO = 'SAVE_FM_PURCHASE_PROCESS_RECEPTION_INFO',
    UPDATE_FM_PURCHASE_PROCESS_RECEPTION_INFO_REMARK = 'UPDATE_FM_PURCHASE_PROCESS_RECEPTION_INFO_REMARK',
    CREATE_FM_PURCHASE_PROCESS_RECEPTION_INFO = 'CREATE_FM_PURCHASE_PROCESS_RECEPTION_INFO',
    READ_FM_PURCHASE_PROCESS_RECEPTION_LINE = 'READ_FM_PURCHASE_PROCESS_RECEPTION_LINE',
    UPDATE_FM_PURCHASE_PROCESS_RECEPTION_LINE = 'UPDATE_FM_PURCHASE_PROCESS_RECEPTION_LINE',
    INPUT_FM_PURCHASE_PROCESS_RECEPTION_LINE_REMARK = 'INPUT_FM_PURCHASE_PROCESS_RECEPTION_LINE_REMARK',
    INPUT_FM_PURCHASE_PROCESS_RECEPTION_LINE_PARTIAL = 'INPUT_FM_PURCHASE_PROCESS_RECEPTION_LINE_PARTIAL',
    INPUT_FM_PURCHASE_PROCESS_RECEPTION_LINE = 'INPUT_FM_PURCHASE_PROCESS_RECEPTION_LINE',
    UPLOAD_FM_PURCHASE_PROCESS_RECEPTION_DOCUMENT = 'UPLOAD_FM_PURCHASE_PROCESS_RECEPTION_DOCUMENT',
    DELETE_FM_PURCHASE_PROCESS_RECEPTION_DOCUMENT = 'DELETE_FM_PURCHASE_PROCESS_RECEPTION_DOCUMENT',
    DOWNLOAD_FM_PURCHASE_PROCESS_RECEPTION_DOCUMENT = 'DOWNLOAD_FM_PURCHASE_PROCESS_RECEPTION_DOCUMENT',
    READ_FM_PURCHASE_PROCESS_RECEPTION_INTERVENTION = 'READ_FM_PURCHASE_PROCESS_RECEPTION_INTERVENTION',
    INPUT_FM_PURCHASE_PROCESS_RECEPTION_INTERVENTION = 'INPUT_FM_PURCHASE_PROCESS_RECEPTION_INTERVENTION',
    READ_FM_PURCHASE_PROCESS_RECEPTION_INTERVENTION_ARCHIVE = 'READ_FM_PURCHASE_PROCESS_RECEPTION_INTERVENTION_ARCHIVE',
    READ_FM_PURCHASE_PROCESS_RECEPTION_INTERVENTION_INFO = 'READ_FM_PURCHASE_PROCESS_RECEPTION_INTERVENTION_INFO',
    UPDATE_FM_PURCHASE_PROCESS_RECEPTION_INTERVENTION_INFO = 'UPDATE_FM_PURCHASE_PROCESS_RECEPTION_INTERVENTION_INFO',
    ACCESS_FM_PURCHASE_PROCESS_RECEPTION_INTERVENTION_INFO = 'ACCESS_FM_PURCHASE_PROCESS_RECEPTION_INTERVENTION_INFO',
    SAVE_FM_PURCHASE_PROCESS_RECEPTION_INTERVENTION_INFO = 'SAVE_FM_PURCHASE_PROCESS_RECEPTION_INTERVENTION_INFO',
    UPDATE_FM_PURCHASE_PROCESS_RECEPTION_INTERVENTION_INFO_REMARK = 'UPDATE_FM_PURCHASE_PROCESS_RECEPTION_INTERVENTION_INFO_REMARK',
    INPUT_FM_PURCHASE_PROCESS_RECEPTION_INTERVENTION_INFO_OK = 'INPUT_FM_PURCHASE_PROCESS_RECEPTION_INTERVENTION_INFO_OK',
    INPUT_FM_PURCHASE_PROCESS_RECEPTION_INTERVENTION_INFO_NOT_OK = 'INPUT_FM_PURCHASE_PROCESS_RECEPTION_INTERVENTION_INFO_NOT_OK',
    CREATE_FM_PURCHASE_PROCESS_RECEPTION_INTERVENTION_INFO = 'CREATE_FM_PURCHASE_PROCESS_RECEPTION_INTERVENTION_INFO',
    INPUT_FM_PURCHASE_PROCESS_RECEPTION_INTERVENTION_INFO = 'INPUT_FM_PURCHASE_PROCESS_RECEPTION_INTERVENTION_INFO',
    READ_FM_PURCHASE_PROCESS_INVOICE = 'READ_FM_PURCHASE_PROCESS_INVOICE',
    INPUT_FM_PURCHASE_PROCESS_INVOICE = 'INPUT_FM_PURCHASE_PROCESS_INVOICE',
    READ_FM_PURCHASE_PROCESS_INVOICE_ARCHIVE = 'READ_FM_PURCHASE_PROCESS_INVOICE_ARCHIVE',
    ACCESS_FM_PURCHASE_PROCESS_INVOICE = 'ACCESS_FM_PURCHASE_PROCESS_INVOICE',
    READ_FM_PURCHASE_PROCESS_INVOICE_INFO = 'READ_FM_PURCHASE_PROCESS_INVOICE_INFO',
    ACCESS_FM_PURCHASE_PROCESS_INVOICE_INFO_ESTABLISHMENT = 'ACCESS_FM_PURCHASE_PROCESS_INVOICE_INFO_ESTABLISHMENT',
    ACCESS_FM_PURCHASE_PROCESS_INVOICE_INFO_SUPPLIER = 'ACCESS_FM_PURCHASE_PROCESS_INVOICE_INFO_SUPPLIER',
    ACCESS_FM_PURCHASE_PROCESS_INVOICE_INFO = 'ACCESS_FM_PURCHASE_PROCESS_INVOICE_INFO',
    CREATE_FM_PURCHASE_PROCESS_INVOICE_INFO = 'CREATE_FM_PURCHASE_PROCESS_INVOICE_INFO',
    READ_FM_PURCHASE_PROCESS_INVOICE_ACCOUNTING_ENTRY = 'READ_FM_PURCHASE_PROCESS_INVOICE_ACCOUNTING_ENTRY',
    UPDATE_FM_PURCHASE_PROCESS_INVOICE_ACCOUNTING_ENTRY = 'UPDATE_FM_PURCHASE_PROCESS_INVOICE_ACCOUNTING_ENTRY',
    READ_FM_PURCHASE_PROCESS_INVOICE_SUMMARY = 'READ_FM_PURCHASE_PROCESS_INVOICE_SUMMARY',
    ACCESS_FM_PURCHASE_PROCESS_INVOICE_SUMMARY_ORDER = 'ACCESS_FM_PURCHASE_PROCESS_INVOICE_SUMMARY_ORDER',
    ACCESS_FM_PURCHASE_PROCESS_INVOICE_SUMMARY = 'ACCESS_FM_PURCHASE_PROCESS_INVOICE_SUMMARY',
    UPLOAD_FM_PURCHASE_PROCESS_INVOICE_DOCUMENT = 'UPLOAD_FM_PURCHASE_PROCESS_INVOICE_DOCUMENT',
    DELETE_FM_PURCHASE_PROCESS_INVOICE_DOCUMENT = 'DELETE_FM_PURCHASE_PROCESS_INVOICE_DOCUMENT',
    DOWNLOAD_FM_PURCHASE_PROCESS_INVOICE_DOCUMENT = 'DOWNLOAD_FM_PURCHASE_PROCESS_INVOICE_DOCUMENT',
    READ_FM_PURCHASE_PROCESS_INVOICE_REG = 'READ_FM_PURCHASE_PROCESS_INVOICE_REG',
    INPUT_FM_PURCHASE_PROCESS_INVOICE_REG = 'INPUT_FM_PURCHASE_PROCESS_INVOICE_REG',
    READ_FM_PURCHASE_PROCESS_INVOICE_REG_ARCHIVE = 'READ_FM_PURCHASE_PROCESS_INVOICE_REG_ARCHIVE',
    READ_FM_PURCHASE_PROCESS_INVOICE_REG_INFO = 'READ_FM_PURCHASE_PROCESS_INVOICE_REG_INFO',
    ACCESS_FM_PURCHASE_PROCESS_INVOICE_REG_INFO = 'ACCESS_FM_PURCHASE_PROCESS_INVOICE_REG_INFO',
    ADD_FM_PURCHASE_PROCESS_INVOICE_REG_INFO = 'ADD_FM_PURCHASE_PROCESS_INVOICE_REG_INFO',
    UPDATE_FM_PURCHASE_PROCESS_INVOICE_REG_INFO = 'UPDATE_FM_PURCHASE_PROCESS_INVOICE_REG_INFO',
    UPDATE_FM_PURCHASE_PROCESS_INVOICE_REG_BASKET = 'UPDATE_FM_PURCHASE_PROCESS_INVOICE_REG_BASKET',
    INPUT_FM_PURCHASE_PROCESS_INVOICE_REG_BASKET = 'INPUT_FM_PURCHASE_PROCESS_INVOICE_REG_BASKET',
    UPDATE_FM_PURCHASE_PROCESS_INVOICE_REG_BASKET_ADDITIONAL_COSTS = 'UPDATE_FM_PURCHASE_PROCESS_INVOICE_REG_BASKET_ADDITIONAL_COSTS',
    INPUT_FM_PURCHASE_PROCESS_INVOICE_REG_ACTIVITY = 'INPUT_FM_PURCHASE_PROCESS_INVOICE_REG_ACTIVITY',
    ADD_FM_PURCHASE_PROCESS_INVOICE_REG_ANALYTIC_PLAN = 'ADD_FM_PURCHASE_PROCESS_INVOICE_REG_ANALYTIC_PLAN',
    UPDATE_FM_PURCHASE_PROCESS_INVOICE_REG_ANALYTIC_PLAN = 'UPDATE_FM_PURCHASE_PROCESS_INVOICE_REG_ANALYTIC_PLAN',
    DELETE_FM_PURCHASE_PROCESS_INVOICE_REG_ANALYTIC_PLAN = 'DELETE_FM_PURCHASE_PROCESS_INVOICE_REG_ANALYTIC_PLAN',
    VALIDATE_FM_PURCHASE_PROCESS_INVOICE_REG_ANALYTIC_PLAN = 'VALIDATE_FM_PURCHASE_PROCESS_INVOICE_REG_ANALYTIC_PLAN',
    READ_FM_PURCHASE_PROCESS_INVOICE_REG_SUMMARY = 'READ_FM_PURCHASE_PROCESS_INVOICE_REG_SUMMARY',
    DOWNLOAD_FM_PURCHASE_PROCESS_INVOICE_REG_SUMMARY = 'DOWNLOAD_FM_PURCHASE_PROCESS_INVOICE_REG_SUMMARY',
    INPUT_FM_PURCHASE_PROCESS_INVOICE_REG_SUMMARY = 'INPUT_FM_PURCHASE_PROCESS_INVOICE_REG_SUMMARY',
    ACCESS_FM_PURCHASE_PROCESS_INVOICE_REG_SUMMARY = 'ACCESS_FM_PURCHASE_PROCESS_INVOICE_REG_SUMMARY',
    UPLOAD_FM_PURCHASE_PROCESS_INVOICE_REG_DOCUMENT = 'UPLOAD_FM_PURCHASE_PROCESS_INVOICE_REG_DOCUMENT',
    DELETE_FM_PURCHASE_PROCESS_INVOICE_REG_DOCUMENT = 'DELETE_FM_PURCHASE_PROCESS_INVOICE_REG_DOCUMENT',
    DOWNLOAD_FM_PURCHASE_PROCESS_INVOICE_REG_DOCUMENT = 'DOWNLOAD_FM_PURCHASE_PROCESS_INVOICE_REG_DOCUMENT',
    READ_FM_PURCHASE_PROCESS_EXPORT_INVOICE_EXPORT = 'READ_FM_PURCHASE_PROCESS_EXPORT_INVOICE_EXPORT',
    READ_FM_PURCHASE_PROCESS_EXPORT_INVOICE_INFO = 'READ_FM_PURCHASE_PROCESS_EXPORT_INVOICE_INFO',
    INPUT_FM_PURCHASE_PROCESS_EXPORT_INVOICE = 'INPUT_FM_PURCHASE_PROCESS_EXPORT_INVOICE',
    INPUT_FM_PURCHASE_PROCESS_EXPORT_INVOICE_PREVIEW = 'INPUT_FM_PURCHASE_PROCESS_EXPORT_INVOICE_PREVIEW',
    READ_FM_PURCHASE_PROCESS_EXPORT_INVOICE = 'READ_FM_PURCHASE_PROCESS_EXPORT_INVOICE',
    READ_FM_PURCHASE_PROCESS_EXPORT_NEW_INVOICE = 'READ_FM_PURCHASE_PROCESS_EXPORT_NEW_INVOICE',
    READ_FM_PURCHASE_PROCESS_EXPORT_NEW_INVOICE_NOT_OK = 'READ_FM_PURCHASE_PROCESS_EXPORT_NEW_INVOICE_NOT_OK',
    INPUT_FM_PURCHASE_PROCESS_EXPORT_NEW_INVOICE = 'INPUT_FM_PURCHASE_PROCESS_EXPORT_NEW_INVOICE',
    READ_FM_PURCHASE_PROCESS_EXPORT_CONTROL_STATE_HISTORY = 'READ_FM_PURCHASE_PROCESS_EXPORT_CONTROL_STATE_HISTORY',
    INPUT_FM_PURCHASE_PROCESS_EXPORT_CONTROL_STATE = 'INPUT_FM_PURCHASE_PROCESS_EXPORT_CONTROL_STATE',
    READ_FM_PURCHASE_PROCESS_EXPORT_CONTROL_STATE = 'READ_FM_PURCHASE_PROCESS_EXPORT_CONTROL_STATE',
    READ_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION = 'READ_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION',
    INPUT_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION = 'INPUT_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION',
    READ_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_ARCHIVE = 'READ_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_ARCHIVE',
    READ_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_INFO = 'READ_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_INFO',
    ACCESS_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_INFO = 'ACCESS_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_INFO',
    UPDATE_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_INFO = 'UPDATE_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_INFO',
    READ_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_INFO_PLANNING = 'READ_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_INFO_PLANNING',
    READ_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_INFO_CONTACT = 'READ_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_INFO_CONTACT',
    CALL_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_INFO = 'CALL_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_INFO',
    SEND_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_MAIL = 'SEND_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_MAIL',
    READ_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_PERIMETER = 'READ_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_PERIMETER',
    READ_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_MANAGEMENT = 'READ_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_MANAGEMENT',
    UPDATE_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_MANAGEMENT_EXTERNAL = 'UPDATE_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_MANAGEMENT_EXTERNAL',
    SAVE_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_MANAGEMENT_EXTERNAL = 'SAVE_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_MANAGEMENT_EXTERNAL',
    UPDATE_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_MANAGEMENT = 'UPDATE_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_MANAGEMENT',
    SAVE_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_MANAGEMENT = 'SAVE_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_MANAGEMENT',
    INPUT_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_MANAGEMENT = 'INPUT_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_MANAGEMENT',
    ACCESS_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_MANAGEMENT = 'ACCESS_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_MANAGEMENT',
    CLOSE_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_MANAGEMENT = 'CLOSE_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_MANAGEMENT',
    READ_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_DOCUMENT = 'READ_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_DOCUMENT',
    UPLOAD_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_DOCUMENT = 'UPLOAD_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_DOCUMENT',
    DOWNLOAD_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_DOCUMENT = 'DOWNLOAD_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_DOCUMENT',
    DELETE_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_DOCUMENT = 'DELETE_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION_DOCUMENT',
    READ_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_EXTERNAL = 'READ_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_EXTERNAL',
    INPUT_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION = 'INPUT_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION',
    READ_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION = 'READ_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION',
    READ_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_INFO_READ = 'READ_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_INFO_READ',
    UPDATE_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_INFO = 'UPDATE_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_INFO',
    ACCESS_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_INFO = 'ACCESS_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_INFO',
    READ_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_INFO = 'READ_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_INFO',
    CALL_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_INFO = 'CALL_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_INFO',
    SEND_FM_PURCHASE_PROCESS_CONTRACT = 'SEND_FM_PURCHASE_PROCESS_CONTRACT',
    READ_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_PERIMETER = 'READ_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_PERIMETER',
    INPUT_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_PERIMETER = 'INPUT_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_PERIMETER',
    READ_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_MANAGEMENT = 'READ_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_MANAGEMENT',
    UPDATE_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_MANAGEMENT_CONTRACT = 'UPDATE_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_MANAGEMENT_CONTRACT',
    SAVE_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_MANAGEMENT_CONTRACT = 'SAVE_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_MANAGEMENT_CONTRACT',
    UPDATE_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_MANAGEMENT = 'UPDATE_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_MANAGEMENT',
    SAVE_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_MANAGEMENT = 'SAVE_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_MANAGEMENT',
    INPUT_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_MANAGEMENT = 'INPUT_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_MANAGEMENT',
    ACCESS_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_MANAGEMENT = 'ACCESS_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_MANAGEMENT',
    CLOSE_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_MANAGEMENT = 'CLOSE_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_MANAGEMENT',
    READ_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_DOCUMENT = 'READ_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_DOCUMENT',
    UPLOAD_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_DOCUMENT = 'UPLOAD_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_DOCUMENT',
    DOWNLOAD_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_DOCUMENT = 'DOWNLOAD_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_DOCUMENT',
    DELETE_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_DOCUMENT = 'DELETE_FM_PURCHASE_PROCESS_CONTRACT_INTERVENTION_DOCUMENT',
    READ_FM_PURCHASE_PROCESS = 'READ_FM_PURCHASE_PROCESS',
    INPUT_FM_PURCHASE_PROCESS = 'INPUT_FM_PURCHASE_PROCESS',
    UPDATE_FM_PURCHASE_PROCESS = 'UPDATE_FM_PURCHASE_PROCESS',
    READ_FM_PURCHASE_PROCESS_INTERN_INTERVENTION = 'READ_FM_PURCHASE_PROCESS_INTERN_INTERVENTION',
    INPUT_FM_PURCHASE_PROCESS_INTERN_INTERVENTION = 'INPUT_FM_PURCHASE_PROCESS_INTERN_INTERVENTION',
    DELETE_FM_PURCHASE_PROCESS_INTERN_INTERVENTION = 'DELETE_FM_PURCHASE_PROCESS_INTERN_INTERVENTION',
    READ_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_ARCHIVE = 'READ_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_ARCHIVE',
    READ_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_INFO = 'READ_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_INFO',
    ACCESS_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_INFO = 'ACCESS_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_INFO',
    UPDATE_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_INFO = 'UPDATE_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_INFO',
    READ_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_INFO_INTERNAL_QUOTE = 'READ_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_INFO_INTERNAL_QUOTE',
    READ_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_INFO_QUOTE = 'READ_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_INFO_QUOTE',
    CALL_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_INFO = 'CALL_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_INFO',
    SEND_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_MAIL = 'SEND_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_MAIL',
    READ_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_READ = 'READ_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_READ',
    READ_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_PERIMETER = 'READ_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_PERIMETER',
    READ_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_MANAGEMENT = 'READ_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_MANAGEMENT',
    UPDATE_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_MANAGEMENT = 'UPDATE_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_MANAGEMENT',
    SAVE_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_MANAGEMENT = 'SAVE_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_MANAGEMENT',
    UPDATE_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_MANAGEMENT_QUOTE = 'UPDATE_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_MANAGEMENT_QUOTE',
    SAVE_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_MANAGEMENT_QUOTE = 'SAVE_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_MANAGEMENT_QUOTE',
    INPUT_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_MANAGEMENT = 'INPUT_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_MANAGEMENT',
    ACCESS_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_MANAGEMENT = 'ACCESS_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_MANAGEMENT',
    CLOSE_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_MANAGEMENT = 'CLOSE_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_MANAGEMENT',
    READ_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_DOCUMENT = 'READ_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_DOCUMENT',
    UPLOAD_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_DOCUMENT = 'UPLOAD_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_DOCUMENT',
    DOWNLOAD_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_DOCUMENT = 'DOWNLOAD_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_DOCUMENT',
    DELETE_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_DOCUMENT = 'DELETE_FM_PURCHASE_PROCESS_INTERN_INTERVENTION_DOCUMENT',
    READ_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION = 'READ_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION',
    INPUT_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION = 'INPUT_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION',
    READ_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_ARCHIVE = 'READ_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_ARCHIVE',
    READ_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_INFO = 'READ_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_INFO',
    UPDATE_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_INFO = 'UPDATE_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_INFO',
    ACCESS_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_INFO = 'ACCESS_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_INFO',
    READ_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_INFO_READ = 'READ_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_INFO_READ',
    CALL_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_INFO = 'CALL_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_INFO',
    SEND_FM_PURCHASE_PROCESS = 'SEND_FM_PURCHASE_PROCESS',
    READ_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_PERIMETER = 'READ_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_PERIMETER',
    READ_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_MANAGEMENT = 'READ_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_MANAGEMENT',
    UPDATE_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_MANAGEMENT_CONTRACT = 'UPDATE_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_MANAGEMENT_CONTRACT',
    SAVE_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_MANAGEMENT_CONTRACT = 'SAVE_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_MANAGEMENT_CONTRACT',
    UPDATE_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_MANAGEMENT = 'UPDATE_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_MANAGEMENT',
    SAVE_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_MANAGEMENT = 'SAVE_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_MANAGEMENT',
    INPUT_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_MANAGEMENT = 'INPUT_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_MANAGEMENT',
    ACCESS_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_MANAGEMENT = 'ACCESS_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_MANAGEMENT',
    CLOSE_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_MANAGEMENT = 'CLOSE_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_MANAGEMENT',
    READ_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_DOCUMENT = 'READ_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_DOCUMENT',
    UPLOAD_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_DOCUMENT = 'UPLOAD_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_DOCUMENT',
    DOWNLOAD_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_DOCUMENT = 'DOWNLOAD_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_DOCUMENT',
    DELETE_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_DOCUMENT = 'DELETE_FM_PURCHASE_PROCESS_INTERN_CONTRACT_INTERVENTION_DOCUMENT',
    READ_FM_PURCHASE_PROCESS_PLANNING = 'READ_FM_PURCHASE_PROCESS_PLANNING',
    INPUT_FM_PURCHASE_PROCESS_PLANNING = 'INPUT_FM_PURCHASE_PROCESS_PLANNING',
    UPDATE_FM_PURCHASE_PROCESS_PLANNING = 'UPDATE_FM_PURCHASE_PROCESS_PLANNING',
    READ_FM_REF_PURCHASE_SUPPLIER = 'READ_FM_REF_PURCHASE_SUPPLIER',
    ADD_FM_REF_PURCHASE_SUPPLIER = 'ADD_FM_REF_PURCHASE_SUPPLIER',
    DOWNLOAD_FM_REF_PURCHASE_SUPPLIER = 'DOWNLOAD_FM_REF_PURCHASE_SUPPLIER',
    IMPORT_FM_REF_PURCHASE_SUPPLIER = 'IMPORT_FM_REF_PURCHASE_SUPPLIER',
    INPUT_FM_REF_PURCHASE_SUPPLIER = 'INPUT_FM_REF_PURCHASE_SUPPLIER',
    READ_FM_REF_PURCHASE_SUPPLIER_ARCHIVE = 'READ_FM_REF_PURCHASE_SUPPLIER_ARCHIVE',
    READ_FM_REF_PURCHASE_SUPPLIER_INFO = 'READ_FM_REF_PURCHASE_SUPPLIER_INFO',
    UPDATE_FM_REF_PURCHASE_SUPPLIER_INFO = 'UPDATE_FM_REF_PURCHASE_SUPPLIER_INFO',
    ADD_FM_REF_PURCHASE_SUPPLIER_INFO = 'ADD_FM_REF_PURCHASE_SUPPLIER_INFO',
    UPDATE_FM_REF_PURCHASE_SUPPLIER_INFO_ADDRESS = 'UPDATE_FM_REF_PURCHASE_SUPPLIER_INFO_ADDRESS',
    DELETE_FM_REF_PURCHASE_SUPPLIER_INFO = 'DELETE_FM_REF_PURCHASE_SUPPLIER_INFO',
    ADD_FM_REF_PURCHASE_SUPPLIER_INFO_FAMILY = 'ADD_FM_REF_PURCHASE_SUPPLIER_INFO_FAMILY',
    UPDATE_FM_REF_PURCHASE_SUPPLIER_INFO_FAMILY = 'UPDATE_FM_REF_PURCHASE_SUPPLIER_INFO_FAMILY',
    DELETE_FM_REF_PURCHASE_SUPPLIER_INFO_FAMILY = 'DELETE_FM_REF_PURCHASE_SUPPLIER_INFO_FAMILY',
    READ_FM_REF_PURCHASE_SUPPLIER_SUPPLIER_CONTACT = 'READ_FM_REF_PURCHASE_SUPPLIER_SUPPLIER_CONTACT',
    ADD_FM_REF_PURCHASE_SUPPLIER_SUPPLIER_CONTACT = 'ADD_FM_REF_PURCHASE_SUPPLIER_SUPPLIER_CONTACT',
    UPDATE_FM_REF_PURCHASE_SUPPLIER_SUPPLIER_CONTACT = 'UPDATE_FM_REF_PURCHASE_SUPPLIER_SUPPLIER_CONTACT',
    DELETE_FM_REF_PURCHASE_SUPPLIER_SUPPLIER_CONTACT = 'DELETE_FM_REF_PURCHASE_SUPPLIER_SUPPLIER_CONTACT',
    INPUT_FM_REF_PURCHASE_SUPPLIER_SUPPLIER_CONTACT = 'INPUT_FM_REF_PURCHASE_SUPPLIER_SUPPLIER_CONTACT',
    INPUT_FM_REF_PURCHASE_SUPPLIER_SUPPLIER_CONTACT_CALL = 'INPUT_FM_REF_PURCHASE_SUPPLIER_SUPPLIER_CONTACT_CALL',
    ADD_FM_REF_PURCHASE_SUPPLIER_CUSTOMER_CODE = 'ADD_FM_REF_PURCHASE_SUPPLIER_CUSTOMER_CODE',
    UPDATE_FM_REF_PURCHASE_SUPPLIER_CUSTOMER_CODE = 'UPDATE_FM_REF_PURCHASE_SUPPLIER_CUSTOMER_CODE',
    INPUT_FM_REF_PURCHASE_SUPPLIER_CUSTOMER_CODE = 'INPUT_FM_REF_PURCHASE_SUPPLIER_CUSTOMER_CODE',
    UPLOAD_FM_REF_PURCHASE_SUPPLIER_DOCUMENT = 'UPLOAD_FM_REF_PURCHASE_SUPPLIER_DOCUMENT',
    DOWNLOAD_FM_REF_PURCHASE_SUPPLIER_DOCUMENT = 'DOWNLOAD_FM_REF_PURCHASE_SUPPLIER_DOCUMENT',
    DELETE_FM_REF_PURCHASE_SUPPLIER_DOCUMENT = 'DELETE_FM_REF_PURCHASE_SUPPLIER_DOCUMENT',
    READ_FM_REF_PURCHASE = 'READ_FM_REF_PURCHASE',
    ADD_FM_REF_PURCHASE = 'ADD_FM_REF_PURCHASE',
    DOWNLOAD_FM_REF_PURCHASE = 'DOWNLOAD_FM_REF_PURCHASE',
    IMPORT_FM_REF_PURCHASE = 'IMPORT_FM_REF_PURCHASE',
    DELETE_FM_REF_PURCHASE = 'DELETE_FM_REF_PURCHASE',
    UPDATE_FM_REF_PURCHASE = 'UPDATE_FM_REF_PURCHASE',
    READ_FM_REF_PURCHASE_INFO = 'READ_FM_REF_PURCHASE_INFO',
    UPDATE_FM_REF_PURCHASE_INFO = 'UPDATE_FM_REF_PURCHASE_INFO',
    INPUT_FM_REF_PURCHASE_INFO_STOCKABLE = 'INPUT_FM_REF_PURCHASE_INFO_STOCKABLE',
    INPUT_FM_REF_PURCHASE_INFO = 'INPUT_FM_REF_PURCHASE_INFO',
    READ_FM_REF_PURCHASE_SERVICE = 'READ_FM_REF_PURCHASE_SERVICE',
    ADD_FM_REF_PURCHASE_SERVICE = 'ADD_FM_REF_PURCHASE_SERVICE',
    DOWNLOAD_FM_REF_PURCHASE_SERVICE = 'DOWNLOAD_FM_REF_PURCHASE_SERVICE',
    IMPORT_FM_REF_PURCHASE_SERVICE = 'IMPORT_FM_REF_PURCHASE_SERVICE',
    DELETE_FM_REF_PURCHASE_SERVICE = 'DELETE_FM_REF_PURCHASE_SERVICE',
    READ_FM_REF_PURCHASE_SERVICE_INFO = 'READ_FM_REF_PURCHASE_SERVICE_INFO',
    UPDATE_FM_REF_PURCHASE_SERVICE_INFO = 'UPDATE_FM_REF_PURCHASE_SERVICE_INFO',
    INPUT_FM_REF_PURCHASE_SERVICE_INFO = 'INPUT_FM_REF_PURCHASE_SERVICE_INFO',
    INPUT_FM_REF_PURCHASE_SERVICE_INFO_PRESTATION = 'INPUT_FM_REF_PURCHASE_SERVICE_INFO_PRESTATION',
    READ_FM_REF_PURCHASE_COMMITMENT = 'READ_FM_REF_PURCHASE_COMMITMENT',
    ADD_FM_REF_PURCHASE_COMMITMENT = 'ADD_FM_REF_PURCHASE_COMMITMENT',
    INPUT_FM_REF_PURCHASE_COMMITMENT = 'INPUT_FM_REF_PURCHASE_COMMITMENT',
    READ_FM_REF_PURCHASE_COMMITMENT_ARCHIVE = 'READ_FM_REF_PURCHASE_COMMITMENT_ARCHIVE',
    VALIDATE_FM_REF_PURCHASE_COMMITMENT = 'VALIDATE_FM_REF_PURCHASE_COMMITMENT',
    CREATE_FM_REF_PURCHASE_COMMITMENT = 'CREATE_FM_REF_PURCHASE_COMMITMENT',
    READ_FM_REF_PURCHASE_COMMITMENT_INFO = 'READ_FM_REF_PURCHASE_COMMITMENT_INFO',
    UPDATE_FM_REF_PURCHASE_COMMITMENT_INFO = 'UPDATE_FM_REF_PURCHASE_COMMITMENT_INFO',
    ACCESS_FM_REF_PURCHASE_COMMITMENT_INFO = 'ACCESS_FM_REF_PURCHASE_COMMITMENT_INFO',
    ADD_FM_REF_PURCHASE_COMMITMENT_INFO = 'ADD_FM_REF_PURCHASE_COMMITMENT_INFO',
    DELETE_FM_REF_PURCHASE_COMMITMENT_INFO = 'DELETE_FM_REF_PURCHASE_COMMITMENT_INFO',
    ADD_FM_REF_PURCHASE_COMMITMENT_INFO_SAVING_CLAUSE = 'ADD_FM_REF_PURCHASE_COMMITMENT_INFO_SAVING_CLAUSE',
    READ_FM_REF_PURCHASE_COMMITMENT_INFO_HISTORY = 'READ_FM_REF_PURCHASE_COMMITMENT_INFO_HISTORY',
    INPUT_FM_REF_PURCHASE_COMMITMENT_INFO = 'INPUT_FM_REF_PURCHASE_COMMITMENT_INFO',
    ADD_FM_REF_PURCHASE_LINE = 'ADD_FM_REF_PURCHASE_LINE',
    DOWNLOAD_FM_REF_PURCHASE_COMMITMENT_LINE = 'DOWNLOAD_FM_REF_PURCHASE_COMMITMENT_LINE',
    IMPORT_FM_REF_PURCHASE_COMMITMENT_LINE = 'IMPORT_FM_REF_PURCHASE_COMMITMENT_LINE',
    ADD_FM_REF_PURCHASE_COMMITMENT_LINE_LOCATION = 'ADD_FM_REF_PURCHASE_COMMITMENT_LINE_LOCATION',
    UPDATE_FM_REF_PURCHASE_COMMITMENT_LINE_LOCATION = 'UPDATE_FM_REF_PURCHASE_COMMITMENT_LINE_LOCATION',
    DELETE_FM_REF_PURCHASE_COMMITMENT_LINE_LOCATION = 'DELETE_FM_REF_PURCHASE_COMMITMENT_LINE_LOCATION',
    ADD_FM_REF_PURCHASE_COMMITMENT_LINE_PRESTATION = 'ADD_FM_REF_PURCHASE_COMMITMENT_LINE_PRESTATION',
    UPDATE_FM_REF_PURCHASE_COMMITMENT_LINE_PRESTATION = 'UPDATE_FM_REF_PURCHASE_COMMITMENT_LINE_PRESTATION',
    DELETE_FM_REF_PURCHASE_COMMITMENT_LINE_PRESTATION = 'DELETE_FM_REF_PURCHASE_COMMITMENT_LINE_PRESTATION',
    ADD_FM_REF_PURCHASE_COMMITMENT_LINE_EQUIPMENT = 'ADD_FM_REF_PURCHASE_COMMITMENT_LINE_EQUIPMENT',
    UPDATE_FM_REF_PURCHASE_COMMITMENT_LINE_EQUIPMENT = 'UPDATE_FM_REF_PURCHASE_COMMITMENT_LINE_EQUIPMENT',
    DELETE_FM_REF_PURCHASE_COMMITMENT_LINE_EQUIPMENT = 'DELETE_FM_REF_PURCHASE_COMMITMENT_LINE_EQUIPMENT',
    ADD_FM_REF_PURCHASE_COMMITMENT_LINE_INTERVENTION = 'ADD_FM_REF_PURCHASE_COMMITMENT_LINE_INTERVENTION',
    UPDATE_FM_REF_PURCHASE_COMMITMENT_LINE_INTERVENTION = 'UPDATE_FM_REF_PURCHASE_COMMITMENT_LINE_INTERVENTION',
    DELETE_FM_REF_PURCHASE_COMMITMENT_LINE_INTERVENTION = 'DELETE_FM_REF_PURCHASE_COMMITMENT_LINE_INTERVENTION',
    ADD_FM_REF_PURCHASE_COMMITMENT_LINE = 'ADD_FM_REF_PURCHASE_COMMITMENT_LINE',
    UPDATE_FM_REF_PURCHASE_COMMITMENT_LINE = 'UPDATE_FM_REF_PURCHASE_COMMITMENT_LINE',
    DELETE_FM_REF_PURCHASE_COMMITMENT_LINE = 'DELETE_FM_REF_PURCHASE_COMMITMENT_LINE',
    DELETE_FM_REF_PURCHASE_LINE = 'DELETE_FM_REF_PURCHASE_LINE',
    INPUT_FM_REF_PURCHASE_COMMITMENT_CATALOG = 'INPUT_FM_REF_PURCHASE_COMMITMENT_CATALOG',
    UPDATE_FM_REF_PURCHASE_COMMITMENT_CATALOG = 'UPDATE_FM_REF_PURCHASE_COMMITMENT_CATALOG',
    DELETE_FM_REF_PURCHASE_COMMITMENT_CATALOG = 'DELETE_FM_REF_PURCHASE_COMMITMENT_CATALOG',
    ADD_FM_REF_PURCHASE_COMMITMENT_CATALOG = 'ADD_FM_REF_PURCHASE_COMMITMENT_CATALOG',
    READ_FM_REF_PURCHASE_COMMITMENT_BASKET_CONTRACT_ALL = 'READ_FM_REF_PURCHASE_COMMITMENT_BASKET_CONTRACT_ALL',
    READ_FM_REF_PURCHASE_COMMITMENT_BASKET_CONTRACT = 'READ_FM_REF_PURCHASE_COMMITMENT_BASKET_CONTRACT',
    DELETE_FM_REF_PURCHASE_COMMITMENT_BASKET_CONTRACT = 'DELETE_FM_REF_PURCHASE_COMMITMENT_BASKET_CONTRACT',
    SEND_FM_REF_PURCHASE_COMMITMENT_BASKET_CONTRACT = 'SEND_FM_REF_PURCHASE_COMMITMENT_BASKET_CONTRACT',
    UPLOAD_FM_REF_PURCHASE_COMMITMENT_DOCUMENT = 'UPLOAD_FM_REF_PURCHASE_COMMITMENT_DOCUMENT',
    DOWNLOAD_FM_REF_PURCHASE_COMMITMENT_DOCUMENT = 'DOWNLOAD_FM_REF_PURCHASE_COMMITMENT_DOCUMENT',
    DELETE_FM_REF_PURCHASE_COMMITMENT_DOCUMENT = 'DELETE_FM_REF_PURCHASE_COMMITMENT_DOCUMENT',
    READ_INCIDENT_EQUIPMENT_FOLLOW_TICKET = 'READ_INCIDENT_EQUIPMENT_FOLLOW_TICKET',
    INPUT_INCIDENT_EQUIPMENT_FOLLOW_TICKET = 'INPUT_INCIDENT_EQUIPMENT_FOLLOW_TICKET',
    ACCESS_INCIDENT_EQUIPMENT_FOLLOW_TICKET = 'ACCESS_INCIDENT_EQUIPMENT_FOLLOW_TICKET',
    INPUT_INCIDENT_EQUIPMENT_FOLLOW_TICKET_ARCHIVE = 'INPUT_INCIDENT_EQUIPMENT_FOLLOW_TICKET_ARCHIVE',
    READ_INCIDENT_EQUIPMENT_FOLLOW_TICKET_ARCHIVE = 'READ_INCIDENT_EQUIPMENT_FOLLOW_TICKET_ARCHIVE',
    READ_INCIDENT_EQUIPMENT_FOLLOW_TICKET_DESCRIPTION = 'READ_INCIDENT_EQUIPMENT_FOLLOW_TICKET_DESCRIPTION',
    SAVE_INCIDENT_EQUIPMENT_FOLLOW_TICKET_DESCRIPTION_CREATOR = 'SAVE_INCIDENT_EQUIPMENT_FOLLOW_TICKET_DESCRIPTION_CREATOR',
    SAVE_INCIDENT_EQUIPMENT_FOLLOW_TICKET_DESCRIPTION = 'SAVE_INCIDENT_EQUIPMENT_FOLLOW_TICKET_DESCRIPTION',
    ACCESS_INCIDENT_EQUIPMENT_FOLLOW_TICKET_DESCRIPTION = 'ACCESS_INCIDENT_EQUIPMENT_FOLLOW_TICKET_DESCRIPTION',
    SEND_INCIDENT_EQUIPMENT_FOLLOW_TICKET_DESCRIPTION = 'SEND_INCIDENT_EQUIPMENT_FOLLOW_TICKET_DESCRIPTION',
    ADD_INCIDENT_EQUIPMENT_FOLLOW_TICKET_DESCRIPTION = 'ADD_INCIDENT_EQUIPMENT_FOLLOW_TICKET_DESCRIPTION',
    CLOSE_INCIDENT_EQUIPMENT_FOLLOW_TICKET_DESCRIPTION = 'CLOSE_INCIDENT_EQUIPMENT_FOLLOW_TICKET_DESCRIPTION',
    READ_INCIDENT_EQUIPMENT_FOLLOW_TICKET_INFO = 'READ_INCIDENT_EQUIPMENT_FOLLOW_TICKET_INFO',
    SAVE_INCIDENT_EQUIPMENT_FOLLOW_TICKET_INFO_CREATOR = 'SAVE_INCIDENT_EQUIPMENT_FOLLOW_TICKET_INFO_CREATOR',
    SAVE_INCIDENT_EQUIPMENT_FOLLOW_TICKET_INFO = 'SAVE_INCIDENT_EQUIPMENT_FOLLOW_TICKET_INFO',
    ACCESS_INCIDENT_EQUIPMENT_FOLLOW_TICKET_INFO = 'ACCESS_INCIDENT_EQUIPMENT_FOLLOW_TICKET_INFO',
    INPUT_INCIDENT_EQUIPMENT_FOLLOW_TICKET_INFO = 'INPUT_INCIDENT_EQUIPMENT_FOLLOW_TICKET_INFO',
    ACCESS_INCIDENT_EQUIPMENT_FOLLOW_TICKET_INFO_BUTTON = 'ACCESS_INCIDENT_EQUIPMENT_FOLLOW_TICKET_INFO_BUTTON',
    CLOSE_INCIDENT_EQUIPMENT_FOLLOW_TICKET_INFO = 'CLOSE_INCIDENT_EQUIPMENT_FOLLOW_TICKET_INFO',
    INPUT_INCIDENT_EQUIPMENT_FOLLOW_TICKET_INFO_RESOLVE = 'INPUT_INCIDENT_EQUIPMENT_FOLLOW_TICKET_INFO_RESOLVE',
    READ_INCIDENT_EQUIPMENT_FOLLOW_TICKET_ACTIVITY = 'READ_INCIDENT_EQUIPMENT_FOLLOW_TICKET_ACTIVITY',
    ACCESS_INCIDENT_EQUIPMENT_FOLLOW_TICKET_ACTIVITY = 'ACCESS_INCIDENT_EQUIPMENT_FOLLOW_TICKET_ACTIVITY',
    SEARCH_INCIDENT_EQUIPMENT_FOLLOW_TICKET_CATALOG = 'SEARCH_INCIDENT_EQUIPMENT_FOLLOW_TICKET_CATALOG',
    INPUT_INCIDENT_EQUIPMENT_FOLLOW_TICKET_CATALOG = 'INPUT_INCIDENT_EQUIPMENT_FOLLOW_TICKET_CATALOG',
    ADD_INCIDENT_EQUIPMENT_FOLLOW_TICKET_CATALOG = 'ADD_INCIDENT_EQUIPMENT_FOLLOW_TICKET_CATALOG',
    UPDATE_INCIDENT_EQUIPMENT_FOLLOW_TICKET_CATALOG = 'UPDATE_INCIDENT_EQUIPMENT_FOLLOW_TICKET_CATALOG',
    DELETE_INCIDENT_EQUIPMENT_FOLLOW_TICKET_CATALOG = 'DELETE_INCIDENT_EQUIPMENT_FOLLOW_TICKET_CATALOG',
    INPUT_INCIDENT_EQUIPMENT_FOLLOW_TICKET_CATALOG_HELP = 'INPUT_INCIDENT_EQUIPMENT_FOLLOW_TICKET_CATALOG_HELP',
    READ_INCIDENT_EQUIPMENT_FOLLOW_TICKET_VISUALIZE = 'READ_INCIDENT_EQUIPMENT_FOLLOW_TICKET_VISUALIZE',
    ADD_INCIDENT_EQUIPMENT_FOLLOW_TICKET = 'ADD_INCIDENT_EQUIPMENT_FOLLOW_TICKET',
    READ_INCIDENT_EQUIPMENT_FOLLOW_TICKET_BASKET = 'READ_INCIDENT_EQUIPMENT_FOLLOW_TICKET_BASKET',
    UPDATE_INCIDENT_EQUIPMENT_FOLLOW_TICKET_BASKET = 'UPDATE_INCIDENT_EQUIPMENT_FOLLOW_TICKET_BASKET',
    DELETE_INCIDENT_EQUIPMENT_FOLLOW_TICKET_BASKET = 'DELETE_INCIDENT_EQUIPMENT_FOLLOW_TICKET_BASKET',
    INPUT_INCIDENT_EQUIPMENT_FOLLOW_TICKET_BASKET = 'INPUT_INCIDENT_EQUIPMENT_FOLLOW_TICKET_BASKET',
    VALIDATE_INCIDENT_EQUIPMENT_FOLLOW_TICKET_BASKET = 'VALIDATE_INCIDENT_EQUIPMENT_FOLLOW_TICKET_BASKET',
    INPUT_INCIDENT_EQUIPMENT_FOLLOW_TICKET_BASKET_REJECT = 'INPUT_INCIDENT_EQUIPMENT_FOLLOW_TICKET_BASKET_REJECT',
    INPUT_INCIDENT_STRATEGIC = 'INPUT_INCIDENT_STRATEGIC',
    READ_LITIGATION = 'READ_LITIGATION',
    READ_LITIGATION_INFO = 'READ_LITIGATION_INFO',
    ACCESS_LITIGATION_INFO_ESTABLISHMENT = 'ACCESS_LITIGATION_INFO_ESTABLISHMENT',
    ACCESS_LITIGATION_INFO_SUPPLIER = 'ACCESS_LITIGATION_INFO_SUPPLIER',
    ACCESS_LITIGATION_INFO = 'ACCESS_LITIGATION_INFO',
    UPDATE_LITIGATION_INFO = 'UPDATE_LITIGATION_INFO',
    READ_LITIGATION_ACTION = 'READ_LITIGATION_ACTION',
    UPDATE_LITIGATION_ACTION = 'UPDATE_LITIGATION_ACTION',
    INPUT_LITIGATION_ACTION = 'INPUT_LITIGATION_ACTION',
    UPLOAD_LITIGATION_DOCUMENT = 'UPLOAD_LITIGATION_DOCUMENT',
    DOWNLOAD_LITIGATION_DOCUMENT = 'DOWNLOAD_LITIGATION_DOCUMENT',
    DELETE_LITIGATION_DOCUMENT = 'DELETE_LITIGATION_DOCUMENT',
    PB_BM_CONSOLIDATION = 'PB_BM_CONSOLIDATION',
    PB_BM_BUDGET = 'PB_BM_BUDGET',
    PB_BM_BUDGET_FOLLOW = 'PB_BM_BUDGET_FOLLOW',
    READ_MM_ENERGY_PERFORMANCE_ENERGY = 'READ_MM_ENERGY_PERFORMANCE_ENERGY',
    RESTORATION_FUNCTION = 'RESTORATION_FUNCTION',
    READ_BD_DOCUMENT = 'READ_BD_DOCUMENT',
    TEST = 'TEST',

    // Permissions for 'ID Energie':
    READ_ENERGY_ID = 'READ_ENERGY_ID',
    ADD_ENERGY_ID = 'ADD_ENERGY_ID',
    DOWNLOAD_ENERGY_ID = 'DOWNLOAD_ENERGY_ID',
    IMPORT_ENERGY_ID = 'IMPORT_ENERGY_ID',
    INPUT_ENERGY_ID = 'INPUT_ENERGY_ID',
    READ_ENERGY_ID_ARCHIVE = 'READ_ENERGY_ID_ARCHIVE',
}
