import {GenericProductModel} from './generic-product.model';
import {SupplierModel} from './supplier.model';
import {TvaModel} from './tva.model';
import {GenericCharacteristicModel} from './generic-characteristic.model';
import {GenericEquipmentModel} from './generic-equipment.model';
import {CustomerGroupComptableLine} from './customer-group.model';
import {ContractPerimeterModel} from './contract.model';
import {CategoryModel} from './category.model';

export class ProductModel {
    _id: string;
    technicalIdentifier: string;
    label: string;
    reference: string;
    previousReference: string;
    priceHT: number;
    priceTTC: number;
    genericProduct: GenericProductModel;
    supplier?: SupplierModel;
    manufacturer: string;
    model: string;
    tva: TvaModel;
    tvaAccountingAccount: CustomerGroupComptableLine;
    accountingAccount: CustomerGroupComptableLine;
    purchaseUnit: string;
    conditioningUnit: string;
    characteristics: ProductCharacteristicModel[];
    perishable: boolean;
    quantity?: number;
    hasContract?: boolean;
    compatibleEquipment: ProductCompatibleEquipmentModel[];
    totalHT?: number;
    totalTTC?: number;
    totalTVA?: number;
    category: CategoryModel;
    customDisplay?: string;
    stockable?: boolean;
    quoteId?: string;

    isAvailable?: boolean;
    categories?: string[];

    comment?: string;

    contractId?: string;
    minQuantity?: number;
    revisionAmount?: number;
    revisionProductAmount?: number;
    isArchive?: boolean;
    isExpired?: boolean;
    isFullPerimeter?: boolean;
    perimeter: ContractPerimeterModel[] ;
    conditioningQuantity?: number;

    generalSupplierCatalog: string;
    technicalSheet: string;
    safetyUsageProtocol: string;
}

export class ProductCharacteristicModel {
    _id?: string;
    characteristic: GenericCharacteristicModel;
    value: string;
}

export class ProductCompatibleEquipmentModel {
    _id?: string;
    reference: string;
    serialNumber: string;
    genericEquipment: GenericEquipmentModel;
}
