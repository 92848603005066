<div
        class="mt-6"
        @fadeInRight
        [ngClass]="getBorderClasses()"
>
    <div class="flex flex-row w-full justify-between">

        <div class="flex flex-col items-start w-full">
            <p class="font-bold" [innerHTML]="title"></p>
            <ul class="list-disc pl-10 text-left">
                <li *ngFor="let error of errors">
                    {{ error }}
                </li>
            </ul>
           <p *ngIf="description">{{ description}}</p>
        </div>

        <div class="flex flex-row gap-3" *ngIf="showButton">
            <button
                    (click)="callButton()"
                    class="w-full"
                    matSuffix
                    mat-raised-button
                    [color]="colorButton"
            >
                {{ textButton }}
            </button>
        </div>
    </div>
</div>
