import {Injectable} from '@angular/core';
import {BaseService} from "../../../../core/base/base.service";
import {GenericRoomModel} from "../../../../core/models/generic-room.model";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class GenericRoomService extends BaseService<GenericRoomModel>{
    defaultSort = 'createdAt';

    constructor(public http: HttpClient) {
        super(http, 'generic-room');
    }
}