<div *ngIf="!compactMode" class="card mt-6">

    <div class="px-6 py-4 border-b">
        <h2 class="title m-0">
            Périmètre étendu
        </h2>
    </div>

    <ng-container *ngIf="hasPerimeter; else noPerimeter">
        <div class="flex flex-col gap-3 px-6 py-4">
            <p class="m-0 caption text-hint">
                {{ typeSelectedTitle }}
            </p>
            <p *ngFor="let patrimony of patrimonies" [innerHTML]="transformTextLabel(patrimony)"></p>
        </div>
    </ng-container>

    <ng-template #noPerimeter>
        <div class="px-6 py-4 flex flex-row gap-6">
            {{ noPerimeterMessage }}
        </div>
    </ng-template>
</div>


<!-- VERSION COMPACT -->

<div *ngIf="compactMode">
    <mat-accordion>
        <mat-expansion-panel (opened)="isExpanded = true" (closed)="isExpanded = false">
            <mat-expansion-panel-header class="px-6 py-4 border-b flex items-center justify-between">
                <mat-panel-title>
                        <h2 class="title m-0 px-6">
                            Périmètre potentiel à sélectionner
                        </h2>
                </mat-panel-title>
                <mat-icon [ngClass]="{ 'rotate-90': isExpanded }">chevron_right</mat-icon>
            </mat-expansion-panel-header>

            <ng-container *ngIf="hasPerimeter; else noPerimeter">
                <div class="flex flex-col gap-3 px-6 py-4">

                    <p class="m-0 caption text-hint">
                        Etablissement cible du eShop
                    </p>

                    <p>{{ quote.establishment.label }}</p>

                    <p class="m-0 caption text-hint">
                        {{ typeSelectedTitle }}
                    </p>

                    <p *ngFor="let patrimony of patrimonies" [innerHTML]="transformTextLabel(patrimony)"></p>
                </div>
            </ng-container>

            <ng-template #noPerimeter>
                <div class="px-6 py-4 flex flex-row gap-6">
                    {{ noPerimeterMessage }}
                </div>
            </ng-template>

        </mat-expansion-panel>
    </mat-accordion>
</div>



