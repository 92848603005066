import { Component, OnInit } from '@angular/core';
import { EquipmentModel } from 'src/app/core/models/equipment.model';
import { EquipmentService } from './equipment.service';
import { MatDialog } from '@angular/material/dialog';
import {
    EquipmentCreateUpdateDeleteComponent
} from './equipment-create-update-delete/equipment-create-update-delete.component';
import { bubbleColor, FeiColumn } from 'src/app/core/base/interfaces/fei-column.interface';
import { DataTableBase } from 'src/app/core/base/components/data-table-base/data-table-base';
import { CrudModeEnum } from 'src/app/core/base/enum/crud-mode.enum';
import { ProductModel } from '../../../../core/models/product.model';
import { Observable } from 'rxjs';
import { EstablishmentModel } from '../../../../core/models/establishment.model';
import { BuildingModel } from '../../../../core/models/building.model';
import { Router } from '@angular/router';
import { scaleIn400ms } from '../../../../../@vex/animations/scale-in.animation';
import { fadeInRight400ms } from '../../../../../@vex/animations/fade-in-right.animation';
import { stagger40ms } from '../../../../../@vex/animations/stagger.animation';
import { fadeInUp400ms } from '../../../../../@vex/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from '../../../../../@vex/animations/scale-fade-in.animation';
import { dropdownAnimation } from '../../../../../@vex/animations/dropdown.animation';
import { popoverAnimation } from '../../../../../@vex/animations/popover.animation';
import { ENUM_PERMISSIONS } from '../../../../core/enums/permission.enum';
import { AuthService } from '../../../../core/services/auth.service';
import { UserProfileModel } from '../../../../core/models/user-profile.model';
import { EquipmentStatusEnum, EquipmentStatusEnumTranslation } from '../../../../core/enums/equipment-status.enum';
import { MetrikStatusTypeEnum } from '../../../../core/enums/metrik-status.enum';
import { MetrikStatusModel } from '../../../../core/models/metrik-status.model';

@Component({
    selector: 'vex-equipment',
    templateUrl: './equipment.component.html',
    styleUrls: ['./equipment.component.scss'],
    animations: [
        scaleIn400ms,
        fadeInRight400ms,
        stagger40ms,
        fadeInUp400ms,
        scaleFadeIn400ms,
        scaleFadeIn400ms,
        dropdownAnimation,
        popoverAnimation
    ]
})
export class EquipmentComponent extends DataTableBase<EquipmentModel> implements OnInit {

    constructor(
        public service: EquipmentService,
        private dialog: MatDialog,
        private router: Router,
        private authService: AuthService,
    ) {
        super();
        this.displayedColumns = this.feisColumns.map(x => x.column);
    }
equipmentStatusEnum = EquipmentStatusEnum;
    feisColumns: Array<FeiColumn> = [
        {label: '', column: 'equipmentStatus',
            statusClassFunc: this.displayStatusField, type: 'custom'},
        {label: 'Référence équipement', column: 'reference'},
        {label: 'Etablissement', column: 'establishment', propertyDisplay: 'establishment.label'},
        {label: 'Equipement générique', column: 'genericEquipment', propertyDisplay: 'genericEquipment.label',
            statusClassFunc: this.displayStatusField},
        {label: 'Numéro de série', column: 'serialNumber'},
        {label: 'Marque', column: 'mark'},
        {label: 'Modèle', column: 'model'},
        {label: 'Mise en service', column: 'commissioningDate', type: 'date'},
        {label: 'Équipement fonctionnel', column: 'isActive', type: 'boolean'},
        // {label: 'Motif', column: 'comment', propertyDisplay: 'operationalStatus.comment'},
        {label: 'Réferent équipement', column: 'contactIntern', propertyDisplay: 'contactIntern.fullName'},
        {label: '', column: 'actions', canUpdate: false, canArchive: true}
    ];

    entityName = 'Équipement';

    establishment$: Observable<EstablishmentModel[]>;
    building$: Observable<BuildingModel[]>;

    ENUM_PERMISSION = ENUM_PERMISSIONS;

    canShowArchivePermission = false;

    legendData = [
        { description: 'Nouvel équipement à compléter', color: '#FFC0CB' },
    ];

    readonly metrikStatusTypeEnum = MetrikStatusTypeEnum;

    ngOnInit() {
        super.ngOnInit();

        this.authService.currentUser$().subscribe((user: UserProfileModel) => {
            const index = this.feisColumns.findIndex(x => x.column === 'establishment');
            if (user.affectations.length <= 1 && index !== -1) {
               this.feisColumns.splice(index, 1);
           }
        });

        this.authService.getCurrentUserPermissions$().subscribe(permissions => {
            this.canShowArchivePermission = permissions.includes(ENUM_PERMISSIONS.READ_EQUIPMENT);
        });

        this.filters.isStatusNeeded = true;

    }

    initData(): void {
        super.initData();
        this.filters.isStatusNeeded = true;
        this.filters.requiredFields = [
            'equipmentStatus',
            'establishment',
            'reference',
            'genericEquipment',
            'serialNumber',
            'mark',
            'model',
            'commissioningDate',
            'operationalStatus',
            'contactIntern'
        ];
    }

    createItem(): void {
        this.dialog.open(EquipmentCreateUpdateDeleteComponent).afterClosed().subscribe((equipment: EquipmentModel) => {
            if (equipment) {
                if (equipment._id) {
                    this.router.navigateByUrl('/management/equipment/' + equipment._id).then();
                } else {
                    this.initData();
                }
            }
        });
    }

    updateItem(element: EquipmentModel): void {
        this.dialog.open(EquipmentCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                mode: CrudModeEnum.Update
            }
        }).afterClosed().subscribe((equipment: EquipmentModel) => {
            if (equipment) {
                this.initData();
            }
        });
    }

    deleteItem(element: EquipmentModel) {
        this.dialog.open(EquipmentCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                name: `${element.reference}`,
                mode: CrudModeEnum.Delete
            }
        }).afterClosed().subscribe((equipment: EquipmentModel) => {
            if (equipment) {
                this.initData();
            }
        });
    }

    archiveItem(element: EquipmentModel) {
        this.dialog.open(EquipmentCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                name: `${element.reference}`,
                mode: CrudModeEnum.Archive
            }
        }).afterClosed().subscribe((equipment: EquipmentModel) => {
            if (equipment) {
                this.initData();
            }
        });
    }

    importItems() {
        this.dialog.open(EquipmentCreateUpdateDeleteComponent, {
            data: {
                mode: CrudModeEnum.Import
            }
        }).afterClosed().subscribe((equipment: ProductModel[]) => {
            if (equipment) {
                this.initData();
            }
        });
    }

    displayStatusField(element: EquipmentModel): bubbleColor {
        if (element.equipmentStatus === EquipmentStatusEnumTranslation(EquipmentStatusEnum.TO_COMPLETE)) {
            return 'red';
        }
    }
}
