export class ValidityModel {
    type: ValidityType = ValidityType.MONTH;
    value: number;
}

export enum ValidityType {
    MONTH = 'MONTH',
    YEAR = 'YEAR',
    DAY = 'DAY',
    WEEK = 'WEEK',
}

export const listValidityType = Object.values(ValidityType);
export const listValidityTypeFrensh = Object.values(ValidityType).map(x => {
    if (x === ValidityType.MONTH) return 'Mois';
    if (x === ValidityType.YEAR) return 'Années';
    if (x === ValidityType.DAY) return 'Jours';
    if (x === ValidityType.WEEK) return 'Semaines';
    return x;
})

export const convertValidityTypeFreshToEnglish = (value: string) => {
    if (value === 'Mois') return ValidityType.MONTH;
    if (value === 'Années') return ValidityType.YEAR;
    if (value === 'Jours') return ValidityType.DAY;
    if (value === 'Semaines') return ValidityType.WEEK;
}

export const convertValidityTypeEnglishToFresh = (value: string) => {
    if (value === ValidityType.MONTH) return 'Mois';
    if (value === ValidityType.YEAR) return 'Années';
    if (value === ValidityType.DAY) return 'Jours';
    if (value === ValidityType.WEEK) return 'Semaines';
}



export const setValidity = (type: ValidityType, value) => {
    return { type, value: +value }
}
