<mat-sidenav-container>
    <mat-sidenav
        #sidenav
        [position]="position"
        [style.width]="'500px'"
        [style.padding]="'15px'"
        [mode]="mode"
        [style.overflow]="'hidden'"
        class="custom-sidenav">
        <ng-container *ngTemplateOutlet="contentSidenav"></ng-container>
    </mat-sidenav>
    <mat-sidenav-content>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </mat-sidenav-content>
</mat-sidenav-container>
