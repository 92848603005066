import { Component, OnInit } from '@angular/core';
import { EstablishmentModel } from 'src/app/core/models/establishment.model';
import { EstablishmentService } from './establishment.service';
import { MatDialog } from '@angular/material/dialog';
import {
    EstablishmentCreateUpdateDeleteComponent
} from './modals/establishment-create-update-delete/establishment-create-update-delete.component';
import { FeiColumn } from 'src/app/core/base/interfaces/fei-column.interface';
import { DataTableBase } from 'src/app/core/base/components/data-table-base/data-table-base';
import { CrudModeEnum } from 'src/app/core/base/enum/crud-mode.enum';
import { Router } from '@angular/router';
import { ENUM_PERMISSIONS } from '../../../../core/enums/permission.enum';
import { EquipmentService } from '../equipment/equipment.service';
import {
    FormModalConfirmWithListComponent
} from '../../../../core/base/components/form-modal-confirm-with-list/form-modal-confirm-with-list.component';
import { SecurityCommissionService } from '../../technique/security-commission/security-commission.service';
import { lastValueFrom } from 'rxjs';
import { AuthService } from '../../../../core/services/auth.service';

@Component({
    selector: 'vex-establishment',
    templateUrl: './establishment.component.html',
    styleUrls: ['./establishment.component.scss'],
})
export class EstablishmentComponent extends DataTableBase<EstablishmentModel> implements OnInit {

    feisColumns: Array<FeiColumn> = [
        {label: 'Identifiant Technique', column: 'technicalIdentifier'},
        {label: 'Dénomination Établissement', column: 'label'},
        {label: 'N° Siren', column: 'sirenNumber'},
        {label: 'N° Siret', column: 'siretNumber'},
        {label: 'Identifiant entreprise', column: 'dunsNumber'},
        {label: 'Code ape', column: 'ape'},
        {label: 'Code TVA', column: 'tva'},
        {label: '',
            column: 'actions',
            canUpdate: false,
            canArchive: this.authService.hasPermission(ENUM_PERMISSIONS.INPUT_CP_ESTABLISHMENT)
        },

    ];
    ENUM_PERMISSIONS = ENUM_PERMISSIONS;

    canCreatePermissions = false;
    canShowArchivePermissions = false;
    pageSize = 10;

    constructor(
        public service: EstablishmentService,
        private dialog: MatDialog,
        private router: Router,
        private equipmentService: EquipmentService,
        private securityCommissionService: SecurityCommissionService,
        private authService: AuthService,
    ) {
        super();
        this.displayedColumns = this.feisColumns.map(x => x.column);
    }

    ngOnInit() {
        super.ngOnInit();

        this.authService.getCurrentUserPermissions$().subscribe(permissions => {
            this.canCreatePermissions = permissions.includes(ENUM_PERMISSIONS.CREATE_CP_ESTABLISHMENT);
            this.canShowArchivePermissions = permissions.includes(ENUM_PERMISSIONS.READ_CP_ESTABLISHMENT_INFORMATION);
        });
    }

    createItem(): void {
        this.dialog.open(EstablishmentCreateUpdateDeleteComponent).afterClosed().subscribe((establishment: EstablishmentModel) => {
            if (establishment) {
                if (establishment._id) {
                    this.router.navigateByUrl('/management/establishment/' + establishment._id).then();
                } else {
                    this.initData();
                }
            }
        });
    }

    importItem(): void {
        this.dialog.open(EstablishmentCreateUpdateDeleteComponent, {
            data: {
                mode: CrudModeEnum.Import,
            }
        }).afterClosed().subscribe((establishment: EstablishmentModel[]) => {
            if (establishment) {
                this.initData();
            }
        });
    }

    updateItem(element: EstablishmentModel): void {
        this.dialog.open(EstablishmentCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                mode: CrudModeEnum.Update
            }
        }).afterClosed().subscribe((establishment: EstablishmentModel) => {
            if (establishment) {
                this.initData();
            }
        });
    }

    deleteItem(element: EstablishmentModel) {
        this.dialog.open(EstablishmentCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                name: `${element.label}`,
                mode: CrudModeEnum.Delete
            }
        }).afterClosed().subscribe((establishment: EstablishmentModel) => {
            if (establishment) {
                this.initData();
            }
        });
    }

    async archiveItem(element: EstablishmentModel) {
        const securityCommission = await lastValueFrom(this.securityCommissionService.getEstablishmentRelation(element._id));
        const equipment = await lastValueFrom(this.equipmentService.getEstablishmentRelation(element._id));
        const securityCAndEquipment = [...equipment.data.map(x => `Equipement - ${x.reference}`), ...securityCommission.data.map(x => `Commission de sécurité - ${x.label}`)];
        if (securityCAndEquipment.length > 0) {
            this.dialog.open(FormModalConfirmWithListComponent, {
                data: {
                    title: 'Archivage impossible',
                    message: `Vous ne pouvez pas archiver cet établissement car il est affecté à des équipements / commissions de sécurité.`,
                    userList: securityCAndEquipment
                }
            });
        } else {
            this.dialog.open(EstablishmentCreateUpdateDeleteComponent, {
                data: {
                    defaults: element,
                    name: `${element.label}`,
                    mode: CrudModeEnum.Archive
                }
            }).afterClosed().subscribe((establishment: EstablishmentModel) => {
                if (establishment) {
                    this.initData();
                }
            });
        }
    }
}
