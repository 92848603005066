import { Component, Inject, OnInit } from '@angular/core';
import {
    FormModalBaseComponent
} from '../../../../../../../core/base/components/form-modal-base/form-modal-base.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EquipmentService } from '../../../equipment.service';
import { EquipmentModel, LocationModel } from '../../../../../../../core/models/equipment.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BuildingService } from '../../../../building/building.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GenericEquipmentService } from '../../../../../settings/generic-equipment/generic-equipment.service';
import { CrudModeEnum } from '../../../../../../../core/base/enum/crud-mode.enum';

@Component({
    selector: 'vex-equipment-locations-create-update-delete',
    templateUrl: './equipment-locations-create-update-delete.component.html',
    styleUrls: ['./equipment-locations-create-update-delete.component.scss']
})
export class EquipmentLocationsCreateUpdateDeleteComponent extends FormModalBaseComponent<EquipmentModel> implements OnInit {

    mode: CrudModeEnum;
    buildings$: Observable<any>;
    rooms: any[] = [];
    isDisable = true;
    listOfBuildingId = [];
    listOfRoomId = [];
    location: LocationModel;
    displayRoom = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<FormModalBaseComponent<EquipmentModel>>,
        public service: EquipmentService,
        public buildingService: BuildingService,
        private genericEquipmentService: GenericEquipmentService
    ) {
        super(data, dialogRef, service);
    }

    ngOnInit(): void {
        this.defaults = this.data.defaults;
        this.location = this.data.location;

        this.genericEquipmentService.findById(this.defaults?.genericEquipment?._id).subscribe(
            result => {
                this.displayRoom = result.data?.needRoom;
            });

        if (this.defaults?.location) {
            this.listOfBuildingId.push(this.location?.building?._id);
            this.listOfRoomId.push(this.location?.room?._id);
        }

        this.autocompleteBuilding();

        this.form = new FormGroup({
            building: new FormControl(this.location?.building, [Validators.required]),
            room: new FormControl(this.location?.building?.room),
        });

        this.form.valueChanges.subscribe(controls => {
            if (controls.building) {
                const el = controls.building;
                this.rooms = el.rooms;
                // remove room if already selected
                this.rooms = this.rooms?.filter((room: any) => !this.listOfRoomId.includes(room._id));
                this.isDisable = false;
            } else {
                this.form.controls.room.setValue(null, {emitEvent: false});
                this.isDisable = true;
            }
        });
    }

    createItem(): void {
        this.loading = true;

        const {_id, technicalIdentifier, label} = this.form.getRawValue().building;

        const location: LocationModel = {
            building: {_id, technicalIdentifier, label},
            room: this.form.getRawValue().room,
            technicalIdentifier: this.form.getRawValue().building?.technicalIdentifier
        };

        if (this.form.valid) {
            const sub = this.service.createLocation(this.data?.defaults?._id, location).subscribe(
                result => {
                    this.afterCreateItem(result, null);
                    this.close(result);
                }, error => {
                    this.setErrorsMessages(error.error.errors);
                    this.loading = false;
                }
            );
            this.subscription.add(sub);
        } else {
            this.onFormInvalid();
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    deleteItem(): void {
        this.loading = true;
        const sub = this.service.deleteLocation(this.defaults?._id, this.location?._id).subscribe(
            result => {
                this.afterCreateItem(result, null);
                this.close(result);
            }, error => {
                this.setErrorsMessages(error.error.errors);
                this.loading = false;
            }
        );
        this.subscription.add(sub);
    }

    beforeCreateItem() {
    }

    afterCreateItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
        }
    }

    afterImportItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
        }
    }
    isCreateMode() {
        return this.mode === CrudModeEnum.Create;
    }

    isUpdateMode() {
        return this.mode === CrudModeEnum.Update;
    }
    beforeUpdateItem() {
    }

    updateItem() {
        this.loading = true;

        const {_id, technicalIdentifier, label} = this.form.getRawValue().building;

        const location: LocationModel = {
            building: {_id, technicalIdentifier, label},
            room: this.form.getRawValue().room,
            technicalIdentifier: this.form.getRawValue().building?.technicalIdentifier
        };

        if (this.form.valid) {
            const sub = this.service.updateLocation(this.data?.defaults?._id, location).subscribe(
                result => {
                    this.afterCreateItem(result, null);
                    this.close(result);
                }, error => {
                    this.setErrorsMessages(error.error.errors);
                    this.loading = false;
                }
            );
            this.subscription.add(sub);
        } else {
            this.onFormInvalid();
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    afterUpdateItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
        }
    }

    autocompleteBuilding(search: string = '') {
        this.buildings$ = this.service.getBuilding(this.defaults?.establishment?._id, [] , search).pipe(map((x: any) => x.data));
    }
}
