import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBaseComponent } from '../form-base/form-base.component';
import { CrudModeEnum } from '../../enum/crud-mode.enum';
import { BaseService } from '../../base.service';

export interface IObjectModal {
    defaults: any;
    mode: CrudModeEnum;
    name?: string;
}

@Component({
    template: ``
})

export class FormModalBaseComponent<T> extends FormBaseComponent<T> implements OnInit {
    // ENUM_PERMISSION = ENUM_PERMISSIONS;
    constructor(@Inject(MAT_DIALOG_DATA) public data: IObjectModal, public dialogRef: MatDialogRef<FormModalBaseComponent<T>>, public service: BaseService<T>) {
        super(service);
        if (this.data) {
            // this.defaults = Object.assign({}, this.data.defaults) as T;
            this.defaults = this.data.defaults as T;
            this.mode = this.data.mode;
        }
    }

    ngOnInit() {
        super.ngOnInit();
    }

    close(data?: any): void {
        this.dialogRef.close(data);
    }

    afterDeleteItem(result?: any, error?: any) {
        if (result) {
            this.close(result);
        }
    }

    afterArchiveItem(result: any, error: any) {
        if (result) {
            this.close(result.data);
        }
    }
}
