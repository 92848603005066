<ng-container *ngIf="isCreateMode() || isUpdateMode()">
    <form (ngSubmit)="save()" [formGroup]="form">
        <base-form-header [mode]='mode' createLabel='Ajouter un équipement compatible' [updateLabel]="'test'"></base-form-header>

        <mat-dialog-content fxLayout="column">

            <base-form-input
                label='Etablissement'
                type='autocomplete'
                [items]='establishment'
                displayProperty='label'
                controlName='establishment'>
            </base-form-input>

            <base-form-input
                label='Équipement'
                type='autocomplete'
                [items$]='equipment$'
                (autocompleteChange)='equipmentAutocompleteChange($event)'
                displayProperty='reference'
                controlName='equipment'>
            </base-form-input>

        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <base-form-input
                type='submit'
                [mode]='mode'
                entityLabel='COMPATIBLE_EQUIPMENT'
                (onCreate)='createItem()'
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>

<ng-container *ngIf="isDeleteMode()">
    <form-modal-delete-base
        [label]='data.name'
        [loading]='loading'
        (onClose)='close()'
        (onDelete)='deleteItem()'
    >
    </form-modal-delete-base>
</ng-container>
