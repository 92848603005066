<ng-container>
   <form (ngSubmit)="save()" [formGroup]="form">
      <base-form-header [mode]="mode" customLabel="Ajouter une date de fin de service"
      ></base-form-header>

      <base-form-input
         class="sm:mr-3 flex-auto"
         label="Date de début"
         type="date"
         controlName="startDate">
      </base-form-input>

      <div style="margin-bottom: 25px" *ngIf="form.controls.comment.enabled">
         <div class="card">
            <quill-editor
               class="h-200"
               [modules]="customModule"
               placeholder="Insérez le commentaire ici"
               [formControl]="form.controls.comment">
            </quill-editor>
            <mat-error *ngIf="form.controls.comment.hasError('required') && form.controls.comment.touched"
                       style="font-size: 75%">
               Le champ commentaire est obligatoire.
            </mat-error>
         </div>
      </div>

      <mat-dialog-actions align="end">
         <base-form-input
            type='submit'
            [mode]='mode'
            entityLabel='Equipement Metrik'
            (onCustom)='setEndServiceDate()'
            customValidateButtonLabel='ENREGISTRER'
         >
         </base-form-input>
      </mat-dialog-actions>
   </form>
</ng-container>