import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {catchError} from "rxjs/operators";

@Injectable()
export class PermissionInterceptor implements HttpInterceptor {
    constructor(
        private router: Router
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(x => this.handlePermissionError(x)))
    }

    private handlePermissionError(err: HttpErrorResponse): Observable<any> {
        if (err.status === 403) {
            this.router.navigate(['/unauthorized']);
            return of(err);
        }

        return throwError(() => err);
    }
}
