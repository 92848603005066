
<base-data-table-header-layout
    [additionalDynamicTemplates]="templates"
    [dynamicTemplates]="[
        { template: title, position: 1, place: 'before' },
        { template: searchContainer, position: 1, place: 'before', isShowing: showSearch},
        { template: archives, position: 1, place: 'after', isShowing: showArchives },
        { template: expired, position: 1, place: 'after', isShowing: showExpires },
        { template: synchronize, position: 1, place: 'after', isShowing: showSynchronization },
        { template: upload, position: 1, place: 'after', hasPermission: importPermission },
        { template: export, position: 1, place: 'after', hasPermission: exportPermission },
        { template: create, position: 1, place: 'after', hasPermission: createPermission },
        { template: edit, position: 1, place: 'after', hasPermission: editPermission },
        { template: filters, position: 1, place: 'after', isShowing: popupFilter },
        { template: closeBtn, position: 1, place: 'after', isShowing: showCloseButton },
        { template: headerChb, position: 1, place: 'after', isShowing: showCustomHeaderCheckbox },
    ]"
>
</base-data-table-header-layout>


<ng-template #title>
    <h2 class='title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l font-bold' fxFlex='none'
        fxHide.xs *ngIf="label">
        {{ label | uppercase}}
    </h2>
</ng-template>

<ng-template #searchContainer>
    <div
            *ngIf='showSearch'
            class='bg-card rounded-full border px-4 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l'
            [ngStyle]="{ width: searchWidthFull ? '100%' : '400px' }"
            fxFlex.lt-md='auto'
            fxLayout='row'
            fxLayoutAlign='start center'>
        <ic-icon [icIcon]='icSearch' size='20px'></ic-icon>
        <input
                class='px-4 py-3 border-0 outline-none w-full bg-transparent'
                placeholder='Recherche...'
                [formControl]="searchControl"
                #search
        >
        <button *ngIf='search.value' matSuffix mat-icon-button aria-label='EFFACER'
                (click)='clearTextFilter()'>
            <ic-icon [icIcon]='icClose' size='20px'></ic-icon>
        </button>
    </div>
</ng-template>

<ng-template #archives>
    <div *ngIf="showArchives">
        <mat-checkbox [(ngModel)]="showArchived" (change)="onShowArchived.emit(showArchived)">Archivés</mat-checkbox>
    </div>
</ng-template>

<ng-template #expired>
    <div *ngIf="showExpires" class="ml-4">
        <mat-checkbox [(ngModel)]="showExpired" (change)="onShowExpired.emit(showExpired)">Expirés</mat-checkbox>
    </div>
</ng-template>

<ng-template #headerChb>
    <div *ngIf="showCustomHeaderCheckbox">
        <mat-checkbox [(ngModel)]="customHeaderCheckbox"
                      (change)="onShowCustomHeaderCheckbox.emit(customHeaderCheckbox)">{{ customHeaderCheckboxLabel }}</mat-checkbox>
    </div>
</ng-template>

<ng-template #closeBtn>
    <button *ngIf="showCloseButton" class="text-secondary ml-4" mat-dialog-close mat-icon-button
            type="button">
        <mat-icon [icIcon]="icClose"></mat-icon>
    </button>
</ng-template>
    <!--    <button class='ml-4' fxFlex='none' fxHide.gt-xs mat-icon-button type='button'>-->
    <!--        <mat-icon [icIcon]='icSearch'></mat-icon>-->
    <!--    </button>-->

    <ng-template #filters>
        <base-data-table-dropdown *ngIf='popupFilter' [content]="templateref" [count]="countPopupFilter" #popup
                                  (eventClear)="clearFilter()">
            <ng-template #templateref>
                <base-data-table-dropdown-template #template *ngIf='popupFilter' [width]=popupWidth>
                    <div class='title mb-4' header>
                        {{ headerPopupTitle }}
                    </div>
                    <div content>
                        <ng-container [ngTemplateOutlet]='contentPopupTemplate'></ng-container>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <button mat-raised-button color="primary" (click)="clearFilter()">EFFACER FILTRES</button>
                            <button mat-raised-button color="primary" (click)="validateFilter()">OK</button>
                        </div>
                    </div>
                </base-data-table-dropdown-template>
            </ng-template>
        </base-data-table-dropdown>
    </ng-template>

    <ng-template #create>
        <div *hasPermission="createPermission">
            <button
                    *ngIf='(canCreate && createOnlyAsPredik && isPredikValue) || (canCreate && !createOnlyAsPredik)'
                    (click)='createItem()'
                    class='ml-4'
                    color='primary'
                    fxFlex='none'
                    mat-mini-fab
                    [matTooltip]='createTooltip'
                    type='button'>
                <mat-icon [icIcon]='icAdd'></mat-icon>
            </button>
        </div>
    </ng-template>
    <ng-template #edit>
        <div *hasPermission='editPermission'>
            <button
                    *ngIf='canEdit'
                    (click)='editItem()'
                    class='ml-4'
                    color='primary'
                    fxFlex='none'
                    mat-mini-fab
                    [matTooltip]='editTooltip'
                    type='button'>
                <mat-icon [icIcon]='icEdit'></mat-icon>
            </button>
        </div>
    </ng-template>
    <ng-template #upload>
        <div *hasPermission="importPermission">
            <button
                    *ngIf='canImport'
                    (click)='importItems()'
                    class='ml-4'
                    color='primary'
                    fxFlex='none'
                    mat-mini-fab
                    matTooltip='Importer'
                    type='button'>
                <mat-icon>upload_file</mat-icon>
            </button>
        </div>
    </ng-template>
<ng-template #export>
    <div *hasPermission="exportPermission">
        <button
                *ngIf='canExport'
                (click)='exportItems()'
                class='ml-4'
                color='primary'
                fxFlex='none'
                mat-mini-fab
                matTooltip='Exporter'
                type='button'>
            <mat-icon>file_download</mat-icon>
        </button>
    </div>
</ng-template>
    <ng-template #synchronize>
        <button
                *ngIf='showSynchronization'
                (click)='synchronization()'
                class='ml-4'
                color='primary'
                fxFlex='none'
                mat-mini-fab
                matTooltip='Synchroniser Docuware'
                type='button'>
            <mat-icon>cached</mat-icon>
        </button>
    </ng-template>

<ng-container *ngTemplateOutlet="actionsTemplate"></ng-container>

<mat-progress-bar *ngIf='loading' mode="indeterminate" color="primary"></mat-progress-bar>

<ng-container [ngTemplateOutlet]='headerTemplate' ></ng-container>
