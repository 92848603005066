<vex-page-layout>

    <vex-page-layout-content class='-mt-6'>
        <div class='card overflow-auto p-6 mt-6'>
            <mat-progress-bar *ngIf='loading' mode="indeterminate" color="primary"></mat-progress-bar>

            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                    <div [ngStyle]="{ 'padding-left': node.level === 0 ? '40px': 0 }" style='padding-left: 40px'></div>
                    <mat-checkbox
                            [checked]="node.selected"
                            [indeterminate]="node.indeterminate && !node.selected"
                            (change)="itemToggle($event.checked,node)">{{node.name}}
                    </mat-checkbox>
                    <span class="ml-2">{{node.label}}</span>
                </mat-tree-node>
                <!-- This is the tree node template for expandable nodes -->
                <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                    <div class="mat-tree-node">
                        <mat-checkbox [disabled]="!canInputContactPermiter"
                                [checked]="node.selected"
                                [indeterminate]="node.indeterminate && !node.selected"
                                (change)="itemToggle($event.checked,node)">{{node.name}}
                        </mat-checkbox>
                        <button mat-icon-button matTreeNodeToggle
                                [attr.aria-label]="'Toggle ' + node.label" (click)='openLevel(node)'>
                            <mat-icon class="mat-icon-rtl-mirror">
                                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                            </mat-icon>
                        </button>
                        {{node.label}}
                        <div *ngIf="node.children.length > 0"
                             class="item-badge">
                            {{ node.children.length }}
                        </div>
                        <mat-progress-bar *ngIf="node.isLoading"
                                          mode="indeterminate"
                                          class="ml-6"></mat-progress-bar>
                    </div>
                    <!-- There is inline padding applied to this div using styles.
                        This padding value depends on the mat-icon-button width.  -->
                    <div [class.example-tree-invisible]="!treeControl.isExpanded(node)"
                         role="group">
                        <ng-container *ngIf='!node.isLoading' matTreeNodeOutlet></ng-container>
                    </div>
                </mat-nested-tree-node>
            </mat-tree>

        </div>
    </vex-page-layout-content>
</vex-page-layout>
