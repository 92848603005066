import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../product.service';
import { scaleIn400ms } from '../../../../../../../@vex/animations/scale-in.animation';
import { fadeInRight400ms } from '../../../../../../../@vex/animations/fade-in-right.animation';
import { stagger40ms } from '../../../../../../../@vex/animations/stagger.animation';
import { fadeInUp400ms } from '../../../../../../../@vex/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from '../../../../../../../@vex/animations/scale-fade-in.animation';
import { MatDialog } from '@angular/material/dialog';
import {
  ProductCreateUpdateDeleteComponent
} from '../../product-create-update-delete/product-create-update-delete.component';
import { CrudModeEnum } from '../../../../../../core/base/enum/crud-mode.enum';
import { MatTableDataSource } from '@angular/material/table';
import { FeiColumn } from '../../../../../../core/base/interfaces/fei-column.interface';
import {
  ProductCompatibleEquipmentCreateUpdateDeleteComponent
} from '../../product-compatible-equipment-create-update-delete/product-compatible-equipment-create-update-delete.component';
import { ENUM_PERMISSIONS } from '../../../../../../core/enums/permission.enum';

@Component({
  selector: 'vex-product-details-resume',
  templateUrl: './product-details-resume.component.html',
  styleUrls: ['./product-details-resume.component.scss'],
  animations: [
    scaleIn400ms,
    fadeInRight400ms,
    stagger40ms,
    fadeInUp400ms,
    scaleFadeIn400ms
  ]
})
export class ProductDetailsResumeComponent implements OnInit {
  ENUM_PERMISSION = ENUM_PERMISSIONS;

  dataSourceCharacteristic: MatTableDataSource<any>;
  displayedColumnsCharacteristic = [];
  feisColumnsCharacteristic: Array<FeiColumn> = [
    { label: 'Libellé', column: 'label', propertyDisplay: 'characteristic.label' },
    { label: 'Unité', column: 'unit', propertyDisplay: 'characteristic.unit' },
    { label: 'Value', column: 'value' },
    { label: '', column: 'actions' },
  ];

  dataSourceCompatibleEquipment: MatTableDataSource<any>;
  displayedColumnsCompatibleEquipment = [];
  feisColumnsCompatibleEquipment: Array<FeiColumn> = [
    { label: 'Référence', column: 'reference' },
    { label: 'Équipement générique', column: 'genericEquipment', propertyDisplay: 'genericEquipment.label' },
    { label: 'Numéro de serie', column: 'serialNumber' },
    { label: '', column: 'actions', canUpdate: false },
  ];

  constructor(
      public service: ProductService,
      private dialog: MatDialog
  ) {
    this.displayedColumnsCharacteristic = this.feisColumnsCharacteristic.map(x => x.column);
    this.displayedColumnsCompatibleEquipment = this.feisColumnsCompatibleEquipment.map(x => x.column);
  }

  ngOnInit(): void {
    this.initData();
  }

  initData(): void {
    this.service.entity$.subscribe(product => {
      this.dataSourceCharacteristic = new MatTableDataSource<any>(product?.characteristics);
      this.dataSourceCompatibleEquipment = new MatTableDataSource<any>(product?.compatibleEquipment);
    });
  }

  async updateItem() {
    this.dialog.open(ProductCreateUpdateDeleteComponent, {
      data: {
        defaults: await this.service.entity,
        mode: CrudModeEnum.Update
      }
    });
  }

  async addCharacteristic() {
    this.dialog.open(ProductCreateUpdateDeleteComponent, {
      data: {
        product: await this.service.entity,
        mode: CrudModeEnum.Create,
        specialMode: 'genericCharacteristic'
      }
    }).afterClosed().subscribe((tva) => {
      if (tva) {
        // this.initData();
      }
    });
  }

  async updateCharacteristic(element: any) {
    this.dialog.open(ProductCreateUpdateDeleteComponent, {
      data: {
        product: await this.service.entity,
        mode: CrudModeEnum.Update,
        defaults: { characteristic: element },
        specialMode: 'genericCharacteristic'
      }
    }).afterClosed().subscribe((tva) => {
      if (tva) {
        // this.initData();
      }
    });
  }

  async deleteCharacteristic(element: any) {
    this.dialog.open(ProductCreateUpdateDeleteComponent, {
      data: {
        product: await this.service.entity,
        defaults: { characteristic: element },
        name: element.label,
        mode: CrudModeEnum.Delete,
        specialMode: 'genericCharacteristic'
      }
    }).afterClosed().subscribe((tva) => {
      if (tva) {
        this.initData();
      }
    });
  }

  round(element) {
    return Math.round(element * 100) / 100;
  }

  async addEquipment() {
    this.dialog.open(ProductCompatibleEquipmentCreateUpdateDeleteComponent, {
      data: {
        product: await this.service.entity,
        mode: CrudModeEnum.Create
      }
    }).afterClosed().subscribe((tva) => {
      if (tva) {
        // this.initData();
      }
    });
  }

  async deleteCompatibleEquipment(element: any) {
    this.dialog.open(ProductCompatibleEquipmentCreateUpdateDeleteComponent, {
      data: {
        product: await this.service.entity,
        defaults: element,
        name: element.reference,
        mode: CrudModeEnum.Delete,
      }
    }).afterClosed().subscribe((tva) => {
      if (tva) {
        this.initData();
      }
    });
  }
}
