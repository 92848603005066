<mat-progress-bar (animationEnd)="redirectToHome()" *ngIf="!loading" [value]="pourcentsLeft"
                  mode="determinate"></mat-progress-bar>

<div class="lottie-animation flex flex-col w-full items-center justify-center">
    <ng-lottie
            (animationCreated)="onAnimate($event)"
            [options]="options"
            class='w-1/4 mb--10'>
    </ng-lottie>
    <p *ngIf="!loading" class='text-xl mt--10 mb-2'>Oops</p>
    <p *ngIf="!loading" class='text-xl'>Vous n'êtes pas autorisé à accéder à cette ressource.</p>
</div>