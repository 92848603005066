<div class="mt-6 flex flex-col md:flex-row md:items-start">
    <div class="flex-auto">
        <div id="establishment-resume-details-card" class="card" *ngIf='(establishmentService.entity$ | async) as establishment'>
            <div class='px-gutter py-4 border-b' fxLayout='row' fxLayoutAlign='space-between center'>
                <h2 class='title m-0'>Détails</h2>
                <!-- TODO PERMISSION <button color='primary' mat-raised-button type='button' *hasPermission="[ENUM_PERMISSIONS.UPDATE_CP_ESTABLISHMENT_INFO]" (click)='updateItem()'>MODIFIER</button>-->
                <button color='primary' mat-raised-button type='button' (click)='updateItem()' *ngIf="canUpdatePermission">MODIFIER</button>
            </div>
            <div *ngIf='establishment._id; else loading' class="px-gutter py-4" gdColumns="1fr 1fr" gdColumns.xs="1fr" gdGap="16px">

                <details-line-base
                        hint='Identifiant Technique'
                        [label]='establishment.technicalIdentifier'
                >
                </details-line-base>

                <details-line-base
                        hint='Libellé'
                        [label]='establishment.label | titlecase'
                >
                </details-line-base>

                <details-line-base
                        *ngIf='establishment.sirenNumber'
                        hint='N° Siren'
                        [label]='establishment.sirenNumber'
                >
                </details-line-base>

                <details-line-base
                        *ngIf='establishment.siretNumber'
                        hint='N° Siret'
                        [label]='establishment.siretNumber'
                >
                </details-line-base>

                <details-line-base
                        *ngIf='establishment.dunsNumber'
                        hint='Identifiant entreprise'
                        [label]='establishment.dunsNumber'
                >
                </details-line-base>

                <details-line-base
                        hint='Identifiant entreprise'
                        [label]='establishment.tva'
                >
                </details-line-base>

                <details-line-base
                        hint='Code APE'
                        [label]='establishment.ape'
                >
                </details-line-base>

                <details-line-base
                        hint="Comptable de rattachement"
                        [label]="establishment.attachedAccountant ? establishment.attachedAccountant.firstName + ' ' + establishment.attachedAccountant.lastName : 'N/A'"
                >
                </details-line-base>

                <details-line-base
                        hint="Dossier comptable"
                        [label]="establishment.accountingFile ? establishment.accountingFile : 'N/A'"
                >
                </details-line-base>

                <details-line-base
                        hint="Capacité budgétaire"
                        [label]="translateValidatorMatriceLevel"
                >
                </details-line-base>

                <details-line-base
                        hint="Statut TVA"
                        [label]="establishment.vatStatus ? 'Soumis' : 'Non soumis'"
                >
                </details-line-base>

            </div>
                <div class='px-gutter py-4 border-b' fxLayout='row' fxLayoutAlign='space-between center'>
                    <h2 class='title m-0'>Adresse</h2>
                </div>
                <div class="px-gutter py-4" gdColumns="1fr 1fr" gdColumns.xs="1fr" gdGap="16px">

                    <details-line-base
                            hint="Capacité Adresse"
                            [label]="establishment.headquarterAddress?.street"
                    >
                    </details-line-base>

                    <details-line-base
                            hint="Complément d'adresse"
                            [label]="establishment.headquarterAddress?.additionalAddress"
                    >
                    </details-line-base>

                    <details-line-base
                            hint="Code postal"
                            [label]="establishment.headquarterAddress?.postalCode"
                    >
                    </details-line-base>

                    <details-line-base
                            hint="Ville"
                            [label]="establishment.headquarterAddress?.city"
                    >
                    </details-line-base>

                    <details-line-base
                            hint="Pays"
                            [label]="establishment.headquarterAddress?.country"
                    >
                    </details-line-base>
                </div>

        </div>
        <div id="establishment-resume-adresses-card" class="card mt-6">
            <div class='px-gutter py-4 border-b' fxLayout='row' fxLayoutAlign='space-between center'>
                <h2 class='title m-0'>Adresses</h2>
                <div>
                    <!-- TODO PERMISSION
                    <button color='primary' mat-raised-button type='button' *hasPermission="[ENUM_PERMISSIONS.CREATE_CP_ESTABLISHMENT_INFO]" (click)='createAddress()'>AJOUTER</button>
                    <button color='primary' mat-raised-button type='button' class='ml-4' *hasPermission="[ENUM_PERMISSIONS.IMPORT_CP_ESTABLISHMENT_INFO_ADDRESS]" (click)='importAddress()'>IMPORTER</button>
                    -->
                    <button color='primary' mat-raised-button type='button' (click)='createAddress()' *ngIf="canAddAddressPermission">AJOUTER</button>
                    <button color='primary' mat-raised-button type='button' class='ml-4' (click)='importAddress()' *ngIf="canImportPermissions">IMPORTER</button>
                </div>
            </div>

            <!-- TODO PERMISSION [updatePermission]="[ENUM_PERMISSIONS.UPDATE_CP_ESTABLISHMENT_INFO_ADDRESS]"
            [deletePermission]="[ENUM_PERMISSIONS.DELETE_CP_ESTABLISHMENT_INFO_ADDRESS]"-->
            <div @stagger
                 class='px-gutter py-4'
                 gdGap='24px'>
                <base-data-table
                    [count]='countAddress'
                    [pageSize]='pageSizeAddress'
                    [sortField]='sortFieldAddress'
                    [sortOrder]='sortOrderAddress'
                    [dataSource]='dataSourceAddress'
                    [displayedColumns]='displayedColumnsAddress'
                    [feisColumns]='feisColumnsAddress'
                    (onPageEvent)='pageEventAddress($event)'
                    (onSortEvent)='sortEventAddress($event)'
                    (onUpdateItem)='updateAddress($event)'
                    (onDeleteItem)='deleteAddress($event)'
                ></base-data-table>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="px-gutter py-4" gdColumns="1fr 1fr" gdColumns.xs="1fr" gdGap="16px">
        <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
            <vex-skeleton class='w-14 mr-2' heigthTailwind='h-12' [circle]='true'></vex-skeleton>
            <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
        </div>

        <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
            <vex-skeleton class='w-14 mr-2' heigthTailwind='h-12' [circle]='true'></vex-skeleton>
            <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
        </div>

        <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
            <vex-skeleton class='w-14 mr-2' heigthTailwind='h-12' [circle]='true'></vex-skeleton>
            <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
        </div>
    </div>
</ng-template>
