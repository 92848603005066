import {AbstractControl} from "@angular/forms";


export const requireMatchHelper  = (control: AbstractControl) => {

    const selection: any = control.value;




    if (typeof selection === 'string') {
        return { incorrect: true };
    }
    // if (items.length > 0 && !items.includes(control.value)) {
    //     return { incorrect: true };
    // }
    return null;
}