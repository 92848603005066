import { Pipe, PipeTransform } from '@angular/core';
import { OrderEmailStatusEnum } from '../enums/order-status.enum';
import { OrderStatusTranslationService } from '../services/translations/order-email-status-translation.service';

@Pipe({
    name: 'emailStatus'
})
export class EmailStatusPipe implements PipeTransform {
    constructor(private statusTranslationService: OrderStatusTranslationService) {}

    transform(status: OrderEmailStatusEnum | null | undefined): string {
        return this.statusTranslationService.translateEmailStatus(status);
    }
}
