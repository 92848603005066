<ng-container *ngIf="isCreatedMode() || isUpdateMode()">
    <form (ngSubmit)="save()" [formGroup]="form">
        <base-form-header [mode]='mode' createLabel='Ajouter un code analytique' updateLabel='Données analytiques'></base-form-header>

        <mat-dialog-content fxLayout="column">

            <base-form-input
                    label='Code analytique '
                    controlName='accountNumber'>
            </base-form-input>

            <base-form-input
                    label='Libellé'
                    controlName='label'>
            </base-form-input>
<!--            <base-form-input-->
<!--                    *ngIf='isUpdateMode()'-->
<!--                    type='checkbox'-->
<!--                    label='Archiver'-->
<!--                    controlName='isArchive'>-->
<!--            </base-form-input>-->
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <base-form-input
                    type='submit'
                    [mode]='mode'
                    entityLabel='BUILDING'
                    (onUpdate)='updateItem()'
                    [loading]='loading'
                    (onCreate)='createItem()'
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>
<ng-container *ngIf="isImportMode()">

    <ng-container>
        <form (ngSubmit)="save()" [formGroup]="importForm">
            <base-form-header [mode]='mode' [importLabel]='"Importer des codes analytique"'></base-form-header>
            <mat-dialog-content fxLayout="column">
                <base-form-input
                        type="file"
                        controlName="file"
                        label="File"
                ></base-form-input>

            </mat-dialog-content>

            <a style="cursor: pointer; text-decoration: underline;" class="text-accent" (click)="exportTemplate()" *ngIf="canDownloadTemplatePermissions">
                Télécharger le modèle
            </a>

            <mat-dialog-actions align="end">
                <base-form-input
                        type='submit'
                        [mode]='mode'
                        [loading]='loading'
                        (onImport)="importItems()"
                >
                </base-form-input>
            </mat-dialog-actions>

        </form>
    </ng-container>
</ng-container>

<ng-container *ngIf='isArchiveMode()'>
    <form-modal-delete-base
            (onClose)='close()'
            (onDelete)='archiveItem()'
            title="Archiver le code analytique"
            defaultMessage='Êtes-vous sûr de vouloir archiver ce code analytique ?'
    >
    </form-modal-delete-base>
</ng-container>
