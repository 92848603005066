import {
    AfterViewInit,
    Component,
    OnInit,
    QueryList,
    TemplateRef,
    ViewChildren,
    ViewEncapsulation
} from '@angular/core';
import { TicketTypeEnum } from '../../../../../core/enums/ticket-type.enum';
import {
    TicketEquipmentCreateUpdateDeleteComponent
} from '../modals/equipment/ticket-equipment-create-update-delete/ticket-equipment-create-update-delete.component';
import {
    TicketRequirementCreateUpdateDeleteComponent
} from '../modals/requirement/ticket-requirement-create-update-delete/ticket-requirement-create-update-delete.component';
import { TicketService } from '../ticket.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EstablishmentService } from '../../../management/establishment/establishment.service';
import { AuthService } from '../../../../../core/services/auth.service';
import { map } from 'rxjs/operators';
import { finalize, lastValueFrom } from 'rxjs';
import { TicketStatusEnum } from '../../../../../core/enums/ticketStatusEnum';
import { TicketModel } from '../../../../../core/models/ticket.model';
import { EstablishmentModel } from '../../../../../core/models/establishment.model';
import { SnackbarService } from '../../../../../core/services/snackbar.service';
import { ENUM_PERMISSIONS } from '../../../../../core/enums/permission.enum';
import { HotTopicModel } from '../../../../../core/models/hot-topic.model';
import { HotTopicService } from '../../hot-topic/hot-topic.service';
import { quillConfigEmpty } from '../../../../../core/config/quill.config';


@Component({
    selector: 'vex-ticket-home',
    templateUrl: './ticket-home.component.html',
    styleUrls: ['./ticket-home.component.scss',
        '../../../../../../../node_modules/quill/dist/quill.snow.css',
        '../../../../../../@vex/styles/partials/plugins/_quill.scss'
    ],
    encapsulation: ViewEncapsulation.None,
})
export class TicketHomeComponent implements OnInit, AfterViewInit {

    loadingEshopBtn = false;
    loadingEticketBtn = false;
    disabledEshopBtn = false;
    disabledEticketBtn = false;
    disabledDemandsBtn = false;
    isPredik = false;
    ENUM_PERMISSION = ENUM_PERMISSIONS;

    hotTopics: HotTopicModel[] = [];
    carouselItems: TemplateRef<any>[] = [];
    @ViewChildren('topicRef') topicTemplates: QueryList<TemplateRef<any>>;


    readonly quillConfig = quillConfigEmpty;

    constructor(
        public service: TicketService,
        private hotTopicService: HotTopicService,
        private dialog: MatDialog,
        private router: Router,
        private establishmentService: EstablishmentService,
        private authService: AuthService,
        private snackbarService: SnackbarService,
    ) {
    }

    ngOnInit(): void {
        this.hotTopicService
            .findAll(null, null, 'createdAt', 'desc', '', {requiredFields: ['contract'], isActive: true})
            .subscribe((res: any) => {
                this.hotTopics = res.data;
            });

        this.checkPermission();
    }

    ngAfterViewInit(): void {
        Promise.resolve().then(() => {
            this.carouselItems = this.topicTemplates.toArray();
        });

        this.topicTemplates.changes.subscribe((list: QueryList<TemplateRef<any>>) => {
            Promise.resolve().then(() => {
                this.carouselItems = list.toArray();
            });
        });
    }

    async createItem(type: TicketTypeEnum) {
        if (type === TicketTypeEnum.REQUIREMENT) {
            this.loadingEshopBtn = true;
            this.disabledEticketBtn = true;
            const establishment = await this.getOneEstablishmentOrNull();
            console.log('establishment', establishment);

            if (establishment) {
                return this.submit(establishment);
            }


            this.dialog.open(TicketRequirementCreateUpdateDeleteComponent, {}).afterClosed().subscribe((result) => {
                if (result) {
                    if (result?.data?._id) {
                        this.router.navigate(['administrative/ticket', result?.data?._id, 'catalog']).then();
                    }
                }
                this.loadingEshopBtn = false;
                this.disabledEticketBtn = false;
                this.disabledEshopBtn = false;
            });
        }

        if (type === TicketTypeEnum.INCIDENT) {
            this.loadingEticketBtn = true;
            this.disabledEshopBtn = true;
            this.dialog.open(TicketEquipmentCreateUpdateDeleteComponent, {}).afterClosed().subscribe((result) => {
                if (result) {
                    if (result?.data?._id) {
                        this.router.navigate(['administrative/ticket', result?.data?._id, 'description-incident'], ).then();
                    }
                }
                this.loadingEshopBtn = false;
                this.disabledEticketBtn = false;
                this.disabledEshopBtn = false;
                this.loadingEticketBtn = false;
            });
        }
    }

    createRequirement() {
        this.createItem(TicketTypeEnum.REQUIREMENT);
    }

    createIncident() {
        this.createItem(TicketTypeEnum.INCIDENT);
    }

    goToTicketList() {
        this.router.navigate(['administrative/ticket']).then();
    }

    async getOneEstablishmentOrNull() {
        const user = await this.authService.currentUserValue();
        const establishmentIds = user.affectations.map(x => x.establishment._id);

        return lastValueFrom(this.establishmentService.findAllAsSelect(true, '_id', 'desc', '', {includeIds: establishmentIds})
            .pipe(
                map((res: any) => res?.data.length === 1 ? res.data[0] : null),
            ));
    }

    submit(establishmentModel: EstablishmentModel) {
        const {_id, label, technicalIdentifier} = establishmentModel;
        const data = {
            establishment: _id,
            type: TicketTypeEnum.REQUIREMENT,
        };
        this.service.create(data)
            .pipe(finalize(() => this.loadingEshopBtn = false))
            .subscribe((response) => {
                this.snackbarService.success('Ticket créé avec succès');
                this.router.navigate(['administrative/ticket', response?.data?._id, 'catalog']).then();
            });
    }

    checkPermission() {
        this.authService.getCurrentUserPermissions$().subscribe((permission) => {

            this.isPredik = this.authService.checkCurrentIsPredik();

            // DS-PRED-1253

            // if (!permission.includes(ENUM_PERMISSIONS.CREATE_TICKET_REQUIREMENT)) {
            //     this.disabledEshopBtn = true;
            // }
            // // if (!permission.includes(ENUM_PERMISSIONS.CREATE_TICKET_INCIDENT)) {
            // //     this.disabledEticketBtn = true;
            // // }
            // if (!permission.includes(ENUM_PERMISSIONS.READ_TICKET_TICKET)) {
            //     this.disabledDemandsBtn = true;
            // }
        });
    }
}
