import { Component, OnDestroy, OnInit } from '@angular/core';
import { EquipmentModel } from '../../../../../../core/models/equipment.model';
import { EquipmentService } from '../../equipment.service';
import { FeiColumn } from '../../../../../../core/base/interfaces/fei-column.interface';
import { MatTableDataSource } from '@angular/material/table';
import { DataTableBase } from '../../../../../../core/base/components/data-table-base/data-table-base';
import { EquipementMetrikStatusModel } from '../../../../../../core/models/equipement-metrik-status.model';
import {
    EquipmentMetrikStatusComponent
} from '../equipment-details-resume/equipment-metrik/equipment-metrik-status.component';
import { CrudModeEnum } from '../../../../../../core/base/enum/crud-mode.enum';
import { MatDialog } from '@angular/material/dialog';


@Component({
    selector: 'vex-equipment-metrik-status',
    templateUrl: './handle-equipment-metrik-status.component.html',
    styleUrls: ['./handle-equipment-metrik-status.component.scss']
})
export class HandleEquipmentMetrikStatusComponent extends DataTableBase<EquipmentModel> implements OnInit, OnDestroy{

    displayedColumns: string[];
    dataSource: MatTableDataSource<any>;
    equipment: EquipmentModel;
    metriks: EquipementMetrikStatusModel[];

    constructor(
        readonly service: EquipmentService,
        readonly dialog: MatDialog,
    ) {
        super();
    }

    feisColumns: Array<FeiColumn> = [
        {label: 'Métrique', column: 'label', propertyDisplay: 'label'},
        {label: 'Commentaire', column: 'comment', propertyDisplay: 'comment'},
        {label: 'Date de début', column: 'startDate', propertyDisplay: 'startDate', type: 'date'},
        {label: 'Date de fin', column: 'endDate', propertyDisplay: 'endDate', type: 'date'},
        {label: 'Equipement caché', column: 'hide', propertyDisplay: 'isProcessExited', type: 'boolean'},
        {label: '', column: 'actions', canDelete: true, canUpdateDynamically: this.canUpdate, canDeleteDynamically: this.canDelete.bind(this)}
    ];


    ngOnInit() {
        this.displayedColumns = this.feisColumns.map(x => x.column);

        this.service.entity$.subscribe((equipment: EquipmentModel) => {
            this.equipment = equipment;
            this.metriks = equipment?.metrikStatus;
            this.dataSource = new MatTableDataSource<EquipementMetrikStatusModel>(this.sortMetrikStatus(equipment?.metrikStatus));
        });
    }

    ngOnDestroy(): void {
    }

    async createItem() {
        this.dialog.open(EquipmentMetrikStatusComponent, {
            data: {
                equipment: this.equipment,
                mode: CrudModeEnum.Create
            },
            width: '620px'
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.initData();
            }
        });
    }

    async updateItem($event: any) {
        this.dialog.open(EquipmentMetrikStatusComponent, {
            data: {
                metrik: $event,
                mode: CrudModeEnum.Update
            },
            width: '620px'
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.initData();
            }
        });
    }

    async deleteItem($event: any) {
        this.dialog.open(EquipmentMetrikStatusComponent, {
            data: {
                metrik: $event,
                mode: CrudModeEnum.Delete
            },
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.initData();
            }
        });
    }

    sortMetrikStatus(metrikStatus: EquipementMetrikStatusModel[]) {
        return metrikStatus.sort(
            (a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
        );
    }

    canUpdate(metrik: any): boolean {

        if (this.equipment?.isDefinitivelyOver) {
            return false;
        }

        const startDate = new Date(metrik.startDate);
        const now = new Date();
        return startDate > now;
    }

    canDelete(metrik: any): boolean {

        if (this.equipment?.isDefinitivelyOver) {
            return false;
        }

        const now = new Date();
        const startDate = new Date(metrik.startDate);

        if (startDate < now) {
            return false;
        }

        const sortedMetrics = this.sortMetrikStatus(this.metriks);
        const lastMetric = sortedMetrics[sortedMetrics.length - 1];
        return metrik._id === lastMetric._id;
    }
}
