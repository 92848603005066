<div class="flex flex-col md:flex-row md:items-start">
    <div class="flex-auto">
<!--        <div class="card">-->
            <div class="px-gutter py-4 border-b"  fxLayout='row' fxLayoutAlign='space-between center'>
                <h2 class="title m-0">Type de sol
                    <icon-warning
                        class='ml-2 mr-2'
                        [show]='displayPercentWarning'
                        toolTip="Le pourcentage doit être égal à 100%"
                    >
                    </icon-warning></h2>
                <button
                    (click)='createFloor()'
                    class='ml-4'
                    color='primary'
                    fxFlex='none'
                    mat-mini-fab
                    matTooltip='Ajouter une salle'
                    type='button'>
                    <mat-icon [icIcon]='icAdd'></mat-icon>
                </button>
            </div>
            <base-data-table
                [dataSource]='dataSourceFloor'
                [displayedColumns]='displayedColumnsFloor'
                [feisColumns]='feisColumnsFloor'
                (onUpdateItem)='updateItemFloor($event)'
                (onDeleteItem)='deleteItemFloor($event)'
                [showPaginator]="false"
            ></base-data-table>
<!--        </div>-->
    </div>
</div>
