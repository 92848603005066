<ng-container *ngIf="canCreateMode || canUpdateMode || canReadMode">
    <form (ngSubmit)="save()" [formGroup]="form" autocomplete='off'>
        <base-form-header [mode]='mode' createLabel='Ajouter un contact au fournisseur'
                          [updateLabel]='form.get("firstName").value + " " + form.get("lastName").value?.toUpperCase()'></base-form-header>

        <mat-dialog-content fxLayout="column">

            <base-form-input
                    *ngIf='!canReadMode'
                    label="Mme/Mr"
                    type="autocomplete"
                    controlName="gender"
                    [items]="genderItem"
            ></base-form-input>

            <base-form-input
                    *ngIf='canReadMode'
                    label="Mme/Mr"
                    controlName="gender"
                    [disabled]='isReadMode()'
            ></base-form-input>

            <!--            <base-form-input-->
            <!--                *ngIf='!isReadMode()'-->
            <!--                label="Type d'acteur"-->
            <!--                type='autocomplete'-->
            <!--                [items$]='contactType$'-->
            <!--                (autocompleteChange)='contactTypeAutocompleteChange($event)'-->
            <!--                displayProperty='label'-->
            <!--                controlName='type'>-->
            <!--            </base-form-input>-->

            <base-form-input
                    *ngIf='!canReadMode'
                    label="Type d'acteur"
                    type="multiple"
                    controlName="contactTypes"
                    [items]="contactTypes"
                    displayProperty='label'
            ></base-form-input>

            <base-form-input
                    *ngIf='canReadMode'
                    label='Type'
                    [disabled]='isReadMode()'
                    displayProperty='label'
                    controlName='type'>
            </base-form-input>

            <div class="flex flex-col sm:flex-row">
                <base-form-input class='sm:mr-3'
                                 [disabled]='isReadMode()'
                                 label='Nom'
                                 controlName='lastName'
                                 [forceUpperCase]='true'>
                </base-form-input>
                <base-form-input class='sm:ml-3'
                                 [disabled]='isReadMode()'
                                 label='Prénom'
                                 controlName='firstName'>
                </base-form-input>
            </div>

            <base-form-input
                    [disabled]='isReadMode()'
                    label='N° Téléphone'
                    controlName='mobileNumber'>
            </base-form-input>

            <base-form-input
                    [disabled]='isReadMode()'
                    label='Email'
                    controlName='email'>
            </base-form-input>

        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <base-form-input
                    [loading]="loading"
                    type='submit'
                    [mode]='mode'
                    entityLabel='SUPPLIER'
                    (onCreate)='createItem()'
                    (onUpdate)='updateItem()'
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>

<ng-container *ngIf="canImportMode">
    <vex-form-import-base
            [modalTitle]='"Importer des contacts"'
            [entityLabel]='"Contact"'
            [form]='importForm'
            [loading]='loading'
            [service]='service'
            [mode]='mode'
            (saveForm)='save()'
            (importItems)='importItems()'
            [canDownloadTemplateModel]='true'
    ></vex-form-import-base>
</ng-container>

<ng-container *ngIf="canDeleteMode">
    <form-modal-delete-base
            [label]="data.name"
            (onClose)="close()"
            (onDelete)="deleteItem()"
    ></form-modal-delete-base>
</ng-container>
